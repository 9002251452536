import { Grid } from '@mui/material';
import React from 'react'
import classes from './style.module.scss'

interface Iprops {
    value: any
    index: any
    children: any
    title: string
    description: string
}
export default function TabPanel(props: Iprops) {
    const { children, title, description, index, value } = props;

    return (
        <Grid container flexDirection="column" role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            rowSpacing={3}
            marginTop={1}
        >
            <Grid item container flexDirection="column" rowSpacing={1}>
                <Grid item className={classes.title}>{title}</Grid>
                <Grid item className={classes.paragraph}>{description}</Grid>
            </Grid>
            <Grid item container rowSpacing={1} flexDirection="column">
                <Grid item><strong>Content:</strong></Grid>
                <Grid item>{value === index && children}</Grid>
            </Grid>
        </Grid>
    );
}