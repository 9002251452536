import React, { Fragment } from "react";
import {
  Button,
  Table,
  Card,
  CardBody,
  CardTitle,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import { RouteComponentProps } from "react-router-dom";
import Swal from "sweetalert2";
import { observer } from "mobx-react";
import { Colxx } from "../../../../template/components/common/CustomBootstrap";
import { IIntegrationModel } from "../../../../store/models/IntegrationModel";
import RootStore from "../../../../store/RootStore";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import profileImg from "../../../../assets/img/profilePlaceholder.jpg";
import { ICountryModel } from "../../../../store/models/CountryModel";
import { CountriesList } from "./../../../../constants/countriesList";
import { NotificationManager } from "../../../../template/components/common/react-notifications";

/* --- dropdown used in actions --- */
class ActionDropDown extends React.Component<
  { onChange: Function },
  { isOpen: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  onClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <Dropdown toggle={this.onClick} isOpen={this.state.isOpen}>
        <DropdownToggle caret={false} className="py-2 px-3">
          <span className="simple-icon-arrow-down" />
        </DropdownToggle>
        <DropdownMenu container="body">
          <DropdownItem onClick={() => this.props.onChange("active")}>
            Activate
          </DropdownItem>
          <DropdownItem
            onClick={() => this.props.onChange("temporary suspend")}
          >
            Temporary suspend
          </DropdownItem>
          <DropdownItem
            onClick={() => this.props.onChange("permanent suspend")}
          >
            Permanently suspend
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
/* --- */

interface Pagination {
  totalCount: number;
  pageSize: number;
  page: number;
  totalPages: number;
}

interface Props extends RouteComponentProps {
  integrations: IIntegrationModel[];
  pagination?: Pagination;
  fetchUsers: Function;
}

interface State {}

@observer
export default class SuspendedUsersList extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {};

    this.actionOnUser = this.actionOnUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.restoreUser = this.restoreUser.bind(this);
  }

  async actionOnUser(integrationId: number, action: string) {
    let actionText = "";
    if (action === "active") {
      actionText = "Activate";
    } else if (action === "temporary suspend") {
      actionText = "Temporary suspend";
    } else if (action === "permanent suspend") {
      actionText = "Permanently suspend";
    }

    try {
      let res = await Swal.fire({
        title: "Warning",
        text: `Are you sure you want to ${actionText.toLowerCase()} this user`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#3085d6",
        confirmButtonText: `Yes, ${actionText} this user`,
      });
      if (res.value) {
        await RootStore.integrationStore.actionOnUser(integrationId, {
          qc_status: action,
        });
        NotificationManager.success(
          "Done",
          `User updated`,
          3000,
          null,
          null,
          "filled"
        );
        this.props.fetchUsers();
      }
    } catch (e) {
      handleError(e as Error);
      ajaxErrorAlert("There was a problem with action on user you selected");
    }
  }
  async removeUser(integrationId: number) {
    try {
      let res = await Swal.fire({
        title: "Warning",
        text: "Are you sure you want to delete this user",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete this user",
      });
      if (res.value) {
        await RootStore.integrationStore.deleteV2(integrationId);
        NotificationManager.success(
          "Done",
          `User deleted`,
          3000,
          null,
          null,
          "filled"
        );
        this.props.fetchUsers();
      }
    } catch (e) {
      handleError(e as Error);
      ajaxErrorAlert("There was a problem with deleting user you selected");
    }
  }
  async restoreUser(integrationId: number) {
    try {
      let res = await Swal.fire({
        title: "Warning",
        text: "Are you sure you want to restore this user",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Restore this user",
      });
      if (res.value) {
        await RootStore.integrationStore.restoreV2(integrationId);
        NotificationManager.success(
          "Done",
          `User restored`,
          3000,
          null,
          null,
          "filled"
        );
        this.props.fetchUsers();
      }
    } catch (e) {
      handleError(e as Error);
      ajaxErrorAlert("There was a problem with restoring user you selected");
    }
  }

  render() {
    const integrations = this.props?.integrations || [];

    return (
      <Fragment>
        <Card className="mb-4">
          <CardBody>
            <CardTitle>Suspended Users List</CardTitle>
            <Row>
              <Colxx>
                <Table striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Email Address</th>
                      <th>Name</th>
                      <th>Created</th>
                      <th>Platform</th>
                      <th>Country</th>
                      <th>IG</th>
                      <th>Followers</th>
                      {/* <th>Quality Score</th> */}
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {integrations?.map((item: any, index: number) => {
                      return (
                        <tr key={`item__${item.id}`} className="cursor-pointer">
                          <td scope="row">
                            {this.props.pagination.page *
                              this.props.pagination.pageSize +
                              (index + 1)}
                          </td>
                          <td>
                            <div className={"d-flex align-items-center"}>
                              <img
                                alt={"img"}
                                src={item.picture?.url || profileImg}
                                className="rounded"
                                width={30}
                                height={30}
                              />
                              <div className="ml-1 text-muted cursor-pointer">
                                {item.user?.email}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="ml-1 text-muted cursor-pointer">
                              <span>{item.user?.full_name}</span>
                            </div>
                          </td>
                          <td>{item?.created_at}</td>
                          <td>{item.user?.registered_platform || "N/A"}</td>
                          <td>
                            {CountriesList.find(
                              (country: ICountryModel) =>
                                country.id === item.country_id
                            )?.name || "N/A"}
                          </td>
                          <td>
                            <a
                              href={`https://www.instagram.com/${item.username}/`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              @{item.username}
                            </a>
                          </td>
                          <td>{item.followers}</td>
                          {/* <td>{item.qc_rate || "N/A"}</td> */}
                          <td>
                            {item.qc_status === "temporary suspend" ? (
                              <Badge color="warning">Temporary suspend</Badge>
                            ) : item.qc_status === "permanent suspend" ? (
                              <Badge color="danger">Permanently suspend</Badge>
                            ) : item.qc_status === "active" ? (
                              <Badge color="success">Active</Badge>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {item.deleted_at ? (
                              <p className="text-muted cursor-pointer d-flex align-items-center">
                                User Has Been Deleted
                                <Button
                                  className={"mr-1 ml-1"}
                                  color={"secondary"}
                                  size={"sm"}
                                  onClick={() => this.restoreUser(item.id)}
                                >
                                  <span className="simple-icon-refresh" />
                                </Button>
                              </p>
                            ) : (
                              <div className="d-flex">
                                <ActionDropDown
                                  onChange={(value) =>
                                    this.actionOnUser(item.id, value)
                                  }
                                />
                                <Button
                                  color={"danger"}
                                  size={"sm"}
                                  className="ml-1"
                                  onClick={() => this.removeUser(item.id)}
                                >
                                  <span className="simple-icon-trash" />
                                </Button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Colxx>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}
