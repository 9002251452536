import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
* this method Time Line Content Media Model
*/
export const TimeLineContentMediaModel = types
  .model({
    type: types.string,
    url: types.string,
  })
  .actions((self) => ({}))
  .views((self) => ({}))
  .views((self) => ({}));
export interface ITimeLineContentMediaModel
  extends Instance<typeof TimeLineContentMediaModel> {}

export interface ITimeLineContentMediaModelSnapshotIn
  extends SnapshotIn<typeof TimeLineContentMediaModel> {}

export interface ITimeLineContentMediaModelSnapshotOut
  extends SnapshotOut<typeof TimeLineContentMediaModel> {}
