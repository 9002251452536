import { IInfluencerModel } from '../store/models/InfluencerModel';

export enum explorerSearchType {
    LOCATION = 'location',
    CATEGORY = 'category',
    HASHTAG = 'hashtag',
}

export type InfluencerGroup = {
    by: explorerSearchType;
    title: string;
    loading: boolean;
    influencers: Array<IInfluencerModel>;
    queryParam: {
        name: explorerSearchType;
        value: any;
    };
    manual?: boolean;
};

export type CustomSearchTermsType = {
    hashtags: Array<string>;
    categories: Array<{ value: number; label: string; slug: string }>;
    search?: string;
    // city: string;
    // state: string;
    country: string;
    followers: { min: number; max: number };
    language: string;
    gender: any;
    inviteOnly: boolean;
    sorts: any;
};
