import { ucfirst } from "../../../lib/Utils";
import { Moment } from "moment";
export type Range = {
  start: Moment | null;
  end: Moment | null;
};

export enum FilterType {
  simpleFilter = "SIMPLE",
  rangeFilter = "RANGE",
}

interface IBaseFilter {
  name: string;
  title: string;
  type: FilterType;
}

export interface IFilter extends IBaseFilter {
  values: () => (string | number)[];
  defaultValue: string | null;
  valueToLabelConverter: (value: string | number | Range) => string | null;
  // defaultValue: string | null;
}
export interface IRangeFilter extends IBaseFilter {
  values: (value: Range) => (Range | null)[];
  defaultValue: Range | null;
}

export interface FilterValues {
  searchTerm: string | null;
  filters: { [key: string]: string | number | Range };
}

export abstract class AbstractFilterSet {
  private filters: IBaseFilter[] = [];
  protected searchTerm: string = "";
  protected filterValues: { [key: string]: string | number | Range } = {};
  protected abstract handleChange(): void;

  addFilter(filter: IFilter | IRangeFilter) {
    let iFilter;
    if (filter.type === FilterType.simpleFilter) {
      iFilter = filter as IFilter;
      if (iFilter.values().length === 0) {
        throw new Error(
          "A filter must always have a set of selectable values..."
        );
      }
    } else if (filter.type === FilterType.rangeFilter) {
      iFilter = filter as IFilter;
    }

    this.filters.push(iFilter);
    this.filterValues[iFilter.name] = iFilter.defaultValue;
  }

  handleSearchEvent(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.searchTerm !== e.target.value) {
      this.searchTerm = e.target.value;
      this.handleChange();
    }
  }

  setFilterValue(
    name: string,
    value: string | number | Range,
    shoudlCallChange: boolean = true
  ) {
    let filters = this.filters;
    let iFilter;
    let filterObj: IBaseFilter = filters.find((filter) => filter.name === name);
    if (!filterObj) throw new Error(`No filter found with name ${name}`);

    if (filterObj.type === FilterType.simpleFilter) {
      iFilter = filterObj as IFilter;
      if (iFilter.values().indexOf(value as string | number) === -1)
        throw new Error(`Value ${value} is not in this filter's value list`);
    } else if (filterObj.type === FilterType.rangeFilter) {
      iFilter = filterObj as IRangeFilter;
    }

    if (this.filterValues[name] !== value) {
      this.filterValues[name] = value;
      if (shoudlCallChange) this.handleChange();
    }
  }

  loadDefaultFilters() {
    for (let filter of this.filters) {
      if (filter.type === FilterType.simpleFilter) {
        let iFilter = filter as IFilter;
        this.filterValues[filter.name] = iFilter.defaultValue;
      } else {
        let iFilter = filter as IRangeFilter;
        this.filterValues[filter.name] = iFilter.defaultValue;
      }
    }
    this.handleChange();
  }

  getFilters(): IBaseFilter[] {
    return this.filters;
  }

  getSelectedValueLabel(filter: IBaseFilter): string {
    return this.getValueLabel(filter, this.filterValues[filter.name]);
  }

  getValueLabel(filter: IBaseFilter, value: string | number | Range): string {
    let iFilter;
    if (filter.type === FilterType.simpleFilter) {
      iFilter = filter as IFilter;
      if (iFilter.valueToLabelConverter)
        return iFilter.valueToLabelConverter(value);
      else return ucfirst(value.toString());
    }
  }

  public getFilterValues(): FilterValues {
    return {
      searchTerm: this.searchTerm,
      filters: this.filterValues,
    };
  }

  public getDefaultFilterValues(): FilterValues {
    let filters: { [key: string]: string | Range } = {};
    for (let filter of this.filters) {
      if (filter.type === FilterType.simpleFilter) {
        let IFilter = filter as IFilter;
        filters[filter.name] = IFilter.defaultValue;
      } else {
        let IFilter = filter as IRangeFilter;
        filters[filter.name] = IFilter.defaultValue;
      }
    }
    return {
      searchTerm: "",
      filters,
    };
  }

  applyFilters() {
    this.handleChange();
  }
}
