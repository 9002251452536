import { AxiosResponse } from "axios";
import { ajaxErrorAlert } from "./Utils";
import Swal, { SweetAlertOptions } from "sweetalert2";
import $ from "jquery";
import { makeIntegrationQuery } from "../store/common/RequestHelpers";
import Transport from "./Transport";

export default class InstagramAuthHelper {
  private readonly operation: () => Promise<AxiosResponse<any>>;
  private readonly igUsername: string;
  private readonly igId: number;

  constructor(
    igId: number,
    igUsername: string,
    integrationRequestGenerator: () => Promise<AxiosResponse<any>>
  ) {
    this.operation = integrationRequestGenerator;
    this.igUsername = igUsername;
    this.igId = igId;
  }

  async perform(): Promise<AxiosResponse<any>> {
    try {
      await this.operation();
    } catch (e) {
      Swal.close();
      if (e.response.status === 422) {
        ajaxErrorAlert(
          "This IG (@" +
            this.igUsername +
            ") has been already used in AInfluencer platform!"
        );
      } else if (e.response.status === 400) {
        if (e.response.data.code === "TWO_FACTOR_LOGIN") {
          return await this.promptTwoStepCode(e.response.data);
        } else if (e.response.data.code === "CHECKPOINT") {
          return await this.handleCheckPoint(
            e.response.data.step === "SUBMIT_PHONE",
            e.response.data.data
          );
        } else if (e.response.data.code === "WRONG_PASSWORD") {
          ajaxErrorAlert("The password you entered is not correct!");
          throw e;
        } else if (e.response.data.code === "INACTIVE_USER") {
          ajaxErrorAlert("The account is inactive on instagram!");
          throw e;
        }
      } else {
        ajaxErrorAlert(
          "There was a problem adding the IG account! Go back and try again..."
        );
        throw e;
      }
    }
  }

  private async promptTwoStepCode(data: any): Promise<AxiosResponse<any>> {
    let self = this;
    const { value: response } = await Swal.fire({
      type: "warning",
      title: "Verify Your Identity",
      text: `Two-step authentication is enabled for ${
        this.igUsername
      }, enter the 6-digit code Instagram sent to your ${
        data.type === "phone_number" ? "number ending in" : "email,"
      } ${data.value}:`,
      input: "text",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
        if (!value.match(/^-{0,1}\d{6}$/)) {
          return "You must enter a 6 digit number!";
        }
      },
      footer: '<a href="#" id="resend-two-step-code">Resend Code</a>',
      showLoaderOnConfirm: true,
      preConfirm: async (authCode) => {
        try {
          return await Transport.post(
            `integrations/${this.igId}/two-factor`,
            { code: authCode },
            makeIntegrationQuery()
          );
        } catch (e) {
          if (e.response.status === 400) {
            Swal.close();
            Swal.fire({
              type: "error",
              title: "Invalid Code!",
              text: "The code you entered was invalid! You either entered the wrong code, or the code you received is expired. Please try again!",
            });
          } else {
            throw e;
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      onOpen(modalElement: HTMLElement): void {
        $(modalElement)
          .find("#resend-two-step-code")
          .on("click", async () => {
            Swal.close();
            await self.perform();
          });
      },
    });
    return response;
  }

  private getSubmitPhoneModalConfig(igData: any): SweetAlertOptions {
    return {
      title: "Instagram has detected an unusual login attempt",
      text: "Instagram will send you a security code via SMS to verify your identity. Please Enter your phone number to receive the security code.",
      input: "text",
      showCancelButton: true,
      inputPlaceholder: "+10000000000",

      inputValidator: (value) => {
        if (!value) {
          return "You need to enter something!";
        }
        value = value.replace("+", "");
        if (!/^\d{10}$/.test(value)) {
          return "You must enter a valid phone number with country code!";
        }
      },
      showLoaderOnConfirm: true,
      preConfirm: async (value) => {
        try {
          await Transport.post(`integrations/${this.igId}/phone-number`, {
            phone_number: value,
          });
          Swal.insertQueueStep(this.getCheckpointModalConfig(igData));
        } catch (e) {
          if (e.response.status === 400) {
            Swal.showValidationMessage(`Could not add phone number!`);
            Swal.hideLoading();
          } else {
            throw e;
          }
        }
      },
    };
  }

  private getCheckpointModalConfig(igData: any): SweetAlertOptions {
    let self = this;
    return {
      title: "Instagram has detected an unusual login attempt",
      text: `This is totally normal! In order to complete the integration with AInfluencer, please enter the 6-digit code Instagram sent to your ${
        igData.type === "phone_number" ? "number ending in" : "email,"
      } ${igData.value}:`,
      input: "text",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to enter something!";
        }
        if (!value.match(/^-{0,1}\d{6}$/)) {
          return "You must enter a 6 digit number!";
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: async (code) => {
        try {
          return await Transport.post(`integrations/${this.igId}/checkpoint`, {
            code,
          });
        } catch (e) {
          Swal.hideLoading();
          if (e.response.status === 400) {
            Swal.showValidationMessage(`Could not validate the code!`);
          } else {
            Swal.showValidationMessage(`Unknown Error! Please try again...`);
            throw e;
          }
        }
      },
      footer: '<a href="#" id="resend-two-step-code">Resend Code</a>',
      onOpen(modalElement: HTMLElement): void {
        $(modalElement)
          .find("#resend-two-step-code")
          .on("click", async () => {
            Swal.close();
            await self.perform();
          });
      },
    };
  }

  private async handleCheckPoint(
    submitPhone: boolean,
    igData: any
  ): Promise<AxiosResponse<any>> {
    let swal: any;
    if (submitPhone) {
      swal = await Swal.queue([this.getSubmitPhoneModalConfig(igData)]);
      if (swal.dismiss) {
        throw new Error("Dismissed");
      }
      return swal;
    } else {
      swal = await Swal.fire(this.getCheckpointModalConfig(igData));
      if (swal.dismiss) {
        throw new Error("Dismissed");
      }
      return swal.value;
    }
  }
}
