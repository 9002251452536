import React, { Component } from "react";
import InvitationWizard from "./components/InvitationWizard";
import InvitationHeader from "./components/InvitationHeader";
import { RouteComponentProps } from "react-router";
import RootStore from "../../store/RootStore";
import { deleteCookies } from "../../store/UserStore";
import { Spinner } from "reactstrap";
import Transport from "../../lib/Transport";
import { ajaxErrorAlert, handleError } from "../../lib/Utils";
import { CountriesList } from './../../constants/countriesList'

export interface StepProp {
  step: number;
}
interface Props extends RouteComponentProps<{ id: string; step: string }> {}
interface State {
  step: number;
  user: User;
  channel: string;
  ad: string | null;
  status: string | null;
  dataFetched: boolean;
}
interface User {
  firstName: string;
  lastName: string;
  email: string | null;
  userName: string | null;
  categories: { label: string; value: string }[];
  country: { name: string; id: string; code: string };
  identity_id: number;
  invited_via: string | null;
}
export default class LandingApp extends Component<Props, State> {
  state: State = {
    step: this.props.match.params.step
      ? parseInt(this.props.match.params.step)
      : 1,
    user: {
      firstName: "",
      lastName: "",
      email: "",
      userName: "",
      categories: [],
      country: { name: "", id: "", code: "" },
      identity_id: null,
      invited_via: null,
    },
    channel: "",
    ad: "",
    status: null,
    dataFetched: false,
  };

  async componentDidMount(): Promise<void> {
    deleteCookies();
    try {
      const res = await Transport.get(
        `invitations/public/${this.props.match.params.id}`
      );

      const { categories, integrations, name, email, country_id } =
        res.data.invitation.target;
      // this.setState({  });
      await RootStore.fetchStaticData();
      const country = CountriesList.find(
        (item: any) => item.id === country_id
      );

      this.setState({
        user: {
          firstName: name.split(" ")[0],
          lastName: name.split(" ")[1],
          email: email,
          userName: integrations[0].username,
          invited_via:
            res.data.invitation.email_status === "success"
              ? "email"
              : res.data.invitation.dm_status === "success"
              ? "instagram_dm"
              : null,
          categories: categories.map((item: any) => {
            return {
              label: item.title,
              value: item.title,
            };
          }),
          country: country,
          identity_id: res.data.invitation.target_id,
        },
        channel: res.data.invitation.source.name,
        ad: res.data.invitation.ad ? res.data.invitation.ad.title : null,
        status: res.data.invitation.status,
        dataFetched: true,
      });
    } catch (error) {
      // this.setState({dataFetched:true})
      handleError(error);
      ajaxErrorAlert("There was a problem with fetching data");
    }
  }
  componentDidUpdate() {
    // this.setState({ step: parseInt(this.props.match.params.step) });
    if (this.props.match.params.step) {
      if (this.state.step !== parseInt(this.props.match.params.step)) {
        this.setState({
          step: parseInt(this.props.match.params.step),
        });
      }
    } else {
      this.props.match.params.step = "1";
      this.setState({ step: 1 });
    }
  }
  onStepChange = (): void => {
    this.setState({ step: parseInt(this.props.match.params.step) });
  };

  render() {
    return (
      <div className="landing-wrapper">
        <InvitationHeader step={this.state.step} />
        <div className="Circle c-1"></div>
        <div className="Circle c-2"></div>
        <div className="Dots d-1"></div>
        <div className="Dots d-2"></div>
        {!this.state.dataFetched ? (
          <React.Fragment>
            <div className="spinner">
              <Spinner color="primary" className="mb-1" />
              <p>Please wait...</p>
            </div>
          </React.Fragment>
        ) : (
          <InvitationWizard
            step={this.state.step}
            user={this.state.user}
            channel={this.state.channel}
            ad={this.state.ad}
            Adstatus={this.state.status}
            onStepChange={this.onStepChange}
            {...this.props}
          />
        )}
      </div>
    );
  }
}
