import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import { FormGroup, Label, Button } from "reactstrap";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import * as Yup from "yup";
import { applySnapshot } from "mobx-state-tree";
import Swal from "sweetalert2";

import RootStore from "../../../../store/RootStore";
import { NotificationManager } from "../../../../template/components/common/react-notifications";
import Transport from "../../../../lib/Transport";
import { ajaxErrorAlert, handleError, showLoading } from "../../../../lib/Utils";

const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string().required("First name can't be empty"),
  last_name: Yup.string().required("Last name can't be empty"),
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Email can't be empty"),
});

@observer
class EditUserProfileInfo extends Component<RouteComponentProps> {
  static async onSubmit(values: any, formikActions: any) {
    let currentUser = RootStore.users.currentUser;

    try {
      formikActions.setSubmitting(true);
      if (values.email !== RootStore.users.currentUser.email) {
        await Swal.fire({
          title: "Warning",
          text:
            "If you change your email address, you won't be able to use any feature unless you activate your new email address." +
            `The activation link will be send to ${values.email}. Are you sure?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "rgb(18, 171, 40)",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, Change It",
        })
          .then(async (res) => {

            if (res.value) {
              showLoading()

              let response = await Transport.patch(
                `users/${RootStore.users.currentUser.id}`,
                {
                  user: values,
                },
                null
              );

              applySnapshot(RootStore.users.currentUser, response.data["user"]);
              await RootStore.users.logOut();
              formikActions.setSubmitting(false);
              Swal.close()
            }
          })
          .catch((e) => {
            formikActions.setSubmitting(false);
            Swal.close()
            handleError(e);
            ajaxErrorAlert("There was a problem in editing your profile.");
          });
      } else {

        showLoading()

        let response = await Transport.patch(
          `users/${RootStore.users.currentUser.id}`,
          {
            user: values,
          },
          null
        );
        formikActions.setSubmitting(false);
        Swal.close()
        applySnapshot(currentUser, response.data["user"]);
        RootStore.users.currentUser.setCreatedAd();
        NotificationManager.success(
          "Done",
          "Your profile was updated successfully!",
          3000,
          null,
          null,
          "filled"
        );
        
      }
    } catch (e) {
      Swal.close()
      formikActions.setSubmitting(false);
      handleError(e);
      ajaxErrorAlert("There was a problem in editing your profile.");
    }
  }

  render() {
    return (
      <Formik
        initialValues={{
          first_name: RootStore.users.currentUser.first_name,
          last_name: RootStore.users.currentUser.last_name,
          email: RootStore.users.currentUser.email,
        }}
        validationSchema={PersonalInfoSchema}
        onSubmit={EditUserProfileInfo.onSubmit.bind(this)}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form
            translate={"no"}
            className="av-tooltip tooltip-label-right panel-form"
          >
            <FormGroup className="error-l-100">
              <Label className="position-relative">
                <b>First name:</b>
                {errors.first_name && touched.first_name && (
                  <div className="invalidFeedback">{errors.first_name}</div>
                )}
              </Label>
              <Field
                className="form-control"
                name="first_name"
                disabled={isSubmitting}
              />
            </FormGroup>
            <FormGroup className="error-l-100">
              <Label className="position-relative">
                <b>Last name:</b>
                {errors.last_name && touched.last_name && (
                  <div className="invalidFeedback">{errors.last_name}</div>
                )}
              </Label>
              <Field
                className="form-control"
                name="last_name"
                disabled={isSubmitting}
              />
            </FormGroup>
            <FormGroup className="error-l-100">
              <Label className="position-relative">
                <b>Email:</b>
                {errors.email && touched.email && (
                  <div className="invalidFeedback">{errors.email}</div>
                )}
              </Label>
              <Field
                className="form-control"
                name="email"
                disabled={isSubmitting}
              />
            </FormGroup>

            <Button color="primary" type="submit">
              Update User Info
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default EditUserProfileInfo;
