import React from 'react'
import classes from './style.module.scss'

interface IProps {
    children: any
}
function OnboardBody(props: IProps) {
    const { children } = props
    return (
        <div className={classes.body}>
            {children}
        </div>
    )
}

export default OnboardBody
