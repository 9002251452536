import Swal from "sweetalert2";
import AbstractOfferHistory, {
  OfferModification,
} from "./AbstractOfferHistory";
import {
  ContentModel,
  IContentModel,
  IContentModelSnapshotIn,
} from "../models/ContentModel";
import { IMediaModel, MediaModel } from "../models/MediaModel";
import RootStore from "../RootStore";
import BatchChange from "./BatchChange";
import {
  applySnapshot,
  clone,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  IOfferModel,
  IOfferModelSnapshotIn,
  OfferModel,
} from "../models/OfferModel";
import Transport from "../../lib/Transport";
import { ajaxErrorAlert, showLoading } from "../../lib/Utils";

const OfferHistoryTree = types
  .model({
    content: ContentModel,
    offer: types.late<typeof OfferModel>(() => OfferModel),
  })
  .actions((self) => ({
    replaceContent(content: IContentModel) {
      self.content = clone(content);
    },
  }));

export interface IOfferHistoryTree extends Instance<typeof OfferHistoryTree> {}

export default class OfferHistory extends AbstractOfferHistory<IOfferHistoryTree> {
  private medias: IMediaModel[] = [];

  /**
  * this method resolve Content By Id
  * @param id, this param content ID
  */
  protected resolveContentById(id: number): IContentModel {
    return RootStore.contentStore.getExistingModel(id);
  }

  /**
  * this method resolve Media By Id
  * @param id, this param content ID
  */
  protected resolveMediaById(id: number): IMediaModel {
    const localMedias = this.medias.filter((media) => media.id === id)[0];
    if (localMedias) {
      return localMedias;
    }
    return this.offer.content_id.medias.filter((media) => media.id === id)[0];
  }

  /**
  * this method counter Offer Request
  * @param modificationsList, this param get Offer Modification
  * @param callback, this param is function for custom callback
  */
  protected async counterOfferRequest(
    modificationsList: OfferModification[],
    callback: (batches: BatchChange[]) => void
  ): Promise<void> {
    return RootStore.offerStore.counterOffer(
      modificationsList,
      this.offer.id,
      callback
    );
  }

  /**
  * this method apply Content Snapshot
  * @param tree, this param get Offer History Tree
  * @param newContentSnapshot, this param get new Content Snapshot
  */
  protected applyContentSnapshot(
    tree: IOfferHistoryTree,
    newContentSnapshot: IContentModelSnapshotIn
  ): void {
    try {
      applySnapshot(tree.content, newContentSnapshot);
    } catch (e) {
      console.log(newContentSnapshot);
      throw e;
    }
  }

  /**
  * this method apply Content Snapshot
  * @param tree, this param get Offer History Tree
  * @param newContentSnapshot, this param get new Content Snapshot
  */
  protected applyOfferSnapshot(
    tree: IOfferHistoryTree,
    newSnapshot: IOfferModelSnapshotIn
  ): void {
    applySnapshot(tree.offer, newSnapshot);
  }

  /**
  * this method get Content From History Tree
  * @param tree, this param get Offer History Tree
  */
  protected getContentFromHistoryTree(tree: IOfferHistoryTree): IContentModel {
    for (let media of tree.content.medias) {
      media.unDelete();
    }
    return tree.content;
  }

  /**
  * this method get Offer From History Tree
  * @param tree, this param get Offer History Tree
  */
  protected getOfferFromHistoryTree(tree: IOfferHistoryTree): IOfferModel {
    return tree.offer;
  }

  /**
  * this method make History Tree
  * @param offer, this param get Offer
  */
  protected makeHistoryTree(offer: IOfferModel): IOfferHistoryTree {
    return OfferHistoryTree.create({
      content: getSnapshot(offer.content_id),
      offer: getSnapshot(offer),
    });
  }

  /**
  * this method replace Content
  * @param tree, this param get Offer History Tree
  * @param content, this param get content
  */
  protected replaceContent(
    tree: IOfferHistoryTree,
    content: IContentModel
  ): void {
    tree.replaceContent(content);
  }

  /**
  * this method get Missing Content Ids
  * @param contentIdList, this param get content ID List
  */
  protected getMissingContentIds(contentIdList: number[]): number[] {
    let contentsToFetch = [];
    for (let contentId of contentIdList) {
      let model = RootStore.contentStore.getExistingModel(contentId);
      if (model === null) {
        contentsToFetch.push(contentId);
      }
    }
    return contentsToFetch;
  }

  /**
  * this method handle New Contents
  * @param response, this param get response
  */
  protected handleNewContents(response: any): void {
    RootStore.contentStore.syncMissingModels(response["contents"]);
  }

  /**
  * this method handle New Medias
  * @param response, this param get response
  */
  protected handleNewMedias(response: any): void {
    for (let mediaData of response["medias"]) {
      this.medias.push(MediaModel.create(mediaData));
    }
  }

  /**
  * this method sync Updated Main Offer
  * @param response, this param get response
  */
  protected syncUpdatedMainOffer(response: any): void {
    RootStore.identityStore.syncMissingModels(response["identity"]);
    RootStore.identityStore.syncMissingModels(response["target_identity"]);
    RootStore.integrationStore.syncMissingModels(
      response["target_integration"]
    );

    if (response["offer"]["content"]) {
      RootStore.contentStore.syncMissingModels([response["offer"]["content"]]);
    }

    RootStore.campaignStore.syncMissingModels(response["ad.campaign"]);
    RootStore.adStore.syncMissingModels(response["ad"]);

    applySnapshot(this.offer, response["offer"]);
  }

  /**
  * this method request Accept
  */
  protected async requestAccept(): Promise<BatchChange[]> {
    try {
      RootStore.offerStore.setBusy();
      showLoading();
      let response = await Transport.post(
        "offers/" + this.offer.id + "/history/status",
        {
          status: "accept",
        }
      );
      Swal.close();
      applySnapshot(this.offer, response.data.offer);
      RootStore.offerStore.setIdle();
      return response.data.offer.changes.map(
        (change: any) => new BatchChange(change)
      );
    } catch (e) {
      if (e.response.status === 409) {
        ajaxErrorAlert("You are not allowed to accept this offer.");
      } else {
        ajaxErrorAlert("Failed to accept offer!");
      }

      RootStore.offerStore.setIdle();
      throw e;
    }
  }

  /**
  * this method request Reject
  */
  protected async requestReject(): Promise<BatchChange[]> {
    try {
      RootStore.offerStore.setBusy();
      let response = await Transport.post(
        "offers/" + this.offer.id + "/history/status",
        {
          status: "reject",
        }
      );
      applySnapshot(this.offer, response.data.offer);
      RootStore.offerStore.setIdle();
      return response.data.offer.changes.map(
        (change: any) => new BatchChange(change)
      );
    } catch (e) {
      if (e.response.status === 409) {
        ajaxErrorAlert("You are not allowed to reject this offer.");
      } else {
        ajaxErrorAlert("Failed to reject offer!");
      }

      RootStore.offerStore.setIdle();
      throw e;
    }
  }
}
