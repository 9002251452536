import {
  // applySnapshot,
  // destroy,
  // flow,
  // getIdentifier,
  // getSnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
// import { AdModel } from "./AdModel";
// import { IntegrationModel } from "./IntegrationModel";
// import { PublishJobModel } from "./PublishJobModel";
import moment from "moment";
import { countDown } from "../../lib/Utils";

// import MegaphoneIcon from "../../assets/icons/Megaphone.svg";
// import HandshakeIcon from "../../assets/icons/Handshake.svg";
// import CalendarWithAClockTimeToolsIcon from "../../assets/icons/CalendarWithAClockTimeTools.svg";

const NotificationData = types.model({
  event: types.string,
  offer_ad_title: types.maybeNull(types.string),
  offer_ad_link: types.maybeNull(types.string),
  offer_target_integration_username: types.maybeNull(types.string),
  offer_target_identity_name: types.maybeNull(types.string),
  publish_integration_username: types.maybeNull(types.string),
  publish_offer_target_identity_name: types.maybeNull(types.string),
  publish_offer_ad_title: types.maybeNull(types.string),
  publish_offer_link: types.maybeNull(types.string),
  offer_link: types.maybeNull(types.string),
  ad_title: types.maybeNull(types.string),
  // ad: types.maybeNull(AdModel),
  // integration: types.maybeNull(IntegrationModel),
  // publish_job: types.maybeNull(PublishJobModel),
});

// type INotificationData = Instance<typeof NotificationData>;


/**
* this method Notification Model
*/
export const NotificationModel = types
  .model({
    created_at: types.string,
    id: types.string,
    notifiable_id: types.number,
    notifiable_type: types.string,
    read_at: types.maybeNull(types.string),
    type: types.string,
    update_at: types.maybeNull(types.string),
    data: NotificationData,
  })
  .views((self) => ({

    /**
    * this method handle title
    */
    title() {
      switch (self.data.event) {
        case "ad:approved":
          return self.data.ad_title
            ? `${self.data.ad_title} approved and ready to invite influencers.`
            : "Your Ad is approved and ready to invite influencers.";
        case "ad:expiry":
          return self.data.ad_title
            ? `${self.data.ad_title} publishing date has expired.`
            : "Your Ad publishing date has expired.";
        case "ad:rejected":
          return self.data.ad_title
            ? `${self.data.ad_title} rejected.`
            : "Your Ad  rejected.";
        case "brand.integration:health-failed":
          return "Your Instagram account is disconnected. Scheduled posts may fail.";
        // ? `Your @${self.data.integration.username} account is disconnected. Scheduled posts may fail.`
        // : ;
        case "brand.offer:created":
          return self.data.offer_target_integration_username
            ? `Good news!  You have received a new offer from @${self.data.offer_target_integration_username}.`
            : "Good news!  You have received a new offer.";
        case "brand.offer:accepted":
          return self.data.offer_ad_title &&
            self.data.offer_target_integration_username
            ? `@${self.data.offer_target_integration_username} accepted your offer and is ready for payment.`
            : "Your latest offer has been accepted!";
        case "offer:updated":
          return self.data.offer_target_integration_username
            ? `There's new activity on an offer with @${self.data.offer_target_integration_username}`
            : "There’s new activity on your offer.";
        case "brand.offer:paid":
          return "Payment successfully processed on your offer.";
        case "brand.publish-job:published":
          return self.data.offer_target_integration_username
            ? `Post successfully published on @${self.data.offer_target_integration_username}.`
            : "Post successfully published.";
        case "publish-job:published":
          return self.data.publish_integration_username
            ? `Post successfully published on @${self.data.publish_integration_username}.`
            : "Post successfully published.";
        case "brand.publish-job:publishing-failed":
          return self.data.offer_target_integration_username
            ? `Post publishing failed on @${self.data.offer_target_integration_username}.`
            : "Your scheduled content did not post successfully.";
        case "publish-job:publishing-failed":
          return self.data.publish_integration_username
            ? `Post publishing failed on @${self.data.publish_integration_username}.`
            : "Your scheduled content did not post successfully.";
        case "brand.publish-job:completed":
          return self.data.offer_target_integration_username
            ? `Post successfully take down on @${self.data.offer_target_integration_username}.`
            : "Post successfully take down.";
        case "publish-job:completed":
          return self.data.publish_integration_username
            ? `Post successfully take down on @${self.data.publish_integration_username}.`
            : "Post successfully take down.";
        case "brand.publish-job:completion-failed":
          return self.data.offer_target_integration_username
            ? `Post publishing or takedown failed on @${self.data.offer_target_integration_username}.`
            : "Post publishing or takedown failed.";
        case "publish-job:completion-failed":
          return self.data.publish_integration_username
            ? `Post publishing or takedown failed on @${self.data.publish_integration_username}.`
            : "Post publishing or takedown failed.";
        case "brand.publish-job:transferred":
          return self.data.offer_target_integration_username
            ? `Payment transferred to @${self.data.offer_target_integration_username}`
            : "Payment transferred.";
        case "publish-job:transferred":
          return self.data.offer_target_integration_username
            ? `Payment transferred to @${self.data.offer_target_integration_username}`
            : "Payment transferred.";
        case "brand.publish-job:health-failed":
          return self.data.offer_target_integration_username
            ? `Post take down failed on @${self.data.offer_target_integration_username}.`
            : "Post take down failed.";
        case "publish-job:health-failed":
          return self.data.offer_target_integration_username
            ? `Post take down failed on @${self.data.offer_target_integration_username}.`
            : "Post take down failed.";
        case "brand.offer:cancelled-intime":
          return `@${self.data.offer_target_integration_username} cancelled the offer for "${self.data.offer_ad_title}".`;
        case "brand.offer:cancel-request-approved":
          return `@${self.data.offer_target_integration_username} approved your cancellation request for "${self.data.offer_ad_title}".`;
        case "brand.offer:cancel-request-rejected":
          return `@${self.data.offer_target_integration_username} rejected your cancellation request for "${self.data.offer_ad_title}".`;
        case "brand.offer:cancel-ineligible":
          return `AInfluencer has declined your cancellation request for "${self.data.offer_ad_title}".`;
        case "brand.offer:late-cancelled":
          return `@${self.data.offer_target_integration_username} cancelled the offer for "${self.data.offer_ad_title}".`;
        case "brand.offer:force-cancelled":
          return `AInfluencer has ruled in your favor; The offer for "${self.data.offer_ad_title}" is cancelled.`;
        case "brand.offer:refund-request-approved":
          return `@${self.data.offer_target_integration_username} approved your refund request for "${self.data.offer_ad_title}".`;
        case "brand.offer:refund-request-rejected":
          return `@${self.data.offer_target_integration_username} rejected your refund request for "${self.data.offer_ad_title}".`;
        case "brand.offer:refund-ineligible":
          return `AInfluencer has declined your refund request for "${self.data.offer_ad_title}".`;
        case "brand.offer:force-refunded":
          break;
        default:
          console.error("Invalid notification type " + self.data.event);
          return "";
      }
    },

    /**
    * this method get Icon
    */
    getIcon() {
      if (
        self.data.event === "brand.offer:refund-request-approved" ||
        self.data.event === "brand.offer:cancel-request-approved" ||
        self.data.event === "brand.offer:force-refunded" ||
        self.data.event === "brand.offer:force-cancelled" ||
        self.data.event === "brand.offer:accepted" ||
        self.data.event === "brand.offer:created" ||
        self.data.event === "brand.offer:paid" ||
        self.data.event === "brand.publish-job:published" ||
        self.data.event === "publish-job:published" ||
        self.data.event === "publish-job:completed" ||
        self.data.event === "brand.publish-job:completed" ||
        self.data.event === "brand.publish-job:completed" ||
        self.data.event === "brand.publish-job:transferred" ||
        self.data.event === "publish-job:transferred"
      ) {
        return "success";
      } else if (
        self.data.event === "brand.publish-job:publishing-failed" ||
        self.data.event === "publish-job:publishing-failed" ||
        self.data.event === "brand.publish-job:completion-failed" ||
        self.data.event === "publish-job:completion-failed" ||
        self.data.event === "brand.publish-job:health-failed" ||
        self.data.event === "publish-job:health-failed" ||
        self.data.event === "brand.offer:cancelled-intime" ||
        self.data.event === "brand.offer:cancel-request-rejected" ||
        self.data.event === "brand.offer:cancel-ineligible" ||
        self.data.event === "brand.offer:late-cancelled" ||
        self.data.event === "brand.offer:refund-request-rejected" ||
        self.data.event === "brand.offer:refund-ineligible"
      ) {
        return "fail";
      } else if (self.data.event === "offer:updated") {
        return "update";
      } else if (self.data.event === "brand.integration:health-failed") {
        return "disconnect";
      }
    },

    /**
    * this method get Redirect Url
    */
    getRedirectUrl() {
      if (self.data.event.includes("ad")) {
        return "/app/ads";
      } else if (self.data.event.includes("offer")) {
        let id = self.data.offer_link
          ? self.data.offer_link.split("/")[
              self.data.offer_link.split("/").length - 1
            ]
          : null;
        return id ? `/app/offers/${id}` : "/app/offers";
      } else if (self.data.event.includes("publish-job")) {
        return "/app/scheduler";
      }
    },

    /**
    * this method get Notification Count Down
    */
    getNotificationCountDown() {
      let arr = countDown(moment.utc(self.created_at).local()).split(" ");
      return arr[1] ? arr[0] + arr[1].substring(0, 1) : "";
    },
    isRed() {
      return self.read_at ? true : false;
    },
  }));

export interface INotificationModel
  extends Instance<typeof NotificationModel> {}

export interface INotificationModelSnapshotIn
  extends SnapshotIn<typeof NotificationModel> {}

export interface INotificationModelSnapshotOut
  extends SnapshotOut<typeof NotificationModel> {}
