import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import Scheduler from "./Scheduler";
import ScheduleNewContentPage from "./ScheduleNewContentPage";
import InvestigatePublishJob from "./InvestigatePublishJob";

export default ({ match }: RouteComponentProps) => (
  <Switch>
    <Route
      path={`${match.url}/investigate/:id`}
      component={InvestigatePublishJob}
    />
    <Route
      path={`${match.url}/schedule-content/:id`}
      component={ScheduleNewContentPage}
    />
    <Route
      path={`${match.url}/schedule-content`}
      component={ScheduleNewContentPage}
    />
    <Route path={`${match.url}/`} component={Scheduler} />
    <Redirect to="/error" />
  </Switch>
);
