import {
  applySnapshot,
  flow,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import moment from "moment";

import Transport from "../lib/Transport";
import { ajaxErrorAlert, momentDateTimeFormat } from "../lib/Utils";
import { makeAdQuery, AdListQuery, adNewCustomQuery } from "./common/RequestHelpers";
import { AdModel, IAdModel, IAdModelSnapshotIn } from "./models/AdModel";
import BatchTransport from "../lib/BatchTransport";
import RootStore from "./RootStore";
import { Schedule } from "../views/app/ads/components/ScheduleSelectFormik";
import { FilterGroup, Pagination } from "../lib/QueryTypes";
import { isStaging } from "../constants/defaultValues";

/**
* this store mobix for ads 
*/

export const AdStore = types
  .model({
    ads: types.array(AdModel),
  })

  .volatile((self) => ({
    isBusy: false,
    pagination: {
      totalPages: null,
      page: 0,
      pageSize: 10,
    },
    fetchedFromServer: false,
    filterSet: null,
    selectedAd: null,
  }))
  .actions((self) => ({

    /**
    * this method check processing for this store
    */
    setBusy() {
      self.isBusy = true;
    },

    /**
    * this method set filters ads
    * @param filterSet , this param get filters
    * @returns set filterSet this store
    */
    setFilters(filterSet: any) {
      self.filterSet = filterSet;
    },

    /**
    * this method just set value isBusy = false
    */
    setIdle() {
      self.isBusy = false;
    },

    /**
    * this method push model this store
    * @param model, this param type object for get model
    */
    pushModel(model: IAdModel) {
      self.ads.push(model);
    },

    /**
    * this method just clear ads
    */
    clearAds() {
      self.ads.clear();
    },

    /**
    * this method handle pagination this store
    * @param pagination, this param type object for get pagination and set this store
    */
    setPagination(pagination: Pagination) {
      self.pagination = pagination;
    },

    /**
     * this method clear ads & offers & Invitations & ContentList
     */
    clearAdsData() {
      self.ads.clear();
      RootStore.invitationStore.clearInvitations();
      RootStore.offerStore.clearOffers();
      RootStore.publishJobStore.clear();
      RootStore.invitationStore.clearInvitations();
      RootStore.contentStore.clearContentList();
    },
  }))
  .actions((self) => ({
    syncMissingModels(models: IAdModelSnapshotIn[]) {
      if (!Array.isArray(models)) {
        return;
      }
      let adIdList: number[] = self.ads.map((content) => {
        return content.id;
      });

      let possiblyUpdatedModels = models.filter(
        (model) => adIdList.indexOf(model.id) >= 0
      );
      if (possiblyUpdatedModels.length) {
        for (let content of self.ads) {
          for (let updatedContent of possiblyUpdatedModels) {
            if (content.id === updatedContent.id) {
              applySnapshot(content, updatedContent);
            }
          }
        }
      }

      /**
      * this method replace data ads this store
      */
      self?.ads?.replace(
        self?.ads?.concat(
          models
            .filter((model) => adIdList.indexOf(model.id) === -1)
            .uniqueById()
        )
      );
    },

    /**
    * this method set ad
    * @param id, this param get id ad
    */
    setAd(id: string | number) {
      self.selectedAd = id;
    },
  }))
  .actions((self) => ({

    /**
    * this method create new ad
    */
    create: flow(function* (snapshot: any): Generator<any, IAdModel, any> {
      self.setBusy();
      try {
        let result: any = null;

        try {
          if (snapshot.schedule) {
            let batch = new BatchTransport();
            batch.post(
              `campaigns/${snapshot.campaign_id}/ads`,
              {
                ad: snapshot,
                register_step: true
              },
              makeAdQuery()
            );
            for (let schedule_item of snapshot.schedule) {
              batch.post("ads/{{$ref:0.ad.id}}/time-tables", {
                time_table: schedule_item,

              });
            }

            result = yield batch.all();
            // select created ad
            const id = result?.data?.ad?.id
            localStorage.setItem('created_ad_id', id)
          } else {
            result = yield Transport.post(
              `campaigns/${snapshot.campaign_id}/ads`,
              {
                ad: snapshot,
              },
              makeAdQuery()
            );
            // select created ad
            const id = result?.data?.ad?.id
            localStorage.setItem('created_ad_id', id)
          }
          if (!isStaging()) {
            //@ts-ignore
            ga('send', {
              hitType: 'event',
              eventCategory: 'Brand',
              eventAction: 'Ad Created',
              eventLabel: '',
            });
          }
        } catch (e) {
          self.setIdle();
          throw e;
        }

        let model;

        if (snapshot.schedule) {
          for (let i = 0; i < snapshot.schedule.length; i++) {
            snapshot.schedule[i].ad_id = result.data[i + 1]["time_table"].ad_id;
            snapshot.schedule[i].id = result.data[i + 1]["time_table"].id;
          }
          model = AdModel.create({
            ...result.data[0]["ad"],
            time_tables: snapshot.schedule,
          });
        } else {
          model = AdModel.create(result.data["ad"]);
        }
        self.ads.push(model);
        self.setAd(model.id);
        self.setIdle();
        return model;
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),

    /**
    * this method update ad
    * @param id, this param id ad for update
    */
    update: flow(function* (id: number, snapshot: any) {
      let model: IAdModel = self.ads.filter((ad) => ad.id === id)[0];

      if (!RootStore.users.currentUser.is_admin) {
        if (snapshot.approval === "approved") delete snapshot.approval;
        delete snapshot.rejection_reason;
        delete snapshot.admin_note;
        delete snapshot.status;
        delete snapshot.invite_only;
      }

      try {
        self.setBusy();
        self.setAd(id);

        let result: any = null;
        let newScheduleListIds: number[];
        let expiredScheduleList: number[] = [];

        if (snapshot.schedule !== null || snapshot.schedule !== undefined) {
          newScheduleListIds = snapshot.schedule.map(
            (schedule: Schedule) => schedule.id
          );
        }

        // check for exists of expired time table
        if (model.time_tables && model.time_tables.length > 0) {
          model.time_tables.forEach((timeTable) => {
            if (
              (timeTable.start &&
                (timeTable.start === timeTable.end ||
                  timeTable.end === null)) ||
              (timeTable.start &&
                timeTable.end &&
                timeTable.start !== timeTable.end)
            ) {
              expiredScheduleList.push(timeTable.id);
            }
          });
        }

        let requestCount = 0,
          totalCreated = 0;

        try {
          let batch = new BatchTransport();

          if (snapshot.schedule || expiredScheduleList.length > 0) {
            // delete removed timetables
            model.time_tables
              .filter(
                (timetable) =>
                  newScheduleListIds.indexOf(timetable.id) === -1 ||
                  expiredScheduleList.includes(timetable.id)
              )
              .forEach((timeTable) => {
                batch.delete(`time-tables/` + timeTable.id);
                requestCount++;
              });

            // insert new time tables
            // for (let schedule_item of snapshot.schedule.filter(
            //   (schedule: Schedule) => schedule.id === null
            // )) {
            for (let schedule_item of snapshot.schedule) {
              batch.post(`ads/${id}/time-tables`, {
                time_table: schedule_item,
              });
              requestCount++;
              totalCreated++;
            }

            delete snapshot.schedule;

            batch.patch(`ads/${id}`, { ad: snapshot }, makeAdQuery());
            result = yield batch.all();
          } else {
            result = yield Transport.patch(
              `ads/${id}`,
              { ad: snapshot },
              makeAdQuery()
            );
          }
        } catch (e) {
          self.setIdle();
          throw e;
        }

        if (requestCount > 0) {
          let timeTables = model.time_tables.filter(
            (timeTable) =>
              newScheduleListIds.includes(timeTable.id) &&
              !expiredScheduleList.includes(timeTable.id)
          );

          for (
            let index = requestCount - 1;
            index >= requestCount - totalCreated;
            index--
          ) {
            timeTables.push(result.data[index].time_table);
          }

          let newModel = result.data[requestCount].ad;
          newModel.time_tables = timeTables;
          applySnapshot(model, newModel);
        } else {
          applySnapshot(model, result.data[requestCount].ad);
        }

        self.setIdle();
      } catch (e) {
        self.setIdle();
        throw e;
      }
    }),

    /**
    * this method check exist ad
    * @param id, this param id ad for check exist
    */
    getExistingAd(id: number) {
      if (self.ads.filter((ad) => ad.id === id).length > 0) {
        return self.ads.filter((ad) => ad.id === id)[0];
      }
      throw new Error("Could not find ad with id" + id);
    },
    getAdById: flow(function* (id: number): Generator<any, IAdModel, any> {
      if (self.ads.filter((ad) => ad.id === id).length > 0) {
        if (
          self.ads.filter((ad) => ad.id === id)[0].time_tables !== undefined
        ) {
          return self.ads.filter((ad) => ad.id === id)[0];
        }
      }

      self.setBusy();
      try {
        let response = yield Transport.get(`ads/${id}`, makeAdQuery(true));

        RootStore.campaignStore.syncMissingModels(response.data["campaign"]);
        RootStore.contentStore.syncMissingModels(response.data["content"]);
        if (response.data["campaign"]) {
          response.data["campaign"].forEach((campaign: any) => {
            RootStore.identityStore.syncMissingModels([campaign.identity]);
            RootStore.fileStore.syncMissingModels([campaign.identity.logo]);
          });
        }
        if (self.ads.filter((ad) => ad.id === id).length > 0) {
          applySnapshot(
            self.ads.filter((ad) => ad.id === id)[0],
            response.data["ad"]
          );
        } else {
          self.ads.push(AdModel.create(response.data["ad"]));
        }

        self.setIdle();

        return self.ads.filter((ad) => ad.id === id)[0];
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),
    getAdByIdInfo: flow(function* (id: number): Generator<any, IAdModel, any> {
      self.setBusy();
      try {
        let response = yield Transport.get(`ads/${id}`, makeAdQuery(true));
        const ad_info = {
          invite_only: response.data["ad"]?.invite_only || false,
          limit_country: response.data["ad"]?.limit_country || [],
          limit_gender: response.data["ad"]?.limit_gender || null,
          ad_hashtags: response.data["content"][0]?.data?.caption?.match(/#\w+/g)?.map(item => item.substring(1))?.map(item => `#${item}`)?.join(' ') || ''
        }
        localStorage.setItem('ad_info', JSON.stringify(ad_info))
        RootStore.campaignStore.syncMissingModels(response.data["campaign"]);
        RootStore.contentStore.syncMissingModels(response.data["content"]);

        if (response.data["campaign"]) {
          response.data["campaign"].forEach((campaign: any) => {
            RootStore.identityStore.syncMissingModels([campaign.identity]);
            RootStore.fileStore.syncMissingModels([campaign.identity.logo]);
          });
        }
        if (self.ads.filter((ad) => ad.id === id).length > 0) {
          applySnapshot(
            self.ads.filter((ad) => ad.id === id)[0],
            response.data["ad"]
          );
        } else {
          self.ads.push(AdModel.create(response.data["ad"]));
        }

        self.setIdle();

        return self.ads.filter((ad) => ad.id === id)[0];
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),

    /**
    * this method fetch all ad
    */
    fetchAll: flow(function* (): Generator<any, IAdModel[], any> {
      self.setBusy();
      try {
        let response = yield Transport.get(`ads/created`, makeAdQuery(true));

        RootStore.campaignStore.syncMissingModels(response.data["campaign"]);
        RootStore.contentStore.syncMissingModels(response.data["content"]);
        RootStore.invitationStore.syncMissingModels(
          response.data["invitations"]
        );

        self.syncMissingModels(response.data["ads"]);
        self.setIdle();
        return self.ads.filter((ad) => ad.deleted_at === null);
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),

    /**
    * this method clear ads and fetch all ads
    * @param pagination, this param object for pagination ads
    * @param filters_group, this param object for filter ads
    * @param SortOrder, this param object for sort ads
    */
    fetchAllAds: flow(function* (
      pagination: { page: number; pageSize: number },
      filters_group: FilterGroup[],
      SortOrder
    ): any {
      self.setBusy();
      try {
        self.clearAds();
        RootStore.invitationStore.clearInvitations();
        RootStore.identityStore.clearIdentities();
        RootStore.offerStore.clearOffers();
        RootStore.contentStore.clearContentList();
        RootStore.users.clearUsers();
        let response = yield Transport.get(
          "ads",
          AdListQuery(false, false, pagination, filters_group, SortOrder)
        );
        self.syncMissingModels(response.data["ads"]);

        RootStore.campaignStore.syncMissingModels(response.data["campaign"]);
        RootStore.contentStore.syncMissingModels(response.data["content"]);

        if (response.data["campaign"]) {
          response.data["campaign"].forEach((campaign: any) => {
            RootStore.identityStore.syncMissingModels([campaign.identity]);
            if (campaign.identity.user) {
              RootStore.users.syncMissingModels([campaign.identity.user]);
            }
          });
        }

        self.setIdle();
        return {
          totalPages: response.data.meta.page_count,
          page: response.data.meta.page,
        };
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),

    /**
    * this method get brand ads
    * @param pagination, this param object for pagination ads
    * @param filters_group, this param object for filter ads
    * @param SortOrder, this param object for sort ads
    */
    fetchAllAdsData: flow(function* (
      pagination: { page: number; pageSize: number },
      filters_group: FilterGroup[],
      SortOrder,
      loadMore: boolean = false
    ): any {
      try {
        if (!self.fetchedFromServer || loadMore) {
          if (!loadMore) self.setBusy();

          // let response = yield Transport.get(
          //   "ads/created",
          //   AdListQuery(false, false, pagination, filters_group, SortOrder)
          // );

          let newresponse = yield Transport.get('v1/advertising/index', adNewCustomQuery(pagination))

          // self.fetchedFromServer = true;

          self.syncMissingModels(newresponse.data.ads);

          newresponse.data.ads.forEach((ads: any) => {
            RootStore.campaignStore.syncMissingModels([ads.campaign])
            RootStore.contentStore.syncMissingModels([ads.content])
          })

          //RootStore.campaignStore.syncMissingModels(response.data["campaign"]);
          //RootStore.contentStore.syncMissingModels(response.data["content"]);
          // RootStore.invitationStore.syncMissingModels(
          //   response.data["invitations"]
          // );
          //self.syncMissingModels(response.data["ads"]);

          self.setIdle();
          self.setPagination({
            totalPages: newresponse.data.meta.page_count,
            page: newresponse.data.meta.page,
            pageSize: newresponse.data.meta.limit,
          });
          return {
            totalPages: newresponse.data.meta.page_count,
            page: newresponse.data.meta.page,
          };
        }
        return self.pagination;
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),

    /**
    * this method get ad by id
    * @param id, this param for get ad by this id
    * @param includeInvitations, this param for include Invitations
    * @param SortOrder, this param for include Offers
    */
    getAdByIdForAdminPanel: flow(function* (
      id: number,
      includeInvitations: boolean,
      includeOffers: boolean
    ) {
      self.setBusy();

      try {
        let response = yield Transport.get(
          `ads/${id}`,
          AdListQuery(includeInvitations, includeOffers)
        );

        self.syncMissingModels(response.data["ads"]);

        RootStore.identityStore.syncMissingModels(
          response.data["offers.identity"]
        );
        if (response.data["offers.identity"]) {
          response.data["offers.identity"].forEach((identity: any) => {
            RootStore.fileStore.syncMissingModels([identity.logo]);
          });
        }

        RootStore.identityStore.syncMissingModels(
          response.data["offers.target_identity"]
        );
        RootStore.campaignStore.syncMissingModels(response.data["campaign"]);
        RootStore.contentStore.syncMissingModels(response.data["content"]);
        RootStore.invitationStore.syncMissingModels(
          response.data["invitations"]
        );

        if (response.data["invitations.target"]) {
          response.data["invitations.target"].forEach((identity: any) => {
            if (identity.user) {
              RootStore.users.syncMissingModels([identity.user]);
            }
          });

          response.data["invitations.target"].forEach((identity: any) => {
            RootStore.integrationStore.syncMissingModels(identity.integrations);
          });

          RootStore.identityStore.syncMissingModels(
            response.data["invitations.target"].map((identity: any) => {
              return {
                ...identity,
                integrations: identity.integrations.map((item: any) => item.id),
                user: identity.user ? identity.user.id : null,
              };
            })
          );
        }

        RootStore.offerStore.syncMissingModels(response.data["offers"]);
        RootStore.contentStore.syncMissingModels(
          response.data["offers.content"]
        );
        RootStore.integrationStore.syncMissingModels(
          response.data["offers.target_integration"]
        );

        if (response.data["campaign"]) {
          response.data["campaign"].forEach((campaign: any) => {
            RootStore.identityStore.syncMissingModels([campaign.identity]);
            RootStore.fileStore.syncMissingModels([campaign.identity.logo]);
            RootStore.users.syncMissingModels([campaign.identity.user]);
          });
        }

        self.setIdle();
      } catch (e) {
        self.setIdle();
        throw e;
      }
    }),

    /**
    * this method for edit ad
    * @param AdId, this param for edit ad this id
    * @param values, this param value edit
    */
    editAd: flow(function* (AdId: number, values: any): any {
      self.setBusy();
      try {
        let response = yield Transport.patch(
          `ads/${AdId}`,
          { ad: values },
          makeAdQuery()
        );

        applySnapshot(
          RootStore.adStore.ads.find((ad: IAdModel) => ad.id === AdId),
          response.data.ad
        );
        self.setIdle();
      } catch (e) {
        self.setIdle();
        throw e;
      }
    }),

    /**
    * this method for remove ad
    * @param AdId, this param get id for remove the ad
    */
    removeAd: flow(function* (id: number): any {
      self.setBusy();
      try {
        yield Transport.delete(`ads/${id}`);
        let removedAd = RootStore.adStore.ads.filter(
          (ad: any) => ad.id === id
        )[0];
        let ads = RootStore.adStore.ads.filter((ad: any) => ad.id !== id);

        removedAd.deleted_at = moment(moment.now()).format(
          momentDateTimeFormat
        );

        ads.push(removedAd);

        RootStore.adStore.ads.replace(ads);

        self.setIdle();
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),

    /**
    * this method for update ad
    * @param id, this param for update ad this id
    */
    updateAd: flow(function* (id: number): any {
      self.setBusy();
      try {
        let updateAd = RootStore.adStore.ads.find((ad: any) => ad.id === id);
        if (updateAd.status === "paused") {
          yield Transport.patch(`ads/${id}`, { ad: { status: "active" } });
          updateAd.status = "active";
        } else {
          yield Transport.patch(`ads/${id}`, { ad: { status: "paused" } });
          updateAd.status = "paused";
        }
        let ads = RootStore.adStore.ads.filter((ad: any) => ad.id !== id);

        ads.push(updateAd);

        RootStore.adStore.ads.replace(ads);
        self.setIdle();
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),

    updateInviteAd: flow(function* (id: number): any {
      self.setBusy();
      try {
        let updateAd = RootStore.adStore.ads.find((ad: any) => ad.id === id);
        if (updateAd.invite_only) {
          yield Transport.patch(`ads/${id}`, { ad: { invite_only: false } });
          updateAd.invite_only = false;
        } else {
          yield Transport.patch(`ads/${id}`, { ad: { invite_only: true } });
          updateAd.invite_only = true;
        }
        let ads = RootStore.adStore.ads.filter((ad: any) => ad.id !== id);

        ads.push(updateAd);

        RootStore.adStore.ads.replace(ads);
        self.setIdle();
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),
  }))
  .actions((self) => ({

    /**
    * this method for fetch all ads force
    */
    fetchAllIfNecessary: flow(function* (): Generator<any, IAdModel[], any> {
      if (!self.fetchedFromServer) {
        try {
          self.fetchedFromServer = true;
          return yield self.fetchAll();
        } catch (e) {
          ajaxErrorAlert("Failed to load ads please refresh the page.");
          throw e;
        }
      }
      return self.ads.filter((ad) => ad.deleted_at === null);
    }),
  }))
  .views((self) => ({

    /**
    * this method for get ad by campaign id
    * @param campaignId, this param campaign Id
    */
    getAdsByCampaign(campaignId: number): IAdModel[] {
      return self.ads
        .filter((ad: IAdModel) => ad.campaign_id.deleted_at === null)
        .filter((ad: IAdModel) => ad.campaign_id.id === campaignId);
    },

    /**
    * this method for get all ads
    */
    getAllAds(): IAdModel[] {
      return self.ads.filter(
        (ad: IAdModel) =>
          ad.deleted_at === null && ad.campaign_id.deleted_at === null
      );
    },

    getExistingAdById(invitationId: number): IAdModel {
      return self.ads.filter((ad) => ad.id === invitationId)[0];
    },
  }));

export interface IAdStore extends Instance<typeof AdStore> { }
export interface IAdStoreSnapshotIn extends SnapshotIn<typeof AdStore> { }
export interface IAdStoreSnapshotOut extends SnapshotOut<typeof AdStore> { }
