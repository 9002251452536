import React from "react";
import moment from "moment";
import { truncate } from "lodash";

import { IOfferModel } from "../../store/models/OfferModel";
import { momentDateTimeFormat } from "../../lib/Utils";

interface WelcomeProps {
  offer: IOfferModel;
}

export default function OfferDetails(props: WelcomeProps) {
  const { offer } = props;

  return (
    <div className="offerDetails">
      <ul>
        <ul>
          <li>
            <img
              alt={"offer content"}
              src={offer.content_id.getPreviewImage(310, 200)}
            />
            <h3 style={{fontSize: '.85rem'}}><strong>{truncate(offer.ad_id.title, { length: 305 })}</strong></h3>
            <p style={{fontSize: '.85rem'}}>{truncate(offer.ad_id.description, { length: 45 })}</p>
          </li>
          <li>
            <span className="schedule-icon" />
            At:{" "}
            {offer.start ? (
              <b>
                {moment(offer.start, momentDateTimeFormat).format(
                  "MMMM Do YYYY, h:mm a"
                )}
              </b>
            ) : (
              <b>Unspecified</b>
            )}
          </li>
          <li>
            <span className="duration-icon" />
            Duration:{" "}
            <b>
              {offer.duration
                ? moment
                    .duration(offer.duration as number, "seconds")
                    .humanize()
                : "Unspecified"}
            </b>
          </li>
          <li>
            <span className="bio-link-icon" />
            Bio Link:{" "}
            {offer.bio_link ? (
              <a
                href={`${offer.bio_link}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {truncate(offer.bio_link, { length: 40 })}
              </a>
            ) : (
              <b>Unspecified</b>
            )}
          </li>
          {/* <li>
            <span className="exclusivity-icon" />
            Exclusive for:{" "}
            <b>
              {offer.exclusivity
                ? moment.duration(offer.exclusivity as number, "seconds").humanize()
                : "Unspecified"}
            </b>
          </li> */}
        </ul>
      </ul>
    </div>
  );
}
