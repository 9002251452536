import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { truncate } from "lodash";

import {
  AbstractFilterSet,
  IFilter,
  IRangeFilter,
  FilterType,
} from "./AbstractFilterSet";

import DateRangeFormik from "../DateRangeFormik";
import moment from "moment";
import { SearchIcon } from "../../../assets/icons";
import classnames from "classnames";

interface Props {
  filterSet: AbstractFilterSet;
  search?: boolean;
  values?: object;
  changeDate?: (value: any) => void;
  clearFilter?: () => void
}

interface State {
  openStatus: { [key: string]: boolean };
  filterValuesJSON: string;
}

export default class FilterComponent<T> extends React.Component<Props, State> {
  public static defaultProps = {
    search: true,
    values: {},
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      openStatus: {},

      filterValuesJSON: Object.keys(props.values).length
        ? JSON.stringify(props.values)
        : JSON.stringify(props.filterSet.getFilterValues()),
    };
    for (let filter of this.props.filterSet.getFilters()) {
      this.state.openStatus[filter.name] = false;
    }
  }

  toggleDateRangeModal = (name: string) => {
    this.setState((prevState) => ({
      openStatus: {
        ...prevState.openStatus,
        [name]: !prevState.openStatus[name],
      },
    }));
  };
  setRangeFilterValue(name: string, value: any) {
    this.props.filterSet.setFilterValue(name, value);
    this.setState({
      filterValuesJSON: JSON.stringify(this.props.filterSet.getFilterValues()),
    });
    // range date mathod for download list
    this.props.changeDate?.(value);
  }

  render() {
    return (
      <div className="filterComponent">
        <div
          className={classnames("filterSet", {
            "filterSet--right": !this.props.search,
          })}
        >
          {this.props.search && (
            <div className="searchBox">
              <SearchIcon />
              <input
                type="text"
                name="keyword"
                id="search"
                placeholder="search in name or email or username"
                autoComplete="off"
                defaultValue={null}
                onChange={(e) => {
                  this.props.filterSet.handleSearchEvent(e);
                  this.setState({
                    filterValuesJSON: JSON.stringify(
                      this.props.filterSet.getFilterValues()
                    ),
                  });
                }}
              />
            </div>
          )}
          <div className="filters pt-3">
            {this.props.filterSet.getFilters().map((filter) => {
              if (filter.type === FilterType.simpleFilter) {
                let iFilter = filter as IFilter;
                return (
                  <ButtonDropdown
                    key={`filter__${iFilter.name}`}
                    className="mr-3 mb-0"
                    isOpen={this.state.openStatus[iFilter.name]}
                    toggle={() =>
                      this.setState((prevState: any) => {
                        prevState.openStatus[iFilter.name] = !prevState
                          .openStatus[iFilter.name];
                        prevState.filterValuesJSON = JSON.stringify(
                          this.props.filterSet.getFilterValues()
                        );
                        return prevState;
                      })
                    }
                  >
                    <DropdownToggle
                      caret
                      size="xs"
                      outline
                      className="offerListDD"
                      color=""
                    >
                      <div className="title">{iFilter.title}</div>
                      {truncate(
                        this.props.filterSet.getSelectedValueLabel(iFilter),
                        {
                          length: 16,
                        }
                      )}
                    </DropdownToggle>
                    <DropdownMenu>
                      <Scrollbars
                        autoHeight={true}
                        autoHeightMax={400}
                        renderTrackHorizontal={(props) => (
                          <div
                            {...props}
                            style={{ display: "none" }}
                            className="track-horizontal"
                          />
                        )}
                      >
                        {iFilter
                          .values()
                          .map((value: string | number, index: number) => {
                            return <DropdownItem
                              key={`${filter.name}_filter_${index}`}
                              onClick={(event: any) => {
                                this.props.filterSet.setFilterValue(
                                  filter.name,
                                  value
                                );
                                this.setState({
                                  filterValuesJSON: JSON.stringify(
                                    this.props.filterSet.getFilterValues()
                                  ),
                                });
                              }}
                            >
                              {this.props.filterSet.getValueLabel(
                                filter,
                                value
                              )}
                            </DropdownItem>
                          })}
                      </Scrollbars>
                    </DropdownMenu>
                  </ButtonDropdown>
                );
              } else {
                let iFilter = filter as IRangeFilter;
                return (
                  <div
                    key={`filter__${iFilter.name}`}
                    className="mr-3 mb-0 btn-group"
                  >
                    <button
                      className="offerListDD dropdown-toggle btn btn-outline- btn-xs"
                      onClick={() => {
                        this.toggleDateRangeModal(iFilter.name);
                      }}
                    >
                      <div className="title">{iFilter.title}</div>
                      {this.props.filterSet.getFilterValues().filters[
                        iFilter.name
                      ]
                        ? `${moment(
                          this.props.filterSet.getFilterValues().filters[
                            iFilter.name
                            //@ts-ignore
                          ].start
                        ).format("YYYY-MM-DD")} -> ${moment(
                          this.props.filterSet.getFilterValues().filters[
                            iFilter.name
                            //@ts-ignore
                          ].end
                        ).format("YYYY-MM-DD")}`
                        : `All`}
                    </button>
                    {this.state.openStatus[iFilter.name] ? (
                      <DateRangeFormik
                        toggle={this.toggleDateRangeModal}
                        onChange={this.setRangeFilterValue.bind(this)}
                        isOpen={this.state.openStatus[iFilter.name]}
                        name={iFilter.name}
                      />
                    ) : null}
                  </div>
                );
              }
            })}

            {JSON.stringify(this.props.filterSet.getFilterValues()) !==
              JSON.stringify(this.props.filterSet.getDefaultFilterValues()) ? (
              <p
                className="offersList_clearFilter"
                onClick={() => {
                  this.props.filterSet.loadDefaultFilters();
                  this.setState({
                    filterValuesJSON: JSON.stringify(
                      this.props.filterSet.getFilterValues()
                    ),
                  });
                  this.props.clearFilter()
                }}
              >
                Clear Filters
              </p>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
