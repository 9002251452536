import React, { Component } from "react";
import { FormGroup, Label, Button, Spinner } from "reactstrap";
import { Form, Formik, Field } from "formik";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import numbro from "numbro";
import Cleave from "cleave.js/react";
import { getIdentifier } from "mobx-state-tree";
import CardValidator from "card-validator";
import { truncate } from "lodash";

import ApplicationMenu from "../../../template/components/common/ApplicationMenu";
import { Separator } from "../../../template/components/common/CustomBootstrap";
import {
  FormikCheckbox,
  FormikReactSelect,
  BreadCrumb,
} from "../../common-components";
import { InformationIcon, TrustPayIcon } from "../../../assets/icons";
import { IOfferModel } from "../../../store/models/OfferModel";
import RootStore from "../../../store/RootStore";
import {
  ajaxErrorAlert,
  handleError,
  momentDateTimeFormat,
  isMobileAndTablet,
} from "../../../lib/Utils";
import { CustomIndicator } from "../account/wallet/AddFund";
import { NotificationManager } from "../../../template/components/common/react-notifications";
import ContentPreview from "../content-manager/components/preview/ContentPreview";
import profileImg from "../../../assets/img/profilePlaceholder.jpg";
import classNames from "classnames";
import axios from 'axios'
import { servicePath, isStaging } from './../../../constants/defaultValues';

const PaymentSchema = Yup.object().shape({
  payment_method: Yup.object().required(),
  terms: Yup.bool().oneOf(
    [true],
    "You must accept AInfluencer terms and conditions!"
  ),
  number: Yup.string().when("payment_method", (method: any, schema: any) => {
    if (method.value === "new") {
      return schema.required("Card number is required!");
    }
  }),
  cvc: Yup.string().when("payment_method", (method: any, schema: any) => {
    if (method.value === "new") {
      return schema
        .matches(/^[0-9]{3,4}$/, "Must be exactly 3 digits")
        .required("CVC is required.")
        .min(3, "Must be exactly 3 digits")
        .max(4, "Must be exactly 4 digits");
    }
  }),
  exp_month: Yup.string().when("payment_method", (method: any, schema: any) => {
    if (method.value === "new") {
      return schema
        .required("Card expire month is required!")
        .min(2, "Enter a valid month expire!")
        .max(2, "Enter a valid month expire!");
    }
  }),
  exp_year: Yup.number().when("payment_method", (method: any, schema: any) => {
    if (method.value === "new") {
      return schema
        .required("Card expire year is required!")
        .min(
          new Date().getFullYear() - 2000,
          `Card expire year must be greater than or equal to ${
            new Date().getFullYear() - 2000
          }`
        )
        .max(99, "Card expire year must be lower than or equal to 99");
    }
  }),
});

interface State {
  offer: IOfferModel | null;
  offerLoaded: boolean;
  displayWarning: boolean;
  loading: boolean;
}

export default class PayOfferPage extends Component<
  RouteComponentProps<{
    id: string;
  }>,
  State
> {
  public url = profileImg;
  constructor(props: any) {
    super(props);

    this.state = {
      offer: null,
      offerLoaded: false,
      displayWarning: false,
      loading: false
    };

    this.setDisplayWarning = this.setDisplayWarning.bind(this);
    this.onCreditCardChange = this.onCreditCardChange.bind(this);
    this.onCreditCardFocus = this.onCreditCardFocus.bind(this);
  }

  async componentDidMount(): Promise<void> {
    try {
      let offer: IOfferModel = await RootStore.offerStore.getOffer(
        parseInt(this.props.match.params.id)
      );
      if (!this.state.offerLoaded) {
        await this.setState({
          offer,
          offerLoaded: true,
        });
      }
    } catch (e) {
      handleError(e);
      ajaxErrorAlert("Could not load offer data!");
    }
  }

  onCreditCardChange(event: any) {}

  onCreditCardFocus(event: any) {
    // update some state
  }

  setDisplayWarning = () => {
    this.setState((prevState) => ({
      displayWarning: !prevState.displayWarning,
    }));
  };

  getDefaultPaymentOption() {
    if (this.state.offer.price <= RootStore.users.currentUser.wallet.credits) {
      return {
        value: null,
        label: `Use Wallet Credit (Total: ${numbro(
          RootStore.users.currentUser.wallet.credits
        )
          .formatCurrency({
            mantissa: 2,
            thousandSeparated: true,
            optionalMantissa: true,
          })
          .toUpperCase()})`,
      };
    } else {
      if (
        RootStore.users.currentUser.wallet.default_payment_method_id === null
      ) {
        return {
          value: "new",
          label: "New Credit Card",
        };
      } else {
        return {
          value: getIdentifier(
            RootStore.users.currentUser.wallet.default_payment_method_id
          ),
          label:
            "**** **** **** " +
            RootStore.users.currentUser.wallet.default_payment_method_id.data
              .last4,
        };
      }
    }
  }

  getPaymentMethodOptions() {
    let options = [];

    if (this.state.offer.price <= RootStore.users.currentUser.wallet.credits) {
      options.push({
        value: null,
        label: `Use Wallet Credit (Total: ${numbro(
          RootStore.users.currentUser.wallet.credits
        )
          .formatCurrency({
            mantissa: 2,
            thousandSeparated: true,
            optionalMantissa: true,
          })
          .toUpperCase()})`,
      });
    }

    // if (this.state.offer.price > RootStore.users.currentUser.wallet.credits) {
      for (let method of RootStore.users.currentUser.wallet.methods) {
        options.push({
          value: method.id,
          label: "**** **** **** " + method.data.last4,
        });
      }
    // }

    options.push({
      value: "new",
      label: "New Credit Card",
    });

    return options;
  }

  render() {
    return !this.state.offerLoaded ? (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner
          color="primary"
          size={"lg"}
          style={{ verticalAlign: "middle" }}
        />
      </div>
    ) : (
      <>
        <BreadCrumb
          heading="Influencer Payment"
          {...this.props}
          optionalProperties={[
            {
              title: this.state.offer.ad_id.title,
              value: this.props.match.params.id,
              id: ":id(\\d+)",
            },
          ]}
        />
        <Separator className="mb-5" />

        {this.state.offer ? (
          <div className="payOffer">
            <div className="payOffer__paymentWrapper">
              <div className="payOffer__payment">
                <h2>Congrats!</h2>
                <p>
                  The offer between you and{" "}
                  <b>{this.state.offer.getOpponentIdentity().name}</b> has been
                  finalized. Please pay the agreed upon rate of{" "}
                  {numbro(this.state.offer.price)
                    .formatCurrency({
                      mantissa: 2,
                      thousandSeparated: true,
                      optionalMantissa: true,
                    })
                    .toUpperCase()}{" "}
                  to ensure the post is published on{" "}
                  <a
                    href={`https://instagram.com/${
                      this.state.offer.getTargetIntegration().username
                    }/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <b>@{this.state.offer.getTargetIntegration().username}</b>
                  </a>
                  .
                </p>

                <Formik
                  initialValues={{
                    amount: 0,
                    payment_method: this.getDefaultPaymentOption(),
                    number: "",
                    cvc: "",
                    exp_month: "",
                    exp_year: "",
                    terms: true,
                  }}
                  validationSchema={PaymentSchema}
                  onSubmit={async (values, formikHelpers) => {
                    this.setState({loading:true})
                    if (!this.state.offer.isComplete()) {
                      ajaxErrorAlert(
                        "Can not start payment for an offer which is not complete! Get back and finalize all items..."
                      );
                      return;
                    }

                    if (this.state.offer.status === "paid") {
                      ajaxErrorAlert("This offer is already Paid!");
                      this.props.history.push(
                        `/app/offers/${this.state.offer.id}`
                      );
                      return;
                    }

                    if (values.payment_method.value === "new") {

                      values.number = values.number
                        .split(" ")
                        .join("")
                        .split("-")
                        .join("");
                      // @ts-ignore
                      values.exp_year = parseInt(`20${values.exp_year}`);
                      // @ts-ignore
                      values.exp_month = parseInt(values.exp_month);

                      this.setState({loading:true});
                      if (CardValidator.number(values.number).isValid) {
                        try {
                          const payWithNewCard = () =>{
                            const headers = {
                              headers: {
                                Authorization: "Bearer " + RootStore.users.getToken(),
                              }
                            }
                            const body_1 ={
                                payment_method: {
                                    type: 'card',
                                    default: true,
                                    data:  {
                                        number: values.number,
                                        cvc: values.cvc,
                                        exp_month: values.exp_month,
                                        exp_year: values.exp_year,
                                      },
                                },
                            }
                           
                            const walletID = RootStore?.users?.currentUser?.wallet?.id
                            const { pathname } = window.location
                            const offerID = pathname.substring(12,pathname.length - 4)

                            const sendCardInfo = axios.post(`${servicePath}/wallets/${walletID}/methods`,body_1,headers)
                            sendCardInfo.then((res)=>{
                              const paymentID = res?.data?.payment_method?.id
                              const body_2 = {
                                  transaction: {
                                      method_id: paymentID,
                                  },
                              }
                              axios.post(`${servicePath}/offers/${offerID}/pay`,body_2,headers).then(()=>{
                                  NotificationManager.success(
                                    "Done",
                                    `Card added and payment was successful!`,
                                    3000,
                                    null,
                                    null,
                                    "filled"
                                    );
                                  this.setState({loading:false});
                                  this.props.history.push(
                                    `/app/offers`
                                  );
                              }).catch((err)=> {
                                this.setState({loading:false})
                                const msg = err?.response?.data?.message || ''
                                ajaxErrorAlert(
                                  `There was a problem , ${msg}`
                                );
                              })
                            }).catch((err)=>{
                              this.setState({loading:false})
                              const msg = err?.response?.data?.message || ''
                                ajaxErrorAlert(
                                  `There was a problem , ${msg}`
                                );
                            }).finally(()=>this.setState({loading:false}))

                            if (!isStaging()) {
                              //@ts-ignore
                              ga('send', {
                                  hitType: 'event',
                                  eventCategory: 'Brand',
                                  eventAction: 'Payment Made',
                                  eventLabel: '',
                              });
                            }
                          }

                          payWithNewCard()

                          // await RootStore.offerStore.pay(this.state.offer, {
                          //   number: values.number,
                          //   cvc: values.cvc,
                          //   exp_month: values.exp_month,
                          //   exp_year: values.exp_year,
                          // });
          
                          // NotificationManager.success(
                          //   "Done",
                          //   `Card added and payment was successful!`,
                          //   3000,
                          //   null,
                          //   null,
                          //   "filled"
                          // );
                          // this.props.history.push(
                          //   `/app/offers/${this.state.offer.id}`
                          // );
                        } catch (e) {
                          // console.log(e);
                          // console.log(e.message);
                          this.setState({loading:false});
                          if (
                            typeof e.response !== "undefined" &&
                            e.response.status === 403
                          ) {
                            this.setState({loading:false})
                            ajaxErrorAlert(
                              `The payment process can not start because there is a problem with the connection between @${
                                this.state.offer.getTargetIntegration().username
                              } and our automated post scheduling system! Please ask ${
                                this.state.offer.getIdentity().name
                              } to check his account settings and fix the errors and then try again.`
                            );
                          } else {
                            this.setState({loading:false})
                            handleError(e);
                            ajaxErrorAlert(
                              `There was a problem starting payment!, ${e.message}`
                            );
                          }
                        }
                      } else {
                        this.setState({loading:false});
                        ajaxErrorAlert(
                          "Invalid credit card number! Please check the credit card number that you entered."
                        );
                      }
                    } else {
                      this.setState({loading:true});
                      try {
                        await RootStore.offerStore.pay(
                          this.state.offer,
                          values.payment_method.value
                        );
                        NotificationManager.success(
                          "Done",
                          `The payment was successful and your ad will go live automatically according to it's schedule...`,
                          3000,
                          null,
                          null,
                          "filled"
                        );
                        this.setState({loading:false});
                        this.props.history.push("/app/offers");
                      } catch (e) {
                        console.log(e);
                        console.log(e.message);
                        this.setState({loading:false});
                        if (
                          typeof e.response !== "undefined" &&
                          e.response.status === 403
                        ) {
                          ajaxErrorAlert(
                            `The payment process can not start because there is a problem with the connection between @${
                              this.state.offer.getTargetIntegration().username
                            } and our automated post scheduling system! Please ask ${
                              this.state.offer.getTargetIdentity().name
                            } to check his account settings and fix the errors and then try again.`
                          );
                        } else {
                          this.setState({loading:false})
                          handleError(e);
                          console.log(e);
                          ajaxErrorAlert("Oops. The payment failed.");
                        }
                      }
                    }
                  }}
                >
                  {({
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    isSubmitting,
                  }) => 
                    {
                    return <Form
                      translate={"no"}
                      className="av-tooltip tooltip-label-right panel-form mt-5"
                    >
                      <FormGroup className="error-l-100 w-100">
                        <Label>Select Payment Method:</Label>
                        <FormikReactSelect
                          formatGroupLabel={(data: any) => (
                            <div style={{ fontSize: 16 }}>
                              <h4>{data.label}</h4>
                            </div>
                          )}
                          name="payment_method"
                          a
                          components={{
                            Option: CustomIndicator,
                          }}
                          options={this.getPaymentMethodOptions()}
                          value={values.payment_method}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                        />
                      </FormGroup>
                      {values.payment_method.value !== null &&
                      RootStore.users.currentUser.wallet.credits > 0 &&
                      RootStore.users.currentUser.wallet.credits <
                        this.state.offer.price ? (
                        <p style={{ color: "red" }}>
                          $
                          {RootStore.users.currentUser.wallet.credits.toFixed(
                            2
                          )}{" "}
                          will be paid from your existing balance in your
                          wallet, and $
                          {(
                            this.state.offer.price -
                            RootStore.users.currentUser.wallet.credits
                          ).toFixed(2)}{" "}
                          will be charged from your credit card.
                        </p>
                      ) : null}
                      {values.payment_method.value === "new" ? (
                        <div style={{ width: "100%" }}>
                          <FormGroup className="error-l-100 mt-4">
                            <Label className="position-relative">
                              Card Number:
                              {errors.number && touched.number && (
                                <div className="invalidFeedback">
                                  {errors.number}
                                </div>
                              )}
                            </Label>
                            <Cleave
                              className="form-control"
                              name="number"
                              placeholder="Enter your credit card number"
                              options={{ creditCard: true, delimiter: " - " }}
                              onFocus={(ev) => {
                                setFieldTouched("number");
                              }}
                              onChange={(ev) => {
                                setFieldValue(
                                  "number",
                                  ev.target.value
                                    .replace("-", "")
                                    .replace(" ", "")
                                );
                              }}
                            />
                          </FormGroup>
                          <div className="d-flex justify-content-between align-items-center mt-4">
                            <FormGroup className="error-l-100 w-60">
                              <Label className="position-relative">
                                Exp Date:
                                {errors.exp_month && touched.exp_month && (
                                  <div className="invalidFeedback">
                                    {errors.exp_month}
                                  </div>
                                )}
                                {errors.exp_year && touched.exp_year && (
                                  <div className="invalidFeedback">
                                    {errors.exp_year}
                                  </div>
                                )}
                              </Label>
                              <div className="d-flex justify-content-between">
                                <Cleave
                                  className="form-control w-45"
                                  name="exp_month"
                                  placeholder="MM"
                                  options={{ date: true, datePattern: ["m"] }}
                                  onFocus={(ev) => {
                                    setFieldTouched("Singleexp_month");
                                  }}
                                  maxLength={2}
                                  onChange={(ev) => {
                                    setFieldValue("exp_month", ev.target.value);
                                  }}
                                />
                                <Cleave
                                  className="form-control w-45"
                                  name="exp_year"
                                  placeholder="YY"
                                  options={{ date: true, datePattern: ["y"] }}
                                  onFocus={(ev) => {
                                    setFieldTouched("exp_year");
                                  }}
                                  onChange={(ev) => {
                                    setFieldValue("exp_year", ev.target.value);
                                  }}
                                />
                              </div>
                            </FormGroup>

                            <FormGroup className="error-l-100 w-30">
                              <Label className="position-relative">
                                CVC:
                                {errors.cvc && touched.cvc && (
                                  <div className="invalidFeedback">
                                    {errors.cvc}
                                  </div>
                                )}
                              </Label>
                              <Field
                                className="form-control"
                                name="cvc"
                                placeholder="CVC"
                                type="text"
                                maxLength={4}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      ) : null}
                      <FormGroup className="error-l-150">
                        <Label className="">
                          {errors.terms && touched.terms && (
                            <div className="invalidFeedback">
                              {errors.terms}
                            </div>
                          )}
                        </Label>
                        <FormikCheckbox
                          name="terms"
                          value={values.terms}
                          label={
                            <p className="termsAgreement">
                              By making payment you agree to the deal{" "}
                              <a
                                href="https://ainfluencer.com/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                terms and conditions
                              </a>
                              .
                            </p>
                          }
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                        />
                      </FormGroup>
                      &nbsp;
                      <br />
                      <Button
                        color="success"
                        type="submit"
                        disabled={this.state.loading || !values.terms}
                      >
                        <span>
                          Pay{" "}
                          {numbro(this.state.offer.price)
                            .formatCurrency({
                              mantissa: 2,
                              thousandSeparated: true,
                              optionalMantissa: true,
                            })
                            .toUpperCase()}
                        </span>
                        {this.state.loading && (
                          <Spinner
                            color="light"
                            size={"sm"}
                            style={{ marginLeft: "10px" }}
                          />
                        )}
                      </Button>
                      <div>
                        <TrustPayIcon /> Secured by <b>TrustPay</b>
                      </div>
                    </Form>
}}
                </Formik>
              </div>

              <div className="payOffer__blockqoute">
                <p>How does AInfluencer secure your influencer transactions?</p>
                <ul>
                  <li>
                    The integrated scheduler ensures your publish criteria are
                    met
                  </li>
                  <li>We only release payment once your criteria are met</li>
                  <li>
                    We help resolve disputes based on the agreement made with
                    influencers
                  </li>
                </ul>
                <div className="infoBox mt-4">
                  <InformationIcon />
                  <p>
                    Your payment is secured by AInfluencer TrustPay. Funds are
                    kept on hold until the post is published and agreed upon
                    deal criteria are met.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={classNames("payOffer__contentPreview", {
                "show-mobile": !this.state.displayWarning,
              })}
            >
              {!isMobileAndTablet() ? (
                <div className="offer-sidebar">
                  <div className="offer-heading">
                    <div className="proposer">
                      <div className="chat">
                        <div className="profile">
                          <img
                            style={{ width: "50px", height: "50px" }}
                            src={
                              this.state.offer.getIdentity().logo
                                ? this.state.offer
                                    .getIdentity()
                                    .logo.getThumbnailUrl(50, 50)
                                : this.url
                            }
                            alt={"Profile"}
                          />
                        </div>
                        <div className="message">
                          <b>
                            {this.state.offer.getIdentity().name.length > 15
                              ? `${this.state.offer
                                  .getIdentity()
                                  .name.slice(0, 14)}...`
                              : this.state.offer.getIdentity().name}
                          </b>
                          <span>
                            @{this.state.offer.getTargetIntegration().username}
                          </span>
                        </div>
                      </div>
                      <div className="money">
                        {this.state.offer.price
                          ? numbro(this.state.offer.price)
                              .formatCurrency({
                                mantissa: 2,
                                thousandSeparated: true,
                                optionalMantissa: true,
                              })
                              .toUpperCase()
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className={"details"}>
                    <ul>
                      <li>
                        <span className="schedule-icon" />
                        At:{" "}
                        {this.state.offer.start ? (
                          <b>
                            {moment(
                              this.state.offer.start,
                              momentDateTimeFormat
                            ).format("MMMM Do YYYY, h:mm a")}
                          </b>
                        ) : (
                          <b>Unspecified</b>
                        )}
                      </li>
                      <li>
                        <span className="duration-icon" />
                        Duration:{" "}
                        <b>
                          {this.state.offer.duration
                            ? moment
                                .duration(
                                  this.state.offer.duration as number,
                                  "seconds"
                                )
                                .humanize()
                            : "Permanent"}
                        </b>
                      </li>

                      {/* <li>
                            <span className="exclusivity-icon" />
                            Exclusive for:{" "}
                            <b>
                              {this.state.offer.duration
                                ? moment
                                    .duration(this.state.offer.exclusivity as number, "seconds")
                                    .humanize()
                                : "Unspecified"}
                            </b>
                          </li> */}
                      <li>
                        <span className="bio-link-icon" />
                        Bio Link:{" "}
                        {this.state.offer.bio_link ? (
                          <a
                            href={`${this.state.offer.bio_link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {truncate(this.state.offer.bio_link, {
                              length: 20,
                            })}
                          </a>
                        ) : (
                          <b>N/A</b>
                        )}
                      </li>
                    </ul>
                  </div>

                  <div className="payOffer__contentDetails">
                    {this.state.offer.content_id ? (
                      <ContentPreview
                        onboardingPreview={false}
                        style={{ float: "left" }}
                        model={this.state.offer.content_id}
                        postData={this?.state?.offer}
                      />
                    ) : (
                      <>
                        No content Specified!
                        <br />
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <ApplicationMenu
                  isOpen={this.state.displayWarning}
                  toggleOpenHandler={this.setDisplayWarning}
                >
                  <div className="offer-sidebar">
                    <div className="offer-heading">
                      <div className="proposer">
                        <div className="chat">
                          <div className="profile">
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={
                                this.state.offer.getIdentity().logo
                                  ? this.state.offer
                                      .getIdentity()
                                      .logo.getThumbnailUrl(50, 50)
                                  : this.url
                              }
                              alt={"Profile"}
                            />
                          </div>
                          <div className="message">
                            <b>
                              {this.state.offer.getIdentity().name.length > 15
                                ? `${this.state.offer
                                    .getIdentity()
                                    .name.slice(0, 14)}...`
                                : this.state.offer.getIdentity().name}
                            </b>
                            <span>
                              @
                              {this.state.offer.getTargetIntegration().username}
                            </span>
                          </div>
                        </div>
                        <div className="money">
                          {this.state.offer.price
                            ? numbro(this.state.offer.price)
                                .formatCurrency({
                                  mantissa: 2,
                                  thousandSeparated: true,
                                  optionalMantissa: true,
                                })
                                .toUpperCase()
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className={"details"}>
                      <ul>
                        <li>
                          <span className="schedule-icon" />
                          At:{" "}
                          {this.state.offer.start ? (
                            <b>
                              {moment(
                                this.state.offer.start,
                                momentDateTimeFormat
                              ).format("MMMM Do YYYY, h:mm a")}
                            </b>
                          ) : (
                            <b>Unspecified</b>
                          )}
                        </li>
                        <li>
                          <span className="duration-icon" />
                          Duration:{" "}
                          <b>
                            {this.state.offer.duration
                              ? moment
                                  .duration(
                                    this.state.offer.duration as number,
                                    "seconds"
                                  )
                                  .humanize()
                              : "Unspecified"}
                          </b>
                        </li>

                        {/* <li>
                              <span className="exclusivity-icon" />
                              Exclusive for:{" "}
                              <b>
                                {this.state.offer.duration
                                  ? moment
                                      .duration(this.state.offer.exclusivity as number, "seconds")
                                      .humanize()
                                  : "Unspecified"}
                              </b>
                            </li> */}
                        <li>
                          <span className="bio-link-icon" />
                          Bio Link:{" "}
                          {this.state.offer.bio_link ? (
                            <a
                              href={`${this.state.offer.bio_link}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {truncate(this.state.offer.bio_link, {
                                length: 20,
                              })}
                            </a>
                          ) : (
                            <b>Unspecified</b>
                          )}
                        </li>
                      </ul>
                    </div>

                    <div className="payOffer__contentDetails">
                      {this.state.offer.content_id ? (
                        <ContentPreview
                          onboardingPreview={false}
                          style={{ float: "left" }}
                          model={this.state.offer.content_id}

                        />
                      ) : (
                        <>
                          No content Specified!
                          <br />
                        </>
                      )}
                    </div>
                  </div>
                </ApplicationMenu>
              )}
            </div>
          </div>
        ) : (
          <div>
            <Spinner color="primary" className="mb-1" />
            <p>Please wait...</p>
          </div>
        )}
      </>
    );
  }
}
