import { Instance, SnapshotIn, SnapshotOut, types, flow } from 'mobx-state-tree';
import { HashtagModel, IHashtagModel } from './models/HashtagModel';
import { ajaxErrorAlert } from '../lib/Utils';
import Transport from '../lib/Transport';

export const HashtagsStore = types
    .model({
        hashtags: types.array(HashtagModel),
    })
    .volatile((self) => ({
        isBusy: false,
        fetchedFromServer: false,
    }))
    .actions((self) => ({

        /**
        * this method check processing for this store
        */
        setBusy() {
            self.isBusy = true;
        },

        /**
        * this method just set value isBusy = false
        */
        setIdle() {
            self.isBusy = false;
        },
    }))
    .actions((self) => ({

        /**
        * this method fetch all ad
        * @param identityId, this param identity Id for get identities
        */
        fetchAll: flow(function* (identityId: number) {
            self.setBusy();
            try {
                let response = yield Transport.get(`identities/${identityId}/hashtags`);
                self.hashtags.replace(response.data['identity_hashtags']);
                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),
    }))
    .actions((self) => ({

        /**
        * this method for fetch all hashtags force
        */
        fetchAllIfNecessary: flow(function* (identityId: number): Generator<any, IHashtagModel[], any> {
            if (!self.fetchedFromServer) {
                try {
                    self.fetchedFromServer = true;
                    return yield self.fetchAll(identityId);
                } catch (e) {
                    ajaxErrorAlert('Failed to load ads please refresh the page.');
                    throw e;
                }
            }
            return self.hashtags;
        }),
    }))
    .actions((self) => ({

        /**
        * this method for search hashtags
        * @param searchTerm, this param search parameters
        */
        searchHashtags: flow(function* (searchTerm: string, n: number = 15) {
            const response = yield Transport.get(`categories/hashtag/${searchTerm}`);
            return response;
        }),

        /**
        * this method for update hashtag
        * @param hashtagList, this param for get hashtags model
        */
        updateHashtags(hashtagList: IHashtagModel[]): void {
            //for (let hashtag of hashtagList) {
            self.hashtags.replace(hashtagList);
            //}
        },
    }))
    .views((self) => ({}));

export interface IHashtagsStore extends Instance<typeof HashtagsStore> { }
export interface IHashtagsStoreSnapshotIn extends SnapshotIn<typeof HashtagsStore> { }
export interface IHashtagsStoreSnapshotOut extends SnapshotOut<typeof HashtagsStore> { }
