/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

export default {
    /* 01.General */
    'general.copyright': 'Ainfluencer © 2021 All Rights Reserved.',

    /* 02.User Login, Logout, Register */
    'user.login-title': 'Login',
    'user.login-description': 'Please use your credentials to login.',
    'user.login-register-description': 'If you are not a member, please',
    'user.login-register-cta': 'click here to register',
    'user.register-title': 'Register',
    'user.register-description': 'Please use this form to register. ',
    'user.register-login-description': 'If you are a member, please',
    'user.register-login-cta': 'click here to login',
    'user.forgot-password': 'Forgot Password',
    'user.forgot-password-description': 'Please use your e-mail to reset your password.',
    'user.email': 'E-mail',
    'user.password': 'Password',
    'user.forgot-password-question': 'Forgot password?',
    'user.firstName': 'First Name',
    'user.lastName': 'Last Name',
    'user.passwordConfirmation': 'Re-enter your password',
    'user.login-button': 'LOGIN',
    'user.register-button': 'Register',
    'user.reset-password-button': 'RESET PASSWORD',
    'user.buy': 'BUY',
    'user.username': 'Username',
    'user.changePassword-button': 'Change my password',

    /* 03.Menu */
    'menu.app': 'Home',
    'menu.dashboard': 'Dashboard',
    'menu.offers': 'Offers',
    'menu.start': 'Dashboard',
    'menu.explorer': 'Find Influencers',
    'menu.content-manager': 'Content Library',
    'menu.user': 'My Account',
    'menu.users-list': 'Users List',
    'menu.ads-management': 'Ads Management',
    'menu.task-manager': 'Task Manager',
    'menu.publish-jobs-report': 'Publish Jobs Report',
    'menu.payments': 'Payments and Payouts',
    'menu.transactions': 'Transactions',
    'menu.ai': 'AI',
    'menu.templates': 'Templates',
    'menu.charts': 'Charts',
    'menu.ads': 'Ads',
    'menu.integrations': 'Instagram Accounts',
    'menu.payment': 'Payment & Transactions',
    'menu.change-password': 'Change Password',
    'menu.edit-account': 'Edit Account',
    'menu.search': 'Search',
    'menu.scheduler': 'Scheduler',
    'menu.blank-page': 'Blank Page',
    'menu.new': 'New Post',
    'menu.account': 'Account',
    'menu.new-integrations': 'Add IG Account',

    'dashboards.calendar': 'Publish Schedule',
    'explorer.orderby': 'Order by ',
    'todo.display-options': 'Options',
    /* 04.Error Page */
    'pages.error-title': 'Ooops... looks like an error occurred!',
    'pages.error-code': 'Error code',
    'pages.go-back-home': 'GO BACK HOME',
};
