import React from "react";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import moment from "moment";

interface Props {
  id: string;
  series: any[];
  minTimeStamp: number;
}

interface State {
  selection: string;
  series: any[];
  options: any;
}

/**
 * class components set first day , first week , first month on chart 
 * 
 */
export default class Chart extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      series: this.props.series,
      options: {
        chart: {
          id: this.props.id,
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        annotations: {
          // yaxis: [{
          //     y: 30,
          //     borderColor: '#999',
          //     label: {
          //         show: true,
          //         text: 'Average in Fashion Design',
          //         style: {
          //             color: "#fff",
          //             background: '#00E396'
          //         }
          //     }
          // }],
          // xaxis: [{
          //     x: new Date('31 Nov 2012').getTime(),
          //     borderColor: '#999',
          //     yAxisIndex: 0,
          //     label: {
          //         show: true,
          //         text: 'Best',
          //         style: {
          //             color: "#fff",
          //             background: '#775DD0'
          //         }
          //     }
          // }]
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          min: this.props.minTimeStamp,
          tickAmount: 6,
          datetimeUTC: false,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy - HH:mm",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      },

      selection: "all",
    };
  }

  /**
   * 
   * @param timeline , string that get functon and throw in switch case
   */
  updateData(timeline: string) {
    this.setState({
      selection: timeline,
    });

    let start = moment(this.props.minTimeStamp);
    let endTimeStamp =
      this.props.series[0].data[this.props.series[0].data.length - 1][0];
    switch (timeline) {
      case "first-day":
        ApexCharts.exec(
          this.props.id,
          "zoomX",
          start.toDate().getTime(),
          // @ts-ignore
          Math.min(endTimeStamp, start.endOf("day").toDate().getTime())
        );
        break;
      case "first-week":
        ApexCharts.exec(
          this.props.id,
          "zoomX",
          start.toDate().getTime(),
          // @ts-ignore
          Math.min(endTimeStamp, start.endOf("week").toDate().getTime())
        );
        break;
      case "first-month":
        ApexCharts.exec(
          this.props.id,
          "zoomX",
          start.toDate().getTime(),
          // @ts-ignore
          Math.min(endTimeStamp, start.endOf("month").toDate().getTime())
        );
        break;
      case "all":
        ApexCharts.exec(
          this.props.id,
          "zoomX",
          this.props.minTimeStamp,
          // @ts-ignore
          endTimeStamp
        );
        break;
      default:
    }
  }

  render() {
    return (
      <div className="chart-body">
        <div className="toolbar">
          <button
            id={this.props.id + "-first-day"}
            onClick={() => this.updateData("first-day")}
            className={this.state.selection === "first-day" ? "active" : ""}
          >
            First Day
          </button>
          &nbsp;
          <button
            id={this.props.id + "-first-week"}
            onClick={() => this.updateData("first-week")}
            className={this.state.selection === "first-week" ? "active" : ""}
          >
            First Week
          </button>
          &nbsp;
          <button
            id={this.props.id + "-first-month"}
            onClick={() => this.updateData("first-month")}
            className={this.state.selection === "first-month" ? "active" : ""}
          >
            First Month
          </button>
          &nbsp; &nbsp;
          <button
            id={this.props.id + "-all"}
            onClick={() => this.updateData("all")}
            className={this.state.selection === "all" ? "active" : ""}
          >
            ALL
          </button>
        </div>

        <div id="chart-timeline">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    );
  }
}
