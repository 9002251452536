import React from "react";
import { Badge, Button, Form, Label } from "reactstrap";
import Swal from "sweetalert2";
import { Link, RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";

import Logo from "../../assets/img/logo.png";
import IntlMessages from "../../template/helpers/IntlMessages";

import Transport from "../../lib/Transport";
import { deleteCookies } from "../../store/UserStore";

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("You need to enter your email address"),
});

interface Props extends RouteComponentProps {}

export default function ForgotPassword(props: Props) {
  return (
    <div className="authForm">
      <div className="authForm__side">
        <h1>Forgot Password</h1>
        <p>
          Enter the email address associated with your account We will email you
          a link to reset your password.
        </p>
        <br />
        <p>
          If you are not a member, please
          <Link to={`/register`}> click here to register</Link>.
        </p>
      </div>

      <Formik
        initialValues={{ email: "" }}
        validationSchema={ResetPasswordSchema}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          Transport.post("auth/password/forgot", {
            email: values.email,
          })
            .then(() => {
              deleteCookies();
              Swal.fire({
                type: "success",
                title: "Done!",
                text: `An email with password reset instructions was sent to 
            ${values.email}. Please continue from there...`,
                confirmButtonClass: "forgotPasswordOkButton",
              });
              actions.setSubmitting(false);
            })
            .catch((err) => {
              Swal.fire({
                type: "error",
                title: "Error!",
                text: `We could not find an account with this email (${values.email})! 
                 Please try again, and if the issue persists, contact support.`,
              });
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="authForm__form">
            <div>
              <a
                href={"https://ainfluencer.com"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Logo} alt="ainfluencer" />
              </a>
              <Label className="form-group has-float-label mb-4">
                <Field className="form-control" type="email" name="email" />
                <ErrorMessage
                  name="email"
                  component={Badge}
                  // @ts-ignore
                  color="danger"
                  pill
                  className="mb-1"
                />
                <IntlMessages id="user.email" />
              </Label>

              <div className="authForm__form_actions">
                <Link to="/login">Login</Link>
                <Button
                  color="primary"
                  className="btn-shadow"
                  size="lg"
                  type={"submit"}
                  disabled={isSubmitting}
                >
                  Send
                </Button>
              </div>
            </div>

            <div className="termsAndPrivacyPolicy">
              <p>&copy; {new Date().getFullYear()} Ainfluencer</p>
              <a
                href="https://ainfluencer.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://ainfluencer.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
