import React from "react";
import classNames from "classnames";
import { Button } from "reactstrap";
import { observer } from "mobx-react";
import moment from "moment";
import { NavLink } from "react-router-dom";
import numbro from "numbro";

import {
  IMessageModel,
  MessageType,
} from "../../../../store/models/MessageModel";
import AdThumbnail from "./OfferBubble/AdThumbnail";
import OfferSpecs from "./OfferBubble/OfferSpecs";
import BubbleFooter from "./OfferBubble/BubbleFooter";
import RootStore from "../../../../store/RootStore";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import { IOfferModel } from "../../../../store/models/OfferModel";
import {
  ClockIcon,
  ExclamationIcon,
  SuccessIcon,
  WarningIcon,
} from "../../../../assets/icons";
import { countDown, momentDateTimeFormat } from "../../../../lib/Utils";
import { isStaging } from "../../../../constants/defaultValues";

type Props = {
  msg: IMessageModel;
  offerButtons: Function;
  performAction: Function;
  postData?: any
};

@observer
export default class OfferBubble extends React.Component<Props, any> {
  private static bubbleCounter: number = 0;

  constructor(props: Props) {
    super(props);
    OfferBubble.bubbleCounter++;
  }

  getMainOffer(): IOfferModel {
    return RootStore.offerStore.getExistingModel(this.props.msg.offer_id);
  }

  isCollapsed(): boolean {
    // initial offer is not collapsed
    // latest version is not collapsed
    // it's a status update
    // it's a publishjob message

    if (
      this.props.msg.getType() === MessageType.PUBLISH_JOB ||
      this.props.msg.getType() === MessageType.STATUS_UPDATE
    ) {
      return true;
    }

    if (
      this.props.msg.getType() === MessageType.STORY_OFFER ||
      this.props.msg.getType() === MessageType.POST_OFFER
    ) {
    }

    return (
      !this.props.msg.isLatestVersion() && !this.props.msg.isFirstVersion()
    );
  }

  hasFooter(): boolean {
    if (this.isCollapsed() === false) {
      if (
        this.props.msg.isLatestVersion() &&
        (this.props.msg.getMainOffer().status === "awaiting-payment" ||
          this.props.msg.getMainOffer().status === "paid")
      ) {
        return (
          this.props.msg.getOffer().getLatestPublishJob() !== null &&
          this.props.msg.getOffer().getLatestPublishJob().isFailed()
        );
      }

      return true;
    }

    return false;
  }

  hasSidebar(): boolean {
    if (
      this.props.msg.getType() === MessageType.PUBLISH_JOB ||
      this.props.msg.getType() === MessageType.STATUS_UPDATE
    ) {
      return false;
    }

    if (this.props.msg.isLatestVersion()) {
      return (
        this.props.msg.getMainOffer().status === "paid" ||
        this.props.msg.getMainOffer().status === "awaiting-payment" ||
        (this.props.msg.getMainOffer().getLatestPublishJob() !== null &&
          !this.props.msg.getOffer().getLatestPublishJob().isFailed())
      );
    }
    return false;
  }

  getPrePaymentSideBar(): JSX.Element {
    switch (this.getMainOffer().getExtensiveStatus()) {
      case "cancelled":
        if (this.getMainOffer().nullification_status === "forced") {
          return (
            <div className={"side"}>
              <ExclamationIcon />
              The deal has been cancelled{" "}
              <small>
                Our compliance team has ruled in your favor, and{" "}
                {numbro(this.getMainOffer().price)
                  .formatCurrency({ mantissa: 2, thousandSeparated: true })
                  .toUpperCase()}{" "}
                is applied to your wallet.
              </small>
              <br />
              <Button
                size={"xs"}
                color={"info"}
                onClick={() => {
                  this.props.performAction("counter");
                }}
              >
                Revise the offer
              </Button>
            </div>
          );
        } else {
          return (
            <div className={"side"}>
              <ExclamationIcon />
              The deal has been cancelled{" "}
              <small>
                {numbro(this.getMainOffer().price)
                  .formatCurrency({ mantissa: 2, thousandSeparated: true })
                  .toUpperCase()}{" "}
                is applied to your wallet.
              </small>
              <br />
              <Button
                size={"xs"}
                color={"info"}
                onClick={() => {
                  this.props.performAction("counter");
                }}
              >
                Revise the offer
              </Button>
            </div>
          );
        }
      default:
        return (
          <div className={"side"}>
            <b>
              {numbro(this.props.msg.getMainOffer().price)
                .formatCurrency({
                  mantissa: 2,
                  thousandSeparated: true,
                  optionalMantissa: true,
                })
                .toUpperCase()}
            </b>
            <div className={"accepted"}>
              (Accepted By{" "}
              {this.props.msg.getReactor().user_id ===
              RootStore.users.currentUser.id
                ? "You"
                : this.props.msg.getReactor().name}
              )
            </div>
            <Button
              size={"xs"}
              color={"success"}
              onClick={() => {
                this.props.performAction("pay");
              }}
            >
              Make A Payment
            </Button>
            <small>
              {/* * Payment will not be distributed to the influencer until the post
              is successfully published and deal terms are met.  */}
              * When you make the payment,{" "}
              <small>
                <b>
                  @{this.props.msg.getOffer().getTargetIntegration().username}{" "}
                </b>
              </small>
              will have to publish the post according to the agreed terms. When
              the post is published, Ainfluencer monitors its performance and
              makes sure it has followed all terms. Your payment only will be
              released to the influencer after the post was published
              successfully.
            </small>
          </div>
        );
    }
  }

  getPostPaymentSideBar(): JSX.Element {
    switch (this.getMainOffer().getExtensiveStatus()) {
      case "cancel-request":
        return (
          <div className={"side"}>
            <WarningIcon />
            You requested to cancel this offer{" "}
            <small>
              A request to refund your payment has been submitted to @
              {this.getMainOffer().getTargetIntegration().username}.
            </small>
          </div>
        );
      case "cancel-request-rejected":
        return (
          <div className={"side"}>
            <WarningIcon />
            Cancel request was rejected{" "}
            <small>
              @{this.getMainOffer().getTargetIntegration().username} rejected
              your request.
            </small>
            <br />
            <Button
              size={"xs"}
              color={"danger"}
              onClick={() => {
                this.props.performAction("cancel-conflict");
              }}
            >
              Log a Dispute
            </Button>
          </div>
        );
      case "cancel-conflict":
        return (
          <div className={"side"}>
            <WarningIcon />
            Your dispute has been sent to Ainfluencer for review.{" "}
            <small>A resolution will be provided shortly.</small>
          </div>
        );
      case "cancel-ineligible":
        return (
          <div className={"side"}>
            <WarningIcon />
            Unfortunately the dispute for cancellation of this offer has been
            ruled in favour of @
            {this.props.msg.getMainOffer().getTargetIntegration().username}{" "}
            <small>
              Your post will go live on @
              {this.props.msg.getMainOffer().getTargetIntegration().username} in{" "}
              {countDown(
                moment(
                  this.props.msg.getMainOffer().start,
                  momentDateTimeFormat
                ) as any
              )}
            </small>
          </div>
        );
      default:
        if (
          this.props.msg.getMainOffer().getLatestPublishJob().status ===
          "expired"
        ) {
          return (
            <div className={"side"}>
              <ExclamationIcon />
              Publish Failed!{" "}
              <small>
                Unfortunately the post failed to be published on the
                influencer’s page. Fees will be credited to your Wallet.
              </small>
            </div>
          );
        } else if (
          this.props.msg.getMainOffer().getLatestPublishJob().status ===
          "failed"
        ) {
          return (
            <div className={"side"}>
              <ExclamationIcon />
              Publish Failed!{" "}
              <small>
                Unfortunately the post failed to be published on the
                influencer’s page. Fees will be credited to your Wallet.
              </small>
            </div>
          );
        } else {
          return (
            <div className={"side"}>
              <ClockIcon />
              {moment(this.props.msg.getMainOffer().start).isAfter(moment.now())
                ? "In " +
                  countDown(
                    moment(
                      this.props.msg.getMainOffer().start,
                      momentDateTimeFormat
                    ) as any
                  )
                : "Completed"}{" "}
              <small>
                Congratulations! Your post will go live on @
                {this.props.msg.getMainOffer().getTargetIntegration().username}{" "}
                …
              </small>
            </div>
          );
        }
    }
  }

  getPostPublishSideBar(): JSX.Element {
    switch (this.getMainOffer().getExtensiveStatus()) {
      case "refund-request":
        break;
      case "refund-request-rejected":
        break;
      case "refunded":
        break;
      case "refund-conflict":
        break;
      case "refund-ineligible":
        break;
      default:
        if (
          this.props.msg.getOffer().getLatestPublishJob().state === "published"
        ) {
          if (
            this.props.msg.getOffer().getLatestPublishJob().status === "failed"
          ) {
            return (
              <div className={"side"}>
                <ExclamationIcon />
                <b>Ad Is Deleted!</b>{" "}
                <small>
                  Our system could not verify existence of the ad on @
                  {
                    this.props.msg.getMainOffer().getTargetIntegration()
                      .username
                  }
                </small>
              </div>
            );
          } else {
            return (
              <div className={"side"}>
                <SuccessIcon />
                <b>Successfully Published!</b>
                <small>
                  The ad is now live in @
                  {
                    this.props.msg.getMainOffer().getTargetIntegration()
                      .username
                  }
                </small>
                <p>
                  <a
                    href={
                      this.props.msg.getMainOffer().getLatestPublishJob()
                        .published_url
                    }
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    View Ad
                  </a>{" "}
                  {!isStaging() && (
                    <>
                      |{" "}
                      <NavLink
                        to={`/app/publish-jobs/${
                          this.props.msg.getMainOffer().getLatestPublishJob().id
                        }/analytics`}
                      >
                        View Analytics
                      </NavLink>
                    </>
                  )}
                </p>
              </div>
            );
          }
        } else if (
          this.props.msg.getOffer().getLatestPublishJob().state === "removal"
        ) {
          if (
            this.props.msg.getOffer().getLatestPublishJob().status === "healthy"
          ) {
            return (
              <div className={"side"}>
                <b>Pending Take-Down!</b>{" "}
                <small>
                  Our system will remove the ad from influencer's account
                  momentarily...
                </small>
              </div>
            );
          } else {
            return (
              <div className={"side"}>
                <ExclamationIcon />
                <b>Removal Failed!</b>{" "}
                <small>
                  We could not remove the ad from the influencer's page due to
                  an error...
                </small>
              </div>
            );
          }
        } else {
          // completed
          if (this.props.msg.getMainOffer().duration === 0) {
            return (
              <div className={"side"}>
                <SuccessIcon />
                <b>The ad is live!</b>
                <small>
                  The ad was published on @
                  {
                    this.props.msg.getMainOffer().getTargetIntegration()
                      .username
                  }
                  , and will stay there permanently.
                </small>
                {!isStaging() && (
                  <p>
                    <NavLink
                      to={`/app/publish-jobs/${
                        this.props.msg.getMainOffer().getLatestPublishJob().id
                      }/analytics`}
                    >
                      View Analytics
                    </NavLink>
                  </p>
                )}
              </div>
            );
          } else {
            return (
              <div className={"side"}>
                <SuccessIcon />
                <b>The Ad Is Taken Down!</b>
                <small>
                  The ad is now removed from @
                  {
                    this.props.msg.getMainOffer().getTargetIntegration()
                      .username
                  }
                </small>
                {!isStaging() && (
                  <p>
                    <NavLink
                      to={`/app/publish-jobs/${
                        this.props.msg.getMainOffer().getLatestPublishJob().id
                      }/analytics`}
                    >
                      View Analytics
                    </NavLink>
                  </p>
                )}
              </div>
            );
          }
        }
    }
  }

  getSidebar(): JSX.Element {
    if (this.props.msg.getOffer().getLatestPublishJob() === null) {
      return this.getPrePaymentSideBar();
    } else if (
      this.props.msg.getOffer().getLatestPublishJob().state === "scheduled"
    ) {
      return this.getPostPaymentSideBar();
    } else {
      // published, removal, completed
      return this.getPostPublishSideBar();
    }
  }

  incomplate(items: any) {
    if (
      items.price === null ||
      items.duration === null ||
      items.start === null
    ) {
      return true;
    }
    return false;
  }

  incomplateText(items: any) {
    if (items.price === null) {
      if (items.duration === null) {
        return "fee and duration ";
      }
      if (items.start === null) {
        return "fee and date/time";
      }
      return "fee";
    }

    if (items.duration === null) {
      if (items.start === null) {
        return "date/time and duration of the post";
      }
      return "duration of the post";
    }

    if (items.start === null) {
      return "date/time";
    }
  }
  getChangeSummary(): JSX.Element {
    let rejectionNotice = "";
    if (this.props.msg.getStatus() === "rejected") {
      let reactor =
        this.props.msg.getReactor().user_id === RootStore.users.currentUser.id
          ? "You"
          : this.props.msg.getReactor().name;
      if (this.props.msg.getAction() === "discarded") {
        rejectionNotice = ` (This version was later discarded by ${reactor})`;
      } else {
        rejectionNotice = ` (This version was later rejected by ${reactor})`;
      }
    }

    return (
      <>
        {this.props.msg.getIdentity().user_id === RootStore.users.currentUser.id
          ? "You"
          : this.props.msg.getIdentity().name}
        &nbsp;
        {this.props.msg.getChangeCaption().trim()}
        {rejectionNotice}
      </>
    );
  }

  getCollapsedContent(): JSX.Element {
    // handle all collapsed types including failed messages
    if (
      this.props.msg.getType() === MessageType.STORY_OFFER ||
      this.props.msg.getType() === MessageType.POST_OFFER
    ) {
      return (
        <>
          {this.getChangeSummary()}
          <span className={"moment"}>
            - {this.props.msg.getMoment().fromNow()}
          </span>
        </>
      );
    } else {
      throw new Error(
        "collapsed content is only available for STORY or POST message types"
      );
    }
  }

  isDanger(): boolean {
    if (
      this.props.msg.getType() === MessageType.POST_OFFER ||
      this.props.msg.getType() === MessageType.STORY_OFFER
    ) {
      if (!this.props.msg.isFresh()) {
        return false;
      }
    }

    if (
      this.props.msg.getType() === MessageType.STATUS_UPDATE &&
      this.props.msg.getStatusUpdateType() === "rejected"
    ) {
      return true;
    }

    if (this.props.msg.getType() === MessageType.PUBLISH_JOB) {
      return (
        this.props.msg.attributes.type === "publish-job:publishing-failed" ||
        this.props.msg.attributes.type === "publish-job:completion-failed"
      );
    }

    if (
      this.props.msg.getType() === MessageType.POST_OFFER ||
      this.props.msg.getType() === MessageType.STORY_OFFER
    ) {
      if (this.props.msg.getStatus() === "rejected") {
        return true;
      }

      if (
        this.props.msg.isLatestVersion() &&
        this.props.msg.getMainOffer().status === "paid"
      ) {
        if (this.props.msg.getMainOffer().getLatestPublishJob() !== null) {
          return this.props.msg.getMainOffer().getLatestPublishJob().isFailed();
        }
      }
    }

    return false;
  }

  isSuccess(): boolean {
    if (
      this.props.msg.getType() === MessageType.POST_OFFER ||
      this.props.msg.getType() === MessageType.STORY_OFFER
    ) {
      if (!this.props.msg.isFresh()) {
        return false;
      }
    }

    if (
      this.props.msg.getType() === MessageType.STATUS_UPDATE &&
      this.props.msg.getStatusUpdateType() === "accepted"
    ) {
      return true;
    }

    if (this.props.msg.getType() === MessageType.PUBLISH_JOB) {
      return (
        this.props.msg.attributes.type === "publish-job:published" ||
        this.props.msg.attributes.type === "publish-job:created"
      );
    }

    if (
      this.props.msg.getType() === MessageType.POST_OFFER ||
      this.props.msg.getType() === MessageType.STORY_OFFER
    ) {
      if (
        this.props.msg.isLatestVersion() &&
        (this.props.msg.getMainOffer().status === "paid" ||
          this.props.msg.getMainOffer().status === "awaiting-payment")
      ) {
        if (this.props.msg.getMainOffer().getLatestPublishJob() !== null) {
          return !this.props.msg
            .getMainOffer()
            .getLatestPublishJob()
            .isFailed();
        }
        return true;
      }

      if (this.props.msg.getStatus() === "accepted") {
        return true;
      }
    }

    return false;
  }

  getSpecsMode(): "revisable" | "final" | "negotiable" {
    if (this.hasSidebar()) {
      if (this.props.msg.getMainOffer().getLatestPublishJob() !== null) {
        if (
          this.props.msg.getMainOffer().getLatestPublishJob().state ===
          "scheduled"
        ) {
          return "revisable";
        }
        return "final";
      } else {
        return "revisable";
      }
    } else {
      return "negotiable";
    }
  }

  getOfferContent(): JSX.Element {
    return (
      <>
        <div className="offer">
          <div className="offer_offerSpecs">
            <AdThumbnail content={this.props.msg.getOffer().content_id} postData={this.props.postData} />
            <OfferSpecs
              offer={this.props.msg.getOffer()}
              mode={this.getSpecsMode()}
              performAction={this.props.performAction}
            />
          </div>
          {this.hasSidebar() ? this.getSidebar() : null}
        </div>
        {this.hasFooter() ? (
          <BubbleFooter
            offerButtons={this.props.offerButtons}
            msg={this.props.msg}
            postData={this.props.postData || null}
          />
        ) : null}
      </>
    );
  }

  getIdentifier(): string {
    if (this.props.msg.getType() === MessageType.PUBLISH_JOB) {
      return "offer-pjm-" + this.props.msg.offer_id + this.props.msg.id;
    } else {
      return (
        "offer-" +
        this.props.msg.offer_id +
        this.props.msg.attributes.data.batch
      );
    }
  }

  isSelf() {
    if (this.props.msg.getType() === MessageType.STATUS_UPDATE) {
      if (this.props.msg.getStatusUpdateType() === "paid") {
        if (this.props.msg.isSelf === false) {
          // force paid message type from brand
          applySnapshot(this.props.msg, {
            ...getSnapshot(this.props.msg),
            isSelf: true,
          });
        }
        return true;
      }
    }
    if (this.props.msg.getType() === MessageType.PUBLISH_JOB) {
      // force all publish job messages from influencer
      if (this.props.msg.isSelf === true) {
        applySnapshot(this.props.msg, {
          ...getSnapshot(this.props.msg),
          isSelf: false,
        });
      }
      return false;
    }
    return this.props.msg.isSelf;
  }

  render() {
    console.log('OfferBubble',this.props)

    return (
      <>
        {!this.isCollapsed() ? (
          <div
            className={classNames({
              "offer-card-indicator": true,
              "is-self": this.isSelf(),
            })}
          >
            {this.props.msg.isFirstVersion() ? (
              <>
                New offer by{" "}
                {this.isSelf()
                  ? "You"
                  : this.getMainOffer().getOpponentIdentity().name}
              </>
            ) : (
              this.getChangeSummary()
            )}
          </div>
        ) : null}
        <div
          className={classNames({
            "offer-bubble": true,
            "is-collapsed": this.isCollapsed(),
            // "has-sidebar": this.hasSidebar(),
            "has-footer": this.hasFooter(),
            danger: this.isDanger(),
            success: this.isSuccess(),
            "is-self": this.isSelf(),
          })}
          id={this.getIdentifier()}
        >
          {this.isCollapsed()
            ? this.getCollapsedContent()
            : this.getOfferContent()}
        </div>
        {this.incomplate(this.getMainOffer()) ? (
          <div className={"status-update"}>
            <div>
              <span style={{ fontSize: "13px" }}>
                To move the offer forward click on the{" "}
                <strong style={{ color: "#1a76fc" }}>Complete</strong> button to
                suggest a {this.incomplateText(this.getMainOffer())} for
                publishing the post.
              </span>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
