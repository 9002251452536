import React, { Component, GetDerivedStateFromProps } from "react";
import { Button } from "reactstrap";
import numbro from "numbro";
import classNames from "classnames";
import { truncate } from "lodash";
import { observer } from "mobx-react";
import ReactTooltip from "react-tooltip";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { nFormatter } from '../../../../lib/Utils';
import RootStore from "../../../../store/RootStore";
import {
  MapPinIcon,
  InstagramIcon,
  AddedTickIcon,
  HandshakeIcon,
  CategoryIcon,
  GlobalIcon,
  PriceIcon,
  InformationIcon,
} from "../../../../assets/icons";
import { IInfluencerModel } from "../../../../store/models/InfluencerModel";
import ProfilePlaceholder from "../../../../assets/img/profilePlaceholder.jpg";
import { minifyPrice, minifyNum } from "./../../../../lib/Utils";

interface Props {
  influencer: IInfluencerModel;
  categoryFilter?: string;
  toggleAdSction?: () => void;
}

interface State {
  selected: boolean;
  imgLoaded: boolean;
}
/**
 * show influencer information such as nam , lang, Q-score, earning
 * @param props , { influencer, categoryFilter, toggleAdSction }
 * @param state , { selected, imgLoaded }
 */
@observer
export default class InfluencerCard extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      selected: false,
      imgLoaded: false,
    };

    this.toggleSelected = this.toggleSelected.bind(this);
  }

  async componentDidMount() {}

  /**
   * check influencer selected or not
   */
  toggleSelected() {
    if (this.state.selected) {
      this.setState((prevState) => ({
        selected: !prevState.selected,
      }));
      RootStore.invitationStore.removeFromList(this.props.influencer);
    } else {
      this.setState((prevState) => ({
        selected: !prevState.selected,
      }));
      RootStore.invitationStore.addToList(this.props.influencer);
    }
  }

  /**
   * check have anything in temporary list or not
   * filter existing influencer in list or not
   * check filtered list hvae memeber or not
   * @returns {selected : boolean} object
   */
  static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (
    props: Props,
    state: State
  ) => {
    if (RootStore.invitationStore.temporaryList.length === 0) {
      return { selected: false };
    }

    let filter = RootStore.invitationStore.temporaryList.filter(
      (infuencer: IInfluencerModel) =>
        infuencer.identity.id === props.influencer.identity.id
    );

    if (filter.length > 0) {
      return { selected: true };
    }

    return { selected: false };
  };

  render() {
    const { selected } = this.state;
    const { influencer, toggleAdSction } = this.props;

    let imgSrc = ProfilePlaceholder;

    if (influencer.integration) {
      influencer.integration.picture
        ? (imgSrc = influencer.integration.picture.getThumbnailUrl(96, 96))
        : (imgSrc = ProfilePlaceholder);
    }

    if (influencer.identity.picutre_path) {
      influencer.identity.picutre_path
        ? (imgSrc = influencer.identity.picutre_path.getThumbnailUrl(96, 96))
        : (imgSrc = ProfilePlaceholder);
    }

    return (
      <div
        className={classNames("influencerCard", {
          influencerCard_selected: selected,
        })}
      >
        {selected && <AddedTickIcon className="addedTick" />}
        <div>
          <div className="influencerCard_imageProfile">
            <LazyLoadImage
              effect={"blur"}
              src={imgSrc}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = ProfilePlaceholder;
              }}
              onLoad={() => this.setState((prevState) => ({ imgLoaded: true }))}
              className={classNames({
                influencerCard_imageProfile_placeholder:
                  imgSrc === ProfilePlaceholder,
              })}
              alt="influencer"
            />

            {influencer.identity &&
              influencer.identity.received_offers &&
              influencer.identity.received_offers.length > 0 && (
                <span data-tip data-for="onGoingOffers">
                  <HandshakeIcon />
                  {influencer.identity.received_offers.length}
                  <ReactTooltip id="onGoingOffers" type="dark" effect="solid">
                    <p>
                      {influencer.identity.received_offers.length} ongoing
                      offers
                    </p>
                  </ReactTooltip>
                </span>
              )}
          </div>
        </div>
        <div className="influencerCard__influencerDetails">
          <p className="influencerName">
            {influencer.integration && influencer.integration.full_name
              ? truncate(influencer.integration.full_name, { length: 20 })
              : influencer.identity && influencer.identity.name
              ? truncate(influencer.identity.name, { length: 20 })
              : "No name"}
          </p>
          <p>
            <InstagramIcon />
            {influencer.integration && influencer.integration.username ? (
              <a
                className="text-muted"
                href={`https://www.instagram.com/${influencer.integration.username}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {influencer.integration.username}
              </a>
            ) : (
              ""
            )}

            {influencer.identity && influencer.identity.username ? (
              <a
                className="text-muted"
                href={`https://www.instagram.com/${influencer.identity.username}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {influencer.identity.username}
              </a>
            ) : (
              ""
            )}
          </p>

          <ul className="categoryContainer">
            {JSON.parse(influencer?.identity?.category_elasticarray)
              .slice(0, 3)
              .filter((item) => item.name !== "Other")
              .map((category: any, index: number) => {
                return (
                  <li className="category" key={index}>
                    <span title={category?.name}>
                      {truncate(category?.name, { length: 12 })}
                    </span>
                  </li>
                );
              })}
          </ul>

          <div className="personalInfo">
            <div className="left">
              <p className="language">
                <GlobalIcon />
                {influencer?.identity?.language
                  ? influencer?.identity?.getLanguageName(
                      influencer.identity.language
                    )
                  : "English"}
              </p>
              <p className="country">
                <MapPinIcon />
                {influencer?.identity?.country_id
                  ? truncate(influencer?.identity?.getCountryName(), {
                      length: 15,
                    })
                  : "Canada"}
              </p>
            </div>

            <div className="right">
              <p>
                <CategoryIcon />
                <span>
                  Q-Score:{" "}
                  {influencer?.identity?.quality_score
                    ? `${Math.floor(
                        influencer?.identity?.quality_score * 100 > 100
                          ? 100
                          : influencer?.identity?.quality_score * 100 || 0
                      )}%`
                    : "..."}
                </span>
              </p>
              <p className="price">
                <PriceIcon /> &nbsp; $
                {minifyPrice(influencer?.identity?.earnings)} &nbsp;&nbsp;{" "}
                <InformationIcon data-tip data-for="price_tooltip" />
              </p>

              <ReactTooltip id="price_tooltip" type="dark" effect="solid">
                <p>
                  The suggested price is for a feed post for a day or longer.
                  The impact of feed posts usually dies after 24 hours . For
                  shorter than 24-hour posts divide the price by 24 and time it
                  by number of hours.
                </p>
                <p>
                  - Stories are usually 30% cheaper and get removed by Instagram
                  automatically after 24 hours.
                </p>
                <p>- Placing a URL in bio should add 15% to the price.</p>
              </ReactTooltip>
            </div>
          </div>
        </div>
        <div className="separator"></div>
        <div className="influencerCard__statistics">
          <div className="influencerCard__statistics--item">
            <h2>
              {influencer.identity.followers
                ? minifyNum(influencer.identity.followers)
                : ""}
            </h2>
            <span>Followers</span>
          </div>
          <div className="influencerCard__statistics--item">
            <h2>
              {influencer.identity.followings
                ? minifyNum(influencer.identity.followings)
                : ""}
            </h2>
            <span>Following</span>
          </div>
          <div className="influencerCard__statistics--item">
            <h2>
              {influencer.identity.engagement_rate
                ? numbro(influencer.identity.engagement_rate).format({
                    output: "percent",
                    mantissa: 2,
                    optionalMantissa: true,
                  })
                : "..."}
            </h2>
            <span>Engagement</span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 mt-4">
          <Button
            outline
            color="primary"
            className={classNames({
              influencerCard_buttonSelected: selected,
            })}
            onClick={(event: any) =>
              RootStore.adStore.selectedAd
                ? this.toggleSelected()
                : toggleAdSction()
            }
          >
            {selected ? "Selected" : "Select"}
          </Button>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/app/influencer-explorer/${influencer.identity.id}`}
            className="buttonLink cursor-pointer"
          >
            View Profile
          </a>
        </div>
      </div>
    );
  }
}
