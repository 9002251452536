import React from "react";
// import { Badge } from "reactstrap";
import moment from "moment";
import classNames from "classnames";
import { RouteComponentProps } from "react-router-dom";
import {
  WhiteSearchIcon,
  CalendarWithAClockTimeToolsIcon,
  RefreshIcon,
  TimeLeftIcon,
  WhiteCheckIcon,
} from "../../../../assets/icons";

import { PublishJobView } from "../../../../store/PublishJobStore";
import { countDown, momentDateTimeFormat } from "../../../../lib/Utils";

// import ThumbnailImage from "../../../../template/components/cards/ThumbnailImage";
import { truncate } from "lodash";
import { ContentPreviewModal } from "../../../common-components/ContentPreviewModal";

interface Props extends RouteComponentProps {
  job: PublishJobView;
}
interface State {
  showContentPreviewModal: boolean;
}

export function viewButtonVisible(job: PublishJobView): boolean {
  return (
    job.eventDate.isAfter(moment().subtract(24, "hours")) &&
    job.url &&
    !job.isTakeDown &&
    !job.failed
  );
}

export default class PublishJob extends React.Component<Props, State> {
  state: State = {
    showContentPreviewModal: false,
  };

  private editButtonVisible(): boolean {
    return (
      this.props.job.mediaType !== "story" &&
      this.props.job.offerId === null &&
      (this.props.job.state === "scheduled" ||
        this.props.job.state === "published")
    );
  }
  toggleShowPreviewModal = () => {
    this.setState({
      showContentPreviewModal: !this.state.showContentPreviewModal,
    });
  };

  private viewButtonVisible(): boolean {
    return viewButtonVisible(this.props.job);
  }

  private analyticsVisible() {
    return (
      this.props.job.state === "published" ||
      this.props.job.state === "completed" ||
      this.props.job.state === "removal"
    );
  }

  render() {
    // let error = this.props.job.failed;

    // let campaignBadge = null;
    // if (this.props.job.campaign) {
    //   campaignBadge = (
    //     <>
    //       <Badge color={error ? "danger" : "light"}>
    //         Campaign: <b>{this.props.job.campaign}</b>
    //       </Badge>
    //       &nbsp;
    //     </>
    //   );
    // }

    return (
      <>
        <ContentPreviewModal
          isOpen={this.state.showContentPreviewModal}
          content={this.props.job.content}
          onClose={this.toggleShowPreviewModal}
        />
        <div className="publishJobNewDesign">
          {/* <div className="left">
          <Card>
            <CardBody>
              <div className="header">
                <div className="date">
                  <span className="day">{this.props.job.eventDate.format("DD")}</span>
                  <br />
                  <span className="month">{this.props.job.eventDate.format("MMMM")}</span>
                  <span className="time">{this.props.job.eventDate.format("hh:mm A")}</span>
                </div>

                <div className="icon">
                  <img
                    alt={"ainfluencer"}
                    src={
                      error
                        ? "/assets/icons/error.svg"
                        : this.props.job.isTakeDown
                        ? "/assets/icons/down.svg"
                        : "/assets/icons/up.svg"
                    }
                  />
                </div>
              </div>
              <div className="footer">
                <div className="d-flex">
                  <ThumbnailImage
                    rounded
                    small
                    src="/assets/img/profile.png"
                    alt="profile"
                    className="m-1 scheduler-profile-picture"
                  />
                  <div className="d-flex flex-grow-1 min-width-zero">
                    {`@${this.props.job.username}`}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div> */}
          {/* <Card className="main">
          <div className="content">
            <div className="preview">
              <img alt="ainfluencer" src={this.props.job.previewImage} />
            </div>
            <div className="title">
              <span className="job">{this.props.job.title}</span>
              <br />
              {campaignBadge}
              <Badge color={error ? "danger" : "light"}>Duration: {this.props.job.duration}</Badge>
            </div>
          </div>
          <div className="buttons">
            {error ? (
              <Link
                to={`/app/scheduler/investigate/${this.props.job.id}`}
                className="btn btn-danger btn-sm">
                Investigate
              </Link>
            ) : (
              <>
                {this.viewButtonVisible() ? (
                  <>
                    <a
                      className="btn btn-xs btn-secondary"
                      href={this.props.job.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      View {ucfirst(this.props.job.mediaType)}
                    </a>
                    <br />
                  </>
                ) : null}
                {this.analyticsVisible() ? (
                  <Button
                    onClick={() => {
                      this.props.history.push(`/app/publish-jobs/${this.props.job.id}/analytics`);
                    }}
                    color="secondary"
                    size="xs">
                    {ucfirst(this.props.job.mediaType)} Analytics
                  </Button>
                ) : null}
              </>
            )}
          </div>
        </Card> */}

          <div className="publishJobNewDesign__body">
            <div
              className={classNames(
                "preview",
                { post: this.props.job.mediaType === "post" },
                { story: this.props.job.mediaType === "story" }
              )}
            >
              <img src={this.props.job.previewImage} alt="ainfluencer" />
              {this.props.job.mediaType === "post" ? (
                <div className="viewPost" onClick={this.toggleShowPreviewModal}>
                  <WhiteSearchIcon />
                  View Ad
                </div>
              ) : (
                <div
                  className="viewStory"
                  onClick={this.toggleShowPreviewModal}
                >
                  <WhiteSearchIcon />
                  View Story
                </div>
              )}
            </div>
            <div className="content">
              <div className="title">
                {this.props.job.offerId
                  ? truncate(this.props.job.offerId.ad_id.title, { length: 20 })
                  : "Personal Post"}
                <span
                  className={classNames(
                    "type",
                    { feedType: this.props.job.mediaType === "post" },
                    { storyType: this.props.job.mediaType === "story" }
                  )}
                >
                  {this.props.job.mediaType === "post" ? "Feed" : "Story"}
                </span>
              </div>
              {this.props.job.mediaType === "post" ? (
                <div className="caption">
                  {truncate(this.props.job.content.data.caption, {
                    length: 150,
                  })}
                </div>
              ) : null}
              {this.props.job.offerId ? (
                <div className="details">
                  <div className="campaign">
                    Campaign: {this.props.job.offerId.campaign_id.title}
                  </div>
                  <div className="price">${this.props.job.offerId.price}</div>
                </div>
              ) : null}
            </div>
            <div className="schedule">
              <div className="at">
                <CalendarWithAClockTimeToolsIcon />
                At: <span>{this.props.job.eventDate.format("MMMM")}</span>{" "}
                <span>{this.props.job.eventDate.format("DD")}th</span>{" "}
                <span>{this.props.job.eventDate.format("YYYY")}</span>{" "}
                <span>{this.props.job.eventDate.format("hh:mm A")}</span>
              </div>
              <div className="duration">
                <RefreshIcon />
                Duration: <span>{this.props.job.duration}</span>
              </div>
              {this.props.job.offerId && this.props.job.offerId.bio_link ? (
                <div className="bioLink">
                  <div className="bio-link-icon"></div>
                  Bio Link:{" "}
                  <a
                    rel="noopener noreferrer"
                    href={this.props.job.offerId.bio_link}
                    target="_blank"
                  >
                    {truncate(this.props.job.offerId.bio_link, { length: 20 })}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              "publishJobNewDesign__footer",
              {
                pending:
                  this.props.job.state === "scheduled" &&
                  this.props.job.status === "healthy",
              },
              {
                completed:
                  (this.props.job.state === "completed" ||
                    this.props.job.state === "published") &&
                  this.props.job.status === "healthy",
              },
              {
                failed:
                  this.props.job.status === "failed" ||
                  this.props.job.status === "expired",
              }
            )}
          >
            {(this.props.job.state === "completed" ||
              this.props.job.state === "published") &&
              this.props.job.status === "healthy" ? (
              <>
                <div className="left">
                  <WhiteCheckIcon /> Published{" "}
                  <span>{this.props.job.eventDate.format("MMMM")}</span>{" "}
                  <span>{this.props.job.eventDate.format("DD")}th</span>{" "}
                  <span>{this.props.job.eventDate.format("YYYY")}</span>{" "}
                  <span>{this.props.job.eventDate.format("hh:mm A")}</span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://instagram.com/${this.props.job.username}/`}
                    className="username"
                  >
                    on @{this.props?.job?.offerId?.getTargetIntegration().username}
                  </a>
                </div>
                <div className="right">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.props.job.url}
                  >
                    View Feed
                  </a>
                  {this.props.job.offerId &&
                    <span
                      onClick={() => {
                        this.props.history.push(
                          `/app/offers/${this.props.job.offerId.id}`
                        );
                      }}
                    >
                      View Offer
                    </span>
                  }
                </div>
              </>
            ) : this.props.job.state === "scheduled" &&
              this.props.job.status === "healthy" ? (
              <>
                <div className="left">
                  <TimeLeftIcon /> Your ad will be published in
                  <span>
                    {countDown(
                      moment(
                        this.props.job.offerId?.start,
                        momentDateTimeFormat
                      ) as any
                    )}
                  </span>
                  &nbsp; on
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://instagram.com/${this.props.job.username}/`}
                    className="username"
                  >
                    @{this.props.job.offerId?.getTargetIntegration().username}
                  </a>
                </div>
                <div className="right">
                  {this.props.job.offerId &&
                    <span
                      onClick={() => {
                        this.props.history.push(
                          `/app/offers/${this.props.job.offerId?.id}`
                        );
                      }}
                    >
                      View Offer
                    </span>
                  }
                </div>
              </>
            ) : (
              <>
                <div className="left">
                  <div className="failIcon">x</div>
                  &nbsp; Post publishing failed
                  <>
                    <a
                      className="username"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://instagram.com/${this.props.job.username}/`}
                    >
                      @{this.props.job.username}
                    </a>
                  </>
                </div>
                <div className="right">
                  {this.props.job.offerId &&
                    <span
                      onClick={() => {
                        this.props.history.push(
                          `/app/offers/${this.props.job.offerId?.id}`
                        );
                      }}
                    >
                      View Offer
                    </span>
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
