import { ICountryModel } from './CountryModel';
import { IAnyModelType, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { FileModel } from './FileModel';
import { IntegrationModel } from './IntegrationModel';
import { CategoryModel } from './CategoryModel';
import { OfferModel } from './OfferModel';
import languageList from '../../constants/data/languages.json';
import { CountriesList } from './../../constants/countriesList'

export const IdentityModel = types
    .model({
        id: types.identifierNumber,
        user_id: types.maybeNull(types.number),
        country_id: types.maybeNull(types.string),
        state_id: types.maybeNull(types.string),
        city_id: types.maybeNull(types.string),
        created_at: types.maybeNull(types.string),
        updated_at: types.maybeNull(types.string),
        deleted_at: types.maybeNull(types.string),
        average_comments_per_post: types.maybeNull(types.number),
        average_likes_per_post: types.maybeNull(types.number),
        comments_per_followers: types.maybeNull(types.number),
        likes_per_followers: types.maybeNull(types.number),
        type: types.enumeration(['brand', 'influencer']),
        logo: types.maybeNull(FileModel),
        name: types.maybeNull(types.string),
        url: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
        categories: types.maybeNull(types.array(CategoryModel)),
        engagement_rate: types.maybeNull(types.number),
        engagement_rate_t_score: types.maybeNull(types.number),
        integrations: types.array(types.reference(types.late((): IAnyModelType => IntegrationModel))),
        received_offers: types.maybeNull(types.array(types.number)),
        offers: types.maybeNull(types.array(OfferModel)),
        email: types.maybeNull(types.string),
        ads_count: types.maybeNull(types.number),
        offers_count: types.maybeNull(types.number),
        offers_received: types.maybeNull(types.number),
        ongoing_offers_count: types.maybeNull(types.number),
        picutre_path: types.maybeNull(FileModel),
        followers: types.maybeNull(types.number),
        followings: types.maybeNull(types.number),
        username: types.maybeNull(types.string),
        quality_score: types.maybeNull(types.number),
        earnings: types.maybeNull(types.number),
        language: types.maybeNull(types.string),
        category_elastic: types.maybeNull(types.string),
        category_elasticarray: types.maybeNull(types.string),
    })
    .volatile((self) => ({
        category_id: null,
    }))
    .actions((self) => ({

        /**
        * this method set Category Id
        */
        setCategoryId() {
            self.category_id = self.categories[0];
        },

        /**
        * this method get Country Name
        */
        getCountryName() {
            return CountriesList.filter((country: ICountryModel) => country.id === self.country_id)[0]?.name;
        },

        /**
        * this method get Language Name
        */
        getLanguageName(languagevale) {
            if (languagevale && languagevale.length > 1 && languagevale !== 'N_A') {
                if (languageList.filter((item) => item.value === languagevale)[0]) {
                    return languageList.filter((item) => item.value === languagevale)[0].name;
                }
            }
        },
    }))
    .views((self) => ({}));
export interface IIdentityModel extends Instance<typeof IdentityModel> { }

export interface IIdentityModelSnapshotIn extends SnapshotIn<typeof IdentityModel> { }

export interface IIdentityModelSnapshotOut extends SnapshotOut<typeof IdentityModel> { }
