import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";

import { AdsIcon, RightArrowIcon } from "../../../../../assets/icons";

interface Props extends RouteComponentProps {
  totalAds: number;
  totalCampaigns: number;
}
/**
 * this is function component shows ads number
 * linked to ads 
 * linked to create ad 
 * @param totalAds , total user ads
 * @param totalCampaigns , total user campaigns started
 * @typeParam Props 
 */

export default function AdsSummary(props: Props) {
  const { totalAds, totalCampaigns } = props;

  return (
    <div className="dashboardWidget">
      <div className="dashboardWidget__header">
        <AdsIcon />
        <div>
          <span>{totalAds}</span>
          <p>Ads</p>
        </div>
      </div>
      <p className="dashboardWidget__content">
        {totalAds === 0 ? (
          <>
            You do not have any active Ads or Campaigns.{" "}
            <Link to="/app/ads/ad/objectives/">Create an Ad</Link> now
          </>
        ) : (
          <>
            You have <span>{totalAds}</span> Ads and{" "}
            <span>{totalCampaigns}</span> Campaigns started.
          </>
        )}
      </p>
      <div className="dashboardWidget__link">
        {totalAds !== 0 && (
          <Link to="/app/ads">
            View Ads <RightArrowIcon />
          </Link>
        )}
      </div>
    </div>
  );
}
