import React, { Component } from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { observer } from 'mobx-react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Modal, ButtonDropdown, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { AdEmptyStateIcon, RightArrowIcon } from '../../../../assets/icons';
import { ICampaignModel } from '../../../../store/models/CampaignModel';
import RootStore from '../../../../store/RootStore';
import { IAdModel } from '../../../../store/models/AdModel';
import { ajaxErrorAlert, handleError, isMobileAndTablet } from '../../../../lib/Utils';
import AdCard from './AdCard';
import AdPlaceholder from './AdPlaceholder';

interface Props extends RouteComponentProps {
    isOpen: boolean;
    toggleAdPopup: () => void;
    skipAd: () => void;
    updateAd: (id: number, ad: any) => void;
}

interface State {
    displayCampaigns: boolean;
    adList: IAdModel[];
    selectedCampaign: ICampaignModel;
    displayInvitedInfluencers: boolean;
    displayCancelDialog: boolean;
    onDisplaySuccessModal: boolean;
    dataFetched: boolean;
    campaignFilter: string;
}
/**
 * calss component ad selection provides this ability user show user
 * ads list and it can select and ad 
 * @param props ,  {isOpen , toggle , update }
 * @param state ,  { displayCampaigns,
                    displayInvitedInfluencers,
                    adList,
                    selectedCampaign,
                    displayCancelDialog,
                    onDisplaySuccessModal,
                    dataFetched,
                    campaignFilter
                }
 */
@observer
export default class AdSelection extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            displayCampaigns: false,
            displayInvitedInfluencers: false,
            adList: [],
            selectedCampaign: null,
            displayCancelDialog: false,
            onDisplaySuccessModal: false,
            dataFetched: false,
            campaignFilter: 'All',
        };

        this.loadData = this.loadData.bind(this);
        this.displaySuccess = this.displaySuccess.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }
    /**
     * send request and fetch all ads
     */
    async loadData() {
        try {
            await RootStore.campaignStore.fetchAllIfNecessary();

            if (!this.state.dataFetched) {
                this.setState((prevState) => ({
                    dataFetched: true,
                    adList: RootStore.adStore.getAllAds()
                }));
            }
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('Failed to load Ads data from server! Maybe it has been deleted!');
        }
    }
    /**
     * 
     * @param ad_id select ad and update selected ad id
     */
    async displaySuccess(ad_id: number, ad: any) {
        this.props.updateAd(ad_id, ad);
        this.props.toggleAdPopup();
        localStorage.setItem('selected_ad', `${ad_id}`)
    }

    render() {
        const { isOpen, toggleAdPopup, skipAd } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={toggleAdPopup} className="adSelection" fade={false}>
                <div className="adSelection__header">
                    <div>{!isMobileAndTablet() && <p>Select an Ad for this invitation list:</p>}</div>
                    {!this.state.dataFetched ? null : (
                        <ButtonDropdown
                            isOpen={this.state.displayCampaigns}
                            toggle={() =>
                                this.setState((prevState: any) => ({
                                    displayCampaigns: !prevState.displayCampaigns,
                                }))
                            }
                        >
                            {/* <DropdownToggle caret size="xs" outline className="offerListDD" color="">
                                {
                                    <>
                                        <span>Campaign</span>
                                        {this.state.campaignFilter}
                                    </>
                                }
                            </DropdownToggle> */}
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={async (event: any) => {
                                        await RootStore.campaignStore.fetchAllIfNecessary();
                                        let campaigns: ICampaignModel[] = RootStore.campaignStore.campaigns.map(
                                            (camp: ICampaignModel) => camp,
                                        );

                                        let ads: any = [];

                                        campaigns.map((campaign) =>
                                            RootStore.adStore
                                                .getAdsByCampaign(campaign.id)
                                                .map((ad: IAdModel) => ads.push(ad)),
                                        );

                                        this.setState((prevState) => ({
                                            selectedCampaign: null,
                                            campaignFilter: 'All',
                                            adList: ads,
                                            dataFetched: true,
                                        }));
                                    }}
                                >
                                    All
                                </DropdownItem>
                                {RootStore.campaignStore.campaigns.length > 0 &&
                                    RootStore.campaignStore.campaigns.map((campaign: ICampaignModel, index: number) => (
                                        <DropdownItem
                                            key={campaign.id}
                                            onClick={(event: any) => {
                                                this.setState((prevState) => ({
                                                    selectedCampaign: campaign,
                                                    campaignFilter: campaign.title,
                                                    adList: RootStore.adStore.getAdsByCampaign(campaign.id),
                                                }));
                                            }}
                                        >
                                            {campaign.title}
                                        </DropdownItem>
                                    ))}
                            </DropdownMenu>
                        </ButtonDropdown>
                    )}

                    <div>
                        <Link className="btn btn-primary" to="/app/ads/ad/objectives/">
                            Create new ad
                        </Link>
                    </div>
                </div>
                <div className="adSelection__newAdList">
                    {/* <div className="adSelection__adList"> */}
                    {/* <PerfectScrollbar
                        style={{
                            backgroundColor: '#f8f8f8',
                            paddingLeft: 12,
                        }}
                        containerRef={(ref) => { }}
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                    > */}
                    {this.state.dataFetched ? (
                        this.state.dataFetched && this.state.adList.length > 0 ? (
                            this.state.adList.map((ad: IAdModel, index: number) => {
                                return <AdCard
                                    ad={ad}
                                    key={`ad_card_${index}`}
                                    onDisplaySuccessModal={() => this.displaySuccess(ad.id, ad)}
                                    {...this.props}
                                />
                            })
                        ) : (
                            <div className="adSelection__emptyState">
                                <AdEmptyStateIcon />
                                <h6 className="mt-2 mb-5 text-muted">There are no Ads to display.</h6>
                                <p>
                                    Create an Ad to invite influencers.
                                </p>
                                <p>
                                    Once an Ad is created, invite influencers to receive offers from.
                                </p>
                                <Button onClick={() => this.props.history.push(`/app/ads/ad/objectives/`)}>
                                    Create An Ad Now
                                    <RightArrowIcon />
                                </Button>
                                <Button
                                    className="skip mt-2"
                                    color="link"
                                    onClick={skipAd}
                                    style={{ boxShadow: 'none !important' }}
                                >
                                    Skip for now
                                </Button>
                            </div>
                        )
                    ) : (
                        <>
                            <AdPlaceholder />
                            <AdPlaceholder />
                            <AdPlaceholder />
                            <AdPlaceholder />
                        </>
                    )}
                    {/* </PerfectScrollbar> */}
                    {/* </div> */}
                </div>
            </Modal>
        );
    }
}
