import React from "react";
import { Badge, Button, Form, Label } from "reactstrap";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

import Logo from "../../assets/img/logo.png";
import { PasswordField } from "../common-components";
import { ajaxErrorAlert, handleError } from "../../lib/Utils";
import IntlMessages from "../../template/helpers/IntlMessages";
import RootStore from "../../store/RootStore";

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

interface Props extends RouteComponentProps {}

export default function Login(props: Props) {
  return (
    <div className="authForm">
      <div className="authForm__side">
        <h1>Login</h1>
        <p>
          Please use your credentials to login.
          <br />
          If you are not a member, please{" "}
          <NavLink to={`/register`}>click here to register</NavLink>.
        </p>
      </div>

      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={LoginSchema}
        onSubmit={async (values, actions) => {
          try {
            let success = await RootStore.users.login(
              values.email,
              values.password,
              props.history
            );

            if (!success) {
              await Swal.fire({
                type: "error",
                title: "Oops...",
                text: "Invalid email or password!",
                footer: '<a href="/forgot-password">Forgot password?</a>',
              });
            }
          } catch (error) {
            handleError(error);

            ajaxErrorAlert("There was a problem logging you in!");
          }
        }}
      >
        {({ isSubmitting, handleSubmit, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit} className="authForm__form">
            <div>
              <a
                href={"https://ainfluencer.com"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Logo} alt="ainfluencer" />
              </a>
              <Label className="form-group has-float-label mb-4">
                <Field className={"form-control"} type="email" name="email" />
                <ErrorMessage
                  name="email"
                  component={Badge}
                  // @ts-ignore
                  color="danger"
                  pill
                  className="mb-1"
                />
                <IntlMessages id="user.email" />
              </Label>
              <Label className="form-group has-float-label mb-4">
                <PasswordField
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={setFieldValue}
                  className="form-control"
                />

                <ErrorMessage
                  name="password"
                  component={Badge}
                  // @ts-ignore
                  color="danger"
                  pill
                  className="mb-1"
                />
                <IntlMessages id="user.password" />
              </Label>
              <div className="authForm__form_actions">
                <NavLink to={`/forgot-password`}>Forgot password?</NavLink>
                <Button
                  color="primary"
                  className="btn-shadow"
                  size="lg"
                  type={"submit"}
                  disabled={isSubmitting}
                >
                  <IntlMessages id="user.login-button" />
                </Button>
              </div>
            </div>

            <div className="termsAndPrivacyPolicy">
              <p>&copy; {new Date().getFullYear()} Ainfluencer</p>
              <a
                href="https://ainfluencer.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://ainfluencer.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
