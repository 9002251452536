import React, { Component } from 'react';
import { Button, Spinner } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import { observer } from 'mobx-react';
import numbro from 'numbro';
import { truncate } from 'lodash';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

import ProfilePlaceholder from '../../../../assets/img/profilePlaceholder.jpg';
import DisapproveReasonModal from './DisapproveReasonModal';
import {
    CategoryIcon,
    GlobalIcon,
    GoBackArrowIcon,
    InstagramIcon,
    MapPinIcon,
    PriceIcon,
} from '../../../../assets/icons';
import ApplicationMenu from '../../../../template/components/common/ApplicationMenu';
import { IOfferModel, PossibleOfferActions } from '../../../../store/models/OfferModel';
// import { ICountryModel } from '../../../../store/models/CountryModel';
import RootStore from '../../../../store/RootStore';
import { countDown, momentDateTimeFormat } from '../../../../lib/Utils';
import { contactSupportLink, isStaging } from '../../../../constants/defaultValues';
import classNames from 'classnames';
import Transport from '../../../../lib/Transport';
import languageList from '../../../../constants/data/languages.json';
import { servicePath, approvePostApi } from './../../../../constants/defaultValues';
import { minifyPrice, minifyNum } from './../../../../lib/Utils';
import { CountriesList } from './../../../../constants/countriesList';
import { ajaxErrorAlert, handleError, showLoading } from '../../../../lib/Utils';

interface Props extends RouteComponentProps {
    offer: IOfferModel;
    offerButtons: any;
    performOfferAction: Function;
    publishButtonAction: (action: string, reson?: string) => void;
    reload?: () => void;
}

interface State {
    searchKey: string;
    displayMenu: boolean;
    reasonModal: boolean;
    userinfo: any;
    mostuserdhashtags: any;
    resent_ads: any;
    countriesList: any;
}

@observer
export default class ChatApplicationMenu extends Component<Props, State> {
    private ref: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            searchKey: '',
            displayMenu: false,
            reasonModal: false,
            userinfo: null,
            mostuserdhashtags: [],
            resent_ads: null,
            countriesList: CountriesList,
        };
    }

    static getDerivedStateFromProps(props: any, state: any) {
        let offer: any = null;

        if (props.offer === null) {
            offer = null;
        }

        offer = props.offer;

        return {
            offer,
        };
    }

    makeOnClickAction(action: PossibleOfferActions) {
        return (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            this.props.performOfferAction(action);
        };
    }

    // getCoutriesList = () => {
    //     axios.get(`${servicePath}${COUNTRIES}`).then(res => {
    //         const { countries } = res?.data
    //         const newCountries = countries.map(item => {
    //             return {
    //                 name: item?.name,
    //                 code: item?.code,
    //             }
    //         }
    //         )
    //         this.setState({ countriesList: newCountries })
    //     })
    // }

    getStateNotification() {
        switch (this.props.offer.getExtensiveStatus()) {
            case 'no-offer':
                return (
                    <div className={'chat-cta'}>
                        <div className="no-offer-icon" />
                        <div className="text">
                            Neither you, nor <b>{this.props.offer.getOpponentIdentity().name}</b> has made any concrete
                            offer yet!{' '}
                            <a href="/" onClick={this.makeOnClickAction('offer')}>
                                Click here to suggest {this.props.offer.getMissingAttributesDescription()}{' '}
                                <span style={{ verticalAlign: 'bottom' }} className="glyph-icon iconsminds-right" />
                            </a>
                        </div>
                    </div>
                );
            case 'rejected-self':
                return (
                    <div className={'chat-cta info'}>
                        <div className="rejected-self" />
                        <div className="text">
                            You have rejected {this.props.offer.getOpponentIdentity().name}'s offer!{' '}
                            <a href="/" onClick={this.makeOnClickAction('counter')}>
                                Counter His Offer
                            </a>
                            , or wait for him to respond/edit...
                        </div>
                    </div>
                );
            case 'rejected-other':
                return (
                    <div className={'chat-cta  info'}>
                        <div className="rejected-other" />
                        <div className="text">
                            {this.props.offer.getOpponentIdentity().name} have rejected your offer!{' '}
                            <a href="/" onClick={this.makeOnClickAction('edit')}>
                                Edit it
                            </a>
                            , or keep negotiating with him...
                        </div>
                    </div>
                );
            case 'pending-self':
                return (
                    <div className={'chat-cta info'}>
                        <div className="waiting-icon" />
                        <div className="text">
                            {this.props.offer.getOpponentIdentity().name} is waiting for you to accept the offer!{' '}
                            <a href="/" className={'success'} onClick={this.makeOnClickAction('accept')}>
                                Accept
                            </a>
                            ,{' '}
                            <a href="/" className={'error'} onClick={this.makeOnClickAction('reject')}>
                                Reject
                            </a>
                            , or{' '}
                            <a href="/" onClick={this.makeOnClickAction('counter')}>
                                Counter His Offer
                            </a>
                        </div>
                    </div>
                );
            case 'pending-other':
                return (
                    <div className={'chat-cta info'}>
                        <div className="waiting-icon" />
                        <div className="text">
                            Counter offer sent to <b>{this.props.offer.getOpponentIdentity().name}</b> and awaiting a
                            response.{' '}
                            <a href="/" onClick={this.makeOnClickAction('edit')}>
                                Edit offer
                            </a>
                            , or continue chat.
                        </div>
                    </div>
                );
            case 'incomplete-self':
                return (
                    <div className={'chat-cta'}>
                        <div className="incomplete-offer-icon" />
                        <div className="text">
                            Current offer by <b>You</b> is not complete yet, because it lacks{' '}
                            {this.props.offer.getMissingAttributesDescription()}!{' '}
                            <a href="/" onClick={this.makeOnClickAction('complete')}>
                                Click here to complete it now
                            </a>{' '}
                            , or keep waiting for {this.props.offer.getOpponentIdentity().name} to set{' '}
                            {this.props.offer.getMissingAttributesDescription()}
                        </div>
                    </div>
                );
            case 'incomplete-other':
                return (
                    <div className="chat-cta">
                        <div className="incomplete-offer-icon" />
                        <div className="text">
                            The current offer from <b>{this.props.offer.getOpponentIdentity().name}</b> is not yet
                            complete. An offer must have a publish date setting.{' '}
                            <a href="/" onClick={this.makeOnClickAction('complete')}>
                                Click here
                            </a>{' '}
                            to edit this offer now.
                        </div>
                    </div>
                );
            case 'awaiting-payment':
                let text = null;
                if (this.props.offer.getLastModifierIdentity().user_id === RootStore.users.currentUser.id) {
                    text = (
                        <React.Fragment>
                            You have accepted <b>{this.props.offer.getOpponentIdentity().name}</b>'s offer!
                        </React.Fragment>
                    );
                } else {
                    text = (
                        <React.Fragment>
                            <b>{this.props.offer.getOpponentIdentity().name}</b> have accepted your offer! In order to
                            make a deal,{' '}
                            <a href="/" onClick={this.makeOnClickAction('pay')}>
                                Click here to pay{' '}
                                {numbro(this.props.offer.price)
                                    .formatCurrency({ mantissa: 2, thousandSeparated: true })
                                    .toUpperCase()}
                            </a>
                        </React.Fragment>
                    );
                }
                return (
                    <div className="chat-cta success">
                        <div className="awaiting-payment-icon" />
                        <div className="text">
                            {text}
                            <br />
                            <a
                                style={{ fontSize: '0.9em', fontWeight: 'normal' }}
                                href="https://ainfluencer.com/payment"
                            >
                                Learn about our secure payments here
                            </a>
                        </div>
                    </div>
                );
            case 'paid':
                if (!this.props.offer.getLatestPublishJob()) {
                    return '<b>Cloud not load the publish-job for this offer! Please contact support...</b>';
                }

                if (this.props.offer.getLatestPublishJob().isFailed()) {
                    return (
                        <div className="chat-cta success">
                            <div className="error-icon" />
                            <div className="text">
                                {this.props.offer.getLatestPublishJob().state === 'scheduled' ? (
                                    <>
                                        Unfortunately publish failed because of an error in{' '}
                                        <b>@{this.props.offer.getTargetIntegration().username}</b> integration with
                                        AInfluencer! Money will be returned to your wallet..!
                                        <br />
                                    </>
                                ) : (
                                    <>
                                        Could not verify ads existence on{' '}
                                        <b>@{this.props.offer.getTargetIntegration().username}</b> page! Maybe the ad
                                        have been deleted prematurely or the influencer's page is disabled! Your money
                                        will be refunded if deal terms are violated!
                                    </>
                                )}
                                <br />
                                <a href={contactSupportLink}>Contact Support</a>
                            </div>
                        </div>
                    );
                } else if (this.props.offer.getLatestPublishJob().state === 'scheduled') {
                    return (
                        <div className="chat-cta success">
                            <div className="deal-icon" />
                            <div className="text">
                                Congratulations! In{' '}
                                <b>{countDown(moment(this.props.offer.start, momentDateTimeFormat) as any)}</b> your
                                post will go live on <b>@{this.props.offer.getTargetIntegration().username}</b> ...
                            </div>
                        </div>
                    );
                } else if (this.props.offer.getLatestPublishJob().state === 'published') {
                    return (
                        <div className="chat-cta success">
                            <div className="success-icon" />
                            <div className="text">
                                Congratulations! Your post is now live and published on
                                <b>@{this.props.offer.getTargetIntegration().username}</b> ...
                                <br />
                                <a
                                    href={this.props.offer.getLatestPublishJob().published_url}
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                >
                                    View Ad
                                </a>{' '}
                                {!isStaging() && (
                                    <>
                                        |{' '}
                                        <NavLink
                                            to={`/app/publish-jobs/${
                                                this.props.offer.getLatestPublishJob().id
                                            }/analytics`}
                                        >
                                            View Analytics
                                        </NavLink>
                                    </>
                                )}
                            </div>
                        </div>
                    );
                } else if (this.props.offer.getLatestPublishJob().state === 'removal') {
                    if (this.props.offer.getLatestPublishJob().status === 'healthy') {
                        return (
                            <div className="chat-cta success">
                                <div className="success-icon" />
                                <div className="text">
                                    Removal of the ad from <b>@{this.props.offer.getTargetIntegration().username}</b> is
                                    scheduled, and will happen momentarily...
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="chat-cta success">
                                <div className="error-icon" />
                                <div className="text">
                                    Could not remove the ad from influencer's page, because of an error in{' '}
                                    <b>@{this.props.offer.getTargetIntegration().username}</b> integration with
                                    AInfluencer!
                                    <br />
                                    <br />
                                    <a href={contactSupportLink}>Contact Support</a>
                                </div>
                            </div>
                        );
                    }
                } else {
                    // completed
                    return (
                        <div className="chat-cta success">
                            <div className="success-icon" />
                            <div className="text">
                                Congratulations!.....Published by @{this.props.offer.getTargetIntegration().username}.
                                <br />
                                {!isStaging() && (
                                    <NavLink
                                        to={`/app/publish-jobs/${this.props.offer.getLatestPublishJob().id}/analytics`}
                                    >
                                        View Analytics
                                    </NavLink>
                                )}
                            </div>
                        </div>
                    );
                }
            case 'cancel-request':
                return (
                    <>
                        <div className={'chat-cta info'}>
                            <div className="waiting-icon" />
                            <div className="text">
                                {this.props.offer.nullification_initiator === 'brand'
                                    ? 'You have'
                                    : '@' + this.props.offer.getTargetIntegration().username + ' has'}{' '}
                                requested to cancel the offer. The content will be published as planned, unless{' '}
                                {this.props.offer.nullification_initiator === 'brand'
                                    ? '@' + this.props.offer.getTargetIntegration().username + ' approves'
                                    : 'you approve'}{' '}
                                the request.
                                {this.props.offer.nullification_initiator === 'influencer' ? (
                                    <a
                                        href={'/'}
                                        onClick={this.makeOnClickAction('approve-cancellation')}
                                        className={'error'}
                                    >
                                        Approve Cancellation
                                    </a>
                                ) : null}{' '}
                                |
                                {this.props.offer.nullification_initiator === 'influencer' ? (
                                    <a href={'/'} onClick={this.makeOnClickAction('reject-cancellation')}>
                                        Reject Cancellation
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </>
                );
            case 'cancel-request-rejected':
                return (
                    <>
                        <div className={'chat-cta info'}>
                            <div className="waiting-icon" />
                            <div className="text">
                                {this.props.offer.nullification_initiator === 'brand'
                                    ? 'You have'
                                    : '@' + this.props.offer.getTargetIntegration().username + ' has'}{' '}
                                rejected the cancellation request from{' '}
                                {this.props.offer.nullification_initiator === 'brand'
                                    ? '@' + this.props.offer.getTargetIntegration().username
                                    : 'you'}
                                . In <b>{countDown(moment(this.props.offer.start, momentDateTimeFormat) as any)}</b>{' '}
                                your post will go live on <b>@{this.props.offer.getTargetIntegration().username}</b>
                                ...
                                <br />
                                If you are not ok with this, you can click here to{' '}
                                <a href="/" onClick={this.makeOnClickAction('cancel-conflict')} className={'danger'}>
                                    log a dispute
                                </a>
                                .
                            </div>
                        </div>
                    </>
                );
            case 'cancelled':
                return (
                    <div className={'chat-cta info'}>
                        <div className="waiting-icon" />
                        <div className="text">
                            {this.props.offer.nullification_status === 'forced' ? (
                                <>
                                    AInfluencer has ruled in{' '}
                                    {this.props.offer.nullification_initiator === 'brand'
                                        ? 'your'
                                        : '@' + this.props.offer.getTargetIntegration().username + '`s'}{' '}
                                    favor and the offer has been cancelled.{' '}
                                    {numbro(this.props.offer.price)
                                        .formatCurrency({ mantissa: 2, thousandSeparated: true })
                                        .toUpperCase()}{' '}
                                    is applied to your wallet. If you want to continue with{' '}
                                    {'@' + this.props.offer.getTargetIntegration().username},{' '}
                                    <a href="/" onClick={this.makeOnClickAction('counter')}>
                                        click here to revise the offer
                                    </a>
                                    .
                                </>
                            ) : (
                                <>
                                    The offer has been cancelled.{' '}
                                    {numbro(this.props.offer.price)
                                        .formatCurrency({ mantissa: 2, thousandSeparated: true })
                                        .toUpperCase()}{' '}
                                    is applied to your wallet. If you want to continue with{' '}
                                    {'@' + this.props.offer.getTargetIntegration().username},{' '}
                                    <a href="/" onClick={this.makeOnClickAction('counter')}>
                                        click here to revise the offer
                                    </a>
                                    .
                                </>
                            )}
                        </div>
                    </div>
                );
            case 'cancel-conflict':
                return (
                    <>
                        <div className={'chat-cta info'}>
                            <div className="waiting-icon" />
                            <div className="text">
                                {this.props.offer.nullification_initiator === 'brand'
                                    ? 'You have'
                                    : '@' + this.props.offer.getTargetIntegration().username + ' has'}{' '}
                                logged a dispute with Ainfluencer. Wait for the verdict.
                            </div>
                        </div>
                    </>
                );
            case 'cancel-ineligible':
                return (
                    <>
                        <div className={'chat-cta info'}>
                            <div className="waiting-icon" />
                            <div className="text">
                                AInfluencer has ruled in{' '}
                                {this.props.offer.nullification_initiator === 'influencer'
                                    ? 'your'
                                    : '@' + this.props.offer.getTargetIntegration().username + '`s'}{' '}
                                favor; So in{' '}
                                <b>{countDown(moment(this.props.offer.start, momentDateTimeFormat) as any)}</b>, your
                                post will go live on <b>@{this.props.offer.getTargetIntegration().username}</b>
                                ...
                                <br />
                            </div>
                        </div>
                    </>
                );
            case 'refund-request':
                return (
                    <div className={'chat-cta info'}>
                        <div className="waiting-icon" />
                        <div className="text">
                            You have requested for a refund from{' '}
                            {'@' + this.props.offer.getTargetIntegration().username}. Wait for him to respond.
                        </div>
                    </div>
                );
            case 'refund-request-rejected':
                return (
                    <div className={'chat-cta info'}>
                        <div className="waiting-icon" />
                        <div className="text">
                            {'@' + this.props.offer.getTargetIntegration().username} has rejected your refund request.
                            If you are not ok with this, you can click here to{' '}
                            <a onClick={this.makeOnClickAction('refund-conflict')} href={'/'} className={'danger'}>
                                log a dispute
                            </a>
                            .
                        </div>
                    </div>
                );
            case 'refunded':
                return (
                    <div className={'chat-cta info'}>
                        <div className="waiting-icon" />
                        <div className="text">
                            {this.props.offer.nullification_status === 'forced' ? (
                                <>
                                    Your refund request has been approved by Ainfluencer.{' '}
                                    {numbro(this.props.offer.price)
                                        .formatCurrency({ mantissa: 2, thousandSeparated: true })
                                        .toUpperCase()}{' '}
                                    is applied to your wallet.
                                </>
                            ) : (
                                <>
                                    Your refund request has been approved by{' '}
                                    {'@' + this.props.offer.getTargetIntegration().username}.{' '}
                                    {numbro(this.props.offer.price)
                                        .formatCurrency({ mantissa: 2, thousandSeparated: true })
                                        .toUpperCase()}{' '}
                                    is applied to your wallet.
                                </>
                            )}
                        </div>
                    </div>
                );
            case 'refund-conflict':
                return (
                    <div className={'chat-cta info'}>
                        <div className="waiting-icon" />
                        <div className="text">Your dispute has been sent to Ainfluencer for review.</div>
                    </div>
                );
            case 'refund-ineligible':
                return (
                    <div className={'chat-cta info'}>
                        <div className="waiting-icon" />
                        <div className="text">Your refund request has not been approved by our compliance team...</div>
                    </div>
                );
        }
    }

    async componentDidMount() {
        const integrationid = this.props.offer.getTargetIntegration().id;
        const userinfo = await Transport.post('v1/elastic/find-integration/' + integrationid, {});

        if (
            userinfo &&
            userinfo.data &&
            userinfo.data.doc &&
            userinfo?.data?.doc[0] &&
            userinfo?.data?.doc[0]?._source
        ) {
            this.setState({ userinfo: userinfo?.data?.doc[0]?._source });
        }

        const mostuserdhashtags = await Transport.post('v1/offer/most-used-hashtags', {
            integration_id: integrationid,
        });
        this.setState({ mostuserdhashtags: mostuserdhashtags.data.most_used_hashtags });

        // this.getCoutriesList()
        //const resentads = await Transport.post("v1/offer/latest" ,{"integration_id": integrationid, "number_of_data": 10})
    }

    getLanguageName(languagevale) {
        if (languagevale && languagevale.length > 1 && languagevale !== 'N_A') {
            if (languageList.filter((item) => item.value === languagevale)[0]) {
                return languageList.filter((item) => item.value === languagevale)[0]?.name || '';
            }
        }
    }

    renderCat = () => {
        return this.state?.userinfo
            ? Array.isArray(this.state?.userinfo?.categories) && this.state?.userinfo?.categories[0]
                ? this.state?.userinfo?.categories[0]?.name === 'Other'
                    ? this.state?.userinfo?.categories[1]
                        ? truncate(this.state?.userinfo?.categories[1]?.name, {
                              length: 16,
                          })
                        : truncate(this.state?.userinfo?.categories[0]?.name, {
                              length: 16,
                          })
                    : truncate(this.state?.userinfo?.categories[0]?.name, {
                          length: 16,
                      })
                : ''
            : '';
    };

    approvePost = async () => {
        axios
            .post(`${servicePath}${approvePostApi(this.props.offer.id)}`)
            .then((res) => {})
            .catch();
        try {
            await Swal.fire({
                title: 'Approval Pending',
                text: 'Are you sure you want to approve the post?',
                type: 'info',
                showCancelButton: true,
                confirmButtonColor: '#28a745',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Approve It',
                allowOutsideClick: false,
            })
                .then(async (res) => {
                    if (res.value) {
                        const headers = {
                            headers: {
                                Authorization: 'Bearer ' + RootStore.users.getToken(),
                            },
                        };
                        const body = {
                            publish_job: {
                                state: 'completed',
                                status: 'healthy',
                            },
                        };
                        showLoading('Please Wait...', 'Please wait while we approving the post');
                        axios
                            .post(`${servicePath}${approvePostApi(this.props.offer.id)}`, body, headers)
                            .then((res) => {
                                Swal.close();
                                Swal.fire({
                                    type: 'success',
                                    title: 'Post has been approved successfully!',
                                    showCancelButton: false,
                                    showConfirmButton: true,
                                }).then(() => this.props.reload());
                            })
                            .catch((e) => {
                                handleError(e);
                                ajaxErrorAlert('There was a problem with approving the post.');
                            });
                    }
                })
                .catch((e) => {
                    handleError(e);
                    ajaxErrorAlert('There was a problem with approving the post.');
                });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem with approving the post');
        }
    };
    render() {
        const countryOutput = this.state.countriesList?.find((item) => item?.code === this.state.userinfo?.country_id)
            ?.name;
        const langOutput = this.state?.userinfo ? this.getLanguageName(this.state?.userinfo?.language) : '';
        const { state, status } = this.props?.offer?.getLatestPublishJob() || { state: '', status: '' };
        return (
            <ApplicationMenu
                isOpen={this.state.displayMenu}
                toggleOpenHandler={() =>
                    this.setState((prevState) => ({
                        ...prevState,
                        displayMenu: !prevState.displayMenu,
                    }))
                }
            >
                {RootStore.offerStore.isBusy ? (
                    <div className="get-state-loading-box">
                        <Spinner color="primary" className="mb-1" />
                        <p>Please wait...</p>
                    </div>
                ) : (
                    <PerfectScrollbar options={{ suppressScrollX: true, wheelPropagation: false }}>
                        <div className={'offer-sidebar'}>
                            {this.getStateNotification()}
                            <div className="d-flex justify-content-center">
                                <div className="offerCard__status mr-1">
                                    {this.props?.offer?.getHumanReadableStatus() === 'Waiting Payment' ? (
                                        <span
                                            className="btn btn-success btn-xs mt-1"
                                            onClick={this.makeOnClickAction('pay')}
                                        >
                                            Make A Payment
                                        </span>
                                    ) : (
                                        <span
                                            className={classNames([
                                                'bg-' + this.props?.offer?.getStatusColor(),
                                                'mt-3',
                                                'd-inline-block',
                                            ])}
                                        >
                                            {this.props?.offer?.getHumanReadableStatus()}
                                        </span>
                                    )}
                                </div>
                                {this.props?.offer?.getLatestPublishJob() &&
                                this.props?.offer?.getLatestPublishJob().url_approval ? (
                                    <div className="offerCard__status">
                                        <span
                                            className={classNames([
                                                `bg-${
                                                    !this.props?.offer
                                                        ?.getLatestPublishJob()
                                                        .url_approval.includes('disapproved')
                                                        ? 'success'
                                                        : 'danger'
                                                }`,
                                                'mt-3',
                                                'd-inline-block',
                                            ])}
                                        >
                                            {!this.props?.offer
                                                ?.getLatestPublishJob()
                                                .url_approval.includes('disapproved')
                                                ? this.props?.offer
                                                      ?.getLatestPublishJob()
                                                      .url_approval.includes('brand')
                                                    ? 'Approved by brand'
                                                    : 'Approved by Admin'
                                                : this.props?.offer
                                                      ?.getLatestPublishJob()
                                                      .url_approval.includes('brand')
                                                ? 'Disapproved by brand'
                                                : 'Disapproved by Admin'}
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            {/* {this.props?.offer?.getLatestPublishJob() &&
                                this.props?.offer?.getLatestPublishJob().state !== 'completed' &&
                                this.props?.offer?.getLatestPublishJob().state !== 'scheduled' && (
                                    <div role="group" className="btn-group mt-4">
                                        {this.props?.offer.getLatestPublishJob()?.status === 'failed' && (
                                            <span>
                                                {this.props?.offer?.getLatestPublishJob()?.url_approval &&
                                                    this.props?.offer
                                                        ?.getLatestPublishJob()
                                                        .url_approval.includes('admin') ? null : (
                                                    <button
                                                        type="button"
                                                        onClick={() => this.props.publishButtonAction('approved')}
                                                        className="btn btn-success btn-sm"
                                                    >
                                                        Approve
                                                    </button>
                                                )}
                                            </span>
                                        )}
                                        {this.props?.offer.getLatestPublishJob()?.status === 'healthy' && (
                                            <span>
                                                {this.props?.offer?.getLatestPublishJob()?.url_approval &&
                                                    this.props?.offer?.getLatestPublishJob().url_approval.includes('brand') ? (
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            this.setState({
                                                                reasonModal: !this.state.reasonModal,
                                                            })
                                                        }
                                                        className="btn btn-danger btn-sm"
                                                    >
                                                        Disapprove
                                                    </button>
                                                ) : null}
                                            </span>
                                        )}
                                    </div>
                                )} */}
                            <br />
                            {this.props.offer?.history?.offer?.status === 'paid' &&
                            state !== 'completed' &&
                            status !== 'healthy' ? (
                                <Button color="success" onClick={this.approvePost}>
                                    Approve Post
                                </Button>
                            ) : (
                                ''
                            )}
                            <br />
                            &nbsp;
                            <hr />
                            <div className="influencerCard mt-5">
                                <div>
                                    <div className="influencerCard_imageProfile">
                                        <img
                                            src={
                                                this.props?.offer?.getTargetIntegration()?.picture &&
                                                this.props?.offer?.getTargetIntegration()?.picture.url
                                                    ? this.props?.offer?.getTargetIntegration()?.picture.url
                                                    : ProfilePlaceholder
                                            }
                                            alt="profile"
                                        />
                                    </div>
                                </div>

                                <div className="influencerCard__influencerDetails">
                                    <p>
                                        <a
                                            style={{ color: '#7d7f85' }}
                                            href={`http://instagram.com/${
                                                this.props?.offer.getTargetIntegration()?.username
                                            }/`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {this.props?.offer.getTargetIntegration()?.full_name
                                                ? truncate(this.props?.offer.getTargetIntegration()?.full_name, {
                                                      length: 24,
                                                  })
                                                : this.props?.offer.getIdentity()?.name
                                                ? truncate(this.props?.offer.getIdentity()?.name, {
                                                      length: 24,
                                                  })
                                                : 'No Name'}
                                        </a>
                                    </p>

                                    <p>
                                        <InstagramIcon />
                                        <a
                                            style={{ color: '#7d7f85' }}
                                            href={`http://instagram.com/${
                                                this.props?.offer.getTargetIntegration()?.username
                                            }/`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {this.props?.offer.getTargetIntegration()?.username
                                                ? this.props?.offer.getTargetIntegration()?.username
                                                : 'No Username'}
                                        </a>
                                    </p>

                                    {this.state.userinfo?.quality_score ? (
                                        <p style={{ fontWeight: 'bold', color: 'black' }}>
                                            Quality Score : &nbsp;{' '}
                                            <span style={{ color: 'green' }}>
                                                {Math.floor(
                                                    this.state.userinfo?.quality_score * 100 > 100
                                                        ? 100
                                                        : this.state.userinfo?.quality_score * 100 || 0,
                                                ) + '%'}
                                            </span>
                                        </p>
                                    ) : (
                                        ''
                                    )}

                                    <div className="personalInfo">
                                        {langOutput ? (
                                            <p className="language">
                                                <GlobalIcon />
                                                {langOutput}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                        {countryOutput ? (
                                            <p className="country">
                                                <MapPinIcon />
                                                {countryOutput}
                                            </p>
                                        ) : (
                                            ''
                                        )}

                                        {this.renderCat() ? (
                                            <p className="category">
                                                <CategoryIcon />
                                                {this.renderCat()}
                                            </p>
                                        ) : (
                                            ''
                                        )}

                                        {this.state?.userinfo?.earnings ? (
                                            <p className="price">
                                                <PriceIcon />${minifyPrice(+this.state?.userinfo?.earnings)}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </div>

                                    <div></div>
                                </div>

                                <div className="separator"></div>
                                <div className="influencerCard__statistics">
                                    <div className="influencerCard__statistics--item">
                                        <h2>{minifyNum(this.props?.offer.getTargetIntegration()?.followers)}</h2>
                                        <span>Followers</span>
                                    </div>
                                    <div className="influencerCard__statistics--item">
                                        <h2>{minifyNum(this.props?.offer.getTargetIntegration()?.followings)}</h2>
                                        <span>Following</span>
                                    </div>
                                    {this.props?.offer.getTargetIntegration()?.engagement_rate ? (
                                        <div className="influencerCard__statistics--item">
                                            <h2>
                                                {numbro(
                                                    this.props?.offer.getTargetIntegration()?.engagement_rate,
                                                ).format({
                                                    output: 'percent',
                                                    optionalMantissa: true,
                                                    spaceSeparated: false,
                                                    mantissa: 2,
                                                })}
                                            </h2>
                                            <span>Engagement</span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="d-flex justify-content-center align-items-center w-100 mt-4">
                                    <a
                                        href={`/app/influencer-explorer/${this.props?.offer?.getIdentity()?.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-outline-primary"
                                        style={{
                                            paddingTop: '0.2em',
                                        }}
                                    >
                                        View Profile
                                    </a>
                                </div>
                            </div>
                            <a
                                className="offerSideBarJumpToLatest"
                                href={
                                    '#offer-' + this.props?.offer?.id + this.props?.offer?.getHistory()?.getMaxBatchId()
                                }
                            >
                                <GoBackArrowIcon />
                                Jump to the latest offer
                            </a>
                        </div>
                        <div className={'offer-sidebar'}>
                            {Array.isArray(this.state?.mostuserdhashtags) &&
                            this.state?.mostuserdhashtags.length !== 0 ? (
                                <p className="text-left">
                                    {' '}
                                    <b>Most Used Hashtags:</b>
                                    <br />
                                    <div className="most-hashtags" style={{ lineBreak: 'anywhere' }}>
                                        {this.state?.mostuserdhashtags
                                            ? Object.keys(this.state?.mostuserdhashtags).map((key, value) => {
                                                  return (
                                                      <a
                                                          href={`https://www.instagram.com/explore/tags/${key.replace(
                                                              '#',
                                                              '',
                                                          )}/`}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                          style={{
                                                              color: 'white',
                                                              background: '#007bff',
                                                              padding: '5px',
                                                              borderRadius: '5px',
                                                              margin: '3px',
                                                              lineHeight: '30px',
                                                          }}
                                                      >
                                                          {key}({value})
                                                      </a>
                                                  );
                                              })
                                            : ''}
                                    </div>
                                </p>
                            ) : (
                                ''
                            )}

                            {/* <p className="text-left">
                                {' '}
                                <b>Recent Ads:</b>
                            </p> */}
                        </div>
                    </PerfectScrollbar>
                )}
                <DisapproveReasonModal
                    isOpen={this.state?.reasonModal}
                    onClose={() => this.setState({ reasonModal: !this.state?.reasonModal })}
                    publishButtonAction={(reason) => this.props.publishButtonAction('disapproved', reason)}
                />
            </ApplicationMenu>
        );
    }
}
