import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'reactstrap';
import classNames from 'classnames';

import { SlickSlider } from '../../../common-components';
import InfluencerCard from '../components/InfluencerCard';
import { RightArrowIcon } from '../../../../assets/icons';
import InfluencerCardLoader from '../components/InfluencerCardLoader';
import { explorerSearchType } from '../../../../lib/CustomTypes';
import SearchHashtag from '../../../common-components/SearchHashtag';
import { ajaxErrorAlert, handleError } from '../../../../lib/Utils';
import RootStore from '../../../../store/RootStore';
import Transport from '../../../../lib/Transport';
import { HashtagModel } from '../../../../store/models/HashtagModel';
// import { SortOrder } from './../../../../lib/QueryTypes';
import languageList from '../../../../constants/data/languages.json';
import { CountriesList } from './../../../../constants/countriesList';
import { isStaging, ELASTIC_SEARCH_V2 } from './../../../../constants/defaultValues';
import Axios from 'axios';
import handleViewport from 'react-in-viewport';
interface Props extends RouteComponentProps {
    header: React.ReactNode;
    emptyMessage: React.ReactNode;
    toggleAdSction: () => void;
    seeMoreHandler: () => void;
    value?: string;
    type?: string;
}
/**
 *
 * @param props , {influencerGroup, header, emptyMessage, toggleAdSction }
 * @returns list of categories, hashtags , location results linked to custom search
 */
export default function GroupList(props: Props) {
    const [sideShadow, setSideShadow] = useState(true);
    const { header, value, type, seeMoreHandler } = props;
    const [showHashtagModal, setShowHashtagModal] = useState(false);
    const [influencers, setInfluencers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [inViewPort, setInViewPort] = useState(false);

    /**
     * hashtag modal toggle
     */
    const toggleSearchHashtagsModal = () => {
        setShowHashtagModal(!showHashtagModal);
    };

    useEffect(() => {
        if (type === explorerSearchType.HASHTAG) setSideShadow(false);
    }, [influencers]);
    const checkArr = (arr) => Array.isArray(arr) && arr.length > 0;

    // get influencers
    const _newElasticSearch = () => {
        try {
            setLoading(true);
            const headers = {
                headers: {
                    Authorization: 'Bearer ' + RootStore.users.getToken(),
                },
            };
            const getThumbnailUrl = (pic) => {
                try {
                    return `${pic}?width=96&height=96`;
                } catch (err) {
                    return '';
                }
            };

            const getLanguageName = (lang) => {
                if (languageList?.filter((item) => item.value === lang)[0]) {
                    return languageList?.filter((item) => item.value === lang)[0].name;
                }
            };

            const getCountryName = (countryID) => {
                return CountriesList.filter((country) => country.id === countryID)[0]?.name;
            };

            // @ts-ignore
            const body = {
                ...(type === explorerSearchType.HASHTAG ? { hashtags: [value] } : {}),
                ...(type === explorerSearchType.CATEGORY ? { categories: [value] } : {}),
                country_id: type === explorerSearchType.LOCATION ? [value] : [],
                from: 0,
                relevancy: true,
                min_follower: 5000,
                max_follower: 100000000,
            };

            let baseURL = '';
            if (isStaging()) {
                baseURL = 'https://api-staging.ainfluencer.com/api';
            } else {
                baseURL = 'https://api.ainfluencer.com/api';
            }

            const url = `${baseURL}${ELASTIC_SEARCH_V2}`;
            Axios.post(url, body, headers)
                .then((res) => {
                    const doc = res?.data?.original?.doc || [];
                    const influ = [];
                    for (let i = 0; i < doc.length; i++) {
                        const { fields } = doc[i];
                        influ.push({
                            integration: null,
                            identity: {
                                id: fields.identity_id ? fields.identity_id[0] : '',
                                country_id: fields?.country_id ? fields?.country_id[0] : '',
                                type: 'influencer',
                                picutre_path: {
                                    height: 64,
                                    width: 64,
                                    id: fields.picutre_path ? fields.picutre_path[0] : '',
                                    label: fields.picutre_path ? fields.picutre_path[0] : '',
                                    type: 'image/jpeg',
                                    url: fields.picutre_path ? fields.picutre_path[0] : '',
                                    getThumbnailUrl: () =>
                                        getThumbnailUrl(fields.picutre_path ? fields.picutre_path[0] : ''),
                                },
                                name: fields?.full_name ? fields?.full_name[0] : '...',
                                followers: fields?.followers ? fields?.followers[0] : 0,
                                followings: fields?.followings[0] ? fields?.followings : 0,
                                engagement_rate: fields?.engagement_rate ? fields?.engagement_rate[0] : 0,
                                username: fields?.username ? fields?.username[0] : '',
                                quality_score: fields?.quality_score ? fields?.quality_score[0] : 0,
                                earnings: fields?.earning ? fields?.earning[0] : 0,
                                language: fields?.language ? fields?.language[0] : '',
                                getLanguageName: () => getLanguageName(fields?.language ? fields?.language[0] : ''),
                                getCountryName: () => getCountryName(fields?.country_id ? fields?.country_id[0] : ''),
                                category_elastic:
                                    fields?.['categories.name'] && fields?.['categories.name'][0]
                                        ? fields?.['categories.name'][0] === 'Other'
                                            ? fields?.['categories.name'][1]
                                                ? fields?.['categories.name'][1]
                                                : fields?.['categories.name'][0]
                                            : fields?.['categories.name'][0]
                                        : 'N/A',
                                category_elasticarray: JSON.stringify(fields?.['categories.slug']),
                            },
                        });
                    }
                    setInfluencers(influ);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log('err', err);
                    setLoading(false);
                });
        } catch (err) {
            console.log('new elastic search err : ', err);
        }
    };
    useEffect(() => {
        if (inViewPort) {
            _newElasticSearch();
        }
    }, [inViewPort, type]);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        initialSlide: 0,
        slidesToShow: 5,
        slidesToScroll: 4,
        arrows: false,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 740,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        afterChange: (currentSlide: number) => {
            if (currentSlide >= 4) {
                setSideShadow(!sideShadow);
            }
        },
        beforeChange: (currentSlide: number) => {
            if (currentSlide >= 4) {
                setSideShadow(!sideShadow);
            }
        },
    };

    /**
     *
     * @param hashtags , array of hashtags
     * give hashtag user want to search according to them
     */
    const saveHashtags = async (hashtags: string[]) => {
        const selected_ad = localStorage.getItem('selected_ad');
        const identityId = RootStore.identityStore.getByUserId(RootStore.users.currentUser.id).id;
        let hashtagList = [];
        if (hashtags.length > 0) {
            hashtags.forEach(async (hashtag, i) => {
                RootStore.invitationStore.addInfluencersToTemporaryList({
                    by: explorerSearchType.HASHTAG,
                    loading: true,
                    title: hashtag,
                    influencers: [],
                    queryParam: {
                        name: explorerSearchType.HASHTAG,
                        value: hashtag,
                    },
                    manual: true,
                });
                hashtagList.push(
                    HashtagModel.create({
                        id: i,
                        identity_id: identityId,
                        name: hashtag,
                    }),
                );
            });
            RootStore.hashtagsStore.updateHashtags(hashtagList);
        } else {
            RootStore.hashtagsStore.updateHashtags(hashtagList);
            RootStore.invitationStore.updateHashtagsTemporaryList();
        }
        try {
            await Transport.post(`identities/${identityId}/hashtags`, {
                hashtags,
            });
            if (selected_ad) {
                const ad_info = JSON.parse(localStorage.getItem('ad_info'));
                const limit_country = ad_info?.limit_country?.length > 0 ? ad_info?.limit_country[0] : '';
                const ad_hashtags = hashtagList?.map((item) => item.name);

                await RootStore.invitationStore.fetchAllInfluencersForExplorerWithAd(limit_country, ad_hashtags);
            } else {
                await RootStore.invitationStore.fetchAllInfluencersForExplorer();
            }
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('Failed to get influencers data! Please reload the page!');
        }
    };

    const LoadingSections = () => (
        <div className="mb-5">
            <SlickSlider
                settings={{
                    dots: false,
                    infinite: false,
                    speed: 500,
                    initialSlide: 0,
                    slidesToShow: 5,
                    slidesToScroll: 4,
                    arrows: false,
                    centerMode: false,
                    responsive: [
                        {
                            breakpoint: 1367,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 0,
                            },
                        },
                        {
                            breakpoint: 1280,
                            settings: {
                                slidesToShow: 3.5,
                                slidesToScroll: 0,
                            },
                        },
                        {
                            breakpoint: 980,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 0,
                            },
                        },
                        {
                            breakpoint: 740,
                            settings: {
                                slidesToShow: 2.5,
                                slidesToScroll: 0,
                            },
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 0,
                            },
                        },
                        {
                            breakpoint: 420,
                            settings: {
                                slidesToShow: 1.5,
                                slidesToScroll: 0,
                            },
                        },
                    ],
                }}
            >
                <InfluencerCardLoader />
                <InfluencerCardLoader />
                <InfluencerCardLoader />
                <InfluencerCardLoader />
                <InfluencerCardLoader />
            </SlickSlider>
        </div>
    );

    const Results = () => (
        <SlickSlider settings={settings}>
            {influencers?.map((item, index: number) => (
                <div key={`groupList__${item?.identity?.id}`} className="position-relative">
                    <InfluencerCard toggleAdSction={props?.toggleAdSction} influencer={item} />
                    {index === influencers?.length - 1 && influencers?.length > 5 ? (
                        <div className="influencerCard_over">
                            <Button outline color="primary" onClick={seeMoreHandler}>
                                See More
                                <RightArrowIcon />
                            </Button>
                        </div>
                    ) : null}
                </div>
            ))}
        </SlickSlider>
    );

    const onEnterViewport = () => {
        console.log('ENTER');
        setInViewPort(true);
    };

    const ViewPort = ({ children }) => {
        const Block = (props: { forwardedRef }) => {
            const { forwardedRef } = props;
            return <div className="viewport-block" ref={forwardedRef}></div>;
        };
        const ViewportBlock = handleViewport(Block);
        return (
            <>
                {children}
                <ViewportBlock onEnterViewport={onEnterViewport} onLeaveViewport={() => console.log('leave')} />
            </>
        );
    };

    const renderBtn = () => {
        if (checkArr(influencers)) {
            return (
                <Button outline color="primary" onClick={seeMoreHandler}>
                    See More
                    <RightArrowIcon />
                </Button>
            );
        } else {
            return null;
        }
    };

    return (
        <ViewPort>
            <div className={influencers.length === 0 ? null : classNames('influencersGroupList', { sideShadow })}>
                {type === explorerSearchType.HASHTAG && (
                    <SearchHashtag isOpen={showHashtagModal} toggle={toggleSearchHashtagsModal} action={saveHashtags} />
                )}
                {checkArr(influencers) ? (
                    <div className="influencersGroupList__heading">
                        {header}
                        {renderBtn()}
                    </div>
                ) : null}
                {loading ? <LoadingSections /> : null}
                {!loading && checkArr(influencers) ? <Results /> : null}
            </div>
        </ViewPort>
    );
}
