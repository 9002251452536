import React, { Component } from "react";
import { Table, Spinner } from "reactstrap";
import HistoryIcon from "../../../../../../assets/icons/TimeLeft.svg";
import WalletReportColProfile from "./parts/profile";
import ModalPaymentTransactions from "./parts/modals/paymentTransactions/index";

interface Props {
  users: any;
  loading: boolean;
}

export default class WalletReportPayment extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalPaymentTransactionsModal: false,
      dataPayment: null,
      password_payment: null,
    };
  }

  modalPaymentTransactionsToggle = () => {
    this.setState((prevState) => {
      return {
        modalPaymentTransactionsModal: !prevState.modalPaymentTransactionsModal,
      };
    });
  };

  render() {
    return (
      <>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Name-Email Address</th>
              <th>Total Deals</th>
              <th>Total Payment</th>
              <th>Total Credit Money</th>
              <th>Total Wallet Money</th>
              <th>History</th>
            </tr>
          </thead>
          <tbody>
            {!this.props.loading &&
              this.props.users &&
              this.props.users.map((item: any) => {
                console.log("ITEM ", item);
                return (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      <WalletReportColProfile item={item} />
                    </td>
                    <td>{item?.deals_count}</td>
                    <td>{item?.total_payment_money}</td>
                    <td>{item.total_credit_amount}</td>
                    <td>{item.total_wallet_amount}</td>
                    <td>
                      <img
                        style={{ width: "20px", cursor: "pointer" }}
                        src={HistoryIcon}
                        alt=""
                        onClick={() => {
                          this.modalPaymentTransactionsToggle();
                          this.setState({
                            dataPayment: item,
                          });
                          console.log("dataPayment : ", item);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {this.props.loading && (
          <div
            style={{
              width: "100%",
              height: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner color="primary" size={"lg"} className="spinner" />
          </div>
        )}
        {this.state.modalPaymentTransactionsModal && (
          <ModalPaymentTransactions
            isOpen={this.state.modalPaymentTransactionsModal}
            toggle={this.modalPaymentTransactionsToggle}
            dataPayment={this.state.dataPayment}
          />
        )}
      </>
    );
  }
}
