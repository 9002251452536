import React from 'react';
import { Button, Spinner } from 'reactstrap';

interface Iprops {
    loading: boolean;
    children: any;
    refs?: object;
    backgroundColor?: string;
}
function LoadingButton(props: Iprops) {
    const { loading, children, refs, backgroundColor } = props;
    const LoaderComponent = function (): JSX.Element {
        return (
            <span>
                <Spinner color="white" size="sm" style={{ marginRight: '4px' }} /> Processing...
            </span>
        );
    };
    return (
        <Button color={backgroundColor || 'primary'} type="submit" {...refs} disabled={loading}>
            {loading ? LoaderComponent() : children}
        </Button>
    );
}

export default LoadingButton;
