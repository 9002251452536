import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import $ from 'jquery';
import { observer } from 'mobx-react';
import { truncate } from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { HistoryClockIcon, LinkButtonIcon } from '../../assets/icons';
import ContentPreview from '../app/content-manager/components/preview/ContentPreview';
import { EventIcon } from '../../assets/icons';
import RootStore from '../../store/RootStore';
// import ScrollBar from "react-perfect-scrollbar";
import profileImg from '../../assets/img/profilePlaceholder.jpg';
import { SeeMoreSeeLessData } from '../common-components';

interface Props {
    values: any;
    currentStep?: number;
    brand?: any;
    userNameShow?: boolean;
}

interface State {
    currentStep: number;
}

@observer
export class AdPreview extends Component<Props, State> {
    private _scrollBarRef: any | null = null;

    private publishTermsRef: React.RefObject<HTMLDivElement>;
    private schedulingRef: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);

        this.state = {
            currentStep: 0,
        };

        this.publishTermsRef = React.createRef();
        this.schedulingRef = React.createRef();
    }

    static getDerivedStateFromProps(props: any, state: any) {
        return {
            currentStep: props.currentStep,
        };
    }

    // componentDidMount(): void {
    //     this.getBrand()
    // }

    componentDidUpdate() {
        if (this.props.currentStep === 3) {
            const target = this.schedulingRef.current;
            $(this._scrollBarRef).animate(
                {
                    scrollTop: $(target).position().top,
                },
                1000,
                function () {},
            );
        }

        if (this.props.currentStep === 4) {
            const target = this.publishTermsRef.current;
            $(this._scrollBarRef).animate(
                {
                    scrollTop: $(target).position().top,
                },
                1000,
                function () {},
            );
        }
    }

    // getBrand = () => {
    //     Transport.get(`v2/user/brand-information`).then(res => {
    //         const resData = res?.data || res?.data?.data
    //         if (resData) {
    //             console.log(resData);

    //         }
    //     })
    // }

    render() {
        const { values } = this.props;

        const url = profileImg;
        return (
            <div className="adPreview">
                <p className="d-block text-center mb-4" style={{ padding: '0 1em' }}>
                    Your Ad in Marketplace
                </p>
                <div className="device-wrapper">
                    <div className="device" data-device="iPhone7" data-orientation="portrait" data-color="black">
                        <div className="screen" style={{ overflow: 'none' }}>
                            <div className="adPreview_preview">
                                <PerfectScrollbar
                                    containerRef={(ref) => {
                                        this._scrollBarRef = ref;
                                    }}
                                    options={{
                                        suppressScrollX: true,
                                        wheelPropagation: false,
                                    }}
                                >
                                    <div className="adPreview_preview_content">
                                        <div className="header">
                                            Ad by{' '}
                                            {this.props?.userNameShow && values?.user?.name
                                                ? values?.user?.name
                                                : this.props?.brand && this.props?.brand?.name
                                                ? this.props?.brand?.name
                                                : RootStore.users.getDefaultIdentity()?.name ||
                                                  RootStore.users?.currentUser?.first_name}
                                        </div>

                                        <div className="adTitle">
                                            <p>{values && values.title ? values.title : 'Ad title goes here'}</p>
                                            <p>
                                                {values && values.description ? (
                                                    <SeeMoreSeeLessData data={values.description} />
                                                ) : (
                                                    'Ad description goes here'
                                                )}
                                            </p>
                                        </div>

                                        <div className="brandInfo">
                                            <>
                                                <LazyLoadImage
                                                    effect={'blur'}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = profileImg;
                                                    }}
                                                    src={
                                                        this.props?.brand
                                                            ? this.props?.brand?.logo
                                                                ? this.props?.brand?.logo
                                                                : url
                                                            : RootStore.users.getDefaultIdentity()?.logo
                                                            ? RootStore.users
                                                                  .getDefaultIdentity()
                                                                  ?.logo.getThumbnailUrl(50, 50)
                                                            : url
                                                    }
                                                    alt="ainfluencer"
                                                />
                                                <p>
                                                    {this.props?.userNameShow && values?.user?.name
                                                        ? values?.user?.name
                                                        : this.props?.brand && this.props?.brand?.name
                                                        ? this.props?.brand?.name
                                                        : RootStore.users.getDefaultIdentity()?.name ||
                                                          RootStore.users?.currentUser?.first_name}{' '}
                                                    <br />{' '}
                                                    <span>
                                                        {this.props?.userNameShow && values?.user?.description
                                                            ? values?.user?.description
                                                            : this.props?.brand
                                                            ? this.props?.brand?.description
                                                                ? this.props?.brand?.description
                                                                : ''
                                                            : RootStore.users.getDefaultIdentity()?.description}
                                                    </span>
                                                </p>
                                            </>
                                        </div>

                                        <div className="adContent">
                                            <p>
                                                Ad Content:{' '}
                                                <strong style={{ color: '#3956fd' }}>
                                                    {values?.content?.type && <strong>{values?.content?.type}</strong>}
                                                </strong>
                                            </p>

                                            {values && values?.content?.medias && values.content?.medias.length ? (
                                                <ContentPreview onboardingPreview={true} model={values?.content} />
                                            ) : (
                                                <div className="adContent_content_empty">
                                                    <p>
                                                        Your creative will appear here, click on the blue button to
                                                        create a feed post or a story...
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        <div className="scheduling" ref={this.schedulingRef}>
                                            <p>Scheduling:</p>
                                            <div className="scheduling_content">
                                                {values && values.schedule && values.schedule.length ? (
                                                    <ul>
                                                        {values.schedule.map((scheduleItem: any) =>
                                                            scheduleItem.end.format('MMMM Do YYYY, h:mm a') ===
                                                            scheduleItem.start.format('MMMM Do YYYY, h:mm a') ? (
                                                                <li key={scheduleItem} style={{ fontSize: '0.65rem' }}>
                                                                    <EventIcon />
                                                                    {scheduleItem.start.format('MMMM Do YYYY, h:mm a')}
                                                                </li>
                                                            ) : (
                                                                <li className="periodic" key={scheduleItem}>
                                                                    <span>
                                                                        {' '}
                                                                        {scheduleItem.start.format(
                                                                            'MMMM Do YYYY, h:mm a',
                                                                        )}
                                                                    </span>
                                                                    <span>
                                                                        {' '}
                                                                        {scheduleItem.end.format(
                                                                            'MMMM Do YYYY, h:mm a',
                                                                        )}
                                                                    </span>
                                                                </li>
                                                            ),
                                                        )}
                                                    </ul>
                                                ) : (
                                                    <p>Not Specified</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="publishTime" ref={this.publishTermsRef}>
                                            <p>Publish Terms:</p>
                                            <div className="publishTime_content">
                                                <ul>
                                                    <li>
                                                        <HistoryClockIcon />
                                                        Duration:
                                                        {values && values.Duration && values.fixedDuration === 2 ? (
                                                            <>
                                                                {' '}
                                                                {values.duration_segmentation &&
                                                                values.duration_segmentation?.segment_name &&
                                                                values.duration_segmentation?.segment_time ? (
                                                                    <>
                                                                        {values.duration_segmentation?.segment_time}{' '}
                                                                        {values.duration_segmentation?.segment_name}
                                                                    </>
                                                                ) : (
                                                                    values.Duration > 1 && (
                                                                        <span style={{ color: '#1A76FC' }}>
                                                                            {moment
                                                                                .duration(values.Duration, 'seconds')
                                                                                .humanize()}
                                                                        </span>
                                                                    )
                                                                )}
                                                            </>
                                                        ) : values.fixedDuration === 1 ? (
                                                            <span>Permanent</span>
                                                        ) : (
                                                            <span>Not Specified</span>
                                                        )}
                                                    </li>
                                                    <li style={{ whiteSpace: 'nowrap' }}>
                                                        <LinkButtonIcon />
                                                        Link in Bio:
                                                        {values && values.bio_link ? (
                                                            <a
                                                                href={values.bio_link}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {truncate(values.bio_link, { length: 20 })}
                                                            </a>
                                                        ) : (
                                                            <span>Not Specified</span>
                                                        )}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                        <div className="button"></div>
                    </div>
                </div>
            </div>
        );
    }
}
