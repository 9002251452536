import { Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import createAdContext from '../../../../../../../../context'
import PublishTermsFormContext from '../../../../context'

function DateRemainDuration() {
    const { updateTerms, setFieldValue, values } = useContext(PublishTermsFormContext)
    const { updateOnboard } = useContext(createAdContext)

    const [Data, setData] = useState({
        number: values.segment_time,
        type: values.segment_name
    })

    const updateSegments = (key: any, value: any) => {
        updateOnboard((prev: any) => ({ ...prev, terms: { ...prev.terms, duration_segmentation: { ...prev.terms.duration_segmentation, [key]: value } } }))
    }

    useEffect(() => {
        updateTerms('duration', toSecend())
    }, [Data])

    const toSecend = () => {
        const number = Data.number
        switch (Data.type) {
            case 'hours':
                return number * 3600
            case 'days':
                return number * 86400
            case 'weeks':
                return number * 604800
            case 'months':
                return number * 2628000
        }
    }

    return (
        <Grid container columnSpacing={2}>
            <Grid item>
                <input type="number" className="form-control w-100" onChange={(e: any) => {
                    const value = e.target?.value
                    if (value && e.target?.value > 0) {
                        setData(prev => ({ ...prev, number: value }))
                        setFieldValue('segment_time', value)
                        updateSegments('segment_time', value)
                    }
                }} value={Data.number} />
            </Grid>
            <Grid item>
                <select className="form-control w-100" value={Data.type} onChange={(e: any) => {
                    const value = e.target?.value
                    if (value) {
                        setData(prev => ({ ...prev, type: value }))
                        setFieldValue('segment_name', value)
                        updateSegments('segment_name', value)
                    }
                }} name="duration_type">
                    <option>hours</option>
                    <option>days</option>
                    <option>weeks</option>
                    <option>months</option>
                </select>
            </Grid>
        </Grid>
    )
}

export default DateRemainDuration
