import React, { Component } from "react";
import { StepProp } from "../index";

export default class InvitationHeader extends Component<StepProp> {
  render() {
    return (
      <div className="header">
        <h6>Ainfluencer</h6>
        <div className="progress">
          <div className="bar">
            <div
              className={`dot ${this.props.step === 1 ? "active" : ""} ${
                this.props.step !== 1 ? "completed" : ""
              }`}
            ></div>
            <div
              className={`dot ${
                this.props.step === 2 || this.props.step === 3 ? "active" : ""
              } ${this.props.step > 3 ? "completed" : ""}`}
            ></div>
            <div
              className={`dot ${this.props.step === 4 ? "active" : ""} `}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
