import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react';

// components
import AppLayout from '../../template/layout/AppLayout';
import RootStore from '../../store/RootStore';
import UserManagement from './users-managment';
import Dashboard from './dashboard';
// import AccountManagment from "./account";
import AddsManagement from './ads-management';
import TaskManager from './task-manager';
import Charts from './charts';
import Explorer from '../app/explorer';
import Templates from './templates';
// import Transactions from './transactions';
import Payment from './payments'
import AI from './AI';
import EditIntegration from '../app/account/integrations/EditIntegration';
import PublishJobsReport from './publish-jobs';
import InitialLoading from './../../views/common-components/InitialLoading'


interface Props extends RouteComponentProps {
    containerClassnames: any[];
    menu: any;
}

interface State {
    token:string,
}

@observer
class Admin extends Component<Props,State> {
    constructor(props){
        super(props)
        this.state = {
            token: Cookies.get('token'),
        }
    }
    async componentDidMount() {
        await RootStore.fetchStaticData();
    }
    render() {
        const { match } = this.props;
        const { token } = this.state
        const loggedIn = RootStore.users.isLoggedIn()

        if(!token && loggedIn === false){
            return <Redirect to={'/login'} />   
        }else if(token && loggedIn === true){
            return <AppLayout displaySideAndTopNav={true}>
                 <Switch>
                     <Route path={`${match.url}/users-list/integrations/:id`} component={EditIntegration} />
                     <Route path={`${match.url}/users-list`} component={UserManagement} />
                     <Route path={`${match.url}/ai`} component={AI} />
                     <Route path={`${match.url}/templates`} component={Templates} />
                     <Route path={`${match.url}/explorer`} component={Explorer} />
                     {/* <Route path={`${match.url}/account`} component={AccountManagment} /> */}
                     {/* <Route path={`${match.url}/transactions`} component={Transactions} /> */}
                     <Route path={`${match.url}/ads-management`} component={AddsManagement} />
                     <Route path={`${match.url}/publish-jobs-report`} component={PublishJobsReport} />
                     <Route path={`${match.url}/payments`} component={Payment} />

                     <Route path={`${match.url}/task-manager`} component={TaskManager} />
                     <Route path={`${match.url}/charts`} component={Charts} />
                     <Route path={`${match.url}`} component={Dashboard} />

                     <Redirect to="/error" />
                 </Switch>
             </AppLayout>
        }else {
            return <InitialLoading/>
        }
    }
}

// @ts-ignore
const mapStateToProps = ({ menu }) => {
    const { containerClassnames } = menu;
    return { containerClassnames };
};

export default withRouter(connect(mapStateToProps, {})(Admin));



// return RootStore.users.isLoggedIn() ? (
//     <AppLayout displaySideAndTopNav={true}>
//         <Switch>
//             <Route path={`${match.url}/users-list/integrations/:id`} component={EditIntegration} />
//             <Route path={`${match.url}/users-list`} component={UserManagement} />
//             <Route path={`${match.url}/ai`} component={AI} />
//             <Route path={`${match.url}/templates`} component={Templates} />
//             <Route path={`${match.url}/explorer`} component={Explorer} />
//             {/* <Route path={`${match.url}/account`} component={AccountManagment} /> */}
//             <Route path={`${match.url}/transactions`} component={Transactions} />
//             <Route path={`${match.url}/ads-management`} component={AddsManagement} />
//             <Route path={`${match.url}/publish-jobs-report`} component={PublishJobsReport} />
//             <Route path={`${match.url}/payments`} component={Payment} />

//             <Route path={`${match.url}/task-manager`} component={TaskManager} />
//             <Route path={`${match.url}/charts`} component={Charts} />
//             <Route path={`${match.url}`} component={Dashboard} />

//             <Redirect to="/error" />
//         </Switch>
//     </AppLayout>
// ) : (
//     <Redirect to={'/login'} />
// );
