/**
 * sort by filter items
 */
export const filterOptions = [

  {
    // bySortBy
    label: "Sort By",
    name: "sortBy",
    options: [
      {
        label: "Followers",
        value: "followers",
        filterType: "sort",
        filter: { key: "followers" },
      },
      {
        label: "Engagement",
        value: "engagement_rate",
        filterType: "sort",
        filter: { key: "engagement_rate" },
      },
      {
        label: "Quality Score",
        value: "quality_score",
        filterType: "sort",
        filter: { key: "quality_score" },
      },
      {
        label: "Price",
        value: "earnings",
        filterType: "sort",
        filter: { key: "earnings" },
      },
    ],
  }
];

