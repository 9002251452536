import React, { Component } from "react";
import Select, { components } from "react-select";

const CustomSelectInput = (props: any) => {
  const customProps = Object.assign({}, props);
  delete customProps.autoCorrect;
  delete customProps.autoCapitalize;
  return <components.Input {...customProps} />;
};

interface Props {
  options: Array<{ label: string; value: any }>;
  value?: Array<{ label: string; value: any }>;
  onChange: (name: string, value: any) => void;
  onBlur: (name: string, value: any) => void;
  name: string;
  id?: string;
  styles?: any
}

interface State {
  selectedOptions: Array<{ label: string; value: any }>;
}

export class ReactSelectMulti extends Component<Props> {
  constructor(props: any) {
    super(props);

    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(options: any) {
    this.props.onChange(this.props.name, options);
  }

  handleBlur() {
    this.props.onBlur(this.props.name, true);
  }

  render() {
    const { options, name, id, styles } = this.props;

    return (
      <Select
        components={{ Input: CustomSelectInput }}
        className="react-select"
        classNamePrefix="react-select"
        isMulti
        name={name}
        id={id}
        value={this.props.value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        options={options}
        styles={styles}
      />
    );
  }
}
