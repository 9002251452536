import { createContext } from "react";

interface IPublishTerms {
    values: any
    touched: any
    errors: any
    setFieldValue: any
    setFieldTouched: any
    updateTerms: any
}
const PublishTermsFormContext = createContext<IPublishTerms>({
    values: null,
    touched: null,
    errors: null,
    setFieldValue: () => { },
    setFieldTouched: () => { },
    updateTerms: () => { }
})

export default PublishTermsFormContext