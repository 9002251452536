import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import Dashboard from "./landing/Dashboard";

interface Props extends RouteComponentProps {}

export default function Gogo(props: Props) {
  return (
    <Switch>
      <Redirect
        exact
        from={`${props.match.url}/`}
        to={`${props.match.url}/start`}
      />
      <Route path={`${props.match.url}/start`} component={Dashboard} />
      <Redirect to="/error" />
    </Switch>
  );
}
