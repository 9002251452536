import { observer } from 'mobx-react';
import React, { Component } from 'react';

interface Props {
    item: any;
    label?: string;
}

@observer
export default class WalletReportColProfile extends Component<Props, any> {
    render() {
        const { item, label } = this.props;
        return (
            <>
                {/* {item.identities && item.identities[0] && item.identities[0].logo ? (
                    <>
                        <LazyLoadImage
                            effect={'blur'}
                            alt="Post"
                            src={item.identities[0].logo?.url}
                            width={50}
                            height={50}
                            className="rounded"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = noImage;
                            }}
                        />
                    </>
                ) : (
                    <img
                        className="rounded"
                        width={50}
                        height={50}
                        src={noImage}
                        alt="no img"
                    />
                )} */}
                <span>{label ? label : item.email}</span>
            </>
        );
    }
}
