import React, { useState, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { ucfirst } from "../../lib/Utils";
import { truncate } from "lodash";

interface BreadcrumbProps extends RouteComponentProps {
  heading: string;
  disableLinks?: boolean;
  optionalProperties?: Array<{ title: string; value: string; id: string }>;
}

export function BreadCrumb(props: BreadcrumbProps) {
  const { heading, match, optionalProperties } = props;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [properties, setProperties] = useState(null);

  useEffect(() => {
    if (optionalProperties) {
      setProperties(optionalProperties);

      if (JSON.stringify(properties) === JSON.stringify(optionalProperties)) {
        setDataLoaded(true);
      }
    }
  }, [optionalProperties, dataLoaded, properties]);

  const getUrl = (index: number) => {
    let redirect = match.path.split("/");
    let app = redirect[1];
    redirect = redirect.slice(2, redirect.length);

    let paths = redirect.map((red) =>
      // @ts-ignore
      red.includes(":") ? match.params[red.slice(1)] : red
    );
    paths = paths.slice(0, index + 1);
    let route = paths.join("/");

    return app === "app" ? `/app/${route}` : `/admin/${route}`;
  };

  let relativePath = match.path.split("/").slice(2);

  relativePath.pop();

  let newPaths: string[] = [];

  newPaths = relativePath.map((path: string, index: number) => {
    let newPath = path.replace("-", " ");
    newPaths = [];

    let tempPath: string = "";

    if (properties && dataLoaded) {
      tempPath = properties.map(
        (property: { title: string; value: string; id: string }) => {
          let p =
            property.value === null
              ? setDataLoaded(false)
              : property.id === newPath || property.id === path
              ? truncate(property.title, { length: 25 })
              : ucfirst(newPath);

          return p;
        }
      );
    } else {
      tempPath = ucfirst(newPath);
    }

    return tempPath;
  });

  return (
    <div className="customBreadCrumb">
      {heading && <h1>{heading}</h1>}
      <Breadcrumb className="pt-0 breadcrumb-container d-lg-inline-block">
        {props.disableLinks
          ? newPaths.length >= 1 &&
            newPaths.map(
              (np, index) =>
                np !== undefined && (
                  <BreadcrumbItem key={index}>
                    {index === 0 ? (
                      <NavLink to={getUrl(index)}>{np}</NavLink>
                    ) : (
                      <span>{np}</span>
                    )}
                  </BreadcrumbItem>
                )
            )
          : newPaths.length >= 1 &&
            newPaths.map(
              (np, index) =>
                np !== undefined && (
                  <BreadcrumbItem key={index}>
                    <NavLink to={getUrl(index)}>{np}</NavLink>
                  </BreadcrumbItem>
                )
            )}
      </Breadcrumb>
    </div>
  );
}
