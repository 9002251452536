import * as firebase from "firebase/app";
import "firebase/messaging";
import { handleError } from "./lib/Utils";
import { firebaseConfig } from "./constants/defaultValues";

const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);

let messaging: firebase.messaging.Messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
}

// messaging.usePublicVapidKey(
//     "BKagOny0KF_2pCJQ3m....moL0ewzQ8rZu"
// );

export { messaging };

export const getTokenAndAskForPermission = async () => {
  try {
    await messaging.requestPermission();
    return await messaging.getToken();
  } catch (error) {
    handleError(error);
    return null;
  }
};
