import React, { Component, Fragment } from "react";
import { ButtonGroup } from "reactstrap";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";

import {
  Colxx,
  Separator,
} from "../../../template/components/common/CustomBootstrap";
import { BreadCrumb } from "../../common-components";
import RootStore from "../../../store/RootStore";
import ContentList from "./components/ContentList";
import ContentFormDialogue, {
  ContentTypeEnum,
} from "./components/ContentFormDialogue";
import { IContentModel } from "../../../store/models/ContentModel";

interface State {
  contentListFetchedOnce: boolean;
}

@observer
export default class ContentManagerPage extends Component<
  RouteComponentProps,
  State
> {
  private contentFormDialogue: ContentFormDialogue | null = null;

  componentDidMount() {
    RootStore.contentStore.fetchAllIfNecessary();
  }

  render() {
    return (
      <Fragment>
        <div className="text-zero top-right-button-container">
          <ButtonGroup>
            <div
              className={"top-right-button btn btn-primary btn-lg"}
              onClick={() => {
                this.contentFormDialogue.openNewDialogue(ContentTypeEnum.POST);
              }}
            >
              New Post
            </div>
            <span className="btn-separator" />
            <div
              className={"top-right-button btn btn-primary btn-lg"}
              onClick={() => {
                this.contentFormDialogue.openNewDialogue(ContentTypeEnum.STORY);
              }}
            >
              New Story
            </div>
          </ButtonGroup>
        </div>
        <BreadCrumb heading="Content Manager" {...this.props} />
        <Separator className="mb-5" />
        <Colxx xxs="12" className="mb-4">
          <ContentFormDialogue
            ref={(ref) => (this.contentFormDialogue = ref)}
            handleChange={() => {}}
            {...this.props}
          />
          <ContentList
            onSelect={(value: IContentModel) => {
              if (this.contentFormDialogue) {
                this.contentFormDialogue.loadForEdit(value.id);
              }
            }}
            mode={"manager"}
            {...this.props}
          />
        </Colxx>
      </Fragment>
    );
  }
}
