import React from "react";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";
import numbro from "numbro";
import { truncate } from "lodash";
import classNames from "classnames";

import _ from "lodash";
import { imageURLRegex, videoURLRegex } from "../../../../lib/Utils";
import { IOfferModel } from "../../../../store/models/OfferModel";
import { MegaphoneIcon } from "../../../../assets/icons";
import profileImg from "../../../../assets/img/profilePlaceholder.jpg";

interface Props extends RouteComponentProps {
  offer: IOfferModel;
  controlOfferModal?: any
}

function OfferCardMobileView(props: Props) {
  const { offer, history } = props;
  const url = profileImg;

  return (
    <>
      <tr
        className={classNames("offerCard d-flex flex-row", {
          offerCard_highlighted: props.offer.getDialogue().unreadMessages > 0,
        })}
        onClick={() => history.push(`/app/offers/${offer.id}`)}
      >
        {offer.isJustMessage() ? (
          <>
            <td className="offerCard__profilePic">
              <img
                style={{ width: "50px", height: "50px" }}
                src={
                  props.offer.getOpponentIdentity().logo
                    ? props.offer
                      .getOpponentIdentity()
                      .logo.getThumbnailUrl(50, 50)
                    : url
                }
                alt={"Profile"}
              />
            </td>
            <td className="offerCard__details d-flex flex-column">
              <div className={"user-section"}>
                <p className={"user-name"}>
                  {props.offer.getOpponentIdentity().name}
                </p>
                <span className={"user-category"}>
                  {truncate(props.offer.getOpponentIdentity().description, {
                    length: 8,
                  })}
                </span>
              </div>
              <div>
                {props.offer.getDialogue().unreadMessages > 0
                  ? props.offer.getDialogue().unreadMessages
                  : null}
              </div>
              <p className={"message unread"}>
                {props.offer.last_message.message}
              </p>
            </td>

            <td className={"message-time"}>
              <span className={"time unread"}>7:14 AM</span>
            </td>
          </>
        ) : (
          <>
            <div className="offerCard__profilePic">
              <img
                style={{ width: "50px", height: "50px" }}
                src={
                  props.offer.getOpponentIdentity().logo
                    ? props.offer
                      .getOpponentIdentity()
                      .logo.getThumbnailUrl(50, 50)
                    : url
                }
                alt={"Profile"}
              />
            </div>

            <div className="offerCard__details d-flex flex-column">
              <div className="offerCard__details_userName">
                <div className="name">
                  {truncate(props.offer.getOpponentIdentity().name, {
                    length: 20,
                  }) ?? "(No Name)"}
                </div>
                {props.offer.getDialogue().getLastMessageMoment() !== null && (
                  <span
                    className={`${props.offer.getDialogue().unreadMessages
                        ? "offerCard__lastMessage_unread"
                        : ""
                      }align-self-end`}
                  >
                    {props.offer
                      .getDialogue()
                      .getLastMessageMoment()
                      .format("h:mm a")}
                  </span>
                )}
              </div>

              <div className="offerCard__lastMessage">
                {props.offer.getDialogue().unreadMessages > 0 && (
                  <div className="badge">
                    {props.offer.getDialogue().unreadMessages}
                  </div>
                )}
                <p
                  className={classNames({
                    offerCard__lastMessage_unread:
                      props.offer.getDialogue().unreadMessages !== 0,
                  })}
                >
                  {!_.isArray(props.offer.last_message.attributes) &&
                    !_.isEmpty(props.offer.last_message.attributes)
                    ? props.offer.getLastMessageStatus()
                    : imageURLRegex.test(props.offer.last_message.message)
                      ? "Image"
                      : videoURLRegex.test(props.offer.last_message.message)
                        ? "Video"
                        : truncate(props.offer.last_message.message, {
                          length: 60,
                        })}
                </p>
              </div>
              <div className="d-flex flex-row">
                <td className="offerCard__price">
                  {props.offer.price ? (
                    <span>
                      {numbro(props.offer.price)
                        .formatCurrency({
                          mantissa: 2,
                          thousandSeparated: true,
                          optionalMantissa: true,
                        })
                        .toUpperCase()
                        .replace(" ", "")}
                    </span>
                  ) : (
                    <span className="offerCard__noPrice">No Price</span>
                  )}
                </td>
                <td className="offerCard__status">
                  <span
                    className={classNames([
                      "bg-" + props.offer.getStatusColor(),
                    ])}
                  >
                    {props.offer.getHumanReadableStatus()}
                  </span>
                </td>
              </div>

              <p
                id={`OfferToolTipToggler_${offer.id}`}
                className="offerDetailsTooltipLink mb-0 mt-2"
              >
                <MegaphoneIcon />
                {truncate(offer.ad_id.title, { length: 20 })}
              </p>
            </div>

            <div className="d-flex flex-row justify-content-center"></div>
            <div className=" d-flex flex-row justify-content-center align-items-center">
              {/* <Tooltip
                  placement="auto"
                  isOpen={isHovering}
                  toggle={() => setIsHovering(!isHovering)}
                  target={`OfferToolTipToggler_${offer.id}`}
                  delay={{ show: 0, hide: 1200 }}
                  // delay={0}
                  fade={false}
                >
                  <div className="offerDetailsTooltip">
                    <OfferDetails offer={props.offer} />
                    <Button color="primary" className="mx-auto">
                      Open Offer
                    </Button>
                  </div>
                </Tooltip> */}
            </div>
          </>
        )}
      </tr>
    </>
  );
}

export default observer(OfferCardMobileView);
