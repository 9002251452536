import ApiQueryBuilder from '../../lib/ApiQueryBuilder';
import { getTokenAndAskForPermission } from '../../PushNotification';
import Cookies from 'js-cookie';
import Transport from '../../lib/Transport';
import { SortOrder, FilterGroup, QueryOperator } from '../../lib/QueryTypes';
import { isStaging } from '../../constants/defaultValues';

/**
 * this method get App Info
 */
export function getAppInfo() {
    (async () => {
        let token = await getTokenAndAskForPermission();
        if (token != null) {
            if (Cookies.get('firebase_token') !== token) {
                Cookies.set('firebase_token', token);
                await Transport.post('auth/firebase', {
                    firebase_token: token,
                });
            }
        }
    })();

    return {
        version: '1.0.0',
        platform: 'web',
        firebase_token: Cookies.get('firebase_token'),
    };
}

/**
 * this method make Integration Query
 * @param metricMode, this param get metric Mode
 */
export function makeIntegrationQuery(metricMode: 'none' | 'latest' | 'all' = 'none') {
    let query = new ApiQueryBuilder();
    query.addIncludes('picture', false);

    if (metricMode === 'latest') {
        query.addIncludes('metric', false);
    }

    if (metricMode === 'all') {
        query.addIncludes('metrics', false);
    }

    return query;
}

/**
 * this method make User Query
 */
export function makeUserQuery(): ApiQueryBuilder {
    let query = new ApiQueryBuilder();
    query.addIncludes('wallet', false);
    query.addIncludes('wallet.methods', false);
    query.addIncludes('identities', true);
    query.addIncludes('identities.categories', false);
    query.addIncludes('identities.logo', false);
    query.addIncludes('integrations', true);
    query.addIncludes('integrations.picture', false);

    return query;
}

/**
 * this method make Token User Query
 */
export function makeTokenUserQuery(): ApiQueryBuilder {
    let query = new ApiQueryBuilder();
    query.addIncludes('wallet', false);
    query.addIncludes('wallet.methods', false);
    query.addIncludes('identities', true);
    query.addIncludes('identities.categories', false);
    query.addIncludes('identities.logo', false);
    query.addIncludes('integrations', true);
    query.addIncludes('integrations.identities', true);
    query.addIncludes('integrations.picture', false);
    query.addIncludes('integrations.metric', false);
    query.addIncludes('offers', false);

    return query;
}

/**
 * this method make Publish Job Query
 * @param metricMode, this param get metric Mode
 */
export function makePublishJobQuery(metricMode: 'none' | 'latest' | 'all' = 'none'): ApiQueryBuilder {
    let query = new ApiQueryBuilder();
    query.addIncludes('integration', true);
    query.addIncludes('integration.metric', false);
    query.addIncludes('integration.picture', false);
    query.addIncludes('content', true);
    query.addIncludes('content.medias', false);
    query.addIncludes('content.medias.preview_file', false);
    query.addIncludes('content.medias.file', false);
    query.addIncludes('error', false);
    query.addIncludes('offer.campaign', true);
    query.addIncludes('identity', true);
    query.addIncludes('identity.user', false);
    query.addIncludes('identity.logo', false);
    query.addIncludes('offer.target_identity', true);
    query.addIncludes('offer.target_identity.user', false);
    query.addIncludes('offer.ad', true);
    query.addIncludes('offer.ad.content', false);
    query.addIncludes('offer.ad.content.medias', false);
    query.addIncludes('offer.ad.content.medias.preview_file', false);
    query.addIncludes('offer.ad.time_tables', false);
    query.addIncludes('metrics', false);
    query.addIncludes('metric', true);

    if (metricMode === 'latest' || metricMode === 'all') {
        query.addIncludes('metric', false);
        query.addIncludes('integration.metric', false);
    }

    if (metricMode === 'all') {
        query.addIncludes('metrics', false);
    }

    return query;
}

/**
 * this method make Content Query
 * @param includeOriginalFile, this param check include Original File
 */
export function makeContentQuery(includeOriginalFile: boolean = false): ApiQueryBuilder {
    let query = new ApiQueryBuilder();
    query.addIncludes('medias', false);
    query.addIncludes('medias.file', false);
    query.addIncludes('medias.preview_file', false);

    if (includeOriginalFile) {
        query.addIncludes('medias.original_file', false);
    }
    return query;
}

/**
 * this method make Media Query
 * @param includeOriginalFile, this param check include Original File
 */
export function makeMediaQuery(includeOriginalFile: boolean = false) {
    let query = new ApiQueryBuilder();
    query.addIncludes('file', false);
    query.addIncludes('preview_file', false);
    if (includeOriginalFile) {
        query.addIncludes('original_file', false);
    }
    return query;
}

/**
 * this method make Campaign Query
 * @param full, this param if true add Includes all
 */
export function makeCampaignQuery(full: boolean = false) {
    let query = new ApiQueryBuilder();

    query.addIncludes('ads.time_tables', false);
    query.addIncludes('ads.content.medias.file', false);
    query.addIncludes('ads.content.medias.preview_file', false);

    if (full) {
        query.addIncludes('ads.offers', true);
        query.addIncludes('ads.offers.identity', true);
        query.addIncludes('ads.offers.identity.logo', false);
        query.addIncludes('ads.offers.identity.categories', false);
        query.addIncludes('ads.offers.target_identity', true);
        query.addIncludes('ads.offers.target_identity.logo', false);
        query.addIncludes('ads.offers.target_identity.categories', false);
        query.addIncludes('ads.offers.content.medias.file', false);
        query.addIncludes('ads.offers.content.medias.preview_file', false);
        query.addIncludes('ads.offers.target_integration', true);
        query.addIncludes('ads.offers.target_integration.metric', false);
        query.addIncludes('ads.offers.target_integration.picture', false);
    }
    return query;
}

/**
 * this method make Ad Query
 * @param includeFullContent, this param check include Full Content
 */
export function makeAdQuery(includeFullContent: boolean = false) {
    let query = new ApiQueryBuilder();
    query.addIncludes('content', true);
    query.addIncludes('content.medias.preview_file', false);
    query.addIncludes('campaign', true);
    query.addIncludes('campaign.identity', false);
    query.addIncludes('campaign.identity.logo', false);
    query.addIncludes('time_tables', false);
    //query.addIncludes("invitations", true);

    if (includeFullContent) {
        query.addIncludes('content.medias', false);
        query.addIncludes('content.medias.file', false);
        query.addIncludes('content.medias.original_file', false);
    }

    query.setPagination(10000, 0);

    return query;
}

/**
 * this method make Ad Query
 * @param includeFullContent, this param check include Full Content
 */
 export function makeNewAdQuery(includeFullContent: boolean = false,limit =100,page=0) {
    let query = new ApiQueryBuilder();
    query.addIncludes('content', true);
    query.addIncludes('content.medias.preview_file', false);
    query.addIncludes('campaign', true);
    query.addIncludes('campaign.identity', false);
    query.addIncludes('campaign.identity.logo', false);
    query.addIncludes('time_tables', false);
    //query.addIncludes("invitations", true);

    if (includeFullContent) {
        query.addIncludes('content.medias', false);
        query.addIncludes('content.medias.file', false);
        query.addIncludes('content.medias.original_file', false);
    }

    query.setPagination(limit, page);

    return query;
}


/**
 * this method Ad List Query
 * @param includeInvitations, this param check includeInvitations
 * @param includeOffers, this param check include Offers
 * @param pagination, this param get pagination
 * @param filterArray, this param get filters
 * @param sort, this param get sort object
 */
export function AdListQuery(
    includeInvitations: boolean = false,
    includeOffers: boolean = false,
    pagination?: { pageSize: number; page: number },
    filterArray: FilterGroup[] = [],
    sort?: { key: string; direction: SortOrder },
) {
    let query = new ApiQueryBuilder();
    if (filterArray.length !== 0) {
        query.addFilterGroupList(filterArray);
    }

    if (sort) {
        query.addSort(sort.key, sort.direction);
    }

    query.addIncludes('campaign', true);
    query.addIncludes('campaign.identity', false);
    query.addIncludes('campaign.identity.user', false);
    query.addIncludes('campaign.identity.logo', false);
    query.addIncludes('content', true);
    query.addIncludes('content.medias.preview_file', false);
    query.addIncludes('time_tables', false);

    if (pagination) {
        query.setPagination(pagination.pageSize, pagination.page);
    }

    if (includeInvitations) {
        query.addIncludes('invitations', true);
        query.addIncludes('invitations.target', true);
        query.addIncludes('invitations.target.logo', false);
        query.addIncludes('invitations.target.integrations', false);
        query.addIncludes('invitations.target.user', false);
    }

    if (includeOffers) {
        query.addIncludes('offers', true);
        query.addIncludes('offers.target_identity', true);
        query.addIncludes('offers.identity', true);
        query.addIncludes('offers.identity.logo', false);
        query.addIncludes('offers.content', true);
        query.addIncludes('offers.target_integration', true);
    }

    return query;
}

/**
 * this method make Offer Query
 * @param campaignId, this param campaign ID
 * @param getIntegrationIdentity, this param check get Integration Identity
 * @param pagination, this param get pagination
 * @param sort, this param get sort object
 */
export function makeOfferQuery(
    campaignId: number | null = null,
    getIntegrationIdentity: boolean = false,
    pagination?: { pageSize: number; page: number },
    sort?: { key: string; direction: SortOrder },
) {
    let query = new ApiQueryBuilder();

    if (pagination) {
        query.setPagination(pagination.pageSize, pagination.page);
    }
    if (sort) {
        query.addSort(sort.key, sort.direction);
    }

    query.addIncludes('identity', true);
    query.addIncludes('identity.logo', false);
    query.addIncludes('identity.categories', false);
    query.addIncludes('target_identity', true);
    query.addIncludes('target_identity.logo', false);
    query.addIncludes('target_identity.categories', false);
    query.addIncludes('target_integration', true);
    if (getIntegrationIdentity) {
        query.addIncludes('target_integration.identities', true);
        query.addIncludes('target_integration.identities.categories', false);
        query.addIncludes('target_integration.identities.logo', false);
    }

    query.addIncludes('target_integration.metric', false);
    query.addIncludes('target_integration.picture', false);
    query.addIncludes('content.medias.file', false);
    query.addIncludes('content.medias.preview_file', false);
    query.addIncludes('content.medias.original_file', false);
    query.addIncludes('ad', true);
    query.addIncludes('ad.content', false);
    query.addIncludes('ad.campaign', false);
    query.addIncludes('publish_job', true);
    // query.setPagination(10000, 0);

    if (campaignId) {
        query.addFilterGroup([
            {
                key: 'offer.ad.campaign',
                operator: QueryOperator.EQUALS,
                value: campaignId,
                not: false,
            },
        ]);
    }

    return query;
}

/**
 * this method make Payment Query
 */
export function makePaymentQuery() {
    let query = new ApiQueryBuilder();
    query.addIncludes('publish_job', true);
    query.addIncludes('publish_job.integration', true);
    query.addIncludes('publish_job.integration.metric', false);
    query.addIncludes('publish_job.integration.picture', false);
    query.addIncludes('publish_job.content', true);
    query.addIncludes('publish_job.content.medias', false);
    query.addIncludes('publish_job.content.medias.file', false);
    query.addIncludes('publish_job.content.medias.preview_file', false);
    return query;
}

/**
 * this method make Wallet Query
 */
export function makeWalletQuery() {
    let query = new ApiQueryBuilder();
    query.addIncludes('methods', false);
    return query;
}

/**
 * this method make Identity Query
 */
export function makeIdentityQuery() {
    let query = new ApiQueryBuilder();

    query.addIncludes('logo', false);
    query.addIncludes('categories', false);

    return query;
}

/**
 * this method make Invitations Query
 */
export function makeInvitationsQuery() {
    let query = new ApiQueryBuilder();

    query.addIncludes('ad', true);
    query.addIncludes('ad.campaign', false);
    query.addIncludes('ad.time_tables', false);
    query.addIncludes('ad.content.medias.file', false);
    query.addIncludes('ad.content.medias.preview_file', false);

    query.addIncludes('source', true);
    query.addIncludes('source.logo', false);
    query.addIncludes('source.categories', false);
    query.addIncludes('target', true);
    query.addIncludes('target.logo', false);
    query.addIncludes('target.categories', false);
    query.setPagination(10000, 0);

    return query;
}

/**
 * this method make Invitations Query With Filters
* @param includeInvitations, this param check includeInvitations
 * @param includeOffers, this param check include Offers
 * @param pagination, this param get pagination
 * @param filterArray, this param get filters
 * @param sort, this param get sort object
 */
export function makeInvitationsQueryWithFilters(
    includeInvitations: boolean = false,
    includeOffers: boolean = false,
    pagination?: { pageSize: number; page: number },
    filterArray: FilterGroup[] = [],
    sort?: { key: string; direction: SortOrder },
) {
    let query = new ApiQueryBuilder();

    if (filterArray.length !== 0) {
        query.addFilterGroupList(filterArray);
    }

    if (sort) {
        query.addSort(sort.key, sort.direction);
    }

    query.addIncludes('ad', true);
    query.addIncludes('ad.campaign', false);
    query.addIncludes('ad.time_tables', false);
    query.addIncludes('ad.content.medias.file', false);
    query.addIncludes('ad.content.medias.preview_file', false);

    query.addIncludes('source', true);
    query.addIncludes('source.logo', false);
    query.addIncludes('source.categories', false);
    query.addIncludes('target', true);
    query.addIncludes('target.logo', false);
    query.addIncludes('target.categories', false);

    if (pagination) {
        query.setPagination(pagination.pageSize, pagination.page);
    }

    return query;
}

/**
 * this method make Influencers Query By Id
 */
export function makeInfluencersQueryById(): ApiQueryBuilder {
    let query = new ApiQueryBuilder();

    query.addIncludes('integrations', false);
    query.addIncludes('logo', false);
    query.addIncludes('integrations.picture', false);
    query.addIncludes('integrations.quality_score', false);
    query.addIncludes('received_offers', false);
    query.addIncludes('offers', false);
    query.addIncludes('offers.content', false);

    return query;
}

const forceElastic: boolean = isStaging();

/**
 * this method make Influencers Query By Hashtag
 * @param hashtag, this param get hashtag 
*/
export function makeInfluencersQueryByHashtag(hashtag: string): ApiQueryBuilder {
    let query = new ApiQueryBuilder();

    let queryParams = {
        followers: {
            min: !isStaging() ? 5000 : 0,
        },
    };

    query.setQueryParams(queryParams);
    query.addIncludes('integrations', true);
    query.addIncludes('integrations.picture', false);
    query.addIncludes('received_offers', false);
    query.addIncludes('categories', false);
    query.addQueryParam('hashtags', [hashtag]);

    if (forceElastic) {
        query.addQueryParam('elastic', 1);
    }
    query.setPagination(9, 0);

    return query;
}

/**
 * this method make Influencers Query By Category
 * @param category, this param get category 
*/
export function makeInfluencersQueryByCategory(category: number): ApiQueryBuilder {
    let query = new ApiQueryBuilder();

    let queryParams = {
        followers: {
            min: !isStaging() ? 5000 : 0,
        },
    };

    query.setQueryParams(queryParams);
    query.addIncludes('integrations', true);
    query.addIncludes('integrations.picture', false);
    query.addIncludes('received_offers', false);
    query.addIncludes('categories', false);
    query.addQueryParam('categories', [category]);

    if (forceElastic) {
        query.addQueryParam('elastic', 1);
    }
    query.setPagination(9, 0);

    return query;
}

/**
 * this method make Influencers Query By Location
 * @param country, this param country 
*/
export function makeInfluencersQueryByLocation(country: string): ApiQueryBuilder {
    let query = new ApiQueryBuilder();

    let queryParams = {
        followers: {
            min: !isStaging() ? 5000 : 0,
        },
    };

    query.setQueryParams(queryParams);
    query.addIncludes('integrations', true);
    query.addIncludes('integrations.picture', false);
    query.addIncludes('received_offers', false);
    query.addIncludes('categories', false);
    query.addQueryParam('country', country);
    if (forceElastic) {
        query.addQueryParam('elastic', 1);
    }
    query.setPagination(9, 0);

    return query;
}

/**
 * this method make Influencers Custom Query
 * @param queryParams, this param get parametrs query
 * @param pageOffset, this param get pageOffset
*/
export function makeInfluencersCustomQuery(queryParams: any, pageOffset: number = 0): ApiQueryBuilder {
    let query = new ApiQueryBuilder();

    query.setQueryParams(queryParams);
    query.addIncludes('received_offers', false);
    query.addIncludes('categories', false);
    query.addIncludes('integrations', true);
    query.addIncludes('integrations.picture', false);
    query.addIncludes('integrations.metric', false);
    if (forceElastic) {
        query.addQueryParam('elastic', 1);
    }
    query.setPagination(20, pageOffset);

    return query;
}

/**
 * this method notification Query
*/
export function notificationQuery() {
    let query = new ApiQueryBuilder();
    query.addSort('created_at', SortOrder.DESCENDING);
    query.setPagination(1000, 0);

    return query;
}

/**
 * this method make Hashtag Custom Query
 * @param hashtag, this param get hashtag
*/
export function makeHashtagCustomQuery(hashtag: string, n: number = 15): ApiQueryBuilder {
    let query = new ApiQueryBuilder();

    query.addQueryParam('hashtag', hashtag);
    query.addQueryParam('n', n);

    return query;
}

/**
 * this method ad New CustomQuery
 * @param pagination, this param get pagination
*/
export function adNewCustomQuery(pagination?: { pageSize: number; page: number }) {
    let query = new ApiQueryBuilder();

    query.addQueryParam('sorts', [{ key: 'updated_at', direction: 'DESC' }]);
    query.addQueryParam('limit', pagination.pageSize);
    query.addQueryParam('page', pagination.page);
    return query;
}

/**
 * this method newOfferQuery
 * @param pagination, this param get pagination
*/
export function newOfferQuery(pagination?: { pageSize: number; page: number }) {
    let query = new ApiQueryBuilder();

    query.addQueryParam('sorts', [{ key: 'updated_at', direction: 'DESC' }]);
    query.addQueryParam('limit', pagination.pageSize);
    query.addQueryParam('page', pagination.page);
    //query.addQueryParam("state", null);

    return query;
}

/**
 * this method Admin Payment
 * @param pagination, this param get pagination
*/
export function AdminPayment(pagination?: { pageSize: number; page: number; search: any }, sort?: string, type?: string) {
    let query = new ApiQueryBuilder();

    query.addQueryParam('sorts', [{ key: sort ? sort : 'updated_at', direction: 'desc' }]);
    query.addQueryParam('limit', pagination.pageSize);
    query.addQueryParam('page', pagination.page);
    query.addQueryParam('type', type ? type : 'brand');
    if (pagination.search) {
        query.addQueryParam('search_query', pagination.search);
    }

    return query;
}
