import React, { Component, Fragment } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";

import {
  Colxx,
  Separator,
} from "../../../../template/components/common/CustomBootstrap";
import { BreadCrumb } from "../../../common-components";
import EditUserProfileInfo from "./EditUserProfileInfo";
import EditBrandInfo from "./EditBrandInfo";

interface Props extends RouteComponentProps {}

class EditAccount extends Component<Props> {
  render() {
    return (
      <Fragment>
        <Colxx xxs="12">
          <BreadCrumb heading="Edit Account" {...this.props} />
          <Separator className="mb-5" />
        </Colxx>
        <Colxx xxs="12" className="mb-4">
          <Card>
            <CardBody>
              <CardTitle>Personal Info</CardTitle>
              <EditUserProfileInfo {...this.props} />
              <hr />
              <CardTitle>Brand Info</CardTitle>
              <EditBrandInfo />
            </CardBody>
          </Card>
        </Colxx>
      </Fragment>
    );
  }
}

export default withRouter(EditAccount);
