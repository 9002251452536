import { flow, getSnapshot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { RouterModel } from 'mst-react-router';
import { ContentStore } from './ContentStore';
import BaseTransport from '../lib/BaseTransport';
import { PublishJobStore } from './PublishJobStore';
import { HashtagsStore } from './HashtagsStore';
import { IntegrationStore } from './IntegrationStore';
import { NotificationStore } from './NotificationStore';
import { CategoryModel } from './models/CategoryModel';
import { CountryModel, ICountryModel } from './models/CountryModel';
import ApiQueryBuilder from '../lib/ApiQueryBuilder';
import BatchTransport from '../lib/BatchTransport';
import { CampaignStore } from './CampaignStore';
import { AdStore } from './AdStore';
import { OfferStore } from './OfferStore';
import { IdentityStore } from './IdentityStore';
import { ChatStore } from './ChatStore';
import { connectReduxDevtools } from 'mst-middlewares';
import { UserStore } from './UserStore';
import { FileStore } from './FileStore';
import { NotificationTemplateStore } from './NotificationTemplateStore';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { InvitationStore } from './InvitationStore';
import Transport from '../lib/Transport';
import { getAppInfo, makeTokenUserQuery } from './common/RequestHelpers';
import { handleError, showLoading } from '../lib/Utils';
import { DiscoverInfluencersStore } from './DiscoverStore';
import EventEmitter from 'events';
import {CountriesList} from './../constants/countriesList'

export const routerModel = RouterModel.create();

const staticDataEventHandler: NodeJS.EventEmitter = new EventEmitter();

export const RootStoreModel: any = types
    .model({
        contentStore: ContentStore,
        publishJobStore: PublishJobStore,
        integrationStore: IntegrationStore,
        identityStore: IdentityStore,
        campaignStore: CampaignStore,
        adStore: AdStore,
        notificationTemplateStore: NotificationTemplateStore,
        notificationStore: NotificationStore,
        offerStore: OfferStore,
        discoverInfluencersStore: DiscoverInfluencersStore,
        invitationStore: InvitationStore,
        hashtagsStore: HashtagsStore,
        fileStore: FileStore,
        chatStore: types.late<typeof ChatStore>(() => ChatStore),
        isLoading: types.boolean,
        staticDataFetched: types.boolean,
        users: UserStore,
        loggedIn: types.boolean,
        token: types.maybeNull(types.string),
        router: RouterModel,
        categories: types.array(CategoryModel),
        countries: types.array(CountryModel),
    })
    .actions((self) => ({

        /**
        * this method fetch Static Data
        */
        fetchStaticData: flow(function* (): any {
            if (self.isLoading) {
                return yield new Promise((resolve, reject) => {
                    staticDataEventHandler.on('ready', () => {
                        resolve();
                    });
                    staticDataEventHandler.on('failed', () => {
                        reject();
                    });
                });
            }

            if (!self.staticDataFetched) {
                self.isLoading = true;

                let batch = new BatchTransport();

                let query = new ApiQueryBuilder();
                query.setPagination(0, 0);

                batch.get('categories', query);
                // batch.get('countries', query);

                try {
                    let all = yield batch.all();

                    self.categories = all.data[0]['categories'];

                    let countries = CountriesList

                    let countriesCode = CountriesList.map((country: any) => country.code);

                    let usIndex = countriesCode.indexOf('US');
                    let caIndex = countriesCode.indexOf('CA');
                    let us = CountriesList[usIndex];
                    let ca = CountriesList[caIndex];

                    countries = CountriesList
                        .filter((country: ICountryModel) => country.code !== 'US')
                        .filter((country: ICountryModel) => country.code !== 'CA');

                    countries.unshift(ca, us);

                    // self.countries = countries;

                    self.staticDataFetched = true;
                    self.isLoading = false;
                    staticDataEventHandler.emit('ready');
                } catch (e) {
                    staticDataEventHandler.emit('failed');
                    handleError(e);
                    self.isLoading = false;
                    throw e;
                }
            }
        }),
    }))
    .views((self) => ({

        /**
        * this method get Category Select Options
        */
        getCategorySelectOptions(): any[] {
            let mainCategories = self.categories.filter((val) => {
                return val.parent_id == null;
            });
            let options: any[] = [];

            for (let major of mainCategories) {
                let option: any = {
                    label: major.title,
                    options: [],
                };

                let sub = self.categories.filter((val) => {
                    return val.parent_id === major.id;
                });

                for (let minor of sub) {
                    option['options'].push({ value: minor.id, label: minor.title, slug: minor.slug });
                }

                option['options'].push({
                    value: major.id,
                    label: major.title + ' (Other)',
                    slug: major.slug,
                });

                options.push(option);
            }
            return options;
        },
    }));

export interface IRootStore extends Instance<typeof RootStoreModel> { }
export interface IRootStoreSnapshotIn extends SnapshotIn<typeof RootStoreModel> { }
export interface IRootStoreSnapshotOut extends SnapshotOut<typeof RootStoreModel> { }

let instance: IRootStore = RootStoreModel.create({
    hashtagsStore: {
        hashtags: [],
    },
    contentStore: {
        contentList: [],
    },
    publishJobStore: {
        publishJobList: [],
    },
    integrationStore: {
        integrations: [],
    },
    identityStore: {
        identities: [],
    },
    discoverInfluencersStore: {
        influencersList: [],
    },
    campaignStore: {
        campaigns: [],
    },
    invitationStore: {
        invitations: [],
    },
    adStore: {
        ads: [],
    },
    notificationTemplateStore: {
        notificationTemplates: [],
    },
    notificationStore: {
        notifications: [],
    },
    offerStore: {
        offers: [],
    },
    fileStore: {
        files: [],
    },
    chatStore: {
        dialogueList: [
            {
                id: '5db3f01aca8bf4206dab28f0',
                typingUserId: null,
                isTyping: false,
                messages: [],
            },
        ],
    },
    users: {
        users: [],
        currentUser: null,
        adminUsers: [],
    },

    loggedIn: false,
    staticDataFetched: false,
    isLoading: false,
    token: null,
    router: routerModel,
});

// used for hot module reloading
export function updateDefinitions() {
    const snapshot = getSnapshot(instance);
    instance = RootStoreModel.create(snapshot);
}

// extract this
let firstRequestedLocation: string | null = null;

export function setFirstLocation(loc: string) {
    if (firstRequestedLocation === null) {
        firstRequestedLocation = loc;
    }
}

let token = Cookies.get('token');
let refresh_token = Cookies.get('refresh_token');
let user_id = Cookies.get('user_id');
if (typeof token !== 'undefined') {
    BaseTransport.token = token;

    showLoading('Loading', '');

    (async () => {
        try {
            let response = null;
            try {
                response = await Transport.get<any>(`users/${user_id}`, makeTokenUserQuery());
            } catch (e) {
                response = await Transport.post<any>(
                    'auth/refresh',
                    {
                        refresh_token,
                        app: getAppInfo(),
                    },
                    makeTokenUserQuery(),
                );
            }

            Swal.close();
            console.log('response.data: ', response.data);
            instance.integrationStore.syncMissingModels(response.data['integrations']);
            instance.identityStore.syncMissingModels(response.data['identities']);
            instance.offerStore.syncMissingModels(response.data['offers']);
            instance.users.setLoggedInUser(response.data.user, firstRequestedLocation);
        } catch (e) {
            handleError(e);
            BaseTransport.token = '';
            Swal.close();
            Swal.fire({
                type: 'info',
                title: 'Please log in again!',
                text: 'Your previous login session seems to be expired! please log in again!',
            });
        }
    })();
}

declare var productionMode: boolean;

if (!productionMode) {
    try {
        connectReduxDevtools(require('remotedev'), instance);
    } catch (e) { }
}

export default instance;
