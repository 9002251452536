import React, { Fragment } from "react";
import { RouteComponentProps } from "react-router";
import {
  Colxx,
  Separator,
} from "../../../template/components/common/CustomBootstrap";
import { Row } from "reactstrap";
import { BreadCrumb } from "../../common-components";

interface State {
  messageInput: string;
}

export default class CounterOfferPage extends React.Component<
  RouteComponentProps,
  State
> {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadCrumb heading="Make A Counter-Offer" {...this.props} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4"></Colxx>
        </Row>
      </Fragment>
    );
  }
}
