import React, { Component } from "react";
import { Form, Formik } from "formik";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { Moment } from "moment";
import { FormikDatePicker } from "./index";

interface Props {
  isOpen: boolean;
  onChange?: Function;
  name: string;
  toggle: Function;
}
interface State {
  isOpen: boolean;
  range_list: Range[];
}

type Range = {
  start: Moment;
  end: Moment;
};

const RangeSchema = Yup.object().shape({
  start: Yup.date().required("Start date required"),
  end: Yup.date()
    .when(
      ["start"],
      (start: any, schema: any) =>
        start && schema.min(start, "End date must be after start date")
    )
    .required("End date required"),
});

export default class DateRangeFormik extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen,
      range_list: [],
    };
    this.addRange = this.addRange.bind(this);
  }
  closeModal() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
    this.props.toggle(this.props.name);
  }

  addRange(values: Range) {
    if (!values.start) {
      alert("Please enter a date/time...");
      return;
    }
    if (!values.end) {
      alert("Please enter the end date/time...");
      return;
    }

    this.setState(
      (prevState: State) => ({
        range_list: [
          ...prevState.range_list,
          {
            start: values.start,
            end: values.end,
          },
        ],
      }),
      () => this.props.onChange(this.props.name, values)
    );
    this.closeModal();
  }
  render() {
    return (
      <Modal isOpen={this.state.isOpen} toggle={this.closeModal.bind(this)}>
        <ModalHeader>Pick a Range</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              start: null,
              end: null,
            }}
            validationSchema={RangeSchema}
            onSubmit={(values: Range) => {
              this.addRange(values);
            }}
          >
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form
                translate={"no"}
                className="av-tooltip tooltip-label-right panel-form"
              >
                <FormGroup className="error-l-100">
                  <Label className="d-block position-relative">
                    Start date
                    {errors.start && touched.start && (
                      <div className="invalidFeedback">{errors.start}</div>
                    )}
                  </Label>
                  <FormikDatePicker
                    name="start"
                    value={values.start}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    // @ts-ignore
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="LLL"
                    timeCaption="Time"
                  />
                </FormGroup>

                <FormGroup className="error-l-100">
                  <Label className="d-block position-relative">
                    End date
                    {errors.end && touched.end && (
                      <div className="invalidFeedback">{errors.end}</div>
                    )}
                  </Label>
                  <FormikDatePicker
                    // ref={(ref) => (this.end = ref)}
                    name="end"
                    value={values.end}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    // @ts-ignore
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="LLL"
                    timeCaption="Time"
                  />
                </FormGroup>

                <div className="schedule-select-field-buttons">
                  <Button color="success" type="submit">
                    Add
                  </Button>{" "}
                  <Button
                    color="secondary"
                    onClick={this.closeModal.bind(this)}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}
