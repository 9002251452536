import React, { useState } from "react";
import { Card, CardTitle, CardText, Button } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";
import Swal from "sweetalert2";
import { truncate } from "lodash";

import { AdPreviewModal, CampaignForm } from "../../../common-components";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import { IAdModel } from "../../../../store/models/AdModel";
import { ICampaignModel } from "../../../../store/models/CampaignModel";
import RootStore from "../../../../store/RootStore";

interface Props extends RouteComponentProps {
  campaign: ICampaignModel;
}

export default function CampaignCard(props: Props) {
  const [displayAdPreview, setDisplayAdPreview] = useState<boolean>(false);
  const [displayCampaignForm, setDisplayCampaignForm] =
    useState<boolean>(false);
  const [ad, setAd] = useState<IAdModel>(null);

  const removeCampaign = async () => {
    const result = await Swal.fire({
      title: "Are you sure you want to delete this campaign",
      text: "You wont be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#cc3733",
      cancelButtonColor: "#3085d6",
      type: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    });

    if (!result.dismiss) {
      try {
        await RootStore.campaignStore.removeCampaign(props.campaign.id);
        await Swal.fire(
          "Deleted!",
          "The campaign was successfully deleted.",
          "success"
        );
      } catch (e) {
        handleError(e);
        ajaxErrorAlert("There was a problem deleting this campaign.");
      }
    }
  };

  return (
    <>
      <CampaignForm
        onClose={() => setDisplayCampaignForm(false)}
        isOpen={displayCampaignForm}
        campaign={props.campaign}
        onEditCampaign={async (values) => {
          RootStore.campaignStore.updateCampaign(props.campaign.id, values);
        }}
      />
      <AdPreviewModal
        isOpen={displayAdPreview}
        ad={ad}
        onClose={() => setDisplayAdPreview(false)}
      />
      <Card className="CampaignCard">
        <div className="CampaignDescription">
          <CardTitle>{props.campaign.title}</CardTitle>
          <CardText>
            {truncate(props.campaign.description, { length: 70 })}
          </CardText>
          <CardText className="Ads">
            Ads :{" "}
            {RootStore.adStore.getAdsByCampaign(props.campaign.id).length ===
            0 ? (
              <span>There is no Ad for this campaign </span>
            ) : (
              RootStore.adStore
                .getAdsByCampaign(props.campaign.id)
                .map((ad: IAdModel) => (
                  <span
                    className="AdSpan"
                    onClick={(event: React.MouseEvent) => {
                      setAd(ad);
                      setDisplayAdPreview(true);
                    }}
                    key={ad.id}
                  >
                    {truncate(ad.title, { length: 25 })}
                  </span>
                ))
            )}
          </CardText>
        </div>
        <div className="CampaignButtons">
          <Button
            onClick={(event: React.MouseEvent) => setDisplayCampaignForm(true)}
            className="btn btn-primary CampaignBtn"
          >
            Edit
          </Button>
          <div
            className="btn btn-danger CampaignBtn"
            onClick={(event: React.MouseEvent) => removeCampaign()}
          >
            Remove
          </div>
        </div>
      </Card>
    </>
  );
}
