import React, { ChangeEvent, useState } from "react";
import classNames from "classnames";

import { EyeIcon } from "../../assets/icons";

interface Props {
  name?: string;
  id?: string;
  value?: any;
  className?: string;
  placeholder?: string;
  onChange?(name: string, value: any): void;
}

export function PasswordField(props: Props) {
  const { name, id, value, className, placeholder, onChange } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="position-relative">
      <input
        name={name}
        value={value}
        id={id}
        className={className}
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange(name, event.currentTarget.value)
        }
      />
      <div
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          setShowPassword(!showPassword)
        }
        className={classNames("showPasswordIcon", {
          showPasswordIcon_show: showPassword,
        })}
      >
        <EyeIcon title="Show / Hide Password" />
      </div>
    </div>
  );
}
