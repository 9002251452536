import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import classes from './style.module.scss';
import ObjectivesLoader from './parts/loader';
import icon1 from '../../../../../assets/img/objectives/1.svg';
import icon2 from '../../../../../assets/img/objectives/2.svg';
import icon3 from '../../../../../assets/img/objectives/3.svg';
import icon4 from '../../../../../assets/img/objectives/4.svg';
import NextButton from '../../../dashboard/multi-step-form/onboarding/parts/button';
import Transport from '../../../../../lib/Transport';
import createAdContext from '../../context';
import OnboardPreview from '../../../dashboard/multi-step-form/onboarding/parts/preview';
import CreatAdLayout from '../../layout';
import Swal from 'sweetalert2';
import BaseTransport from '../../../../../lib/BaseTransport';
import API from '../../../../../lib/axiosSetup';
import { NarrowArrowIcon } from '../../../../../assets/icons';

function Objectives(props: any) {
    const [List, setList] = useState(null);
    const {
        baseUrl,
        oldData,
        data: { adID, objectives },
        updateOnboard,
    } = useContext(createAdContext);
    const [Process, setProcess] = useState(false);

    useEffect(() => {
        Transport.get('v2/ad/goal-questions').then(
            (res) => res?.data && setList(res?.data?.goal_questions[0]?.answers),
        );
    }, []);

    const submit = () => {
        if (!objectives.length) {
            return Swal.fire({
                text: 'Please select one or more Ad objectives. This help us connect you with right influencers.',
                type: 'error',
                allowEscapeKey: true,
                allowOutsideClick: true,
            });
        }
        if (adID) {
            setProcess(true);

            if (oldData && JSON.stringify(objectives) === JSON.stringify(oldData.goal_answers))
                return props.history.push(`${baseUrl}/marketplace/${props.match.params?.adID || ''}`);

            API.put(BaseTransport.getEndPointPath(`v2/ads/${adID}`), {
                goal_answers: objectives,
            })
                .then(() => {
                    setProcess(false);
                    props.history.push(`${baseUrl}/marketplace/${props.match.params?.adID || ''}`);
                })
                .catch(function (error) {
                    setProcess(false);
                    if (error.response?.data?.message) {
                        Swal.fire({
                            type: 'error',
                            text: error.response.data.message,
                            showCancelButton: false,
                            showConfirmButton: true,
                        });
                    }
                });
        } else {
            props.history.push(`${baseUrl}/marketplace/${props.match.params?.adID || ''}`);
        }
    };

    const updateObjectives = (value: any) => {
        updateOnboard((prev: any) =>
            prev.objectives.includes(value.id)
                ? { ...prev, objectives: prev.objectives.filter((key: any) => key !== value.id) }
                : { ...prev, objectives: [...prev.objectives, value.id] },
        );
    };

    const icons = [icon1, icon2, icon3, icon4];

    return (
        <>
            <CreatAdLayout
                head={{
                    title: 'Objectives with influencer marketing',
                    paragraph: 'Set the type of collaborations or ads you want to run with influencers.',
                }}
            >
                <Grid container>
                    <Grid item container className={classes.objectives}>
                        <Grid item container sm={7} flexDirection="column" className={classes.list}>
                            <Grid item className={classes.title} marginBottom={4}>
                                What would you like to achieve with influencers?
                            </Grid>
                            {List ? (
                                List.map((el: any, key: number) => (
                                    <Grid
                                        item
                                        container
                                        wrap="nowrap"
                                        alignItems="center"
                                        component="li"
                                        onClick={() => updateObjectives(el)}
                                        className={objectives && objectives.includes(el.id) ? classes.active : ''}
                                    >
                                        <Grid item>
                                            <img src={icons[key]} alt={el.title} />
                                        </Grid>
                                        <Grid item marginLeft={2}>
                                            {el.title}
                                        </Grid>
                                    </Grid>
                                ))
                            ) : (
                                <ObjectivesLoader />
                            )}
                            <Grid item>
                                <NextButton loading={Process} onClick={submit}>
                                    {!adID && (
                                        <>
                                            Next <NarrowArrowIcon className="ml-2" />
                                        </>
                                    )}
                                </NextButton>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            sm={5}
                            display={{ xs: 'none', lg: 'flex', sm: 'flex', md: 'flex' }}
                            justifyContent="center"
                        >
                            <Grid item sm={6}>
                                <OnboardPreview />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CreatAdLayout>
        </>
    );
}

export default withRouter(Objectives);
