import { destroy, flow, getSnapshot, IAnyModelType, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { defaultProfilePicture } from '../../constants/defaultValues';
import { IdentityModel, IIdentityModel, IIdentityModelSnapshotIn } from './IdentityModel';
import Transport from '../../lib/Transport';
import { IIntegrationModel, IntegrationModel } from './IntegrationModel';
import RootStore from '../RootStore';
import { TokenModel } from './TokenModel';
import { WalletModel } from './WalletModel';
import { makeIdentityQuery } from '../common/RequestHelpers';

/**
* this method User Model
*/
export const UserModel = types
    .model({
        first_name: types.string,
        last_name: types.maybeNull(types.string),
        email: types.string,
        profilePicture: types.optional(types.string, defaultProfilePicture),
        integrations: types.array(types.reference(types.late((): IAnyModelType => IntegrationModel))),
        token: types.maybeNull(TokenModel),
        id: types.identifierNumber,
        is_admin: types.boolean,
        is_super_admin: types.maybeNull(types.boolean),
        created_at: types.maybeNull(types.string),
        updated_at: types.maybeNull(types.string),
        email_verified_at: types.maybeNull(types.string),
        deleted_at: types.maybeNull(types.string),
        is_brand: types.maybeNull(types.boolean),
        is_influencer: types.maybeNull(types.boolean),
        created_campaign: types.maybeNull(types.boolean),
        created_ad: types.maybeNull(types.boolean),
        wallet: types.maybeNull(WalletModel),
        offer_count: types.maybeNull(types.number),
        negotiating_offer_count: types.maybeNull(types.number),
        waiting_offer_count: types.maybeNull(types.number),
        notifications: types.maybeNull(types.number),
        ad_count: types.maybeNull(types.number),
        received_invitation_count: types.maybeNull(types.number),
        sent_invitation_count: types.maybeNull(types.number),
        campaign_count: types.maybeNull(types.number),
        notifications_unread: types.maybeNull(types.number),
        registered_platform: types.maybeNull(types.string),
        invited_via: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        type: types.maybeNull(types.enumeration(['influencer', 'brand'])),
    })
    // .preProcessSnapshot(
    //     snapshot => {
    //         if(snapshot.integrations){
    //             snapshot.integrations = snapshot.integrations.map((integration:IIntegrationModel)=>{
    //                 return integration.id;
    //             });
    //             for(let integration of snapshot.integrations){
    //                 RootStore.integrationStore.pushModel(integration);
    //             }
    //         }
    //         return snapshot;
    //     }
    // )
    .volatile((self) => ({
        isBusy: false,
    }))
    .actions((self) => ({

        /**
        * this method check processing for this store
        */
        setBusy() {
            self.isBusy = true;
        },

        /**
        * this method just set value isBusy = false
        */
        setIdle() {
            self.isBusy = false;
        },

        /**
        * this method set Created Ad
        */
        setCreatedAd() {
            self.created_ad = true;
        },

        /**
        * this method set Created Campaign
        */
        setCreatedCampaign() {
            self.created_campaign = true;
        },
        
        /**
        * this method increase Invitations Count
        */
        increaseInvitationsCount(count: number) {
            self.sent_invitation_count += count;
        },
        
        /**
        * this method increase Campaigns Count
        */
        increaseCampaignsCount() {
            self.campaign_count += 1;
        },

        /**
        * this method increase Ads Count
        */
        increaseAdsCount() {
            self.ad_count += 1;
        },
    }))
    .actions((self) => ({
        
        /**
        * this method create Identity
        */
        createIdentity: flow(function* (snapshot: IIdentityModelSnapshotIn): any {
            self.setBusy();
            try {
                let response = yield Transport.post(
                    'identities',
                    {
                        identity: snapshot,
                    },
                    makeIdentityQuery(),
                );

                RootStore.identityStore.addIdentity(IdentityModel.create(response.data.identity));
                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method add Integration
        * @param model, this param get Integration for push
        */
        addIntegration(model: IIntegrationModel) {
            self.integrations.push(model.id);
        },

        /**
        * this method set Name
        * @param model, this param name
        */
        setName(name: string) {
            self.first_name = name;
        },

        /**
        * this method set Name
        * @param ig, this param Integration for removed
        */
        removeIntegration(ig: IIntegrationModel) {
            self.integrations.replace(self.integrations.filter((integration) => integration.id !== ig.id));
            destroy(ig);
        },
    }))
    .views((self) => ({

        /**
        * this method get FullName
        */
        getFullName() {
            return `${self.first_name} ${self.last_name}`;
        },

        /**
        * this method get Identities
        */
        getIdentities(): IIdentityModel[] {
            return RootStore.identityStore.identities.filter(
                (identity: IIdentityModel) => getSnapshot(identity).user_id === self.id,
            );
        },
    }));

export interface IUserModel extends Instance<typeof UserModel> {}
export interface IUserModelSnapshotIn extends SnapshotIn<typeof UserModel> {}
export interface IUserModelSnapshotOut extends SnapshotOut<typeof UserModel> {}
