import { Filter, FilterGroup, SortOrder } from "./QueryTypes";

export default class ApiQueryBuilder {
  private queryParams: any = {};

  public addIncludes(model: string, sideLoad: boolean) {
    if (typeof this.queryParams["includes"] == "undefined") {
      this.queryParams["includes"] = [];
    }

    this.queryParams["includes"].push(model + (sideLoad ? ":sideload" : ""));
    this.queryParams["includes"].push('categories');

    // @ts-ignore
    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    this.queryParams["includes"] = this.queryParams.includes.filter(onlyUnique);
  }

  public addIncludesUser(model: string, sideLoad: boolean) {
    if (typeof this.queryParams["includes"] == "undefined") {
      this.queryParams["includes"] = [];
    }

    this.queryParams["includes"].push(model + (sideLoad ? ":sideload" : ""));

    // @ts-ignore
    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    this.queryParams["includes"] = this.queryParams.includes.filter(onlyUnique);
  }

  public addIncludesIntegration(model: string, sideLoad: boolean) {
    if (typeof this.queryParams["includes"] == "undefined") {
      this.queryParams["includes"] = [];
    }

    this.queryParams["includes"].push(model + (sideLoad ? ":sideload" : ""));

    // @ts-ignore
    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    this.queryParams["includes"] = this.queryParams.includes.filter(onlyUnique);
  }

  public setPagination(limit: number, page: number) {
    this.queryParams["limit"] = limit;
    this.queryParams["page"] = page;
  }

  // new search method
  public setSearch(search: string) {
    this.queryParams["search"] = search;
  }

  // new category method
  public setCategory(category: string) {
    this.queryParams["category"] = category;
  }

  // new type method
  public setType(category: string) {
    this.queryParams["type"] = category;
  }

  public addSort(column: string, order: SortOrder) {
    if (typeof this.queryParams["sort"] == "undefined") {
      this.queryParams["sort"] = [];
    }
    this.queryParams["sort"].push({
      key: column,
      direction: order,
    });
  }

  public addFilterGroup(filters: Filter[], isOr: boolean = false) {
    console.log('filters ',filters)
    filters = filters.map((filter) => {
      // @ts-ignore
      filter.not = filter.not ? 1 : 0;
      return filter;
    });

    if (typeof this.queryParams["filter_groups"] == "undefined") {
      this.queryParams["filter_groups"] = [];
    }
    this.queryParams["filter_groups"].push({
      or: isOr,
      filters: filters,
    });
  }

  public addFilterGroupList(filterGroups: FilterGroup[]) {
    for (let filterGroup of filterGroups) {
      this.addFilterGroup(filterGroup.filters, filterGroup.isOr);
    }
  }

  public addThumbnail(width: number, height: number) {
    this.queryParams["thumbnail"] = width + "x" + height;
  }

  public addThumbnailByType(data: any) {
    this.queryParams["thumbnail"] = data;
  }

  public addQueryParam(name: string, data: any) {
    this.queryParams[name] = data;
  }

  public setQueryParams(queryParams: any) {
    this.queryParams = queryParams;
  }

  public getQueryData() {
    return this.queryParams;
  }
}
