import React from 'react';
import { Button, Spinner, Row, Col } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { Separator } from '../../../../template/components/common/CustomBootstrap';
import { BreadCrumb } from '../../../common-components';
import RootStore from '../../../../store/RootStore';
import { CreditCardIcon } from '../../../../assets/icons';
import CreditCard from './CreditCard';
import { observer } from 'mobx-react';
import { IPaymentMethodModel } from '../../../../store/models/PaymentMethodModel';

interface Props extends RouteComponentProps {}

function CardsList(props: Props) {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap align-items-center pr-2">
                <div>
                    <BreadCrumb heading="Cards List" {...props} />
                </div>
                <div className="card-list-btns">
                    <Button
                        color="success"
                        style={{ fontSize: '1.2em' }}
                        className="pl-4 pr-4"
                        onClick={() => props.history.push('/app/account/payment/add-new-card')}
                    >
                        Add New Card
                    </Button>
                    <Button
                        color="secondary"
                        style={{ fontSize: '1.2em' }}
                        className="pl-4 pr-4 ml-4"
                        onClick={() => props.history.push('/app/account/payment/add-funds')}
                    >
                        Add Fund
                    </Button>
                </div>
            </div>
            <Separator className="mb-3 mt-3" />

            {RootStore?.users?.currentUser?.wallet?.isBusy ? (
                <div className="card-list-loading">
                    <Spinner color="primary" className="mb-1" />
                    <p>Please wait...</p>
                </div>
            ) : RootStore.users.currentUser.wallet.methods.length === 0 ? (
                <div className="emptyStateContainer">
                    <CreditCardIcon style={{ padding: '1em', marginBottom: '1em' }} />
                    <h2>No credit cards have been added yet</h2>
                    <p>Adding a credit card is required for making influencer payments.</p>
                    <div className="invoicesEmptyState__buttons">
                        <Button color="success" onClick={() => props.history.push('/app/account/payment/add-new-card')}>
                            Add Credit Card
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="manageCards">
                    <p className="manageCards__title">Add or remove your credit cards below</p>
                    <p className="manageCards__description">Here is the list of all cards you have added so far:</p>

                    <Row>
                        {/* className="cardsList" */}
                        {RootStore?.users?.currentUser?.wallet?.methods?.map((method: IPaymentMethodModel) => (
                            <>
                                <Col lg={4} md={4} xs={12} className={'creditCardSlide'}>
                                    <CreditCard model={method} />
                                </Col>
                                {/* <br />
                                &nbsp;
                                <br /> */}
                            </>
                        ))}
                    </Row>
                </div>
            )}
        </>
    );
}

export default observer(CardsList);
