import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import { CloseIcon, NarrowArrowIcon, PlusIcon, SearchIcon } from "../../../../../../../assets/icons";
import Transport from "../../../../../../../lib/Transport";
import NextButton from "../../../../../dashboard/multi-step-form/onboarding/parts/button";
import createAdContext from "../../../../context";
import { findHashtags } from "../../../../model";
import classes from './style.module.scss'

function OnboardDiscoverSearch(props: any) {
    const { mode, data: { discover: { hashtags }, createAd: { feeds: { caption } } }, updateOnboard } = useContext(createAdContext)
    const [Hashtags, setHashtags] = useState(null)
    const [Keyword, setKeyword] = useState(null)
    const [Loading, setLoading] = useState(false)

    const updateDiscoverHashtag = (value: any) => {
        if (hashtags.includes(value)) return false
        updateOnboard((prev: any) => ({ ...prev, discover: { ...prev.discover, hashtags: [...prev.discover.hashtags, value] } }))
    }

    const deleteDiscoverHashtag = (value: any) => {
        updateOnboard((prev: any) => ({ ...prev, discover: { ...prev.discover, hashtags: prev.discover.hashtags.filter((el: any) => el !== value) } }))
    }


    useEffect(() => {
        if (props.location.state?.hashtag_required) {
            Swal.fire({
                type: "error",
                title: "Please add hashtags",
                showCancelButton: true,
                showConfirmButton: true,
            });
        }
    }, [])

    useEffect(() => {
        if (caption.length && findHashtags(caption).length) {
            findHashtags(caption).map(el => updateDiscoverHashtag(el.replace('#', '')))
        }
    }, [caption])

    const searchHashtags = (e?: any) => {
        e.preventDefault();
        if (Keyword && Keyword?.length >= 3) {
            setLoading(true)
            Transport.get(`v2/categories/hashtags?search=${Keyword.replace('#', '')}`).then(res => {
                if (res?.data && res?.data.length) {
                    setHashtags(res.data)
                    setLoading(false)
                }
            })
        } else {
            setHashtags(null)
        }
    }

    return (
        <Grid container className={classes.Discover} rowSpacing={3}>
            {mode !== 'onboard' &&
                <Grid item container alignItems="center" columnSpacing={2} wrap="nowrap">
                    <Grid item>
                        <SearchIcon className={classes.icon} />
                    </Grid>
                    <Grid item>
                        <strong>Discover & Invite Influencers By Hashtags</strong>
                    </Grid>
                </Grid>
            }
            <Grid item container rowSpacing={1}>
                <form style={{ display: 'block', width: '100%' }} onSubmit={searchHashtags}>
                    {mode !== "onboard" &&
                        <Grid item>
                            Enter a few hashtags to get our AI engine started.
                        </Grid>
                    }
                    <Grid item container columnSpacing={2}>
                        <Grid item sm={9} xs={12}>
                            <input type="text" value={Keyword} onChange={(e: any) => {
                                setKeyword(e.target.value.trim())
                            }} className={classes.textfield} placeholder="Enter hashtag" />
                        </Grid>
                        <Grid item sm={3} xs={12} marginTop={{ xs: 1, sm: 0, md: 0, lg: 0 }}>
                            <Button color="secondary" className={classes.button} onClick={searchHashtags} type="submit" {...props}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            {hashtags &&
                <Grid item container className={classes.list} component="ul">
                    {hashtags && hashtags.map((el: any) => (
                        <Grid item container component="li" flexBasis="content" columnSpacing={1} alignItems="center">
                            <Grid item>
                                {el.replace('#', '')}
                            </Grid>
                            <Grid item>
                                <span onClick={() => deleteDiscoverHashtag(el)}>
                                    <CloseIcon />
                                </span>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            }
            <Grid item container>
                {!Loading && Hashtags ?
                    <ul className={classes.hashtags}>
                        {Hashtags && Hashtags.map((el: any) => !hashtags.includes(el.name) && (
                            <li onClick={() => updateDiscoverHashtag(el.name)}>
                                <div className={classes.title}>
                                    {el.name} <strong className={classes.count}>({el.mediacount})</strong>
                                </div>
                                <div className={classes.icon}>
                                    <PlusIcon />
                                </div>
                            </li>
                        ))}
                    </ul>
                    : Loading ? <span>Loading ...</span> : ''
                }
            </Grid>
            <Grid item container justifyContent="right">
                <Grid item>
                    <NextButton onClick={() => props.changeStep()}>Next <NarrowArrowIcon className="ml-2" /></NextButton>
                </Grid>
            </Grid>
        </Grid >
    );
}

export default withRouter(OnboardDiscoverSearch)
