import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
* this method Token Model
*/
export const TokenModel = types
  .model({
    id: types.identifierNumber,
    access_token: types.string,
    access_ttl: types.number,
    chat_token: types.string,
    platform: types.enumeration(["web", "ios", "android"]),
    refresh_token: types.string,
    refresh_ttl: types.number,
    firebase_token: types.maybeNull(types.string),
    user_id: types.number,
    version: types.string,
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export interface ITokenModel extends Instance<typeof TokenModel> {}

export interface ITokenModelSnapshotIn extends SnapshotIn<typeof TokenModel> {}

export interface ITokenModelSnapshotOut
  extends SnapshotOut<typeof TokenModel> {}
