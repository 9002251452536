import React from "react";
import numbro from "numbro";
import moment from "moment";
import { Link } from "react-router-dom";

import {
  IMessageModel,
  MessageType,
} from "../../../../../store/models/MessageModel";
import {
  contactSupportLink,
  tosUrl,
} from "../../../../../constants/defaultValues";
import RootStore from "../../../../../store/RootStore";

export default function OfferStatusUpdate(props: {
  msg: IMessageModel;
  offerId: number;
  performOfferAction: Function;
}) {
  const getMessage = (): JSX.Element => {
    if (props.msg.getType() === MessageType.STATUS_UPDATE) {
      if (props.msg.getStatusUpdateType() === "rejected") {
        return (
          <span>
            {props.msg.getIdentity().user_id === RootStore.users.currentUser.id
              ? "You"
              : "@" + props.msg.getOffer().getTargetIntegration().username}{" "}
            rejected the offer{" "}
            <span className={"moment"}>
              - ({props.msg.getMoment().fromNow()})
            </span>
          </span>
        );
      } else if (props.msg.getStatusUpdateType() === "accepted") {
        // accepted, awaiting payment

        if (
          props.msg.getMainOffer().status === "awaiting-payment" &&
          props.msg.isLatestBatch()
        ) {
          return (
            <span>
              You are one step away to publish your post on @
              {props.msg.getMainOffer().getTargetIntegration().username}{" "}
              {/* <Link to={`/app/offers/${props.offerId}/pay`}></Link> */}
              <span className="Make-payment-link"   onClick={() => {
                props.performOfferAction("pay");
              }}>Make the payment</span>  to secure the deal.
            </span>
          );
        } else {
          return (
            <span>
              {props.msg.getIdentity().user_id ===
              RootStore.users.currentUser.id ? (
                "You"
              ) : (
                <a
                  href={`/app/influencer-explorer/${
                    props.msg.getIdentity().id
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @{props.msg.getOffer().getTargetIntegration().username}
                </a>
              )}{" "}
              accepted the above offer{" "}
              <span className={"moment"}>
                - ({props.msg.getMoment().fromNow()})
              </span>
            </span>
          );
        }
      } else if (
        props.msg.getStatusUpdateType() === "reopened" ||
        props.msg.getStatusUpdateType() === "revised"
      ) {
        return (
          <span>
            {props.msg.getIdentity().user_id === RootStore.users.currentUser.id
              ? "You"
              : props.msg.getIdentity().name}{" "}
            {props.msg.getStatusUpdateType()} the offer{" "}
            <span className={"moment"}>
              - ({props.msg.getMoment().fromNow()})
            </span>
          </span>
        );
      } else {
        // paid
        return (
          <span>
            You paid{" "}
            {numbro(props.msg.getOffer().price)
              .formatCurrency({
                mantissa: 2,
                thousandSeparated: true,
                optionalMantissa: true,
              })
              .toUpperCase()}{" "}
            for the offer.{" "}
            {props.msg.getMainOffer().status === "paid" ? (
              <>
                The post will be published on{" "}
                {props.msg.getOffer().getTargetIntegration().full_name} on{" "}
                {moment(props.msg.getOffer().start).format("MMM Do YYYY")}.{" "}
                <Link to={`/app/scheduler`}>Visit the calendar</Link> to review
                the post. Post publishing and monitoring will be done by
                Ainfluencer and you will be notified with updates.
              </>
            ) : (
              <span className={"moment"}>
                - ({props.msg.getMoment().fromNow()})
              </span>
            )}
          </span>
        );
      }
    } else if (props.msg.getType() === MessageType.NULLIFY) {
      let alertInfo = {
        msg: "",
        color: "",
      };
      switch (props.msg.attributes.type.replace("offer:", "")) {
        case "cancelled-intime":
          if (props.msg.isSelf) {
            alertInfo = {
              msg: `You have cancelled the offer. Payment has been refunded to your Wallet. Read more about cancellations <a href="${tosUrl}" target="_blank">here</a>.`,
              color: "warning",
            };
          } else {
            alertInfo = {
              msg: `@${
                props.msg.getOffer().getTargetIntegration().username
              } has cancelled this offer. Payment has been refunded to your Wallet. Read more about cancellations <a href="${tosUrl}" target="_blank">here</a>.`,
              color: "warning",
            };
          }
          break;
        case "cancel-request":
          alertInfo = {
            msg: `You requested to cancel this offer within the 48 hour minimum notification period. A request to refund your payment has been submitted to @${
              props.msg.getOffer().getTargetIntegration().username
            }. Read more about cancellations <a href="${tosUrl}" target="_blank">here</a>.`,
            color: "info",
          };
          break;
        case "cancel-request-approved":
          alertInfo = {
            msg: `Your cancellation request was approved by @${
              props.msg.getOffer().getTargetIntegration().username
            }`,
            color: "success",
          };
          break;
        case "cancel-request-rejected":
          alertInfo = {
            msg: `Your request to cancel this offer was not approved by @${
              props.msg.getOffer().getTargetIntegration().username
            }. Click here to log a dispute. Read more about cancellations <a href="${tosUrl}" target="_blank">here</a>.`,
            color: "danger",
          };
          break;
        case "cancel-resolution":
          alertInfo = {
            msg: `Your dispute has been sent to Ainfluencer for review. A resolution will be provided shortly. Read more about cancellations <a href="${tosUrl}" target="_blank">here</a>.`,
            color: "warning",
          };
          break;
        case "cancel-ineligible":
          alertInfo = {
            msg: `Unfortunately the dispute for cancellation of this offer has been ruled in favour of @${
              props.msg.getOffer().getTargetIntegration().username
            }. The post will be published as agreed. Read more about cancellations <a href="${tosUrl}" target="_blank">here</a>.`,
            color: "warning",
          };
          break;
        case "late-cancelled":
          alertInfo = {
            msg: `The offer was cancelled by @${
              props.msg.getOffer().getTargetIntegration().username
            }. Payment has been credited to your Wallet. Read more about cancellations <a href="${tosUrl}" target="_blank">here</a>.`,
            color: "warning",
          };
          break;
        case "force-cancelled":
          alertInfo = {
            msg: `This offer has been cancelled by Ainfluencer’s compliance team. Payment has been credited to your Wallet. Read more about cancellations <a href="${tosUrl}" target="_blank">here</a>.`,
            color: "warning",
          };
          break;
        case "refund-request":
          alertInfo = {
            msg: `You have requested a refund from @${
              props.msg.getOffer().getTargetIntegration().username
            }. Read more about refunds <a href="${tosUrl}" target="_blank">here</a>.`,
            color: "warning",
          };
          break;
        case "refund-request-approved":
          alertInfo = {
            msg: `Your refund request was approved by @${
              props.msg.getOffer().getTargetIntegration().username
            } and funds have been credited to your Wallet.`,
            color: "success",
          };
          break;
        case "refund-request-rejected":
          alertInfo = {
            msg: `Your refund request was not approved by the Seller @${
              props.msg.getOffer().getTargetIntegration().username
            }. Click here to log a dispute with Ainfluencer.`,
            color: "warning",
          };
          break;
        case "refund-resolution":
          alertInfo = {
            msg: `Your dispute has been sent to Ainfluencer for review. A resolution will be provided shortly.`,
            color: "warning",
          };
          break;
        case "refund-ineligible":
          alertInfo = {
            msg: `Your refund request has not been approved by our compliance team... Read more about refunds <a href="${tosUrl}" target="_blank">here</a>.`,
            color: "warning",
          };
          break;
        case "force-refunded":
          alertInfo = {
            msg: `Your refund request has been approved by Ainfluencer. A credit has been applied to your Wallet.`,
            color: "warning",
          };
          break;
        default:
          throw new Error(
            "Invalid status update type " + props.msg.attributes.type
          );
      }

      return (
        <span
          style={{ color: alertInfo.color === "danger" ? "red" : "inherit" }}
        >
          <span dangerouslySetInnerHTML={{ __html: alertInfo.msg }} />
          {alertInfo.color === "danger" ? (
            <>
              &nbsp;<a href={contactSupportLink}>Contact Support</a>
            </>
          ) : null}
          <span className={"moment"}>
            {" "}
            - ({moment(props.msg.timestamp).fromNow()})
          </span>
        </span>
      );
    } else if (props.msg.getType() === MessageType.PUBLISH_JOB) {
      let alertInfo = {
        msg: "",
        color: "",
      };
      if (props.msg.attributes.type === "publish-job:published") {
        alertInfo = {
          msg: "The content was successfully published",
          color: "success",
        };
      } else if (props.msg.attributes.type === "publish-job:created") {
        alertInfo = {
          msg: "The content is scheduled for release",
          color: "info",
        };
      } else if (
        props.msg.attributes.type === "publish-job:publishing-failed"
      ) {
        alertInfo = {
          msg: "The content could not be published successfully on influencer's Instagram... Money will be returned to your wallet and our team would contact you soon to investigate this issue",
          color: "danger",
        };
      } else if (
        props.msg.attributes.type === "publish-job:completion-failed"
      ) {
        alertInfo = {
          msg: "The content could not be removed successfully on influencer's Instagram... Please ask him/her to remove it manually. Our team would contact you soon to investigate this issue",
          color: "danger",
        };
      } else if (props.msg.attributes.type === "publish-job:completed") {
        if (props.msg.getMainOffer().duration === 0) {
          alertInfo = {
            msg: "The content publish was successfully verified! The post will remain on influencer's page permanently, and from now on, we will only collect limited analytics for it.",
            color: "info",
          };
        } else {
          alertInfo = {
            msg: "The content was removed successfully from influencer's Instagram... The post will no longer be available, but you still can access the analytics data.",
            color: "info",
          };
        }
      } else if (props.msg.attributes.type === "publish-job:health-failed") {
        alertInfo = {
          msg: "Our system could not verify the ad's existence on influencers Instagram. The post might have been removed sooner than the time which was agreed upon",
          color: "danger",
        };
      }

      return (
        <span
          style={{ color: alertInfo.color === "danger" ? "red" : "inherit" }}
        >
          {alertInfo.msg}
          {alertInfo.color === "danger" ? (
            <>
              &nbsp;<a href={contactSupportLink}>Contact Support</a>
            </>
          ) : null}
          <span className={"moment"}>
            {" "}
            - ({moment(props.msg.timestamp).fromNow()})
          </span>
        </span>
      );
    } else {
      // cancel and refund

      throw new Error(
        "OfferStatusUpdate component can not handle message of type" +
          props.msg.getType() +
          props.msg.attributes.type
      );
    }
  };

  return (
    <div className="status-update">
      <div>{getMessage()}</div>
    </div>
  );
}
