import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
import { TabsComponent } from "../../../common-components";
import UsersTab from "./UsersTab";
import InfluencersTab from "./InfluencersTab";
import NewBrandsTab from "./NewBrandsTab";
// import RootStore from "../../../../store/RootStore";
import { Colxx } from "../../../../template/components/common/CustomBootstrap";
import { Row } from "reactstrap";
import { BreadCrumb } from "../../../common-components";
import IntegrationsTab from "./IntegrationsTab";
import SuspendedUsersTab from "./SuspendedUsersTab";
import UTMTab from "./UTMTab";

interface Props extends RouteComponentProps {}
export type Filters = {
  searchTerm: string | null;
  filters: any;
};

@observer
export default class UserManagement extends Component<Props> {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadCrumb heading="Users Management" {...this.props} />
            {/* <Separator className="mb-5" /> */}
          </Colxx>
        </Row>
        <TabsComponent
          tabs={[
            {
              tabName: "Users",
              component: <UsersTab key="UsersTab" {...this.props} />,
              to: `${this.props.match.url}`,
            },
            {
              tabName: "Influencers",
              component: (
                <InfluencersTab key="InfluencersTab" {...this.props} />
              ),
              to: `${this.props.match.url}/influencers`,
            },
            {
              tabName: "Brands",
              component: <NewBrandsTab key="BrandsTab" {...this.props} />,
              to: `${this.props.match.url}/brands`,
            },
            {
              tabName: "IG Accounts",
              component: (
                <IntegrationsTab key="IntegrationsTab" {...this.props} />
              ),
              to: `${this.props.match.url}/integrations`,
            },
            {
              tabName: "Suspended Users",
              component: (
                <SuspendedUsersTab key="SuspendedUsersTab" {...this.props} />
              ),
              to: `${this.props.match.url}/suspended-users`,
            },
            {
              tabName: "UTM",
              component: (
                <UTMTab key="UTMTab" {...this.props} />
              ),
              to: `${this.props.match.url}/utm`,
            },
          ]}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}
