const menuItems = [
    {
        id: 'dashboard',
        icon: 'iconsminds-dashboard',
        label: 'menu.dashboard',
        to: '/app/start',
    },
    {
        id: 'explorer',
        icon: 'iconsminds-magnifi-glass',
        label: 'menu.explorer',
        to: '/app/influencer-explorer',
    },
    {
        id: 'offers',
        icon: 'iconsminds-handshake',
        label: 'menu.offers',
        to: '/app/offers',
    },
    {
        id: 'ads',
        icon: 'iconsminds-megaphone',
        label: 'menu.ads',
        to: '/app/ads',
    },
    {
        id: 'scheduler',
        icon: 'iconsminds-calendar-4',
        label: 'menu.scheduler',
        to: '/app/scheduler',
    },
];

const adminMenuItems = [
    {
        id: 'dashboard',
        icon: 'iconsminds-dashboard',
        label: 'menu.dashboard',
        to: '/admin/start',
    },
    {
        id: 'users-list',
        icon: 'iconsminds-user',
        label: 'menu.users-list',
        to: '/admin/users-list',
    },
    {
        id: 'ad-management',
        icon: 'iconsminds-megaphone',
        label: 'menu.ads-management',
        to: '/admin/ads-management',
    },
    {
        id: 'publish-jobs-report',
        icon: 'iconsminds-calendar-4',
        label: 'menu.publish-jobs-report',
        to: '/admin/publish-jobs-report',
    },
    {
        id: 'payments',
        icon: 'iconsminds-mail-money',
        label: 'menu.payments',
        to: '/admin/payments',
    },
    // {
    //     id: 'transactions',
    //     icon: 'iconsminds-money-bag',
    //     label: 'menu.transactions',
    //     to: '/admin/transactions',
    // },
    {
        id: 'task-manager',
        icon: 'iconsminds-administrator',
        label: 'menu.task-manager',
        to: '/admin/task-manager',
    },
    {
        id: 'ai',
        icon: 'iconsminds-idea',
        label: 'menu.ai',
        to: '/admin/ai',
    },
    {
        id: 'charts',
        icon: 'iconsminds-line-chart-1',
        label: 'menu.charts',
        to: '/admin/charts',
    },
    {
        id: 'templates',
        icon: 'iconsminds-files',
        label: 'menu.templates',
        to: '/admin/templates',
    },
];

export { menuItems, adminMenuItems };
