import axios from 'axios'
import { servicePath } from '../constants/defaultValues'
import BaseTransport from './BaseTransport'

const API = axios.create({
	baseURL: servicePath + '/'
})

API.interceptors.request.use(
	config => {
		const token = BaseTransport.token
		if (token === null) {
			return config
		}
		config.headers = BaseTransport.getHeaders()
		return config
	},
	err => Promise.reject(err)
)
API.interceptors.response.use(
	response => {
		return response
	},
	error => {
		return Promise.reject(error)
	}
)

export default API;
