import * as React from "react";
import { Button, ButtonGroup, ModalBody, ModalHeader } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";
import ContentList from "./ContentList";
import { IContentModel } from "../../../../store/models/ContentModel";
import ReactModal from "react-modal";
import { ContentTypeEnum } from "./ContentFormDialogue";
import RootStore from "../../../../store/RootStore";

interface Props extends RouteComponentProps {
  handleChange: Function;
  onNewContent: Function;
}

interface State {
  isOpen: boolean;
}

export default class ContentSelectDialogue extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  open() {
    this.setState({
      isOpen: true,
    });
  }

  close() {
    this.setState({
      isOpen: false,
    });
  }

  componentDidMount(): void {
    RootStore.contentStore.fetchAllIfNecessary();
  }

  render() {
    return (
      <ReactModal
        ariaHideApp={false}
        isOpen={this.state.isOpen}
        className="contentFormDialogueModal"
        contentLabel="Minimal Modal Example"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <ModalHeader tag={"h3"} toggle={this.close.bind(this)}>
          Select something to schedule, or create a&nbsp;&nbsp;
          <ButtonGroup style={{ display: "inline-block" }}>
            <Button
              className={"top-right-button btn btn-success btn-sm"}
              onClick={() => {
                this.close();
                this.props.onNewContent(ContentTypeEnum.POST);
              }}
            >
              New Post
            </Button>
            <span className="btn-separator" />
            <Button
              className={"top-right-button btn btn-success btn-sm"}
              onClick={() => {
                this.close();
                this.props.onNewContent(ContentTypeEnum.STORY);
              }}
            >
              New Story
            </Button>
          </ButtonGroup>
        </ModalHeader>
        <ModalBody>
          <ContentList
            mode={"select"}
            {...this.props}
            onSelect={(item: IContentModel) => {
              this.close();
              this.props.handleChange(item);
            }}
          />
        </ModalBody>
      </ReactModal>
    );
  }
}
