import ApiQueryBuilder from "../../../../lib/ApiQueryBuilder";

export function NotificationQueryBuilder(pagination: {
  pageSize: number;
  page: number;
}): ApiQueryBuilder {
  let query = new ApiQueryBuilder();

  query.setPagination(pagination.pageSize, pagination.page);
  return query;
}
