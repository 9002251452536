import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import NotificationItem from "./NotificationItem";
// import Transport from "../../lib/Transport";
// import ApiQueryBuilder from "../../lib/ApiQueryBuilder";
// import { SortOrder } from "../../lib/QueryTypes";
import RootStore from "../../store/RootStore";
import { CloseIcon } from "../../assets/icons";
import { INotificationModel } from "../../store/models/NotificationModel";
import { observer } from "mobx-react";
import { NotificationBellIcon } from "../../assets/icons";
// import { RouteComponentProps } from "react-router-dom";

// import { AxiosResponse } from "axios";

// interface Props {
//   notifications: INotification[];
// }
interface Props {}
interface State {
  isOpen: boolean;
}

@observer
export default class TopnavNotification extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.readNotifications = this.readNotifications.bind(this);
  }
  toggle() {
    this.setState((prevState) => {
      return { isOpen: !prevState.isOpen };
    });
    this.readNotifications();
  }

  async componentDidMount(): Promise<void> {
    RootStore.notificationStore.removeNotifications();
    await RootStore.notificationStore.fetchNotifications();
  }
  async readNotifications() {
    if (RootStore.notificationStore.getNumberOfUnredNotifications() > 0) {
      await RootStore.notificationStore.readNotifications();
    }
  }
  render() {
    return (
      <div className="topNavNotifications d-inline-block mr-3">
        {RootStore.notificationStore.notifications.length ? (
          <Dropdown
            className="dropdown-menu-right"
            isOpen={this.state.isOpen}
            toggle={this.toggle}
          >
            <DropdownToggle
              className="header-icon notificationButton"
              color="empty"
            >
              {/* <i className="simple-icon-bell" /> */}
              <NotificationBellIcon />
              {RootStore.notificationStore.getNumberOfUnredNotifications() >
              0 ? (
                <span className="count">
                  {RootStore.notificationStore.getNumberOfUnredNotifications()}
                </span>
              ) : null}
            </DropdownToggle>
            <DropdownMenu
              className="position-absolute mt-3 scroll"
              right
              id="notificationDropdown"
            >
              <PerfectScrollbar>
                <div onClick={this.toggle} className="notification__close">
                  <CloseIcon />
                </div>
                {RootStore.notificationStore.todayNotifications().length > 0 ? (
                  <div className="timeSpan">
                    <div className="time">Today</div>
                    {RootStore.notificationStore
                      .todayNotifications()
                      .map(
                        (notification: INotificationModel, index: number) => {
                          return (
                            <NotificationItem
                              toggleDropDown={this.toggle}
                              key={index}
                              notification={notification}
                            />
                          );
                        }
                      )}
                  </div>
                ) : null}
                {RootStore.notificationStore.yesterdayNotifications().length >
                0 ? (
                  <div className="timeSpan">
                    <div className="time">Yesterday</div>
                    {RootStore.notificationStore
                      .yesterdayNotifications()
                      .map(
                        (notification: INotificationModel, index: number) => {
                          return (
                            <NotificationItem
                              toggleDropDown={this.toggle}
                              key={index}
                              notification={notification}
                            />
                          );
                        }
                      )}
                  </div>
                ) : null}
                {RootStore.notificationStore.lastMonthNotifications().length >
                0 ? (
                  <div className="timeSpan">
                    <div className="time">Last month</div>
                    {RootStore.notificationStore
                      .lastMonthNotifications()
                      .map(
                        (notification: INotificationModel, index: number) => {
                          return (
                            <NotificationItem
                              toggleDropDown={this.toggle}
                              key={index}
                              notification={notification}
                            />
                          );
                        }
                      )}
                  </div>
                ) : null}
                {/* {RootStore.notificationStore.notifications.map(
                  (notification: INotificationModel, index: number) => {
                    return (
                      <NotificationItem
                        key={index}
                        notification={notification}
                      />
                    );
                  }
                )} */}
              </PerfectScrollbar>
            </DropdownMenu>
          </Dropdown>
        ) : null}
      </div>
    );
  }
}
