import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import classNames from "classnames";
import Swal from "sweetalert2";
import { truncate } from "lodash";

import { IAdModel } from "../../../../store/models/AdModel";
import { AdDetails, AdPreviewModal } from "../../../common-components";
import { RightArrowIcon } from "../../../../assets/icons";
import RootStore from "../../../../store/RootStore";
import {
  ajaxErrorAlert,
  handleError,
  showLoading,
} from "../../../../lib/Utils";
import EditSchedule from "./EditSchedule";

interface Props {
  ad: IAdModel;
  onDisplaySuccessModal?: () => void;
  history?: any
}

interface State {
  displayAdPreview: boolean;
  displayEditSchedule: boolean;
}

/**
 * class components allows user edit , delet preview ad , update schedule, select ad
 * show status of ad 
 */
export default class AdCard extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      displayAdPreview: false,
      displayEditSchedule: false,
    };

    this.onDeleteAd = this.onDeleteAd.bind(this);
  }

  /**
   * send request server and delete ad 
   */
  async onDeleteAd() {
    try {
      await Swal.fire({
        title: "Warning",
        text: "Are you sure you want to delete this Ad?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete It",
        allowOutsideClick: false,
      })
        .then(async (res) => {
          if (res.value) {
            showLoading("Please Wait...", "Please wait while we deleting Ad");
            await RootStore.adStore.removeAd(this.props.ad.id);
            Swal.close();

            Swal.fire({
              type: "success",
              title: "Ad has been deleted successfully!",
              showCancelButton: false,
              showConfirmButton: true,
            });
          }
        })
        .catch((e) => {
          handleError(e);
          ajaxErrorAlert("There was a problem with deleting Ad you selected.");
        });
    } catch (e) {
      handleError(e);
      ajaxErrorAlert("There was a problem with deleting Ad you selected");
    }
  }

  render() {
    const { ad, onDisplaySuccessModal } = this.props;

    let fileTypes = {
      img: 0,
      video: 0,
    };

    if (ad?.content_id?.getMedias() && ad?.content_id?.getMedias()?.length > 0) {
      ad?.content_id?.getMedias()?.forEach((media) => {
        if (
          media?.file?.type === "image/gif" ||
          media?.file?.type === "image/jpeg" ||
          media?.file?.type === "image/jpg" ||
          media?.file?.type === "image/png"
        ) {
          fileTypes.img = fileTypes.img + 1;
        }

        if (media?.file?.type === "video/mp4") {
          fileTypes.video = fileTypes.video + 1;
        }
      });
    }

    return (
      <>
        {this.props.ad && this.state.displayEditSchedule && (
          <EditSchedule
            ad={this.props.ad}
            onClose={() =>
              this.setState((prevState) => ({ displayEditSchedule: false }))
            }
            isOpen={this.state.displayEditSchedule}
          />
        )}
        <AdPreviewModal
          isOpen={this.state.displayAdPreview}
          ad={ad}
          onClose={() =>
            this.setState((prevState) => ({
              displayAdPreview: !prevState.displayAdPreview,
            }))
          }
          getAdService={true}
        />
        <div
          className={classNames("adCard", {
            adCard__expired: ad.status === "expired",
          })}
          onClick={(event: React.MouseEvent) => {
            if (ad.status !== "expired" && ad.status !== "paused") {
              onDisplaySuccessModal();
            }
            if (ad.status === "paused" ) {
              this.props?.history?.push('/app/ads')
            }
          }}
        >
          <div className="adCard__adInfo">
            <img
              className={classNames("", {
                adCard__adInfo_story: ad?.content_id?.type === "story",
              })}
              //@ts-ignore
              src={ad?.content_id?.medias[0]?.preview_file?.url}
              alt="aifluencer ad card"
            />
            <div>
              <div>
                <h3>
                  {truncate(ad.title, { length: 225 })}{" "}
                  {ad.status === "expired" && (
                    <span className="adCard__expired_badge">Expired</span>
                  )}
                  {ad.status === "paused" && (
                    <span className="adCard__expired_badge">Paused</span>
                  )}
                </h3>
                <p>{truncate(ad.description, { length: 40 })}</p>
                {fileTypes.img > 0 && fileTypes.video === 0 && (
                  <p>{fileTypes.img} photo </p>
                )}
                {fileTypes.img > 0 && fileTypes.video > 0 && (
                  <p>
                    {fileTypes.img} photo - {fileTypes.video} video
                  </p>
                )}
                {fileTypes.img === 0 && fileTypes.video > 0 && (
                  <p>{fileTypes.video} video </p>
                )}
              </div>
              {ad.status === "expired" ? (
                <div className="adCard__expired_actions">
                  <Button
                    outline
                    color="primary"
                    onClick={(event: React.MouseEvent) => {
                      event.stopPropagation();
                      this.setState((prevState) => ({
                        displayEditSchedule: true,
                      }));
                    }}
                  >
                    Update Schedule
                  </Button>
                  <Button
                    outline
                    color="danger"
                    onClick={(event: React.MouseEvent) => {
                      event.stopPropagation();
                      this.onDeleteAd();
                    }}
                  >
                    Delete Ad
                  </Button>
                </div>
              ) : (
                <Row>
                  <Col xs="auto" className="d-none d-lg-block mt-2">
                    <Button
                      disabled={ad.status === "paused"}
                      color="success"
                      onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        onDisplaySuccessModal();
                      }}
                    >
                      Select Ad
                    </Button>
                  </Col>
                  <Col className="mt-2">
                    <Button
                      outline
                      color="primary"
                      onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        this.setState((prevState) => ({
                          displayAdPreview: !prevState.displayAdPreview,
                        }));
                      }}
                    >
                      View Ad
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <AdDetails ad={ad} />
          <div className="adCard__adSelectBtn">
            <RightArrowIcon />
          </div>
          <Button outline color="primary adCard__adSelectBtn-mobile">
            Invite to this ad
          </Button>
        </div>
      </>
    );
  }
}
