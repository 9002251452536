import React, { useEffect, useState } from 'react';
import { Grid, Step, StepLabel, Stepper } from '@mui/material';
import { withRouter } from 'react-router-dom';

function CreatAdHocTopMenu(props: any) {
    const pathname = props.location.pathname;
    const [KeyActiveStep, setKeyActiveStep] = useState(null);

    const route_base = '/app/ads/ad';

    const routesList = [
        {
            path: `${route_base}`,
            label: 'Objectives',
            key: 'objectives',
        },
        {
            path: `${route_base}/marketplace`,
            label: 'Marketplace Details',
            key: 'marketplace',
        },
        {
            path: `${route_base}/post-content`,
            label: 'Create an Ad - Post and Content',
            key: 'post-content',
        },
        {
            path: `${route_base}/publish-terms`,
            label: 'Publish Terms',
            key: 'publish-terms',
        },
        {
            path: `${route_base}/confirm`,
            label: 'Confirm',
            key: 'skip',
            skip: true,
        },
        {
            path: `${route_base}/discover`,
            label: 'Discover And Invite Influencer',
            key: 'discover',
        },
    ];

    useEffect(() => {
        if (pathname.split('/')[4] === 'confirm') {
            setKeyActiveStep(3);
        } else {
            routesList.find((el: any, key: number) => (pathname.split('/')[4] === el.key ? setKeyActiveStep(key) : ''));
        }
    }, [pathname]);

    const style = {
        padding: '30px',
        backgroundColor: '#FFF',
        borderRadius: '10px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.04)',
    };

    return (
        <Grid item marginBottom={4} style={style} display={{ xs: 'none', lg: 'block', sm: 'block', md: 'block' }}>
            <Stepper activeStep={KeyActiveStep} alternativeLabel>
                {routesList.map((el: any) => {
                    if (el?.skip) return false;
                    return (
                        <Step key={el.label}>
                            <StepLabel>{el.label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Grid>
    );
}

export default withRouter(CreatAdHocTopMenu);
