import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
 * this method Category Model
 */
export const CategoryModel = types.model({
  id: types.identifierNumber,
  title: types.string,
  slug:types.maybeNull(types.string),
  parent_id: types.maybeNull(types.integer),
});

export interface ICategoryModel extends Instance<typeof CategoryModel> {}

export interface ICategoryModelSnapshotIn
  extends SnapshotIn<typeof CategoryModel> {}

export interface ICategoryModelSnapshotOut
  extends SnapshotOut<typeof CategoryModel> {}
