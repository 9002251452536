import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "reactstrap";

import { NotFound404Icon } from "../../../../assets/icons";

/**
 * 
 * @param props , just use history for routing
 * @returns a btn and 404 icon and text
 */
export default function NotFound(props: RouteComponentProps) {
  return (
    <div className="influencer404">
      <NotFound404Icon />
      <h1>Oops... looks like you got lost</h1>
      <Button onClick={() => props.history.push("/app/influencer-explorer")}>
        Go Back
      </Button>
    </div>
  );
}
