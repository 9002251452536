import React from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router";

import Ads from "./Ads";
import EditAdPage from "../../app/ads/ad/EditAdPage";

interface Props extends RouteComponentProps {}

export default function Index(props: Props) {
  const { match } = props;

  return (
    <Switch>
      <Route
        path={`${match.url}/:ad_id/edit-ad`}
        exact={true}
        component={EditAdPage}
      />
      <Route path={`${match.url}`} component={Ads} />
      <Redirect to={`${match.url}`} />
      <Redirect to="/error" />
    </Switch>
  );
}
