import React from "react";
import { SearchEmptyStateIcon } from "../../../../assets/icons";

interface Props {}

export default function EmptySearchResult(props: Props) {
  return (
    <div className="searchEmptyState">
      <SearchEmptyStateIcon />
      <h2>Couldn't find any results</h2>
      <p>Please try searching with other terms</p>
    </div>
  );
}
