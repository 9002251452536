import React from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Button, Badge, Form, Label } from "reactstrap";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";

import IntlMessages from "../../template/helpers/IntlMessages";
import RootStore from "../../store/RootStore";
import { PasswordField } from "../common-components";
import Logo from "../../assets/img/logo.png";

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password_confirm: Yup.string()
    .required("Confirm your new password")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

export default function ChangePassword(
  props: RouteComponentProps<{ token: string }>
) {
  return (
    <div className="authForm">
      <div className="authForm__side">
        <h1>Change Password</h1>
        <p>
          Create a new password for your account and you will automatically
          redirect to your home page
          <br />
          If you are not a member, please{" "}
          <NavLink to={`/register`}>click here to register</NavLink>.
        </p>
      </div>

      <Formik
        initialValues={{ password: "", password_confirm: "" }}
        validationSchema={ChangePasswordSchema}
        onSubmit={async (values, actions) => {
          await RootStore.users.changeUserPassword(
            values.password,
            props.match.params["token"]
          );
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="authForm__form">
            <div>
              <a
                href={"https://ainfluencer.com"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Logo} alt="ainfluencer" />
              </a>
              <Label className="form-group has-float-label mb-4">
                <PasswordField
                  className="form-control"
                  name="password"
                  onChange={setFieldValue}
                />
                <ErrorMessage
                  name="password"
                  component={Badge}
                  // @ts-ignore
                  color="danger"
                  pill
                  className="mb-1"
                />
                <IntlMessages id="user.password" />
              </Label>
              <Label className="form-group has-float-label mb-4">
                <PasswordField
                  className="form-control"
                  name="password_confirm"
                  onChange={setFieldValue}
                />
                <ErrorMessage
                  name="password_confirm"
                  component={Badge}
                  // @ts-ignore
                  color="danger"
                  pill
                  className="mb-1"
                />
                <IntlMessages id="user.passwordConfirmation" />
              </Label>

              <Button
                color="primary"
                className="btn-shadow"
                size="lg"
                type={"submit"}
                disabled={isSubmitting}
              >
                <IntlMessages id="user.changePassword-button" />
              </Button>
            </div>

            <div className="termsAndPrivacyPolicy">
              <p>&copy; {new Date().getFullYear()} Ainfluencer</p>
              <a
                href="https://ainfluencer.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://ainfluencer.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
