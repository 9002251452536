import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Profile from './Profile';
import Insights from './Insights';
import RecentActivities from './RecentActivities';
import OngoingOffers from './OngoingOffers';
import SimilarProfiles from './SimilarProfiles';
import { ajaxErrorAlert, handleError } from '../../../../lib/Utils';
import { BreadCrumb } from '../../../common-components';
import { Separator } from '../../../../template/components/common/CustomBootstrap';
import CancelInvitation from '../components/CancelInvitationWarning';
import SelectedInflucners from '../explorer/SelectedInfluencers';
import { IdentityModel, IIdentityModelSnapshotIn } from '../../../../store/models/IdentityModel';
import { IntegrationModel } from '../../../../store/models/IntegrationModel';
import { FileModel } from '../../../../store/models/FileModel';
import RootStore from '../../../../store/RootStore';
import { CategoryModel, ICategoryModelSnapshotIn } from '../../../../store/models/CategoryModel';
import {
    TimeLineContentModel,
    ITimeLineContentModel,
    ITimeLineContentModelSnapshotIn,
} from '../../../../store/models/TimeLineContentModel';
import { IInfluencerModel } from '../../../../store/models/InfluencerModel';
import NotFound from './NotFound';

interface Props extends RouteComponentProps<{ influencer_id: string }> {}

interface State {
    dataLoaded: boolean;
    influencer: IInfluencerModel;
    similarInfluencers: Array<IInfluencerModel>;
    displayWarning: boolean;
    timelineContents: ITimeLineContentModel[];
    invited: boolean;
    notFound: boolean;
}
/**
 * influencer profile shows engagement rate, followers, followingg ,
 *  invited or not bio ,Avg Likes Per Post , Avg Comments Per Post, 
 * Like / Followers Ratio, Comment / Followers Ratio, 
 */
@observer
export default class InfluencerProfile extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            dataLoaded: false,
            influencer: null,
            displayWarning: false,
            timelineContents: [],
            similarInfluencers: [],
            invited: false,
            notFound: false,
        };

        this.loadData = this.loadData.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
    }
    /**
     * when influsencer id changes load data 
     */
    componentDidUpdate(prevProps: Props) {
        if (prevProps.match.params.influencer_id !== this.props.match.params.influencer_id) {
            if (this.state.dataLoaded) {
                this.setState((prevState) => ({
                    dataLoaded: false,
                }));
            }
            this.loadData();
        }
    }
    /**
     * invited or oninvited influencer
     */
    toggleSelected() {
        if (this.state.invited) {
            this.setState((prevState) => ({
                invited: !prevState.invited,
            }));
            RootStore.invitationStore.removeFromList(this.state.influencer);
        } else {
            this.setState((prevState) => ({
                invited: !prevState.invited,
            }));
            RootStore.invitationStore.addToList(this.state.influencer);
        }
    }
  
    async componentDidMount() {
        if (!this.state.dataLoaded) {
            await this.loadData();
        }
    }
    /**
     * send request and get influencer data 
     */
    async loadData() {
        try {
            await RootStore.fetchStaticData();
            let model = await RootStore.discoverInfluencersStore.getInfluencerById(
                this.props.match.params.influencer_id,
            );

            let influencer = {
                identity: IdentityModel.create({
                    id: model.identity.id,
                    created_at: model.identity.created_at,
                    updated_at: model.identity.updated_at,
                    user_id: model.identity.user_id,
                    average_comments_per_post: model.identity.average_comments_per_post,
                    average_likes_per_post: model.identity.average_likes_per_post,
                    comments_per_followers: model.identity.comments_per_followers,
                    likes_per_followers: model.identity.likes_per_followers,
                    country_id: model.identity.country_id,
                    state_id: model.identity.state_id,
                    city_id: model.identity.city_id,
                    type: model.identity.type,
                    logo: model.identity.logo !== null ? FileModel.create(model.identity.logo) : null,
                    name: model.identity.name,
                    url: model.identity.url,
                    description: model.identity.description,
                    categories:
                        model.identity.categories &&
                        model.identity.categories.map((category: ICategoryModelSnapshotIn) =>
                            CategoryModel.create(category),
                        ),
                    engagement_rate: model.identity.engagement_rate,
                    engagement_rate_t_score: model.identity.engagement_rate_t_score,
                    quality_score: model.dentity?.quality_score,
                    integrations: [],
                    received_offers: model.identity.received_offers,
                    offers: model.identity.offers,
                }),
                integration: IntegrationModel.create(model.identity.integrations[0]),
            };

            let similarInfluencers = model.identity.similar_influencers
                .filter((si: any) => si.integrations.length > 0)
                .map((si: IIdentityModelSnapshotIn) => ({
                    identity: IdentityModel.create({
                        id: si.id,
                        created_at: si.created_at,
                        updated_at: si.updated_at,
                        user_id: si.user_id,
                        average_comments_per_post: si.average_comments_per_post,
                        average_likes_per_post: si.average_likes_per_post,
                        comments_per_followers: si.comments_per_followers,
                        likes_per_followers: si.likes_per_followers,
                        country_id: si.country_id,
                        state_id: si.state_id,
                        city_id: si.city_id,
                        type: si.type,
                        logo: si.logo && FileModel.create(si.logo),
                        name: si.name,
                        url: si.url,
                        description: si.description,
                        categories:
                            si.categories &&
                            si.categories.map((category: ICategoryModelSnapshotIn) => CategoryModel.create(category)),
                        engagement_rate: si.engagement_rate,
                        integrations: [],
                        received_offers: si.received_offers,
                    }),
                    integration: IntegrationModel.create(si.integrations[0]),
                }));

            let timelineContents = model.identity.integrations[0].timeline_contents.map(
                (tc: ITimeLineContentModelSnapshotIn) => TimeLineContentModel.create(tc),
            );

            if (!this.state.dataLoaded) {
                this.setState((prevState) => ({
                    influencer: influencer,
                    dataLoaded: true,
                    timelineContents,
                    similarInfluencers,
                }));
            }
        } catch (error) {
            if (error && error.response && error.response.status === 404) {
                this.setState((prevState) => ({ notFound: true, dataLoaded: true }));
            } else {
                ajaxErrorAlert('Failed to load influencer data from server!');
            }

            handleError(error);
            throw error;
        }
    }

    render() {
        const { influencer, dataLoaded } = this.state;

        return !dataLoaded ? (
            <div style={{ textAlign: 'center' }}>
                <Spinner />
            </div>
        ) : this.state.notFound ? (
            <NotFound {...this.props} />
        ) : (
            <>
                <BreadCrumb
                    heading="Discover Influencers"
                    optionalProperties={[
                        {
                            id: ':influencer_id',
                            title: `${influencer.integration.full_name}'s profile`,
                            value: this.props.match.params.influencer_id,
                        },
                    ]}
                    {...this.props}
                />
                <Separator className="mb-5" />
                <div className="influencerProfile">
                    <div
                        className={classNames('influencerProfile__details', {
                            'w-100': RootStore.users.currentUser.is_admin,
                        })}
                    >
                        <Profile
                            influencer={influencer}
                            onToggleSelected={this.toggleSelected}
                            invited={this.state.invited}
                            {...this.props}
                        />
                        <Insights influencer={influencer} />
                        {this.state.timelineContents.length > 0 && (
                            <RecentActivities
                                timelineContents={this.state.timelineContents}
                                totalFollowers={this.state.influencer.integration.followers}
                            />
                        )}
                    </div>
                    {!RootStore.users.currentUser.is_admin ? (
                        <>
                            <div className="influencerProfile__sideBar">
                                <OngoingOffers
                                    offers={influencer.identity.offers}
                                    fullName={influencer.integration.full_name}
                                    categories={influencer.identity.categories}
                                    onInviteInfluencer={this.toggleSelected}
                                    {...this.props}
                                />
                                {this.state.similarInfluencers && this.state.similarInfluencers.length > 0 && (
                                    <SimilarProfiles
                                        similarInfluencers={this.state.similarInfluencers}
                                        categories={influencer.identity.categories}
                                        {...this.props}
                                    />
                                )}
                            </div>
                            {RootStore.invitationStore.temporaryList.length > 0 && (
                                <SelectedInflucners
                                    {...this.props}
                                    onCancel={() =>
                                        this.setState((prevState) => ({
                                            displayWarning: !prevState.displayWarning,
                                        }))
                                    }
                                />
                            )}

                            <CancelInvitation
                                dialogOpen={this.state.displayWarning}
                                onConfirm={() => {
                                    RootStore.invitationStore.clearList();
                                    this.setState((prevState) => ({
                                        displayWarning: !prevState.displayWarning,
                                    }));
                                }}
                                onCancel={() =>
                                    this.setState((prevState) => ({
                                        displayWarning: !prevState.displayWarning,
                                    }))
                                }
                            />
                        </>
                    ) : null}
                </div>
            </>
        );
    }
}
