import AbstractTaskGenerator, { ITask } from "./AbstractTaskGenerator";
import RootStore from "../../../../store/RootStore";
// import Transport from "../../../../lib/Transport";
// import moment from "moment";
// interface IAdTask {
//   viewAd: () => void;
// }

export default class ApproveAdTask extends AbstractTaskGenerator {
  constructor(public task: ITask) {
    super(task);
    this.completeTask = this.completeTask.bind(this);
  }
  taskTitle(): string {
    //@ts-ignore
    return `Approve ${this.task.data.ad.title}`;
  }
  actionBtnTitle(): string {
    return "Approve";
  }
  viewText(): string {
    return "View Ad";
  }
  async getAd(): Promise<void> {
    await RootStore.adStore.getAdById(this.task.data.ad.id);
  }
  async approveAd(): Promise<void> {
    await RootStore.adStore.editAd(this.task.data.ad.id, {
      approval: "approved",
    });
    this.completeTask();
  }
}
//git
