import React, { Component, Fragment } from "react";
import { Form, Formik } from "formik";
import { FormGroup, Label, Button, Card, CardBody } from "reactstrap";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import {
  Colxx,
  Separator,
} from "../../../../template/components/common/CustomBootstrap";
import { BreadCrumb, PasswordField } from "../../../common-components";
import { IUserModel } from "../../../../store/models/UserModel";
import Swal from "sweetalert2";
import RootStore from "../../../../store/RootStore";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import { NotificationManager } from "../../../../template/components/common/react-notifications";
import * as Yup from "yup";

const PasswordSchema = Yup.object().shape({
  old_password: Yup.string().required("Enter your current password"),
  new_password: Yup.string().required("Enter your new password"),
  new_password_confirm: Yup.string()
    .required("Confirm your new password")
    .oneOf([Yup.ref("new_password"), null], "Password must match"),
});

interface Props extends RouteComponentProps {}

interface Props {
  user: IUserModel;
}

@observer
class ChangePassword extends Component<Props> {
  async onSubmit(values: any) {
    try {
      await Swal.fire({
        title: "Warning",
        text: "Are you sure you want to change your password?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "rgb(18, 171, 40)",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Change It",
      })
        .then(async (res) => {
          if (res.value) {
            await RootStore.users.changeCurrentUserPassword({
              old_password: values.old_password,
              new_password: values.new_password,
            });
            // formikActions.resetForm();
          }
          // formikActions.setSubmitting(false);
          NotificationManager.success(
            "Done",
            "Your password was changed successfully!",
            3000,
            null,
            null,
            "filled"
          );
        })
        .catch((e) => {
          handleError(e);
          ajaxErrorAlert("These credentials do not match our records.");
        });
    } catch (e) {
      handleError(e);
      ajaxErrorAlert("There was a problem in editing your profile.");
    }
  }

  getInitialValues = () => ({
    old_password: "",
    new_password: "",
    new_password_confirm: "",
  });

  render() {
    return (
      <Fragment>
        <Colxx xxs="12">
          <BreadCrumb heading="Change Password" {...this.props} />
          <Separator className="mb-5" />
        </Colxx>
        <Colxx xxs="12" className="mb-4 changePassword">
          <Card>
            <CardBody>
              <Formik
                validationSchema={PasswordSchema}
                initialValues={this.getInitialValues()}
                onSubmit={this.onSubmit.bind(this)}
              >
                {({
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isSubmitting,
                }) => (
                  <Form
                    translate={"no"}
                    className="av-tooltip tooltip-label-right panel-form panel-form--change-password"
                  >
                    <FormGroup className="error-l-100">
                      <Label className="position-relative">
                        Old password:
                        {errors.old_password && touched.old_password && (
                          <div className="invalidFeedback">
                            {errors.old_password}
                          </div>
                        )}
                      </Label>
                      <PasswordField
                        className="form-control"
                        name="old_password"
                        onChange={setFieldValue}
                      />
                    </FormGroup>
                    <FormGroup className="error-l-100">
                      <Label className="position-relative">
                        New password:
                        {errors.new_password && touched.new_password && (
                          <div className="invalidFeedback">
                            {errors.new_password}
                          </div>
                        )}
                      </Label>
                      <PasswordField
                        className="form-control"
                        name="new_password"
                        onChange={setFieldValue}
                      />
                    </FormGroup>
                    <FormGroup className="error-l-100">
                      <Label className="position-relative">
                        Confirm password:
                        {errors.new_password_confirm &&
                        touched.new_password_confirm ? (
                          <div className="invalidFeedback">
                            {errors.new_password_confirm}
                          </div>
                        ) : null}
                      </Label>
                      <PasswordField
                        className="form-control"
                        name="new_password_confirm"
                        onChange={setFieldValue}
                      />
                    </FormGroup>

                    <Button color="primary" type="submit">
                      Update
                    </Button>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Colxx>
      </Fragment>
    );
  }
}

export default ChangePassword;
