import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Badge, Form, Label } from "reactstrap";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

import IntlMessages from "../../template/helpers/IntlMessages";
import RootStore from "../../store/RootStore";
import { ITokenModel } from "../../store/models/TokenModel";
import { ajaxErrorAlert, handleError } from "../../lib/Utils";
import { VerificationCodeFormIcon } from "../../assets/icons";

const ChangeEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("You need to enter a valid email")
    .required("Email is required"),
});

interface Props
  extends RouteComponentProps<
    {},
    {},
    { email: string; token: ITokenModel; user_id: number }
  > {}

export default class ChangeEmail extends Component<Props> {
  constructor(props: any) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values: any, formikActions: any) {
    const { user_id, token } = this.props.location.state;
    formikActions.setSubmitting(true);

    if (!token) {
      await Swal.fire({
        type: "error",
        title: "Access denied!",
        text: "You don't have permission to change your email.",
      });

      formikActions.setSubmitting(false);
    } else {
      try {
        formikActions.setSubmitting(true);

        let response = await RootStore.users.changeEmail(
          user_id,
          values.email,
          token
        );

        try {
          let verificationCodeResponse =
            await RootStore.users.resendVerificationEmail(
              this.props.location.state.token.access_token.toString()
            );

          if (verificationCodeResponse.status === 200) {
            await Swal.fire({
              type: "success",
              title: "",
              text: "Your email has changed successfully, the activation code has been sent to your new email.",
            });
          }
          formikActions.setSubmitting(false);
        } catch (error) {
          await Swal.fire({
            type: "error",
            title: "",
            text: "Your email has changed successfully, but the activation code send has been failed.",
          });
          formikActions.setSubmitting(false);
          handleError(error);
        }

        this.props.history.push({
          pathname: "/account-activation",
          state: {
            token: token,
            email: values.email,
            user_id: parseInt(response.data.user.id),
          },
        });

        formikActions.setSubmitting(false);
      } catch (err) {
        if (err.response.status === 422) {
          Swal.fire({
            type: "error",
            title: "Already Registered!",
            text:
              "Looks like someone has already registered with this email address (" +
              values.email +
              ")! Please choose another one.",
            footer: '<a href="/forgot-password">Forgot your password?</a>',
          });
        } else {
          ajaxErrorAlert("There was a problem in change your email address!");
          handleError(err);
        }

        formikActions.setSubmitting(false);
      }
    }
  }

  render() {
    return (
      <div className="authForm">
        <div className="authForm__side">
          <h1>Change Email</h1>
          <p>
            Please enter a new email to receive the verification code.
            <br />
          </p>
          <span
            className="changeEmail_link"
            onClick={(event: React.MouseEvent) =>
              this.props.history.push({
                pathname: "/account-activation",
                state: {
                  token: this.props.location.state.token,
                  email: this.props.location.state.email,
                  user_id: this.props.location.state.user_id,
                },
              })
            }
          >
            Back
          </span>
          .
        </div>

        <Formik
          initialValues={{ email: "" }}
          validationSchema={ChangeEmailSchema}
          onSubmit={this.onSubmit}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
            setSubmitting,
            handleReset,
          }) => (
            <Form
              onSubmit={handleSubmit}
              onReset={handleReset}
              className="authForm__form"
            >
              <div>
                <VerificationCodeFormIcon
                  style={{ margin: "0 auto", width: "100%" }}
                />

                <Label className="form-group has-float-label mb-4">
                  <Field
                    className="form-control"
                    type="email"
                    name="email"
                    value={values.email}
                  />
                  <ErrorMessage
                    name="email"
                    component={Badge}
                    // @ts-ignore
                    color="danger"
                    pill
                    className="mb-1"
                  />
                  <IntlMessages id="user.email" />
                </Label>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    className="btn-shadow"
                    size="lg"
                    type="submit"
                  >
                    Change My Email
                  </Button>
                </div>
              </div>

              <div className="termsAndPrivacyPolicy">
                <p>&copy; {new Date().getFullYear()} Ainfluencer</p>
                <a
                  href="https://ainfluencer.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                <a
                  href="https://ainfluencer.com/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
