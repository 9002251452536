import React from "react";
import { Switch, Route, RouteComponentProps } from "react-router-dom";

import Explorer from "./explorer";
import NewExplorer from "./new-explorer";
import CustomSearch from "./custom-search";
import NewCustomSearch from "./new-custom-search";
import InfluencerProfile from "./influencer-profile";

export default ({ match }: RouteComponentProps) => {
  return <Switch>
    <Route
      path={`${match.url}/custom-search`}
      exact={true}
      component={CustomSearch}
    />
    <Route
      path={`${match.url}/new-custom-search`}
      exact={true}
      component={NewCustomSearch}
    />
    <Route path={`${match.url}/:influencer_id`} component={InfluencerProfile} />
    <Route path={`${match.url}`} exact={true} component={Explorer} />
    <Route path={`${match.url}`} exact={true} component={NewExplorer} />
  </Switch>
}
