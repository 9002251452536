import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { IntegrationModel } from "./IntegrationModel";
import { IdentityModel } from "./IdentityModel";
import { ContentModel } from "./ContentModel";
import moment from "moment";
import { OfferModel } from "./OfferModel";
import { PublishJobErrorModel } from "./PublishJobErrorModel";
import { PublishJobView } from "../PublishJobStore";
import { ucfirst } from "../../lib/Utils";

/**
* this method Publish Job Model
*/
export const PublishJobModel = types
  .model({
    id: types.identifierNumber,
    start: types.string,
    created_at: types.string,
    duration: types.number,
    offer_id: types.maybeNull(types.reference(OfferModel)),
    content_id: types.reference(ContentModel),
    status: types.enumeration(["healthy", "expired", "failed"]),
    state: types.enumeration([
      "scheduled",
      "published",
      "completed",
      "removal",
    ]),
    published_url: types.maybeNull(types.string),
    integration_id: types.reference(IntegrationModel),
    identity_id: types.reference(IdentityModel),
    error: types.maybeNull(PublishJobErrorModel),
    metric: types.frozen(),
    metrics: types.frozen(),
    manual: types.maybeNull(types.boolean),
    url_approval: types.maybeNull(types.string),
    ig_status_on_publish: types.maybeNull(types.string),
    published_date: types.maybeNull(types.string),
  })
  .actions((self) => ({}))
  .views((self) => ({
    isTakeDown() {
      return self.duration !== 0 && self.state === "published";
    },
    isPermanent() {
      return self.duration === 0;
    },
    isFailed(): boolean {
      return self.status === "failed" || self.status === "expired";
    },
    hasOffer() {
      return self.offer_id !== null;
    },
    getContentTypeTitle() {
      if (self.content_id.type === "story") {
        if (self.content_id.getMedias().length === 1) {
          return "Story";
        } else {
          return "Story Set";
        }
      } else {
        return "Post";
      }
    },
  }))
  .views((self) => ({
    getIntegrationUsername() { },
    getEventDate(): moment.Moment {
      if (self.isPermanent()) {
        return moment(self.start);
      } else {
        if (self.isTakeDown()) {
          return moment(self.start).add(self.duration, "seconds");
        } else {
          return moment(self.start);
        }
      }
    },
    getJobDescription(): string {
      let str = "";
      if (self.isTakeDown()) {
        str += "Remove ";
      } else {
        str += "Publish ";
      }
      if (self.hasOffer()) {
        str += "Campaign ";
      } else {
        str += "Scheduler ";
      }
      str += self.getContentTypeTitle();
      return str;
    },
    getDurationLabel() {
      if (self.isPermanent()) {
        return "Permanent";
      } else {
        return moment.duration(self.duration, "seconds").humanize(false);
      }
    },
    getPreviewImage() {
      return self.content_id.getPreviewImage(100, 100);
    },
  }))
  .views((self) => ({
    getView(): PublishJobView {
      return {
        title:
          "Publish " +
          (self.offer_id ? "Campaign" : "Scheduler") +
          " " +
          ucfirst(self.content_id.type),
        isTakeDown: false,
        previewImage: self.getPreviewImage(),
        eventDate: moment(self.start),
        username: self.integration_id.username,
        content: self.content_id,
        // campaign:
        //   self.offer_id && self.offer_id.campaign_id.deleted_at === null
        //     ? self.offer_id.campaign_id.title
        //     : null,
        duration: self.getDurationLabel(),
        mediaType: self.content_id.type,
        offerId: self.offer_id ? self.offer_id : null,
        url:
          self.content_id.type === "post"
            ? self.published_url
            : "https://www.instagram.com/" + self.integration_id.username + '/',
        failed: self.isFailed(),
        id: self.id,
        state: self.state,
        status: self.status,
      };
    },
    getTakeDownView(): PublishJobView {
      return {
        title:
          "Remove " +
          (self.offer_id ? "Campaign" : "Scheduler") +
          " " +
          ucfirst(self.content_id.type),
        isTakeDown: true,
        previewImage: self.getPreviewImage(),
        eventDate: moment(self.start).add(self.duration, "seconds"),
        username: self.integration_id.username,
        content: self.content_id,
        // campaign: self.offer_id ? self.offer_id.campaign_id.title : null,
        duration: self.getDurationLabel(),
        mediaType: self.content_id.type,
        offerId: self.offer_id ? self.offer_id : null,
        url:
          self.content_id.type === "post"
            ? self.published_url
            : "https://www.instagram.com/" + self.integration_id.username + "/",
        failed: self.isFailed(),
        id: self.id,
        state: self.state,
        status: self.status,
      };
    },
    calculateEngagementRate(): number {
      if (!self.metric) {
        return 0;
      }

      if (self.content_id.type === "post") {
        return (
          parseInt(self.metric.likes + self.metric.comments) /
          Math.max(self.integration_id.metric.followers, 1)
        );
      } else {
        return (
          parseInt(self.metric.views) /
          Math.max(self.integration_id.metric.followers, 1)
        );
      }
    },
  }));

export interface IPublishJobModel extends Instance<typeof PublishJobModel> { }

export interface IPublishJobModelSnapshotIn
  extends SnapshotIn<typeof PublishJobModel> { }

export interface IPublishJobModelSnapshotOut
  extends SnapshotOut<typeof PublishJobModel> { }
