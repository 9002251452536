import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
import { Spinner } from "reactstrap";

import { Colxx } from "../../../../template/components/common/CustomBootstrap";
import { BreadCrumb } from "../../../common-components";
import { IAdModel } from "../../../../store/models/AdModel";
import AdForm from "./AdForm";
import RootStore from "../../../../store/RootStore";

interface Props extends RouteComponentProps<{ ad_id: string }> {}

interface State {
  isLoading: boolean;
  adModel: IAdModel;
}

@observer
export default class EditAdPage extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      adModel: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    RootStore.fetchStaticData()
    if (this.state.isLoading) {
      let adModel = await RootStore.adStore.getAdByIdInfo(
        parseInt(this.props.match.params.ad_id)
      );
      this.setState((prevState) => ({
        adModel: adModel,
        isLoading: !prevState.isLoading,
      }));
    }
  }

  render() {
    const { adModel, isLoading } = this.state;

    return isLoading ? (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    ) : (
      <>
        <BreadCrumb
          heading="Edit Ad"
          {...this.props}
          optionalProperties={[
            {
              title: adModel.title,
              value: adModel.id.toString(),
              id: ":ad_id",
            },
          ]}
          disableLinks={true}
        />
        <div className="separator mb-5" />

        <Colxx xxs="12" className="mb-4">
          <AdForm model={adModel} mode="edit" {...this.props} />
        </Colxx>
      </>
    );
  }
}
