import React, { Component } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { Badge, Button, ButtonGroup } from "reactstrap";
import classNames from "classnames";
import moment from "moment";
import Swal from "sweetalert2";
import { truncate } from "lodash";

import {
  ajaxErrorAlert,
  handleError,
  momentDateTimeFormat,
} from "../../../../lib/Utils";
import { IIntegrationModel } from "../../../../store/models/IntegrationModel";
import RootStore from "../../../../store/RootStore";
import { NotificationManager } from "../../../../template/components/common/react-notifications";
import { defaultProfilePicture } from "../../../../constants/defaultValues";
import { observer } from "mobx-react";
import { IIdentityModel } from "../../../../store/models/IdentityModel";

interface Props extends RouteComponentProps {
  integration: IIntegrationModel;
}
interface State {
  identity: IIdentityModel | null;
}
@observer
export default class IntegrationRow extends Component<Props, State> {
  state: State = {
    identity: null,
  };
  componentDidMount() {
    let identity = this.props.integration.identities
      ? this.props.integration.identities[0]
      : null;
    this.setState({ identity });
    console.log(identity);
  }

  logOut = async () => {
    try {
      let response = await Swal.fire({
        title: "Warning",
        text:
          `By ${
            this.props.integration.authenticated ? "logging out" : "deleting"
          }, you will no longer be able to use @${
            this.props.integration.username
          } with our system to schedule posts or accept offers! ` +
          `After pressing yes our system will ${
            this.props.integration.authenticated ? "logout of" : "delete"
          } your IG account. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#cc3733",
        confirmButtonText: this.props.integration.authenticated
          ? "Yes, Logout"
          : "Yes, Delete",
      });
      if (response.value) {
        await RootStore.integrationStore.delete(this.props.integration);
        NotificationManager.success(
          "Done",
          `Our system ${
            this.props.integration.authenticated ? "logged out of" : "deleted"
          } @${this.props.integration.username}!`,
          3000,
          null,
          null,
          "filled"
        );
      }
    } catch (e) {
      handleError(e);
      ajaxErrorAlert(
        "Could not log out of @" + this.props.integration.username
      );
      throw e;
    }
  };

  render() {
    const { integration } = this.props;
    let active = !integration.hasError();

    return (
      <div className="integrationRow">
        <div className="integrationRow__content">
          <a
            href={`https://instagram.com/${integration.username}/`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={
                integration.picture
                  ? integration.picture.getThumbnailUrl(64, 64)
                  : defaultProfilePicture
              }
              alt={integration.full_name ?? "No name"}
            />
          </a>
          <div
            className={classNames({
              integrationHasError: !active,
            })}
          >
            <a
              href={integration.getInstagramUrl()}
              target={"_blank"}
              className={classNames({
                integrationHasError: !active,
              })}
              rel="noopener noreferrer"
            >
              {integration.full_name
                ? integration.full_name
                : this.state.identity
                ? this.state.identity.name
                : "No name"}{" "}
              (@
              {integration.username})
            </a>
            <p>
              {integration
                ? truncate(integration.description, { length: 25 })
                : "No bio description"}
            </p>
            <div className="integrationRow__badge">
              {!active ? (
                <span
                  style={{
                    verticalAlign: "bottom",
                    fontSize: "3em",
                    color: "red",
                  }}
                  className="glyph-icon simple-icon-exclamation"
                />
              ) : null}
              <p>
                {active ? (
                  integration.authenticated ? (
                    <Badge color="success">Active</Badge>
                  ) : (
                    <Badge color="warning">Disconnected</Badge>
                  )
                ) : (
                  <Badge color="danger">Inactive (error)</Badge>
                )}
              </p>
            </div>
          </div>
        </div>
        {RootStore.users.currentUser.is_admin &&
        this.props.integration.user_id ? (
          <div className="integrationRow__userDetail">
            <div className="mb-2">
              user : <b>{this.props.integration.user_id.getFullName()}</b>{" "}
            </div>

            <div className="mb-2">
              user email: <b>{this.props.integration.user_id.email}</b>{" "}
            </div>

            <div className="mb-2">
              user type:{" "}
              <b>
                {this.props.integration.user_id.is_brand
                  ? "brand"
                  : this.props.integration.user_id.is_influencer
                  ? "influencer"
                  : "na"}
              </b>
            </div>

            <div>
              created_at: <b>{this.props.integration.created_at}</b>
            </div>
          </div>
        ) : null}

        <div className="integrationRow__actions">
          <p className="list-item-heading mt-1 w-30 w-sm-100">
            {integration.last_health_check ? (
              <small>
                Last checked:
                <br />
                <b>
                  {moment(
                    integration.last_health_check,
                    momentDateTimeFormat
                  ).fromNow()}
                </b>
              </small>
            ) : null}
          </p>

          <ButtonGroup>
            {active ? (
              <>
                {!RootStore.users.currentUser.is_admin ? (
                  <NavLink
                    className="btn btn-outline-secondary btn-sm"
                    to={
                      RootStore.users.currentUser.is_admin
                        ? `${this.props.match.url}/integrations/${this.props.integration.id}`
                        : `${this.props.match.url}/${this.props.integration.id}`
                    }
                  >
                    {this.props.integration.authenticated
                      ? "Change Password"
                      : "Connect & Set Password"}
                  </NavLink>
                ) : null}

                {this.props.integration.authenticated &&
                !RootStore.users.currentUser.is_admin ? (
                  <NavLink
                    className="btn btn-outline-secondary btn-sm"
                    to={`/app/scheduler/schedule-content/${integration.id}`}
                  >
                    Schedule Content
                  </NavLink>
                ) : null}
                <Button size="sm" outline={true} onClick={this.logOut}>
                  {integration.authenticated ? "Log Out" : "Delete"}
                </Button>
              </>
            ) : (
              <>
                {!RootStore.users.currentUser.is_admin ? (
                  <NavLink
                    className={"btn btn-outline-danger btn-sm"}
                    to={`${this.props.match.url}/${integration.id}`}
                  >
                    Troubleshoot
                  </NavLink>
                ) : (
                  <Button size="sm" outline={true} onClick={this.logOut}>
                    {integration.authenticated ? "Log Out" : "Delete"}
                  </Button>
                )}
              </>
            )}
          </ButtonGroup>
        </div>
      </div>
    );
  }
}
