import axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from "axios";
import { apiMockMode, servicePath } from "../constants/defaultValues";
import RootStore from "../store/RootStore";
import ApiQueryBuilder from "./ApiQueryBuilder";
import Qs from "qs";
import Swal from "sweetalert2";
import { deleteCookies } from "../store/UserStore";
import $ from "jquery";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

if (apiMockMode) {
  //ApiMockConfig.applyToAxios(axios);
}

class BaseTransport {
  static token: string = "";

  private static sideLoadInterceptors: any = {};

  static setUpAxiosInterceptor() {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        for (let paramName in BaseTransport.sideLoadInterceptors) {
          if (typeof response.data[paramName] !== "undefined") {
            BaseTransport.sideLoadInterceptors[paramName](
              response.data[paramName]
            );
          }
        }
        return response;
      },
      function (error) {
        // Pass the rejection
        return Promise.reject(error);
      }
    );
  }

  private static detected401: boolean = false;

  static async detectInvalidToken<T>(
    request: Promise<AxiosResponse<T>>
  ): Promise<AxiosResponse<T>> {
    if (BaseTransport.detected401) {
      return new Promise(() => {
        // block forever
      });
    }
    try {
      return await request;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401 && BaseTransport.hasToken()) {
          if (typeof RootStore !== "undefined") {
            if (RootStore.users.isLoggedIn()) {
              BaseTransport.detected401 = true;
              try {
                Swal.close();
              } catch (e) {}
              await Swal.fire({
                type: "warning",
                title: "Not Logged In!",
                text: "You are no longer logged into the system! Please login again...",
                confirmButtonText: "Login Again",
                confirmButtonColor: "rgb(78 191 75)",
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
              });
              $("body").css("display", "none");
              deleteCookies();
              window.location.reload();
              throw e;
            }
          }
        }
      }
      throw e;
    }
  }

  static registerSideLoadInterceptor(requestParamName: string, fn: Function) {
    BaseTransport.sideLoadInterceptors[requestParamName] = fn;
  }

  static getConfig(
    query: ApiQueryBuilder | null = null,
    additionalHeaders: any | null = null,
    cancelToken: CancelToken | null = null
  ): AxiosRequestConfig {
    let config: any = {
      headers: BaseTransport.getHeaders(),
      // `paramsSerializer` is an optional function in charge of serializing `params`
      // (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
      paramsSerializer: function (params: any) {
        return Qs.stringify(params, { arrayFormat: "indices" });
      },
      maxContentLength: 30000,
      cancelToken: cancelToken,
    };
    if (query !== null) {
      config["params"] = query.getQueryData();
    }

    if (additionalHeaders !== null) {
      config["headers"] = {
        ...config["headers"],
        ...additionalHeaders,
      };
    }

    return config;
  }

  static hasToken(): boolean {
    if (typeof RootStore !== "undefined") {
      if (RootStore.users.isLoggedIn()) {
        return true;
      }
    }
    return BaseTransport.token !== "";
  }

  static getHeaders() {
    let headers: any = {
      "Content-Type": "application/json",
    };

    if (typeof RootStore !== "undefined") {
      if (RootStore.users.isLoggedIn()) {
        headers["Authorization"] = "Bearer " + RootStore.users.getToken();
      }
    }
    if (BaseTransport.token !== "") {
      headers["Authorization"] = "Bearer " + BaseTransport.token;
    }
    return headers;
  }

  static getEndPointPath(path: string) {
    return servicePath + "/" + path;
  }
}

BaseTransport.setUpAxiosInterceptor();

export default BaseTransport;
