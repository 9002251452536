import { Grid } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { Button } from 'reactstrap';
import { DokaOutput } from '../../../../../../../../../../../3rd-party/doka/doka.esm.min';
import * as Doka from '../../../../../../../../../../../3rd-party/doka/doka.esm.min';
import ApiQueryBuilder from '../../../../../../../../../../../lib/ApiQueryBuilder';
import Transport from '../../../../../../../../../../../lib/Transport';
import CreatAdFeedContext from '../../context';
import CreatAdFeedLibrary from './parts/library';
import createAdContext from '../../../../../../../../context';
import { getExtension } from '../../../../../../../../../../../lib/Utils';
import Swal from 'sweetalert2';

function CreatAdFeedFileUpload() {
    const elementFile = useRef(null)
    const { images: { add } } = useContext(CreatAdFeedContext)
    const [Uploading, setUploading] = useState(false)
    const { data: { createAd: { feeds: { images } } }, updateOnboard } = useContext(createAdContext)
    const [ModalLibrary, setModalLibrary] = useState(false)

    const transportFile = (file: any) => {
        setUploading(true)
        let qb = new ApiQueryBuilder();
        qb.addQueryParam('type', 'post');
        Transport.upload<any>(
            'files',
            [
                {
                    file: file,
                    name: 'file',
                },
            ],
            qb,
        ).then(res => {
            if (!res?.data) return false
            const file = res.data.file
            add(file.url);
            updateOnboard((prev: any) => ({
                ...prev,
                createAd: {
                    ...prev.createAd,
                    feeds: {
                        ...prev.createAd.feeds,
                        images: [...prev.createAd.feeds.images, file]
                    }
                }
            }))
            setUploading(false)
        })
    }

    const showDoka = (file: File): Promise<DokaOutput> => {
        const doka = Doka.create(
            {
                cropAspectRatioOptions: [
                    {
                        label: 'Square',
                        value: '1:1',
                    },
                    {
                        label: 'Landscape',
                        value: '1.9:1',
                    },
                    {
                        label: 'Portrait',
                        value: '4:5',
                    },
                ],
                afterCreateOutput: (blob): any => {
                    transportFile(blob.file)
                },
            }
        );
        // @ts-ignore
        return doka.edit(file);
    }

    const uploadFile = (e: any) => {
        const file = e.target.files[0];
        const fileType = getExtension(file.type)
        const fileSize = Math.ceil(file.size / 1024)

        if (fileSize > 200000) {
            return Swal.fire({
                type: 'error',
                title: '',
                text: 'The story should max size 200 mg',
                showCloseButton: true,
                confirmButtonText: 'Ok!'
            })
        }

        if (fileType === 'image') {
            showDoka(file);
        } else if (fileType === 'video') {
            transportFile(file)
        }
    }

    return (
        <Grid item container columnSpacing={1}>
            <Grid item>
                <input type="file" ref={elementFile} hidden onChange={(e) => uploadFile(e)} />
                <Button
                    color="primary"
                    type="button"
                    size="xs"
                    className="media-list-container-action"
                    disabled={Uploading}
                    onClick={() => {
                        if (images.length >= 10) {
                            return Swal.fire({
                                type: "error",
                                title: "Oops! Maximum 10 contents are allowed to be uploaded.",
                                showCancelButton: false,
                                showConfirmButton: true,
                            });
                        }
                        elementFile.current && elementFile.current.click()
                    }
                    }
                >
                    <span style={{ verticalAlign: 'bottom' }} className="glyph-icon simple-icon-plus" />
                    {Uploading ? ' Uploading ...' : '  Add new image / video'}
                </Button>
            </Grid>
            <Grid item>
                {/* <Button
                    color="primary"
                    type="button"
                    size="xs"
                    className="media-list-container-action"
                    onClick={() => setModalLibrary(true)}
                >
                    Select From My Library
                </Button> */}
            </Grid>

            {ModalLibrary && <CreatAdFeedLibrary open={ModalLibrary} onClose={() => setModalLibrary(false)} />}
        </Grid>
    )
}

export default CreatAdFeedFileUpload
