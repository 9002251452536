import React, { useState } from "react";
import { Badge, Button, CustomInput, Form, Label } from "reactstrap";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { ErrorMessage, Field, Formik } from "formik";
import { Grid } from "@mui/material";

import IntlMessages from "../../template/helpers/IntlMessages";
import Logo from "../../assets/img/logo.png";
import { PasswordField } from "../common-components";
import RootStore from "../../store/RootStore";
import { ajaxErrorAlert } from "../../lib/Utils";
import { isStaging } from "../../constants/defaultValues";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import { isMobile } from 'react-device-detect';

const RegisterSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Required")
    .min(2, "First name must be at least two characters"),
  last_name: Yup.string()
    .required("Required")
    .min(2, "Last name must be at least two characters"),
  password: Yup.string()
    .min(8, "Too Short! Must be at least 8 characters")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string().required("Required")
    .min(6, "Too Short! Must be at least 6 characters")
    .max(16, "Too Short! Must be at least 16 characters"),
  confirmation: Yup.string().oneOf(
    [Yup.ref("password")],
    "Passwords must match"
  ),
  type: Yup.number().required(),
});
interface FormikValues {
  first_name: string;
  last_name: string;
  password: string;
  email: string;
  type: number;
  phone: number;
}

function Register(props: RouteComponentProps) {
  const [Step, setStep] = useState(1)
  return (
    <div className="authForm">
      <div className="authForm__side">
        {Step === 1 ?
          <>
            <h1 style={{ maxWidth: '400px', lineHeight: '22px', marginBottom: '20px' }}><b>Welcome to Ainfluencer,</b> <small style={{ fontSize: '1rem' }}>the first 100% free to use DIY marketplace for influencer marketing.</small></h1>
            <p style={{ fontSize: '1rem' }}>Register your <b>free</b> account today!</p>
          </>
          :
          <>
            <h1 style={{ maxWidth: '400px', lineHeight: '22px', marginBottom: '20px' }}>Register your<b> FREE</b> account.</h1>
            <p style={{ fontSize: '.9rem', lineHeight: '24px' }}>Ainfluencer is the first, 100% free DIY marketplace for influencer marketing. With Ainfluencer you can;</p>

            <ul style={{ fontSize: '.8rem' }}>
              <li>Post Ads on the influencer marketplace.</li>
              <li>Find and Invite Instagram influencers.</li>
              <li>Negotiate terms using built-in chat.</li>
              <li>Escrow payment keeps your money safe till influencers deliver.</li>
              <li>View real-time analytics.</li>
            </ul>
          </>
        }


        <p>
          Already a member? <NavLink to={`/login`}>Login here</NavLink>.
        </p>
      </div>

      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          type: 0,
          phone: '',
        }}
        validationSchema={RegisterSchema}
        onSubmit={async (values, formikActions: any) => {
          try {
            let response = await RootStore.users.register({
              ...values,
              type: values.type === 0 ? "brand" : "influencer",
            });

            // check repetitous email
            const useremail = response?.data?.errors?.['user.email'] || ''
            if (useremail === 'The email address has already been taken.') {
              Swal.fire({
                type: "error",
                title: "Already Registered!",
                text:
                  "Looks like someone has already registered with this email address (" +
                  values.email +
                  ")! Please choose another one.",
                footer: '<a href="/forgot-password">Forgot your password?</a>',
              });
            }

            if (values.type === 0) {
              if (!isStaging()) {
                //@ts-ignore
                ga('send', {
                  hitType: 'event',
                  eventCategory: 'Brand',
                  eventAction: 'SignUp',
                  eventLabel: '',
                });
              }
            } else {
              if (!isStaging()) {
                //@ts-ignore
                ga('send', {
                  hitType: 'event',
                  eventCategory: 'Influencer',
                  eventAction: 'SignUp',
                  eventLabel: '',
                });
              }
            }

            formikActions.setSubmitting(true);
            props.history.replace({
              pathname: "/account-activation",
              state: {
                token: response.data.user.token,
                email: values.email,
                user_id: parseInt(response.data.user.id),
                type: values.type,
                phone: values.phone
              },
            });
          } catch (error) {
            console.log('reg Err : ', error,)
            if (error.response.status === 422) {
              formikActions.setSubmitting(false);
              Swal.fire({
                type: "error",
                title: "Already Registered!",
                text:
                  "Looks like someone has already registered with this email address (" +
                  values.email +
                  ")! Please choose another one.",
                footer: '<a href="/forgot-password">Forgot your password?</a>',
              });
            } else {
              ajaxErrorAlert("There was a problem in registration!");
            }

            formikActions.setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          setSubmitting,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="authForm__form">
            <div>
              <a
                href={"https://ainfluencer.com"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Logo} alt="ainfluencer" />
              </a>
              {Step === 1 ?
                <Grid container flexDirection="column" className="registerUser__userType" rowSpacing={2}>
                  <Grid item textAlign="center">
                    <h3 style={{ color: '#777' }}><strong>Tell us why you are interested in using Ainfluencer.</strong></h3>
                  </Grid>
                  <Grid item>
                    <CustomInput
                      className={`${values.type === 0 ? "active" : null}`}
                      type="radio"
                      id="exCustomRadio"
                      name="type"
                      label="I am a company or brand looking to find and collaborate with influencers to help sell my products and services."
                      value={0}
                      checked={values.type === 0}
                      onChange={() => {
                        setFieldValue("type", 0);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <CustomInput
                      className={`${values.type === 1 ? "active" : null}`}
                      type="radio"
                      id="exCustomRadio1"
                      name="type"
                      label="I am a creator or influencer looking to generate income by promoting products and services on my Instagram channel."
                      value={1}
                      checked={values.type === 1}
                      onChange={() => {
                        setFieldValue("type", 1);
                      }}
                    />
                    <ErrorMessage
                      name="type"
                      component={Badge}
                      // @ts-ignore
                      color={"danger"}
                      pill
                      className="mb-1"
                    />
                  </Grid>
                  <Grid item textAlign="center">
                    <Button
                      color="primary"
                      className="btn-shadow"
                      type="button"
                      onClick={() => {
                        if (values.type === 1) {
                          if (isMobile) {
                            window.location.replace('https://ainfluencer.app.link/a969IAl9lnb')
                          } else {
                            props.history.push('/mobile-app')
                          }
                        } else {
                          setStep(2)
                        }
                      }}
                    >
                      <b>Register Free</b>
                    </Button>
                  </Grid>
                </Grid>
                :
                <>
                  <Label className="form-group has-float-label mb-4">
                    <Field
                      className={"form-control"}
                      type="text"
                      name="first_name"
                    />
                    <ErrorMessage
                      name="first_name"
                      component={Badge}
                      // @ts-ignore
                      color={"danger"}
                      pill
                      className="mb-1"
                    />
                    <IntlMessages id="user.firstName" />
                  </Label>
                  <Label className="form-group has-float-label mb-4">
                    <Field
                      className={"form-control"}
                      type="text"
                      name="last_name"
                    />
                    <ErrorMessage
                      name="last_name"
                      component={Badge}
                      // @ts-ignore
                      color={"danger"}
                      pill
                      className="mb-1"
                    />
                    <IntlMessages id="user.lastName" />
                  </Label>
                  <Label className="form-group has-float-label mb-4">
                    <Field className={"form-control"} type="text" name="email" />
                    <ErrorMessage
                      name="email"
                      component={Badge}
                      // @ts-ignore
                      color={"danger"}
                      pill
                      className="mb-1"
                    />
                    <IntlMessages id="user.email" />
                  </Label>
                  <Label className="form-group has-float-label mb-4">
                    <PasswordField
                      id="password"
                      name="password"
                      className="form-control"
                      onChange={setFieldValue}
                    />
                    <ErrorMessage
                      name="password"
                      component={Badge}
                      // @ts-ignore
                      color={"danger"}
                      pill
                      className="mb-1"
                    />
                    <IntlMessages id="user.password" />
                  </Label>
                  <Label className="form-group has-float-label mb-4">
                    <PasswordField
                      id="confirmation"
                      name="confirmation"
                      className="form-control"
                      onChange={setFieldValue}
                    />
                    <ErrorMessage
                      name="confirmation"
                      component={Badge}
                      // @ts-ignore
                      color={"danger"}
                      pill
                      className="mb-1"
                    />
                    <IntlMessages id="user.passwordConfirmation" />
                  </Label>
                  <Label className="form-group has-float-label mb-4 phoneInput">
                    <PhoneInput
                      international
                      style={{
                        padding: '11px',
                        border: '1px solid #CCCCCCDE',
                      }}
                      defaultCountry="CA"
                      onChange={(e) => setFieldValue('phone', e && e.length ? e.replace('+', '') : '')} />
                    <ErrorMessage
                      name="phone"
                      component={Badge}
                      // @ts-ignore
                      color={"danger"}
                      pill
                      className="mb-1"
                    />
                    <IntlMessages id="Phone" />
                  </Label>
                  <Button
                    color="primary"
                    className="btn-shadow"

                    type={"submit"}
                    disabled={isSubmitting}
                  >
                    <IntlMessages id="user.register-button" /> <b>Free</b>
                  </Button>
                </>
              }
            </div>

            <div className="termsAndPrivacyPolicy">
              <p>&copy; {new Date().getFullYear()} Ainfluencer</p>
              <a
                href="https://ainfluencer.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://ainfluencer.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default withRouter(Register)