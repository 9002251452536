import React, { Component } from 'react';
import { getIdentifier } from 'mobx-state-tree';
import Swal from 'sweetalert2';

import { MasterCardIcon, MasterSimIcon, VisaCardIcon, VisaSimIcon } from '../../../../assets/icons';
import { IPaymentMethodModel } from '../../../../store/models/PaymentMethodModel';
import RootStore from '../../../../store/RootStore';
import { ajaxErrorAlert, handleError } from '../../../../lib/Utils';
import { NotificationManager } from '../../../../template/components/common/react-notifications';
import { Badge } from 'reactstrap';

interface Props {
    model: IPaymentMethodModel;
}

interface State {
    displayOptionsMenu: boolean;
}

export default class CreditCard extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            displayOptionsMenu: false,
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside);
    }

    handleClickOutside(e: any) {
        e.stopPropagation();
        if (this.state?.displayOptionsMenu) {
            this.setState((prevState) => ({
                displayOptionsMenu: false,
            }));
        }
    }

    getComponentClass() {
        if (this.props?.model?.data?.brand === 'Visa') {
            return 'visaCard';
        } else {
            return 'masterCard';
        }
    }

    getComponentIcon() {
        if (this.props?.model?.data?.brand === 'Visa') {
            return <VisaCardIcon className="cardIcon" />;
        } else {
            return <MasterCardIcon className="cardIcon" />;
        }
    }

    getComponentSimIcon() {
        if (this.props?.model?.data?.brand === 'Visa') {
            return <VisaSimIcon className="cardSim" />;
        } else {
            return <MasterSimIcon className="cardSim" />;
        }
    }

    async remove() {
        let res = await Swal.fire({
            title: 'Warning',
            text: `Are you sure you want to delete **** **** **** ${this.props?.model?.data?.last4}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Delete It',
        });
        if (res.value) {
            try {
                await RootStore.users.currentUser.wallet.removePaymentMethod(this.props?.model);
                NotificationManager.success('Done', `Successfully removed!`, 3000, null, null, 'filled');
            } catch (e) {
                handleError(e);
                ajaxErrorAlert('Could not delete credit card!');
            }
        }
    }

    async setAsDefault() {
        try {
            await RootStore.users.currentUser.wallet.setDefaultPaymentMethod(this.props.model);
            NotificationManager.success(
                `card **** **** **** ${this.props?.model?.data?.last4} is set as the default payment method!`,
                'The card has been set as default',
                4000,
                null,
                null,
                'filled',
            );
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('Could not set credit card as default payment method!');
        }
    }

    render() {
        const isDefaultCard =
            this.props.model.id ===
            parseInt(getIdentifier(RootStore.users.currentUser.wallet.default_payment_method_id));

        return (
            <div className={this.getComponentClass()}>
                {isDefaultCard && (
                    <Badge color="dark" className="defaultCardBadge">
                        Default Card
                    </Badge>
                )}
                {this.state.displayOptionsMenu && !isDefaultCard && (
                    <div className="cardMenu">
                        <p onMouseDown={this.setAsDefault.bind(this)}>Make Default</p>
                        <p onMouseDown={this.remove.bind(this)}>Remove This Card</p>
                    </div>
                )}
                {!isDefaultCard ? <div
                    className="cardMenuDots"
                    onClick={(e) => {
                        this.setState((prevState) => ({
                            displayOptionsMenu: !prevState.displayOptionsMenu,
                        }));
                    }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div> : ''}

                {this.getComponentIcon()}

                <div className="cardNumber">
                    <p>Card Number</p>
                    <p>**** **** **** {this.props?.model?.data?.last4}</p>
                </div>
                {this.getComponentSimIcon()}
            </div>
        );
    }
}
