import React, { useState } from 'react';
import { truncate } from 'lodash';
import numbro from 'numbro';

import { SlickSlider } from '../../../common-components';
import { EngagementRateIcon, LikeIcon, CommentsIcon, NoImageAvailableIcon } from '../../../../assets/icons';
import { minifyNum } from '../../../../lib/Utils';

interface Props {
    content: any;
    totalFollowers: number;
}

export default function PostPreview(props: Props) {
    const { content, totalFollowers } = props;
    const [imgLoaded, setImgLoaded] = useState(false);

    let engagement_rate = 0;

    if (content.metric && content.metric.likes && content.metric.comments) {
        engagement_rate = (content.metric.likes + content.metric.comments) / totalFollowers;
    }

    return (
        <div className="postPreview">
            <div className="postPreview_pr">
                <SlickSlider
                    settings={{
                        className: 'slider variable-width',
                        dots: true,
                        infinite: false,
                        speed: 500,
                        initialSlide: 0,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                    }}
                >
                    {content.medias.map((cont: any, index: number) => (
                        <div key={`postPreview__${index}`}>
                            {cont.url && (
                                <img
                                    src={cont.url}
                                    style={{ display: imgLoaded ? '' : 'none' }}
                                    alt="ainfluencer"
                                    onLoad={() => setImgLoaded(true)}
                                />
                            )}
                            {(!imgLoaded || !cont.url) && <NoImageAvailableIcon />}
                        </div>
                    ))}
                </SlickSlider>
                <p>{truncate(content.caption, { length: 170 })}</p>
            </div>
            <div className="postPreview_dt">
                <span>
                    <LikeIcon />{' '}
                    {content.metric && content.metric.likes >= 0 ? minifyNum(content.metric.likes) : '?'}
                </span>

                <span>
                    <CommentsIcon />
                    {content.metric && content.metric.comments >= 0 ? minifyNum(content.metric.comments) : '?'}
                </span>
                <span>
                    <EngagementRateIcon style={{ width: '2.2em', height: '2.2em' }} />{' '}
                    {numbro(engagement_rate).format({
                        output: 'percent',
                        mantissa: 2,
                        optionalMantissa: true,
                    })}
                </span>
            </div>
        </div>
    );
}
