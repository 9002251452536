import React from "react";
import { RouteComponentProps } from "react-router";

import { BreadCrumb, TabsComponent } from "../../common-components";
import { observer } from "mobx-react";
import OffersTab from "./OffersTab";
import DealsTab from "./DealsTab";
import RootStore from "../../../store/RootStore";
import { Button } from "reactstrap";

interface Props extends RouteComponentProps {}

function OfferListPage(props: Props) {
  return (
    <div className="offersListPage">
      <div className="row  mb-2">
        <div className="col-sm-8">
          <BreadCrumb heading="Offers" {...props} />
        </div>
        <div className="offersTab_header_actions col-sm-4 mb-2">
          <Button
            color="primary"
            onClick={() => props.history.push("/app/influencer-explorer")}
          >
            Explore &amp; Invite Influencers
          </Button>
          <Button
            color="primary"
            onClick={() => props.history.push("/app/ads/ad/objectives/")}
          >
            Create A New Ad
          </Button>
        </div>
      </div>

      <TabsComponent
        tabs={[
          {
            tabName: "In Discussion",
            component: <OffersTab {...props} />,
            to: `${props.match.url}`,
          },
          {
            tabName: "Deals Closed",
            component: (
              <DealsTab offers={RootStore.offerStore.offers} {...props} />
            ),
            to: `${props.match.url}/deals`,
          },
        ]}
        {...props}
      />
    </div>
  );
}

export default observer(OfferListPage);
