import { ITokenModel } from './models/TokenModel';
import { applySnapshot, flow, getSnapshot, Instance, SnapshotIn, SnapshotOut, types, destroy } from 'mobx-state-tree';
import Transport from '../lib/Transport';
import {
    ajaxErrorAlert,
    handleError,
    showActivationModal,
    // showInfluencersSideHasNotBeenImplemented,
} from '../lib/Utils';
import { IUserModel, UserModel, IUserModelSnapshotIn } from './models/UserModel';
import BatchTransport from '../lib/BatchTransport';
import { getAppInfo, makeTokenUserQuery } from './common/RequestHelpers';
import Cookies from 'js-cookie';
import BaseTransport from '../lib/BaseTransport';
import { IIdentityModel } from './models/IdentityModel';
import RootStore from './RootStore';
import TwilioFacade from '../lib/TwilioFacade';
import * as Sentry from '@sentry/browser';
// import { IntegrationModel, IIntegrationModel } from "./models/IntegrationModel";
import { userQueryBuilder } from '../views/admin/users-managment/components/UserQueryBuilder';
import { FilterGroup } from '../lib/QueryTypes';
import ApiQueryBuilder from "../lib/ApiQueryBuilder";

declare var productionMode: boolean;

function saveToken(token: any, id: number) {
    Cookies.set('token', token.access_token);
    Cookies.set('refresh_token', token.refresh_token);
    Cookies.set('chat_token', token.chat_token);
    Cookies.set('user_id', id.toString());
}
export function deleteCookies() {
    Cookies.remove('token');
    Cookies.remove('refresh_token');
    Cookies.remove('user_id');
    Cookies.remove('chat_token');
}

// export type RegistrationError = {
//     type: 'user-exists' | 'invalid-data',
//     msg: string
// }

export const UserStore = types
    .model({
        users: types.array(UserModel),
        adminUsers: types.array(UserModel),
        currentUser: types.maybeNull(types.reference(UserModel)),
        token: types.maybeNull(types.string),
        loggedIn: false,
    })
    .volatile((self) => ({
        isBusy: false,
    }))
    .actions((self) => ({

        /**
        * this method check processing for this store
        */
        setBusy() {
            self.isBusy = true;
        },

        /**
        * this method just set value isBusy = false
        */
        setIdle() {
            self.isBusy = false;
        },

        /**
        * this method add User
        * @param data, this param new user
        */
        addUser(data: any) {
            self.users.push(data);
        },

        /**
        * this method add admin User
        * @param user, this param new user
        */
        addAdminUser(user: IUserModel) {
            self.adminUsers.push(user);
        },

        /**
        * this method clear Users
        */
        clearUsers() {
            self.adminUsers.clear();
        },
        syncMissingModels(models: IUserModelSnapshotIn[]) {
            if (!Array.isArray(models)) {
                return;
            }

            let userList: number[] = self.adminUsers.map((user) => {
                return user.id;
            });

            let possiblyUpdatedModels = models.filter((model) => userList.indexOf(model.id) >= 0);
            if (possiblyUpdatedModels.length) {
                for (let user of self.adminUsers) {
                    for (let updatedContent of possiblyUpdatedModels) {
                        if (user.id === updatedContent.id) {
                            applySnapshot(user, updatedContent);
                        }
                    }
                }
            }

            self.adminUsers.replace(
                self.adminUsers.concat(models.filter((model) => userList.indexOf(model.id) === -1).uniqueById()),
            );
        },

        /**
        * this method set Logged In User
        * @param data, this param data login
        * @param redirect, this param link for redirect after login
        */
        setLoggedInUser(data: any, redirect: string | null = ''): any {
            let model = UserModel.create(data);
            self.users.push(model);
            self.currentUser = model;

            let chatToken = null;
            if (data.token) {
                self.token = data.token.access_token;
                saveToken(data.token, self.currentUser.id);
                chatToken = data.token.chat_token;
            } else {
                chatToken = Cookies.get('chat_token');
                self.token = Cookies.get('token');
            }
            BaseTransport.token = self.token;

            if (productionMode) {
                Sentry.configureScope((scope) =>
                    scope.setUser({
                        id: self.currentUser.id.toString(),
                        email: self.currentUser.email,
                        username: self.currentUser.email,
                    }),
                );
            }

            (async () => {
                TwilioFacade.blockNewChannelEvent = true;
                await TwilioFacade.connectMessagingClient(chatToken);
                let descriptors = await TwilioFacade.getUserChannelDescriptors();
                for (let channel of descriptors.items) {
                    TwilioFacade.retrievedChannelSids.push(channel.sid);
                }
                TwilioFacade.blockNewChannelEvent = false;
            })();

            // if (!self.currentUser.is_admin) {
            //   (async () => {
            //     await RootStore.offerStore.fetchAllOffers();
            //   })();
            // }

            (async () => {
                if (RootStore.identityStore.getByUserId(RootStore.users.currentUser.id)) {
                    await RootStore.hashtagsStore.fetchAllIfNecessary(
                        RootStore.identityStore.getByUserId(RootStore.users.currentUser.id).id,
                    );
                }
            })();

            self.loggedIn = true;

            if (self.currentUser.type === 'influencer') {
                RootStore.router.push('/influencer-login');
                return;
            } else if (self.currentUser && !self.currentUser.created_campaign && !self.currentUser.is_admin) {
                RootStore.router.push('/app/welcome');
                return;
            } else {
                RootStore.router.push(redirect);
                return;
            }
            // if (self.currentUser.getIdentities().length === 0) {
            //   RootStore.router.push("/app");
            //   return;
            // }
        },
    }))
    .actions((self) => ({

        /**
        * this method create new user
        */
        create: flow(function* (userData: any): Generator<any, IUserModel, any> {
            self.setBusy();

            try {
                let result: any = null;
                let batch = new BatchTransport();
                batch.post<any>('auth/register/', { user: userData, app: getAppInfo() }, null);
                result = yield batch.all();

                if (!result) {
                    console.error('result was null in create user');
                    return;
                }

                let model;

                model = UserModel.create(result.data['ad']);
                self.setIdle();

                return model;
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method register
        * @param userData, this param user data for register
        */
        register: flow(function* (userData: any) {
            self.setBusy();

            try {
                deleteCookies();

                let response = yield Transport.post<any>('v1/auth/register', {
                    user: userData,
                    app: getAppInfo(),
                });

                return response;
            } catch (error) {
                handleError(error);
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method login user
        * @param email, this param email user
        * @param password, this param password user
        * @param history, this param get history from react-route-dom
        */
        login: flow(function* (email: string, password: string, history: any): any {
            try {
                let response = yield Transport.post('v1/auth/login', {
                    email: email,
                    password: password,
                    app: getAppInfo(),
                });

                if (response.status === 200) {
                    if (response.data.user.email_verified_at === null) {
                        showActivationModal(response.data.user.email, 'login', response.data.user.token, history);

                        return true;
                    } else {
                        let brandIdentities = response.data.user['identities'].filter(
                            (identity: any) => identity.type === 'brand',
                        );

                        // if (
                        //   response.data["identities"].length !== 0 &&
                        //   brandIdentities.length === 0
                        // ) {
                        //   return true;
                        // }

                        for (let identity of response.data.user['identities']) {
                            // Check if user is brand
                            if (identity.type === 'brand') {
                                RootStore.fileStore.syncMissingModels([identity['logo']]);
                            }
                        }

                        // for (let integration of response.data["integrations"]) {
                        //   RootStore.fileStore.syncMissingModels([
                        //     integration["picture"] ??
                        //       FileModel.create({
                        //         height: 64,
                        //         width: 64,
                        //         id: makeTempModelId(),
                        //         label: "profile",
                        //         type: "text/jpg",
                        //         url: defaultProfilePicture,
                        //       }),
                        //   ]);
                        // }

                        // RootStore.integrationStore.syncMissingModels(
                        //   response.data["integrations"]
                        // );
                        RootStore.identityStore.syncMissingModels(brandIdentities);

                        self.setLoggedInUser(response.data['user']);
                        return true;
                    }
                }
            } catch (e) {
                if (e.response) {
                    if (e.response.status === 401) {
                        return false;
                    }
                }
                throw e;
            }
        }),

        /**
        * this method logot user
        * @param reload, this param if true reload page
        * @param location, this param get location from route 
        */
        logOut: flow(function* (reload: boolean = true, location: string = '/login'): Generator<any, any, any> {
            try {
                yield Transport.post('auth/logout', {});
                deleteCookies();
                window.location.pathname = location
            } catch (e) {
                handleError(e);
                yield ajaxErrorAlert('There was a problem logging you out! Click on the button to refresh the page!');
            }
            if (reload) {
                window.location.reload();
            }
        }),

        /**
        * this method fetch All Users
        * @param pagination, this param object for pagination Users
        * @param filters_group, this param object for filter Users
        * @param SortOrder, this param object for sort Users
        */
        fetchAllUsers: flow(function* (
            pagination: { page: number; pageSize: number },
            filter_groups: FilterGroup[],
            SortOrder,
            search
        ): any {
            self.clearUsers();
            RootStore.integrationStore.clearList();
            RootStore.identityStore.clearIdentities();
            self.setBusy();

            try {
                let response = yield Transport.get(
                    `admin/users`,
                    userQueryBuilder(pagination, filter_groups, SortOrder,search),
                );

                self.syncMissingModels(response.data['users']);
                RootStore.identityStore.syncMissingModels(response.data['identities']);
                RootStore.integrationStore.syncMissingModels(response.data['integrations']);
                self.setIdle();

                return {
                    pagination: {
                        totalPages: response.data.meta.page_count,
                        pageSize: response.data.meta.limit,
                        page: response.data.meta.page,
                        totalCount: response.data.meta.total_count,
                    },
                };
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method edit User
        * @param userId, this param get user ID
        * @param values, this param get new values
        */
        editUser: flow(function* (userId: number, values: any): any {
            self.setBusy();

            try {
                let response = yield Transport.patch(
                    `admin/users/${userId}`,
                    {
                        user: values,
                    },
                    null,
                );
                if (userId === RootStore.users.currentUser.id) {
                    applySnapshot(RootStore.users.currentUser, response.data['user']);
                } else {
                    applySnapshot(
                        RootStore.users.adminUsers.find((user: IUserModel) => user.id === userId),
                        response.data['user'],
                    );
                }

                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method delete user
        * @param userId, this param get user ID for deleted
        */
        deleteUser: flow(function* (userId: number): any {
            self.setBusy();
            try {
                yield Transport.delete(`admin/users/${userId}`);
                destroy(RootStore.users.adminUsers.find((user: IUserModel) => user.id === userId));
                self.setIdle();
            } catch (e) {
                self.setIdle();
                throw e;
            }
        }),

        /**
        * this method change Current User Password
        * @param params, this param data params for edit
        */
        changeCurrentUserPassword: flow(function* (params: any): any {
            self.setBusy();

            try {
                let response = yield Transport.post('auth/password/change', params);
                applySnapshot(RootStore.users.currentUser, response.data['user']);
                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method resend Verification Email
        * @param token, this param get token recived
        */
        resendVerificationEmail: flow(function* (token: string) {
            self.setBusy();

            try {
                BaseTransport.token = token;
                let response = yield Transport.post('auth/email/resend', {});
                return response;
            } catch (error) {
                handleError(error);
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method change Email
        * @param userId, this param user ID
        * @param email, this param get new email
        * @param token, this param get token
        */
        changeEmail: flow(function* (userId: number, email: string, token: ITokenModel) {
            self.setBusy();
            try {
                BaseTransport.token = token.access_token;
                let response = yield Transport.patch(
                    `users/${userId}`,
                    {
                        user: {
                            email,
                        },
                    },
                    null,
                );
                self.setIdle();
                return response;
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method send Email
        * @param userId, this param user ID
        * @param values, this param get data content email
        */
        sendEmail: flow(function* (userId: number, values: any) {
            try {
                yield Transport.post(`admin/users/${userId}/send-email`, values);
            } catch (error) {
                throw error;
            }
        }),

        /**
        * this method change User Password By Admin
        * @param userId, this param user ID
        * @param values, this param get values for change password
        */
        changeUserPasswordByAdmin: flow(function* (userId: number, values: any) {
            try {
                yield Transport.post(`admin/users/${userId}/change-password`, values);
            } catch (error) {
                throw error;
            }
        }),

        /**
        * this method verify Email Address
        * @param verificationCode, this param get verification Code
        * @param token, this param get token
        * @param logInAfterVerification, this param if true login After Verification
        */
        verifyEmailAddress: flow(function* (verificationCode: string, token: any, logInAfterVerification: boolean) {
            self.setBusy();

            try {
                BaseTransport.token = token.access_token;
                let response = yield Transport.post<any>(
                    'v1/auth/email/verify',
                    {
                        token: verificationCode,
                    },
                    makeTokenUserQuery(),
                );

                response.data.user.token = token;
                let user = response.data['user'];
                user.token = token;
                if (logInAfterVerification) {
                    self.setLoggedInUser(response.data['user']);
                    self.setIdle();
                }
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method change User Password
        * @param password, this param get new password
        * @param token, this param get token
        */
        changeUserPassword: flow(function* (password: string, token: string) {
            self.setBusy();
            BaseTransport.token = token;

            try {
                deleteCookies();

                let response = yield Transport.post<any>(
                    'auth/password/reset',
                    { token: token, password: password, app: getAppInfo() },
                    makeTokenUserQuery(),
                );

                self.setLoggedInUser(response.data['user']);

                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        fetchAllUTMs: flow(function* (
            pagination: { page: number; pageSize: number },
            filter_groups,
            SortOrder,
            search
        ): any {
            self.setBusy();
            try {
                let query = new ApiQueryBuilder();
                query.addQueryParam('filters', filter_groups)
                query.addQueryParam('sorts', SortOrder)
                query.setSearch(search)
                query.setPagination(pagination.pageSize, pagination.page + 1)
        
                let response = yield Transport.get("v2/admin/utms?", query);
        
                self.setIdle();
                return {
                  users: response.data["result"],
                  pagination: {
                    totalPages: response.data.last_page,
                    pageSize: response.data.per_page,
                    page: response.data.current_page - 1,
                    totalCount: response.data.total,
                  },
                };
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),
        fetchUTMFilters: flow(function* (): any {
            try {        
                let response = yield Transport.get("v2/admin/utms/filter-options");
                return response.data.filters
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),
    }))
    .views((self) => ({

        /**
        * this method is Logged In
        */
        isLoggedIn() {
            return self.loggedIn;
        },

        /**
        * this method get Token
        */
        getToken() {
            return self.token;
        },

        /**
        * this method get Identities
        */
        getIdentities(): IIdentityModel[] {
            return RootStore.identityStore.identities.filter(
                (identity: IIdentityModel) => getSnapshot(identity).user_id === self.currentUser.id,
            );
        },

        /**
        * this method get Default Identity
        */
        getDefaultIdentity(): IIdentityModel {
            let identity = RootStore.identityStore.identities.filter(
                (identity: IIdentityModel) =>
                    getSnapshot(identity).user_id === self.currentUser.id && identity.type === 'brand',
            )[0];
            if (typeof identity === 'undefined') {
                return self.currentUser
                    .getIdentities()
                    .filter(
                        (identity: IIdentityModel) =>
                            getSnapshot(identity).user_id === self.currentUser.id && identity.type === 'brand',
                    )[0];
            }
            return identity;
        },

        /**
        * this method get User By Id
        */
        getUserById() {
            return self.adminUsers;
        },
    }));

export interface IUserStore extends Instance<typeof UserStore> { }

export interface IUserStoreSnapshotIn extends SnapshotIn<typeof UserStore> { }

export interface IUserStoreSnapshotOut extends SnapshotOut<typeof UserStore> { }

// {(values, { setSubmitting }) => {
//     Transport.post<any>('auth/register', {
//         user: values,
//         app: getAppInfo()
//     }).then(
//         (response)=>{
//             setSubmitting(false);
//
//             showActivationModal(values.email, "register");
//         }
//     ).catch(
//         (error)=>{
//             if(typeof error.response !== "undefined"){
//                 if(error.response.status === 422){
//                     setSubmitting(false);
//                     Swal.fire({
//                         type: 'error',
//                         title: 'Already Registered!',
//                         text: 'Looks like someone has already registered with this email address! Please choose another one.',
//                         footer: '<a href="/forgot-password">Forgot your password?</a>'
//                     })
//                 }else{
//                     setSubmitting(false);
//                     ajaxErrorAlert('There was a problem in registration!');
//
//                 }
//             }else{
//                 console.log(error);
//             }
//
//         }
//     );
// }}
