import React, { Component} from 'react';
import { Row } from 'reactstrap';
import { Colxx } from "../../../../template/components/common/CustomBootstrap";
import { BreadCrumb } from "../../../common-components";
import { TabsComponent } from "../../../common-components";
import { RouteComponentProps } from "react-router-dom";
import InviteStatus from './InviteStatue';
import PublishJobsChart from './PublishJobsChart';
import OverView from './OverView';


interface Props extends RouteComponentProps {}


export default class chart extends Component<Props>{
    render() {
        return (
            <Row>
            <Colxx xxs="12">
                <BreadCrumb heading="Chart and Analysis" {...this.props} />
            </Colxx>

            <div style={{ width: "100%" }}>
            <TabsComponent
              tabs={[
                {
                  tabName:"overview",
                  component:(
                      <OverView key="overview" {...this.props}/>
                  ),
                  to:`${this.props.match.url}`
                },
                {
                  tabName: "Invite",
                  component: (
                    <InviteStatus key="Invitation" {...this.props} />
                  ),
                  to: `${this.props.match.url}/Invitation`,
                },
                {
                    tabName:"Publishjob",
                    component:(
                        <PublishJobsChart key="Publishjob" {...this.props}/>
                    ),
                    to:`${this.props.match.url}/publishjob`
                },
                
              ]}
              {...this.props}
            />
          </div>

            </Row>
        )
    }
}