import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
* this method Publish Job Error Model
*/
export const PublishJobErrorModel = types
  .model({
    id: types.identifierNumber,
    type: types.enumeration([
      "CHECKPOINT",
      "MEDIA_NOT_FOUND",
      "INACTIVE_USER",
      "ACTION_SPAM",
      "LOGIN_REQUIRED",
      "CHALLENGE_WRONG_CODE",
      "UNKNOWN",
      "ACCESS_SUSPENDED",
      "WRONG_PASSWORD",
      "TWO_FACTOR_LOGIN",
    ]),
    created_at: types.string,
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export interface IPublishJobErrorModel
  extends Instance<typeof PublishJobErrorModel> {}
export interface IPublishJobErrorModelSnapshotIn
  extends SnapshotIn<typeof PublishJobErrorModel> {}
export interface IPublishJobErrorModelSnapshotOut
  extends SnapshotOut<typeof PublishJobErrorModel> {}
