import {
  // applySnapshot,
  flow,
  //   Instance,
  //   SnapshotIn,
  //   SnapshotOut,
  types,
  destroy,
  // applySnapshot,
} from "mobx-state-tree";
import { notificationQuery } from "./common/RequestHelpers";
// import moment from "moment";
import Transport from "../lib/Transport";
import {
  NotificationModel,
  INotificationModel,
} from "./models/NotificationModel";
import moment from "moment";
import { countDown } from "../lib/Utils";

export const NotificationStore = types
  .model({
    notifications: types.array(NotificationModel),
  })
  .volatile((self) => ({
    isBusy: false,
  }))
  .actions((self) => ({

    /**
    * this method check processing for this store
    */
    setBusy() {
      self.isBusy = true;
    },

    /**
    * this method just set value isBusy = false
    */
    setIdle() {
      self.isBusy = false;
    },

    /**
    * this method remove notifications
    */
    removeNotifications() {
      destroy(self.notifications);
    },
  }))
  .actions((self) => ({

    /**
    * this method fetch notifications
    */
    fetchNotifications: flow(function* () {
      self.setBusy();
      try {
        let response = yield Transport.get(
          "notifications",
          notificationQuery()
        );
        response.data.notifications.forEach(
          (notification: INotificationModel) => {
            self.notifications.push(notification);
          }
        );
        self.setIdle();
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),
  }))
  .views((self) => ({

    /**
    * this method get new Offer notifictions
    */
    getNewOfferNotifictions() {
      return self.notifications.filter((notification) =>
        notification.data.event.includes("offer")
      ).length;
    },

    todayNotifications() {
      return self.notifications.filter((notification) => {
        return (
          !countDown(moment(notification.created_at)).includes("month") &&
          !countDown(moment(notification.created_at)).includes("days")
        );
      });
    },
    yesterdayNotifications() {
      return self.notifications.filter((notification) => {
        return (
          !countDown(moment(notification.created_at)).includes("month") &&
          countDown(moment(notification.created_at)).includes("days") &&
          countDown(moment(notification.created_at))[1] === " " &&
          countDown(moment(notification.created_at))[0] === "1"
        );
      });
    },
    lastMonthNotifications() {
      return self.notifications.filter((notification) => {
        return (
          !countDown(moment(notification.created_at)).includes("years") &&
          !countDown(moment(notification.created_at)).includes("month") &&
          countDown(moment(notification.created_at)).includes("days") &&
          +countDown(moment(notification.created_at)).split(" ")[0] > 1
        );
      });
    },

    /**
   * this method get unread notifications
   */
    getUnredNotifications() {
      return self.notifications.filter(
        (notification: INotificationModel) => !notification.read_at
      );
    },
  }))
  .views((self) => ({

    /**
    * this method get Number Of Unred Notifications
    */
    getNumberOfUnredNotifications() {
      return self.getUnredNotifications().length;
    },
  }))
  .actions((self) => ({

    /**
    * this method for read notifications
    */
    readNotifications: flow(function* () {
      self.setBusy();
      let unreadNotificationIds = self
        .getUnredNotifications()
        .map((notification: INotificationModel) => {
          return notification.id;
        });
      try {
        let response = yield Transport.post("notifications/read", {
          ids: unreadNotificationIds,
        });
        self.notifications.forEach((notification: INotificationModel) => {
          if (!notification.read_at) {
            notification.read_at = response.data.read_at;
          }
        });

        self.setIdle();
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),
  }));
