import React, { Component } from "react";
import axios from "axios";

import { FormGroup, Label, Input, Button, Card, CardBody } from "reactstrap";
// import { BreadCrumb } from '../../common-components';
import { RouteComponentProps } from "react-router-dom";
// import Transport from '../../../lib/Transport';
// import { AxiosResponse } from 'axios';
import TransactionsListComponent from "./TransactionsListComponent";
// import ApiQueryBuilder from '../../../lib/ApiQueryBuilder';
import { FilterGroup, SortOrder, QueryOperator } from "../../../lib/QueryTypes";
import ServerSideFilterSet from "../../common-components/Filter/ServerSideFilterSet";
import { debounce } from "lodash";
import { Filters } from "../users-managment/components/Users";
import {
  FilterType,
  Range,
} from "../../common-components/Filter/AbstractFilterSet";
import { ITransactionModel } from "../../../store/models/TransactionModel";
import FilterComponent from "../../common-components/Filter/FilterComponent";
import moment from "moment";
// import { showLoading } from '../../../lib/Utils';
import DatatablePagination from "../../common-components/DatatablePagination";
import Swal from "sweetalert2";
import {
  servicePath,
  V2_TRANSACTION,
} from "./../../../constants/defaultValues";
import RootStore from "../../../store/RootStore";
import classes from "./style.module.scss";

interface State {
  transactions: ITransactionModel[] | [];
  datafetched: boolean;
  filter_groups?: FilterGroup[];
  sorts: { key: string; direction: SortOrder };
  pagination: {
    limit: number;
    page: number;
    pagesCount: number;
    totalCount: number;
  };
  status?: string;
  type?: string;
  sort?: string;
  start?: string;
  end?: string;
  search?: string;
  delayTimer?: any;
}

interface Props extends RouteComponentProps {}

export default class Transactions extends Component<Props, State> {
  private readonly filterSet: ServerSideFilterSet;
  constructor(props: Props) {
    super(props);
    this.state = {
      transactions: [],
      datafetched: false,
      sorts: {
        key: "created_at",
        direction: SortOrder.DESCENDING,
      },
      filter_groups: [
        {
          isOr: false,
          filters: [
            {
              key: "",
              value: "",
              operator: null,
              not: false,
            },
          ],
        },
      ],
      pagination: {
        limit: 20,
        page: 1,
        pagesCount: 0,
        totalCount: 0,
      },
      status: null,
      type: "charge",
      sort: null,
      start: null,
      end: null,
      search: null,
      delayTimer: null,
    };
    // status limit page type sort start end search

    this.filterSet = new ServerSideFilterSet(debounce(this.onChange, 600));
    this.filterSet.addFilter({
      name: "created_at",
      title: "Create Date",
      type: FilterType.simpleFilter,
      defaultValue: this.state.sorts
        ? this.state.sorts.direction
        : SortOrder.DESCENDING,
      values: () => [SortOrder.ASCENDING, SortOrder.DESCENDING],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === SortOrder.ASCENDING) return "Ascending";
        else if (value === SortOrder.DESCENDING) return "Descending";
      },
    });
    this.filterSet.addFilter({
      name: "status",
      title: "Status",
      type: FilterType.simpleFilter,
      defaultValue: null,
      values: () => [
        // "cancelled",
        // "expired",
        // "failed",
        "locked",
        "pending",
        "refunded",
        "transferred",
        null,
      ],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value && typeof value === "string") return value;
        else return "All";
      },
    });
    this.filterSet.addFilter({
      name: "type",
      title: "Type",
      type: FilterType.simpleFilter,
      defaultValue: "charge",
      values: () => ["charge", "transfer", "payout", "credit", null],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value && typeof value === "string") return value;
        else return "All";
      },
    });
    this.filterSet.addFilter({
      name: "created_at_range",
      title: "Date range",
      type: FilterType.rangeFilter,
      defaultValue: null,
      values: (value: Range) => [{ start: value.start, end: value.end }, null],
    });

    this.onChange = this.onChange.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.pageSizeChange = this.pageSizeChange.bind(this);
  }

  getTransactions = async () => {
    this.setState({ datafetched: false });
    const headers = {
      headers: {
        Authorization: "Bearer " + RootStore.users.getToken(),
      },
    };
    const { status, type, start, end, search } = this.state;
    const { limit, page } = this.state.pagination;
    const { direction } = this.state.sorts;

    const STATUS = status ? `&status=${status}` : "";
    const LIMIT = limit ? `&limit=${limit}` : "";
    const PAGE = page ? `&page=${page}` : "";
    const TYPE = type ? `&type=${type}` : "";
    const SORT = direction
      ? `&sort=${direction === "DESC" ? "desc" : "asc"}`
      : "";
    const START = start ? `&start=${start}` : "";
    const END = end ? `&end=${end}` : "";
    const SEARCH = search ? `&search=${search}` : "";

    const url = `${servicePath}${V2_TRANSACTION}?${STATUS}${LIMIT}${PAGE}${TYPE}${SORT}${START}${END}${SEARCH}`;

    axios
      .get(url, headers)
      .then((res) => {
        this.setState((prevState) => ({
          transactions: res.data.transactions,
          datafetched: true,
          pagination: {
            ...prevState.pagination,
            pagesCount: res.data.per_page,
            totalCount: res.data.total,
          },
        }));
        this.setState({ datafetched: true });
      })
      .catch((err) => {
        this.setState({ datafetched: true });
        Swal.fire({
          type: "error",
          text: err.response.data.message,
          showCancelButton: false,
          showConfirmButton: true,
        });
      });
  };

  async componentDidMount(): Promise<any> {
    this.getTransactions();
  }

  setFilters(filters: Filters) {
    console.log("filters: ", filters);
    let filterGroup = [];
    if (filterGroup.length)
      filterGroup.push({
        isOr: false,
        filters: [
          {
            key: "",
            value: "",
            operator: null,
            not: false,
          },
        ],
      });
    function addToFilterGroup(key, value, op, not) {
      filterGroup.push({
        isOr: false,
        filters: [
          {
            key: key,
            value: value === "all" ? null : value,
            operator: op,
            not: not,
          },
        ],
      });
    }

    // status
    if (filters.filters.status || filters.filters.status === null) {
      addToFilterGroup("status", filters.filters.status, null, false);
      this.setState({ status: filters.filters.status || "" });
    } else {
      filterGroup.filter((fl) => {
        return fl.filters[0].value !== null;
      });
    }

    // type
    if (filters?.filters?.type || filters?.filters?.type === null) {
      addToFilterGroup("type", filters.filters?.type, null, false);
      this.setState({ type: filters.filters?.type || "" });
    } else {
      filterGroup.filter((fl) => {
        return fl.filters[0].value !== null;
      });
    }
    // create at - sort
    const start = moment(filters?.filters?.created_at_range?.start).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const end = moment(filters?.filters?.created_at_range?.end).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    if (filters?.filters?.created_at_range)
      addToFilterGroup(
        "created_at",
        [start, end],
        QueryOperator.BETWEEN,
        false
      );

    this.setState({
      pagination: {
        ...this.state.pagination,
        page: 1,
        limit: this.state.pagination.limit,
      },
      filter_groups: filterGroup,
      sorts: {
        key: "created_at",
        direction: filters.filters.created_at,
      },
    });
  }

  onChange = (filters: Filters): void => {
    this.setFilters(filters);
    this.getTransactions();
  };

  pageSizeChange(limit: number) {
    this.setState(
      {
        pagination: { ...this.state.pagination, page: 1, limit },
      },
      this.getTransactions
    );
  }

  pageChange(page: number) {
    this.setState(
      (prevState) => ({
        pagination: { ...prevState.pagination, page: page + 1 },
      }),
      this.getTransactions
    );
  }

  changeDate = (date) => {
    this.setState({
      start: moment(date?.start).format("YYYY-MM-DD HH:mm:ss"),
      end: moment(date?.end).format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  searchOnChange = async (e) => {
    const { value } = e.target;
    await this.setState({
      search: value,
    });
    clearTimeout(this.state.delayTimer);
    const self = this;
    if (value.length > 2 || value.length < 1) {
      this.setState({
        delayTimer: setTimeout(function () {
          self.getTransactions();
        }, 300),
      });
    }
  };

  clearFilter = () => {
    this.setState({
      status: "",
      type: "",
      start: "",
      end: "",
      search: "",
    });
  };

  render() {
    const { transactions, pagination, datafetched } = this.state;
    const { limit, page, pagesCount, totalCount } = pagination;
    return (
      <Card className="mb-4">
        <CardBody>
          <div className={classes.filter_box}>
            <div className={classes.filter_box_search_box}>
              <div className={classes.filter_box_search_box_input}>
                <FormGroup className="w-100">
                  <Label for="transactions">Search in Transactions : </Label>
                  <Input
                    onChange={this.searchOnChange}
                    type="text"
                    name="transactions"
                    id="transactions"
                    placeholder="search in name, lastname and email ... "
                  />
                </FormGroup>
              </div>
              <Button
                onClick={this.getTransactions}
                className={classes.filter_box_search_box_button}
                outline
                color="primary"
              >
                Apply Filter
              </Button>
            </div>
            <div className={classes.filter_box_dropdowns}>
              <FilterComponent
                clearFilter={this.clearFilter}
                changeDate={this.changeDate}
                filterSet={this.filterSet}
                search={false}
              />
            </div>
          </div>
          <>
            <TransactionsListComponent
              transactions={transactions}
              pagination={pagination}
              datafetched={datafetched}
            />
            {Array.isArray(transactions) && transactions.length > 0 ? (
              <DatatablePagination
                key="pagination"
                showPageSizeOptions={true}
                pageSizeOptions={[20, 50, 100, 200, 500]}
                onPageSizeChange={this.pageSizeChange}
                page={page - 1}
                pages={Math.floor(totalCount / limit) + 1}
                canPrevious={page === 1 ? false : true}
                canNext={page === pagesCount ? false : true}
                defaultPageSize={20}
                onPageChange={this.pageChange}
              />
            ) : null}
          </>
        </CardBody>
      </Card>
    );
  }
}
//git
