import { Grid } from '@mui/material';
import React from 'react';
import classes from './style.module.scss';

interface Iprops {
    list: [{}];
    remove: any;
}
export default function CreatAdUploads(props: Iprops) {
    const { list, remove } = props;

    const checkType = (el) => {
        return el.split('.').pop();
    };

    return (
        <>
            {list ? (
                <Grid item>
                    <Grid container marginTop={2} component="ul" className={classes.images} spacing={1}>
                        {list &&
                            list.map((el: any, key: number) => (
                                <Grid item component="li">
                                    <div className={classes.content}>
                                        {checkType(el) !== 'mp4' ? <img alt="" src={el} /> : <video src={el}></video>}
                                        <span className={classes.key}>{key + 1}</span>
                                        <button onClick={() => remove(el)}>
                                            <span className="glyph-icon simple-icon-trash"></span>
                                        </button>
                                    </div>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            ) : (
                ''
            )}
        </>
    );
}
