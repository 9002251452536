import React, { Component } from "react";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";

import {
  Colxx,
  Separator,
} from "../../../template/components/common/CustomBootstrap";
import { BreadCrumb, CalendarWidget } from "../../common-components";
import PublishJob from "./components/PublishJob";
import RootStore from "../../../store/RootStore";
import { PublishJobView } from "../../../store/PublishJobStore";
import {
  NoIgAddedIcon,
  ScheduleNewContentIcon,
  WhiteSearchIcon,
} from "../../../assets/icons";
import classNames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

interface State {
  activeTab: "pending" | "published" | "failed";
  isFetched: boolean;
}

@observer
export default class Scheduler extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "pending",
      isFetched: false,
    };
  }

  async componentDidMount() {
    await RootStore.publishJobStore.fetchAllIfNecessary();
    this.setState({ isFetched: true });

    // for (let offer of RootStore.offerStore.offers) {
    //   (async () => {
    //     await offer.getDialogue().fetchUnreadMessageCount();
    //   })();
    // }
  }

  toggle(tab: "pending" | "published" | "failed") {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div className="scheduler">
        <div className="text-zero top-right-button-container"></div>
        <BreadCrumb heading="Scheduler" {...this.props} />
        <Separator className="mb-5" />
        <div>
          {this.state.isFetched ? (
            <>
              {!RootStore.publishJobStore.publishJobList?.length ? (
                <div className="emptyStateContainer">
                  <NoIgAddedIcon />
                  <h2>There are no posts scheduled as of yet.</h2>
                  <p>
                    As you finalize deals with influencers, scheduled and
                    completed Instagram posts will appear here. You can also use
                    the free post scheduler for your own Instagram posting
                    activity including feed and story posts. Schedule days,
                    weeks or even months in advance from the convenience of your
                    desktop.
                  </p>
                  <div className="emptyStateContainer__buttons">
                    {RootStore.integrationStore.getMyIntegrations().length >
                    0 ? (
                      <Button
                        color="success"
                        onClick={() =>
                          this.props.history.push(
                            `${this.props.match.url}/schedule-content`
                          )
                        }
                      >
                        {" "}
                        New Post/Story
                      </Button>
                    ) : (
                      <Button
                        color="success"
                        onClick={() =>
                          this.props.history.push(
                            `/app/account/integrations/new-integration`
                          )
                        }
                      >
                        {" "}
                        Add IG Now
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                <Colxx xxs="12" className="mb-4">
                  <Row>
                    <Col lg={7} xs={12}>
                      <Colxx xxs="12" className="pl-0 pr-0 mb-5">
                        <div className="tabs">
                          <div
                            className={classNames("tab", {
                              selected: this.state.activeTab === "pending",
                            })}
                            onClick={() => this.toggle("pending")}
                          >
                            Pending{" "}
                            <div
                              className={classNames("count", {
                                selected: this.state.activeTab === "pending",
                              })}
                            >
                              {
                                RootStore.publishJobStore
                                  .getAllJobs()
                                  .filter(
                                    (model: PublishJobView) =>
                                      model.state !== "removal"
                                  )
                                  .filter(
                                    (model: PublishJobView) =>
                                      model.isTakeDown === false
                                  )
                                  .filter(
                                    (model: PublishJobView) =>
                                      model.status === "healthy" &&
                                      model.state === "scheduled"
                                  ).length
                              }
                            </div>
                          </div>
                          <div
                            className={classNames("tab", {
                              selected: this.state.activeTab === "published",
                            })}
                            onClick={() => this.toggle("published")}
                          >
                            Published
                            <div
                              className={classNames("count", {
                                selected: this.state.activeTab === "published",
                              })}
                            >
                              {
                                RootStore.publishJobStore
                                  .getAllJobs()
                                  .filter(
                                    (model: PublishJobView) =>
                                      model.state !== "removal"
                                  )
                                  .filter(
                                    (model: PublishJobView) =>
                                      model.isTakeDown === false
                                  )
                                  .filter(
                                    (model: PublishJobView) =>
                                      !(
                                        model.status === "failed" ||
                                        model.status === "expired"
                                      ) && model.state !== "scheduled"
                                  ).length
                              }
                            </div>
                          </div>
                          <div
                            className={classNames("tab", {
                              selected: this.state.activeTab === "failed",
                            })}
                            onClick={() => this.toggle("failed")}
                          >
                            Failed
                            <div
                              className={classNames("count", "failed", {
                                selected: this.state.activeTab === "failed",
                              })}
                            >
                              {
                                RootStore.publishJobStore
                                  .getAllJobs()
                                  .filter(
                                    (model: PublishJobView) =>
                                      model.state !== "removal"
                                  )
                                  .filter(
                                    (model: PublishJobView) =>
                                      model.isTakeDown === false
                                  )
                                  .filter(
                                    (model: PublishJobView) =>
                                      model.status === "failed" ||
                                      model.status === "expired"
                                  ).length
                              }
                            </div>
                          </div>
                          <div className="text-center ml-auto p-2">
                            <Link
                              className="btn btn-secondary btn-sm"
                              to="/app/content-manager"
                            >
                              <i className="iconsminds-folder-edit"> </i>{" "}
                              Content Library
                            </Link>
                          </div>
                        </div>
                        <PerfectScrollbar
                          style={{
                            backgroundColor: "#f8f8f8",
                            paddingLeft: 12,
                            height: "105vh",
                          }}
                          containerRef={(ref) => {}}
                          options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                          }}
                        >
                          {RootStore.publishJobStore
                            .getAllJobs()
                            .filter(
                              (model: PublishJobView) =>
                                model.state !== "removal"
                            )
                            .filter(
                              (model: PublishJobView) =>
                                model.isTakeDown === false
                            )
                            .filter((model: PublishJobView) => {
                              if (this.state.activeTab === "pending") {
                                return (
                                  model.status === "healthy" &&
                                  model.state === "scheduled"
                                );
                              } else if (this.state.activeTab === "failed") {
                                return (
                                  model.status === "failed" ||
                                  model.status === "expired"
                                );
                              } else {
                                return (
                                  !(
                                    model.status === "failed" ||
                                    model.status === "expired"
                                  ) && model.state !== "scheduled"
                                );
                              }
                            })
                            .map((model: PublishJobView) => (
                              <PublishJob
                                {...this.props}
                                key={`publishJob_${model.id}_${model.offerId}_${
                                  model.isTakeDown ? "t" : ""
                                }`}
                                job={model}
                              />
                            ))}
                        </PerfectScrollbar>
                      </Colxx>
                    </Col>
                    <Col lg={5} xs={12}>
                      <div className="calendar">
                        <div className="calendar_header">
                          <h3>Publish Schedule</h3>
                          <Link
                            className="btn btn-primary"
                            to="/app/scheduler/schedule-content"
                          >
                            <ScheduleNewContentIcon /> Schedule New Content
                          </Link>
                        </div>
                        <CalendarWidget />
                      </div>

                      <div className="actions">
                        <div
                          className="createNewAd"
                          onClick={() =>
                            this.props.history.push(`/app/ads/ad/objectives/`)
                          }
                        >
                          <div className="add">+</div>
                          Create a New Ad
                        </div>
                        <div
                          className="discover"
                          onClick={() =>
                            this.props.history.push(`/app/influencer-explorer`)
                          }
                        >
                          <WhiteSearchIcon /> Discover and Invite Influencers
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Colxx>
              )}
            </>
          ) : (
            <Spinner color="primary" className="mb-1" />
          )}
        </div>
      </div>
    );
  }
}
