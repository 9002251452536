import React from "react";
import { SlickSlider } from "../../../common-components";

// import StoryPreview from "./StoryPreview";
import PostPreview from "./PostPreview";

interface Props {
  timelineContents: any;
  totalFollowers: number;
}

export default function RecentActivities(props: Props) {
  const { timelineContents, totalFollowers } = props;

  return (
    <div className="influencerProfile__details_recentActivities">
      <h1>Recent Feed Posts and Stories</h1>
      <div>
        <SlickSlider
          settings={{
            className: "slider variable-width",
            dots: false,
            infinite: false,
            speed: 500,
            initialSlide: 0,
            slidesToShow: 3.4,
            slidesToScroll: 3,
            arrows: false,
            variableWidth: false,
            responsive: [
              {
                breakpoint: 420,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1.5,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 740,
                settings: {
                  slidesToShow: 2.5,
                  slidesToScroll: 4,
                },
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 1280,
                settings: {
                  slidesToShow: 3.2,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 1367,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 4,
                },
              },
              {
                breakpoint: 1800,
                settings: {
                  slidesToShow: 3.2,
                  slidesToScroll: 4,
                },
              },
            ],
          }}
        >
          {timelineContents.map((tc: any) => (
            <PostPreview
              content={tc}
              key={`timelineContent__${tc.id}`}
              totalFollowers={totalFollowers}
            />
          ))}
        </SlickSlider>
      </div>
    </div>
  );
}
