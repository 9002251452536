import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
* this method Time Table Model
*/
export const TimeTableModel = types
  .model({
    id: types.identifierNumber,
    ad_id: types.number,
    start: types.string,
    end: types.maybeNull(types.string),
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export interface ITimeTableModel extends Instance<typeof TimeTableModel> {}

export interface ITimeTableModelSnapshotIn
  extends SnapshotIn<typeof TimeTableModel> {}

export interface ITimeTableModelSnapshotOut
  extends SnapshotOut<typeof TimeTableModel> {}
