import moment, { Moment } from "moment";
import React, { Component } from "react";
import Datetime from "react-datetime";
import RootStore from "../../store/RootStore";

interface Props {
  name: string;
  value: any;
  className: string;
  onChange: (name: string, value: any) => void;
  onBlur: (name: string, flag: boolean) => void;
}

interface State {}

export class FormikDatePicker extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  value: any = null;

  getValue() {
    return this.value;
  }

  handleChange(value: string | moment.Moment) {
    this.props.onChange(this.props.name, value);
  }

  handleBlur() {
    this.props.onBlur(this.props.name, true);
  }

  render() {
    const { value, className } = this.props;

    let inputProps = {};
    // @ts-ignore
    if (this.props.disabled) {
      inputProps = {
        disabled: true,
        onClick: (e: any) => {
          e.stopPropagation();
          e.preventDefault();
        },
      };
    }

    return (
      <Datetime
        {...this.props}
        // id={name}
        // name={name}
        value={value}
        className={`${className}`}
        onChange={this.handleChange}
        closeOnClickOutside
        closeOnSelect
        dateFormat="YYYY MMMM Do"
        initialValue={value}
        inputProps={inputProps}
        isValidDate={(current: Moment, selectedDate: Moment) => {
          if (RootStore.users.currentUser.is_admin) return true;
          return moment(current).isSameOrAfter(moment.now(), "date");
        }}
      />
    );
  }
}
