import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { Button, FormGroup, Label, Modal, ModalBody, Badge } from 'reactstrap';
// @ts-ignore
import ReactTooltip from 'react-tooltip';

import { momentDateTimeFormat, isMobileAndTablet } from '../../../../lib/Utils';
import { FormikSwitch, FormikDuration } from '../../../common-components';
import ContentSelectFormik from '../../scheduler/components/ContentSelectFormik';
import { IOfferModel } from '../../../../store/models/OfferModel';
import { IContentModel } from '../../../../store/models/ContentModel';
import { FormikDatePicker } from '../../../common-components';
import { LeftArrowIcon, BackToDefaultIcon, NarrowArrowIcon, InformationIcon } from '../../../../assets/icons';
import RootStore from '../../../../store/RootStore';

interface Props extends RouteComponentProps {
    disabled?: boolean;
    showSubmit?: boolean;
    offer?: IOfferModel | null;
    isOpen?: boolean;
    onSubmit?: (values: any) => void;
    onClose?: () => void;
}
interface State {
    start: moment.Moment;
}

export default class OfferForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            start: this.props.offer.start
                ? moment(this.props.offer.start, momentDateTimeFormat)
                : moment(new Date(), momentDateTimeFormat),
        };
    }
    static defaultProps = {
        disabled: false,
        showSubmit: true,
        onSubmit: () => { },
    };

    static getSchema(allowNull: boolean) {
        return Yup.object().shape({
            start: Yup.date().min(new Date(), 'You must select a date in future!'),
            permanent: Yup.boolean().required('You must select permanent post config'),
            duration: Yup.number()
                .nullable(allowNull)
                .test(
                    'test_length_less_then_hour',
                    "You can't set the duration to less than 4 hours",
                    function (value) {
                        // your condition
                        if (value > 1 && value < 14400) {
                            // setting the error message using the value's length
                            return false;
                        }
                        return true;
                    },
                )
                .min(1, 'You can not set duration less than 1')
                .when(['permanent'], (permanent: boolean, schema: any) => {
                    if (permanent) {
                        return schema.min(0);
                    }
                })
                .when(['content', 'permanent'], (content: IContentModel, permanent: number, schema: any) => {
                    if (content && content.type === 'story' && permanent) {
                        return schema.max(
                            24 * 60 * 60,
                            'Stories will be automatically deleted by instagram after 24 hours, so you can not set a duration longer than 24 hours.',
                        );
                    }
                    return schema;
                }),
            // exclusivity: Yup.number().when("exclusive_publish", {
            //   is: true, // alternatively: (val) => val == true
            //   then: Yup.number().min(0),
            //   otherwise: Yup.number(),
            // }),

            price: Yup.number()
                .nullable(allowNull)
                .min(0.1, 'You can not set a price less than $0.1')
                .typeError('The price value must be a number!'),
            content: Yup.object().nullable(allowNull),
            bio_link: Yup.string()
                .matches(
                    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                    'Please enter a valid URL',
                )
                .nullable(),
        });
    }

    getInitialValues(): any {
        if (!this.props.offer) {
            return {
                price: 0,
                permanent: false,
                duration: 60 * 60,
                exclusive_publish: 0,
                // exclusivity: 0,
                content: null,
                start: this.state.start,
                bio_link: '',
                has_bio_link: false,
            };
        } else {
            let start: any = this.state.start;

            return {
                price: this.props.offer.price ? this.props.offer.price : 0,
                permanent: this.props.offer.duration === 1,
                duration: this.props.offer.duration ? this.props.offer.duration : 0,
                // exclusive_publish: this.props.offer.exclusivity > 0,
                // exclusivity: this.props.offer.exclusivity ? this.props.offer.exclusivity : 0,
                content: this.props.offer.content_id,
                bio_link: this.props.offer.bio_link,
                has_bio_link: this.props.offer.bio_link && this.props.offer.bio_link.length > 0,
                start: start,
            };
        }
    }

    render() {
        return (
            <Modal isOpen={true} className="offerForm">
                <ModalBody>
                    <Formik
                        initialValues={this.getInitialValues()}
                        validationSchema={OfferForm.getSchema(!!this.props.offer)}
                        onSubmit={this.props.onSubmit}
                    >
                        {({
                            handleSubmit,
                            setFieldValue,
                            setFieldTouched,
                            setValues,
                            handleChange,
                            handleBlur,
                            values,
                            errors,
                            touched,
                            isSubmitting,
                        }) => (
                            <Form translate={'no'} className="av-tooltip tooltip-label-right w-100">
                                <div className="offerForm__header">
                                    <div className="offerForm__header_title">
                                        <h3>
                                            <p>Counter Offer{!isMobileAndTablet() && ' for'}</p>
                                            <Badge color="primary" pill>
                                                Ad: {this.props.offer.ad_id.title}
                                            </Badge>
                                        </h3>
                                        <p className="subTitle">
                                            <span>Last Offer:</span>
                                            <span>
                                                By{' '}
                                                {this.props.offer.getLastModifierIdentity().user_id ===
                                                    RootStore.users.currentUser.id
                                                    ? 'Yourself'
                                                    : this.props.offer.getLastModifierIdentity().name}
                                                , {moment.utc(this.props.offer.updated_at).local().fromNow()}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="offerForm__header_actions">
                                        {!isMobileAndTablet() ? (
                                            <div className="buttonLink mr-4" onClick={this.props.onClose}>
                                                Cancel
                                            </div>
                                        ) : (
                                            <LeftArrowIcon
                                                className="adSelection__closeIcon"
                                                onClick={this.props.onClose}
                                            />
                                        )}
                                        {this.props.showSubmit ? (
                                            <Button color="success" type="submit" disabled={isSubmitting}>
                                                {this.props.offer ? 'Make Counter-Offer' : 'Make Offer'}
                                                <span
                                                    style={{ verticalAlign: 'bottom' }}
                                                    className="glyph-icon iconsminds-right"
                                                />
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="offerForm__formWrapper">
                                    <div className="offerForm__form">
                                        <p>
                                            {this.props.offer.status === 'paid' ? (
                                                <>
                                                    After payment, you can only change bio link and the content. If you
                                                    want to change price, date or duration, please cancel the deal and
                                                    re-negotiate with the influencer.
                                                </>
                                            ) : !isMobileAndTablet() ? (
                                                <>
                                                    Current offer details are outlined below. You may change values and
                                                    then select Make Counter Offer to propose a new deal
                                                </>
                                            ) : (
                                                <>Change any of the terms to make new suggestions</>
                                            )}
                                        </p>
                                        <div className="offerForm__form_priceTime">
                                            <FormGroup className="error-l-100">
                                                <Label>
                                                    <div>
                                                        <b className="position-relative">
                                                            Offer Price ($ USD):
                                                            {errors.price && touched.price && (
                                                                <div className="invalidFeedback">{errors.price}</div>
                                                            )}
                                                        </b>
                                                        <InformationIcon data-tip data-for="AdPrice" />
                                                        <ReactTooltip id="AdPrice" type="dark" effect="solid">
                                                            <p>
                                                                The price this influencer will be paid to post the
                                                                creatives on their story or feed, based on the offer
                                                                terms you will agree on.
                                                            </p>
                                                        </ReactTooltip>
                                                    </div>
                                                    {this.props.offer.price !== values.price ? (
                                                        <Badge key={values.price} className="changed" pill>
                                                            Changed
                                                        </Badge>
                                                    ) : null}
                                                </Label>
                                                <Field
                                                    disabled={this.props.disabled}
                                                    className="form-control w-100"
                                                    name="price"
                                                />
                                                {values.price !== this.props.offer.price &&
                                                    this.props.offer.price !== null && (
                                                        <div
                                                            className="backToDefault"
                                                            onClick={() =>
                                                                setFieldValue('price', this.props.offer.price)
                                                            }
                                                        >
                                                            <BackToDefaultIcon />
                                                            {!isMobileAndTablet() ? 'Back To Default' : 'Undo'}
                                                        </div>
                                                    )}
                                            </FormGroup>

                                            <FormGroup className="error-l-100 offerForm__form_publishDate">
                                                <Label
                                                    className="d-flex justify-content-between"
                                                    style={{ height: 20 }}
                                                >
                                                    <div>
                                                        <b className="position-relative">
                                                            Expected Publishing Date:
                                                            {errors.start && touched.start && (
                                                                <div className="invalidFeedback">{errors.start}</div>
                                                            )}
                                                        </b>
                                                        <InformationIcon data-tip data-for="AdPublishDate" />
                                                        <ReactTooltip id="AdPublishDate" type="dark" effect="solid">
                                                            <p>
                                                                The date and time you would like the influencer to publish the post.
                                                                This is your own local time as you see on your device.
                                                            </p>
                                                        </ReactTooltip>
                                                    </div>
                                                    {values.start === null &&
                                                        !moment(this.props.offer.start, momentDateTimeFormat).isSame(
                                                            values.start,
                                                        ) ? (
                                                        <Badge color="primary" pill>
                                                            Changed
                                                        </Badge>
                                                    ) : null}
                                                </Label>
                                                <FormikDatePicker
                                                    //@ts-ignore
                                                    disabled={this.props.disabled}
                                                    name="start"
                                                    value={values.start}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    dateFormat="LLL"
                                                    timeCaption="Time"
                                                />
                                                {!this.state.start.isSame(values.start) && (
                                                    <div
                                                        className="backToDefault"
                                                        onClick={() => {
                                                            setFieldValue(
                                                                'start',
                                                                moment(this.state.start, momentDateTimeFormat),
                                                            );
                                                        }}
                                                    >
                                                        <BackToDefaultIcon />
                                                        {!isMobileAndTablet() ? 'Back To Default' : 'Undo'}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </div>

                                        <div className="offerForm__form_permanent">
                                            <FormGroup className="error-l-100">
                                                <Label>
                                                    <b className="position-relative">
                                                        Permanent Post
                                                        {errors.permanent && touched.permanent && (
                                                            <div className="invalidFeedback">{errors.permanent}</div>
                                                        )}
                                                    </b>
                                                </Label>
                                                <FormikSwitch
                                                    disabled={this.props.disabled}
                                                    name="permanent"
                                                    className="custom-switch custom-switch-primary"
                                                    value={values.permanent}
                                                    onChange={this.props.disabled ? () => { } : setFieldValue}
                                                    onBlur={
                                                        this.props.disabled ||
                                                            (this.props.offer?.status === 'paid' &&
                                                                this.props.offer.getLatestPublishJob()?.status !== 'failed')
                                                            ? () => { }
                                                            : setFieldTouched
                                                    }
                                                />
                                            </FormGroup>

                                            <FormGroup className="error-l-100">
                                                {!values.permanent ? (
                                                    <div>
                                                        <Label>
                                                            <div>
                                                                <b className="position-relative">
                                                                    Duration:
                                                                    {errors.duration && touched.duration && (
                                                                        <div className="invalidFeedback">
                                                                            {errors.duration}
                                                                        </div>
                                                                    )}
                                                                </b>
                                                                <InformationIcon data-tip data-for="AdDuration" />
                                                                <ReactTooltip
                                                                    id="AdDuration"
                                                                    type="dark"
                                                                    effect="solid"
                                                                >
                                                                    <p>
                                                                        Posts can display permanently, or be posted for
                                                                        a period of time and then being removed based on
                                                                        the duration. Typical durations are 6, 12 or 24
                                                                        hours. The platform will publish the post and
                                                                        remove it automatically.
                                                                    </p>
                                                                </ReactTooltip>
                                                            </div>

                                                            {this.props.offer.duration !== values.duration && (
                                                                <Badge className="changed" pill>
                                                                    Changed
                                                                </Badge>
                                                            )}
                                                        </Label>
                                                        <FormikDuration
                                                            className="formikDuration"
                                                            name="duration"
                                                            gridRatio={4}
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            disabled={this.props.disabled}
                                                            value={values.duration}
                                                        />
                                                        {this.props.offer.duration !== values.duration && (
                                                            <div
                                                                className="backToDefault"
                                                                onClick={() => {
                                                                    setFieldValue(
                                                                        'duration',
                                                                        this.props.offer.duration,
                                                                    );
                                                                }}
                                                            >
                                                                <BackToDefaultIcon />
                                                                {!isMobileAndTablet() ? 'Back To Default' : 'Undo'}
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <React.Fragment>
                                                        <input
                                                            className={'form-control'}
                                                            value={'Never (Permanent Post)'}
                                                            disabled={true}
                                                        />

                                                        {(this.props.offer.duration === 0 &&
                                                            values.permanent === false) ||
                                                            (this.props.offer.duration !== 0 &&
                                                                values.permanent === true && (
                                                                    <div
                                                                        className="backToDefault"
                                                                        onClick={(
                                                                            event: React.MouseEvent<HTMLDivElement>,
                                                                        ) => {
                                                                            if (
                                                                                this.props.offer.duration === 0 &&
                                                                                values.permanent
                                                                            ) {
                                                                                setFieldValue('permanent', true);
                                                                            }

                                                                            if (
                                                                                this.props.offer.duration !== 0 &&
                                                                                values.permanent === true
                                                                            ) {
                                                                                setFieldValue('permanent', false);
                                                                            }

                                                                            if (
                                                                                values.duration !==
                                                                                this.props.offer.duration
                                                                            ) {
                                                                                setFieldValue(
                                                                                    'duration',
                                                                                    this.props.offer.duration,
                                                                                );
                                                                                setValues({
                                                                                    duration: this.props.offer.duration,
                                                                                });
                                                                            }
                                                                        }}
                                                                    >
                                                                        <BackToDefaultIcon />
                                                                        {!isMobileAndTablet()
                                                                            ? 'Back To Default'
                                                                            : 'Undo'}
                                                                    </div>
                                                                ))}
                                                    </React.Fragment>
                                                )}
                                            </FormGroup>
                                        </div>

                                        <div className="offerForm__form_bioLink">
                                            <FormGroup className="error-l-100">
                                                <Label>
                                                    <b className="position-relative">
                                                        Put bio URL in Influencer's profile
                                                        {errors.bio_link && touched.bio_link && (
                                                            <div className="invalidFeedback">{errors.bio_link}</div>
                                                        )}
                                                    </b>
                                                </Label>
                                                <FormikSwitch
                                                    disabled={this.props.disabled}
                                                    name="has_bio_link"
                                                    className="custom-switch custom-switch-primary"
                                                    value={values.has_bio_link}
                                                    onChange={this.props.disabled ? () => { } : setFieldValue}
                                                    onBlur={this.props.disabled ? () => { } : setFieldTouched}
                                                />
                                            </FormGroup>

                                            <FormGroup className="error-l-100">
                                                {values.has_bio_link && (
                                                    <div>
                                                        <Field
                                                            name="bio_link"
                                                            id="bio_link"
                                                            className="form-control w-100"
                                                            value={values.bio_link}
                                                        />
                                                    </div>
                                                )}
                                                {this.props.offer.bio_link &&
                                                    this.props.offer.bio_link.length > 0 &&
                                                    !values.has_bio_link && (
                                                        <div
                                                            className="backToDefault"
                                                            onClick={() => {
                                                                setFieldValue('bio_link', this.props.offer.bio_link);
                                                                setFieldValue('has_bio_link', true);
                                                            }}
                                                        >
                                                            <BackToDefaultIcon />
                                                            {!isMobileAndTablet() ? 'Back To Default' : 'Undo'}
                                                        </div>
                                                    )}
                                                {!this.props.offer.bio_link && values.has_bio_link && (
                                                    <div
                                                        className="backToDefault"
                                                        onClick={() => {
                                                            setFieldValue('bio_link', '');
                                                            setFieldValue('has_bio_link', false);
                                                        }}
                                                    >
                                                        <BackToDefaultIcon />
                                                        {!isMobileAndTablet() ? 'Back To Default' : 'Undo'}
                                                    </div>
                                                )}

                                                {this.props.offer.bio_link &&
                                                    values.bio_link !== this.props.offer.bio_link && (
                                                        <div
                                                            className="backToDefault"
                                                            onClick={() => {
                                                                setFieldValue('bio_link', this.props.offer.bio_link);
                                                                setFieldValue('has_bio_link', true);
                                                            }}
                                                        >
                                                            <BackToDefaultIcon />
                                                            {!isMobileAndTablet() ? 'Back To Default' : 'Undo'}
                                                        </div>
                                                    )}
                                                {values.bio_link && !values.has_bio_link && (
                                                    <div
                                                        className="backToDefault"
                                                        onClick={() => {
                                                            setFieldValue('bio_link', values.bio_link);
                                                            setFieldValue('has_bio_link', true);
                                                        }}
                                                    >
                                                        <BackToDefaultIcon />
                                                        {!isMobileAndTablet() ? 'Back To Default' : 'Undo'}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </div>

                                        {/* <div className="offerForm__form_exclusivity">
                      <FormGroup className="error-l-100">
                        <Label>
                          <b className="position-relative">
                            Exclusive Post
                            {errors.exclusive_publish && touched.exclusive_publish && (
                              <div className="invalidFeedback">{errors.exclusive_publish}</div>
                            )}
                          </b>
                        </Label>
                        <FormikSwitch
                          disabled={this.props.disabled}
                          name="exclusive_publish"
                          className="custom-switch custom-switch-primary"
                          value={values.exclusive_publish}
                          onChange={this.props.disabled ? () => {} : setFieldValue}
                          onBlur={this.props.disabled ? () => {} : setFieldTouched}
                        />
                      </FormGroup>

                      <FormGroup className="error-l-100">
                        {values.exclusive_publish ? (
                          <div>
                            <Label>
                              <div>
                                <b className="position-relative">
                                  Exclusivity Hours:
                                  {errors.exclusivity && touched.exclusivity && (
                                    <div className="invalidFeedback">{errors.exclusivity}</div>
                                  )}
                                </b>
                                <InformationIcon data-tip data-for="AdDuration" />
                                <ReactTooltip id="AdDuration" type="dark" effect="solid">
                                  <p>
                                    <b>Only for Feed posts</b>. You may not want other brands or
                                    content promoted while your post is being published on Instagram
                                    feed. Set your preference here.
                                  </p>
                                </ReactTooltip>
                              </div>
                              {values.exclusivity !== this.props.offer.exclusivity && (
                                <Badge className="changed" pill>
                                  Changed
                                </Badge>
                              )}
                            </Label>
                            <FormikDuration
                              className="formikDuration"
                              name="exclusivity"
                              gridRatio={4}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              disabled={this.props.disabled}
                              value={values.exclusivity}
                            />
                            {values.exclusivity !== this.props.offer.exclusivity && (
                              <div
                                className="backToDefault"
                                onClick={() => {
                                  setFieldValue("exclusivity", this.props.offer.exclusivity);
                                }}>
                                <BackToDefaultIcon />
                                                              {
                                !isMobileAndTablet() ? "Back To Default" : "Undo"
                              }
                              </div>
                            )}
                          </div>
                        ) : (
                          <React.Fragment>
                            <Label>
                              <div>
                                Exclusivity Hours:
                                <InformationIcon />
                              </div>
                            </Label>
                            <input className="form-control" value="Non-exclusive" disabled={true} />
                            {((this.props.offer.exclusivity > 0 && !values.exclusive_publish) ||
                              (this.props.offer.exclusivity === 0 && values.exclusive_publish)) && (
                              <div
                                className="backToDefault"
                                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                  if (
                                    this.props.offer.exclusivity > 0 &&
                                    !values.exclusive_publish
                                  ) {
                                    setFieldValue("exclusive_publish", true);
                                  }

                                  if (
                                    this.props.offer.exclusivity === 0 &&
                                    values.exclusive_publish
                                  ) {
                                    setFieldValue("exclusive_publish", false);
                                  }

                                  if (values.duration !== this.props.offer.duration) {
                                    setFieldValue("duration", this.props.offer.duration);
                                  }
                                }}>
                                <BackToDefaultIcon />
                                                              {
                                !isMobileAndTablet() ? "Back To Default" : "Undo"
                              }
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </FormGroup>
                    </div> */}
                                    </div>

                                    {/* mobile view  */}
                                    <div className="offerForm__preview">
                                        <div className="offerForm__preview_content">
                                            <div>
                                                <ContentSelectFormik
                                                    {...this.props}
                                                    offerMode={this.props.offer.content_id === values.content}
                                                    disabled={this.props.offer.content_id !== values.content}
                                                    name="content"
                                                    value={this.props.offer.content_id}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                />
                                                {this.props.offer.content_id !== values.content ? (
                                                    <div
                                                        className="buttonLink"
                                                        onClick={() =>
                                                            setFieldValue('content', this.props.offer.content_id)
                                                        }
                                                    >
                                                        <BackToDefaultIcon />
                                                        {!isMobileAndTablet() ? 'Back To Default' : 'Undo'}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {errors.content && touched.content ? (
                                                <div className="invalidFeedback">{errors.content}</div>
                                            ) : null}

                                            {this.props.offer.content_id !== values.content && (
                                                <div className="narrowArrow">
                                                    <NarrowArrowIcon />
                                                </div>
                                            )}

                                            {this.props.offer.content_id !== values.content ? (
                                                <div>
                                                    <ContentSelectFormik
                                                        {...this.props}
                                                        offerMode={true}
                                                        disabled={false}
                                                        name="content"
                                                        value={values.content}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        );
    }
}
