import InviteWithDmTask from "./InviteWitDmTask";
import AppropveAdTask from "./ApproveAdTask";
import { ITask, TaskType } from "./AbstractTaskGenerator";
import ApproveAdTask from "./ApproveAdTask";

export default class TaskList {
  public taskList: (InviteWithDmTask | ApproveAdTask)[] = [];
  constructor(public tasks: ITask[]) {
    for (let task of this.tasks) {
      if (task.type === TaskType.invite) {
        this.taskList.push(new InviteWithDmTask(task));
      }
      if (task.type === TaskType.approve) {
        this.taskList.push(new AppropveAdTask(task));
      }
    }
  }
}
//git
