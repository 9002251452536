import React, { Fragment } from "react";
import { ButtonGroup, CardSubtitle, Col, Row, Spinner } from "reactstrap";
import { truncate } from "lodash";
import { NavLink, RouteComponentProps } from "react-router-dom";
import numbro from "numbro";
import moment from "moment";

import {
  Colxx,
  Separator,
} from "../../../template/components/common/CustomBootstrap";
import { IPublishJobModel } from "../../../store/models/PublishJobModel";
import { BreadCrumb } from "../../common-components";
import RootStore from "../../../store/RootStore";
import ContentPreview from "../content-manager/components/preview/ContentPreview";
import { viewButtonVisible } from "./components/PublishJob";
import {
  ajaxErrorAlert,
  handleError,
  numberFormat,
  numberFormatK,
  ucfirst,
} from "../../../lib/Utils";
import {
  CommentsIcon,
  LikeFollowersIcon,
  LikeIcon,
  MediaFeedIcon,
} from "../../../assets/icons/index";
import Chart from "./components/Chart";
import Analytics404 from "./components/Analytics404";

interface State {
  model: IPublishJobModel;
  likesData: any;
  commentsData: any;
  engagementData: any;
  viewsData: any;
  notFound: boolean;
}

interface Props extends RouteComponentProps<{ id: string }> {}

export default class AnalyticsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      model: null,
      likesData: null,
      commentsData: null,
      engagementData: null,
      viewsData: null,
      notFound: false,
    };
  }

  async componentDidMount(): Promise<void> {
    try {
      let model = await RootStore.publishJobStore.fetch(
        parseInt(this.props.match.params.id),
        "all",
        true
      );
      this.setState(
        {
          model,
        },
        () => {
          this.setState({
            likesData: this.getLikesData(),
            commentsData: this.getCommentsData(),
            engagementData: this.getEngagementRateData(),
            viewsData: this.getViewsData(),
          });
        }
      );
    } catch (error) {
      if (error.response.status === 404) {
        this.setState((prevState) => ({ notFound: true }));
      } else {
        ajaxErrorAlert("Could not load analytics...");
        handleError(error);
      }
    }
  }

  getLikesData() {
    let series: any[] = [
      {
        data: [],
      },
    ];
    for (let metricData of this.state.model.metrics) {
      series[0].data.push([
        moment
          .utc(metricData["created_at"])
          .add(moment().utcOffset(), "minutes")
          .toDate()
          .getTime(),
        metricData["likes"],
      ]);
    }
    return this.state.model.metrics.length ? series : null;
  }

  getCommentsData() {
    let series: any[] = [
      {
        data: [],
      },
    ];
    for (let metricData of this.state.model.metrics) {
      series[0].data.push([
        moment
          .utc(metricData["created_at"])
          .add(moment().utcOffset(), "minutes")
          .toDate()
          .getTime(),
        metricData["comments"],
      ]);
    }
    return this.state.model.metrics.length ? series : null;
  }

  getViewsData() {
    let series: any[] = [
      {
        data: [],
      },
    ];
    for (let metricData of this.state.model.metrics) {
      series[0].data.push([
        moment
          .utc(metricData["created_at"])
          .add(moment().utcOffset(), "minutes")
          .toDate()
          .getTime(),
        metricData["views"],
      ]);
    }
    return this.state.model.metrics.length ? series : null;
  }

  getEngagementRateData() {
    let series: any[] = [
      {
        data: [],
      },
    ];
    for (let metricData of this.state.model.metrics) {
      series[0].data.push([
        moment
          .utc(metricData["created_at"])
          .add(moment().utcOffset(), "minutes")
          .toDate()
          .getTime(),
        (metricData["comments"] + metricData["likes"]) /
          Math.max(this.state.model.integration_id.followers, 1),
      ]);
    }
    return series;
  }

  getEmptyState(): JSX.Element {
    return (
      <div
        style={{
          height: 350,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>No data available...</span>
      </div>
    );
  }

  render() {
    return this.state.model === null && !this.state.notFound ? (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    ) : this.state.notFound ? (
      <Analytics404 {...this.props} />
    ) : (
      <Fragment>
        <BreadCrumb
          heading={this.state.model.getContentTypeTitle() + " Analytics"}
          {...this.props}
          optionalProperties={[
            {
              id: ":id",
              title: "Deal #" + this.props.match.params.id,
              value: this.props.match.params.id,
            },
          ]}
        />
        <Separator className="mb-5" />

        <div className="analyticsPage">
          <Colxx xxs="12" className="mb-4 position-relative">
            <Row>
              <Col md="2" xl="3">
                <div className="published-content">
                  {this.state.model.offer_id ? (
                    <h2>Content & Terms</h2>
                  ) : (
                    <h2>Content</h2>
                  )}
                  Published at{" "}
                  <span>
                    {moment
                      .utc(this.state.model.start)
                      .local()
                      .format("MMMM Do YYYY, h:mm:ss a")}
                  </span>
                  <ContentPreview
                    samplePostData={{
                      comments: this.state.model.metric
                        ? this.state.model.metric.comments
                        : 0,
                      likes: this.state.model.metric
                        ? this.state.model.metric.likes
                        : 0,
                      profilePicture:
                        this.state.model.integration_id.getThumbnail(40, 40),
                      name: this.state.model.integration_id.full_name,
                    }}
                    model={this.state.model.content_id}
                  />
                </div>
              </Col>

              <Col md="10" xl="9">
                <div className="analyticsPage__igInfo">
                  <div>
                    <NavLink
                      to={this.state.model.integration_id.getInstagramUrl()}
                    >
                      {this.state.model.integration_id.picture && (
                        <img
                          src={this.state.model.integration_id.picture.getThumbnailUrl(
                            64,
                            64
                          )}
                          alt={
                            this.state.model.integration_id.full_name ??
                            "No name"
                          }
                        />
                      )}
                    </NavLink>
                    <div>
                      <a
                        href={this.state.model.integration_id.getInstagramUrl()}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        <CardSubtitle className="truncate">
                          {this.state.model.integration_id.full_name ??
                            "No name"}{" "}
                          (@
                          {truncate(this.state.model.integration_id.username, {
                            length: 20,
                          })}
                          )
                        </CardSubtitle>
                      </a>
                      <p className="text-muted text-small mb-2">
                        {this.state.model.integration_id.description
                          ? truncate(
                              this.state.model.integration_id.description,
                              { length: 30 }
                            )
                          : "No bio description"}
                      </p>
                    </div>
                  </div>

                  <div>
                    <h2>
                      {numberFormatK(
                        this.state.model.integration_id.metric.followers
                      )}
                      <span>Followers</span>
                    </h2>
                    <h2>
                      {numberFormatK(
                        this.state.model.integration_id.followings
                      )}
                      <span>Following</span>
                    </h2>
                    <h2>
                      {this.state.model.integration_id.metric.medias}
                      <span>Posts</span>
                    </h2>
                  </div>

                  <div>
                    <ButtonGroup>
                      <a
                        className={"btn btn-outline-secondary btn-sm"}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        href={this.state.model.integration_id.getInstagramUrl()}
                      >
                        View IG
                      </a>

                      {viewButtonVisible(this.state.model.getView()) ? (
                        <>
                          <a
                            className={"btn btn-outline-secondary btn-sm"}
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                            href={this.state.model.getView().url}
                          >
                            View {ucfirst(this.state.model.getView().mediaType)}
                          </a>
                        </>
                      ) : null}
                    </ButtonGroup>
                  </div>
                </div>

                <div className="kpiList_4col">
                  {this.state.model.content_id.type === "post" && (
                    <>
                      <div className="dashboardWidget">
                        <div className="dashboardWidget__header">
                          <LikeIcon />
                          <div>
                            <span>
                              {this.state.model.metric
                                ? numberFormat(this.state.model.metric.likes)
                                : "?"}
                            </span>
                            <p>Likes</p>
                          </div>
                        </div>
                      </div>
                      <div className="dashboardWidget">
                        <div className="dashboardWidget__header">
                          <CommentsIcon />
                          <div>
                            <span>
                              {this.state.model.metric
                                ? numberFormat(this.state.model.metric.comments)
                                : "?"}
                            </span>
                            <p>Comments</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {this.state.model.content_id.type === "story" && (
                    <>
                      <div className="dashboardWidget">
                        <div className="dashboardWidget__header">
                          <MediaFeedIcon />
                          <div>
                            <span>
                              {this.state.model.metric
                                ? numberFormat(this.state.model.metric.views)
                                : "?"}
                            </span>
                            <p>Views</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="dashboardWidget">
                    <div className="dashboardWidget__header">
                      <LikeFollowersIcon />
                      <div>
                        <span>
                          {numbro(
                            this.state.model.calculateEngagementRate()
                          ).format({
                            output: "percent",
                            spaceSeparated: false,
                            optionalMantissa: true,
                            average: true,
                            mantissa: 2,
                          })}
                        </span>
                        <p>Engagement rate</p>
                      </div>
                    </div>
                  </div>
                  <div className="dashboardWidget">
                    <div className="dashboardWidget__header">
                      <MediaFeedIcon />
                      <div>
                        <span>?</span>
                        <p>Bio Link Clicks</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="analyticsPage__charts">
                  {this.state.model.content_id.type === "post" && (
                    <>
                      <div className="analyticsPage__charts_chart">
                        <div>
                          <h2>Likes</h2>
                          {this.state.likesData ? (
                            <Chart
                              id={"post-likes"}
                              series={this.state.likesData}
                              minTimeStamp={moment(this.state.model.start)
                                .toDate()
                                .getTime()}
                            />
                          ) : (
                            this.getEmptyState()
                          )}
                        </div>
                      </div>
                      <div className="analyticsPage__charts_chart">
                        <div>
                          <h2>Comments</h2>
                          {this.state.commentsData ? (
                            <Chart
                              id={"post-comments"}
                              series={this.state.commentsData}
                              minTimeStamp={moment(this.state.model.start)
                                .toDate()
                                .getTime()}
                            />
                          ) : (
                            this.getEmptyState()
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {this.state.model.content_id.type === "story" && (
                    <>
                      <div className="analyticsPage__charts_chart">
                        <div>
                          <h2>Views</h2>
                          {this.state.viewsData ? (
                            <Chart
                              id={"post-likes"}
                              series={this.state.viewsData}
                              minTimeStamp={moment(this.state.model.start)
                                .toDate()
                                .getTime()}
                            />
                          ) : (
                            this.getEmptyState()
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="analyticsPage__charts_chart">
                    <div>
                      <h2>Engagement Rate %</h2>
                      {this.state.engagementData ? (
                        <Chart
                          id={"post-engagement"}
                          series={this.state.engagementData}
                          minTimeStamp={moment(this.state.model.start)
                            .toDate()
                            .getTime()}
                        />
                      ) : (
                        this.getEmptyState()
                      )}
                    </div>
                  </div>
                  <div className="analyticsPage__charts_chart">
                    <div>
                      <h2>Bio Link Clicks</h2>
                      {this.getEmptyState()}
                      {/*<Chart id={'post-clicks'} series={series} minTimeStamp={new Date('01 Mar 2012').getTime()}/>*/}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Colxx>
        </div>
      </Fragment>
    );
  }
}
