import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { SendIcon, ChatImage } from '../../../../assets/icons';

interface Props {
    placeholder: string;
    handleSendButtonClick: Function;
    handleSendMediaClick: Function;
}

interface State {
    value: string;
}

class SaySomething extends Component<Props, State> {
    private textRef: React.RefObject<HTMLTextAreaElement>;
    private charLeftRef: React.RefObject<HTMLTextAreaElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            value: '',
        };
        this.fileUploadHandle = this.fileUploadHandle.bind(this);
        this.textRef = React.createRef()
        this.charLeftRef = React.createRef()
    }

    async fileUploadHandle(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files[0];

        this.props.handleSendMediaClick(file);
    }

    calcHeight(value) {
        let numberOfLineBreaks = (value?.match(/\n/g) || []).length;
        const br = numberOfLineBreaks * 20
        const char = Math.floor(value?.length/120)*20
        // min-height + lines x line-height + padding + border
        let newHeight = 20 + (br > char ? br :char)  + 12 + 2 
        return newHeight;
      }

    textAreaKeyUp = () => {
        this.textRef.current.style.height = this.calcHeight(this.textRef?.current?.value) + "px";
    }
    textAreaOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {value} = e.target
        this.setState({
            value,
        })
    }
    render() {
        const { placeholder, handleSendButtonClick } = this.props;

        return (
            <div
                className="chat-input-container d-flex justify-content-between align-items-center"
                style={{ height: 'auto' }}
            >
                <textarea 
                    ref={this.textRef}
                    className="say-somthing-textarea form-control chat-control-input flex-grow-1 mb-3 mt-3"
                    placeholder={placeholder}
                    value={this.state.value}
                    onKeyUp={this.textAreaKeyUp}
                    onChange={this.textAreaOnChange}
                    rows={1}
                ></textarea>
                <div className="chat-action-btns">
                    {/*<Button outline color="primary" className="icon-button large ml-1" title={'Set Content'}>*/}
                    {/*  <i className="simple-icon-paper-clip" />*/}
                    {/*</Button>*/}

                    {/*  <Button outline color="primary" className="icon-button large ml-1" title={'Set Price'}>*/}
                    {/*      <i className="simple-icon-wallet" />*/}
                    {/*  </Button>*/}

                    {/*  <Button outline color="primary" className="icon-button large ml-1" title={'Set Schedule'}>*/}
                    {/*      <i className="simple-icon-calendar" />*/}
                    {/*  </Button>*/}

                    <label className="icon-button large ml-1 file-upload-btn">
                        <ChatImage />
                        <input
                            accept="image/jpg, image/gif, image/png, video/mp4, video/mkv"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.fileUploadHandle(e)}
                            type="file"
                        />
                    </label>
                    <Button
                        color="#103c64"
                        className="icon-button large ml-1 d-flex align-items-center justify-content-center"
                        onClick={() => {
                            handleSendButtonClick(this.state.value);
                            this.setState({
                                value: '',
                            });
                        }}
                    >
                        <SendIcon />
                    </Button>
                </div>
            </div>
        );
    }
}
export default SaySomething;
