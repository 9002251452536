import React, { Component, GetDerivedStateFromProps } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Row, FormGroup, Label, Button, CustomInput } from 'reactstrap';
import { Field, Form } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { FormikDuration, FormikReactSelect, CampaignForm } from '../../../common-components';
import { AdPreview } from '../../../common-components/AdPreview2';
import ContentSelectFormik from '../../scheduler/components/ContentSelectFormik';
import ScheduleSelectFormik from '../components/ScheduleSelectFormik';
import NotificationManager from '../../../../template/components/common/react-notifications/NotificationManager';
import ApplicationMenu from '../../../../template/components/common/ApplicationMenu';
import RootStore from '../../../../store/RootStore';
import {
    showLoading,
    handleError,
    ajaxErrorAlert,
    makeTempModelId,
    momentDateTimeFormat,
    notURL,
} from '../../../../lib/Utils';
import { IAdModel } from '../../../../store/models/AdModel';
import { PlusIcon } from '../../../../assets/icons';
import { ICampaignModel } from '../../../../store/models/CampaignModel';
import { InformationIcon } from '../../../../assets/icons';
import InviteInfluencersPopup from '../../../common-components/InviteInfluencersPopup';
import Picker ,{SKIN_TONE_MEDIUM_LIGHT} from 'emoji-picker-react';
import FormikWithRef from '../../../common-components/FormikWithRef'
import $ from "jquery";


const AdSchema = Yup.object().shape({
    campaign_id: Yup.string().required('You need to select or create a campaign'),
    title: Yup.string().required('Ad title is required!').matches(notURL, "You can't add URL as an Ad title"),

    content_id: Yup.mixed().required('You must set the Ad creative'),
    description: Yup.string()
        .required('Ad description is required!')
        .min(3, 'Description must be at least 3 characters long')
        .max(3000, 'Description must be at most 3000 characters'),
    fixedDuration: Yup.number().required('You must select Ad duration config'),
    duration: Yup.number()
        .typeError("Ad duration field can't be empty!")
        .nullable()
        .min(3600, 'An Ad can not get published for less than 24 hour'),
    bio_link: Yup.string().url('Please enter a valid URL').nullable(),
    // exclusivity: Yup.number()
    //   .typeError(
    //     "This field can't be empty, unless you want a fully exclusive Ad, then select the above radio button!"
    //   )
    //   .nullable()
    //   .notRequired()
    //   .min(0, "If you want a fully exclusive Ad, select the above radio button!"),
});

interface Props extends RouteComponentProps {
    model?: IAdModel;
    mode: 'edit' | 'create';
}

interface State {
    displayCreateCampaign: boolean;
    adModel?: IAdModel;
    displayHashtagsSearchForm: boolean;
    displayMenu: boolean;
    showHashtagModal: boolean;
    hashtags: [];
    setShow: boolean;
    setEmoji: string;
    schedule_list?: Object[],
    // new_content?:any
}

export default class AdForm extends Component<Props, State> {
    private formik: any | null = null

    constructor(props: any) {
        super(props);

        this.state = {
            displayCreateCampaign: false,
            adModel: null,
            displayHashtagsSearchForm: false,
            displayMenu: false,
            showHashtagModal: false,
            hashtags: [],
            setShow: false,
            setEmoji: this.props?.model?.description ? this.props.model.description : '',
            schedule_list : [],
            // new_content:{}
        };

        this.createCampaign = this.createCampaign.bind(this);
        this.getInitialValues = this.getInitialValues.bind(this);
        // this.handleClickAdd = this.handleClickAdd.bind(this);
        this.formik = React.createRef()
    }

    static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
        if (props.mode === 'edit') {
            return {
                adModel: props.model,
            };
        }
        return state;
    };

    async createCampaign(values: { title: string }) {
        showLoading('Loading...', 'Please wait while we creating your campaign...');
        try {
            let campaign = (await RootStore.campaignStore.create({
                id: makeTempModelId(),
                title: values?.title,
                description: '',
                budget: 0,
                state: 'unstarted',
            })) as ICampaignModel;
            RootStore.users.currentUser.increaseCampaignsCount();

            Swal.close();

            return {
                label: campaign.title,
                value: campaign.id,
            };
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem creating the campaign...');
            throw e;
        }
    }

    getInitialValues() {
        if (this.state.adModel) {
            let data: any = { ...this.state.adModel };
            data.content_id = this.state.adModel.content_id;

            if (data.bio_link) {
                data.displayBioLink = true;
            }

            data.schedule = [];
            for (let timeTable of this.props.model.time_tables) {
                data.schedule.push({
                    start: timeTable.start,
                    end: timeTable.end,
                    id: timeTable.id,
                });
            }

            if (this.props.model.time_tables.length) {
                data.fixedSchedule = 1;
            }

            if (data.duration === null) {
                data.fixedDuration = 0;
            } else if (data.duration === 0) {
                data.fixedDuration = 1;
                data.duration = null;
            } else {
                data.fixedDuration = 2;
            }

            // if (data.exclusivity === 0) {
            //   data.exclusivityTolerance = 0;
            // } else {
            //   data.exclusivityTolerance = 1;
            // }

            data.campaign_id = {
                label: this.props.model.campaign_id.title,
                value: this.props.model.campaign_id.id,
            };

            return data;
        } else {
            return {
                campaign_id: {
                    label: RootStore.campaignStore.getExistingCampaigns()[0].title,
                    value: RootStore.campaignStore.getExistingCampaigns()[0].id,
                },
                title: '',
                description: '',
                content_id: null,
                schedule: null,
                fixedSchedule: 0,
                fixedDuration: 0,
                duration: null,
                // exclusivity: 0,
                displayBioLink: false,
                bio_link: '',
                // exclusivityTolerance: 0,
            };
        }
    }

    toggleHashtagModal = () => {
        this.setState({ showHashtagModal: !this.state.showHashtagModal });
    };

    // handleClickAdd = (n, e) => {
    //     if (n.target.value) this.setState({ setEmoji: e.target.value });

    //     if (e.emoji)
    //         this.setState({
    //             setEmoji: this.state.setEmoji + e.emoji,
    //         });
    // };

    addEmoji = (event, emojiObject) => {
        if (emojiObject.emoji) {
            // will give the current position of the cursor
            const curorPos = $('#description').prop('selectionStart');

            // will get the value of the text area
            let inputVal = $('#description').val().toString();
            const finalVal = inputVal.slice(0, curorPos) + emojiObject.emoji + inputVal.slice(curorPos)

            // setting the updated value in the text area
            this.formik?.current?.setFieldValue('description', finalVal);
            this.setState({setShow:false,setEmoji:finalVal})
        }
    };

    render() {
        return (
            <>
                <InviteInfluencersPopup
                    {...this.props}
                    isOpen={this.state.showHashtagModal}
                    toggle={this.toggleHashtagModal}
                    hashtags={this.state.hashtags}
                />
                <FormikWithRef
                    ref={this.formik}
                    initialValues={this.getInitialValues()}
                    validationSchema={AdSchema}
                    onSubmit={async (formValues, actions) => {
                        showLoading(
                            'Loading...',
                            `Please wait while we update your Ad...`,
                        );

                        try {
                            let values = formValues;
                            let hashtagList = values?.content_id?.data?.caption
                                ? values?.content_id?.data?.caption.match(/#\w+/g)
                                : [];
                            if (hashtagList && hashtagList.length > 0) hashtagList = hashtagList.map((h) => h.slice(1));
                            this.setState({ hashtags: hashtagList || [] });
                            values.exclusivity = 0;
                            values.invite_only = 0;

                            if (values.schedule || values.time_tables) {
                                for (let schedule_item of values.schedule) {
                                    schedule_item.start = moment(schedule_item?.start).format(momentDateTimeFormat);
                                    if (schedule_item?.end) {
                                        schedule_item.end = moment(schedule_item?.end).format(momentDateTimeFormat);
                                    } else {
                                        schedule_item.end = schedule_item?.start;
                                    }
                                }
                            }

                            values.campaign_id = formValues?.campaign_id?.value;
                            values.content_id = values?.content_id?.id;
                            
                            // values.content = this.state?.new_content
                            // values.register_step = true

                            if (values.bio_link && !values.bio_link.includes('http')) {
                                values.bio_link = `http://${values.bio_link}`;
                            }

                            if (this.state?.setEmoji !== values?.description) {
                                values.description = this.state?.setEmoji;
                            }

                            // if (values.exclusivityTolerance === 0) {
                            //   values.exclusivity = 0;
                            // }

                            // if (values.exclusivity < 3600 && values.exclusivityTolerance !== 0) {
                            //   values.exclusivity = 3600;
                            // }

                            if (values?.approval === 'rejected') values.approval = 'pending';

                            if (values?.fixedDuration === 1) values.duration = 0;

                            if (this.state.adModel) {
                                await RootStore.adStore.update(this.state?.adModel?.id, values);

                                Swal.close();
                                NotificationManager.success(
                                    'Done',
                                    'The ad was successfully updated!',
                                    3000,
                                    null,
                                    null,
                                    'filled',
                                );

                                //Show suggested hashtag popup
                                if (this.props.model.approval !== 'pending') this.toggleHashtagModal();
                                else this.props.history.push(`/app/ads`);
                            } 
                        } catch (e) {
                            handleError(e);
                            ajaxErrorAlert(`Could not update the Ad!`);
                        }
                        this.formik?.current?.setFieldValue('schedule', this.state?.schedule_list);
                    }}
                >
                    {({
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        setValues,
                        setErrors,
                        setFieldError,
                    }) => (
                        <Form translate={'no'} className="av-tooltip tooltip-label-right panel-form">
                            <CampaignForm
                                onClose={() =>
                                    this.setState((prevState) => ({
                                        displayCreateCampaign: false,
                                    }))
                                }
                                isOpen={this.state.displayCreateCampaign}
                                onCreateCampaign={async (campaignTitle) => {
                                    let campaign = await this.createCampaign(campaignTitle);

                                    setFieldValue('campaign_id', campaign);
                                }}
                            />
                            <div className="app-row">
                                <div className="adForm">
                                    <div>
                                        <FormGroup className="error-l-100">
                                            <Label htmlFor="adTitle" className="position-relative">
                                                Provide a title for your Ad:
                                                {errors.title && touched.title && (
                                                    <div className="invalidFeedback">{errors.title}</div>
                                                )}
                                                <InformationIcon data-tip data-for="AdTitle" />
                                                <ReactTooltip id="AdTitle" type="dark" effect="solid">
                                                    <p>
                                                        This is the title that will appear in the Ad Marketplace. e.g.
                                                        Fall Shoe Promotion
                                                    </p>
                                                </ReactTooltip>
                                            </Label>
                                            <Field
                                                className="form-control"
                                                name="title"
                                                id="adTitle"
                                                placeholder="Example: Ainfluencer"
                                                onChange={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                    setFieldValue('title', e.currentTarget.value);
                                                }}
                                            />
                                        </FormGroup>

                                        <FormGroup className="error-l-100">
                                            <Label htmlFor="description" className="position-relative">
                                                Describe the Ad or promotion:
                                                {errors.description && touched.description && (
                                                    <div className="invalidFeedback">{errors.description}</div>
                                                )}
                                                <InformationIcon data-tip data-for="AdDescription" />
                                                <ReactTooltip id="AdDescription" type="dark" effect="solid">
                                                    <p>
                                                        Short description explaining the promotion and why influencers
                                                        should join. e.g. Promote our latest shoe lineup and earn great
                                                        income this fall.
                                                    </p>
                                                </ReactTooltip>
                                            </Label>
                                            <div className="adForm__adDescription">
                                                <Field
                                                    className="form-control"
                                                    name="description"
                                                    id="description"
                                                    value={values?.description}
                                                    component="textarea"
                                                    contentEditable="true"
                                                    suppressContentEditableWarning={true}
                                                    style={{ height: 160 }}
                                                    placeholder="Example: What does your business do?"
                                                    onChange={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                        const value = e.currentTarget.value;
                                                        setFieldValue('description', value);
                                                        this.setState({ setEmoji: value });
                                                    }}
                                                    onFocus={() => {
                                                        this.setState({
                                                            setShow: this.state.setShow ? false : null,
                                                        });
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '0.5em',
                                                        right: '12px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                        fontSize: '18px',
                                                    }}
                                                >
                                                    <span
                                                        onClick={() => {
                                                            this.setState({ setShow: !this.state.setShow });
                                                        }}
                                                        className={'cursor-pointer simple-icon-emotsmile'}
                                                    ></span>

                                                    {this.state.setShow ? (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                bottom: '20px',
                                                                right: '0px',
                                                                maxWidth: '150px',
                                                                zIndex: 2,
                                                            }}
                                                        >
                                                            <Picker 
                                                                onEmojiClick={this.addEmoji} 
                                                                skinTone={SKIN_TONE_MEDIUM_LIGHT}
                                                                disableSkinTonePicker={false}
                                                                native
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* <Button
                        outline
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          event.preventDefault();
                          this.setState((prevState) => ({ displayHashtagsSearchForm: true }));
                        }}>
                        <HashtagIcon />
                        Search Hashtags
                      </Button> */}
                                            </div>
                                        </FormGroup>

                                        <Row className="pl-3">
                                            <FormGroup className="error-l-100">
                                                <Label className="position-relative">
                                                    Add a sample creative:
                                                    {errors.content_id && touched.content_id && (
                                                        <div className="invalidFeedback">{errors.content_id}</div>
                                                    )}
                                                    <InformationIcon
                                                        style={{ top: '0.45em' }}
                                                        data-tip
                                                        data-for="AdContent"
                                                    />
                                                    <ReactTooltip id="AdContent" type="dark" effect="solid">
                                                        <p>
                                                            Upload a feed or story post creative that you wish to have
                                                            promoted. This can be replaced later if you want an
                                                            influencer to create something new.
                                                        </p>
                                                    </ReactTooltip>
                                                </Label>

                                                <ContentSelectFormik
                                                    {...this.props}
                                                    name="content_id"
                                                    onBoardingMode={true}
                                                    value={values?.content_id}
                                                    disableLibrary={false}
                                                    offerMode={false}
                                                    onBlur={setFieldTouched}
                                                    onChange={(name: any, value: any) => {
                                                        setFieldValue('content_id', value);
                                                        // this.setState({'new_content':value})
                                                    }}
                                                    postData={values}
                                                />
                                            </FormGroup>
                                        </Row>

                                        <FormGroup className="error-l-100">
                                            <Label for="exCustomRadio4" className="mb-3 position-relative">
                                                What date would you like your Ad published on?
                                                <InformationIcon data-tip data-for="AdDate" className="specificLabel" />
                                                <ReactTooltip id="AdDate" type="dark" effect="solid">
                                                    <p>
                                                        Suggest a specific date and time if desired. This can be further
                                                        edited during the offer process.
                                                    </p>
                                                </ReactTooltip>
                                            </Label>
                                            <div>
                                                <CustomInput
                                                    type="radio"
                                                    id="exCustomRadio4"
                                                    name="fixedSchedule"
                                                    label="I don't have any limitations on when influencers can post it (open for negotiation)"
                                                    value={0}
                                                    checked={!values?.fixedSchedule}
                                                    onChange={(e) => {
                                                        e.target.checked = !e.target.checked;
                                                        setFieldValue('fixedSchedule', false);
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                                <CustomInput
                                                    type="radio"
                                                    id="exCustomRadio5"
                                                    name="fixedSchedule"
                                                    label="I want to set a specific date range for when the post is published"
                                                    value={1}
                                                    // @ts-ignore
                                                    checked={values?.fixedSchedule}
                                                    onChange={(e) => {
                                                        // this.props.onSetAdPreviewValues("fixedSchedule", 86400);
                                                        setFieldValue('fixedSchedule', true);
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </FormGroup>
                                        {values?.fixedSchedule ? (
                                            <FormGroup className="error-l-100 d-flex flex-column">
                                                <Label className="position-relative">
                                                    Set a date range of when this Ad can be published:{' '}
                                                    {errors.schedule && touched.schedule ? (
                                                        <div className="invalidFeedback">{errors.schedule}</div>
                                                    ) : null}
                                                </Label>
                                                <ScheduleSelectFormik
                                                    onChange={(name: string, value: any) => {
                                                        console.log('SCHEDULE : ',name,value)
                                                        setFieldValue(name, value);
                                                        this.setState({schedule_list:value})
                                                    }}
                                                    name="schedule"
                                                    value={values?.schedule}
                                                />
                                            </FormGroup>
                                        ) : null}

                                        <FormGroup className="error-l-100">
                                            <Label for="exCustomRadio" className="mb-3 position-relative">
                                                How long should this post remain on the Influencer’s Instagram page?
                                                {errors.fixedDuration && touched.fixedDuration && (
                                                    <div className="invalidFeedback">{errors.fixedDuration}</div>
                                                )}
                                                <InformationIcon data-tip data-for="AdDuration" />
                                                <ReactTooltip id="AdDuration" type="dark" effect="solid">
                                                    <p>
                                                        Suggest a specific duration that the Ad will remain on the
                                                        influencer’s page. Typically Ads are removed after 1 or 2 days.
                                                    </p>
                                                </ReactTooltip>
                                            </Label>
                                            <div>
                                                <CustomInput
                                                    type="radio"
                                                    id="exCustomRadio"
                                                    name="fixedDuration"
                                                    label="I will negotiate ad duration for each offer"
                                                    value={0}
                                                    checked={values?.fixedDuration === 0}
                                                    onChange={(e) => {
                                                        setFieldValue('fixedDuration', 0);
                                                        setFieldValue('duration', null);
                                                    }}
                                                />

                                                <CustomInput
                                                    type="radio"
                                                    id="exCustomRadio2"
                                                    name="fixedDuration"
                                                    label="I want this post to be permanent"
                                                    value={1}
                                                    checked={values?.fixedDuration === 1}
                                                    onChange={(e) => {
                                                        setFieldValue('fixedDuration', 1);
                                                    }}
                                                />

                                                <CustomInput
                                                    type="radio"
                                                    id="exCustomRadio3"
                                                    name="fixedDuration"
                                                    label="I want to set a specific duration for this content"
                                                    value={2}
                                                    checked={values?.fixedDuration === 2}
                                                    onChange={(e) => {
                                                        setFieldValue('fixedDuration', 2);
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </FormGroup>

                                        {values?.fixedDuration === 2 ? (
                                            <div className="formikDuration">
                                                <FormGroup className="error-l-100 mb-0">
                                                    <Label className="position-relative">
                                                        Ad duration:
                                                        {errors.duration && touched.duration && (
                                                            <div className="invalidFeedback">{errors.duration}</div>
                                                        )}
                                                    </Label>
                                                    <FormikDuration
                                                        className="ml-0"
                                                        name="duration"
                                                        value={values?.duration >= 86400 ? values?.duration : 86400}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                    />
                                                </FormGroup>
                                            </div>
                                        ) : null}

                                        <FormGroup className="error-l-100">
                                            <Label htmlFor="displayBioLinkField" className="mb-3 position-relative">
                                                Do you want to add a link in the influencer's bio after publishing your
                                                Ad?
                                                {errors.bio_link && touched.bio_link && (
                                                    <div className="invalidFeedback">{errors.bio_link}</div>
                                                )}
                                                <InformationIcon data-tip data-for="LinkInBio" />
                                                <ReactTooltip id="LinkInBio" type="dark" effect="solid">
                                                    <p>
                                                        Select YES if you wish to negotiate placing a promotional link
                                                        in an influencer’s profile page. The link will be placed for the
                                                        duration period below.
                                                    </p>
                                                </ReactTooltip>
                                            </Label>
                                            <div>
                                                <CustomInput
                                                    type="radio"
                                                    id="displayBioLinkField1"
                                                    name="displayBioLink"
                                                    label="No"
                                                    value={0}
                                                    checked={!values?.displayBioLink}
                                                    onChange={(e) => {
                                                        e.target.checked = !e.target.checked;
                                                        setFieldValue('displayBioLink', false);
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                                <CustomInput
                                                    type="radio"
                                                    id="displayBioLinkField2"
                                                    name="displayBioLink"
                                                    label="Yes"
                                                    value={1}
                                                    // @ts-ignore
                                                    checked={values?.displayBioLink}
                                                    onChange={(e: any) => {
                                                        setFieldValue('displayBioLink', true);
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {values?.displayBioLink && (
                                                <Field
                                                    className="form-control"
                                                    name="bio_link"
                                                    placeholder="https://example.com"
                                                    onChange={(e: any) => {
                                                        setFieldValue('bio_link', e.target.value);
                                                    }}
                                                />
                                            )}
                                        </FormGroup>
                                    </div>

                                    <FormGroup className="error-l-100 adForm__createCampaign">
                                        <Label htmlFor="campaign_id" className="position-relative">
                                            <b>Choose your campaign:</b>
                                            {errors.campaign_id && touched.campaign_id && (
                                                <div className="invalidFeedback">{errors.campaign_id}</div>
                                            )}
                                        </Label>
                                        <div>
                                            <FormikReactSelect
                                                formatGroupLabel={(data: any) => (
                                                    <div>
                                                        <h4>{data.label}</h4>
                                                    </div>
                                                )}
                                                name="campaign_id"
                                                id="campaign_id"
                                                value={values?.campaign_id}
                                                options={RootStore.campaignStore
                                                    .getExistingCampaigns()
                                                    .map((campaign: ICampaignModel) => {
                                                        return {
                                                            label: campaign.title,
                                                            value: campaign.id,
                                                        };
                                                    })}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                            />
                                            <Button
                                                color="link"
                                                onClick={(event: React.MouseEvent) =>
                                                    this.setState((prevState) => ({
                                                        displayCreateCampaign: true,
                                                    }))
                                                }
                                            >
                                                <PlusIcon />
                                                Create New Campaign
                                            </Button>
                                        </div>
                                    </FormGroup>

                                    <Button color="success" type="submit">
                                        {!this.state.adModel ? 'Create Ad' : 'Save Ad'}
                                    </Button>

                                    <div className="mt-2">
                                        {errors.title && touched.title && (
                                            <p className="text-danger mb-0">
                                                <i>*{errors.title}</i>
                                            </p>
                                        )}
                                        {errors.description && touched.description && (
                                            <p className="text-danger mb-0">
                                                <i>*{errors.description}</i>
                                            </p>
                                        )}
                                        {errors.content_id && touched.content_id && (
                                            <p className="text-danger mb-0">
                                                <i>*{errors.content_id}</i>
                                            </p>
                                        )}
                                    </div>

                                    <ApplicationMenu
                                        isOpen={this.state.displayMenu}
                                        toggleOpenHandler={() =>
                                            this.setState((prevState) => ({
                                                ...prevState,
                                                displayMenu: !prevState.displayMenu,
                                            }))
                                        }
                                    >
                                        <div className="adForm_adPreview">
                                            <AdPreview values={values} />
                                        </div>
                                    </ApplicationMenu>
                                </div>
                            </div>
                        </Form>
                    )}
                </FormikWithRef>
            </>
        );
    }
}


/* <FormGroup className="error-l-100">
                    <Label for="exCustomRadio" className="position-relative">
                      Do you mind if an influencer posts other Ads?
                      {errors.exclusivityTolerance && touched.exclusivityTolerance && (
                        <div className="invalidFeedback">{errors.exclusivityTolerance}</div>
                      )}
                      <InformationIcon data-tip data-for="AdExclusivity" />
                      <ReactTooltip id="AdExclusivity" type="dark" effect="solid">
                        <p>
                          Most influencers will not post multiple Ads at once. Specify your
                          preference here.
                        </p>
                      </ReactTooltip>
                    </Label>
                    <div>
                      <CustomInput
                        type="radio"
                        id="exCustomRadio6"
                        name="exclusivityTolerance"
                        label="I don't have any limitations on how many posts/stories influencers publish besides my Ad (open for negotiation)"
                        value={0}
                        checked={values.exclusivityTolerance === 0}
                        onChange={(e) => {
                          setFieldValue("exclusivityTolerance", 0);
                          setFieldValue("exclusivity", 0);
                        }}
                        onBlur={handleBlur}
                      />

                      <CustomInput
                        type="radio"
                        id="exCustomRadio8"
                        name="exclusivityTolerance"
                        label="I want this Ad to be exclusive for a specific amount of hours after it's publish date"
                        value={1}
                        checked={values.exclusivityTolerance !== 0}
                        onChange={(e) => {
                          setFieldValue("exclusivityTolerance", 1);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  </FormGroup> */

                                        /* {values.exclusivityTolerance > 0 ? (
                    <FormGroup className="error-l-100">
                      <Label className="position-relative">
                        How long should this Ad remain exclusive on the influencer’s Instagram page?
                        {errors.exclusivity && touched.exclusivity && (
                          <div className="invalidFeedback">{errors.exclusivity}</div>
                        )}
                      </Label>
                      <div className="formikDuration">
                        <FormikDuration
                          name="exclusivity"
                          className="ml-0"
                          onChange={(name: string, value: any) => {
                            if (values.duration && values.duration < value) {
                              setFieldError(
                                "exclusivity",
                                "Exclusivity period can not be bigger than duration"
                              );

                              setErrors({
                                exclusivity: "Exclusivity period can not be bigger than duration",
                              });
                            } else {
                              setFieldValue(name, value);
                            }
                          }}
                          onBlur={setFieldTouched}
                          value={values.exclusivity >= 3600 ? values.exclusivity : 3600}
                        />
                      </div>
                    </FormGroup>
                  ) : null} */
