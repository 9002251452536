import React, { Component, Fragment } from "react";

import { Colxx } from "../../../../template/components/common/CustomBootstrap";
import { Row } from "reactstrap";
import { BreadCrumb } from "../../../common-components";
import { RouteComponentProps } from "react-router-dom";
import Transport from "../../../../lib/Transport";
import { AxiosResponse } from "axios";
import { ITask } from "./AbstractTaskGenerator";
import TaskListComponent from "./TaskListComponent";
import { Spinner } from "reactstrap";
import ApiQueryBuilder from "../../../../lib/ApiQueryBuilder";
import { FilterGroup, SortOrder } from "../../../../lib/QueryTypes";
import ServerSideFilterSet from "../../../common-components/Filter/ServerSideFilterSet";
import { debounce } from "lodash";
import { Filters } from "../../users-managment/components/Users";
import {
  FilterType,
  Range,
} from "../../../common-components/Filter/AbstractFilterSet";
import FilterComponent from "../../../common-components/Filter/FilterComponent";

interface State {
  tasks: ITask[] | null;
  datafetched: boolean;
  filter_groups: FilterGroup[];
  sort: { key: string; direction: SortOrder };
}

interface Props extends RouteComponentProps {}

export default class TaskManager extends Component<Props, State> {
  private readonly filterSet: ServerSideFilterSet;
  constructor(props: Props) {
    super(props);
    this.state = {
      tasks: null,
      datafetched: false,
      sort: {
        key: "created_at",
        direction: SortOrder.DESCENDING,
      },
      filter_groups: [
        {
          isOr: false,
          filters: [
            {
              key: "completed_at",
              value: "null",
              operator: null,
              not: false,
            },
          ],
        },
      ],
    };
    this.filterSet = new ServerSideFilterSet(debounce(this.onChange, 600));
    this.filterSet.addFilter({
      name: "created_at",
      title: "Create Date",
      type: FilterType.simpleFilter,
      defaultValue: this.state.sort
        ? this.state.sort.direction
        : SortOrder.DESCENDING,
      values: () => [SortOrder.ASCENDING, SortOrder.DESCENDING],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === SortOrder.ASCENDING) {
          return "Ascending";
        } else if (value === SortOrder.DESCENDING) {
          return "Descending";
        }
      },
    });
    this.filterSet.addFilter({
      name: "task_type",
      title: "Task Type",
      type: FilterType.simpleFilter,
      defaultValue: null,
      values: () => ["invitation_with_no_email", "ad_approval", null],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === "ad_approval") {
          return "Ad approval tasks";
        } else if (value === "invitation_with_no_email") {
          return "Invite With DM tasks";
        } else {
          return "All";
        }
      },
    });
  }

  async componentDidMount(): Promise<any> {
    this.fetchTasks();
  }

  setFilters(filters: Filters) {
    let filterGroup = [];
    filterGroup.push({
      isOr: false,
      filters: [
        {
          key: "completed_at",
          value: "null",
          operator: null,
          not: false,
        },
      ],
    });
    if (filters.filters.task_type) {
      filterGroup.push({
        isOr: false,
        filters: [
          {
            key: "type",
            value: filters.filters.task_type,
            operator: null,
            not: filters.filters.email_verified_at === "Active" ? true : false,
          },
        ],
      });
    }
    this.setState({
      filter_groups: filterGroup,
      sort: {
        key: "created_at",
        direction: filters.filters.created_at,
      },
    });
  }
  async fetchTasks() {
    let response: AxiosResponse<any> = await Transport.get(
      "tasks",
      this.queyBuilder(this.state.filter_groups, this.state.sort)
    );

    this.setState({ tasks: response.data.tasks, datafetched: true });
  }

  async onFilter(filters: Filters): Promise<void> {
    this.setFilters(filters);
    this.fetchTasks();
  }

  onChange = (filters: Filters): void => {
    this.onFilter(filters);
  };

  queyBuilder(
    filterGroups: FilterGroup[] = [],
    sort: { key: string; direction: SortOrder }
  ): ApiQueryBuilder {
    let query = new ApiQueryBuilder();
    if (filterGroups.length !== 0) {
      query.addFilterGroupList(filterGroups);
    }
    if (sort) {
      query.addSort(sort.key, sort.direction);
    }
    query.setPagination(100, 0);
    return query;
  }
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadCrumb heading="Task manager" {...this.props} />
            {/* <Separator className="mb-5" /> */}
          </Colxx>
        </Row>
        {!this.state.datafetched ? (
          <Spinner />
        ) : (
          <>
            <div className="mb-4">
              <FilterComponent filterSet={this.filterSet} />
            </div>

            <TaskListComponent tasks={this.state.tasks} />
          </>
        )}
      </Fragment>
    );
  }
}
//git
