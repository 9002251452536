import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";

import { OffersIcon, RightArrowIcon } from "../../../../../assets/icons";

interface Props extends RouteComponentProps {
  totalOffers: number;
  totalWaiting: number;
  totalPending: number;
}

/**
 * this is function component shows offer number 
 * and linked to offers page,Create Ad,Explore Influencers
 * @param totalOffers , user total offer 
 * @param totalPending , user offers negotiating
 * @param totalWaiting , user offers waiting for Influencers
 * @typeParam Props 
 */


function OffersSummary(props: Props) {
  const { totalOffers, totalPending, totalWaiting } = props;

  return (
    <div className="dashboardWidget">
      <div className="dashboardWidget__header">
        <OffersIcon />
        <div>
          <span>{totalOffers}</span>
          <p>Offers</p>
        </div>
      </div>
      <p className="dashboardWidget__content">
        {totalOffers === 0 ? (
          <>
            You do not have any offers. You can{" "}
            <Link to="/app/ads/ad/objectives/">Create an Ad</Link> or{" "}
            <Link to="/app/influencer-explorer">Explore Influencers</Link> to
            get started.
          </>
        ) : (
          <>
            <span>{totalWaiting}</span> Negotiating, <span>{totalPending}</span>{" "}
            Waiting for Influencers.
          </>
        )}
      </p>
      <div className="dashboardWidget__link">
        {totalOffers !== 0 && (
          <Link to="/app/offers">
            View Offers <RightArrowIcon />
          </Link>
        )}
      </div>
    </div>
  );
}

export default OffersSummary;
