import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import DatatablePagination from '../../../../views/common-components/DatatablePagination';
import FilterComponent from '../../../common-components/Filter/FilterComponent';
import ServerSideFilterSet from '../../../common-components/Filter/ServerSideFilterSet';
import { Separator } from '../../../../template/components/common/CustomBootstrap';
import EmptySearchResult from '../../../app/offers/components/EmptySearchResult';
import { FilterType, Range } from '../../../common-components/Filter/AbstractFilterSet';
import InfluencersList from './InfluencersList';
import { SortOrder, FilterGroup } from '../../../../lib/QueryTypes';
import { debounce } from 'lodash';
import { Filters } from './Users';
import { Spinner } from 'reactstrap';
import RootStore from '../../../../store/RootStore';
import { observer } from 'mobx-react';
import { ICountryModel } from '../../../../store/models/CountryModel';
import { FilterFactory } from './InfluencerAndBrandFilterFactory';
import { ICategoryModel } from '../../../../store/models/CategoryModel';
import { CountriesList } from './../../../../constants/countriesList';

interface Props extends RouteComponentProps {}

interface State {
    searchTerm: string | null;
    category?: string | null;
    type?: string | null;
    sort: { key: string; direction: SortOrder };
    dataFetched: boolean;
    updateLists: boolean;
    filter_groups?: FilterGroup[];
    pagination: {
        totalPages: number;
        page: number;
        pageSize: number;
        totalCount: number;
    };
    rangeDate?: string[];
}
@observer
export default class InflunecersTab extends Component<Props, State> {
    private readonly filterSet: ServerSideFilterSet;
    constructor(props: Props) {
        super(props);
        this.state = {
            searchTerm: null,
            sort: {
                key: 'identities.created_at',
                direction: SortOrder.DESCENDING,
            },
            dataFetched: false,
            updateLists: false,
            pagination: {
                page: 0,
                pageSize: 10,
                totalPages: 0,
                totalCount: 0,
            },
            rangeDate: ['', ''],
            category: null,
            type: 'influencer',
        };

        this.filterSet = new ServerSideFilterSet(debounce(this.onChange, 600));
        this.filterSet.addFilter({
            name: 'user_id',
            title: 'Users',
            defaultValue: 'true',
            type: FilterType.simpleFilter,
            values: () => ['true', 'false', null],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value === 'true') {
                    return 'Internal Users';
                } else if (value === 'false') {
                    return 'External Users';
                } else {
                    return 'All';
                }
            },
        });
        this.filterSet.addFilter({
            name: 'created_at',
            title: 'Create Date',
            type: FilterType.simpleFilter,
            defaultValue: this.state.sort ? this.state.sort.direction : SortOrder.DESCENDING,
            values: () => [SortOrder.ASCENDING, SortOrder.DESCENDING],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value === SortOrder.ASCENDING) {
                    return 'Ascending';
                } else if (value === SortOrder.DESCENDING) {
                    return 'Descending';
                }
            },
        });
        this.filterSet.addFilter({
            name: 'country_id',
            title: 'Country',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [null, ...CountriesList.map((country: ICountryModel) => country.id)],
            valueToLabelConverter: (value: string | number | Range) => {
                if (!value) {
                    return 'All';
                } else {
                    return CountriesList.find((country: ICountryModel) => country.id === value).name;
                }
            },
        });
        this.filterSet.addFilter({
            name: 'category',
            title: 'Category',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [
                null,
                ...RootStore.categories.map((category: ICategoryModel) => {
                    return category.slug;
                }),
            ],
            valueToLabelConverter: (value: string | number | Range) => {
                if (!value) {
                    return 'All';
                } else {
                    return RootStore.categories.find((category: ICategoryModel) => category.slug === value).title;
                }
            },
        });
        this.filterSet.addFilter({
            name: 'created_at_range',
            title: 'range',
            defaultValue: null,
            type: FilterType.rangeFilter,
            values: (value: Range) => [{ start: value.start, end: value.end }, null],
        });
        this.onChange = this.onChange.bind(this);
        this.fetchUsers = this.fetchUsers.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.pageSizeChange = this.pageSizeChange.bind(this);
    }
    componentWillUnmount() {
        RootStore.users.clearUsers();
        RootStore.identityStore.clearIdentities();
    }
    async componentDidMount() {
        RootStore.identityStore.clearIdentities();
        await this.fetchUsers();
    }
    setFilters(filters: Filters) {
        let filterConfig = FilterFactory.influencerFilterSet(filters).buildFilterGruop(filters);

        this.setState({
            pagination: {
                page: 0,
                totalPages: this.state.pagination.totalPages,
                pageSize: this.state.pagination.pageSize,
                totalCount: this.state.pagination.totalCount,
            },
            filter_groups: filterConfig,
            sort: {
                key: 'identities.created_at',
                //@ts-ignore
                direction: filters.filters.created_at,
            },
        });
    }

    async fetchUsers() {
        this.setState({ dataFetched: false });
        let response = await RootStore.identityStore.fetchAllIdentities(
            this.state.pagination,
            this.state.filter_groups,
            this.state.sort,
            this.state.searchTerm,
            this.state.category,
            this.state.type,
        );
        this.setState({
            dataFetched: true,
            pagination: response.pagination,
        });
    }

    async onFilter(filters: Filters): Promise<void> {
        const { searchTerm } = filters;
        const { category } = filters.filters;
        this.setState({ searchTerm, category }, async () => {
            await this.setFilters(filters);
            this.fetchUsers();
        });
    }

    pageChange(page: number) {
        this.setState(
            {
                pagination: { ...this.state.pagination, page },
            },
            this.fetchUsers,
        );
    }
    pageSizeChange(pageSize: number) {
        this.setState(
            {
                pagination: { ...this.state.pagination, page: 0, pageSize },
            },
            this.fetchUsers,
        );
    }

    onChange = (filters: Filters): void => {
        this.onFilter(filters);
    };

    // set range of date for download user list
    changeDate = (value) => {
        const startMonth = value.start._d.getMonth() + 1;
        const startDay = value.start._d.getDate();
        const startYear = value.start._d.getFullYear();

        const endMonth = value.end._d.getMonth() + 1;
        const endDay = value.end._d.getDate();
        const endYear = value.end._d.getFullYear();

        const start = `${startYear}-${startMonth < 10 ? '0' + startMonth : startMonth}-${
            startDay < 10 ? '0' + startDay : startDay
        }`;

        const end = `${endYear}-${endMonth < 10 ? '0' + endMonth : endMonth}-${endDay < 10 ? '0' + endDay : endDay}`;

        this.setState({ rangeDate: [start, end] });
    };

    clearFilter = () => {
        this.setState({
            searchTerm: null,
            rangeDate: ['', ''],
            pagination: {
                page: 0,
                pageSize: 10,
                totalPages: 0,
                totalCount: 0,
            },
            sort: {
                key: 'identities.created_at',
                direction: SortOrder.DESCENDING,
            },
        });
    };

    render() {
        const { dataFetched, pagination, rangeDate } = this.state;
        return (
            <>
                <FilterComponent
                    filterSet={this.filterSet}
                    changeDate={this.changeDate}
                    clearFilter={this.clearFilter}
                />
                <Separator className="mb-4 mt-3" />
                {!dataFetched ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '20em' }}>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <Spinner color="primary" className="mb-1" />
                            <p>Please wait...</p>
                        </div>
                    </div>
                ) : (
                    <>
                        {RootStore.identityStore.identities.length > 0 ? (
                            <React.Fragment>
                                <InfluencersList
                                    identities={RootStore.identityStore.identities}
                                    integrations={RootStore.integrationStore.integrations}
                                    identityUsers={RootStore.users.adminUsers}
                                    pagination={pagination}
                                    rangeDate={rangeDate}
                                    {...this.props}
                                />
                                <DatatablePagination
                                    key="pagination"
                                    page={pagination.page}
                                    pages={pagination.totalPages}
                                    showPageSizeOptions={true}
                                    pageSizeOptions={[10, 50, 100, 200, 500]}
                                    onPageSizeChange={this.pageSizeChange}
                                    canPrevious={pagination.page === 0 ? false : true}
                                    canNext={pagination.page === pagination.totalPages - 1 ? false : true}
                                    defaultPageSize={pagination.pageSize}
                                    onPageChange={this.pageChange}
                                />
                            </React.Fragment>
                        ) : (
                            <EmptySearchResult />
                        )}
                    </>
                )}
            </>
        );
    }
}
