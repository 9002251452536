import { AbstractFilterSet, FilterValues } from "./AbstractFilterSet";

export default class ServerSideFilterSet extends AbstractFilterSet {
  private readonly onChange: (filterConfig: FilterValues) => void;

  constructor(onChange: (filterConfig: FilterValues) => void) {
    super();
    this.onChange = onChange;
  }

  protected handleChange(): void {
    this.onChange(this.getFilterValues());
  }
}
