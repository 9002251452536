import * as React from "react";
import { Alert, Row, Col } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../template/components/common/CustomBootstrap";
import { RouteComponentProps } from "react-router-dom";
import { BreadCrumb } from "../../../common-components";
import NewIntegrationWizzard from "./NewIntegrationWizzard";

interface Props extends RouteComponentProps {}

export default function NewIntegrationPage(props: Props) {
  return (
    <div className="newIntegrationPageWraper">
      <Col>
        <Row>
          <Colxx xxs="12">
            <BreadCrumb
              heading="Connecting Your Instagram Account"
              {...props}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <div className="newIntegrationPage">
          <NewIntegrationWizzard {...props} />

          <div className="newIntegrationPage__reason">
            <div>
              <img
                alt={"Instagram"}
                src={"/assets/img/instagram_icon_small.png"}
              />
              <div>
                <h1>Why Add Instagram Account?</h1>
                <p>
                  Because Instagram does not allow official API access for it's
                  users, we will have to connect to your account as a new device
                  from some location near what you entered in your profile page.
                  This will allow us to schedule posts and get insights for you!
                  You can logout any time you want!
                </p>
                <Alert color={"success"}>
                  <b>Fully Secure & Encrypted:</b> We do not store your password
                  at all, it will be used only once to get an access token from
                  Instagram...
                </Alert>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </div>
  );
}
