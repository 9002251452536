import React,{Component} from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'



export default class OverView extends Component{
    render(){
        return(
            <>
             <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"customBreadCrumb"}>
                    <h1>OverView</h1>
                    </div>
                </div>
            </div>

        <Card className="mb-4">
            <CardBody>
                <CardTitle>Charts</CardTitle>
            </CardBody>
        </Card>
        
            </>
        )
    }
}