import {
  applySnapshot,
  flow,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import {
  ContentModel,
  IContentModel,
  IContentModelSnapshotIn,
} from "./models/ContentModel";
import Transport from "../lib/Transport";
import { ajaxErrorAlert, handleError } from "../lib/Utils";
import { MediaAction, MediaModification } from "./models/MediaModel";
import BatchTransport from "../lib/BatchTransport";
import { makeContentQuery } from "./common/RequestHelpers";
import RootStore from "./RootStore";
import { IUserModel } from "./models/UserModel";

export const ContentStore = types
  .model({
    contentList: types.array(ContentModel),
  })
  .volatile((self) => ({
    isBusy: false,
    fetchedFromServer: false,
  }))
  .actions((self) => ({

    /**
    * this method check processing for this store
    */
    setBusy() {
      self.isBusy = true;
    },

    /**
    * this method just set value isBusy = false
    */
    setIdle() {
      self.isBusy = false;
    },

    /**
    * this method push model this store
    * @param model, this param type object for get model
    */
    pushModel(model: IContentModel) {
      self.contentList.push(model);
    },

    /**
    * this method just clear Content List
    */
    clearContentList() {
      self.contentList.clear();
    },
    syncMissingModels(models: IContentModelSnapshotIn[]) {
      if (!Array.isArray(models)) {
        return;
      }

      let currentIdList: number[] = self.contentList.map((content) => {
        return content.id;
      });

      let possiblyUpdatedModels = models.filter(
        (model) => currentIdList.indexOf(model.id) >= 0
      );
      if (possiblyUpdatedModels.length) {
        for (let content of self.contentList) {
          for (let updatedContent of possiblyUpdatedModels) {
            if (content.id === updatedContent.id) {
              applySnapshot(content, updatedContent);
            }
          }
        }
      }

      self.contentList.replace(
        self.contentList.concat(
          models
            .filter((model) => currentIdList.indexOf(model.id) === -1)
            .uniqueById()
        )
      );
    },
  }))
  .actions((self) => ({

    /**
    * this method create new content
    */
    create: flow(function* (snapshot: IContentModel): any {
      self.setBusy();
      let query = makeContentQuery();
      try {
        let response = yield Transport.post(
          "contents",
          { content: snapshot },
          query
        );

        let model = ContentModel.create(response.data.content);
        self.contentList.push(model);

        self.setIdle();
        return model;
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),

    /**
    * this method fetch all contents
    */
    fetchAll: flow(function* (): any {
      self.setBusy();

      let query = makeContentQuery();
      query.setPagination(10000, 0);
      try {
        let response = yield Transport.get("contents/created", query);

        self.syncMissingModels(response.data["contents"]);

        self.setIdle();
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),
  }))
  .actions((self) => ({

     /**
    * this method for update content
    */
    update: flow(function* (
      model: IContentModel,
      modifications: MediaModification[],
      newSnapshot: IContentModelSnapshotIn
    ): any {
      self.setBusy();

      try {
        let batch = new BatchTransport();

        let query = makeContentQuery(true);

        for (let modification of modifications) {
          // @ts-ignore

          let payload = {
            media: {
              ...modification.model,
              file_id: modification.model.file.id,
              original_file_id:
                modification.model.original_file === null
                  ? modification.model.file.id
                  : modification.model.original_file.id,
            },
          };

          if (modification.action === MediaAction.CREATE) {
            batch.post(
              "contents/" + modification.model.content_id + "/medias",
              payload
            );
          } else if (
            modification.action === MediaAction.EDIT ||
            modification.action === MediaAction.REORDER
          ) {
            batch.patch("medias/" + modification.model.id, payload);
          } else {
            // delete
            batch.delete("medias/" + modification.model.id);
          }
        }
        let contentData = JSON.parse(JSON.stringify(newSnapshot));
        contentData["medias"] = undefined;
        batch.patch("contents/" + model.id, { content: contentData }, query);

        let response = yield batch.all();

        let items = self.contentList.filter((value) => {
          return value.id === model.id;
        });

        let newContent = response.data[modifications.length].content;

        // @ts-ignore
        newContent["medias"] = newContent.medias.filter(
          (media) => media.deleted_at === null
        );

        applySnapshot(model, newContent);

        if (!items.length) {
          self.contentList.push(model);
        }
        self.setIdle();

        model.clearModifications();

        return model;
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),
    delete(model: IContentModel) {},

     /**
    * this method for fetch campaign by id
    * @param id, this param get id for fetch
    * @param force, this param if true call setIdle() for isBusy = false
    */
    fetchById: flow(function* (id: number, force: boolean = false): any {
      self.setBusy();

      let items = self.contentList.filter((value) => {
        return value.id === id;
      });

      let needsSync = false;

      if (items.length) {
        if (!force) {
          self.setIdle();
          return items[0];
        } else {
          needsSync = true;
        }
      }

      let query = makeContentQuery(true);

      try {
        let response = yield Transport.get("contents/" + id, query);
        self.setIdle();
        if (needsSync) {
          applySnapshot(items[0], response.data["content"]);
          return items[0];
        } else {
          return ContentModel.create(response.data["content"]);
        }
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),
    
    /**
    * this method for fetch all contents force
    */
    fetchAllIfNecessary: flow(function* (): any {
      if (!self.fetchedFromServer && self.isBusy === false) {
        try {
          yield self.fetchAll();
          self.fetchedFromServer = true;
        } catch (e) {
          handleError(e);
          ajaxErrorAlert(
            "Failed to load content list, please refresh the page."
          );
        }
      }
    }),

    /**
    * this method check exist content
    * @param id, this param id content for check exist
    */
    getExistingModel(id: number): IContentModel | null {
      let models = self.contentList.filter((content) => {
        return content.id === id;
      });
      if (models.length) {
        return models[0];
      }
      return null;
    },
    // fetchById(id:string) {
    //     self.setBusy();
    //
    //     return new Promise((resolve, reject)=>{
    //
    //         let items = self.contentList.filter((value)=>{
    //             return value.id == id;
    //         });
    //
    //         if(items.length){
    //             self.setIdle();
    //             return resolve(items[0]);
    //         }
    //
    //         let bruno = new ApiQueryBuilder();
    //         bruno.addIncludes('media', false);
    //
    //         Transport.getBruno('contents/' + id, bruno).then(
    //             (response)=>{
    //                 self.setIdle();
    //                 resolve(response);
    //             }
    //         ).catch(
    //             (error)=>{
    //                 self.setIdle();
    //                 reject();
    //             }
    //         );
    //
    //     });
    // }
  }))
  .views((self) => ({
    // getContents(): IContentModel[] {
    //   return self.contentList.filter((content) => {
    //     let snp = getSnapshot(content);
    //     return (
    //       snp.parent_id === null && snp.user_id === (RootStore.users.currentUser as IUserModel).id
    //     );
    //   });
    // },

    /**
    * this method get contents
    */
    getContents(): IContentModel[] {
      return self.contentList
        .filter((content) => {
          if (
            content.parent_id === null &&
            content.user_id === (RootStore.users.currentUser as IUserModel).id
          ) {
            return content.getMedias();
          }
          return null;
        })
        .filter((item) => item !== null);
    },
  }));

export interface IContentStore extends Instance<typeof ContentStore> {}

export interface IContentStoreSnapshotIn
  extends SnapshotIn<typeof ContentStore> {}

export interface IContentStoreSnapshotOut
  extends SnapshotOut<typeof ContentStore> {}
