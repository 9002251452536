import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "reactstrap";

import InvitedInfluencersEmptyState from "../../../../assets/img/InvitedInfluencersEmptyState.png";

interface Props extends RouteComponentProps {}

export default function EmptyState(props: Props) {
  return (
    <div className="invitedInfluencersEmptyState">
      <img src={InvitedInfluencersEmptyState} alt="ainfluencer" />
      <h2>You have not invited any influencers yet</h2>
      <p>
        Explore influencers, find the right influencer, and invite them to your
        ads.
      </p>
      <Button
        color="primary"
        onClick={() => props.history.push("/app/influencer-explorer")}
      >
        Explore Influencers
      </Button>
    </div>
  );
}
