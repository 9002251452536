import React, { Component } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router-dom";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import RootStore from "../../../../store/RootStore";
import { INotificationTemplateModel } from "../../../../store/models/NotificationTemplateModel";
import { FormikAceEditor } from "../../../common-components/FormikFields";

const NotificationSchema = Yup.object().shape({
  title: Yup.string().required("Title can not be empty"),
  body: Yup.string().required("body can not be empty"),
  html: Yup.string().required("html can not be empty"),
});
interface FormikValues {
  title: string;
  body: string;
  html: string;
}
interface Props extends RouteComponentProps<{ id: string }> {}

export default class NotificationTemplateEditForm extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  async onSubmit(values: FormikValues, formikActions: any) {
    formikActions.setSubmitting(true);
    try {
      await RootStore.notificationTemplateStore.update(
        +this.props.match.params.id,
        values
      );
      formikActions.setSubmitting(false);
      this.props.location.pathname = "admin/templates";
    } catch (e) {
      formikActions.setSubmitting(false);
      handleError(e);
      ajaxErrorAlert("There was a problem Editing the template.");
    }
  }

  getNotificationInitialValues(): FormikValues {
    let notification =
      RootStore.notificationTemplateStore.notificationTemplates.find(
        (notification: INotificationTemplateModel) =>
          notification.id === +this.props.match.params.id
      );
    return {
      title: notification.title,
      body: notification.body,
      html: notification.html,
    };
  }
  render() {
    return (
      <Formik
        initialValues={this.getNotificationInitialValues()}
        validationSchema={NotificationSchema}
        onSubmit={this.onSubmit}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form
            // translate={"no"}
            className="av-tooltip tooltip-label-right panel-form"
          >
            <FormGroup className="error-l-100">
              <Label className="position-relative">
                <b>Title</b>
                {errors.title && touched.title && (
                  <div className="invalidFeedback">{errors.title}</div>
                )}
              </Label>
              <FormikAceEditor
                name="title"
                value={values.title}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </FormGroup>
            <FormGroup className="error-l-100">
              <Label>
                <b>Body</b>
                {errors.body && touched.body && (
                  <div className="invalidFeedback">{errors.body}</div>
                )}
              </Label>
              <FormikAceEditor
                name="body"
                value={values.body}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </FormGroup>
            <FormGroup className="error-l-100">
              <Label className="position-relative">
                <b>Html</b>
                {errors.html && touched.html && (
                  <div className="invalidFeedback">{errors.html}</div>
                )}
              </Label>
              <FormikAceEditor
                name="html"
                value={values.html}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </FormGroup>
            <hr />
            <Button
              style={{ float: "right" }}
              type="submit"
              color="success ml-1"
              disabled={isSubmitting}
            >
              Edit Notification
            </Button>
            <Button
              style={{ float: "right" }}
              color="secondary"
              // onClick={toggle}
              disabled={isSubmitting}
            >
              Back
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}
