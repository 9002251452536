import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { AdModel } from "./AdModel";
import { IdentityModel } from "./IdentityModel";

export const InvitationModel = types
  .model({
    id: types.identifierNumber,
    ad_id: types.reference(AdModel),
    source_id: types.reference(IdentityModel),
    instagram_dm: types.maybeNull(types.boolean),
    target_id: types.reference(IdentityModel),
    status: types.enumeration([
      "pending",
      "expired",
      "accepted",
      "seen",
      "success",
      "failed",
    ]),
    created_at: types.maybeNull(types.string),
    deleted_at: types.maybeNull(types.string),
    updated_at: types.maybeNull(types.string),
    dm_status: types.maybeNull(types.string),
    email_status: types.maybeNull(types.string),
    track: types.maybeNull(types.string),
  })
  .actions((self) => ({}))
  .views((self) => ({

    /**
    * this method get Invitation Status
    */
    getInvitationStatus(): string {
      switch (self.status) {
        case "accepted":
          return "Invitation Accepted";
        case "expired":
          return "Invitation Expired";
        case "pending":
          return "Pending";
        case "seen":
          return "Invitation Seen By Influencer";
        case "success":
          return "Invitation Has Been Successfully Done!";
        case "failed":
          return "Invitation Has Been Failed!";
      }
    },
  }));

export interface IInvitationModel extends Instance<typeof InvitationModel> {}

export interface IInvitationModelSnapshotIn
  extends SnapshotIn<typeof InvitationModel> {}

export interface IInvitationModelSnapshotOut
  extends SnapshotOut<typeof InvitationModel> {}
