import React from 'react';
import { withRouter } from 'react-router-dom';
import CreatAdLayout from '../../layout';
import PublishTermsConfirm from '../publishTerms/parts/confirm';

function ConfrimCreateAd(props: any) {
    return (
        <CreatAdLayout>
            <PublishTermsConfirm />
        </CreatAdLayout>
    );
}

export default withRouter(ConfrimCreateAd);
