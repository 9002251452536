import React, { Component, GetDerivedStateFromProps } from 'react';
import { observer } from 'mobx-react';
import { Carousel } from 'react-responsive-carousel';
import { Scrollbars } from 'react-custom-scrollbars';
import { truncate } from 'lodash';

import { IContentModel } from '../../../../../store/models/ContentModel';
import '../../../../../../node_modules/react-responsive-carousel/lib/styles/carousel.css';
import RootStore from '../../../../../store/RootStore';
import { IUserModel } from '../../../../../store/models/UserModel';
import { ISamplePostData } from './ContentPreview';
import { SeeMoreSeeLessData } from '../../../../common-components';

interface Props {
    content: IContentModel;
    justContent?: boolean;
    samplePostData: ISamplePostData;
    postData?: any
}

interface State {
    content: any;
    justContent: boolean;
    samplePostData: ISamplePostData;
    dataLoaded: boolean;
    viewFullCaption: boolean;
}

@observer
export default class PostPreview extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            content: null,
            justContent: false,
            samplePostData: null,
            dataLoaded: false,
            viewFullCaption: false,
        };
    }

    static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props: Props, state: State) => {
        if (props.content && !state.dataLoaded) {
            return {
                content: props?.content,
                justContent: props?.justContent,
                samplePostData: props?.samplePostData,
                dataLoaded: true,
            };
        }

        return state;
    };

    getName(): string {
        return this.state?.samplePostData?.name?.length
            ? this.state?.samplePostData?.name
            : (RootStore.users?.currentUser as IUserModel).getFullName();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.content !== this.props.content) {
            this.setState((prevState) => ({
                content: this.props?.content,
            }));
        }
    }

    renderCaption = () =>{
        try{
            if(this.props?.postData?.captcha){
                return <SeeMoreSeeLessData data={this.props?.postData?.captcha} />
            }else if(this.props?.postData?.ad_id?.content_id?.data?.caption){
                return <SeeMoreSeeLessData data={this.props?.postData?.ad_id?.content_id?.data?.caption} />
            }else if(this.props?.content?.data?.caption){
                return <SeeMoreSeeLessData data={this.props?.content?.data?.caption} />
            }else{
                return ''
            }
        }catch(e){
            return ''
        }
        // {this.props?.postData?.captcha || this.props?.content?.data?.caption ? <SeeMoreSeeLessData data={this.props?.postData?.captcha || this.props?.content?.data?.caption} /> : (
        //     <SeeMoreSeeLessData data={this.props?.postData?.ad_id?.description || this.props?.postData?.description || ''} />
        //  )}
    }

    render() {
        const { content, justContent, samplePostData, dataLoaded } = this.state;
        // @ts-ignore
        return !dataLoaded ? null : justContent ? (
            <div className="onboarding-post-preview">
                <div className="post">
                    <div className="medias">
                        {content?.medias && (
                            <Carousel
                                dynamicHeight={true}
                                showArrows={false}
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={true}
                                emulateTouch={true}
                            >
                                {content?.medias.map((value, index) => (
                                    <div key={'post_image_item_' + index}>
                                        {value && value?.type?.startsWith('video/') ? (
                                            <video
                                                controls={false}
                                                autoPlay={true}
                                                loop={true}
                                                muted={true}
                                                style={{ width: '100%', height: 'auto' }}
                                            >
                                                <source src={value?.url} type={value?.type} />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : (
                                            <img alt={'IG'} src={value?.url} />
                                        )}
                                    </div>
                                ))}
                            </Carousel>
                        )}
                    </div>

                    {content?.data?.caption &&
                        <div className="caption">
                            <p><SeeMoreSeeLessData data={content?.data?.caption} /></p>
                        </div>
                    }
                </div>
            </div>
        ) : (
            <div className="post-preview">
                <Scrollbars
                    renderTrackHorizontal={(props) => (
                        <div {...props} style={{ display: 'none' }} className="track-horizontal" />
                    )}
                >
                    {content?.medias && content?.medias?.length ? (
                        <>
                            <div className="app-header"></div>
                            <div className="post">
                                <div className="header">
                                    {RootStore.users.getDefaultIdentity().logo && (
                                        <img
                                            alt="ainfluencer"
                                            src={
                                                samplePostData.profilePicture.length
                                                    ? samplePostData.profilePicture
                                                    : RootStore.users?.getDefaultIdentity()?.logo?.getThumbnailUrl(40, 40)
                                            }
                                        />
                                    )}
                                    <span>{truncate(this.getName(), { length: 40 })}</span>
                                </div>
                                <div className="medias">
                                    <Carousel
                                        dynamicHeight={true}
                                        showArrows={false}
                                        showThumbs={false}
                                        showStatus={false}
                                        showIndicators={true}
                                        emulateTouch={true}
                                    >
                                        {content?.getMedias()?.map((value, index) => (
                                            <div key={'post_image_item_' + index}>
                                                {value.file?.type?.startsWith('video/') ? (
                                                    <video
                                                        controls={false}
                                                        autoPlay={true}
                                                        loop={true}
                                                        muted={true}
                                                        style={{ width: '100%', height: 'auto' }}
                                                    >
                                                        <source src={value?.file?.url} type={value?.file?.type} />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                ) : (
                                                    <img alt={'IG'} src={value?.file?.url} />
                                                )}
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className="footer">
                                    <div className="icons">
                                        <span className="glyph-icon simple-icon-heart" />
                                        <span className="glyph-icon simple-icon-bubble" />
                                        <span className="glyph-icon simple-icon-action-redo" />
                                        <span />
                                    </div>
                                    <div className="caption">
                                        {/* <div>{numberFormatK(samplePostData.likes)} likes</div> */}
                                        <span style={{ color: '#000' }}>
                                            <b>{this.getName()}</b>{' '}
                                            <br />
                                            {this.props?.postData?.captcha || this.props?.content?.data?.caption ? <SeeMoreSeeLessData data={this.props?.postData?.captcha || this.props?.content?.data?.caption} /> : (
                                                <SeeMoreSeeLessData data={this.props?.postData?.ad_id?.description || this.props?.postData?.description || ''} />
                                            )}
                                        </span>
                                        <br />
                                        {/* <span>View all {numberFormatK(samplePostData.comments)} comments</span> */}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <p style={{ textAlign: 'center', padding: '1em' }}>
                            &nbsp;
                            <br />
                            In order to see the preview, please at least add a new media...
                        </p>
                    )}
                </Scrollbars>
            </div>
        );
    }
}
