import React, { Component } from 'react';
import moment from 'moment';

import { Colxx } from '../../../template/components/common/CustomBootstrap';
import { Row, Table, Spinner } from 'reactstrap';
import { IAdModel } from '../../../store/models/AdModel';
import { ITransactionModel } from '../../../store/models/TransactionModel';

interface Ipagination {
    limit: number;
    page: number;
    pagesCount: number;
    totalCount: number;
}

interface Props {
    transactions: ITransactionModel[];
    pagination: Ipagination;
    datafetched?: boolean;
}
interface State {
    transactionsList: Array<ITransactionModel>;
    displayAdPreview: boolean;
    adToBeShown: IAdModel | null;
    currentTaskIndex: number | null;
    pagination: Ipagination;
}

export default class TransactionsListComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            transactionsList: this.props.transactions,
            displayAdPreview: false,
            adToBeShown: null,
            currentTaskIndex: null,
            pagination: this.props.pagination,
        };
        this.onDisplayAdPreview = this.onDisplayAdPreview.bind(this);
        //this.approveAd = this.approveAd.bind(this);
        //this.rejectAd = this.rejectAd.bind(this);
    }
    onDisplayAdPreview() {
        this.setState((prevState) => ({
            displayAdPreview: !this.state.displayAdPreview,
        }));
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.transactions !== this.props.transactions) {
            this.setState({
                transactionsList: this.props.transactions, //new TransactionModel(this.props.transactions),
            });
        }
    }

    render() {
        const { datafetched } = this.props;
        return (
            <>
                <Row>
                    <Colxx>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Transaction Date</th>
                                    <th>Brand</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th style={{ width: 240 }}>Description</th>
                                    <th>Influencer / Wallet</th>
                                    <th>Currency</th>
                                </tr>
                            </thead>
                            {datafetched && (
                                <tbody>
                                    {this.state.transactionsList.map((transaction, index) => {
                                        const date = moment(transaction.created_at).format('MM/DD/YYYY - hh:mm a');
                                        const S = transaction['source_wallet'];
                                        const T = transaction['target_wallet'];

                                        const brand = () => {
                                            if (S && T) {
                                                return (
                                                    <>
                                                        <span>{S?.owner?.first_name + S?.owner?.last_name}</span>
                                                        <br />
                                                        <span>{S?.owner?.email}</span>
                                                    </>
                                                );
                                            } else if (!S && T) {
                                                if (transaction?.type === 'charge') {
                                                    return (
                                                        <>
                                                            <span>
                                                                {T?.owner?.first_name || '' + T?.owner?.last_name || ''}
                                                            </span>
                                                            <br />
                                                            <span>{T?.owner?.email}</span>
                                                        </>
                                                    );
                                                }
                                                //@ts-ignore
                                                else if (transaction?.type === 'credit') {
                                                    return 'Ainfluencer';
                                                }
                                            } else if (!T && S) {
                                                return 'Ainfluencer';
                                            } else {
                                                return '...';
                                            }
                                        };

                                        const influencer = () => {
                                            if (S && T) {
                                                return (
                                                    <>
                                                        <span>
                                                            {T?.owner?.first_name || '' + T?.owner?.last_name || ''}
                                                        </span>
                                                        <br />
                                                        <span>{T?.owner?.email}</span>
                                                    </>
                                                );
                                            } else if (!S && T) {
                                                if (transaction?.type === 'charge') {
                                                    return 'wallet';
                                                }
                                                //@ts-ignore
                                                else if (transaction?.type === 'credit') {
                                                    return (
                                                        <>
                                                            <span>
                                                                {T?.owner?.first_name || '' + T?.owner?.last_name || ''}
                                                            </span>
                                                            <br />
                                                            <span>{S?.owner?.email}</span>
                                                        </>
                                                    );
                                                }
                                            } else if (!T && S) {
                                                return (
                                                    <>
                                                        <span>
                                                            {S?.owner?.first_name || '' + S?.owner?.last_name || ''}
                                                        </span>
                                                        <br />
                                                        <span>{S?.owner?.email}</span>
                                                    </>
                                                );
                                            } else {
                                                return '...';
                                            }
                                        };

                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{date}</td>
                                                <td>{brand()}</td>
                                                <td>{transaction.amount}</td>
                                                <td
                                                    className={
                                                        transaction.status === 'locked'
                                                            ? 'text-danger'
                                                            : transaction.status === 'pending'
                                                            ? 'text-warning'
                                                            : transaction.status === 'refunded'
                                                            ? 'text-info'
                                                            : 'text-success'
                                                    }
                                                >
                                                    {transaction.status}
                                                </td>
                                                <td>{transaction.type}</td>
                                                <td>{transaction?.description}</td>
                                                <td>{influencer()}</td>
                                                <td>{transaction.currency}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            )}
                        </Table>
                        {!datafetched && (
                            <div
                                style={{
                                    width: '100%',
                                    height: 400,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Spinner color="primary" size={'lg'} className="spinner" />
                            </div>
                        )}
                    </Colxx>
                </Row>
            </>
        );
    }
}
