export const route_wellcome = '/app/welcome';
export const routesOnboarding = [
    {
        path: `${route_wellcome}/mycompany`,
        label: 'Company Profile',
        key: 'mycompany',
    },
    {
        path: `${route_wellcome}/objectives`,
        label: 'Objectives',
        key: 'objectives',
    },
    {
        path: `${route_wellcome}/marketplace`,
        label: 'Marketplace Details',
        key: 'marketplace',
    },
    {
        path: `${route_wellcome}/post-content`,
        label: 'Create an Ad - Post and Content',
        key: 'post-content',
    },
    {
        path: `${route_wellcome}/publish-terms`,
        label: 'Publish Terms',
        key: 'publish-terms',
    },
    {
        path: `${route_wellcome}/confirm`,
        label: 'Confirm',
        key: 'confirm',
        skip: true,
    },
    {
        path: `${route_wellcome}/discover`,
        label: 'Discover And Invite Influencer',
        key: 'discover',
    },
    {
        path: `${route_wellcome}/skip`,
        skip: true,
        key: 'skip',
    },
];
