import React from "react";
import numbro from "numbro";
import { RouteComponentProps } from "react-router-dom";
import { truncate } from "lodash";

import { isMobileAndTablet } from "../../../../lib/Utils";
import { IInfluencerModel } from "../../../../store/models/InfluencerModel";
import profileImg from "../../../../assets/img/profilePlaceholder.jpg";
import { minifyNum } from './../../../../lib/Utils'

interface Props extends RouteComponentProps {
  influencer: IInfluencerModel;
}

export default function OngoingOfferCard(props: Props) {
  const { influencer } = props;
  const url = profileImg;

  return (
    <div
      className="similarProfileCard"
      onClick={() =>
        props.history.push(`/app/influencer-explorer/${influencer.identity.id}`)
      }
    >
      {influencer.identity.logo && (
        <img
          style={{ width: "90px", height: "90px" }}
          src={
            influencer.identity.logo
              ? influencer.identity.logo.getThumbnailUrl(90, 90)
              : url
          }
          alt="ainfluencer"
        />
      )}
      <div className="similarProfileCard__details">
        <div className="similarProfileCard__details_header">
          <div>
            <p>{truncate(influencer.integration.full_name, { length: 25 })}</p>
            <p>{influencer.integration.username}</p>
          </div>
          <span>
            {influencer.identity.categories &&
              influencer.identity.categories[0].title}
          </span>
        </div>
        {!isMobileAndTablet() && (
          <div className="similarProfileCard__details_statistics">
            <div>
              <h2>
                {influencer.integration &&
                  influencer.integration.followers &&
                  minifyNum(influencer.integration.followers)}
              </h2>
              <span>Followers</span>
            </div>
            <div>
              <h2>
                {influencer.integration &&
                  influencer.integration.followings &&
                  minifyNum(influencer.integration.followings)}
              </h2>
              <span>Following</span>
            </div>
            <div>
              <h2>
                {numbro(influencer.integration.engagement_rate).format({
                  output: "percent",
                  optionalMantissa: true,
                  mantissa: 2,
                })}
              </h2>
              <span>Engagement</span>
            </div>
          </div>
        )}
      </div>
      {isMobileAndTablet() && (
        <div className="similarProfileCard__details_statistics">
          <div>
            <h2>
              {influencer.integration &&
                influencer.integration.followers &&
                minifyNum(influencer.integration.followers)}
            </h2>
            <span>Followers</span>
          </div>
          <div>
            <h2>
              {influencer.integration &&
                influencer.integration.followings &&
                minifyNum(influencer.integration.followings)}
            </h2>
            <span>Following</span>
          </div>
          <div>
            <h2>
              {numbro(influencer.integration.engagement_rate).format({
                output: "percent",
                optionalMantissa: true,
                mantissa: 2,
              })}
            </h2>
            <span>Engagement</span>
          </div>
        </div>
      )}
    </div>
  );
}
