import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import RootStore from "../../../../store/RootStore";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import { NotificationManager } from "../../../../template/components/common/react-notifications";
import { observer } from "mobx-react";
import { IUserModel } from "../../../../store/models/UserModel";
import { FormikWysiwyg } from "../../../common-components/FormikFields";

const EmailSchema = Yup.object().shape({
  subject: Yup.string().required("Email subject can not be empty"),
  body: Yup.string().required("Email Body can not be empty"),
});
interface Props {
  isOpen: boolean;
  user: IUserModel;
  toggle: () => void;
}

@observer
export default class EmailForm extends React.Component<Props> {
  onSubmitEmail = async (values: any, actions: any) => {
    try {
      actions.setSubmitting(true);
      const emailValues = {
        subject: values.subject,
        body: `
        <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${values.subject}</title>
          </head>
          <body>
            ${values.body}
          </body>
        </html>`,
      };
      await RootStore.users.sendEmail(this.props.user.id, emailValues);
      NotificationManager.success(
        "Done",
        "Email sent successfully!",
        3000,
        null,
        null,
        "filled"
      );
      actions.setSubmitting(false);
      this.props.toggle();
    } catch (e) {
      actions.setSubmitting(false);
      handleError(e);
      ajaxErrorAlert("There was a problem in sending the email.");
    }
  };

  getEmailInitialValues(): any {
    let user = this.props.user;
    return {
      id: user.id,
      subject: "",
      body: "",
    };
  }

  render() {
    const { isOpen, toggle } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Send Email</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={this.getEmailInitialValues()}
            validationSchema={EmailSchema}
            onSubmit={this.onSubmitEmail}
          >
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form
                translate={"no"}
                className="av-tooltip tooltip-label-right panel-form"
              >
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Subject</b>
                    {errors.subject && touched.subject && (
                      <div className="invalidFeedback">{errors.subject}</div>
                    )}
                  </Label>
                  <Field
                    className="form-control w-100"
                    name="subject"
                    disabled={isSubmitting}
                  />
                </FormGroup>
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Body</b>
                    {errors.body && touched.body && (
                      <div className="invalidFeedback">{errors.body}</div>
                    )}
                  </Label>
                  <FormikWysiwyg
                    name="body"
                    value={values.body}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    disabled={isSubmitting}
                  />
                </FormGroup>
                <hr />
                <Button
                  style={{ float: "right" }}
                  type="submit"
                  color="success ml-1"
                  disabled={isSubmitting}
                >
                  Send Email
                </Button>
                <Button
                  style={{ float: "right" }}
                  color="secondary"
                  onClick={toggle}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}
