import React, { Component } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import Users from "./components/Users";

interface Props extends RouteComponentProps {}

export default class UsersTab extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}`} component={Users} />
      </Switch>
    );
  }
}
