import React from "react";
import { Modal } from "reactstrap";
import { CloseIcon } from "../../assets/icons";
import { IContentModel } from "../../store/models/ContentModel";
import ContentPreview from "../app/content-manager/components/preview/ContentPreview";
interface Props {
  content: IContentModel;
  isOpen: boolean;
  onClose: () => void;
  postData?: any
}

export function ContentPreviewModal(props: Props) {
  const { isOpen, content, onClose } = props;

  return (
    <Modal isOpen={isOpen} className="adPreviewModal">
      <CloseIcon onClick={onClose} className="adPreviewModal__closeButton" />
      <ContentPreview model={content} postData={props?.postData} />
    </Modal>
  );
}
