import React from "react";
import { Modal } from "reactstrap";

import { DangerIcon } from "../../assets/icons";

interface AdBlockerDetectorProps {
  isOpen: boolean;
  onClose(): void;
}

export function AdBlockerDetector(props: AdBlockerDetectorProps) {
  const { onClose, isOpen } = props;
  return (
    <Modal toggle={onClose} isOpen={isOpen} className="adBlockerDetectorModal">
      <DangerIcon />
      <h1>It looks like you're using an ad-blocker!</h1>
      <p>
        Ainfluencer is an advertising based site and we noticed you have
        ad-blocker enabled. Please turn off you're ad-blocker.
      </p>
    </Modal>
  );
}
