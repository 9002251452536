import React from "react";
import { observer } from "mobx-react";
import { truncate } from "lodash";
import classNames from "classnames";
import moment from "moment";
import TooltipLite from "react-tooltip-lite";
import { Link, NavLink } from "react-router-dom";

import DealCard from "./DealCard";
import { CountDown } from "../../../common-components";
import {
  TickIcon,
  CloseIcon,
  HourGlassIcon,
  MegaphoneIcon,
} from "../../../../assets/icons";
import profileImg from "../../../../assets/img/profilePlaceholder.jpg";

@observer
export default class DealCardMobileView extends DealCard {
  public url = profileImg;
  render() {
    const { offer } = this.props;

    return (
      <div className="dealCard">
        <div>
          <img
            style={{ width: "50px", height: "50px" }}
            src={
              this.props.offer.getOpponentIdentity().logo
                ? this.props.offer
                    .getOpponentIdentity()
                    .logo.getThumbnailUrl(50, 50)
                : this.url
            }
            alt={"Profile"}
          />
        </div>

        <div>
          <div className="dealCard__profileDetails">
            <div className="d-flex flex-column">
              <p>
                {this.props.offer.getOpponentIdentity().name ?? "(No Name)"}
              </p>
              <span>
                @
                {truncate(this.props.offer.getTargetIntegration().username, {
                  length: 24,
                })}
              </span>
            </div>
            <div className="dealCard__status">
              {offer.getHumanReadableStatus() === "Failed" ? (
                <TooltipLite
                  mouseOutDelay={200}
                  background="#000"
                  content={
                    <p>
                      This Ad has been failed because of some technical
                      problems. <br />
                      please{" "}
                      <Link to={`/app/offers/${this.props.offer.id}`}>
                        view offer
                      </Link>{" "}
                      and renegotiate.
                    </p>
                  }
                >
                  <CloseIcon
                    className={classNames(
                      "bg-" + this.props.offer.getStatusColor()
                    )}
                  />
                </TooltipLite>
              ) : offer.getHumanReadableStatus() === "Completed" ? (
                <TooltipLite
                  mouseOutDelay={200}
                  background="#000"
                  content={<p>Published</p>}
                >
                  <TickIcon
                    className={classNames(
                      "bg-" + this.props.offer.getStatusColor()
                    )}
                  />
                </TooltipLite>
              ) : offer.getHumanReadableStatus() === "Scheduled" ? (
                <TooltipLite
                  mouseOutDelay={200}
                  background="#000"
                  content={<p>Waiting for publish</p>}
                >
                  <HourGlassIcon
                    className={classNames(
                      "bg-" + this.props.offer.getStatusColor()
                    )}
                  />
                </TooltipLite>
              ) : null}
            </div>
          </div>
          <div className="dealCard__ad">
            <MegaphoneIcon />
            <p
              id={`OfferToolTipToggler_${offer.id}`}
              className="offerDetailsTooltipLink"
              // onMouseEnter={this.handleMouseHover}
              // onMouseLeave={this.handleMouseHover}
            >
              {truncate(offer.ad_id.title, { length: 20 })}
            </p>
            {/* <Tooltip
              placement="auto"
              isOpen={this.state.isHovering}
              toggle={this.handleMouseHover}
              target={`OfferToolTipToggler_${offer.id}`}
              delay={{ show: 0, hide: 1200 }}
              // delay={0}
              fade={false}
            >
              <div className="offerDetailsTooltip">
                <OfferDetails offer={this.props.offer} />
                <Button color="primary" className="mx-auto">
                  Open Offer
                </Button>
              </div>
            </Tooltip> */}
          </div>

          <div className="dealCard__timer">
            <span>Starts in:</span>
            <br />
            <CountDown endsAt={moment(offer.start).valueOf()} />
          </div>
          <div className="dealCard__actions">
            {offer.getHumanReadableStatus() === "Failed" ? (
              <Link
                className="dealCard__actions_failed"
                style={{ color: this.props.offer.getStatusColor() }}
                to={`/app/offers/${this.props.offer.id}`}
              >
                View Offer
              </Link>
            ) : offer.getHumanReadableStatus() === "Completed" ? (
              <>
                <Link to={`/app/offers/${this.props.offer.id}`}>
                  View Offer
                </Link>
                <NavLink
                  to={`/app/publish-jobs/${
                    this.props.offer.getLatestPublishJob().id
                  }/analytics`}
                >
                  Analytics
                </NavLink>
              </>
            ) : offer.getHumanReadableStatus() === "Scheduled" ? (
              <Link to={`/app/offers/${this.props.offer.id}`}>View Offer</Link>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
