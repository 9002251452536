import { Instance, SnapshotIn, SnapshotOut, types, flow } from 'mobx-state-tree';
import { IdentityModel, IIdentityModel, IIdentityModelSnapshotIn } from './models/IdentityModel';

import Transport from '../lib/Transport';
import RootStore from './RootStore';
import { makeIdentityQuery } from './common/RequestHelpers';
import { identityQueryBuilder } from '../views/admin/users-managment/components/UserQueryBuilder';
import { FilterGroup } from '../lib/QueryTypes';
import { ajaxErrorAlert, handleError } from '../lib/Utils';

export const IdentityStore = types
    .model({
        identities: types.array(IdentityModel),
    })
    .volatile((self) => ({
        isBusy: false,
    }))
    .actions((self) => ({
        /**
         * this method check processing for this store
         */
        setBusy() {
            self.isBusy = true;
        },

        /**
         * this method just set value isBusy = false
         */
        setIdle() {
            self.isBusy = false;
        },

        /**
         * this method add Identity
         * @param identity, this param identity model
         */
        addIdentity(identity: IIdentityModel) {
            self.identities.push(identity);
        },

        /**
         * this method just clear Identities
         */
        clearIdentities() {
            self.identities.clear();
        },

        /**
         * this method get Identity by user id
         * @param id, this param user id for get Identity
         */
        getByUserId(id: number): IIdentityModel {
            let model = self.identities.filter((identity: IIdentityModel) => identity.user_id === id)[0];

            return model;
        },

        /**
         * this method get Identity by Id
         * @param id, this param get IdentityId id
         */
        getByIdentityId(id: number): IIdentityModel {
            let model = self.identities.filter((identity: IIdentityModel) => identity.id === id)[0];

            return model;
        },

        syncMissingModels(models: IIdentityModelSnapshotIn[]) {
            if (!Array.isArray(models)) {
                return;
            }

            let identityIdList: number[] = self.identities.map((identity) => {
                return identity.id;
            });

            // let possiblyUpdatedModels = models.filter(model => identityIdList.includes(model.id));
            // if (possiblyUpdatedModels.length) {
            //   self.identities.forEach (identity => {
            //     possiblyUpdatedModels.forEach(updatedContent => {
            //         if (identity.id === updatedContent.id) {
            //             applySnapshot(identity, updatedContent);
            //           }
            //         }
            //       )
            //     }
            //   )
            // }

            self.identities.replace(
                self.identities.concat(models.filter((model) => !identityIdList.includes(model.id))),
            );
        },
    }))
    .actions((self) => ({
        /**
         * this method fetch all Identities
         * @param pagination, this param type object for query pagination
         * @param filter_groups, this param object for filter Identities
         * @param SortOrder, this param object for sort Identities
         */
        fetchAllIdentities: flow(function* (
            pagination: { page: number; pageSize: number },
            filter_groups: FilterGroup[],
            SortOrder,
            search,
            category,
            type,
        ) {
            self.clearIdentities();
            self.setBusy();
            try {
                let response = yield Transport.get(
                    'identities',
                    identityQueryBuilder(pagination, filter_groups, SortOrder, search, category, type),
                );

                self.syncMissingModels(response.data['identities']);
                RootStore.integrationStore.syncMissingModels(response.data['integrations']);
                RootStore.users.syncMissingModels(response.data['user']);
                self.setIdle();
                return {
                    pagination: {
                        totalPages: response.data.meta.page_count,
                        pageSize: response.data.meta.limit,
                        page: response.data.meta.page,
                        totalCount: response.data.meta.total_count,
                    },
                };
            } catch (error) {
                self.setIdle();
                ajaxErrorAlert(error);
                handleError(error);
                throw error;
            }
        }),

        /**
         * this method edit Identities
         * @param identity_id, this param identity id for edit
         * @param values, this param new values for edit identity
         */
        editIdentity: flow(function* (identity_id: number, values: IIdentityModelSnapshotIn) {
            self.setBusy();

            try {
                yield Transport.patch(
                    `identities/${identity_id}`,
                    {
                        identity: values,
                    },
                    makeIdentityQuery(),
                );
                // let identityIndex = findIndex(self.identities, ["id", identity_id]);

                // applySnapshot(
                //   self.getByIdentityId(identity_id),
                //   response.data["identity"]
                // );
                // applySnapshot throw error why i ignore it

                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),
    }))
    .views((self) => ({}));

export interface IIdentityStore extends Instance<typeof IdentityStore> {}
export interface IIdentityStoreSnapshotIn extends SnapshotIn<typeof IdentityStore> {}
export interface IIdentityStoreSnapshotOut extends SnapshotOut<typeof IdentityStore> {}
