import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { CustomInput, Label } from 'reactstrap';
import countriesList from '../../../../../../../lib/json/countries';
import { ICountryModel } from '../../../../../../../store/models/CountryModel';
import RootStore from '../../../../../../../store/RootStore';
import { FormikReactSelect } from '../../../../../../common-components';
import NextButton from '../../../../../dashboard/multi-step-form/onboarding/parts/button';
import createAdContext from '../../../../context';
import classes from './style.module.scss';
import API from '../../../../../../../lib/axiosSetup';
import Swal from 'sweetalert2';
import { SortOrder } from '../../../../../../../lib/QueryTypes';
import BaseTransport from '../../../../../../../lib/BaseTransport';
import { observer } from 'mobx-react';
import { getCountry } from '../../../../model';

function OnboardDiscoverForm(props: any) {
    const {
        baseUrl,
        oldData,
        mode,
        data: {
            adID,
            discover: { gender, offer, countries, hashtags },
            createAd: { feeds, story },
            company,
        },
        updateOnboard,
    } = useContext(createAdContext);

    const [Process, setProcess] = useState(false);

    const updateDiscover = (key: string, value: any) => {
        updateOnboard((prev: any) => ({
            ...prev,
            discover: { ...prev.discover, [key]: value },
        }));
    };

    const discoverInfluencersStore = async (data: any) => {
        await RootStore.discoverInfluencersStore.setSearchObj(data);
    };

    const capitalize = (s: string) => {
        return s.substr(0, 1).toUpperCase() + s.slice(1, s.length);
    };

    const activeAd = () => {
        API.put(BaseTransport.getEndPointPath(`v2/ads/${adID}`), {
            status: 'active',
            final_step: true,
        })
            .then((res) => {
                setProcess(false);
                updateOnboard((prev: any) => ({ ...prev, status: 'active' }));
            })
            .catch(function (error) {
                setProcess(false);
                if (error.response?.data?.message) {
                    Swal.fire({
                        type: 'error',
                        text: error.response.data.message,
                        showCancelButton: true,
                        showConfirmButton: true,
                    });
                }
            });
    };

    useEffect(() => {
        initiAds();
    }, []);

    const initiAds = async () => {
        await RootStore.adStore.fetchAll();
        const Ads = await RootStore.adStore.getAllAds();
        const checkExistAd = await Ads.find((item) => +item?.id === +adID);
        if (checkExistAd) {
            RootStore.adStore.setAd(adID);
        }
    };

    const customSearchRedirect = async (values: any) => {
        const { countries, gender, offer } = values;

        setProcess(false);
        activeAd();
        const getIdentities = RootStore.users.currentUser.getIdentities;
        const data = {
            categories: [],
            followers: {
                min: 5,
                max: 1000,
            },
            language: '',
            sorts: [
                {
                    key: 'followers',
                    direction: SortOrder.DESCENDING,
                },
            ],
            country:
                Array.isArray(countries) && countries.length > 0
                    ? countries[0]
                    : company?.country
                    ? company?.country[0]
                    : getIdentities() && getIdentities().length
                    ? getCountry([getIdentities()[0]?.country_id])[0]
                    : '',
            inviteOnly: offer,
            ...(hashtags.length && { hashtags: hashtags }),
            ...(gender && {
                gender: {
                    label: capitalize(gender),
                    value: gender === 'all' ? null : gender,
                },
            }),
        };

        discoverInfluencersStore(data);
        await localStorage.setItem(`filter_${adID}`, JSON.stringify(data));
        await localStorage.setItem('selected_ad', `${adID}`);
        await RootStore.adStore.setAd(adID);
        const ad_info_country_changed = { ...data };
        ad_info_country_changed.country = [ad_info_country_changed.country?.value];

        const ad_info = {
            invite_only: data?.inviteOnly,
            limit_country: [ad_info_country_changed.country?.value],
            limit_gender: data?.gender,
            ad_hashtags: data?.hashtags?.map((item) => `#${item}`).join(' '),
        };
        await localStorage.setItem(`ad_info`, JSON.stringify(ad_info));

        const control_offer_ad = JSON.parse(localStorage.getItem('control_offer_ad')) || {
            limit_county: [],
            limit_gender: '',
            invite_only: true,
            setting: { followers: '', engagement: '', languages: [] },
        };
        control_offer_ad.limit_country = countries?.map((item) => item?.value) || [];
        control_offer_ad.invite_only = offer || true;
        control_offer_ad.limit_gender = gender?.value || 'all';
        await localStorage.setItem('control_offer_ad', JSON.stringify(control_offer_ad));
        RootStore.router.push('/app/influencer-explorer/custom-search');
    };

    return (
        <Formik
            initialValues={{
                countries: countries && countries.length ? countries : null,
                gender: gender,
                offer: offer,
            }}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                if (feeds.images.length === 0 && story.images.length === 0)
                    return RootStore.router.push(`${baseUrl}/post-content/${adID}`);

                const { countries, gender, offer } = values;
                updateDiscover('gender', gender);
                updateDiscover('offer', offer);
                updateDiscover('countries', countries);

                const data = {
                    invite_only: offer,
                    gender: gender.toLowerCase(),
                    hashtags: hashtags?.length ? hashtags : [],
                    ...(countries &&
                        countries?.length && {
                            countries: countries?.map((el: any) => el.value),
                        }),
                };

                if (oldData) {
                    const customCountry = [];
                    if (oldData?.limit_country && Array.isArray(oldData?.limit_country)) {
                        oldData?.limit_country?.map((ca: any) => {
                            countriesList.map((el: any) => {
                                if (el.id === ca) {
                                    customCountry.push({
                                        label: el.name,
                                        value: el.id,
                                    });
                                }
                            });
                        });
                    }

                    const old = {
                        invite_only: oldData?.invite_only,
                        gender: oldData?.limit_gender ? oldData?.limit_gender.toLowerCase() : 'all',
                        hashtags: oldData?.hashtags && oldData?.hashtags?.length ? oldData?.hashtags : [],
                        ...(customCountry.length && { countries: customCountry }),
                    };

                    if (JSON.stringify(data) === JSON.stringify(old)) return customSearchRedirect(values);
                }

                setProcess(true);
                API.post(`v2/ads/${adID}/influencer-filters`, data)
                    .then((res) => {
                        customSearchRedirect(values);
                    })
                    .catch(function (error) {
                        setProcess(false);
                        if (error.response?.data?.message) {
                            Swal.fire({
                                type: 'error',
                                text: error.response.data.message,
                                showCancelButton: true,
                                showConfirmButton: true,
                            });
                        }
                    });
            }}
        >
            {({ setFieldValue, values, setFieldTouched }) => (
                <Form style={{ width: '100%' }}>
                    <Grid container className={classes.Discover} flexDirection="column" rowSpacing={4}>
                        <Grid item>
                            <Label className={classes.label}>
                                Are you looking to work with influencers of a given gender?
                            </Label>
                            <Grid item container columnSpacing={2} className={classes.content}>
                                <Grid item>
                                    <CustomInput
                                        type="radio"
                                        id="exCustomRadio4"
                                        name="gender"
                                        label="Female"
                                        value={'female'}
                                        // @ts-ignore
                                        checked={values?.gender === 'female'}
                                        onChange={(e) => {
                                            setFieldValue('gender', 'female');
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <CustomInput
                                        type="radio"
                                        id="exCustomRadio5"
                                        name="gender"
                                        label="Male"
                                        value={'male'}
                                        // @ts-ignore
                                        checked={values?.gender === 'male'}
                                        onChange={(e) => {
                                            setFieldValue('gender', 'male');
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <CustomInput
                                        type="radio"
                                        id="exCustomRadio6"
                                        name="gender"
                                        label="All"
                                        value={'All'}
                                        // @ts-ignore
                                        checked={values?.gender === 'all'}
                                        onChange={(e) => {
                                            setFieldValue('gender', 'all');
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Label className={classes.label}>
                                Which countries are you looking to target through influencers?
                            </Label>
                            <Grid item className={classes.content} flexDirection="column" sm={6}>
                                <FormikReactSelect
                                    name="country"
                                    id="country"
                                    value={values.countries}
                                    isMulti={true}
                                    isClearable={true}
                                    options={countriesList?.map((cou: ICountryModel) => {
                                        return {
                                            label: cou.name,
                                            value: cou.id,
                                        };
                                    })}
                                    onChange={(name: string, value: any) => {
                                        setFieldValue('countries', value || null);
                                    }}
                                    onBlur={setFieldTouched}
                                    className="w-100"
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Label for="exCustomRadio4" className={classes.label}>
                                How would you like to receive offers on your Ad?
                            </Label>
                            <Grid item className={classes.content} flexDirection="column">
                                <Grid item>
                                    <CustomInput
                                        type="radio"
                                        id="exCustomRadio1"
                                        name="offer"
                                        label="Only allow offers from influencers that I have invited"
                                        value={1}
                                        checked={values?.offer === true}
                                        onChange={(e) => {
                                            setFieldValue('offer', true);
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <CustomInput
                                        type="radio"
                                        id="exCustomRadio2"
                                        name="offer"
                                        label="Allow any influencer to send me an offer"
                                        value={0}
                                        // @ts-ignore
                                        checked={values?.offer === false}
                                        onChange={(e) => {
                                            setFieldValue('offer', false);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="right">
                            {mode === 'onboard' ? (
                                <NextButton loading={Process} />
                            ) : (
                                <NextButton loading={Process}> Finish & Save</NextButton>
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

export default observer(withRouter(OnboardDiscoverForm));
