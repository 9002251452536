import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import moment from "moment";

import { ContentModel, IContentModel } from "./ContentModel";
import { IIdentityModel } from "./IdentityModel";
import { AdModel } from "./AdModel";
import { IIntegrationModel } from "./IntegrationModel";
import RootStore from "../RootStore";
import { DialogueModel, IDialogueModel } from "./DialogueModel";
import { LastModificationModel } from "./LastModificationModel";
import { arrayToText } from "../../lib/Utils";
import AbstractOfferHistory from "../common/OfferHistory";
import OfferHistory from "../common/OfferHistory";
import { CampaignModel } from "./CampaignModel";
import { IPublishJobModel } from "./PublishJobModel";

export type PossibleOfferActions =
  | "offer"
  | "counter"
  | "edit"
  | "cancel"
  | "accept"
  | "reject"
  | "revise"
  | "terminate"
  | "contact-support"
  | "complete"
  | "pay"
  | "approve-cancellation"
  | "reject-cancellation"
  | "cancel-conflict"
  | "refund-conflict"
  | "refund"
  | "cancel";

export type ExtensiveOfferStatus =
  | "no-offer"
  | "rejected-self"
  | "rejected-other"
  | "pending-other"
  | "pending-self"
  | "incomplete-self"
  | "incomplete-other"
  | "awaiting-payment"
  | "paid"
  | "refunded"
  | "refund-request"
  | "cancel-request"
  | "refund-request-rejected"
  | "cancel-request-rejected"
  | "cancelled"
  | "refund-conflict"
  | "cancel-conflict"
  | "refund-ineligible"
  | "cancel-ineligible";

export type ExtensiveOfferState =
  | "Reject"
  | "Read"
  | "Archive"
  | "Delete"
  | "Spam"
  | "Block";


/**
* this method Offer Model
*/
export const OfferModel = types
  .model({
    id: types.identifierNumber,
    content_id: types.maybeNull(types.reference(ContentModel)),
    price: types.maybeNull(types.number),
    start: types.maybeNull(types.string),
    created_at: types.maybeNull(types.string),
    duration: types.maybeNull(types.number),
    exclusivity: types.maybeNull(types.number),
    bio_link: types.maybeNull(types.string),
    identity_id: types.number,
    target_integration_id: types.number,
    target_identity_id: types.number,
    ad_id: types.maybeNull(types.reference(AdModel)),
    last_modification: types.maybeNull(LastModificationModel),
    last_message: types.maybeNull(types.frozen()),
    campaign_id: types.maybeNull(types.reference(CampaignModel)),
    version: types.number,
    chat_id: types.union(types.string),
    status: types.enumeration([
      "rejected",
      "pending",
      "awaiting-payment",
      "paid",
      "cancelled",
      "refunded",
    ]),

    state: types.maybeNull(
      types.enumeration([
        "reject",
        "read",
        "archive",
        "delete",
        "spam",
        "block",
        "unread"
      ])
    ),

    nullification_type: types.maybeNull(
      types.enumeration(["refund", "cancel"])
    ),
    nullification_initiator: types.maybeNull(
      types.enumeration(["brand", "influencer"])
    ),
    nullification_status: types.maybeNull(
      types.enumeration([
        "requested",
        "rejected",
        "nullified",
        "conflict",
        "ineligible",
        "forced",
      ])
    ),
    updated_at: types.maybeNull(types.string),
  })
  .volatile((self) => ({
    history: null,
  }))
  .views((self) => ({
    getLatestPublishJob(): IPublishJobModel | null {
      let results = RootStore.publishJobStore.getJobsByOffer(self.id);
      if (results.length) {
        return results.sort(
          (x: IPublishJobModel, y: IPublishJobModel): number => y.id - x.id
        )[0];
      }
      return null;
    },

    isComplete() {
      return (
        self.price !== null &&
        self.content_id !== null &&
        // self.exclusivity !== null &&
        self.start !== null &&
        self.duration !== null
      );
    },

    isEmpty() {
      return (
        self.price == null &&
        self.content_id == null &&
        // self.exclusivity == null &&
        self.start == null &&
        self.duration == null
      );
    },
    getPublishTime(): moment.Moment {
      return moment(self.start).add(self.duration, "seconds");
    },

    isJustMessage() {
      return !(
        self.price != null ||
        self.content_id != null ||
        // self.exclusivity != null ||
        self.start != null ||
        self.duration != null
      );
    },

    getIdentity(): IIdentityModel {
      let models = RootStore.identityStore.identities.filter(
        (identity: IIdentityModel) => identity.id === self.identity_id
      );
      if (models.length === 0) {
        // eslint-disable-next-line
        throw `Could not resolve identity ${self.identity_id}`;
      }
      return models[0];
    },

    getTargetIdentity(): IIdentityModel {
      let models = RootStore.identityStore.identities.filter(
        (identity: IIdentityModel) => identity.id === self.target_identity_id
      );
      if (models.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw "Could not resolve target identity " + self.target_identity_id;
      }
      return models[0];
    },

    getTargetIntegration(): IIntegrationModel | null {
      let models = RootStore.integrationStore.integrations.filter(
        (integration: IIntegrationModel) =>
          integration.id === self.target_integration_id
      );
      if (models.length === 0) {
        return null;
      }
      return models[0];
    },
  }))
  .views((self) => ({
    getPossibleActions(): PossibleOfferActions[] {
      switch (self.status) {
        case "pending":
          if (self.last_modification == null) {
            if (self.getIdentity().user_id === RootStore.users.currentUser.id) {
              return ["edit"];
            } else {
              if (self.isComplete()) {
                return ["accept", "counter", "reject"];
              } else {
                return ["reject", "complete"];
              }
            }
          } else if (self.last_modification.isSelf()) {
            if (self.isComplete()) {
              if (self.last_modification.status === "rejected") {
                return ["counter"];
              } else {
                // pending
                return ["edit" /*, "terminate"*/];
              }
            } else {
              return ["reject", "complete"];
            }
          } else {
            if (self.isComplete()) {
              if (self.last_modification.status === "rejected") {
                return ["counter"];
              } else {
                // pending
                return ["accept", "reject", "counter"];
              }
            } else {
              return ["reject", "complete"];
            }
          }
        case "awaiting-payment":
          return ["pay", "revise", "reject"];
        case "paid":
          // check publish job
          return [
            /*"terminate"*/
          ];
        case "rejected":
          if (self.last_modification.isSelf()) {
            return ["counter", "accept"];
          } else {
            return ["counter"];
          }
      }
    },
    getExtensiveState(): ExtensiveOfferState {
      switch (self.state) {
        case "reject":
          return "Reject";
        case "read":
          return "Read";
        case "archive":
          return "Archive";
        case "delete":
          return "Delete";
        case "spam":
          return "Spam";
        case "block":
          return "Block";
      }
    },

    getExtensiveStatus(): ExtensiveOfferStatus {
      switch (self.status) {
        case "pending":
          if (self.last_modification == null) {
            if (self.isEmpty()) {
              return "no-offer";
            } else {
              if (
                self.getIdentity().user_id === RootStore.users.currentUser.id
              ) {
                if (self.isComplete()) {
                  return "pending-other";
                } else {
                  return "incomplete-self";
                }
              } else {
                if (self.isComplete()) {
                  return "pending-self";
                } else {
                  return "incomplete-other";
                }
              }
            }
          } else if (self.last_modification.isSelf()) {
            if (self.isComplete()) {
              return "pending-other";
            } else {
              return "incomplete-self";
            }
          } else {
            if (self.isComplete()) {
              return "pending-self";
            } else {
              return "incomplete-other";
            }
          }
        case "rejected":
          if (self.last_modification.isSelf()) {
            return "rejected-self";
          } else {
            return "rejected-other";
          }
        case "awaiting-payment":
          switch (self.nullification_status) {
            case null:
              return self.status;
            case "requested":
              return "cancel-request";
            case "rejected":
              return "cancel-request-rejected";
            case "nullified":
              return "cancelled";
            case "conflict":
              return "cancel-conflict";
            case "ineligible":
              return "cancel-ineligible";
            case "forced":
              return "cancelled";
          }
          break;
        case "refunded":
          return self.status;
        case "paid":
          switch (self.nullification_status) {
            case null:
              return "paid";
            case "requested":
              return "refund-request";
            case "rejected":
              return "refund-request-rejected";
            case "nullified":
              return "refunded";
            case "conflict":
              return "refund-conflict";
            case "ineligible":
              return "refund-ineligible";
            case "forced":
              return "refunded";
          }
      }
    },

    getLastModifierIdentity(): IIdentityModel | null {
      if (self.last_modification === null) {
        return self.getIdentity();
      }

      if (self.last_modification.user_id === self.getIdentity().user_id) {
        return self.getIdentity();
      }
      if (self.last_modification.user_id === self.getTargetIdentity().user_id) {
        return self.getTargetIdentity();
      }
    },

    getOpponentIdentity(): IIdentityModel {
      if (self.getIdentity().user_id === RootStore.users.currentUser.id) {
        return self.getTargetIdentity();
      } else {
        return self.getIdentity();
      }
    },

    getMissingAttributesDescription(): string {
      let attr_list = [];
      if (self.price == null) {
        attr_list.push("price");
      }
      if (self.start == null) {
        attr_list.push("publish date");
      }
      if (self.duration == null) {
        attr_list.push("duration");
      }
      // if (self.exclusivity == null) {
      //   attr_list.push("exclusivity terms");
      // }
      return arrayToText(attr_list, (item) => item);
    },

    updateFields(data: any) {
      Object.keys(data).forEach((key) => {
        if (self.hasOwnProperty(key)) {
          if (key === "id") {
            return;
          }
          // @ts-ignore
          self[key] = data[key];
        }
      });
    },
  }))
  .views((self) => ({
    getHumanReadableStatus(): string {
      switch (self.getExtensiveStatus()) {
        case "refund-conflict":
        case "cancel-conflict":
          return "Conflict Resolution";
        case "refund-ineligible":
          return "Refund Ineligible";
        case "cancel-ineligible":
          return "Cancellation Ineligible";
        case "refund-request":
          return "Refund Request";
        case "cancel-request":
          return "Cancellation Request";
        case "refund-request-rejected":
          return "Refund Rejected";
        case "cancel-request-rejected":
          return "Cancellation Rejected";
        case "cancelled":
          return "Cancelled / Pending";
        case "refunded":
          return "Refunded";
        case "no-offer":
          return "Just Chatting";
        case "rejected-self":
          return !RootStore.users.currentUser.is_admin
            ? "Rejected by You"
            : "Rejected by Brand";
        case "rejected-other":
          return "Rejected by Influencer";
        case "pending-other":
          return "Waiting Approval";
        case "pending-self":
          return !RootStore.users.currentUser.is_admin
            ? "Waiting your Approval"
            : "Waiting Approval";
        case "incomplete-self":
        case "incomplete-other":
          return "in Negotiation";
        case "awaiting-payment":
          return "Waiting Payment";
        case "paid":
          if (self.getLatestPublishJob() !== null) {
            if (self.getLatestPublishJob().isFailed()) {
              if (self.getLatestPublishJob().state === "removal") {
                return "Failed to Remove";
              } else if (self.getLatestPublishJob().state === "published") {
                return "Failed to Publish";
              }
            } else {
              if (self.getLatestPublishJob().state === "scheduled") {
                return "Scheduled";
              } else if (self.getLatestPublishJob().state === "published") {
                return "Published";
              } else {
                return "Completed";
              }
            }
          }
          return "Pending Publish";
      }
    },

    getLastMessageStatus(): string {
      if (self.last_message?.attributes?.type) {
        switch (self.last_message.attributes.type) {
          case "offer:created":
            return "Offer has been created.";
          case "offer:updated":
            return "Offer has been updated.";
          case "offer:paid":
            return "Offer has been paid.";
          case "publish-job:published":
            return "Ad has been published.";
          case "publish-job:publishing-failed":
            return "Ad publishing failed!";
          case "publish-job:completed":
            return "Ad has been published and removed from IG.";
          case "publish-job:completion-failed":
            return "Ad has been published but failed to remove from IG!";
          case "offer:accepted":
            return "Offer has been accepted by influencer.";
          case "cancelled-intime":
            return "Offer has been cancelled.";
          case "cancel-request":
            return "You have requested to cancell the offer.";
          case "cancel-request-approved":
            return "Your cancellation request was approved.";
          case "cancel-request-rejected":
            return "Your cancellation request was rejected.";
          case "cancel-resolution":
            return "You have requested a conflict resolution.";
          case "cancel-ineligible":
            return "AInfluencer has ruled on the disputed.";
          case "late-cancelled":
            return "The influencer has cancelled the deal.";
          case "force-cancelled":
            return "AInfluencer has cancelled the deal.";
          case "refund-request":
            return "You have asked for a refund.";
          case "refund-request-approved":
            return "Your refund request has been approved.";
          case "refund-request-rejected":
            return "Your refund request has been rejected approved.";
          case "refund-resolution":
            return "You have requested a conflict resolution.";
          case "refund-ineligible":
            return "AInfluencer has ruled in the influencer's favor.";
          case "force-refunded":
            return "AInfluencer has refunded the offer.";
        }
      }
    },

    getStatusColor(): string {
      switch (self.getExtensiveStatus()) {
        case "cancelled":
          return "danger";

        case "no-offer":
        case "pending-other":
        case "pending-self":
        case "incomplete-other":
          return "info";

        case "refund-conflict":
        case "cancel-conflict":
        case "refund-ineligible":
        case "cancel-ineligible":
        case "refund-request":
        case "cancel-request":
        case "refund-request-rejected":
        case "cancel-request-rejected":
        case "rejected-self":
        case "rejected-other":
          return "warning";

        case "incomplete-self":
        case "awaiting-payment":
          return "primary";

        case "paid":
          if (self.getLatestPublishJob() !== null) {
            if (self.getLatestPublishJob().isFailed()) {
              return "danger";
            } else {
              return "success";
            }
          }
          return "success";
      }
    },

    getStateColor(): string {
      switch (self.getExtensiveState()) {
        case "Reject":
          return "danger";
        case "Read":
          return "primary";
        case "Archive":
          return "dark";
        case "Delete":
          return "danger";
        case "Spam":
          return "dark";
        case "Block":
          return "danger";
      }
    },
  }))
  .actions((self) => ({
    setLastMessage(lastMessage: any) {
      self.last_message = lastMessage;
    },

    getDialogue(): IDialogueModel {
      let previousDlgModels: IDialogueModel[] =
        RootStore.chatStore.dialogueList.filter(
          (dlg: IDialogueModel) => dlg.id === self.chat_id
        );
      if (previousDlgModels.length) {
        return previousDlgModels[0];
      }

      let model = DialogueModel.create({
        messages: [],
        isTyping: false,
        typingUserId: null,
        id: self.chat_id,
      });
      model.setOffer(self as IOfferModel);
      RootStore.chatStore.addDialogue(model);
      return model;
    },

    getHistory(): AbstractOfferHistory {
      if (!self.history) {
        self.history = new OfferHistory(self as IOfferModel);
      }
      return self.history;
    },

    setContent(model: IContentModel) {
      self.content_id = model;
    },
  }));

export interface IOfferModel extends Instance<typeof OfferModel> {}
export interface IOfferModelSnapshotIn extends SnapshotIn<typeof OfferModel> {}
export interface IOfferModelSnapshotOut
  extends SnapshotOut<typeof OfferModel> {}
