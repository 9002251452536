import React, { Component } from 'react';
import { Row, FormGroup, Input, Button, Col } from 'reactstrap';

interface Props {
    searchUsers: any;
    applychanges: any;
}

export default class WalletReportSearch extends Component<Props, any> {
    render() {
        return (
            <>
                <Row>
                    <Col lg={4}>
                        <FormGroup className="w-100">
                            <Input
                                onChange={this.props.searchUsers}
                                type="text"
                                name="email"
                                id="exampleEmail"
                                placeholder="Search by name or email"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <Button onClick={this.props.applychanges} className="mt-2" outline color="primary">
                            Apply Filter
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}
