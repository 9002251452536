import React from "react";
import { Redirect, RouteComponentProps, Link } from "react-router-dom";
import RootStore from "../../store/RootStore";
import Img from "../../assets/img/wrongLoginDeskTop.svg";
import { AppStoreBtnIcon, GooglePlayBtnIcon } from "../../assets/icons";
import logo from "../../assets/img/logo.png";

export default function WrongLogin(props: RouteComponentProps) {
  const goLogin = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    RootStore.users.logOut();
  }

  const goRegister = async (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    await RootStore.users.logOut(false,"/register");
  }
  return RootStore.users.isLoggedIn() ? (
    <div className="wrongLogin">
      <div>
        <img src={Img} alt="ainfluencer" />
      </div>
      <a href="https://ainfluencer.com">
        <img src={logo} alt="ainfluencer" />
      </a>
      <h1>
        Hello{" "}
        {RootStore.users.currentUser
          ? `${RootStore.users.currentUser.first_name}`
          : null}, 
      </h1>
      <p>
      Please launch or download the Ainfluencer app to browse sponsored post opportunities and connect with brands.
      </p>
      <p>
      Select one of the icons below to download the app now.
      </p>
      <div>
        <a href="https://apps.apple.com/us/app/id1525128818">
          <AppStoreBtnIcon />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.ainfluencer">
          <GooglePlayBtnIcon />
        </a>
      </div>
      <div>
        <p>
          Are you looking to sell your products or services through the
          marketplace?
        </p>
        <p>
          <span
            onClick={goRegister}
          >
            Register here
          </span>
          {/* <Link
            to="/register"
            onClick={goRegister}
            className="wrongLogin_login-here"
          >
            Register here
          </Link> */}
          {" "}
          to create an account and post a free Ad.
        </p>
        <p>
          Have a brand account ?  
          <Link
            to="/login"
            onClick={goLogin}
            className="wrongLogin_login-here"
          >
            {' '} Login here
          </Link>
        </p>
      </div>
      <Link
        to="/login"
        onClick={goLogin}
      >
        Go Back
      </Link>
    </div>
  ) : (
    <Redirect to={"/login"} />
  );
}
