import * as React from "react";
import { Spinner } from "reactstrap";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import { observer } from "mobx-react";

import {
  IMessageModel,
  MessageType,
} from "../../../../store/models/MessageModel";
import RootStore from "../../../../store/RootStore";
import {
  arrayGroupBy,
  hideSensitiveData,
  ucfirst,
  imageURLRegex,
  videoURLRegex,
} from "../../../../lib/Utils";
import OfferBubble from "./OfferBubble";
import profileImg from "../../../../assets/img/profilePlaceholder.jpg";
// import { messaging } from "firebase";

interface Props extends RouteComponentProps {
  self: boolean;
  messages: IMessageModel[];
  offerButtons: Function;
  performAction: Function;
  offer?: any
  offerStatus: any
}

@observer
export default class BubbleGroup extends React.Component<Props> {
  private static bubbleCounter: number = 0;
  public url = profileImg;
  getCardFooter(msg: IMessageModel) {
    let contents: JSX.Element[] = [];

    if (msg.offerButtonsVisible()) {
      contents.push(
        <React.Fragment key={"msg-controls" + msg.id}>
          {contents.length > 0 ? (
            <React.Fragment>
              &nbsp;
              <br />
              <br />
              &nbsp;
            </React.Fragment>
          ) : null}
          {this.props.offerButtons()}
        </React.Fragment>
      );
    } else {
      if (msg.getStatus() === "accepted") {
        contents.push(
          <div className={"offer-badge success"} key={"msg-accepted" + msg.id}>
            Accepted by{" "}
            <b>
              {msg.getReactor().user_id === RootStore.users.currentUser.id
                ? "You"
                : msg.getReactor().name}
            </b>{" "}
            {msg.getMoment().fromNow()}
          </div>
        );
      } else {
        contents.push(
          <div className={"offer-badge"} key={"msg-rejected" + msg.id}>
            {ucfirst(msg.getAction())} by{" "}
            <b>
              {msg.getReactor().user_id === RootStore.users.currentUser.id
                ? "You"
                : msg.getReactor().name}
            </b>{" "}
            {msg.getMoment().fromNow()}
          </div>
        );
      }
    }

    return (
      <div style={{ textAlign: "center" }}>
        <br />
        {contents}
        <br />
      </div>
    );
  }

  checkForSensitiveData(msg: IMessageModel): JSX.Element | string {
    let message = msg.msg.includes("#")
      ? msg.msg.split(" ").map((item) => {
        if (item.startsWith("#")) {
          return (
            <a
              href={`http://instagram.com/explore/tags/${item.replace(
                "#",
                ""
              )}/`}
            >
              {"#" + hideSensitiveData(item.replace("#", "")).msg + " "}
            </a>
          );
        } else {
          return `${hideSensitiveData(item).msg} `;
        }
      })
      : hideSensitiveData(msg.msg).msg;

    return <>{message}</>;
  }

  getBubble(msg: IMessageModel): JSX.Element {
    let id = BubbleGroup.bubbleCounter++;
    switch (msg.getType()) {
      case MessageType.SIMPLE:
        return imageURLRegex.test(msg.msg) ? (
          <div className="image-bubble" key={"bbl" + id}>
            {!msg.isSent ? (
              <Spinner color="primary" />
            ) : (
              <img src={msg.msg} alt={msg.msg} />
            )}
          </div>
        ) : videoURLRegex.test(msg.msg) ? (
          <div className="video-bubble" key={"bbl" + id}>
            {!msg.isSent ? (
              <Spinner color="primary" />
            ) : (
              <video width="300" height="300" controls>
                <source src={msg.msg} type={msg.attributes.type} />
              </video>
            )}
          </div>
        ) : (
          <div className={"bubble pre-wrap"} key={"bbl" + id}>
            {this.props.offerStatus ==='paid' ? msg.msg : this.checkForSensitiveData(msg)}
            {!msg.isSent ? (
              <React.Fragment>
                &nbsp;&nbsp;
                <Spinner
                  color="primary"
                  size={"sm"}
                  style={{ verticalAlign: "middle" }}
                />
              </React.Fragment>
            ) : msg.isDelivered && msg.isRead ? (
              <span className="check double" />
            ) : (
              <span className="check double" />
            )}
          </div>
        );
      case MessageType.POST_OFFER:
      case MessageType.STORY_OFFER:
        return RootStore.offerStore.isBusy ? (
          <Spinner color="primary" key={"bbl" + id} />
        ) : (
          <OfferBubble
            msg={msg}
            key={"bbl" + id}
            offerButtons={this.props.offerButtons}
            performAction={this.props.performAction}
            postData={this.props?.offer}
          />
        );
      case MessageType.PUBLISH_JOB:
      case MessageType.STATUS_UPDATE:
        throw new Error("BubbleGroup does not support status update messages");
    }
  }

  render() {
    // console.log('BubbleGroup ',this.props)
    let groupComponents: JSX.Element[] = [];

    let groupList = arrayGroupBy(this.props.messages, (item) => {
      return moment(item.timestamp).format("YYYY-MM-DD_hh:mm A");
    });

    for (const key in groupList) {
      let group = groupList[key];

      groupComponents.push(
        <React.Fragment key={`bubble_${key}`}>
          <span>
            {(!this.props.self
              ? this.props.messages[0].getIdentity().name + ", "
              : "") + key.split("_").join(" ")}
          </span>
          {group.map((msg) => {
            return this.getBubble(msg);
          })}
        </React.Fragment>
      );
    }

    if (this.props.self) {
      return <div className={"self"}>{groupComponents}</div>;
    } else {
      return (
        <div className={"other"}>
          <div className={"profile-picture"}>
            <img
              style={{ width: "50px", height: "50px" }}
              alt={this.props.messages[0].getIdentity().name}
              src={
                this.props.messages[0].getIdentity().logo
                  ? this.props.messages[0]
                    .getIdentity()
                    .logo.getThumbnailUrl(50, 50)
                  : this.url
              }
            />
          </div>
          <div className={"bubble-list"}>{groupComponents}</div>
        </div>
      );
    }
  }
}
