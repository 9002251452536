import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
* this method Notification Template Model
*/
export const NotificationTemplateModel = types.model({
  id: types.number,
  name: types.string,
  title: types.string,
  body: types.string,
  html: types.string,
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
});

export interface INotificationTemplateModel
  extends Instance<typeof NotificationTemplateModel> {}

export interface INotificationTemplateModelSnapshotIn
  extends SnapshotIn<typeof NotificationTemplateModel> {}

export interface INotificationTemplateModelSnapshotOut
  extends SnapshotOut<typeof NotificationTemplateModel> {}
