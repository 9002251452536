import React, { Component } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import Measure, { BoundingRect } from 'react-measure';
import InviteInfluencersPopup from '../../../common-components/InviteInfluencersPopup';
import { Button } from 'reactstrap';

interface AdCreatedPageProps extends RouteComponentProps {}

interface State {
    dimensions: BoundingRect | undefined;
    showHashtagModal: boolean;
    hashtags: [];
}

export default class AdCreatedPage extends Component<AdCreatedPageProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            dimensions: {
                width: -1,
                height: -1,
                right: 0,
                bottom: 0,
                top: 0,
                left: 0,
            },
            showHashtagModal: false,
            hashtags: props.location.state?.hashtags,
        };
    }

    toggleHashtagModal = () => {
        this.setState({ showHashtagModal: !this.state.showHashtagModal });
    };

    render() {
        return (
            <>
                <InviteInfluencersPopup
                    {...this.props}
                    isOpen={this.state.showHashtagModal}
                    toggle={this.toggleHashtagModal}
                    hashtags={this.state.hashtags}
                />
                <Measure
                    bounds
                    onResize={(contentRect) => {
                        this.setState({ dimensions: contentRect.bounds });
                    }}
                >
                    {({ measureRef }) => (
                        <div className="adCreatedPage" ref={measureRef}>
                            <h1 style={{ fontSize: '4rem' }}>
                                <i style={{ verticalAlign: 'middle' }} className="simple-icon-check text-success" />
                            </h1>
                            <h1>Your Ad needs admin approval. Hang tight!</h1>
                            <p>
                                While waiting for approval, start inviting influencers which our AI-based Influence
                                engine helps you discover.
                            </p>

                            <Button className="btn btn-lg btn-secondary mt-4" onClick={this.toggleHashtagModal}>
                                <i style={{ verticalAlign: 'middle' }} className="simple-icon-magnifier" /> Explore and
                                Invite Influencers
                            </Button>

                            <NavLink to={'/app/ads'}>
                                <i style={{ verticalAlign: 'middle' }} className="simple-icon-arrow-left" /> Go back to
                                campaign home page
                            </NavLink>
                        </div>
                    )}
                </Measure>
            </>
        );
    }
}
