import React from "react";
import { RouteComponentProps } from "react-router";
import moment from "moment";

import FilterComponent from "../../common-components/Filter/FilterComponent";
import { Separator } from "../../../template/components/common/CustomBootstrap";
import RootStore from "../../../store/RootStore";
import { IOfferModel } from "../../../store/models/OfferModel";
import DealCard from "./components/DealCard";
import { Spinner } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import DealCardMobileView from "./components/DealCardMobileView";
import EmptySearchResult from "./components/EmptySearchResult";
import ClientSideFilterSet from "../../common-components/Filter/ClientSideFilterSet";
import {
  FilterType,
  Range,
} from "../../common-components/Filter/AbstractFilterSet";
import { isMobileAndTablet } from "../../../lib/Utils";
import { IAdModel } from "../../../store/models/AdModel";
import { ICampaignModel } from "../../../store/models/CampaignModel";
import { SortOrder, Pagination } from "../../../lib/QueryTypes";

interface Props extends RouteComponentProps<{}, {}, { adId?: string }> {
  offers: IOfferModel[];
}

interface State {
  filteredOffers: IOfferModel[];
  reachedEnd: boolean;
  pagination: Pagination;
  dataFetched: boolean;
  sort: { key: string; direction: SortOrder };
  loading: boolean;
}

interface IOfferModelFlattened {
  // to fix stupid typescript bug
  [key: string]: any;
  opIdentityId: number;
  adId: number;
  campaignId: number;
  offerStatus: string;
  adTitle: string;
  influencerName: string;
  lastMessage: string;
}

export default class DealsTab extends React.Component<Props, State> {
  private readonly filterSet: ClientSideFilterSet<
    IOfferModel,
    IOfferModelFlattened
  >;

  constructor(props: any) {
    super(props);
    this.state = {
      filteredOffers: [],
      reachedEnd: false,
      dataFetched: false,
      loading: false,
      pagination: {
        totalPages: null,
        page: 0,
        pageSize: 25,
      },
      sort: {
        key: "updated_at",
        direction: SortOrder.DESCENDING,
      },
    };

    this.filterSet = new ClientSideFilterSet<IOfferModel, IOfferModelFlattened>(
      {
        getModels: () => {
          return RootStore.offerStore.offers.filter(
            (offer: IOfferModel) =>
              offer.getHumanReadableStatus() === "Completed" ||
              offer.getHumanReadableStatus() === "Scheduled" ||
              offer.getHumanReadableStatus() === "Failed"
          );
        },
        flattenFunction: (offer: IOfferModel) => {
          return {
            opIdentityId: offer.getOpponentIdentity().id,
            adId: offer.ad_id.id,
            campaignId: offer.ad_id.campaign_id.id,
            offerStatus: offer.getHumanReadableStatus(),
            adTitle: offer.ad_id.title,
            influencerName: offer.getOpponentIdentity().name,
            lastMessage: offer.last_message.message,
          };
        },
        onFilteredCallback: (filteredOffers: IOfferModel[]) => {
          this.setState({
            filteredOffers,
          });
        },
        fuzzySearchKeys: ["influencerName", "lastMessage", "adTitle"],
      }
    );

    this.filterSet.addFilter({
      name: "opIdentityId",
      title: "Influencer",
      type: FilterType.simpleFilter,
      defaultValue: null,
      values: () => [null, ...RootStore.offerStore.getAllOpponentIdentities()],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === null) return "All";
        return RootStore.identityStore.getByIdentityId(
          parseInt(value.toString())
        ).name;
      },
    });

    this.filterSet.addFilter({
      name: "adId",
      title: "Ad",
      type: FilterType.simpleFilter,
      defaultValue: this.props.location.state?.adId
        ? this.props.location.state.adId
        : null,
      values: () => [
        null,
        ...RootStore.adStore.getAllAds().map((ad: IAdModel) => ad.id),
      ],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === null) return "All";
        return RootStore.adStore.getExistingAd(parseInt(value.toString()))
          .title;
      },
    });

    this.filterSet.addFilter({
      name: "offerStatus",
      title: "Status",
      type: FilterType.simpleFilter,
      defaultValue: null,
      values: () => [null, "Failed", "Scheduled", "Completed"],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === null) return "All";
        else if (value === "Completed") return "Published";
        else if (value === "Scheduled") return "Waiting for Publish";
        return value.toString();
      },
    });

    this.filterSet.addFilter({
      name: "campaignId",
      title: "Campaign",
      type: FilterType.simpleFilter,
      defaultValue: null,
      values: () => [
        null,
        ...RootStore.campaignStore.campaigns.map(
          (campaign: ICampaignModel) => campaign.id
        ),
      ],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === null) return "All";
        return RootStore.campaignStore.getExistingCampaign(
          parseInt(value.toString())
        ).title;
      },
    });
  }

  async loadMoreOffers() {
    if (!this.state.reachedEnd) {
      // this.setState({ dataFetched: false });
      let res = await RootStore.offerStore.fetchAllOffers(
        this.state.pagination,
        this.state.sort,
        !this.state.reachedEnd
      );
      this.setState({
        dataFetched: true,
        filteredOffers: RootStore.offerStore.offers,
        pagination: {
          pageSize: this.state.pagination.pageSize,
          //@ts-ignore
          totalPages: res.totalPages,
          //@ts-ignore
          page: res.page,
        },
        loading: false,
        reachedEnd: res.page === res.totalPages - 1,
      });
    }
  }

  async pageChange(page: number): Promise<void> {
    await this.setState({
      pagination: { ...this.state.pagination, page },
    });
    await this.loadMoreOffers();
    for (let offer of RootStore.offerStore.offers) {
      (async () => {
        await offer.getDialogue().fetchUnreadMessageCount();
      })();
    }

    this.filterSet.applyFilters();
  }

  async componentDidMount() {
    if (!RootStore.offerStore.isBusy)
      await RootStore.offerStore.fetchAllOffers(
        this.state.pagination,
        this.state.sort
      );

    for (let offer of RootStore.offerStore.offers) {
      (async () => {
        await offer.getDialogue().fetchUnreadMessageCount();
      })();
    }
    this.setState({
      dataFetched: true,
      filteredOffers: RootStore.offerStore.offers,
    });

    const havingFilters = RootStore.offerStore.filterSet;
    if (havingFilters) {
      Object.keys(havingFilters).map((filter) => {
        this.filterSet.setFilterValue(filter, havingFilters[filter]);
        return filter;
      });
    }

    this.filterSet.applyFilters();
  }

  render() {
    return (
      <>
        <FilterComponent filterSet={this.filterSet} />

        <Separator className="mb-4 mt-3" />
        <InfiniteScroll
          style={{ paddingTop: "25px" }}
          dataLength={this.state.filteredOffers.length}
          next={() => {
            if (!this.state.reachedEnd) {
              this.pageChange(this.state.pagination.page + 1);
            }
          }}
          hasMore={!this.state.reachedEnd}
          loader={
            <div className="table-loader">
              <Spinner color="primary" size={"lg"} className="spinner" />
            </div>
          }
          hasChildren={true}
        >
          {this.state.filteredOffers.length > 0 ? (
            <table className="dealsList">
              {!isMobileAndTablet() ? (
                <thead>
                  <tr>
                    <th>Influencer</th>
                    <th>Ad</th>
                    <th>Status</th>
                    <th>Starts in:</th>
                    <th>Actions</th>
                  </tr>
                </thead>
              ) : null}

              <tbody>
                {this.state.filteredOffers.length > 0
                  ? this.state.filteredOffers
                      .sort((a, b) => moment(b.updated_at).diff(a.updated_at))
                      .map((offer: IOfferModel) => (
                        <React.Fragment key={`dealCard_${offer.id}`}>
                          {!isMobileAndTablet() ? (
                            <DealCard
                              offer={offer}
                              key={`offer_${offer.id}`}
                              {...this.props}
                            />
                          ) : (
                            <DealCardMobileView
                              offer={offer}
                              key={`offer_${offer.id}`}
                              {...this.props}
                            />
                          )}

                          <tr className="offersList__spacer" />
                        </React.Fragment>
                      ))
                  : null}
              </tbody>
            </table>
          ) : (
            <EmptySearchResult />
          )}
        </InfiniteScroll>
      </>
    );
  }
}
