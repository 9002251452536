import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { DialogueModel, IDialogueModel } from "./models/DialogueModel";

export const ChatStore = types
  .model({
    dialogueList: types.array(DialogueModel),
  })
  .actions((self) => ({

    /**
    * this method set dialogue
    * @param dlg, this param get dialogue for set dialogue List this store
    */
    addDialogue(dlg: IDialogueModel) {
      self.dialogueList.push(dlg);
    },
  }));

export interface IChatStore extends Instance<typeof ChatStore> {}

export interface IChatStoreSnapshotIn extends SnapshotIn<typeof ChatStore> {}

export interface IChatStoreSnapshotOut extends SnapshotOut<typeof ChatStore> {}
