import React from "react";
import { Colxx } from "../../../template/components/common/CustomBootstrap";
import { RouteComponentProps } from "react-router";

import { observer } from "mobx-react";
import ScheduleContentForm from "./components/ScheduleContentForm";
import RootStore from "../../../store/RootStore";
import Swal from "sweetalert2";

interface Props extends RouteComponentProps<{ id: string }> {}

@observer
export default class ScheduleNewContentPage extends React.Component<Props> {
  componentWillMount(): void {
    if (RootStore.integrationStore.integrations.length === 0) {
      Swal.fire({
        title: "No IG added!",
        text: "In order to schedule posts or stories, you have to at least add one Instagram account!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "rgb(18, 171, 40)",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Add IG now...",
      })
        .then((result) => {
          if (result.value) {
            this.props.history.push(
              "/app/account/integrations/new-integration"
            );
          } else {
            this.props.history.goBack();
          }
        })
        .catch((e) => {});
    }
  }

  render() {
    return (
      <Colxx xxs="12" className="mb-4">
        {RootStore.integrationStore.integrations.length > 0 ? (
          <ScheduleContentForm {...this.props} />
        ) : null}
      </Colxx>
    );
  }
}
