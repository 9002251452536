import React from "react";
import moment from "moment";

import { CalendarToolbar } from "../../template/components/CalendarToolbar";

import { getDirection } from "../../template/helpers/Utils";

import { Calendar, momentLocalizer } from "react-big-calendar";
import { observer } from "mobx-react";
import RootStore from "../../store/RootStore";

const localizer = momentLocalizer(moment);

// const data = [
//     {
//         key: 0,
//         title: "3 posts",
//         allDay: true,
//         start: "Tue Jul 31 2018 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Thu Aug 02 2018 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 1,
//         title: "1 story",
//         start: "Mon Sep 10 2018 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Mon Sep 10 2018 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 2,
//         title: "5 posts",
//         start: "Sun Oct 14 2018 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Sun Oct 21 2018 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 3,
//         title: "1 post",
//         start: "Tue Nov 13 2018 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Tue Nov 13 2018 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 4,
//         title: "12 posts",
//         start: "Sun Dec 09 2018 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Fri Dec 14 2018 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 5,
//         title: "Marketing Conference",
//         start: "Sun Nov 04 2018 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Wed Nov 07 2018 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 6,
//         title: "1 post, 2 stories",
//         start: "Sat Dec 08 2018 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Sat Dec 08 2018 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 7,
//         title: "7 stories, 3 posts",
//         start: "Tue Dec 25 2018 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Fri Dec 28 2018 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 8,
//         title: "10 posts",
//         start: "Mon Jan 14 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Wed Jan 16 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 9,
//         title: "2 stories",
//         start: "Tue Feb 05 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Fri Feb 08 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 10,
//         title: "1 story",
//         start: "Fri Feb 22 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Sat Feb 23 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 11,
//         title: "10 stories",
//         start: "Sun Mar 10 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Sun Mar 17 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 12,
//         title: "2 stories",
//         start: "Wed Mar 27 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Thu Mar 28 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 13,
//         title: "1 post, 1 story",
//         start: "Sun Apr 14 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Mon Apr 15 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 14,
//         title: "3 stories",
//         start: "Mon Apr 22 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Fri Apr 26 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 15,
//         title: "2 posts",
//         start: "Wed May 08 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Thu May 09 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 16,
//         title: "2 stories",
//         start: "Tue May 14 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Thu May 16 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 17,
//         title: "12 posts",
//         start: "Sun Jun 09 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Sun Jun 23 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 18,
//         title: "3 posts, 5 stories",
//         start: "Tue Jul 09 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Sun Jul 14 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 19,
//         title: "10 posts, 12 stories",
//         start: "Wed Jul 17 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Thu Jul 18 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 20,
//         title: "1 story",
//         start: "Sat Aug 10 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Sun Aug 11 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 21,
//         title: "1 post",
//         start: "Sun Aug 18 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Sun Aug 25 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 22,
//         title: "7 stories",
//         start: "Fri Sep 13 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Sun Sep 15 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 23,
//         title: "2 posts",
//         start: "Tue Oct 08 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Wed Oct 09 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 23,
//         title: "3 posts",
//         start: "Mon Oct 21 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Sat Oct 26 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 24,
//         title: "1 post",
//         start: "Thu Nov 14 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Fri Nov 15 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 25,
//         title: "4 stories",
//         start: "Fri Nov 22 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Tue Nov 26 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 26,
//         title: "2 story, 2 posts",
//         start: "Sun Dec 08 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Mon Dec 09 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     },
//     {
//         key: 26,
//         title: "1 story",
//         start: "Tue Dec 24 2019 00:00:00 GMT+0300 (GMT+03:00)",
//         end: "Wed Dec 25 2019 00:00:00 GMT+0300 (GMT+03:00)"
//     }
// ];

export const CalendarWidget = observer(() => {
  // @ts-ignore
  return (
    <Calendar
      localizer={localizer}
      events={RootStore.publishJobStore.getCalendarData()}
      rtl={getDirection().isRtl}
      views={["month"]}
      components={{
        toolbar: CalendarToolbar,
      }}
    />
  );
});
