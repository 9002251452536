import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react";

import RootStore from "../../../../store/RootStore";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import { NotificationManager } from "../../../../template/components/common/react-notifications";
import { IUserModel } from "../../../../store/models/UserModel";
import { PasswordField } from "../../../common-components";

const EmailSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  passwordConfirmation: Yup.string()
    .required("Confirm your new password")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});
interface Props {
  isOpen: boolean;
  user: IUserModel;
  toggle: () => void;
}

function ChangePasswordForm(props: Props) {
  const { isOpen, toggle } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Change Password</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            id: props.user.id,
            password: "",
            passwordConfirmation: "",
          }}
          validationSchema={EmailSchema}
          onSubmit={async (values: any, actions: any) => {
            try {
              actions.setSubmitting(true);
              const editIdentityValues = {
                new_password: values.password,
              };
              await RootStore.users.changeUserPasswordByAdmin(
                props.user.id,
                editIdentityValues
              );
              NotificationManager.success(
                "Done",
                "Password changed successfully!",
                3000,
                null,
                null,
                "filled"
              );
              actions.setSubmitting(false);
              props.toggle();
            } catch (e) {
              actions.setSubmitting(false);
              handleError(e);
              ajaxErrorAlert("There was a problem in changing password.");
            }
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
          }) => (
            <Form
              translate={"no"}
              className="av-tooltip tooltip-label-right panel-form"
            >
              <FormGroup className="error-l-100">
                <Label>
                  <b>New Password</b>
                  {errors.password && touched.password && (
                    <div className="invalidFeedback">{errors.password}</div>
                  )}
                </Label>
                <PasswordField
                  id="password"
                  name="password"
                  className="form-control w-100"
                  onChange={setFieldValue}
                />
              </FormGroup>
              <FormGroup className="error-l-100">
                <Label>
                  <b>Confirm Password</b>
                  {errors.passwordConfirmation &&
                    touched.passwordConfirmation && (
                      <div className="invalidFeedback">
                        {errors.passwordConfirmation}
                      </div>
                    )}
                </Label>
                <PasswordField
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  placeholder="Password Confirmation"
                  className="form-control w-100"
                  onChange={setFieldValue}
                />
              </FormGroup>
              <hr />
              <Button
                style={{ float: "right" }}
                type="submit"
                color="success ml-1"
                disabled={isSubmitting}
              >
                change Password
              </Button>
              <Button
                style={{ float: "right" }}
                color="secondary"
                onClick={toggle}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
}

export default observer(ChangePasswordForm);
