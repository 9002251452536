import React, { useState } from "react";
import OnboardDiscoverSearch from "./parts/search";
import OnboardDiscoverForm from "./parts/form";
import CreatAdLayout from "../../layout";

function Discover() {
    const [state, setstate] = useState(1)

    return (
        <>
            <CreatAdLayout
                head={{
                    url: state === 1 ? "publish-terms" : () => setstate(1),
                    title: "Discover and invite influencers",
                    ...state === 1 && { paragraph: "Enter a few hashtags or use our hashtag search tool to give our AI engine some clues for the types of influencers that you are looking for." }
                }}
            >
                {state === 1 ? <OnboardDiscoverSearch changeStep={() => setstate(2)} /> : <OnboardDiscoverForm changeStep={() => setstate(1)} />}
            </CreatAdLayout>
        </ >
    );
}

export default Discover
