import React, { Component } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import TaskManager from "./components/TaskManager";

interface Props extends RouteComponentProps {}

export default class NotificationsTemplate extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/`} component={TaskManager} />
        <Redirect to="/error" />
      </Switch>
    );
  }
}
