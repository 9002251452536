import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

import { InfinityIcon } from "../../assets/icons";

export function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

interface Props {
  endsAt: number;
  isPermanent?: boolean;
}

export function CountDown(props: Props) {
  const [currentTime, setCurrentTime] = useState<number>(props.endsAt);
  const [timeHasBeeSet, setTimeHasBeenSet] = useState<boolean>(false);

  if (!timeHasBeeSet) {
    if (props.endsAt > moment.now()) {
      setCurrentTime(props.endsAt - moment.now());
    } else {
      setCurrentTime(0);
    }
    setTimeHasBeenSet(true);
  }

  useInterval(() => {
    if (currentTime > 0) {
      setCurrentTime(currentTime - 1000);
    }
  }, 1000);

  const aDay = 86400000,
    aHour = 3600000;

  let days = Math.floor(currentTime / aDay);
  let hours = Math.floor((currentTime - days * aDay) / aHour);
  let minutes = Math.floor((currentTime - days * aDay - hours * aHour) / 60000);
  let seconds = Math.floor(
    (currentTime - days * aDay - hours * aHour - minutes * 60000) / 1000
  );

  return (
    <div className="countDown">
      <div>
        {currentTime < 1000
          ? null
          : currentTime > 1000
          ? null
          : props.isPermanent
          ? "Permanent:"
          : "Starts in:"}
      </div>

      {props.isPermanent ? (
        <InfinityIcon />
      ) : (
        <p>
          {currentTime < 1000 ? (
            <span>Completed</span>
          ) : (
            <>
              <span>{days}d</span>
              <span>{hours < 10 ? `0${hours}` : hours}h</span>
              <span>{minutes < 10 ? `0${minutes}` : minutes}m</span>
              <span>{seconds < 10 ? `0${seconds}` : seconds}s</span>
            </>
          )}
        </p>
      )}
    </div>
  );
}
