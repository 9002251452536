import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, FormGroup, Label } from 'reactstrap';
import * as Yup from 'yup';
import Cleave from 'cleave.js/react';
import { FormikHelpers } from 'formik/dist/types';
import CardValidator from 'card-validator';
import { ajaxErrorAlert } from '../../../../lib/Utils';
import RootStore from './../../../../store/RootStore';




export const cardValidationSchema = Yup.object().shape({
    number: Yup.string().required("The card's number is required!")
            .test('number',"The card's duplicated",(number) => {
                const addingCard = number.substring(number.length-4)
                const listOfCard = RootStore.users.currentUser.wallet.methods.map(item=>item.data?.last4)
                
                return !listOfCard.includes(addingCard)
        }),
    cvc: Yup.string()
        .required("The card's CVC is required!")
        .matches(/^[0-9]{3,4}$/, "The card's CVC is not valid.")            
        ,
    exp_month: Yup.string()
        .required("The card's expiry month is required!")
        .matches(/^[0-9]{2}$/, 'Enter a valid month expire!'),
    exp_year: Yup.number()
        .required("The card's expiry year is required!")
        .moreThan(new Date().getFullYear() - 2001, "The card's expiry year is not valid."),
});

interface Props {
    onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
    submitText: string;
}

export default function AddNewCardForm(props: Props) {
    return (
        <Formik
            initialValues={{
                number: '',
                cvc: '',
                exp_month: '',
                exp_year: '',
            }}
            validationSchema={cardValidationSchema}
            onSubmit={(values, actions) => {
                const { number } = values
                const addingCard = number.substring(number.length-4)
                const listOfCard = RootStore.users.currentUser.wallet.methods.map(item=>item.data?.last4)

                if(!listOfCard.includes(addingCard)){
                    actions.setSubmitting(true);
                    values.number = values.number.split(' ').join('').split('-').join('');

                    if (CardValidator.number(values.number).isValid) {
                        props.onSubmit(
                            {
                                number: values.number,
                                cvc: values.cvc,
                                exp_month: parseInt(values.exp_month),
                                exp_year: parseInt('20' + values.exp_year),
                            },
                            actions,
                        );
                    } else {
                        ajaxErrorAlert('Invalid credit card number! Please check the credit card number that you entered.');
                    actions.setSubmitting(false);

                    }

                }else{
                    actions.setSubmitting(false);
                }

            }}
        >
            {({
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                isSubmitting,
            }) => (
                <Form
                    translate={'no'}
                    className="av-tooltip tooltip-label-right w-100 d-flex flex-column"
                    onSubmit={handleSubmit}
                >
                    <FormGroup className="error-l-100 mt-4">
                        <Label className="position-relative">
                            Card Number:
                            {errors.number && touched.number && (
                                <div className="invalidFeedback d-block">{errors.number}</div>
                            )}
                        </Label>
                        <Cleave
                            className="form-control"
                            name="number"
                            placeholder="Enter your credit card number"
                            options={{ creditCard: true, delimiter: ' - ' }}
                            onFocus={(ev) => {
                                setFieldTouched('number');
                            }}
                            onChange={(ev) => {
                                setFieldValue('number', ev.target.value.replace('-', '').replace(' ', ''));
                            }}
                        />
                        <div className="d-flex justify-content-end mt-2">
                            <img alt="accepting_cards" src="/assets/img/all_cards.jpeg" className="w-40" />
                        </div>
                    </FormGroup>

                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <FormGroup className="error-l-100">
                            <Label className="position-relative">
                                Exp Date:
                                {errors.exp_month && touched.exp_month && (
                                    <div className="invalidFeedback d-block">{errors.exp_month}</div>
                                )}
                                {errors.exp_year && touched.exp_year && (
                                    <div className="invalidFeedback d-block">{errors.exp_year}</div>
                                )}
                            </Label>
                            <div className="d-flex justify-content-between">
                                <Cleave
                                    className="form-control w-45"
                                    name="exp_month"
                                    placeholder="MM"
                                    options={{ date: true, datePattern: ['m'] }}
                                    onFocus={(ev) => {
                                        setFieldTouched('exp_month');
                                    }}
                                    onChange={(ev) => {
                                        setFieldValue('exp_month', ev.target.value);
                                    }}
                                />

                                <Cleave
                                    className="form-control w-45"
                                    name="exp_year"
                                    placeholder="YY"
                                    options={{ date: true, datePattern: ['y'] }}
                                    onFocus={(ev) => {
                                        setFieldTouched('exp_year');
                                    }}
                                    onChange={(ev) => {
                                        setFieldValue('exp_year', ev.target.value);
                                    }}
                                />
                            </div>
                        </FormGroup>
                    </div>

                    <FormGroup className="error-l-100 w-30">
                        <Label className="position-relative">
                            CVC:
                            {errors.cvc && touched.cvc && <div className="invalidFeedback d-block">{errors.cvc}</div>}
                        </Label>
                        <Field
                            className="form-control"
                            name="cvc"
                            placeholder="CVC"
                            // type="number"
                            // pattern="\d*"
                            maxLength="4"
                        />
                    </FormGroup>

                    <Button type="submit" disabled={isSubmitting} className="mt-4" color="success">
                        {props.submitText}
                    </Button>

                    <h3 className="my-3">
                        <small className="text-muted">
                            Note: we are using stripe, the most secure and trusted payment processor.If you have any
                            questions about our products or services please contact us at{' '}
                            <a className="text-muted" href="mailto:support@ainfluencer.com">
                                <b>support@ainfluencer.com</b>
                            </a>
                        </small>
                    </h3>

                    <div className="d-flex justify-content-center">
                        <img alt="secure" src="/assets/img/trust_payment.png" className="w-45" />
                    </div>
                </Form>
            )}
        </Formik>
    );
}
