import {
  flow,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
  destroy,
} from "mobx-state-tree";
import Transport from "../lib/Transport";
// import { ajaxErrorAlert } from "../lib/Utils";
// import RootStore from "./RootStore";
import {
  NotificationTemplateModel,
  INotificationTemplateModel,
} from "../store/models/NotificationTemplateModel";
import { NotificationQueryBuilder } from "../views/admin/templates/components/NotificationQueryBuilder";

export const NotificationTemplateStore = types
  .model({
    notificationTemplates: types.array(NotificationTemplateModel),
  })
  .volatile((self) => ({
    isBusy: false,
  }))
  .actions((self) => ({

    /**
    * this method check processing for this store
    */
    setBusy() {
      self.isBusy = true;
    },

    /**
    * this method just set value isBusy = false
    */
    setIdle() {
      self.isBusy = false;
    },

     /**
    * this method push model this store
    * @param model, this param type object for get model
    */
    pushModel(model: INotificationTemplateModel) {
      self.notificationTemplates.push(model);
    },

     /**
    * this method clear notification templates
    */
    clearModel() {
      destroy(self.notificationTemplates);
    },
  }))
  .actions((self) => ({

    /**
    * this method fetch all notification templates
    */
    fetchAll: flow(function* () {
      self.setBusy();

      try {
        let res = yield Transport.get(
          "admin/notifications/templates",
          NotificationQueryBuilder({ page: 0, pageSize: 100 })
        );

        for (let notification of res.data["notification_templates"]) {
          let model = NotificationTemplateModel.create(notification);
          self.pushModel(model);
        }
        self.setIdle();
      } catch (e) {
        self.setIdle();
        throw e;
      }
    }),

    /**
    * this method update notifications templates
    * @param notificationId, this param notification ID for update
    * @param values, this param new values for update
    */
    update: flow(function* (notificationId: number, values: any) {
      self.setBusy();

      try {
        yield Transport.patch(
          `admin/notifications/templates/${notificationId}`,
          {
            notification_template: values,
          }
        );
        // applySnapshot(
        //   self.notificationTemplates.find(
        //     (notification) => notification.id === notificationId
        //   ),
        //   response.data["notification_template"]
        // );
      } catch (e) {
        self.setIdle();
        throw e;
      }
    }),
  }));
export interface INotificationTemplateStore
  extends Instance<typeof NotificationTemplateStore> {}

export interface INotificationTemplateStoreSnapshotIn
  extends SnapshotIn<typeof NotificationTemplateStore> {}

export interface INotificationTemplateStoreSnapshotOut
  extends SnapshotOut<typeof NotificationTemplateStore> {}
