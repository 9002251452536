import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import Transport from "../../../../lib/Transport";
import moment from "moment";
import { Bar, Pie } from "react-chartjs-2";
import { EyeIcon } from "../../../../assets/icons";
import { Separator } from "../../../../template/components/common/CustomBootstrap";
import DateRangeFormik from "./DateRangeFormik";

interface Props {}

interface State {
  from: String;
  to: String;
  invite: any;
  modalpreview: boolean;
  invitedetails: any;
  selected: Number;
  customdatemodalpreview: boolean;
  customfrom: any;
  customto: any;
  loading: boolean;
  loadingpopup: boolean;
  filter_searchinsdmtatus: any;
}

export default class InviteStatus extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      to: moment().format("YYYY-MM-DD HH:mm:ss"),
      from: moment().subtract(3, "day").format("YYYY-MM-DD HH:mm:ss"),
      invite: [],
      modalpreview: false,
      invitedetails: [],
      selected: 3,
      customdatemodalpreview: false,
      customfrom: null,
      customto: null,
      loading: false,
      loadingpopup: false,
      filter_searchinsdmtatus: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    let response = await Transport.post("invitations/statics", {
      from: this.state.from,
      to: this.state.to,
    });
    this.setState({ invite: response.data, loading: false });
  }

  async detailsday(item) {
    this.setState((prevState) => ({
      modalpreview: !prevState.modalpreview,
      loadingpopup: true,
    }));

    const res = await Transport.post("invitations/statics/day", {
      day: item,
    });

    this.setState(() => ({
      invitedetails: res.data,
      loadingpopup: false,
      filter_searchinsdmtatus: res.data,
    }));
  }

  async selectDay(day) {
    this.setState({
      from: moment().subtract(day, "day").format("YYYY-MM-DD HH:mm:ss"),
      to: moment().format("YYYY-MM-DD HH:mm:ss"),
      selected: day,
      loading: true,
    });

    let response = await Transport.post("invitations/statics", {
      from: moment().subtract(day, "day").format("YYYY-MM-DD HH:mm:ss"),
      to: moment().format("YYYY-MM-DD HH:mm:ss"),
    });

    this.setState({ invite: response.data, loading: false });
  }

  customdatemodal() {
    this.setState((prevState) => ({
      customdatemodalpreview: !prevState.customdatemodalpreview,
    }));
  }

  async customdate() {
    let from = moment(this.state.customfrom, "YYYY-MM-DD");
    let to = moment(this.state.customto, "YYYY-MM-DD");

    if (from.diff(to) >= 0) {
      alert("End date must be after start date");
      return null;
    }
    let fromstring = from.format("YYYY-MM-DD");
    let totring = to.format("YYYY-MM-DD");

    this.setState({
      from: fromstring,
      to: totring,
      customdatemodalpreview: false,
      invite: [],
      selected: 100,
      loading: true,
    });

    let response = await Transport.post("invitations/statics", {
      from: fromstring,
      to: totring,
    });

    this.setState({ invite: response.data, loading: false });
  }

  componentWillUnmount() {
    this.setState({
      invitedetails: "",
      invite: "",
    });
  }

  searchinsdmtatus(e) {
    let cfilter_searchinsdmtatus = [];
    this.state.invitedetails.filter((item: any) => {
      if (item.dm_status === e.target.value) {
        cfilter_searchinsdmtatus.push(item);
      }
      return null;
    });
    if (e.target.value === "all") {
      this.state.invitedetails.filter((item) => {
        return cfilter_searchinsdmtatus.push(item);
      });
    }
    this.setState({ filter_searchinsdmtatus: cfilter_searchinsdmtatus });
  }
  render() {
    const labels = this.state.invite
      .filter((item: any) => moment(item.sent_at).isBefore(moment(), "hour"))
      .map((item: any) => moment(item.sent_at).format("DD-MM-YYYY"))
      .filter((item: any, i: any, ar: any) => ar.indexOf(item) === i)
      .slice(0, 20);

    const data = {
      labels: [
        "inviteClicked",
        "SignedUp",
        "Verified",
        "AppVisit",
        "AppLogin",
        "ViewOffer",
        "AcceptOffer",
        "OfferMade",
        "Dealclosed",
      ],
      datasets: [
        {
          label: "# of Votes",
          data: [
            this.state.invite.filter(
              (invite: any) => invite.track === "inviteClicked"
            ).length,
            this.state.invite.filter(
              (invite: any) => invite.track === "SignedUp"
            ).length,
            this.state.invite.filter(
              (invite: any) => invite.track === "Verified"
            ).length,
            this.state.invite.filter(
              (invite: any) => invite.track === "AppVisit"
            ).length,
            this.state.invite.filter(
              (invite: any) => invite.track === "AppLogin"
            ).length,
            this.state.invite.filter(
              (invite: any) => invite.track === "ViewOffer"
            ).length,
            this.state.invite.filter(
              (invite: any) => invite.track === "AcceptOffer"
            ).length,
            this.state.invite.filter(
              (invite: any) => invite.track === "OfferMade"
            ).length,
            this.state.invite.filter(
              (invite: any) => invite.track === "Dealclosed"
            ).length,
          ],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
            "rgba(25, 59, 64, 0.2)",
            "rgba(255, 179, 164, 0.2)",
            "rgba(2, 19, 164, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(25, 59, 64, 1)",
            "rgba(255, 179, 164, 1)",
            "rgba(2, 19, 164, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
    return (
      <>
        <div className={"row"}>
          <div className={"col-12"}>
            <div className={"customBreadCrumb"}>
              <h1>Invitation Charts</h1>
            </div>
          </div>
        </div>
        {this.state.loading ? (
          <Spinner color="primary" size={"lg"} className="spinner" />
        ) : (
          <Card className="mb-4">
            <CardBody>
              <Row>
                <Col lg={12} style={{ textAlign: "right" }}>
                  <span
                    className={
                      this.state.selected === 3
                        ? "invitestatus_selected_day"
                        : ""
                    }
                    onClick={this.selectDay.bind(this, 3)}
                    style={{ padding: "5px" }}
                  >
                    3Days
                  </span>
                  -
                  <span
                    className={
                      this.state.selected === 7
                        ? "invitestatus_selected_day"
                        : ""
                    }
                    onClick={this.selectDay.bind(this, 7)}
                    style={{ padding: "5px" }}
                  >
                    Week
                  </span>
                  -
                  <span
                    className={
                      this.state.selected === 30
                        ? "invitestatus_selected_day"
                        : ""
                    }
                    onClick={this.selectDay.bind(this, 30)}
                    style={{ padding: "5px" }}
                  >
                    Month
                  </span>
                  -
                  <span
                    className={
                      this.state.selected === 100
                        ? "invitestatus_selected_day"
                        : ""
                    }
                    onClick={this.customdatemodal.bind(this)}
                    style={{ padding: "5px" }}
                  >
                    Custom
                  </span>
                </Col>
              </Row>
              <br />
              <hr />
              <br />
              <Row>
                <Col lg={7} className="publishjobs-chart">
                  <h4>DM Status</h4>

                  <Bar
                    type="bar"
                    options={{
                      scales: {
                        xAxes: [
                          {
                            gridLines: {
                              display: false,
                            },
                          },
                        ],
                      },
                    }}
                    data={{
                      labels: labels,
                      datasets: [
                        {
                          type: "line",
                          label: "sent",
                          data: labels.map(
                            (item: any) =>
                              this.state.invite.filter(
                                (invite: any) =>
                                  invite.dm_status !== "not_sent" &&
                                  moment(invite.sent_at).format(
                                    "DD-MM-YYYY"
                                  ) === item
                              ).length
                          ),
                          borderColor: "#BA1F33",
                        },

                        {
                          label: "pending",
                          data: labels.map(
                            (item: any) =>
                              this.state.invite.filter(
                                (invite: any) =>
                                  invite.dm_status === "pending" &&
                                  moment(invite.sent_at).format(
                                    "DD-MM-YYYY"
                                  ) === item
                              ).length
                          ),
                          borderColor: "#F2C14E",
                          backgroundColor: "#F2C14E",
                        },
                        {
                          label: "success",
                          data: labels.map(
                            (item: any) =>
                              this.state.invite.filter(
                                (invite: any) =>
                                  invite.dm_status === "success" &&
                                  moment(invite.sent_at).format(
                                    "DD-MM-YYYY"
                                  ) === item
                              ).length
                          ),
                          borderColor: "#5FAD56",
                          backgroundColor: "#5FAD56",
                        },
                        {
                          label: "failed",
                          data: labels.map(
                            (item: any) =>
                              this.state.invite.filter(
                                (invite: any) =>
                                  invite.dm_status === "failed" &&
                                  moment(invite.sent_at).format(
                                    "DD-MM-YYYY"
                                  ) === item
                              ).length
                          ),
                          borderColor: "#F78154",
                          backgroundColor: "#F78154",
                        },
                      ],
                    }}
                  />
                </Col>
                <Col lg={5}>
                  <h4>DM Details</h4>
                  <Pie data={data} />
                </Col>
              </Row>
              <br />
              <hr />
              <Row>
                <Col lg={6}>
                  <h4>Email Status</h4>

                  <Bar
                    type="bar"
                    options={{
                      scales: {
                        xAxes: [
                          {
                            gridLines: {
                              display: false,
                            },
                          },
                        ],
                      },
                    }}
                    data={{
                      labels: labels,
                      datasets: [
                        {
                          label: "Sent",
                          data: labels.map(
                            (item: any) =>
                              this.state.invite.filter(
                                (invite: any) =>
                                  invite.email_status === "success" &&
                                  moment(invite.sent_at).format(
                                    "DD-MM-YYYY"
                                  ) === item
                              ).length
                          ),
                          borderColor: "#5FAD56",
                          backgroundColor: "#5FAD56",
                        },
                      ],
                    }}
                  />
                </Col>

                <Col lg={6}>
                  <h4>Details</h4>
                  <Table striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Count</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {labels.map((item, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{index}</td>
                            <td>{item}</td>
                            <td>
                              {
                                this.state.invite.filter(
                                  (invite: any) =>
                                    moment(invite.sent_at).format(
                                      "DD-MM-YYYY"
                                    ) === item
                                ).length
                              }
                            </td>
                            <td>
                              <Button
                                data-for="Preview"
                                color="primary"
                                onClick={this.detailsday.bind(
                                  this,
                                  moment(item, "DD-MM-YYYY").format(
                                    "YYYY-MM-DD"
                                  )
                                )}
                                style={{
                                  padding: "4px 10px",
                                  background: "white",
                                  borderColor: "white",
                                }}
                              >
                                <EyeIcon
                                  style={{ width: "20px", fill: "black" }}
                                />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}

        <Separator className="mb-4 mt-3" />

        <Card className="py-4" style={{ marginBottom: 200 }}>
          <CardBody className="px-3">
            <DateRangeFormik />
          </CardBody>
        </Card>

        <Modal
          isOpen={this.state.modalpreview}
          toggle={() => this.setState({ modalpreview: false })}
          size="lg"
        >
          <ModalHeader toggle={() => this.setState({ modalpreview: false })}>
            Invite List
          </ModalHeader>
          <div style={{ padding: "10px 5px 2px 10px" }}>
            <label>DM-status</label>
            <select
              className="form-control"
              name="duration_type"
              onChange={(e) => this.searchinsdmtatus(e)}
              style={{ width: "140px" }}
            >
              <option value="all">All</option>
              <option value="not_sent">not_sent</option>
              <option value="pending">pending</option>
              <option value="success">success</option>
              <option value="failed">failed</option>
            </select>
          </div>
          <hr />

          {this.state.loadingpopup ? (
            <Spinner color="primary" size={"lg"} className="spinner" />
          ) : (
            <ModalBody>
              <div style={{ overflowX: "auto" }}>
                <Table striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>username/email</th>
                      <th>DM Status</th>
                      <th>Email Status</th>
                      <th>Track</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.filter_searchinsdmtatus.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index}</td>
                          <td>
                            username :{" "}
                            {item.target.integrations
                              ? item.target.integrations[0]
                                ? item.target.integrations[0].username
                                : ""
                              : ""}
                            <br />
                            email : {item.target.email}
                          </td>
                          <td>{item.dm_status}</td>
                          <td>{item.email_status}</td>
                          <td>{item.track}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </ModalBody>
          )}
        </Modal>

        <Modal
          isOpen={this.state.customdatemodalpreview}
          toggle={() => this.setState({ customdatemodalpreview: false })}
        >
          <ModalHeader
            toggle={() => this.setState({ customdatemodalpreview: false })}
          >
            Select Data
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={6}>
                <label htmlFor="">From :</label>
                <br />
                <input
                  type="date"
                  onChange={(e) =>
                    this.setState({ customfrom: e.target.value })
                  }
                />
              </Col>
              <Col lg={6}>
                <label htmlFor="">To :</label>
                <br />
                <input
                  type="date"
                  onChange={(e) => this.setState({ customto: e.target.value })}
                />
              </Col>
            </Row>
            <br />
            <br />
            <hr />
            <br />
            <Row>
              <Col style={{ textAlign: "center" }}>
                <Button onClick={() => this.customdate()}>Set Time</Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
