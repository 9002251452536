import {
  applySnapshot,
  flow,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
  destroy,
} from "mobx-state-tree";

import {
  IIntegrationModel,
  IIntegrationModelSnapshotIn,
  IntegrationModel,
} from "./models/IntegrationModel";
import instance from "./RootStore";
import RootStore from "./RootStore";
import Transport from "../lib/Transport";
import { FilterGroup } from "../lib/QueryTypes";
import {
  ajaxErrorAlert,
  // handleError,
  makeTempModelId,
  // showActivationModal,
  // showInfluencersSideHasNotBeenImplemented,
} from "../lib/Utils";
import { integrationQueryBuilder } from "../views/admin/users-managment/components/UserQueryBuilder";
import { FileModel } from "./models/FileModel";
import { defaultProfilePicture } from "../constants/defaultValues";
import ApiQueryBuilder from "../lib/ApiQueryBuilder";

export const IntegrationStore = types
  .model({
    integrations: types.array(IntegrationModel),
  })
  .volatile((self) => ({
    isBusy: false,
    fetchedFromServer: false,
  }))
  .actions((self) => ({
    /**
     * this method check processing for this store
     */
    setBusy() {
      self.isBusy = true;
    },

    /**
     * this method just set value isBusy = false
     */
    setIdle() {
      self.isBusy = false;
    },

    /**
     * this method push model this store
     * @param model, this param type object for get model
     */
    pushModel(model: IIntegrationModel) {
      self.integrations.push(model);
    },

    /**
     * this method for set Integrations
     * @param list, this param get Integrations model
     */
    setIntegrations(list: IIntegrationModel[]) {
      applySnapshot(instance.integrationStore, {
        integrations: list,
      });
    },

    /**
     * this method get Integrations by id
     * @param id, this param Integrations id
     */
    getById(id: number): IIntegrationModel {
      let list = self?.integrations?.filter(
        (integration) => integration.id === id
      );
      if (list.length) {
        return list[0];
      }
      // eslint-disable-next-line no-throw-literal
      throw "can not find integration with id " + id;
    },

    /**
     * this method just clear integrations
     */
    clearList() {
      self?.integrations?.clear();
    },

    syncMissingModels(models: IIntegrationModelSnapshotIn[]) {
      if (!Array.isArray(models)) {
        return;
      }

      let integrationIdList: number[] = self?.integrations?.map(
        (integration) => {
          return integration?.id;
        }
      );

      let possiblyUpdatedModels = models.filter(
        (model) => integrationIdList?.indexOf(model.id) >= 0
      );
      if (possiblyUpdatedModels.length) {
        for (let identity of self.integrations) {
          for (let updatedIntegration of possiblyUpdatedModels) {
            if (
              identity?.id === updatedIntegration?.id &&
              !identity?.identities
            ) {
              applySnapshot(identity, updatedIntegration);
            }
          }
        }
      }

      self?.integrations?.replace(
        self?.integrations?.concat(
          models
            .filter((model) => integrationIdList.indexOf(model.id) === -1)
            .uniqueById()
        )
      );
    },
  }))
  .actions((self) => ({
    /**
     * this method fetch all integrations
     * @param pagination, this param object for pagination integrations
     * @param filter_groups, this param object for filter integrations
     * @param SortOrder, this param object for sort integrations
     */
    fetchAll: flow(function* (
      pagination: { page: number; pageSize: number },
      filter_groups: FilterGroup[],
      SortOrder,
      search
    ) {
      self.setBusy();
      destroy(self?.integrations);
      try {
        let response = yield Transport.get(
          "integrations",
          integrationQueryBuilder(pagination, filter_groups, SortOrder, search)
        );

        for (let integration of response.data["integrations"]) {
          RootStore?.fileStore?.syncMissingModels([
            integration["picture"] ??
              FileModel.create({
                height: 64,
                width: 64,
                id: makeTempModelId(),
                label: "profile",
                type: "text/jpg",
                url: defaultProfilePicture,
              }),
          ]);
        }
        RootStore?.integrationStore?.syncMissingModels(
          response.data["integrations"]
        );
        if (response.data["identities"]) {
          RootStore?.identityStore?.syncMissingModels(
            response.data["identities"]
          );
        }
        RootStore?.users?.syncMissingModels(response.data["user"]);
        self.setIdle();
        return {
          pagination: {
            totalPages: response.data.meta.page_count,
            pageSize: response.data.meta.limit,
            page: response.data.meta.page,
            totalCount: response.data.meta.total_count,
          },
        };
      } catch (error) {
        self.setIdle();
        ajaxErrorAlert(error);
        throw error;
      }
    }),

    /**
     * this method fetch all integrations
     * and instead of globally saving it RETURN it
     */
    fetchAllV2: flow(function* (
      pagination: { page: number; pageSize: number },
      filter_groups,
      SortOrder,
      search
    ) {
      self.setBusy();
      destroy(self?.integrations);
      try {
        let query = new ApiQueryBuilder();
        query.addQueryParam("filters", filter_groups);
        query.addQueryParam("sorts", SortOrder);
        query.setSearch(search);
        query.setPagination(pagination.pageSize, pagination.page + 1);

        let response = yield Transport.get("v2/admin/integrations?", query);

        self.setIdle();
        return {
          integrations: response.data["result"],
          pagination: {
            totalPages: response.data.last_page,
            pageSize: response.data.per_page,
            page: response.data.current_page - 1,
            totalCount: response.data.total,
          },
        };
      } catch (error) {
        self.setIdle();
        ajaxErrorAlert(error);
        throw error;
      }
    }),

    /**
     * this method get integration by id
     * @param id, this param integration ID
     */
    getById(id: number): IIntegrationModel {
      let prev = self.integrations.filter(
        (integration) => integration.id === id
      );
      if (prev.length) {
        return prev[0];
      }
      // eslint-disable-next-line no-throw-literal
      throw "Could not find integration with id" + id;
    },

    /**
     * this method change password integration
     * @param model, this param integration model
     * @param password, this param new password
     */
    changePassword: flow(function* (
      model: IIntegrationModel,
      password: string
    ): Generator<any, void, any> {
      try {
        self?.setBusy();
        yield Transport.patch("integrations/" + model.id, {
          integration: {
            token: password,
          },
        });
        self.setIdle();
      } catch (e) {
        self.setIdle();
        throw e;
      }
    }),

    /**
     * this method is for activate or suspend integration
     * @param ig, this param integration model
     */
    actionOnUser: flow(function* (
      ig: any,
      body: any
    ): Generator<any, void, any> {
      self?.setBusy();
      try {
        yield Transport.put("v2/admin/integrations/" + ig, body);

        // RootStore.users.currentUser.removeIntegration(ig);
        self.setIdle();
      } catch (e) {
        self?.setIdle();
        throw e;
      }
    }),

    /**
     * this method delete integration
     * @param ig, this param integration model
     */
    delete: flow(function* (ig: IIntegrationModel): Generator<any, void, any> {
      self?.setBusy();
      try {
        yield Transport.delete("integrations/" + ig.id);

        RootStore.users.currentUser.removeIntegration(ig);
        self.setIdle();
      } catch (e) {
        self?.setIdle();
        throw e;
      }
    }),
    deleteV2: flow(function* (ig: any): Generator<any, void, any> {
      self?.setBusy();
      try {
        yield Transport.delete("v2/admin/integrations/" + ig);
        self.setIdle();
      } catch (e) {
        self?.setIdle();
        throw e;
      }
    }),
    restoreV2: flow(function* (ig: any): Generator<any, void, any> {
      self?.setBusy();
      try {
        yield Transport.patch("v2/admin/integrations/" + ig + "/restore", {});
        self.setIdle();
      } catch (e) {
        self?.setIdle();
        throw e;
      }
    }),

    /**
     * this method add new integration
     */
    addIntegration(snapshot: IIntegrationModelSnapshotIn): void {
      let model = IntegrationModel.create(snapshot);
      self?.integrations?.push(model);
      RootStore.users.currentUser.addIntegration(model);
    },

    alreadyAdded(username: string): boolean {
      let list = self?.integrations?.filter(
        (integration: IIntegrationModel) => {
          return integration.isSelf();
        }
      );
      for (let ig of list) {
        if (ig.username === username) {
          return true;
        }
      }
      return false;
    },

    alreadyIntegrated(username: string): boolean {
      let list = self?.integrations?.filter(
        (integration: IIntegrationModel) => {
          return integration.isSelf();
        }
      );
      for (let ig of list) {
        if (ig.username === username && ig.authenticated) {
          return true;
        }
      }
      return false;
    },
  }))
  .views((self) => ({
    /**
     * this method get Integrations user logined
     */
    getMyIntegrations(): IIntegrationModel[] {
      return self?.integrations?.filter((integration: IIntegrationModel) => {
        return integration?.isSelf();
      });
    },

    /**
     * this method get Usable Integrations
     */
    getUsableIntegrations(): IIntegrationModel[] {
      return self?.integrations?.filter((integration: IIntegrationModel) => {
        return integration?.isSelf() && integration?.authenticated;
      });
    },

    /**
     * this method get Integrations by username
     * @param username, this param get username
     */
    getByUsername(username: string): IIntegrationModel {
      return self?.integrations?.filter(
        (integration: IIntegrationModel) => integration.username === username
      )[0];
    },

    /**
     * this method get Integrations by user id
     * @param user_id, this param get user ID
     */
    getByUserId(user_id: number): IIntegrationModel {
      return self?.integrations.filter(
        (integration: IIntegrationModel) => integration?.id === user_id
      )[0];
    },
  }))
  .views((self) => ({
    getUnhealthyIntegrations(): IIntegrationModel[] {
      return self.getMyIntegrations().filter((int) => int.hasError());
    },
  }));

export interface IIntegrationStore extends Instance<typeof IntegrationStore> {}

export interface IIntegrationStoreSnapshotIn
  extends SnapshotIn<typeof IntegrationStore> {}

export interface IIntegrationStoreSnapshotOut
  extends SnapshotOut<typeof IntegrationStore> {}
