import React, { Component, GetDerivedStateFromProps } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import { Button, ButtonGroup, FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import moment, { Moment } from 'moment';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { FormikDatePicker } from '../../../common-components';
import { momentDateTimeFormat } from '../../../../lib/Utils';

interface Props extends FormikValues {
    value?: Schedule[];
    onChange: Function;
}

export type Schedule = {
    start: Moment;
    end: Moment | null;
    id?: number;
};

interface State {
    schedule_list: Schedule[];
    modal: 'closed' | 'interval' | 'fixed';
    dataFetched: boolean;
}

const IntervalSchema = Yup.object().shape({
    start: Yup.date()
        .min(new Date(), 'The selected schedule must be from this moment on')
        .nullable()
        .required('Date required'),
    end: Yup.date()
        .min(new Date(), 'The selected schedule must be from this moment on')
        .nullable()
        .when(['start'], (start: any, schema: any) => start && schema.min(start, 'End date must be after start date')),
    // .when(
    //   ["start"],
    //   (start: any, schema: any) =>
    //     start && schema.min(Date.now(), "The selected time must be from this moment on")
    // )
});

@observer
export default class ScheduleSelectFormik extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            schedule_list: [],
            modal: 'closed',
            dataFetched: false,
        };

        this.addSchedule = this.addSchedule.bind(this);
        this.addInterval = this.addInterval.bind(this);
        this.addFixed = this.addFixed.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props: Props, state: State) => {
        if (props.value && !state.dataFetched) {
            let processed: Schedule[] = [];
            for (let stamp of props.value) {
                stamp.start = moment(stamp.start, momentDateTimeFormat);
                if (stamp.end) {
                    stamp.end = moment(stamp.end, momentDateTimeFormat);
                }
                processed.push(stamp);
            }

            processed = processed.concat(state.schedule_list);

            let uniqueList: Schedule[] = [];
            for (let item of processed) {
                let found = false;
                for (let schedule_item of uniqueList) {
                    if (item.start.isSame(schedule_item.start)) {
                        if (item.end === null && schedule_item.end === null) {
                            found = true;
                        } else if (item.end !== null && schedule_item.end !== null) {
                            if (item.end.isSame(schedule_item.end)) {
                                found = true;
                            }
                        } else {
                            found = false;
                        }
                    }
                }
                if (!found) {
                    uniqueList.push(item);
                }
            }

            return {
                schedule_list: uniqueList,
                modal: state.modal,
                dataFetched: true,
            };
        } else {
            return state;
        }
    };

    addFixed() {
        this.setState({
            modal: 'fixed',
        });
    }

    addInterval() {
        this.setState({
            modal: 'interval',
        });
    }

    closeModal() {
        this.setState({
            modal: 'closed',
        });
    }

    handleChange() {
        this.props.onChange(this.props.name, this.state.schedule_list);
    }

    handleBlur() {
        this.props.onBlur(this.props.name, true);
    }

    addSchedule(values: any, actions: any) {
        var d = new Date();
        d.setHours(d.getHours(), d.getMinutes() + 5, 0, 0);
        if (values.start._d <= new Date()) {
            values.start._d = d;
        }

        if (!values.start) {
            alert('Please enter a date/time...');
            return;
        }

        if (this.state.modal === 'interval') {
            if (!values.end) {
                alert('Please enter the end date/time...');
                return;
            }
        }

        this.setState(
            (prevState: State) => ({
                schedule_list: [
                    ...prevState.schedule_list,
                    this.state.modal === 'interval'
                        ? {
                            start: values.start,
                            end: values.end,
                            id: null,
                        }
                        : {
                            start: values.start,
                            end: null,
                            id: null,
                        },
                ],
                modal: 'closed',
            }),
            () => {
                this.handleChange();
            },
        );
    }

   async  removeItem(item: Schedule) {
        let index = [...this.state.schedule_list].indexOf(item); // make a separate copy of the array

        if (index !== -1) {
            await this.setState((prevState) => ({
                schedule_list: prevState.schedule_list.filter((item: Schedule, idx: number) => index !== idx),
            }));

            this.handleChange();
        }
    }

    render() {
        return (
            <div className="schedule-select-field">
                <Modal isOpen={this.state.modal !== 'closed'} toggle={this.closeModal.bind(this)}>
                    <ModalHeader toggle={this.closeModal.bind(this)} style={{ padding: '1rem' }}>
                        {this.state.modal === 'fixed' ? 'Add fixed date/time' : 'Select a date range for your ad'}
                    </ModalHeader>

                    <ModalBody>
                        <Formik
                            initialValues={{
                                start: null,
                                end: null,
                            }}
                            validationSchema={IntervalSchema}
                            onSubmit={(values: any, actions: any) => {
                                this.addSchedule(values, actions);
                            }}
                        >
                            {({
                                handleSubmit,
                                setFieldValue,
                                setFieldTouched,
                                handleChange,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                isSubmitting,
                            }) => (
                                <Form translate={'no'} className="av-tooltip tooltip-label-right panel-form">
                                    <FormGroup className="error-l-100">
                                        <Label className=" position-relative" style={{ width: 'fit-content' }}>
                                            {this.state.modal === 'interval' ? 'Start date/time:' : 'Date/Time:'}
                                            {errors.start && touched.start && (
                                                <div className="invalidFeedback">{errors.start}</div>
                                            )}
                                        </Label>
                                        <FormikDatePicker
                                            name="start"
                                            value={values.start}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            // @ts-ignore
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            dateFormat="LLL"
                                            timeCaption="Time"
                                        />
                                    </FormGroup>
                                    {this.state.modal === 'interval' ? (
                                        <FormGroup className="error-l-100">
                                            <Label
                                                className="d-block position-relative"
                                                style={{ width: 'fit-content !important' }}
                                            >
                                                End date/time:
                                                {errors.end && touched.end && (
                                                    <div className="invalidFeedback">{errors.end}</div>
                                                )}
                                            </Label>
                                            <FormikDatePicker
                                                // ref={(ref) => (this.end = ref)}
                                                name="end"
                                                value={values.end}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                // @ts-ignore
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                dateFormat="LLL"
                                                timeCaption="Time"
                                            />
                                        </FormGroup>
                                    ) : null}
                                    <strong>
                                        NOTE: the time is your own local time
                                    </strong>
                                    <div className="schedule-select-field-buttons">
                                        <Button color="success" type="submit">
                                            Add
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </Modal>

                <ButtonGroup size="sm">
                    <Button color="primary" onClick={this.addFixed}>
                        Add fixed date/time
                    </Button>
                    <Button color="primary" onClick={this.addInterval}>
                        Add date/time interval
                    </Button>
                </ButtonGroup>

                {this.state.dataFetched && this.state.schedule_list && this.state.schedule_list.length > 0 ? (
                    <ul>
                        {this.state.schedule_list.map((item: Schedule, index: number) => {
                            let expired = false;

                            if (item.end && item.start !== item.end) {
                                expired = moment(item.end).isBefore(moment.now());
                            } else if (!item.end || item.start === item.end) {
                                expired = moment(item.end).isBefore(moment.now());
                            }

                            return (
                                <li key={'schedule_interval_' + index}>
                                    <div className={classNames('title', { date_expired: expired })}>
                                        <div>
                                            <span className="calendar-icon" />
                                            <span className="date">
                                                {moment(item.start).format('MMMM Do YYYY, h:mm a')}
                                            </span>
                                        </div>

                                        {item.end === null ||
                                            item.end === item.start ||
                                            moment(item.start).isSame(item.end) ? null : (
                                            <>
                                                <span className="arrow-icon" />
                                                <div>
                                                    <span className="calendar-icon" />
                                                    <span className="date">
                                                        {' '}
                                                        {moment(item.end).format('MMMM Do YYYY, h:mm a')}
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {expired && <span className="expired_tag">Expired</span>}
                                    <div
                                        className="remove"
                                        data-index={index}
                                        onClick={(e) => {
                                            this.removeItem(item);
                                        }}
                                    ></div>

                                </li>
                            );
                        })}
                    </ul>
                ) : null}
            </div>
        );
    }
}
