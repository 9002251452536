import React, { Fragment } from 'react';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

const SliderHandle = (props) => {
    const { value, dragging, index, offset, ...restProps } = props;
    const positionStyle = {
        position: 'absolute',
        left: `${offset}%`,
    };
    return (
        <Fragment key={index}>
            <div className="rc-slider-tooltip" style={positionStyle}>
                {value >= 1000 ? (value / 1000).toFixed(1).replace(/\.0$/, '') + 'M' : value + 'K'}
            </div>
            <Slider.Handle value={value} offset={offset} {...restProps} />
        </Fragment>
    );
};

const SliderHandle2 = (props) => {
    const { value, dragging, index, offset, ...restProps } = props;
    const positionStyle = {
        position: 'absolute',
        left: `${offset}%`,
    };
    return (
        <Fragment key={index}>
            <div className="rc-slider-tooltip" style={positionStyle}>
                {value + '%'}
            </div>
            <Slider.Handle value={value} offset={offset} {...restProps} />
        </Fragment>
    );
};

export class SliderTooltip extends React.Component {
    render() {
        const { handle } = this.props;

        return <Slider tipFormatter={(value) => `${value}%`} handle={handle || SliderHandle2} {...this.props} />;
    }
}

export class RangeTooltip extends React.Component {
    render() {
        return (
            <Range tipFormatter={(value) => `${value}%`} handle={this.props.handle || SliderHandle} {...this.props} />
        );
    }
}
