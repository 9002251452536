import React from 'react';
import TooltipLite from 'react-tooltip-lite';
import { truncate } from 'lodash';
import numbro from 'numbro';

// import RootStore from '../../store/RootStore';
import { IIdentityModel } from '../../store/models/IdentityModel';
import { IIntegrationModel } from '../../store/models/IntegrationModel';
import { LanguageIcon, MapPinIcon, PriceIcon, CategoryIcon } from '../../assets/icons';
// import { ICountryModel } from '../../store/models/CountryModel';
import profileImg from '../../assets/img/profilePlaceholder.jpg';
import { CountriesList } from './../../constants/countriesList'
import { minifyNum } from './../../lib/Utils'

interface IInfluencerProfileOverviewProps {
    identity: IIdentityModel;
    integration: IIntegrationModel;
}

export function InfluencerProfileOverview(props: IInfluencerProfileOverviewProps) {
    const { identity, integration } = props;
    const url = profileImg;
    const { language, country_id, quality_score, earnings } = identity || {
        language: '',
        quality_score: '',
        country_id: '',
        earnings: ''
    }
    const { followers, followings, engagement_rate } = integration || {
        followers: 0, followings: 0, engagement_rate: 0
    }

    const renderCountry = () => {
        const userCountryName = CountriesList?.find(item => item?.code === country_id)?.name
        return userCountryName
    }
    return (
        <TooltipLite
            mouseOutDelay={200}
            background="transparent"
            content={
                <div className="influencerProfileOverview">
                    <div>
                        <div>
                            <img
                                style={{ height: '75px', width: '75px' }}
                                src={identity.logo ? identity.logo.getThumbnailUrl(75, 75) : url}
                                alt="ainfluencer"
                            />
                            <a
                                href={`/app/influencer-explorer/${identity.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View Profile
                            </a>
                        </div>
                        {identity && <p>{identity.name ? truncate(identity.name, { length: 20 }) : 'No Name'}</p>}
                    </div>

                    <div className='influencerProfileOverview_influencerInfo'>
                        {language ? <span className="influencerProfileOverview_influencerInfo_span">
                            <LanguageIcon />
                            {language}
                        </span> : null}

                        {renderCountry() ? <span className="influencerProfileOverview_influencerInfo_span">
                            <MapPinIcon />
                            {renderCountry()}
                        </span> : null}

                        {quality_score ? <span className="influencerProfileOverview_influencerInfo_span">
                            <CategoryIcon />
                            {quality_score}
                        </span> : null}

                        {earnings ? <span className="influencerProfileOverview_influencerInfo_span">
                            <PriceIcon />
                            {earnings}
                        </span> : null}
                    </div>

                    <div>
                        <div>
                            <h3>
                                {minifyNum(followers)}
                            </h3>
                            <span>followers</span>
                        </div>
                        <div>
                            <h3>
                                {minifyNum(followings)}
                            </h3>
                            <span>following</span>
                        </div>
                        <div>
                            <h3>
                                {numbro(engagement_rate).format({
                                    mantissa: 2,
                                    optionalMantissa: true,
                                    output: 'percent',
                                })}
                            </h3>
                            <span>engagement</span>
                        </div>
                    </div>
                </div>
            }
        >
            <p id={`InfluencerOverviewToolTipToggler_${identity.id}`}>@{integration.username}</p>
        </TooltipLite>
    );
}
