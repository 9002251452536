import React from "react";
// @ts-ignore
import Sortable from "react-sortablejs";
import { Badge, Button, Card, CardBody, Spinner } from "reactstrap";
import { Colxx } from "../../../../../template/components/common/CustomBootstrap";
import {
  IMediaModel,
  MediaAction,
} from "../../../../../store/models/MediaModel";
import Swal from "sweetalert2";
import { IContentModel } from "../../../../../store/models/ContentModel";
import { getParent } from "mobx-state-tree";
import { counter } from "../../../../../lib/Utils";
import { NotificationManager } from "../../../../../template/components/common/react-notifications/index";
import { observer } from "mobx-react";
// @ts-ignore
import Img from "react-image";

interface Props {
  medias: IMediaModel[];
  onChange: Function;
  onEditRequest: (val: IMediaModel) => void;
  actionCallback: Function;
}

// Functional Component
const SortableMedia = observer(
  ({ medias, onChange, onEditRequest, actionCallback }: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let sortable: any = null; // sortable instance
    // const reverseOrder:any = (evt:Event) => {
    //     const order = sortable.toArray();
    //     onChange(order.reverse());
    // };

    const onDeleteRequest: Function = function (model: IMediaModel) {
      let parent: IContentModel = getParent(model, 2);

      let type = parent.type === "story" ? "story" : "post media";

      Swal.fire({
        title: "Are you sure?",
        text:
          "If you delete this " + type + ", it will not be available again!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          actionCallback(MediaAction.DELETE, model);
          parent.deleteMedia(model);

          NotificationManager.success(
            "Done",
            "The " + type + " was successfully deleted!",
            3000,
            null,
            null,
            "filled"
          );
        }
      });
    };

    const listItems = medias && medias.map((val: IMediaModel, index) => {
      let isStory = getParent<IContentModel>(val, 2).type === "story";

      return (
        <Colxx
          xxs="6"
          sm="3"
          md="2"
          className="mb-4 sortable-media"
          key={"sortable_" + index}
          data-id={val.id + counter}
        >
          <Card>
            <div className="position-relative">
              <Img
                src={
                  isStory
                    ? val.file.getThumbnailUrl(192, 293)
                    : val.file.getThumbnailUrl(195, 146)
                }
                loader={
                  <div
                    style={
                      isStory
                        ? { width: 192 * 2, height: 293 * 2 }
                        : { width: 195, height: 146 }
                    }
                  >
                    <Spinner color="primary" className="mb-1" />
                  </div>
                }
                className={"card-img-top"}
              />
              <Badge
                color="secondary"
                pill
                className="position-absolute badge-top-left"
              >
                {index + 1}
              </Badge>
            </div>
            <CardBody className="text-center card-controls">
              {isStory ? (
                <Button
                  type={"button"}
                  onClick={() => {
                    onEditRequest(val);
                  }}
                  color="info"
                  size={"small"}
                  className={"btn-circle"}
                >
                  <div className="glyph-icon simple-icon-pencil" />
                </Button>
              ) : null}
              <Button
                type={"button"}
                color="danger"
                size={"small"}
                onClick={() => {
                  onDeleteRequest(val);
                }}
                className={"btn-circle"}
                style={{ marginLeft: 5 }}
              >
                <div className="glyph-icon simple-icon-trash" />
              </Button>
            </CardBody>
          </Card>
        </Colxx>
      );
    });

    return (
      <div>
        {/*<button type="button" onClick={reverseOrder}>Reverse Order</button>*/}
        <Sortable
          className="row icon-cards-row mb-2"
          // Sortable options (https://github.com/RubaXa/Sortable#options)
          options={{}}
          // [Optional] Use ref to get the sortable instance
          // https://facebook.github.io/react/docs/more-about-refs.html#the-ref-callback-attribute
          ref={(c: any) => {
            if (c) {
              sortable = c.sortable;
            }
          }}
          // [Optional] The onChange method allows you to implement a controlled component and keep
          // DOM nodes untouched. You have to change state to re-render the component.
          // @param {Array} order An ordered array of items defined by the `data-id` attribute.
          // @param {Object} sortable The sortable instance.
          // @param {Event} evt The event object.
          onChange={(order: any[], sortable: any, evt: Event) => {
            let keys = [];
            for (let id of order) {
              keys.push(id - counter);
            }
            onChange(keys);
          }}
        >
          {listItems}
        </Sortable>
      </div>
    );
  }
);

export default SortableMedia;
