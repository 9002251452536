import React from 'react';

function YouTubePlayer(props) {
    const { embed } = props;
    return (
        <div className="youtube-video">
            <br />
            <h3>Best Practices on Ainfluencer to Attract Influencers to Grow your Business</h3>
            <div className="video-responsive">
                <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/${embed}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Ainfluencer"
                />
            </div>
        </div>
    );
}

export default YouTubePlayer;
