import { createContext } from "react";
interface Iprops {
    images: {
        list: any
        add: any
        remove: any
    }

}
const CreatAdStoryContext = createContext<Iprops>({
    images: {
        list: {},
        add: () => { },
        remove: () => { }
    }
})

export default CreatAdStoryContext