import React, { Fragment } from 'react';
import { Table, Card, CardBody, CardTitle, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Colxx } from '../../../../template/components/common/CustomBootstrap';
import { IIntegrationModel } from '../../../../store/models/IntegrationModel';
import profileImg from '../../../../assets/img/profilePlaceholder.jpg';
import { ICountryModel } from '../../../../store/models/CountryModel';
import { CountriesList } from './../../../../constants/countriesList';

interface Pagination {
    totalCount: number;
    pageSize: number;
    page: number;
    totalPages: number;
}

interface Props extends RouteComponentProps {
    users: IIntegrationModel[];
    pagination?: Pagination;
    fetchUsers: Function;
}

interface State {}

@observer
export default class UTMList extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const users = this.props?.users || [];

        return (
            <Fragment>
                <Card className="mb-4">
                    <CardBody>
                        <CardTitle>UTM List</CardTitle>
                        <Row>
                            <Colxx>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Email Address</th>
                                            <th>Name</th>
                                            <th>Created</th>
                                            <th>Country</th>
                                            <th>UTM_NAME</th>
                                            <th>UTM_SOURCE</th>
                                            <th>UTM_CONTENT</th>
                                            <th>UTM_TERM</th>
                                            <th>UTM_MEDIUM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users?.map((item: any, index: number) => {
                                            return (
                                                <tr key={`item__${item.id}`} className="cursor-pointer">
                                                    <td scope="row">
                                                        {this.props.pagination.totalCount -
                                                            this.props.pagination.page *
                                                                this.props.pagination.pageSize -
                                                            index}
                                                    </td>
                                                    <td>
                                                        <div className={'d-flex align-items-center'}>
                                                            <img
                                                                alt={'img'}
                                                                src={item.user?.identity?.logo?.url || profileImg}
                                                                className="rounded"
                                                                width={30}
                                                                height={30}
                                                            />
                                                            <div className="ml-1 text-muted cursor-pointer">
                                                                {item.user?.email}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{item.user?.full_name}</td>
                                                    <td>{item?.created_at}</td>
                                                    <td>
                                                        {CountriesList.find(
                                                            (country: ICountryModel) =>
                                                                country.id === item.user?.identity?.country_id,
                                                        )?.name || 'N/A'}
                                                    </td>
                                                    <td>{item.utm?.name}</td>
                                                    <td>{item.utm?.source}</td>
                                                    <td>{item.utm?.content}</td>
                                                    <td>{item.utm?.term}</td>
                                                    <td>{item.utm?.medium}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Colxx>
                        </Row>
                    </CardBody>
                </Card>
            </Fragment>
        );
    }
}
