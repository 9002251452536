import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { thumbnailApiPath } from "../../constants/defaultValues";

export const FileModel = types
  .model({
    id: types.identifierNumber,
    url: types.string,
    label: types.string,
    width: types.integer,
    type: types.string,
    height: types.integer,
  })
  .actions((self) => ({}))
  .views((self) => ({

    /**
    * this method get Thumbnail Url
    * @param width, this param width Thumbnail
    * @param height, this param width height
    */
    getThumbnailUrl(width: number, height: number) {
      console.log('LABEL ', self.label)
      if (self.type.startsWith("video/") || self.type.startsWith("image/")) {
        return (
          thumbnailApiPath +
          "/" +
          self.label +
          ".jpg?width=" +
          width +
          "&height=" +
          height
        );
      } else {
        // eslint-disable-next-line no-throw-literal
        throw `Can not generate thumbnails for ${self.type}`;
      }
    },
  }));

export interface IFileModel extends Instance<typeof FileModel> { }
export interface IFileModelSnapshotIn extends SnapshotIn<typeof FileModel> { }
export interface IFileModelSnapshotOut extends SnapshotOut<typeof FileModel> { }
