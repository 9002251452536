import React, { Component } from "react";
import { Link } from "react-router-dom";
import { truncate } from "lodash";

import { INotificationModel } from "../../store/models/NotificationModel";
import {
  SuccessNotificationIcon,
  FailNotificationIcon,
  UpdateNotificationIcon,
  DisconnectNotificationIcon,
} from "../../assets/icons";

interface Props {
  notification: INotificationModel;
  toggleDropDown: () => void;
}

export default class NotificationItem extends Component<Props> {
  render() {
    return (
      <div onClick={this.props.toggleDropDown}>
        <Link
          to={this.props.notification.getRedirectUrl()}
          className={`notificationItem ${
            this.props.notification.isRed() ? null : "unread"
          }`}
        >
          {this.props.notification.getIcon() === "success" ? (
            <SuccessNotificationIcon className="notificationIcon" />
          ) : this.props.notification.getIcon() === "fail" ? (
            <FailNotificationIcon className="notificationIcon" />
          ) : this.props.notification.getIcon() === "update" ? (
            <UpdateNotificationIcon className="notificationIcon" />
          ) : (
            <DisconnectNotificationIcon className="notificationIcon" />
          )}

          <div className="notificationMessage">
            <p className="font-weight-medium">
              {truncate(this.props.notification.title(), { length: 100 })}
            </p>
            <div className="countDown">
              {this.props.notification.getNotificationCountDown()}
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
