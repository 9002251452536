import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';

import { SlickSlider } from '../../../common-components';
import InfluencerCard from './InfluencerCard';
import { RightArrowIcon } from '../../../../assets/icons';
import { InfluencerModel, IInfluencerModel } from '../../../../store/models/InfluencerModel';
import InfluencerCardLoader from '../components/InfluencerCardLoader';
import { explorerSearchType } from '../../../../lib/CustomTypes';
import SearchHashtag from '../../../common-components/SearchHashtag';
import { ajaxErrorAlert, handleError, makeTempModelId } from '../../../../lib/Utils';
import RootStore from '../../../../store/RootStore';
import Transport from '../../../../lib/Transport';
import { HashtagModel } from '../../../../store/models/HashtagModel';
import handleViewport from 'react-in-viewport';
// import { Spinner } from 'reactstrap';

interface Props {
    header: React.ReactNode;
    toggleAdSction: () => void;
    seeMoreHandler: () => void;
    value?: string;
    type?: string;
}
/**
 * 
 * @param props , {influencerGroup, header, emptyMessage, toggleAdSction }
 * @returns list of categories, hashtags , location results linked to custom search
 */
export default React.memo(function GroupListNew(props: Props) {
    const [sideShadow, setSideShadow] = useState(true);
    const { header, value, type, seeMoreHandler } = props;
    const [showHashtagModal, setShowHashtagModal] = useState(false);
    const [influencers, setInfluencers] = useState([])
    const [loading, setLoading] = useState(true)
    const [inViewPort, setInViewPort] = useState(false)

    /**
     * hashtag modal toggle
     */
    const toggleSearchHashtagsModal = () => {
        setShowHashtagModal(!showHashtagModal);
    };
    const toggleAdSction = () => {
        props?.toggleAdSction()
    };

    useEffect(() => {
        let cancel = false
        if (type === explorerSearchType.HASHTAG) {
            if (cancel) return
            setSideShadow(false);
        }
        return () => { cancel = true }
    }, [influencers]);
    const checkArr = (arr) => Array.isArray(arr) && arr.length > 0

    // get influencers
    const _newElasticSearch = async () => {
        try {
            setLoading(true)
            // @ts-ignore
            const body = {
                ...(type === explorerSearchType.HASHTAG ? { hashtags: [value] } : {}),
                ...(type === explorerSearchType.CATEGORY ? { categories: [value] } : {}),
                ...(type === explorerSearchType.LOCATION ? { country_id: value } : {}),
                from: 0,
                sorts: [
                    {
                        key: 'followers',
                        direction: 'desc',
                    },
                ],
                number_of_data: 5,
            }

            let url = 'v1/elastic/search'

            Transport.post(url, body)
                .then(res => {
                    const doc = res?.data?.doc || []
                    let influ: IInfluencerModel[] = [];
                    for (let i = 0; i < doc.length; i++) {
                        influ = res?.data?.doc.map((elasticitem: any) =>
                            InfluencerModel.create({
                                integration: null,
                                identity: {
                                    id: elasticitem._source.identity_id,
                                    country_id: elasticitem._source.country_id,
                                    type: 'influencer',
                                    picutre_path: {
                                        height: 64,
                                        width: 64,
                                        id: makeTempModelId(),
                                        label: elasticitem._source.picutre_path
                                            ? elasticitem._source.picutre_path
                                                .split('/files/')[1]
                                                .split('.jpg')[0]
                                            : '',
                                        type: 'image/jpeg',
                                        url: elasticitem._source.picutre_path
                                            ? elasticitem._source.picutre_path
                                                .split('/files/')[1]
                                                .split('.jpg')[0]
                                            : '',
                                    },
                                    name: elasticitem._source.full_name,
                                    followers: elasticitem._source.followers,
                                    followings: elasticitem._source.followings,
                                    engagement_rate: elasticitem._source.engagement_rate,
                                    username: elasticitem._source.username,
                                    quality_score: elasticitem._source.quality_score,
                                    earnings: elasticitem._source.earnings,
                                    language: elasticitem._source.language,
                                    category_elastic: elasticitem._source.categories[0]
                                        ? elasticitem._source.categories[0].name === 'Other'
                                            ? elasticitem._source.categories[1]
                                                ? elasticitem._source.categories[1].name
                                                : elasticitem._source.categories[0].name
                                            : elasticitem._source.categories[0].name
                                        : 'N/A',
                                    category_elasticarray: JSON.stringify(elasticitem._source.categories)
                                },
                            }),
                        );
                    }
                    setInfluencers(influ)
                    setLoading(false)

                }).catch((err) => {
                    console.log('err', err)
                    setLoading(false)

                })
        } catch (err) { console.log('new elastic search err : ', err) }
    }
    useEffect(() => {
        let cancel = false
        if (inViewPort) {
            if (cancel) return
            _newElasticSearch()
        }
        return () => { cancel = true }
    }, [inViewPort, type])

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        initialSlide: 0,
        slidesToShow: 5,
        slidesToScroll: 4,
        arrows: false,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 740,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        afterChange: (currentSlide: number) => {
            if (currentSlide >= 4) {
                setSideShadow(!sideShadow);
            }
        },
        beforeChange: (currentSlide: number) => {
            if (currentSlide >= 4) {
                setSideShadow(!sideShadow);
            }
        },
    };

    /**
     * 
     * @param hashtags , array of hashtags 
     * give hashtag user want to search according to them
     */
    const saveHashtags = async (hashtags: string[]) => {
        const selected_ad = localStorage.getItem('selected_ad')
        const identityId = RootStore.identityStore.getByUserId(RootStore.users.currentUser.id).id;
        let hashtagList = [];
        if (hashtags.length > 0) {
            hashtags.forEach(async (hashtag, i) => {
                RootStore.invitationStore.addInfluencersToTemporaryList({
                    by: explorerSearchType.HASHTAG,
                    loading: true,
                    title: hashtag,
                    influencers: [],
                    queryParam: {
                        name: explorerSearchType.HASHTAG,
                        value: hashtag,
                    },
                    manual: true,
                });
                hashtagList.push(
                    HashtagModel.create({
                        id: i,
                        identity_id: identityId,
                        name: hashtag,
                    }),
                );
            });
            RootStore.hashtagsStore.updateHashtags(hashtagList);
        } else {
            RootStore.hashtagsStore.updateHashtags(hashtagList);
            RootStore.invitationStore.updateHashtagsTemporaryList();
        }
        try {
            await Transport.post(`identities/${identityId}/hashtags`, {
                hashtags,
            });
            if (selected_ad) {
                const ad_info = JSON.parse(localStorage.getItem('ad_info'))
                const limit_country = ad_info?.limit_country?.length > 0 ? ad_info?.limit_country[0] : ''
                const ad_hashtags = hashtagList?.map(item => item.name)

                await RootStore.invitationStore.fetchAllInfluencersForExplorerWithAd(limit_country, ad_hashtags)
            } else {
                await RootStore.invitationStore.fetchAllInfluencersForExplorer();
            }
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('Failed to get influencers data! Please reload the page!');
        }
    };

    const LoadingSections = () => (
        <div className="mb-5">
            <SlickSlider
                settings={{
                    dots: false,
                    infinite: false,
                    speed: 500,
                    initialSlide: 0,
                    slidesToShow: 5,
                    slidesToScroll: 4,
                    arrows: false,
                    centerMode: false,
                    responsive: [
                        {
                            breakpoint: 1367,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 0,
                            },
                        },
                        {
                            breakpoint: 1280,
                            settings: {
                                slidesToShow: 3.5,
                                slidesToScroll: 0,
                            },
                        },
                        {
                            breakpoint: 980,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 0,
                            },
                        },
                        {
                            breakpoint: 740,
                            settings: {
                                slidesToShow: 2.5,
                                slidesToScroll: 0,
                            },
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 0,
                            },
                        },
                        {
                            breakpoint: 420,
                            settings: {
                                slidesToShow: 1.5,
                                slidesToScroll: 0,
                            },
                        },
                    ],
                }}
            >
                <InfluencerCardLoader />
                <InfluencerCardLoader />
                <InfluencerCardLoader />
                <InfluencerCardLoader />
                <InfluencerCardLoader />
            </SlickSlider>
        </div>
    )

    const Results = () => (
        <SlickSlider settings={settings}>
            {influencers?.map((item, index: number) => (
                <div key={`groupList__${item?.identity?.id}`} className="position-relative">
                    <InfluencerCard toggleAdSction={toggleAdSction} influencer={item} />
                    {index === influencers?.length - 1 &&
                        influencers?.length > 5 ? (
                        <div className="influencerCard_over">
                            <Button
                                outline
                                color="primary"
                                onClick={seeMoreHandler}
                            >
                                See More
                                <RightArrowIcon />
                            </Button>
                        </div>
                    ) : null}
                </div>
            ))}
        </SlickSlider>
    )

    const onEnterViewport = () => {
        setInViewPort(true)
    }

    const ViewPort = ({ children }) => {
        const Block = (props: { forwardedRef }) => {
            const { forwardedRef } = props;
            return (
                <div className="viewport-block" ref={forwardedRef}></div>
            );
        };
        const ViewportBlock = handleViewport(Block);
        return <>
            {children}
            <ViewportBlock onEnterViewport={onEnterViewport} />
        </>
    }

    const renderBtn = () => {
        if (checkArr(influencers)) {
            return <Button
                outline
                color="primary"
                onClick={seeMoreHandler}
            >
                See More
                <RightArrowIcon />
            </Button>
        } else {
            return null
        }
    }

    return (
        <ViewPort >
            <div
                className={
                    influencers.length === 0 ?
                        null :
                        classNames('influencersGroupList', { sideShadow })
                }
            >
                {type === explorerSearchType.HASHTAG &&
                    <SearchHashtag isOpen={showHashtagModal} toggle={toggleSearchHashtagsModal} action={saveHashtags} />}
                {checkArr(influencers) ? <div className="influencersGroupList__heading">
                    {header}
                    {renderBtn()}
                </div> : null}
                {loading ? <LoadingSections /> : null}
                {!loading && checkArr(influencers) ? <Results /> : null}
            </div>
        </ViewPort >
    );
})
