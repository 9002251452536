import { flow, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import RootStore from './RootStore';
import Transport from '../lib/Transport';
import { InvitationModel, IInvitationModelSnapshotIn, IInvitationModel } from './models/InvitationModel';
import { ICountryModel } from './models/CountryModel';
import { IHashtagModel } from './models/HashtagModel';
import { ICategoryModel } from './models/CategoryModel';
import { IIdentityModel } from './models/IdentityModel';
import { makeInvitationsQuery, makeInvitationsQueryWithFilters } from './common/RequestHelpers';
import { observable } from 'mobx';
import { InfluencerModel, IInfluencerModel } from './models/InfluencerModel';
import { ajaxErrorAlert, makeTempModelId } from '../lib/Utils';
import { FilterGroup, Pagination } from '../lib/QueryTypes';
import { isStaging } from '../constants/defaultValues';
import { explorerSearchType, InfluencerGroup } from '../lib/CustomTypes';
import { ICampaignModel } from './models/CampaignModel';
import { IContentModel } from './models/ContentModel';
import { CountriesList } from './../constants/countriesList'

export const InvitationStore = types
    .model({
        invitations: types.array(InvitationModel),
    })
    .volatile((self) => ({
        isBusy: true,
        isSelected: false,
        fetchedFromServer: false,
        pagination: {
            totalPages: null,
            page: 0,
            pageSize: 10,
        },
        temporaryList: observable([]),
        cachedInfluencers: observable([]),
        totalFoundInfluencers: observable<{ total: number }>({ total: 0 }),
    }))
    .actions((self) => ({

        /**
        * this method select the invitation
        */
        selected() {
            self.isSelected = true;
        },

        /**
        * this method unselect the invitation
        */
        unSelect() {
            self.isSelected = false;
        },

        /**
        * this method check processing for this store
        */
        setBusy() {
            self.isBusy = true;
        },

        /**
        * this method just set value isBusy = false
        */
        setIdle() {
            self.isBusy = false;
        },

        /**
        * this method push model this store
        * @param model, this param type object for get model
        */
        pushModel(model: IInfluencerModel) {
            self.temporaryList.push(model);
        },

        clearList() {
            self.temporaryList.length = 0;
            self.isSelected = false;
        },

        /**
        * this method just clear Invitations
        */
        clearInvitations() {
            self.invitations.clear();
        },

        /**
        * this method just clear ads
        */
        clearInvitationsData() {
            RootStore.adStore.clearAdsData();
        },

        /**
        * this method set pagination Invitations
        */
        setPagination(pagination: Pagination) {
            self.pagination = pagination;
        },
        initializeCachedInfluencers() {
            self.cachedInfluencers = observable([])
        }
    }))
    .actions((self) => ({
        syncMissingModels(models: IInvitationModelSnapshotIn[]) {
            if (!Array.isArray(models)) {
                return;
            }

            let invitationsIdList: number[] = self.invitations.map((invitation) => invitation.id);

            self.invitations.replace(
                self.invitations.concat(
                    models.filter((model) => invitationsIdList.indexOf(model.id) === -1).uniqueById(),
                ),
            );
        },
    }))

    .actions((self) => ({

        /**
        * this method create new nvitation
        * @param ad_id, this param ad ID
        */
        createInvitation: flow(function* (ad_id: number) {
            self.setBusy();

            let response;
            try {
                let identity_ids = self.temporaryList?.map((influencer) => influencer.identity.id);
                self.clearList();
                response = yield Transport.post('invitations', {
                    invitation: {
                        ad_id: ad_id,
                        identity_ids: identity_ids,
                    },
                });

                if (!isStaging()) {
                    //@ts-ignore
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'Brand',
                        eventAction: 'Invitation Created',
                        eventLabel: '',
                    });
                }

                self.setIdle();

                return response.data['invitation'];
            } catch (error) {
                self.setBusy();
                throw error;
            }
        }),

        /**
        * this method fetch all invitations
        * @param campaign_id, this param campaign ID
        */
        fetchAll: flow(function* (campaign_id: number = null) {
            self.setBusy();

            try {
                let query = makeInvitationsQuery();

                if (campaign_id) {
                    query.addQueryParam('campaign_id', campaign_id);
                }

                let response = yield Transport.get('invitations/created', query);
                if (response.data['invitations'].length > 0) {
                    self.invitations.clear();

                    let campaignModels: ICampaignModel[] = [];
                    let contentModels: IContentModel[] = [];

                    for (let ad of response.data['ad']) {
                        if (campaignModels.filter((model) => model.id === ad.campaign.id).length === 0) {
                            campaignModels.push(ad.campaign);
                        }
                        if (contentModels.filter((model) => model.id === ad.content.id).length === 0) {
                            contentModels.push(ad.content);
                        }
                    }

                    RootStore.campaignStore.syncMissingModels(campaignModels);
                    RootStore.contentStore.syncMissingModels(contentModels);

                    RootStore.adStore.syncMissingModels(response.data['ad']);
                    RootStore.identityStore.syncMissingModels(response.data['source']);
                    RootStore.identityStore.syncMissingModels(response.data['target']);

                    for (let invitation of response.data['invitations']) {
                        self.invitations.push(InvitationModel.create(invitation));
                    }
                }
                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
         * this method fetch all data invitations
         * @param campaign_id, this param campaign ID
         * @param pagination, this param object for pagination invitations
         * @param filters_group, this param object for filter invitations
         * @param SortOrder, this param object for sort invitations
        */
        fetchAllData: flow(function* (
            campaign_id: number = null,
            pagination: { page: number; pageSize: number },
            filters_group: FilterGroup[],
            SortOrder,
            loadMore: boolean = false,
        ) {
            try {
                if (!self.fetchedFromServer || loadMore) {
                    if (!loadMore) self.setBusy();

                    let query = makeInvitationsQueryWithFilters(false, false, pagination, filters_group, SortOrder);

                    if (campaign_id) {
                        query.addQueryParam('campaign_id', campaign_id);
                    }

                    let response = yield Transport.get('invitations/created', query);
                    if (response.data['invitations'].length > 0) {
                        // self.invitations.clear();
                        self.fetchedFromServer = true;
                        let campaignModels: ICampaignModel[] = [];
                        let contentModels: IContentModel[] = [];

                        for (let ad of response.data['ad']) {
                            if (campaignModels.filter((model) => model.id === ad.campaign.id).length === 0) {
                                campaignModels.push(ad.campaign);
                            }
                            if (contentModels.filter((model) => model.id === ad.content.id).length === 0) {
                                contentModels.push(ad.content);
                            }
                        }

                        RootStore.campaignStore.syncMissingModels(campaignModels);
                        RootStore.contentStore.syncMissingModels(contentModels);

                        RootStore.adStore.syncMissingModels(response.data['ad']);
                        RootStore.identityStore.syncMissingModels(response.data['source']);
                        RootStore.identityStore.syncMissingModels(response.data['target']);
                        self.syncMissingModels(response.data['invitations']);
                    }
                    self.setPagination({
                        totalPages: response.data.meta.page_count,
                        page: response.data.meta.page,
                        pageSize: response.data.meta.limit,
                    });
                    self.setIdle();
                    return {
                        totalPages: response.data.meta.page_count,
                        page: response.data.meta.page,
                    };
                }

                return self.pagination;
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
         * this method delete invitations
         * @param invitation_id, this param invitation ID
         * @param onDelete, this param handle custom action after delete invitations
        */
        deleteInvitation: flow(function* (invitation_id: number, onDelete: Function | null = null) {
            self.setBusy();

            try {
                yield Transport.delete(`invitations/${invitation_id}`);
                self.invitations.replace(self.invitations.filter((invitation) => invitation.id !== invitation_id));
                if (onDelete) {
                    onDelete();
                }
                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
         * this method push influencer to temporary list
         * @param influencer, this param get Influencer Model
        */
        addToList(influencer: IInfluencerModel): void {
            let models: IInfluencerModel[] = self.temporaryList.filter(
                (inf) => inf.identity.id === influencer.identity.id,
            );
            self.selected();
            if (models.length === 0) {
                self.pushModel(influencer);
            }
        },

        /**
         * this method delete item as temporary list
         * @param influencer, this param get Influencer Model for deleted
        */
        removeFromList(influencer: IInfluencerModel): void {
            if (self.temporaryList.length > 0) {
                let models: IInfluencerModel[] = self.temporaryList.filter(
                    (inf) => inf.identity.id !== influencer.identity.id,
                );

                self.clearList();

                models.forEach((model) => {
                    self.pushModel(model);
                });
            }
        },
    }))
    .actions((self) => ({

        /**
        * this method for fetch all invitations force
        */
        fetchAllIfNecessary: flow(function* (): Generator<any, IInvitationModel[], any> {
            if (!self.fetchedFromServer) {
                try {
                    self.fetchedFromServer = true;
                    yield self.fetchAll();
                    return self.invitations.filter((invitation) => invitation.deleted_at === null);
                } catch (e) {
                    ajaxErrorAlert('Failed to load invitations please refresh the page.');
                    throw e;
                }
            }
            return self.invitations.filter((invitation) => invitation.deleted_at === null);
        }),

        /**
        * this method add influencers to temporary list
        * @param influencers, this param get influencers to add
        */
        addInfluencersToTemporaryList(influencers: InfluencerGroup) {
            let infgIndex = self.cachedInfluencers.map((infg) => infg.title).indexOf(influencers.title);
            if (infgIndex > -1) {
                self.cachedInfluencers[infgIndex] = influencers;
            } else {
                self.cachedInfluencers.push(influencers);
            }
            if (influencers.manual)
                self.cachedInfluencers = observable(
                    self.cachedInfluencers.filter(
                        (inf) => inf.by !== explorerSearchType.HASHTAG && inf.title !== 'no hashtag',
                    ),
                );
        },
    }))

    .views((self) => ({
        isInList(id: number): boolean {
            let model = self.temporaryList.filter((influencer) => influencer.identity.id === id)[0];

            if (model) {
                return true;
            }
            return false;
        },

        /**
        * this method get length temporary list
        */
        getLength(): number {
            return self.temporaryList.length;
        },

        /**
        * this method get invitation by ad ID
        * @param adId, this param get ad ID
        */
        getByAdId(adId: number) {
            if (self.invitations.length) {
                return self.invitations.filter((invitation) => invitation.ad_id.id === adId);
            }
        },

        /**
        * this method get influencer name
        * @param influencerId, this param get influencer ID
        */
        getInfluencerName(influencerId: number) {
            return self.invitations
                .filter((invitation) => invitation.target_id.id === influencerId)
                .map((inf) => inf.target_id.name);
        },

        isInCachedList(title: string) {
            let list = self.cachedInfluencers.filter((infGroup) => infGroup.title === title);
            return list.length > 0 && list[0].influencers.length > 0;
        },

        getInfluencerGroupFromCachedList(title: string) {
            return self.cachedInfluencers.filter((infGroup) => infGroup.title === title)[0];
        },
    }))
    .views((self) => ({
        isLoaded(title: string) {
            if (self.isInCachedList(title)) {
                let list = self.cachedInfluencers.filter((infGroup) => infGroup.title === title);
                return list.length > 0 && list[0].loading;
            }
            return false;
        },

        /**
        * this method set total found influencers
        * @param count, this param get count total found influencers
        */
        setTotalFoundInfluencersCount(count: number) {
            self.totalFoundInfluencers.total = count;
        },
    }))
    .actions((self) => ({

        /**
        * this method fetch all influencers for explorer
        */
        fetchAllInfluencersForExplorer() {
            self.setBusy()
            let country = CountriesList.filter(
                (country: ICountryModel) => country.id === RootStore.users.currentUser.getIdentities()[0].country_id ,
            )[0]

            if (!self.isInCachedList(country?.name) && !self.isLoaded(country?.name)) {
                self.addInfluencersToTemporaryList({
                    by: explorerSearchType.LOCATION,
                    loading: true,
                    title: country?.name,
                    influencers: [],
                    queryParam: {
                        name: explorerSearchType.LOCATION,
                        value: country?.id,
                    },
                });
            }

            if (RootStore.hashtagsStore.hashtags.length > 0) {
                RootStore.hashtagsStore.hashtags.forEach((hashtag: IHashtagModel) => {
                    if (!self.isInCachedList(hashtag?.name) && !self.isLoaded(hashtag?.name)) {
                        self.addInfluencersToTemporaryList({
                            by: explorerSearchType.HASHTAG,
                            loading: true,
                            title: hashtag?.name,
                            influencers: [],
                            queryParam: {
                                name: explorerSearchType.HASHTAG,
                                value: hashtag,
                            },
                        });
                    }
                });
            } else {
                self.addInfluencersToTemporaryList({
                    by: explorerSearchType.HASHTAG,
                    loading: true,
                    title: 'no hashtag',
                    influencers: [],
                    queryParam: {
                        name: explorerSearchType.HASHTAG,
                        value: 'no hashtag',
                    },
                });
            }

            RootStore.users.currentUser
                .getIdentities()
                .flatMap((identity: IIdentityModel) => identity.categories.map((cate: ICategoryModel) => cate))
                .forEach((category: ICategoryModel) => {
                    const title = category?.title
                    if (!self.isInCachedList(title) && !self.isLoaded(title)) {
                        self.addInfluencersToTemporaryList({
                            by: explorerSearchType.CATEGORY,
                            loading: true,
                            title: title,
                            influencers: [],
                            queryParam: {
                                name: explorerSearchType.CATEGORY,
                                value: [{ value: category?.id, label: title, slug: category?.slug }],
                            },
                        });
                    }
                });

            // Load influencers by location
            if (country) {
                (async () => {
                    if (!self.isLoaded(country?.name)) {
                        try {
                            let response = await Transport.post('v1/elastic/search', {
                                sorts: [
                                    {
                                        key: 'followers',
                                        direction: 'desc',
                                    },
                                ],
                                country_id: country?.id || undefined,
                                number_of_data: 50,
                                from: 0,
                            });

                            let group: IInfluencerModel[] = [];
                            if (response?.data?.doc?.length > 0) {
                                group = response.data.doc.map((elasticitem: any) =>
                                    InfluencerModel.create({
                                        integration: null,
                                        identity: {
                                            id: elasticitem._source.identity_id,
                                            country_id: elasticitem._source.country_id,
                                            type: 'influencer',
                                            picutre_path: {
                                                height: 64,
                                                width: 64,
                                                id: makeTempModelId(),
                                                label: elasticitem._source.picutre_path
                                                    ? elasticitem._source.picutre_path
                                                        .split('/files/')[1]
                                                        .split('.jpg')[0]
                                                    : '',
                                                type: 'image/jpeg',
                                                url: elasticitem._source.picutre_path
                                                    ? elasticitem._source.picutre_path
                                                        .split('/files/')[1]
                                                        .split('.jpg')[0]
                                                    : '',
                                            },
                                            name: elasticitem._source.full_name,
                                            followers: elasticitem._source.followers,
                                            followings: elasticitem._source.followings,
                                            engagement_rate: elasticitem._source.engagement_rate,
                                            username: elasticitem._source.username,
                                            quality_score: elasticitem._source.quality_score,
                                            earnings: elasticitem._source.earnings,
                                            language: elasticitem._source.language,
                                            category_elastic: elasticitem._source.categories[0]
                                                ? elasticitem._source.categories[0].name === 'Other'
                                                    ? elasticitem._source.categories[1]
                                                        ? elasticitem._source.categories[1].name
                                                        : elasticitem._source.categories[0].name
                                                    : elasticitem._source.categories[0].name
                                                : 'N/A',
                                            category_elasticarray: JSON.stringify(elasticitem._source.categories)
                                        },
                                    }),
                                );

                                self.setTotalFoundInfluencersCount(parseInt(response.data.number_of_data));
                            }

                            self.addInfluencersToTemporaryList({
                                by: explorerSearchType.LOCATION,
                                title: country?.name,
                                loading: false,
                                influencers: group,
                                queryParam: {
                                    name: explorerSearchType.LOCATION,
                                    value: country?.id,
                                },
                            });
                        } catch (error) {
                            self.addInfluencersToTemporaryList({
                                by: explorerSearchType.LOCATION,
                                title: country?.name,
                                loading: false,
                                influencers: [],
                                queryParam: {
                                    name: explorerSearchType.LOCATION,
                                    value: country?.id,
                                },
                            });
                            throw error;
                        }
                    }
                })();
            }

            // Load influencers by categories
            RootStore.users.currentUser
                .getIdentities()
                .flatMap((identity: IIdentityModel) => identity.categories.map((cate: ICategoryModel) => cate))
                .forEach((category: ICategoryModel) => {
                    (async () => {
                        if (!self.isLoaded(category.title)) {
                            try {
                                let group: IInfluencerModel[] = [];
                                let response = await Transport.post('v1/elastic/search', {
                                    sorts: [
                                        {
                                            key: 'followers',
                                            direction: 'desc',
                                        },
                                    ],
                                    categories: [category?.slug] || undefined,
                                    number_of_data: 50,
                                    from: 0,
                                });

                                if (response.data.doc && response?.data?.doc?.length > 0) {
                                    group = response.data.doc.map((elasticitem: any) =>
                                        InfluencerModel.create({
                                            integration: null,
                                            identity: {
                                                id: elasticitem._source.identity_id,
                                                country_id: elasticitem._source.country_id,
                                                type: 'influencer',
                                                picutre_path: {
                                                    height: 64,
                                                    width: 64,
                                                    id: makeTempModelId(),
                                                    label: elasticitem._source.picutre_path
                                                        ? elasticitem._source.picutre_path
                                                            .split('/files/')[1]
                                                            .split('.jpg')[0]
                                                        : '',
                                                    type: 'image/jpeg',
                                                    url: elasticitem._source.picutre_path
                                                        ? elasticitem._source.picutre_path
                                                            .split('/files/')[1]
                                                            .split('.jpg')[0]
                                                        : '',
                                                },
                                                name: elasticitem._source.full_name,
                                                followers: elasticitem._source.followers,
                                                followings: elasticitem._source.followings,
                                                engagement_rate: elasticitem._source.engagement_rate,
                                                username: elasticitem._source.username,
                                                quality_score: elasticitem._source.quality_score,
                                                earnings: elasticitem._source.earnings,
                                                language: elasticitem._source.language,
                                                category_elastic: elasticitem._source.categories[0]
                                                    ? elasticitem._source.categories[0].name === 'Other'
                                                        ? elasticitem._source.categories[1]
                                                            ? elasticitem._source.categories[1].name
                                                            : elasticitem._source.categories[0].name
                                                        : elasticitem._source.categories[0].name
                                                    : 'N/A',
                                                category_elasticarray: JSON.stringify(elasticitem._source.categories)
                                            },
                                        }),
                                    );

                                    self.setTotalFoundInfluencersCount(parseInt(response.data.number_of_data));
                                }

                                self.addInfluencersToTemporaryList({
                                    by: explorerSearchType.CATEGORY,
                                    title: category?.title,
                                    loading: false,
                                    influencers: group,
                                    queryParam: {
                                        name: explorerSearchType.CATEGORY,
                                        value: [{ value: category?.id, label: category?.title, slug: category?.slug }],
                                    },
                                });
                            } catch (error) {
                                self.addInfluencersToTemporaryList({
                                    by: explorerSearchType.CATEGORY,
                                    title: category?.title,
                                    loading: false,
                                    influencers: [],
                                    queryParam: {
                                        name: explorerSearchType.CATEGORY,
                                        value: [{ value: category?.id, label: category?.title, slug: category?.slug }],
                                    },
                                });
                                throw error;
                            }
                        }
                    })();
                });

            // Load influencers by hashtags
            if (RootStore.hashtagsStore.hashtags.length > 0) {
                RootStore.hashtagsStore.hashtags
                    .map((hashtag: IHashtagModel) => hashtag.name)
                    .forEach((hashtag: string) => {
                        (async () => {
                            if (!self.isLoaded(hashtag)) {
                                try {
                                    let response = await Transport.post('v1/elastic/search', {
                                        sorts: [
                                            {
                                                key: 'followers',
                                                direction: 'desc',
                                            },
                                        ],
                                        hashtags: [hashtag] || undefined,
                                        number_of_data: 50,
                                        from: 0,
                                    });

                                    let group: IInfluencerModel[] = [];
                                    if (response?.data?.doc?.length > 0) {
                                        group = response.data.doc.map((elasticitem: any) =>
                                            InfluencerModel.create({
                                                integration: null,
                                                identity: {
                                                    id: elasticitem._source.identity_id,
                                                    country_id: elasticitem._source.country_id,
                                                    type: 'influencer',
                                                    picutre_path: {
                                                        height: 64,
                                                        width: 64,
                                                        id: makeTempModelId(),
                                                        label: elasticitem._source.picutre_path
                                                            ? elasticitem._source.picutre_path
                                                                .split('/files/')[1]
                                                                .split('.jpg')[0]
                                                            : '',
                                                        type: 'image/jpeg',
                                                        url: elasticitem._source.picutre_path
                                                            ? elasticitem._source.picutre_path
                                                                .split('/files/')[1]
                                                                .split('.jpg')[0]
                                                            : '',
                                                    },
                                                    name: elasticitem._source.full_name,
                                                    followers: elasticitem._source.followers,
                                                    followings: elasticitem._source.followings,
                                                    engagement_rate: elasticitem._source.engagement_rate,
                                                    username: elasticitem._source.username,
                                                    quality_score: elasticitem._source.quality_score,
                                                    earnings: elasticitem._source.earnings,
                                                    language: elasticitem._source.language,
                                                    category_elastic: elasticitem._source.categories[0]
                                                        ? elasticitem._source.categories[0].name === 'Other'
                                                            ? elasticitem._source.categories[1]
                                                                ? elasticitem._source.categories[1].name
                                                                : elasticitem._source.categories[0].name
                                                            : elasticitem._source.categories[0].name
                                                        : 'N/A',
                                                    category_elasticarray: JSON.stringify(elasticitem._source.categories)
                                                },
                                            }),
                                        );

                                        self.setTotalFoundInfluencersCount(parseInt(response.data.number_of_data));
                                    }
                                    self.addInfluencersToTemporaryList({
                                        by: explorerSearchType.HASHTAG,
                                        title: hashtag,
                                        loading: false,
                                        influencers: group,
                                        queryParam: {
                                            name: explorerSearchType.HASHTAG,
                                            value: hashtag,
                                        },
                                    });
                                } catch (error) {
                                    self.addInfluencersToTemporaryList({
                                        by: explorerSearchType.HASHTAG,
                                        title: hashtag,
                                        loading: false,
                                        influencers: [],
                                        queryParam: {
                                            name: explorerSearchType.HASHTAG,
                                            value: 'no hashtag',
                                        },
                                    });
                                    throw error;
                                }
                            }
                        })();
                    });
            }
            self.setIdle()
        },
        fetchAllInfluencersForExplorerWithAd(adCountry = null, adHashtags = undefined) {
            self.setBusy()
            self.initializeCachedInfluencers()
            let country = CountriesList.filter(
                (country: ICountryModel) => country.id === adCountry,
            )[0]

            // Load influencers by location
            if (country) {
                if (!self.isInCachedList(country?.name) && !self.isLoaded(country?.name)) {
                    self.addInfluencersToTemporaryList({
                        by: explorerSearchType.LOCATION,
                        loading: true,
                        title: country?.name,
                        influencers: [],
                        queryParam: {
                            name: explorerSearchType.LOCATION,
                            value: country?.id,
                        },
                    });
                }
                (async () => {
                    if (!self.isLoaded(country?.name)) {
                        try {
                            let response = await Transport.post('v1/elastic/search', {
                                sorts: [
                                    {
                                        key: 'followers',
                                        direction: 'desc',
                                    },
                                ],
                                country_id: country?.id || undefined,
                                number_of_data: 50,
                                from: 0,
                            });

                            let group: IInfluencerModel[] = [];
                            if (response?.data?.doc?.length > 0) {
                                group = response.data.doc.map((elasticitem: any) =>
                                    InfluencerModel.create({
                                        integration: null,
                                        identity: {
                                            id: elasticitem._source.identity_id,
                                            country_id: elasticitem._source.country_id,
                                            type: 'influencer',
                                            picutre_path: {
                                                height: 64,
                                                width: 64,
                                                id: makeTempModelId(),
                                                label: elasticitem._source.picutre_path
                                                    ? elasticitem._source.picutre_path
                                                        .split('/files/')[1]
                                                        .split('.jpg')[0]
                                                    : '',
                                                type: 'image/jpeg',
                                                url: elasticitem._source.picutre_path
                                                    ? elasticitem._source.picutre_path
                                                        .split('/files/')[1]
                                                        .split('.jpg')[0]
                                                    : '',
                                            },
                                            name: elasticitem._source.full_name,
                                            followers: elasticitem._source.followers,
                                            followings: elasticitem._source.followings,
                                            engagement_rate: elasticitem._source.engagement_rate,
                                            username: elasticitem._source.username,
                                            quality_score: elasticitem._source.quality_score,
                                            earnings: elasticitem._source.earnings,
                                            language: elasticitem._source.language,
                                            category_elastic: elasticitem._source.categories[0]
                                                ? elasticitem._source.categories[0].name === 'Other'
                                                    ? elasticitem._source.categories[1]
                                                        ? elasticitem._source.categories[1].name
                                                        : elasticitem._source.categories[0].name
                                                    : elasticitem._source.categories[0].name
                                                : 'N/A',
                                            category_elasticarray: JSON.stringify(elasticitem._source.categories)
                                        },
                                    }),
                                );

                                self.setTotalFoundInfluencersCount(parseInt(response.data.number_of_data));
                            }

                            self.addInfluencersToTemporaryList({
                                by: explorerSearchType.LOCATION,
                                title: country?.name,
                                loading: false,
                                influencers: group,
                                queryParam: {
                                    name: explorerSearchType.LOCATION,
                                    value: country?.id,
                                },
                            });
                        } catch (error) {
                            self.addInfluencersToTemporaryList({
                                by: explorerSearchType.LOCATION,
                                title: country?.name,
                                loading: false,
                                influencers: [],
                                queryParam: {
                                    name: explorerSearchType.LOCATION,
                                    value: country?.id,
                                },
                            });
                            throw error;
                        }
                    }
                })();
            }

            // Load influencers by hashtags

            if (Array.isArray(adHashtags) && adHashtags?.length > 0) {
                adHashtags?.forEach((hashtag) => {
                    if (!self.isInCachedList(hashtag) && !self.isLoaded(hashtag)) {
                        self.addInfluencersToTemporaryList({
                            by: explorerSearchType.HASHTAG,
                            loading: true,
                            title: hashtag,
                            influencers: [],
                            queryParam: {
                                name: explorerSearchType.HASHTAG,
                                value: { name: hashtag },
                            },
                        });
                    }
                });
            } else {
                self.addInfluencersToTemporaryList({
                    by: explorerSearchType.HASHTAG,
                    loading: true,
                    title: 'no hashtag',
                    influencers: [],
                    queryParam: {
                        name: explorerSearchType.HASHTAG,
                        value: 'no hashtag',
                    },
                });
            }
            if (adHashtags?.length > 0) {
                adHashtags
                    ?.forEach((hashtag: string) => {
                        (async () => {
                            if (!self.isLoaded(hashtag)) {
                                try {
                                    let response = await Transport.post('v1/elastic/search', {
                                        sorts: [
                                            {
                                                key: 'followers',
                                                direction: 'desc',
                                            },
                                        ],
                                        hashtags: [hashtag] || undefined,
                                        number_of_data: 50,
                                        from: 0,
                                    });

                                    let group: IInfluencerModel[] = [];
                                    if (response?.data?.doc?.length > 0) {
                                        group = response.data.doc.map((elasticitem: any) =>
                                            InfluencerModel.create({
                                                integration: null,
                                                identity: {
                                                    id: elasticitem._source.identity_id,
                                                    country_id: elasticitem._source.country_id,
                                                    type: 'influencer',
                                                    picutre_path: {
                                                        height: 64,
                                                        width: 64,
                                                        id: makeTempModelId(),
                                                        label: elasticitem._source.picutre_path
                                                            ? elasticitem._source.picutre_path
                                                                .split('/files/')[1]
                                                                .split('.jpg')[0]
                                                            : '',
                                                        type: 'image/jpeg',
                                                        url: elasticitem._source.picutre_path
                                                            ? elasticitem._source.picutre_path
                                                                .split('/files/')[1]
                                                                .split('.jpg')[0]
                                                            : '',
                                                    },
                                                    name: elasticitem._source.full_name,
                                                    followers: elasticitem._source.followers,
                                                    followings: elasticitem._source.followings,
                                                    engagement_rate: elasticitem._source.engagement_rate,
                                                    username: elasticitem._source.username,
                                                    quality_score: elasticitem._source.quality_score,
                                                    earnings: elasticitem._source.earnings,
                                                    language: elasticitem._source.language,
                                                    category_elastic: elasticitem._source.categories[0]
                                                        ? elasticitem._source.categories[0].name === 'Other'
                                                            ? elasticitem._source.categories[1]
                                                                ? elasticitem._source.categories[1].name
                                                                : elasticitem._source.categories[0].name
                                                            : elasticitem._source.categories[0].name
                                                        : 'N/A',
                                                    category_elasticarray: JSON.stringify(elasticitem._source.categories)
                                                },
                                            }),
                                        );

                                        self.setTotalFoundInfluencersCount(parseInt(response.data.number_of_data));
                                    }
                                    self.addInfluencersToTemporaryList({
                                        by: explorerSearchType.HASHTAG,
                                        title: hashtag,
                                        loading: false,
                                        influencers: group,
                                        queryParam: {
                                            name: explorerSearchType.HASHTAG,
                                            value: hashtag,
                                        },
                                    });
                                } catch (error) {
                                    self.addInfluencersToTemporaryList({
                                        by: explorerSearchType.HASHTAG,
                                        title: hashtag,
                                        loading: false,
                                        influencers: [],
                                        queryParam: {
                                            name: explorerSearchType.HASHTAG,
                                            value: hashtag,
                                        },
                                    });
                                    throw error;
                                }
                            }
                        })();
                    });
            }
            self.setIdle()
        },
        /**
        * this method update hashtags temporary list
        */
        updateHashtagsTemporaryList() {
            if (RootStore.hashtagsStore.hashtags.length > 0) {
                let newCachedList = observable(
                    self.cachedInfluencers.filter((item) => item.by !== explorerSearchType.HASHTAG),
                );

                self.cachedInfluencers = newCachedList;

                let newTempList = observable(
                    self.temporaryList.filter((item) => {
                        return item.by !== explorerSearchType.HASHTAG;
                    }),
                );
                self.temporaryList = newTempList;

                RootStore.hashtagsStore.hashtags.forEach((hashtag: IHashtagModel) => {
                    if (!self.isInCachedList(hashtag.name) && !self.isLoaded(hashtag.name)) {
                        self.addInfluencersToTemporaryList({
                            by: explorerSearchType.HASHTAG,
                            loading: true,
                            title: hashtag.name,
                            influencers: [],
                            queryParam: {
                                name: explorerSearchType.HASHTAG,
                                value: hashtag,
                            },
                        });
                    }
                });
            } else {
                self.cachedInfluencers = observable([]);
                self.addInfluencersToTemporaryList({
                    by: explorerSearchType.HASHTAG,
                    loading: true,
                    title: 'no hashtag',
                    influencers: [],
                    queryParam: {
                        name: explorerSearchType.HASHTAG,
                        value: 'no hashtag',
                    },
                });
            }
        },
    }));

export interface IInvitationStore extends Instance<typeof InvitationStore> { }
export interface IInvitationStoreSnapshotIn extends SnapshotIn<typeof InvitationStore> { }
export interface IInvitationStoreSnapshotOut extends SnapshotOut<typeof InvitationStore> { }
