import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import Transactions from './Transactions';

interface Props extends RouteComponentProps {}

export default class TransactionsTab extends Component<Props> {
    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.url}/`} component={Transactions} />
                <Redirect to="/error" />
            </Switch>
        );
    }
}
