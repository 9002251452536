import React, { Component } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Row, Card, CardBody, Table, Button, Spinner } from 'reactstrap';
import { truncate } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { SortOrder, FilterGroup, Pagination } from '../../../../lib/QueryTypes';
import { Separator } from '../../../../template/components/common/CustomBootstrap';
import ClientSideFilterSet from '../../../common-components/Filter/ClientSideFilterSet';
import RootStore from '../../../../store/RootStore';
import { ajaxErrorAlert, handleError } from '../../../../lib/Utils';
import EmptyState from './EmptyState';
import FilterComponent from '../../../common-components/Filter/FilterComponent';
import { FilterType, Range } from '../../../common-components/Filter/AbstractFilterSet';
import { IInvitationModel } from '../../../../store/models/InvitationModel';
import { IIdentityModel } from '../../../../store/models/IdentityModel';
import Swal from 'sweetalert2';
import profileImg from '../../../../assets/img/profilePlaceholder.jpg';

interface Props extends RouteComponentProps { }

interface State {
    scrollTop: number;
    filteredInvitations: IInvitationModel[];
    invitationsFetched: boolean;
    filter_groups: FilterGroup[];
    sort: { key: string; direction: SortOrder };
    pagination: Pagination;
    reachedEnd: boolean;
    loading: boolean;
}

interface Filters {
    searchTerm: string | null;
    filters: any;
}

interface IInvitationModelFlattened {
    [key: string]: any;
    invitationId: number;
    influencerName: string;
    influencerId: number;
    adId: number;
    adTitle: string;
    invitationStatus: string;
    invitationContentType: string;
}

@observer
export default class InvitedInfluencersTab extends Component<Props, State> {
    private readonly filterSet: ClientSideFilterSet<IInvitationModel, IInvitationModelFlattened>;
    public url = profileImg;
    constructor(props: any) {
        super(props);

        this.state = {
            scrollTop: 0,
            invitationsFetched: false,
            filteredInvitations: [],
            sort: {
                key: 'updated_at',
                direction: SortOrder.DESCENDING,
            },
            filter_groups: [],
            pagination: {
                totalPages: null,
                page: 0,
                pageSize: 20,
            },
            reachedEnd: false,
            loading: false,
        };
        this.filterSet = new ClientSideFilterSet<IInvitationModel, IInvitationModelFlattened>({
            getModels: () => RootStore.invitationStore.invitations,
            flattenFunction: (invitation: IInvitationModel) => ({
                invitationId: invitation.id,
                influencerName: invitation.target_id.name,
                influencerId: invitation.target_id.id,
                adId: invitation.ad_id.id,
                adTitle: invitation.ad_id.title,
                invitationStatus: invitation.getInvitationStatus(),
                invitationContentType: invitation.ad_id.content_id.type,
            }),
            onFilteredCallback: (filteredInvitations: IInvitationModel[]) => {
                console.log(
                    RootStore.invitationStore.invitations.map((invitation: IInvitationModel) => invitation.status),
                );
                this.setState({
                    filteredInvitations,
                });
            },
            fuzzySearchKeys: ['influencerName', 'adTitle'],
        });

        this.filterSet.addFilter({
            name: 'influencerId',
            title: 'Influencer',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [
                null,
                ...RootStore.invitationStore.invitations
                    .map((invitation: IInvitationModel) => invitation.target_id.id)
                    .filter(
                        (invitation_id: number, index: number, self: number[]) => index === self.indexOf(invitation_id),
                    ),
            ],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value === null) return 'All';
                return RootStore.identityStore.identities.filter(
                    (identity: IIdentityModel) => identity.id === parseInt(value.toString()),
                )[0].name;
            },
        });
        this.filterSet.addFilter({
            name: 'adId',
            title: 'Ad',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [
                null,
                ...RootStore.invitationStore.invitations
                    .map((invitation: IInvitationModel) => invitation.ad_id.id)
                    .filter((ad_id: number, index: number, self: number[]) => index === self.indexOf(ad_id)),
            ],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value === null) return 'All';
                return RootStore.adStore.getExistingAdById(parseInt(value.toString())).title;
            },
        });
        this.filterSet.addFilter({
            name: 'invitationStatus',
            title: 'Status',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [null, 'Invitation Accepted', 'Invitation Expired', 'Pending', 'Accepted'],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value === null) return 'All';
                if (value === 'Accepted') return 'Invitation Seen By Influencer';
                return value.toString();
            },
        });
        this.filterSet.addFilter({
            name: 'invitationContentType',
            title: 'Type',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [null, 'post', 'story'],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value === null) return 'All';
                else if (value === 'story') return 'Story Post';
                else if (value === 'post') return 'Feed Post';
                return value.toString();
            },
        });
    }

    async fetchInvitations(loadMore: boolean = false) {
        this.setState({ invitationsFetched: false });
        let res = await RootStore.invitationStore.fetchAllData(
            null,
            this.state.pagination,
            this.state.filter_groups,
            this.state.sort,
            loadMore,
        );

        this.setState({
            invitationsFetched: true,
            filteredInvitations: RootStore.invitationStore.invitations,
            pagination: {
                pageSize: this.state.pagination.pageSize,
                //@ts-ignore
                totalPages: res.totalPages,
                //@ts-ignore
                page: res.page,
            },
            loading: false,
            reachedEnd: res.page === res.totalPages - 1,
        });
    }

    async loadMoreInvitations() {
        if (!this.state.reachedEnd) {
            let res = await RootStore.invitationStore.fetchAllData(
                null,
                this.state.pagination,
                this.state.filter_groups,
                this.state.sort,
                !this.state.reachedEnd,
            );
            this.setState({
                filteredInvitations: RootStore.invitationStore.invitations,
                pagination: {
                    pageSize: this.state.pagination.pageSize,
                    //@ts-ignore
                    totalPages: res.totalPages,
                    //@ts-ignore
                    page: res.page,
                },
                loading: false,
                reachedEnd: res.page === res.totalPages - 1,
            });
        }
    }

    deleteInvitation = async (invitation_id: number, name: string) => {
        Swal.fire({
            type: 'error',
            title: 'Are you sure?',
            html: `You want to cancel the invitation with <b>${name}</b>?`,
            // showConfirmButton: true,
            // confirmButtonColor: "#ff0000",
            confirmButtonText: 'Cancel Invitation',
            showCancelButton: true,
            cancelButtonText: 'Back',
            reverseButtons: true,
        }).then(async (res) => {
            if (res.value) {
                try {
                    await RootStore.invitationStore.deleteInvitation(invitation_id, () => {
                        this.filterSet.applyFilters();
                    });
                } catch (error) {
                    handleError(error);
                    ajaxErrorAlert('Could not delete invitation you selected');
                }
            }
        });
    };

    async componentDidMount() {
        await this.fetchInvitations();
    }

    async pageChange(page: number): Promise<void> {
        await this.setState({
            pagination: { ...this.state.pagination, page },
        });
        await this.loadMoreInvitations();
    }

    render() {
        return (
            <>
                <Row className="mb-4">
                    <div className="invitedInfluencers_header">
                        {this.filterSet && <FilterComponent filterSet={this.filterSet} />}

                        <Button
                            color="primary"
                            onClick={(event: React.MouseEvent) => this.props.history.push('/app/influencer-explorer')}
                        >
                            Invite Influencers
                        </Button>
                    </div>
                    <Separator className="mb-4 mt-3" />
                </Row>
                <Card className="invitedInfluencers">
                    {RootStore.invitationStore.isBusy ? (
                        <Spinner color="primary" size={'lg'} className="spinner" />
                    ) : !RootStore.invitationStore.invitations?.length ? (
                        <EmptyState {...this.props} />
                    ) : (
                        <CardBody>
                            <InfiniteScroll
                                dataLength={this.state.filteredInvitations.length}
                                next={() => {
                                    if (!this.state.reachedEnd) {
                                        this.pageChange(this.state.pagination.page + 1);
                                    }
                                }}
                                hasMore={!this.state.reachedEnd}
                                loader={
                                    <div className="table-loader">
                                        <Spinner color="primary" size={'lg'} className="spinner" />
                                    </div>
                                }
                                hasChildren={true}
                            >
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th style={{ borderTop: 'none' }}></th>
                                            <th style={{ borderTop: 'none' }}>Influencer</th>
                                            <th style={{ borderTop: 'none' }}>Date</th>
                                            <th style={{ borderTop: 'none' }}>Ad</th>
                                            <th style={{ borderTop: 'none' }}>Type</th>
                                            <th style={{ borderTop: 'none' }}>Status</th>
                                            <th style={{ borderTop: 'none' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.filteredInvitations
                                            .sort((a, b) => moment(b.updated_at).diff(a.updated_at))
                                            .map((invitation: IInvitationModel) => (
                                                <tr key={'invitation' + invitation.id}>
                                                    <td>
                                                        <a
                                                            href={`/app/influencer-explorer/${invitation.target_id.id}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <LazyLoadImage
                                                                effect={'blur'}
                                                                alt="ainfluencer"
                                                                className="rounded"
                                                                style={{ width: '42px', height: '42px' }}
                                                                src={
                                                                    invitation.target_id && invitation.target_id.logo
                                                                        ? invitation.target_id.logo.getThumbnailUrl(
                                                                            42,
                                                                            42,
                                                                        )
                                                                        : this.url
                                                                }
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = profileImg;
                                                                }}
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a
                                                            href={`/app/influencer-explorer/${invitation.target_id.id}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {invitation.target_id.name}
                                                        </a>
                                                    </td>
                                                    <td>{invitation.created_at}</td>
                                                    <td>{truncate(invitation.ad_id.title, { length: 205 })}</td>
                                                    <td>
                                                        {invitation.ad_id.content_id.type === 'story'
                                                            ? 'Story Post'
                                                            : 'Feed Post'}
                                                    </td>
                                                    <td>
                                                        {invitation.status === 'pending' ? (
                                                            <p className="invitedInfluencers_sp">Sent</p>
                                                        ) : invitation.status === 'accepted' ? (
                                                            <p className="invitedInfluencers_sa">Offer</p>
                                                        ) : invitation.status === 'expired' ? (
                                                            <p className="invitedInfluencers_se">Expired</p>
                                                        ) : (
                                                            <p className="invitedInfluencers_so">Opened</p>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="invitedInfluencers_actions">
                                                            {invitation.status === 'pending' || invitation.status === 'seen' ? invitation.target_id && (
                                                                <>
                                                                    <Button
                                                                        color="link"
                                                                        onClick={() =>
                                                                            this.deleteInvitation(
                                                                                invitation.id,
                                                                                invitation.target_id.name,
                                                                            )
                                                                        }
                                                                    >
                                                                        Cancel Invitation
                                                                    </Button>
                                                                </>
                                                            )
                                                                : invitation.status === 'accepted'
                                                                    ? invitation.target_id && (
                                                                        <>
                                                                            {
                                                                                <Link
                                                                                    to={`/app/offers/${invitation.source_id}`}
                                                                                >
                                                                                    View Offer
                                                                                </Link>
                                                                            }
                                                                        </>
                                                                    )
                                                                    : null}
                                                            <Link
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                to={`/app/influencer-explorer/${invitation.target_id.id}`}
                                                            >
                                                                View Influencer
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </InfiniteScroll>
                        </CardBody>
                    )}
                </Card>
            </>
        );
    }
}
