import React from "react";
import { Col } from "reactstrap";

/**
* this method create custom col
* @param props , this props type object and get custom props
* @typeParam Props
*/
const Colxx = (props) => (
  <Col {...props} widths={["xxs", "xs", "sm", "md", "lg", "xl", "xxl"]} />
);

/**
* this method create custom separator
* @param props , this props type object and get custom props
* @typeParam Props
*/
const Separator = (props) => (
  <div className={`separator ${props.className}`}></div>
);
export { Colxx, Separator };
