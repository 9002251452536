/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/

export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 800;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
    { id: 'en', name: 'English - LTR', direction: 'ltr' },
    { id: 'es', name: 'Español', direction: 'ltr' },
    { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const searchPath = '/app/pages/search';
export const contactSupportLink = 'https://www.ainfluencer.com/contact-us';
export const tosUrl = 'https://www.ainfluencer.com/terms-of-service';

//export const servicePath = "http://192.168.1.191:8080";
//export const thumbnailApiPath = 'http://192.168.1.191:8080/files';

export const defaultProfilePicture = '/assets/img/profile.png';

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const isMultiColorActive = false;
export const isDarkSwitchActive = true;
export const defaultColor = 'light.purple';
export const defaultDirection = 'ltr';

export const apiMockMode = true;

// Connecty Cube settings

export const connectyCubeConfig = [
    {
        appId: 1384,
        authKey: '2VG9P8UhTgRXfvg',
        authSecret: 'jVOAVqvBAVQKUVQ',
    },
    {
        debug: {
            mode: 1,
        },
        chat: {
            streamManagement: {
                enable: true,
            },
        },
    },
];

export function isStaging() {
    const hostUrl = window.location.hostname;
    return hostUrl.indexOf('app-staging') !== -1 || hostUrl.indexOf('admin-staging') !== -1 || hostUrl === 'localhost';
}

let firebaseConfigData;

let servicePathValue = null;
let thumbnailApiPathValue = null;

if (isStaging()) {
    firebaseConfigData = {
        apiKey: 'AIzaSyC7M7TQKplmAz_K5q5evSsVUIxELPZcFdo',
        authDomain: 'ainfluencer-2ae68.firebaseapp.com',
        databaseURL: 'https://ainfluencer-2ae68.firebaseio.com',
        projectId: 'ainfluencer-2ae68',
        storageBucket: 'ainfluencer-2ae68.appspot.com',
        messagingSenderId: '126181247427',
        appId: '1:126181247427:web:52c185425250f86c459576',
    };
    servicePathValue = 'https://api-staging.ainfluencer.com/api';
    thumbnailApiPathValue = 'https://api-staging.ainfluencer.com/api/files';
} else {
    firebaseConfigData = {
        apiKey: 'AIzaSyAXdKe37u8RAtMk_xdhdN_zJ6tGWkzxg4U',
        authDomain: 'ainfluencer-prod.firebaseapp.com',
        databaseURL: 'https://ainfluencer-prod.firebaseio.com',
        projectId: 'ainfluencer-prod',
        storageBucket: 'ainfluencer-prod.appspot.com',
        messagingSenderId: '302957557474',
        appId: '1:302957557474:web:064232387855fd733450bb',
        measurementId: 'G-9RZV868N80',
    };
    servicePathValue = 'https://www.ainfluencer.com/api';
    thumbnailApiPathValue = 'https://www.ainfluencer.com/api/files';
}

export const servicePath = servicePathValue;
export const thumbnailApiPath = thumbnailApiPathValue;
export const firebaseConfig = firebaseConfigData;

export const userListExcelDownload = '/v1/admin/users/download/';

export const COUNTRIES = '/countries';

export const ADS_UPDATE_FILTER = '/ads/update-filters';

export const V2_TRANSACTION = '/v2/transactions';
// 'status=transferred&limit=20&search=Mirmoeini&start=2020-05-24%2011%3A05%3A08&end=2020-08-25%2011%3A05%3A08&sort=desc&type=charge'

export const v2TansactionHistory = (user = '') => `/v2/users/${user}/transaction-history`;

export const ELASTIC_SEARCH_V2 = '/v2/elastic/search';

export const PAYOUT_V2 = '/v2/payouts';
export const PAYMENT_V2 = '/v2/payments';
export const v2UsersCheckouts = (user) => `/v2/users/${user}/checkouts`;
export const v2UsersPayouts = (user) => `/v2/users/${user}/payouts`;
export const V2_ADS = '/v2/ads';
export const V2_USERS_CREDITS = '/v2/users/credits';

export const approvePostApi = (offer) => `/offers/${offer}/approve-post`;
