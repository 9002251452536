import moment from "moment";
import { IOfferModelSnapshotIn } from "../models/OfferModel";
import { IContentModelSnapshotIn } from "../models/ContentModel";
import { IMediaModelSnapshotIn } from "../models/MediaModel";
import { arrayGroupBy } from "../../lib/Utils";
import {
  OfferDetailedStatus,
  OfferModificationInfo,
  OfferStatus,
} from "./AbstractOfferHistory";

export enum ChangeableTypes {
  Offer = "App\\Offer",
  Content = "App\\Content",
  Media = "App\\Media",
}

export enum ChangeStatus {
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected",
}

export interface ChangeDescription {
  id?: number;
  user_id: number;
  changeable_type: ChangeableTypes;
  changeable_id: number;
  batch: number;
  operation: string;
  field?: string;
  value: string | number | null;
  status: ChangeStatus;
  created_at: string;
}

export default class BatchChange {
  private changes: ChangeDescription[] = [];

  constructor(changes: ChangeDescription[]) {
    this.changes = changes;
  }

  /**
  * this method force Status
  * @param status, this param get status
  */
  forceStatus(status: ChangeStatus): void {
    for (let change of this.changes) {
      change.status = status;
    }
  }

  /**
  * this method get Modification Info
  */
  getModificationInfo(): OfferModificationInfo {
    return {
      user_id: this.changes[0].user_id,
      moment: moment.utc(this.changes[0].created_at).local(),
    };
  }

  /**
  * this method get Offer Changes
  */
  private getOfferChanges(): ChangeDescription[] {
    return this.changes.filter(
      (change) =>
        change.changeable_type === "App\\Offer" && change.operation === "change"
    );
  }

  /**
  * this method get Content Changes
  */
  private getContentChanges(): ChangeDescription[] {
    return this.changes.filter(
      (change) =>
        change.changeable_type === "App\\Content" &&
        change.operation === "change"
    );
  }

  /**
  * this method get Media Changes
  */
  private getMediaChanges(): ChangeDescription[] {
    return this.changes.filter(
      (change) =>
        change.changeable_type === "App\\Media" && change.operation === "change"
    );
  }

  /**
  * this method get New Content Id
  */
  getNewContentId(): number {
    return parseInt(
      this.changes
        .filter(
          (change) =>
            change.changeable_type === "App\\Offer" &&
            change.field === "content_id" &&
            change.operation === "change"
        )[0]
        .value.toString()
    );
  }

  /**
  * this method get New Media Ids
  */
  getNewMediaIds(): number[] {
    return this.changes
      .filter(
        (change) =>
          change.changeable_type === "App\\Content" &&
          change.field === "App\\Media" &&
          change.operation === "add"
      )
      .map((change) => parseInt(change.value.toString()));
  }
  
  /**
  * this method get Removed Media Ids
  */
  getRemovedMediaIds(): number[] {
    return this.changes
      .filter(
        (change) =>
          change.changeable_type === "App\\Content" &&
          change.field === "App\\Media" &&
          change.operation === "delete"
      )
      .map((change) => parseInt(change.value.toString()));
  }

  /**
  * this method get All Content Model Ids
  */
  getAllContentModelIds(): number[] {
    return this.changes
      .filter(
        (change) =>
          change.changeable_type === "App\\Offer" &&
          change.field === "content_id"
      )
      .map((change) => parseInt(change.value.toString()))
      .concat(this.getContentChanges().map((change) => change.changeable_id));
  }

  /**
  * this method get All Media Model Ids
  */
  getAllMediaModelIds(): number[] {
    return this.getMediaChanges()
      .map((change) => change.changeable_id)
      .concat(this.getNewMediaIds())
      .concat(this.getRemovedMediaIds());
  }

  /**
  * this method check has Main Content Change
  */
  hasMainContentChange() {
    return (
      this.changes.filter(
        (change) =>
          change.changeable_type === "App\\Offer" &&
          change.field === "content_id" &&
          change.operation === "change"
      ).length > 0
    );
  }

  /**
  * this method check has Offer Update
  */
  hasOfferUpdate() {
    return this.getOfferChanges().length > 0;
  }

  /**
  * this method check has Content Update
  */
  hasContentUpdate() {
    return this.getContentChanges().length > 0;
  }

  /**
  * this method check has Media Change
  */
  hasMediaChange() {
    return this.getMediaChanges().length > 0;
  }

  /**
  * this method get Offer Snap Shot
  */
  getOfferSnapShot(): IOfferModelSnapshotIn {
    let obj: any = {};
    for (let change of this.getOfferChanges()) {
      obj[change.field] = change.value;
    }
    return obj;
  }

  /**
  * this method get Content SnapShot
  */
  getContentSnapShot(): IContentModelSnapshotIn {
    let obj: any = {};
    for (let change of this.getContentChanges()) {
      obj[change.field] = change.value;
    }
    return obj;
  }

  /**
  * this method get Media SnapShots
  */
  getMediaSnapShots(): IMediaModelSnapshotIn[] {
    let grp: { [key: string]: ChangeDescription[] } = arrayGroupBy(
      this.getMediaChanges(),
      (change: ChangeDescription) => {
        return change.changeable_id;
      }
    );
    let objList: any[] = [];
    for (let key in grp) {
      let obj: any = {
        id: key,
      };
      for (let change of grp[key]) {
        obj[change.field] = change.value;
      }
      objList.push(obj);
    }
    return objList;
  }

  /**
  * this method get Status batch
  */
  getStatus(): OfferDetailedStatus {
    for (const change of this.changes) {
      if (change.status === ChangeStatus.Rejected) {
        return OfferStatus.Rejected;
      }
    }
    for (const change of this.changes) {
      if (change.status === ChangeStatus.Pending) {
        return OfferStatus.Pending;
      }
    }
    // Accepted
    return OfferStatus.AwaitingPayment;
  }

  /**
  * this method check Status Update
  */
  isStatusUpdate(): boolean {
    return this.changes.length === 1 && this.changes[0].field === "status";
  }

  somethingChanged(): boolean {
    return this.changes[0].field !== undefined;
  }

  // Possible values: "pending" | "accepted" | "rejected"
  getStatusChangeValue(): OfferStatus {
    if (this.isStatusUpdate()) {
      return this.changes[0].value as OfferStatus;
    }
    // eslint-disable-next-line no-throw-literal
    throw "can not get status update value if the batch is not a status update";
  }
}
