import { Box, Grid, Tab, Tabs, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import createAdContext from '../../context';
import CreatAdFeed from './parts/feed';
import CreatAdStory from './parts/story';
import TabPanel from './parts/tabPanel';
import classes from './style.module.scss'

function CreatAdTabs() {
    const { data: { createAd: { feeds, story } } } = useContext(createAdContext)
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (feeds.images.length) setValue(0)
        if (story.images.length) setValue(1)
    }, [feeds, story])


    return (
        <Grid container flexDirection="column" className={classes.tabs}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} scrollButtons="auto" variant="scrollable">
                    <Tab label="Feed Post" style={{ textTransform: 'none' }} disabled={story.images.length} />
                    <Tab label="Stories" style={{ textTransform: 'none' }} disabled={feeds.images.length} />
                    <Tooltip title="Coming soon" placement="top">
                        <span>
                            <Tab label="Reels" style={{ textTransform: 'none' }} disabled={true} />
                        </span>
                    </Tooltip>
                </Tabs>
            </Box>

            <TabPanel
                title="Instagram Feed Post"
                description="Add combined up to 10 videos or images which you would like to show up on Influencers Feed. This could be replaced later by the content Influencers create on their own."
                value={value}
                index={0}
            >
                <CreatAdFeed />
            </TabPanel>
            <TabPanel title="Instagram Story Set" description="Add up to 10 videos of less than 15 seconds or photos or a combination of both" value={value} index={1}>
                <CreatAdStory />
            </TabPanel>
            <TabPanel title="asd" description="asd" value={value} index={2}>
                Item Three
            </TabPanel>
        </Grid>
    )
}

export default CreatAdTabs