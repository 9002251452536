import axios, { AxiosResponse, CancelToken } from "axios";
import { apiMockMode } from "../constants/defaultValues";
import ApiQueryBuilder from "./ApiQueryBuilder";
import BaseTransport from "./BaseTransport";

if (apiMockMode) {
  //ApiMockConfig.applyToAxios(axios);
}

export interface IUploadParam {
  name: string;
  file: File;
}

export default class Transport {
  public static post<T = any>(
    path: string,
    params: any,
    query: ApiQueryBuilder | null = null,
    additionalConfigs: any | null = null
  ): Promise<AxiosResponse<T>> {
    return BaseTransport.detectInvalidToken<T>(
      axios.post(BaseTransport.getEndPointPath(path), params, {
        ...BaseTransport.getConfig(query),
        ...additionalConfigs,
      })
    );
  }

  public static upload<T = any>(
    path: string,
    files: IUploadParam[],
    query: ApiQueryBuilder | null = null
  ): Promise<AxiosResponse<T>> {
    let data = new FormData();
    for (let file of files) {
      data.append(file.name, file.file);
    }

    return BaseTransport.detectInvalidToken<T>(
      axios.post(
        BaseTransport.getEndPointPath(path),
        data,
        BaseTransport.getConfig(query, {
          "Content-Type": "multipart/form-data",
        })
      )
    );
  }

  public static get<T = any>(
    path: string,
    query: ApiQueryBuilder | null = null,
    cancelToken: CancelToken | null = null
  ): Promise<AxiosResponse<T>> {
    return BaseTransport.detectInvalidToken<T>(
      axios.get(
        BaseTransport.getEndPointPath(path),
        BaseTransport.getConfig(query, null, cancelToken)
      )
    );
  }

  public static patch<T = any>(
    path: string,
    params: any,
    query: ApiQueryBuilder | null = null
  ): Promise<AxiosResponse<T>> {
    return BaseTransport.detectInvalidToken<T>(
      axios.patch(
        BaseTransport.getEndPointPath(path),
        params,
        BaseTransport.getConfig(query)
      )
    );
  }

  public static put<T = any>(
    path: string,
    params: any,
    query: ApiQueryBuilder | null = null
  ): Promise<AxiosResponse<T>> {
    return BaseTransport.detectInvalidToken<T>(
      axios.put(
        BaseTransport.getEndPointPath(path),
        params,
        BaseTransport.getConfig(query)
      )
    );
  }

  public static delete<T = any>(path: string): Promise<AxiosResponse<T>> {
    return BaseTransport.detectInvalidToken<T>(
      axios.delete(BaseTransport.getEndPointPath(path), {
        headers: BaseTransport.getHeaders(),
      })
    );
  }
}
