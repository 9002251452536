import React, { Component } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import User from "./authentication";
import RootStore from "../store/RootStore";
import { observer } from "mobx-react";

@observer
class Main extends Component<RouteComponentProps> {
  render() {
    return !RootStore.users.isLoggedIn() ? (
      <User />
    ) : RootStore.users.currentUser.is_influencer ? (
      <Redirect to={"/influencer-login"} />
    ) : RootStore.users.currentUser.is_admin ? (
      <Redirect to={"/admin"} />
    ) : (
      <Redirect to={"/app"} />
    );
  }
}
export default Main;
