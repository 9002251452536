import React from 'react';
import { Button } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import numbro from 'numbro';
import { truncate } from 'lodash';
import classNames from 'classnames';
import TooltipLite from 'react-tooltip-lite';
import Swal from 'sweetalert2';
import _ from 'lodash';
import RootStore from '../../../../store/RootStore';
import OfferDetails from '../../../common-components/OfferDetails';
import { IOfferModel } from '../../../../store/models/OfferModel';
import { imageURLRegex, videoURLRegex, hideSensitiveData, showLoading } from '../../../../lib/Utils';
import { observer } from 'mobx-react';
import { InfluencerProfileOverview } from '../../../common-components';
import profileImg from '../../../../assets/img/profilePlaceholder.jpg';
import { DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import { ThreeDotIcon } from '../../../../assets/icons';
import ReactTooltip from 'react-tooltip';

interface Props extends RouteComponentProps {
    offer: IOfferModel;
    controlOfferModal?: () => void;
}

export default observer(function OfferCard(props: Props) {
    const { offer, history } = props;
    const url = profileImg;

    const updateOfferState = async (value) => {
        showLoading('Loading...', 'Please wait while we process...');
        await RootStore.offerStore.updateOffersetstate(offer, value);
        if (value === 'reject') offer.getHistory().reject();
        Swal.close();
    };

    return (
        <>
            {RootStore.users.currentUser.is_admin ? (
                <>
                    <tr
                        className={classNames('offerCard', {
                            offerCard_highlighted: offer.getDialogue().unreadMessages > 0,
                        })}
                    >
                        <td className="offerCard__profileDetails">
                            {/* <img src={props.offer.getOpponentIdentity().logo.getThumbnailUrl(50, 50)} alt={'Profile'} /> */}
                            <div>
                                <p>@{offer.getTargetIntegration().username}</p>
                                <a
                                    href={'https://instagram.com/' + offer.getTargetIntegration().username + '/'}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {truncate(offer.getOpponentIdentity().name ?? '(No Name)', {
                                        length: 24,
                                    })}
                                </a>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex flex-column justify-content-center">
                                <p id={`OfferToolTipToggler_${offer.id}`} className="offerDetailsTooltipLink mb-0">
                                    {truncate(offer.ad_id.title, { length: 24 })}
                                </p>
                            </div>
                        </td>
                        <td className="offerCard__price">
                            {props.offer.price ? (
                                <span>
                                    {numbro(props.offer.price)
                                        .formatCurrency({
                                            mantissa: 2,
                                            thousandSeparated: true,
                                            optionalMantissa: true,
                                        })
                                        .toUpperCase()
                                        .replace(' ', '')}
                                </span>
                            ) : (
                                <span className="offerCard__noPrice">Suggest a Price</span>
                            )}
                        </td>
                        <td className="offerCard__status">
                            <span className={classNames(['bg-' + props.offer.getStatusColor()])}>
                                {props.offer.getHumanReadableStatus()}
                            </span>
                        </td>
                        <td></td>
                    </tr>
                </>
            ) : (
                <tr
                    className={classNames('offerCard', {
                        offerCard_highlighted: props.offer.getDialogue().unreadMessages > 0,
                    })}
                >
                    <div
                        // to={}
                        style={{ display: 'contents' }}
                        //@ts-ignore
                        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                            RootStore.offerStore.decreaseTotalUnreadMessages(props.offer.getDialogue().unreadMessages);
                            props.history.push(`/app/offers/${offer.id}`);
                        }}
                    >
                        <td className="offerCard__profileDetails">
                            <img
                                style={{ width: '50px', height: '50px' }}
                                src={
                                    props.offer.getOpponentIdentity().logo
                                        ? props.offer.getOpponentIdentity().logo.getThumbnailUrl(50, 50)
                                        : url
                                }
                                alt={'Profile'}
                            />
                            <div>
                                <InfluencerProfileOverview
                                    identity={props.offer.getOpponentIdentity()}
                                    integration={props.offer.getTargetIntegration()}
                                />
                                <span>
                                    {truncate(props.offer.getOpponentIdentity().name ?? '(No Name)', {
                                        length: 24,
                                    })}
                                </span>
                                {props.offer.state ? (
                                    <p className="offerCard__status" style={{ fontSize: '0.8em' }}>
                                        <span
                                            className={classNames(['bg-' + props.offer.getStateColor()])}
                                            style={{ color: 'white' }}
                                        >
                                            {props.offer.state}
                                            {props.offer.state === 'archive'
                                                ? 'd'
                                                : props.offer.state === 'delete'
                                                ? 'd'
                                                : 'ed'}
                                        </span>{' '}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </td>
                        <td>
                            <p
                                className="findInfluencers__header_icon_offer_control"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.controlOfferModal();
                                }}
                                data-tip
                                data-for="Control Offer"
                            >
                                <img
                                    alt="gear icon"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABNUlEQVRIibWVTU7DMBCFP1qJDeUQrYTgACCxLN71JF1EqrpFZc1t2gXpCQIr4Dz9k1DYTCR3Oo6nCjzpSUn83hvHiT3gQwXUipXTm0Uf2BgFtjLWGXdGeMPbnLkXXV8CI0MzafFbYyPJOsGrzOoLmAGPwIcxa8130c7EW0vWSdWtI8zLPXATF1j9YXjDVRMeHOI3YAxcCZ+A0uELAEVG9KzXM8Ii4y0a4RQ4JGaeg/UmB8k8QgB2Sjh2FNBLvJNnJvSOHTgKXCvPJh7sWY4zcaHu61SBYBR8cBS4V/d9jCVKfeTSUWBt+I4+cu43XbSEv2S8Bfg2Wim6gTAkZm5uNIClQ3wul/GrDvnnww7s49pqlZoVjuMa0g1n3hI+N/TJhpNCp5bpQaem7zkqfoBv4/mnjLXiF5CfCtFi5/niAAAAAElFTkSuQmCC"
                                />
                            </p>
                            <ReactTooltip id="Control Offer" type="dark" effect="solid">
                                <p>Advanced Settings</p>
                            </ReactTooltip>
                        </td>
                        <td>
                            <div className="d-flex flex-column justify-content-center">
                                <TooltipLite
                                    mouseOutDelay={200}
                                    background="transparent"
                                    content={
                                        <div className="offerDetailsTooltip">
                                            <OfferDetails offer={props.offer} />
                                            <Button
                                                color="primary"
                                                className="mx-auto"
                                                onClick={(event: React.MouseEvent) =>
                                                    history.push(`/app/offers/${offer.id}`)
                                                }
                                            >
                                                Open Offer
                                            </Button>
                                        </div>
                                    }
                                >
                                    <p id={`OfferToolTipToggler_${offer.id}`} className="offerDetailsTooltipLink mb-0">
                                        {truncate(offer.ad_id?.title, { length: 24 })}
                                    </p>
                                </TooltipLite>
                            </div>
                        </td>
                        <td>
                            <div className="offerCard__lastMessage">
                                <div>
                                    <p
                                        className={classNames({
                                            offerCard__lastMessage_unread: props.offer.getDialogue().unreadMessages > 0,
                                        })}
                                    >
                                        {!_.isArray(props.offer.last_message.attributes) &&
                                        !_.isEmpty(props.offer.last_message.attributes)
                                            ? props.offer.getLastMessageStatus()
                                            : imageURLRegex.test(props.offer.last_message.message)
                                            ? 'Image'
                                            : videoURLRegex.test(props.offer.last_message.message)
                                            ? 'Video'
                                            : hideSensitiveData(props.offer.last_message.message).msg}
                                    </p>
                                </div>
                                {props.offer.getDialogue().getLastMessageMoment() !== null && (
                                    <span
                                        className={classNames({
                                            offerCard__lastMessage_unread: props.offer.getDialogue().unreadMessages > 0,
                                        })}
                                    >
                                        {props.offer.getDialogue().getLastMessageMoment().format('YYYY-MM-DD HH:mm')}
                                    </span>
                                )}
                            </div>
                        </td>
                        <td className="offerCard__price">
                            {props.offer.price ? (
                                <span>
                                    {numbro(props.offer.price)
                                        .formatCurrency({
                                            mantissa: 2,
                                            thousandSeparated: true,
                                            optionalMantissa: true,
                                        })
                                        .toUpperCase()
                                        .replace(' ', '')}
                                </span>
                            ) : (
                                <span className="offerCard__noPrice">Suggest a Price</span>
                            )}
                        </td>
                        <td className="offerCard__status">
                            <span className={classNames(['bg-' + props.offer.getStatusColor()])}>
                                {props.offer.getHumanReadableStatus()}
                            </span>
                        </td>
                    </div>
                    <td className="text-center">
                        {!offer.state ? (
                            <UncontrolledDropdown className="dropdown-menu-left">
                                <DropdownToggle color="light" className="p-1">
                                    <ThreeDotIcon />
                                </DropdownToggle>
                                <DropdownMenu className="mt-2" left container="body">
                                    <DropdownItem onClick={() => updateOfferState('reject')}>
                                        {`${offer.state === 'reject' ? 'Unreject' : 'Reject'} Offer`}
                                    </DropdownItem>
                                    {/* <DropdownItem>Flag Read </DropdownItem> */}
                                    <DropdownItem onClick={() => updateOfferState('archive')}>
                                        {`${offer.state === 'archive' ? 'Unarchive' : 'Archive'} Offer`}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => updateOfferState('delete')}>
                                        {`${offer.state === 'delete' ? 'Undelete' : 'Delete'} Offer`}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => updateOfferState('spam')}>
                                        {`${offer.state === 'spam' ? 'Unspam ' : 'Spam '} Offer`}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => updateOfferState('block')}>
                                        {`${offer.state === 'block' ? 'Unblock' : 'Block'} User`}
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        ) : (
                            <Button
                                color="primary"
                                onClick={() => updateOfferState(offer.state)}
                                size="sm"
                            >{`Un${offer.state}`}</Button>
                        )}
                    </td>
                </tr>
            )}
        </>
    );
});
