import React, { Component } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import Swal from 'sweetalert2';

import { Separator } from '../../../../template/components/common/CustomBootstrap';
import RootStore from '../../../../store/RootStore';
import { Spinner } from 'reactstrap';
import { FilterType, Range } from '../../../common-components/Filter/AbstractFilterSet';
import ControlOfferPopup from '../../../common-components/ControlOfferPopup';
import AdCard from './AdCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import AdCardMobileView from './AdCardMobileView';
import { SortOrder, FilterGroup, Pagination } from '../../../../lib/QueryTypes';
import NoAds from '../../../../assets/img/NoAds.png';
import { RightArrowIcon } from '../../../../assets/icons';
import { IAdModel } from '../../../../store/models/AdModel';
import ClientSideFilterSet from '../../../common-components/Filter/ClientSideFilterSet';
import FilterComponent from '../../../common-components/Filter/FilterComponent';
import { ajaxErrorAlert, handleError, showLoading, isMobileAndTablet } from '../../../../lib/Utils';
import moment from 'moment';
import { AdPreviewModal } from '../../../common-components';

interface Props extends RouteComponentProps {}

interface State {
    scrollTop: number;
    ads: IAdModel[];
    dataFetched: boolean;
    filter_groups: FilterGroup[];
    sort: { key: string; direction: SortOrder };
    pagination: Pagination;
    reachedEnd: boolean;
    loading: boolean;
    adModal: boolean;
    adDataModal: any;
    controlOfferModalisOpen?: boolean;
    controlOfferAd?: any;
}

// interface Filters {
//     searchTerm: string | null;
//     filters: any;
// }

interface IAdModelFlattened {
    [key: string]: any;
    adId: number;
    campaignId: number;
    campaignTitle: string;
    adApproval: string;
    adStatus: string;
    adTitle: string;
    adDescription: string;
}

export default class AdsTab extends Component<Props, State> {
    private readonly filterSet: ClientSideFilterSet<IAdModel, IAdModelFlattened>;

    constructor(props: any) {
        super(props);

        this.state = {
            scrollTop: 0,
            ads: [],
            sort: {
                key: 'updated_at',
                direction: SortOrder.DESCENDING,
            },
            filter_groups: [],
            dataFetched: false,
            reachedEnd: false,
            loading: false,
            adModal: false,
            adDataModal: null,
            pagination: {
                totalPages: null,
                page: 1,
                pageSize: 10,
            },
            controlOfferModalisOpen: false,
            controlOfferAd: null,
        };
        this.filterSet = new ClientSideFilterSet<IAdModel, IAdModelFlattened>({
            getModels: () => RootStore.adStore.getAllAds(),
            flattenFunction: (ad: IAdModel) => {
                return {
                    adId: ad?.id,
                    campaignId: ad?.campaign_id?.id,
                    campaignTitle: ad?.campaign_id?.title,
                    adApproval: ad?.approval,
                    adStatus: ad?.status,
                    adTitle: ad?.title,
                    adDescription: ad?.description,
                };
            },
            onFilteredCallback: (filteredAds: IAdModel[]) => {
                this.setState({
                    ads: filteredAds,
                });

                RootStore.adStore.setFilters(this.filterSet.getFilterValues().filters);
            },
            fuzzySearchKeys: ['adDescription', 'adTitle'],
        });

        this.filterSet.addFilter({
            name: 'adId',
            title: 'Ad',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [
                null,
                ...RootStore.adStore
                    .getAllAds()
                    .map((ad: IAdModel) => ad.id)
                    .filter((ad_id: number, index: number, self: number[]) => index === self.indexOf(ad_id)),
            ],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value === null) return 'All';
                return RootStore.adStore?.getExistingAd(parseInt(value.toString())).title;
            },
        });

        this.filterSet.addFilter({
            name: 'adApproval',
            title: 'Approval',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [null, 'pending', 'approved', 'rejected'],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value) {
                    return value.toString();
                } else {
                    return 'All';
                }
            },
        });

        this.filterSet.addFilter({
            name: 'adStatus',
            title: 'Status',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [null, 'expired', 'approved', 'active', 'draft', 'paused'],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value === null) return 'All';
                return value.toString();
            },
        });

        this.onDeleteAd = this.onDeleteAd.bind(this);
        this.onPauseAd = this.onPauseAd.bind(this);
        this.inviteOnlyHandle = this.inviteOnlyHandle.bind(this);
        this.onActiveAd = this.onActiveAd.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.fetchAds = this.fetchAds.bind(this);
        this.duplicateAd = this.duplicateAd.bind(this);
    }

    async componentDidMount(): Promise<void> {
        await this.fetchAds();
        this.setState({ ads: RootStore.adStore?.getAllAds() });

        const havingFilters = RootStore.adStore?.filterSet;
        if (havingFilters) {
            Object.keys(havingFilters).map((filter) => {
                this.filterSet.setFilterValue(filter, havingFilters[filter]);
                return filter;
            });
        }
    }
    reloadList = async () => {
        await this.fetchAds();
        this.setState({ ads: RootStore.adStore?.getAllAds() });
    };

    async onDeleteAd(adId: number) {
        try {
            await Swal.fire({
                title: 'Warning',
                text: 'Are you sure you want to delete this ad?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Delete It',
                allowOutsideClick: false,
            })
                .then(async (res) => {
                    if (res.value) {
                        showLoading('Please Wait...', 'Please wait while we deleting ad');
                        await RootStore.adStore?.removeAd(adId);
                        this.setState({
                            ads: [...RootStore.adStore?.getAllAds()],
                        });
                        Swal.close();

                        Swal.fire({
                            type: 'success',
                            title: 'Ad has been deleted successfully!',
                            showCancelButton: false,
                            showConfirmButton: true,
                        });
                    }
                })
                .catch((e) => {
                    handleError(e);
                    ajaxErrorAlert('There was a problem with deleting ad you selected.');
                });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem with deleting ad you selected');
        }
    }
    async duplicateAd(adId: number): Promise<void> {
        try {
            showLoading('Loading...', 'Please wait while we creating your duplicate Ad...');
            let ad = RootStore.adStore.getExistingAdById(adId);
            await RootStore.adStore.create({
                duplicate_id: adId,
                campaign_id: ad?.campaign_id?.id,
                title: ad?.title,
                description: ad?.description,
                content_id: ad?.content_id?.id,
                schedule: ad?.schedule ? ad?.schedule : null,
                fixedSchedule: ad?.fixedDuration,
                fixedDuration: ad?.fixedDuration,
                duration: ad?.duration ? ad?.duration : null,
                displayBioLink: false,
                bio_link: ad?.bio_link,
                exclusivity: ad?.exclusivity,
                invite_only: ad?.invite_only,
            });
            this.setState({ ads: RootStore.adStore?.getAllAds() });

            Swal.close();
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem in duplicate the Ad.');
        }
    }

    async onPauseAd(adId: number) {
        try {
            showLoading('Please Wait...', 'Please wait while we are pausing ad');
            await RootStore.adStore.updateAd(adId);

            this.setState({ ads: RootStore.adStore?.getAllAds() });

            this.filterSet.applyFilters();
            Swal.fire({
                type: 'success',
                title: 'Ad has been paused successfully!',
            });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem with pausing ad you selected');
        }
    }

    async onActiveAd(adId: number) {
        try {
            await Swal.fire({
                title: 'Warning',
                text: 'Are you sure you want to Activate this ad?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Activate It',
                allowOutsideClick: false,
            })
                .then(async (res) => {
                    if (res.value) {
                        showLoading('Please Wait...', 'Please wait while we are Activating ad');
                        await RootStore.adStore.updateAd(adId);

                        this.setState({ ads: RootStore.adStore?.getAllAds() });

                        this.filterSet.applyFilters();
                        Swal.fire({
                            type: 'success',
                            title: 'Ad has been Activated successfully!',
                        });
                    }
                })
                .catch((e) => {
                    handleError(e);
                    ajaxErrorAlert('There was a problem with Activating ad you selected.');
                });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem with Activating ad you selected');
        }
    }

    async inviteOnlyHandle(adId: number) {
        try {
            showLoading('Please Wait...', 'Please wait while we are Updating ad');
            await RootStore.adStore.updateInviteAd(adId);

            this.setState({ ads: RootStore.adStore?.getAllAds() });

            this.filterSet.applyFilters();
            Swal.fire({
                type: 'success',
                title: 'Ad has been Updated successfully!',
            });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem with Updated ad you selected');
        }
    }

    async fetchAds(loadMore: boolean = false) {
        this.setState({ dataFetched: false });
        let res = await RootStore.adStore.fetchAllAdsData(
            this.state?.pagination,
            this.state?.filter_groups,
            this.state?.sort,
            loadMore,
        );

        this.setState({
            dataFetched: true,
            ads: RootStore.adStore?.getAllAds(),
            pagination: {
                pageSize: this.state?.pagination?.pageSize,
                //@ts-ignore
                totalPages: res?.totalPages,
                //@ts-ignore
                page: res?.page,
            },
            loading: false,
            reachedEnd: res?.page === res?.totalPages,
        });
    }

    async loadMoreAds() {
        if (!this.state?.reachedEnd) {
            this.setState({ dataFetched: false });
            let res = await RootStore.adStore.fetchAllAdsData(
                this.state?.pagination,
                this.state?.filter_groups,
                this.state?.sort,
                !this.state?.reachedEnd,
            );
            this.setState({
                dataFetched: true,
                ads: RootStore.adStore.getAllAds(),
                pagination: {
                    pageSize: this.state?.pagination?.pageSize,
                    totalPages: res?.totalPages,
                    page: res?.page,
                },
                loading: false,
                reachedEnd: res?.page === res?.totalPages,
            });
        }
    }

    async pageChange(page: number): Promise<void> {
        await this.setState({
            pagination: { ...this.state?.pagination, page },
        });
        await this.loadMoreAds();

        this.filterSet.applyFilters();
    }

    controlOfferModal = (ad) => {
        console.log('SSS ', ad);
        this.setState({
            controlOfferAd: ad,
            controlOfferModalisOpen: true,
        });
    };

    onCloseControlOfferModal = () => {
        this.setState({
            controlOfferAd: null,
            controlOfferModalisOpen: false,
        });
    };

    render() {
        return (
            <>
                {this.filterSet && <FilterComponent filterSet={this.filterSet} />}
                {RootStore.adStore.isBusy ? (
                    <Spinner color="primary" size={'lg'} className="spinner" />
                ) : this.state?.ads?.length > 0 ? (
                    <>
                        {!isMobileAndTablet() ? <Separator className="mb-1 mt-3" /> : null}
                        <InfiniteScroll
                            dataLength={this.state?.ads?.length}
                            next={() => {
                                if (!this.state?.reachedEnd) {
                                    this.pageChange(this.state?.pagination?.page + 1);
                                }
                            }}
                            hasMore={!this.state?.reachedEnd}
                            loader={
                                <div>
                                    <Spinner color="primary" size={'lg'} className="spinner" />
                                </div>
                            }
                            hasChildren={true}
                        >
                            <table className="adsList">
                                <thead className="adsList__header">
                                    {!isMobileAndTablet() ? (
                                        <tr>
                                            <th>Ad</th>
                                            <th>Invite Influencers</th>
                                            <th>Offers</th>
                                            <th>Deals</th>
                                            <th>Impression</th>
                                            <th>Invite Only</th>
                                            <th>Pause</th>
                                            <th>
                                                <NavLink
                                                    className="adsList__createAdBtn"
                                                    to={`/app/ads/ad/objectives/`}
                                                >
                                                    Create a New Ad
                                                </NavLink>
                                            </th>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <th>
                                                <NavLink
                                                    className="adsList__createAdBtn"
                                                    to={`/app/ads/ad/objectives/`}
                                                >
                                                    Create a New Ad
                                                </NavLink>
                                            </th>
                                        </tr>
                                    )}
                                </thead>
                                <tbody>
                                    {this.state.adModal && (
                                        <AdPreviewModal
                                            getAdService={true}
                                            isOpen={true}
                                            ad={this.state.adDataModal}
                                            onClose={() => this.setState({ adModal: false })}
                                        />
                                    )}

                                    {this.state.ads
                                        .sort((a, b) => moment(b.updated_at).diff(a.updated_at))
                                        .map((ad) => {
                                            if (!isMobileAndTablet()) {
                                                return (
                                                    <AdCard
                                                        ad={ad}
                                                        openModal={(ad: any) => {
                                                            this.setState({
                                                                adDataModal: ad,
                                                                adModal: true,
                                                            });
                                                        }}
                                                        key={`ad_${ad.id}`}
                                                        onDelete={this.onDeleteAd}
                                                        onPauseAd={this.onPauseAd}
                                                        onActiveAd={this.onActiveAd}
                                                        inviteOnlyHandle={this.inviteOnlyHandle}
                                                        duplicateAd={this.duplicateAd}
                                                        controlOfferModal={() => this.controlOfferModal(ad)}
                                                        {...this.props}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <AdCardMobileView
                                                        ad={ad}
                                                        openModal={(ad: any) => {
                                                            this.setState({
                                                                adDataModal: ad,
                                                                adModal: false,
                                                            });
                                                        }}
                                                        key={`ad_${ad?.id}`}
                                                        onDelete={this.onDeleteAd}
                                                        onPauseAd={this.onPauseAd}
                                                        onActiveAd={this.onActiveAd}
                                                        inviteOnlyHandle={this.inviteOnlyHandle}
                                                        duplicateAd={this.duplicateAd}
                                                        controlOfferModal={() => this.controlOfferModal(ad)}
                                                        {...this.props}
                                                    />
                                                );
                                            }
                                        })}
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    </>
                ) : (
                    <div className="emptyState">
                        <img src={NoAds} alt="noAds" className="no-offers-img" width={'20%'} />
                        <p className="lead">You have not created any ads for this campaign!</p>
                        <NavLink className={'btn btn-primary btn-sm pull-left'} to={`/app/ads/ad/objectives/`}>
                            Create A New Ad
                            <RightArrowIcon />
                        </NavLink>
                    </div>
                )}
                {this.state.controlOfferModalisOpen && (
                    <ControlOfferPopup
                        isOpen={this.state.controlOfferModalisOpen}
                        onClose={this.onCloseControlOfferModal}
                        ad={this.state.controlOfferAd}
                        reloadList={this.reloadList}
                    />
                )}
            </>
        );
    }
}
