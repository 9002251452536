import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { Row, Card, Table, CardBody, Badge, Spinner } from 'reactstrap';
import RootStore from '../../../../store/RootStore';
// import TooltipLite from 'react-tooltip-lite';
import { SortOrder } from '../../../../lib/QueryTypes';
import DatatablePagination from '../../../common-components/DatatablePagination';
import noImage from '../../../../assets/img/profilePlaceholder.jpg';
// import { InformationIcon } from '../../../../assets/icons';
import DisapproveReasonModal from '../../../app/offers/components/DisapproveReasonModal';
import moment from 'moment';
import { ajaxErrorAlert, handleError, momentDateTimeFormat } from '../../../../lib/Utils';
import { Colxx } from '../../../../template/components/common/CustomBootstrap';
import { EyeIcon } from '../../../../assets/icons';
import { Button } from 'reactstrap';
// @ts-ignore
import ReactTooltip from 'react-tooltip';

import { AdPreviewModal } from '../../../common-components/AdPreviewModalV2';
import { IAdModel } from '../../../../store/models/AdModel';
import { NotificationManager } from '../../../../template/components/common/react-notifications';
// import HistoryIcon from '../../../../assets/icons/TimeLeft.svg';
import ShowDetails from './ShowDetails';

interface State {
    loading: boolean;
    publishJobs: any;
    sort: { key: string; direction: SortOrder };
    pagination: {
        totalPages: number | null;
        page: number;
        pageSize: number;
    };
    adID: number | null;
    displayAdPreview: boolean;
    ad: IAdModel;
    identityAd: any;
    metrics: object;
    adIDdetails: number | null;
}
interface Props {}

@observer
export default class Report extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            sort: {
                key: 'updated_at',
                direction: SortOrder.DESCENDING,
            },
            publishJobs: [],
            pagination: {
                totalPages: null,
                page: 0,
                pageSize: 20,
            },
            adID: null,
            displayAdPreview: false,
            ad: null,
            identityAd: null,

            adIDdetails: null,
            metrics: null,
        };
        this.fetchJob = this.fetchJob.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.publishButtonAction = this.publishButtonAction.bind(this);
        this.showdetails = this.showdetails.bind(this);
    }

    showdetails(details) {
        this.setState({ metrics: details });
        this.setState({ adIDdetails: 1 });
        return true;
    }

    onDisplayAdPreview(ad: IAdModel, identity: any) {
        this.setState({
            ad: ad,
            identityAd: identity,
            displayAdPreview: !this.state.displayAdPreview,
        });
    }

    async typeChanged(e: any, d: any) {
        try {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Are you sure you want to change approval?')) e.target.value = d.url_approval || '0';
            else {
                const { value } = e.target;
                console.log('VAL ', value, d.id);

                if (value === 'admin:disapproved') {
                    this.setState({ adID: d.id });
                    return true;
                } else {
                    this.setState({ loading: true });
                    await RootStore.publishJobStore.sendApprovebyadminRequest(d.id, value).then((res) => {
                        if (res)
                            NotificationManager.success(
                                'Done',
                                `Publish job has been Approved!`,
                                3000,
                                null,
                                null,
                                'filled',
                            );
                    });
                    await this.fetchJob();
                }
            }
        } catch (err) {
            console.log('EEEE ', err);
            handleError(err);
            ajaxErrorAlert('There was a problem with approval method');
        }
    }

    async publishButtonAction(action: string, reson?: string) {
        this.setState({ loading: true });
        await RootStore.publishJobStore
            .sendApprovebyadminRequestV2(this.state.adID, action, reson)
            .then(async (res) => {
                if (res)
                    NotificationManager.success(
                        'Done',
                        `Publish job has been Disapproved!`,
                        3000,
                        null,
                        null,
                        'filled',
                    );
            });
        await this.fetchJob();
        this.setState({ loading: false });
    }

    async componentDidMount() {
        await this.fetchJob();
        // this.setState({ publishJobs: RootStore.publishJobStore.getAlljobsAdmin() });
    }

    async fetchJob() {
        this.setState({ loading: true });
        let res = await RootStore.publishJobStore.fetchAllPaginateV2(this.state.pagination, this.state.sort);
        this.setState({
            loading: false,
            publishJobs: res.publish_jobs,
            pagination: {
                pageSize: this.state.pagination.pageSize,
                //@ts-ignore
                totalPages: res.totalPages,
                //@ts-ignore
                page: res.page,
            },
        });
    }
    async pageChange(page: number): Promise<void> {
        await this.setState({
            pagination: { ...this.state.pagination, page },
        });
        await this.fetchJob();
    }

    render() {
        return (
            <Fragment>
                {this.state.displayAdPreview && (
                    <AdPreviewModal
                        getAdService={true}
                        isOpen={this.state.displayAdPreview}
                        ad={this.state.ad}
                        identity={this.state.identityAd}
                        onClose={() =>
                            this.setState({
                                displayAdPreview: false,
                            })
                        }
                        clear="report-component"
                    />
                )}

                <DisapproveReasonModal
                    isOpen={this.state.adID !== null}
                    onClose={() => this.setState({ adID: null, loading: false })}
                    publishButtonAction={(reason) => this.publishButtonAction('admin:disapproved', reason)}
                />

                <ShowDetails
                    isOpendetails={this.state.adIDdetails !== null}
                    onClosedetails={() => this.setState({ adIDdetails: null })}
                    metrics={this.state.metrics}
                ></ShowDetails>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={'customBreadCrumb'}>
                            <h1>Publish Jobs</h1>
                            <nav
                                className={'pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block'}
                                aria-label="breadcrumb"
                            >
                                <ol className={'breadcrumb'}></ol>
                            </nav>
                        </div>
                    </div>
                </div>

                <Card className="mb-4">
                    <CardBody>
                        <Row>
                            <Colxx>
                                {RootStore.publishJobStore.isBusy || this.state.loading ? (
                                    <Spinner />
                                ) : (
                                    <div style={{ overflowX: 'auto' }}>
                                        <Table striped>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>IG - Email - IG status</th>
                                                    <th>Ad Status</th>
                                                    <th>Type</th>
                                                    <th>Post Status</th>
                                                    <th>Manual / Auto</th>
                                                    <th>Admin Approval</th>
                                                    {/* <th>IG Status</th> */}
                                                    <th>Offer Created At</th>
                                                    <th>IG Status On Publish</th>
                                                    <th>To be Publish At</th>
                                                    <th>Published Date</th>
                                                    <th>Duration </th>
                                                    <th>Brand </th>
                                                    <th>Ad Title</th>
                                                    <th>Ad Preview</th>
                                                    <th>َAmount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.publishJobs
                                                    .sort((a: any, b: any) => moment(b.start).diff(a.start))
                                                    .map((item: any, index: number) => {
                                                        const offerDate = item?.offer?.created_at;
                                                        return item.offer?.id ? (
                                                            <tr
                                                                key={item.id}
                                                                className={
                                                                    moment().isSame(item.start, 'day')
                                                                        ? 'todaypublishjob'
                                                                        : ''
                                                                }
                                                            >
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <div className={'d-flex align-items-center'}>
                                                                        <img
                                                                            className="rounded"
                                                                            width={50}
                                                                            height={50}
                                                                            src={item.identity.logo.url || noImage}
                                                                            alt="influencer pic"
                                                                        />
                                                                        <span className="pl-2">
                                                                            <strong>
                                                                                <a
                                                                                    href={`http://instagram.com/${item.integration?.username}/`}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    @{item.integration?.username}
                                                                                </a>
                                                                            </strong>
                                                                            <br />
                                                                            &nbsp;
                                                                            {item.identity?.user?.email}
                                                                            <br />
                                                                            {item.integration.healthy ? (
                                                                                <Badge color="success">Active</Badge>
                                                                            ) : (
                                                                                <Badge color="warning">Inactive</Badge>
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {item.offer?.ad?.status}
                                                                    <br />
                                                                    {item.offer?.ad?.approval}
                                                                </td>
                                                                <td>
                                                                    {item.content?.type === 'post' ? 'Feed' : 'Story'}
                                                                </td>
                                                                <td>
                                                                    {moment(item.start).isAfter() ? (
                                                                        'Due Future'
                                                                    ) : item.status === 'failed' ? (
                                                                        <span
                                                                            style={{
                                                                                color: 'red',
                                                                                fontWeight: 'bold',
                                                                            }}
                                                                        >
                                                                            Failed
                                                                        </span>
                                                                    ) : item.status === 'expired' ? (
                                                                        <span
                                                                            style={{
                                                                                color: 'orange',
                                                                                fontWeight: 'bold',
                                                                            }}
                                                                        >
                                                                            Expired
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            style={{
                                                                                color: 'green',
                                                                                fontWeight: 'bold',
                                                                            }}
                                                                        >
                                                                            Successful
                                                                        </span>
                                                                    )}
                                                                    {/* {item.error ? (
                                                                        <TooltipLite
                                                                            mouseOutDelay={200}
                                                                            background="#000"
                                                                            content={
                                                                                <p style={{ color: 'white' }}>
                                                                                    {item.error?.type}
                                                                                </p>
                                                                            }
                                                                        >
                                                                            <InformationIcon
                                                                                style={{ width: '20px' }}
                                                                            />
                                                                        </TooltipLite>
                                                                    ) : (
                                                                        ''
                                                                    )} */}
                                                                </td>

                                                                <td>
                                                                    {item.published_url ? (
                                                                        <a
                                                                            href={`${item.published_url}`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <Badge color={'secondary'}>
                                                                                &#128279;&nbsp;{' '}
                                                                                {item.manual ? ' Manual' : ' Auto'}
                                                                            </Badge>
                                                                        </a>
                                                                    ) : (
                                                                        <Badge color={'light'}>
                                                                            {item.manual
                                                                                ? 'Manual'
                                                                                : item.manual === null
                                                                                ? 'Not Selected'
                                                                                : 'Auto'}
                                                                        </Badge>
                                                                    )}
                                                                    <br />
                                                                </td>

                                                                <td>
                                                                    <select
                                                                        className="form-control"
                                                                        name="duration_type"
                                                                        onChange={(e) => this.typeChanged(e, item)}
                                                                        style={{ width: '140px' }}
                                                                    >
                                                                        <option
                                                                            value="0"
                                                                            selected={!item.url_approval ? true : false}
                                                                            disabled
                                                                            hidden
                                                                        >
                                                                            Select action:
                                                                        </option>
                                                                        <option
                                                                            defaultValue={
                                                                                item.url_approval ===
                                                                                    'admin:approved' &&
                                                                                item.url_approval
                                                                            }
                                                                            value="admin:approved"
                                                                        >
                                                                            Admin approved
                                                                        </option>
                                                                        <option
                                                                            selected={
                                                                                item.url_approval ===
                                                                                    'admin:disapproved' &&
                                                                                item.url_approval
                                                                            }
                                                                            value="admin:disapproved"
                                                                        >
                                                                            Admin disapproved
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                {/* <td>
                                                                    {item.integration.healthy ? (
                                                                        <Badge color="success">Active</Badge>
                                                                    ) : (
                                                                        <Badge color="warning">Inactive</Badge>
                                                                    )}
                                                                </td> */}
                                                                <td>
                                                                    {offerDate ? (
                                                                        <>
                                                                            {' '}
                                                                            {moment(
                                                                                offerDate,
                                                                                momentDateTimeFormat,
                                                                            ).diff(moment.now(), 'days') >= 0
                                                                                ? moment(
                                                                                      offerDate,
                                                                                      momentDateTimeFormat,
                                                                                  ).diff(moment.now(), 'days') === 0
                                                                                    ? moment(
                                                                                          offerDate,
                                                                                          momentDateTimeFormat,
                                                                                      ).diff(moment.now(), 'hours') +
                                                                                      'h'
                                                                                    : moment(
                                                                                          offerDate,
                                                                                          momentDateTimeFormat,
                                                                                      ).diff(moment.now(), 'days') + 'd'
                                                                                : moment(
                                                                                      offerDate,
                                                                                      momentDateTimeFormat,
                                                                                  ).format('DD/MM/YYYY, h:mm A')}
                                                                        </>
                                                                    ) : (
                                                                        '...'
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.ig_status_on_publish === 'healthy' ? (
                                                                        <Badge color="success">Active</Badge>
                                                                    ) : item.ig_status_on_publish === 'unhealthy' ? (
                                                                        <Badge color="warning">Inactive</Badge>
                                                                    ) : item.ig_status_on_publish ===
                                                                      'unauthenticated' ? (
                                                                        <Badge color="danger">Disconnected</Badge>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </td>

                                                                <td>
                                                                    {moment(item.start, momentDateTimeFormat).diff(
                                                                        moment.now(),
                                                                        'days',
                                                                    ) >= 0
                                                                        ? moment(item.start, momentDateTimeFormat).diff(
                                                                              moment.now(),
                                                                              'days',
                                                                          ) === 0
                                                                            ? moment(
                                                                                  item.start,
                                                                                  momentDateTimeFormat,
                                                                              ).diff(moment.now(), 'hours') + 'h'
                                                                            : moment(
                                                                                  item.start,
                                                                                  momentDateTimeFormat,
                                                                              ).diff(moment.now(), 'days') + 'd'
                                                                        : moment(
                                                                              item.start,
                                                                              momentDateTimeFormat,
                                                                          ).format('DD/MM/YYYY, h:mm A')}
                                                                </td>
                                                                <td>
                                                                    {item.published_date
                                                                        ? moment(
                                                                              item.published_date,
                                                                              momentDateTimeFormat,
                                                                          ).format('DD/MM/YYYY, h:mm A')
                                                                        : ''}
                                                                </td>
                                                                <td>
                                                                    {item.duration === 0
                                                                        ? 'Permanent'
                                                                        : moment
                                                                              .duration(item.duration, 'seconds')
                                                                              .humanize()}
                                                                    {/* <img
                                                                        onClick={() => this.showdetails(item?.metrics)}
                                                                        style={{ width: '20px' }}
                                                                        src={HistoryIcon}
                                                                        alt=""
                                                                    /> */}
                                                                </td>

                                                                <td>
                                                                    {item.offer ? (
                                                                        <span>
                                                                            {item.offer?.target_identity?.name}
                                                                            <br />
                                                                            <span className="text-muted">
                                                                                {
                                                                                    item.offer.target_identity?.user
                                                                                        ?.email
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                    ) : (
                                                                        'No Brand'
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.offer ? (
                                                                        <span>
                                                                            {item.offer.ad.title.length > 20
                                                                                ? item.offer.ad.title.substr(0, 20) +
                                                                                  '...'
                                                                                : item.offer.ad.title}
                                                                        </span>
                                                                    ) : (
                                                                        'No Ad'
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item?.offer ? (
                                                                        <div>
                                                                            <Button
                                                                                data-tip
                                                                                data-for="Preview"
                                                                                style={{ padding: '3px 10px' }}
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    this.onDisplayAdPreview(
                                                                                        item.offer?.ad,
                                                                                        item.offer?.target_identity,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <EyeIcon
                                                                                    style={{
                                                                                        width: '1.2em',
                                                                                        fill: '#ffffff',
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                            <ReactTooltip
                                                                                id="Preview"
                                                                                type="dark"
                                                                                effect="solid"
                                                                            >
                                                                                <p>Preview</p>
                                                                            </ReactTooltip>
                                                                        </div>
                                                                    ) : (
                                                                        'No Ad'
                                                                    )}
                                                                </td>
                                                                <td>{item.offer ? '$' + item.offer?.price : ''}</td>
                                                            </tr>
                                                        ) : null;
                                                    })}
                                            </tbody>
                                        </Table>
                                        <DatatablePagination
                                            key="pagination"
                                            page={this.state.pagination.page}
                                            pages={this.state.pagination.totalPages}
                                            canPrevious={this.state.pagination.page === 0 ? false : true}
                                            canNext={
                                                this.state.pagination.page === this.state.pagination.totalPages - 1
                                                    ? false
                                                    : true
                                            }
                                            defaultPageSize={20}
                                            onPageChange={this.pageChange}
                                        />
                                    </div>
                                )}
                            </Colxx>
                        </Row>
                    </CardBody>
                </Card>
            </Fragment>
        );
    }
}
