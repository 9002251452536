import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "reactstrap";

import { NotFound404Icon } from "../../../../assets/icons";

export default function Analytics404(props: RouteComponentProps) {
  return (
    <div className="influencer404">
      <NotFound404Icon />
      <h1>Oops... looks like you got lost</h1>
      <Button onClick={() => props.history.push("/app/offers")}>Go Back</Button>
    </div>
  );
}
