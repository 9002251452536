import React from "react";
import { components } from "react-select";

/**
* this method create custom input selectbox
* @param props , this props type object and get custom props
* @typeParam Props
*/

const CustomSelectInput = (props) => {
  var customProps = Object.assign({}, props);
  delete customProps.autoCorrect;
  delete customProps.autoCapitalize;
  return <components.Input {...customProps} />;
};

export default CustomSelectInput;
