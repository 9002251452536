import React, { Component } from 'react';

// @ts-ignore
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

interface Props {
    onChange: (name: string, value: any) => void;
    name: string;
    id: string;
    placeholder?: string;
    tags?: Array<string>;
}

interface State {
    tags: string[];
}

export class TagsInputExample extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            tags: [],
        };

        this.handleTagChange = this.handleTagChange.bind(this);
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.tags) {
            return {
                tags: props.tags
                    .map((tag) => {
                        if (tag.includes('#')) {
                            return tag.split('#');
                        }
                        return tag;
                    })
                    .flatMap((item) => item)
                    .filter((item) => item.length > 0),
            };
        }

        return state;
    }

    handleTagChange(tags: string[]) {
        const { onChange, name } = this.props;

        this.setState((prevState) => ({
            tags: tags
                .map((tag) => {
                    if (tag.includes('#')) {
                        return tag.split('#');
                    }
                    return tag;
                })
                .flatMap((item) => item)
                .filter((item) => item.length > 0),
        }));
        onChange(name, tags);
    }

    render() {
        const { placeholder } = this.props;

        return (
            <TagsInput
                value={this.state.tags}
                onChange={this.handleTagChange}
                inputProps={{ placeholder: placeholder }}
            />
        );
    }
}
