import React from 'react';
import { Modal } from 'reactstrap';

import { AdPreview } from './AdPreview';
import { IAdModel } from '../../store/models/AdModel';
import { CloseIcon } from '../../assets/icons';
import RootStore from '../../store/RootStore';
import { IUserModel } from '../../store/models/UserModel';
import Transport from '../../lib/Transport';
import moment from 'moment';
import { momentDateTimeFormat } from '../../lib/Utils';

interface Props {
    ad: IAdModel;
    identity:any; // identity for brand
    isOpen: boolean;
    onClose: () => void;
    clear?: String;
    getAdService?: boolean;
    userNameShow?: boolean;
}
interface State {
    adAsService: any;
    user: IUserModel; // user from brand
}
export class AdPreviewModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            adAsService: null,
            user: null,
        };
    }

    componentDidMount() {
        // if (this.props.brand) {
        //     Transport.get(`admin/users/${this.props.brand.user_id}`).then((res) => {
        //         this.setState({
        //             user: res.data?.user,
        //         });
        //     });
        // }
        if (this.props.getAdService) {
            Transport.get(`v2/ads/${this.props.ad.id}`).then((res) => {
                const resData = res?.data || res?.data?.data;

                if (resData)
                    this.setState({
                        adAsService: resData,
                    });
            });
        }
        if (RootStore.users.currentUser.is_admin) {
            this.setState({ user:this.props.identity?.user });
        }
    }

    render() {
        const { isOpen, onClose } = this.props;
        const brand = this.state.adAsService?.campaign?.identity

        return (
            <Modal toggle={onClose} isOpen={isOpen} className="adPreviewModal">
                <CloseIcon onClick={onClose} className="adPreviewModal__closeButton" />
                {this.state.adAsService && (
                    <AdPreview
                        brand={brand}
                        userNameShow={this.props?.userNameShow}
                        values={{
                            title: this.state.adAsService.title,
                            user: {
                                name: brand?.name,
                                description: brand?.description,
                            },
                            description: this.state.adAsService.description,
                            schedule: this.state.adAsService.schedules
                                ? this.state.adAsService.schedules.map((el: any) => {
                                      return {
                                          end: moment(el.end, momentDateTimeFormat),
                                          id: null,
                                          start: moment(el.start, momentDateTimeFormat),
                                      };
                                  })
                                : null,
                            duration_segmentation: {
                                segment_name: this.state.adAsService.duration_segmentation.segment_name || 'hours',
                                segment_time: this.state.adAsService.duration_segmentation.segment_time || 1,
                            },
                            Duration: this.state.adAsService.duration,
                            fixedDuration:
                                this.state.adAsService.duration === null
                                    ? 0
                                    : this.state.adAsService.duration === 0
                                    ? 1
                                    : 2,
                            bio_link: this.state.adAsService.bio_link,
                            content: {
                                medias:
                                    this.state.adAsService.content.medias &&
                                    this.state.adAsService.content.medias.length &&
                                    this.state.adAsService.content.medias.map((el) => el?.file),
                                type:
                                    this.state.adAsService && this.state.adAsService.content.type === 'post'
                                        ? 'Feed'
                                        : 'Story',
                                data: {
                                    caption: this.state.adAsService.content.data.caption,
                                },
                            },
                        }}
                    />
                )}
                {brand ? (
                    <div className="w-100 d-flex flex-column mt-3 align-items-start">
                        <div className="mt-1">
                            First Name: <b>{this.state.user?.first_name || ''}</b>
                        </div>
                        <div className="mt-1">
                            Last Name: <b>{this.state.user?.last_name || ''}</b>
                        </div>
                        <div className="mt-1">
                            Email Address: <b>{this.state.user?.email || ''}</b>
                        </div>
                        <div className="mt-1">
                            Company Name: <b>{brand.name || ''}</b>
                        </div>
                        <div className="mt-1">
                            Company's Description: <b>{brand.description || ''}</b>
                        </div>
                        <div className="mt-1">
                            Url: <b>{brand.url || ''}</b>
                        </div>
                        <div className="mt-1">
                            Country: <b>{brand.country_id || ''}</b>
                        </div>
                    </div>
                ) : null}
            </Modal>
        );
    }
}
