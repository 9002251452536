import { Alert, Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import classes from './style.module.scss'
import { hashtagsCounter } from '../../../../../../../../../lib/Utils';
import SearchHashtag from '../../../../../../../../common-components/SearchHashtag';
import { withRouter } from 'react-router-dom';
import createAdContext from '../../../../../../context';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { InformationIcon } from '../../../../../../../../../assets/icons';
import ReactTooltip from 'react-tooltip';

function CreatAdFeedText(props: any) {
    const { data: { createAd: { feeds: { caption } } }, updateOnboard } = useContext(createAdContext)
    const [Textarea, setTextarea] = useState('')
    const [TextareaMount, setTextareaMount] = useState(false)
    const [showHashtagModal, setShowHashtagModal] = useState(false);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    useEffect(() => {
        setTextarea(caption)
        if (caption && !TextareaMount) {
            setTextareaMount(true)
            let text = ''
            caption.split('\n').map(el => text += el + '<br>')
            setEditorState(() => EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(`<p>${text}</p>`)
                )
            ))
        }
    }, [caption])

    const updateCaption = (value: string, append: boolean = true) => {
        updateOnboard((prev: any) => ({
            ...prev,
            createAd: {
                ...prev.createAd,
                feeds: {
                    ...prev.createAd.feeds,
                    caption: append ? prev.createAd.feeds.caption + value : value
                }
            }
        }))
    }

    const toggleSearchHashtagsModal = () => {
        setShowHashtagModal(!showHashtagModal);
    };

    const addHashtags = (hashtags: string[], field: string = 'caption'): void => {
        const hashtagsString = '#' + hashtags.join(' #');
        setTextareaMount(false)

        setTextarea((prev: any) => prev + hashtagsString);
        updateCaption(Textarea + hashtagsString, false)
    };

    return (
        <Grid container item className={classes.text} flexDirection="column" rowSpacing={2}>
            <SearchHashtag isOpen={showHashtagModal} toggle={toggleSearchHashtagsModal} action={addHashtags} />
            <Grid item container alignItems="center" className={classes.detail}>
                <Grid item>
                    <strong>Feed Caption:</strong>
                </Grid>
                <Grid item>
                    &nbsp;
                    <InformationIcon width={15} height={15} data-tip data-for="AdTitle" />
                    <ReactTooltip id="AdTitle" type="dark" effect="solid">
                        <p className={classes.tooltip}>
                            Write a caption you would like to appear on the post or give some caption ideas to influencers if they supposed to create the post from scratch.
                        </p>
                    </ReactTooltip>
                </Grid>
            </Grid>
            <Grid item className={classes.textarea}>
                <Editor
                    editorState={editorState}
                    // hashtag={{
                    //     separator: ' ',
                    //     trigger: '#',
                    // }}
                    // mention={{
                    //     separator: ' ',
                    //     trigger: '#',
                    //     suggestions: [
                    //       { text: 'APPLE', value: 'apple', url: 'apple' },
                    //       { text: 'BANANA', value: 'banana', url: 'banana' },
                    //       { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                    //       { text: 'DURIAN', value: 'durian', url: 'durian' },
                    //       { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                    //       { text: 'FIG', value: 'fig', url: 'fig' },
                    //       { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                    //       { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                    //     ],
                    //   }}
                    toolbar={{ options: ['emoji'] }}
                    onChange={(e) => {
                        let text = ''
                        if (e.blocks.length) {
                            e.blocks.map(el => text += el.text + '\n')
                            setTextarea(text)
                            updateCaption(text, false)
                        }
                    }}
                    onEditorStateChange={setEditorState}
                />
            </Grid>
            <Grid item container justifyContent="space-between" alignItems="top" columnSpacing={2}>
                <Grid item container sm={7} xs={12} rowSpacing={1} flexDirection="column">
                    <Grid item>
                        <span className={`${classes.hashtag} ${hashtagsCounter(Textarea) > 30 ? classes.red : ''}`}>
                            {hashtagsCounter(Textarea) < 31 ? `${hashtagsCounter(Textarea)} #` : `Exceeded ${hashtagsCounter(Textarea) - 30} #`}
                        </span>
                    </Grid>
                    <Grid item>
                        {Textarea && hashtagsCounter(Textarea) > 30 && <Alert severity="warning">Up to 30 hashtags are allowed!</Alert>}
                    </Grid>
                    <Grid item>
                        {Textarea && Textarea.length > 2200 &&
                            <Alert severity="warning">
                                You can't enter more than 2200 characters into the caption.
                            </Alert>}
                    </Grid>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <div className="SearchHashtag" onClick={toggleSearchHashtagsModal}>
                        <i className="simple-icon-magnifier"></i> Search &amp; Add Hashtags
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withRouter(CreatAdFeedText)
