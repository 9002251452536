import React, { Component } from "react";
import moment from "moment";
import $ from "jquery";
import { observer } from "mobx-react";
import { truncate } from "lodash";

import { HistoryClockIcon, LinkButtonIcon } from "../../assets/icons";
import ContentPreview from "../app/content-manager/components/preview/ContentPreview";
import { EventIcon } from "../../assets/icons";
import RootStore from "../../store/RootStore";
import { IAdModel } from "../../store/models/AdModel";
import { IIdentityModel } from "../../store/models/IdentityModel";
import { IUserModel } from "../../store/models/UserModel";
import profileImg from "../../assets/img/profilePlaceholder.jpg";
import { Scrollbars } from "react-custom-scrollbars";
import { SeeMoreSeeLessData } from "../common-components";
// import {ICampaignModel} from '../../store/models/CampaignModel'
// import ScrollBar from "react-perfect-scrollbar";

interface Props {
  values: IAdModel;
  currentStep?: number;
  identity?: IIdentityModel;
  user?: IUserModel;
}

interface State {
  currentStep: number;
  schedule_list?: Object[]
}

@observer
export class AdPreview extends Component<Props, State> {
  public url = profileImg;
  private _scrollBarRef: any | null = null;

  private publishTermsRef: React.RefObject<HTMLDivElement>;
  private schedulingRef: React.RefObject<HTMLDivElement>;
  private isAdmin: boolean = RootStore.users.currentUser.is_admin;
  private identity: IIdentityModel = RootStore.identityStore.identities.find(
    (identity: IIdentityModel) => {
      return identity.id === this.props.values.campaign_id.identity_id;
    }
  );
  constructor(props: any) {
    super(props);

    this.state = {
      currentStep: 0,
      schedule_list: []
    };

    this.publishTermsRef = React.createRef();
    this.schedulingRef = React.createRef();
  }

  static getDerivedStateFromProps(props: any, state: any) {
    return {
      currentStep: props.currentStep,
      schedule_list: props.values?.schedule
    };
  }

  componentDidUpdate() {
    if (this.props.currentStep === 3) {
      const target = this.schedulingRef.current;
      $(this._scrollBarRef).animate(
        {
          scrollTop: $(target).position().top,
        },
        1000,
        function () { }
      );
    }

    if (this.props.currentStep === 4) {
      const target = this.publishTermsRef.current;
      $(this._scrollBarRef).animate(
        {
          scrollTop: $(target).position().top,
        },
        1000,
        function () { }
      );
    }
  }

  render() {
    const { values } = this.props;
    const brand_email = RootStore.identityStore.identities.find(
      (identity: IIdentityModel) => {
        return identity.id === this.props.values.campaign_id.identity_id;
      }
    )?.email

    const renderScheduleList = () => {
      if (this.state?.schedule_list === undefined && (!values.time_tables || values?.time_tables.length === 0)) {
        return <p>Not Specified</p>
      } else if (this.state?.schedule_list && this.state?.schedule_list.length > 0) {
        return <ul>
          {this.state?.schedule_list.map((scheduleItem: any) =>
            scheduleItem.end === null ||
              moment(scheduleItem.start).format(
                "MMMM Do YYYY, h:mm a"
              ) === moment(scheduleItem.end).format(
                "MMMM Do YYYY, h:mm a"
              ) ? (
              <li key={scheduleItem.start}>
                <EventIcon />
                {moment(scheduleItem.start).format(
                  "MMMM Do YYYY, h:mm a"
                )}
              </li>
            ) : (
              <li
                key={scheduleItem.start + scheduleItem.end}
                className="periodic"
              >
                <span>
                  {" "}
                  {moment(scheduleItem.start).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </span>
                <span>
                  {" "}
                  {moment(scheduleItem.end).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </span>
              </li>
            )
          )}
        </ul>
      } else if (this.state?.schedule_list === undefined && values.time_tables &&
        values.time_tables.length > 0) {
        return <ul>
          {values.time_tables.map((scheduleItem: any) =>
            scheduleItem.end === null ||
              scheduleItem.start === scheduleItem.end ? (
              <li key={scheduleItem.start}>
                <EventIcon />
                {moment(scheduleItem.start).format(
                  "MMMM Do YYYY, h:mm a"
                )}
              </li>
            ) : (
              <li
                key={scheduleItem.start + scheduleItem.end}
                className="periodic"
              >
                <span>
                  {" "}
                  {moment(scheduleItem.start).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </span>
                <span>
                  {" "}
                  {moment(scheduleItem.end).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </span>
              </li>
            )
          )}
        </ul>
      } else {
        return <p>Not Specified</p>
      }
    }
    return (
      <div className="adPreview">
        <p
          className="d-block text-center mb-3 mt-n3"
          style={{ padding: "0 1em" }}
        >
          Your Ad in Marketplace
        </p>
        <div className="device-wrapper">
          <div
            className="device"
            data-device="iPhone7"
            data-orientation="portrait"
            data-color="black"
          >
            <div className="screen" style={{ overflow: "none" }}>
              <div className="adPreview_preview">
                <Scrollbars
                  renderTrackHorizontal={(ref) => (
                    <div
                      {...ref}
                      style={{ display: "none" }}
                      className="track-horizontal"
                    />
                  )}
                >
                  <div className="adPreview_preview_content">
                    <div className="header text-truncate">
                      <i className="simple-icon-arrow-left"></i> Ad by{" "}
                      {!this.isAdmin
                        ? RootStore.users.getDefaultIdentity().name
                        : this.identity.name}
                    </div>

                    <div className="adTitle">
                      <p>
                        {values && values.title
                          ? values.title
                          : "Ad title goes here"}
                      </p>
                      <p>
                        {values && values.description ? (
                          <SeeMoreSeeLessData data={values.description} />
                        ) : (
                          "Ad description goes here"
                        )}
                      </p>
                    </div>

                    <div className="brandInfo">
                      <img
                        style={{ width: "38px", height: "38px" }}
                        src={
                          !this.isAdmin &&
                            RootStore.users.getDefaultIdentity().logo
                            ? RootStore.users
                              .getDefaultIdentity()
                              .logo.getThumbnailUrl(50, 50)
                            : this.url
                        }
                        alt="ainfluencer"
                      />
                      <p className="text-truncate">
                        {!this.isAdmin
                          ? RootStore.users.getDefaultIdentity().name
                          : this.identity.name}{" "}
                        <br />{" "}
                        <span>
                          {!this.isAdmin
                            ? RootStore.users.getDefaultIdentity().description
                            : this.identity.description}
                        </span>
                      </p>
                    </div>

                    <div className="adContent">
                      <p>
                        Ad Content:{" "}
                        <span style={{ color: "#355eff" }}>
                          {values.content_id
                            ? values.content_id.type === "story"
                              ? "Story"
                              : "Feed"
                            : null}
                        </span>
                      </p>

                      {values && values.content_id ? (
                        <>
                          <ContentPreview
                            onboardingPreview={true}
                            model={values?.content_id.getMedias().map(el => el.preview_file)}
                          />
                        </>
                      ) : (
                        <div className="adContent_content_empty">
                          <p>
                            Your creative will appear here, click on the blue
                            button to create a feed post or a story...
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="scheduling" ref={this.schedulingRef}>
                      <p>Scheduling:</p>
                      <div className="scheduling_content">
                        {renderScheduleList()}
                      </div>
                    </div>

                    <div className="publishTime" ref={this.publishTermsRef}>
                      <p>Publish Terms:</p>
                      <div className="publishTime_content">
                        <ul>
                          <li>
                            <HistoryClockIcon />
                            Duration:
                            {values &&
                              (values.fixedDuration === 0 ||
                                values.duration === null) ? (
                              <span>Not Specified</span>
                            ) : values &&
                              (values.fixedDuration === 1 ||
                                values.duration === 0) ? (
                              <span>Permanent</span>
                            ) : (
                              values &&
                              values.duration !== 0 && (
                                <span style={{ color: "#1A76FC" }}>
                                  {moment
                                    .duration(values.duration, "seconds")
                                    .humanize()}
                                </span>
                              )
                            )}
                          </li>
                          <li>
                            <LinkButtonIcon />
                            Link in Bio:
                            {values && values.bio_link ? (
                              <a
                                href={values.bio_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {truncate(values.bio_link, { length: 20 })}
                              </a>
                            ) : (
                              <span>Not Specified</span>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </div>
            <div className="button"></div>
          </div>
        </div>
        {this.props.identity ? (
          <div className="w-100 d-flex flex-column mt-3 align-items-start">
            <div className="mt-1">
              First Name:{" "}
              <b>
                {this.props.user ? this.props.user.first_name : "Deleted User"}
              </b>
            </div>
            <div className="mt-1">
              Last Name:{" "}
              <b>
                {this.props.user ? this.props.user.last_name : "Deleted User"}
              </b>
            </div>
            <div className="mt-1">
              Email Address:{" "}
              {/* <b>{this.props.user ? this.props.user.email : "Deleted User"}</b> */}
              <b>{this.props.user ? brand_email || this.props.user.email : "Deleted User"}</b>
            </div>
            <div className="mt-1">
              Company Name:{" "}
              <b>
                {this.props.identity
                  ? this.props.identity.name
                  : "Deleted User"}
              </b>
            </div>
            <div className="mt-1">
              Company's Description:{" "}
              <b>
                {this.props.identity
                  ? this.props.identity.description
                  : "Deleted User"}
              </b>
            </div>
            <div className="mt-1">
              Url:{" "}
              <b>
                {this.props.identity ? this.props.identity.url : "Deleted User"}
              </b>
            </div>
            <div className="mt-1">
              Country:{" "}
              <b>
                {this.props.identity
                  ? this.props.identity.country_id
                  : "Deleted User"}
              </b>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

{/* <div className="scheduling" ref={this.schedulingRef}>
                      <p>Scheduling:</p>
                      <div className="scheduling_content">
                        {values &&
                        values.schedule &&
                        values.schedule.length !== 0 ? (
                          <ul>
                            {values.schedule.map((scheduleItem: any) =>
                              scheduleItem.end === null ||
                              moment(scheduleItem.start).format(
                                "MMMM Do YYYY, h:mm a"
                              ) === moment(scheduleItem.end).format(
                                "MMMM Do YYYY, h:mm a"
                              ) ? (
                                <li key={scheduleItem.start}>
                                  <EventIcon />
                                  {moment(scheduleItem.start).format(
                                    "MMMM Do YYYY, h:mm a"
                                  )}
                                </li>
                              ) : (
                                <li
                                  key={scheduleItem.start + scheduleItem.end}
                                  className="periodic"
                                >
                                  <span>
                                    {" "}
                                    {moment(scheduleItem.start).format(
                                      "MMMM Do YYYY, h:mm a"
                                    )}
                                  </span>
                                  <span>
                                    {" "}
                                    {moment(scheduleItem.end).format(
                                      "MMMM Do YYYY, h:mm a"
                                    )}
                                  </span>
                                </li>
                              )
                            )}
                          </ul>
                        ) : values &&
                          values.time_tables &&
                          values.time_tables.length !== 0 ? (
                          <ul>
                            {values.time_tables.map((scheduleItem: any) =>
                              scheduleItem.end === null ||
                              scheduleItem.start === scheduleItem.end ? (
                                <li key={scheduleItem.start}>
                                  <EventIcon />
                                  {moment(scheduleItem.start).format(
                                    "MMMM Do YYYY, h:mm a"
                                  )}
                                </li>
                              ) : (
                                <li
                                  key={scheduleItem.start + scheduleItem.end}
                                  className="periodic"
                                >
                                  <span>
                                    {" "}
                                    {moment(scheduleItem.start).format(
                                      "MMMM Do YYYY, h:mm a"
                                    )}
                                  </span>
                                  <span>
                                    {" "}
                                    {moment(scheduleItem.end).format(
                                      "MMMM Do YYYY, h:mm a"
                                    )}
                                  </span>
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          <p>Not Specified</p>
                        )}
                      </div>
                    </div> */}
