import {
    applySnapshot,
    flow,
    getIdentifier,
    getSnapshot,
    Instance,
    SnapshotIn,
    SnapshotOut,
    types,
} from 'mobx-state-tree';
import Transport from '../lib/Transport';
import { makeOfferQuery, makePaymentQuery, newOfferQuery } from './common/RequestHelpers';
import { IOfferModel, IOfferModelSnapshotIn, OfferModel } from './models/OfferModel';
import { IAdModelSnapshotIn } from './models/AdModel';
import RootStore from './RootStore';
import { AxiosResponse } from 'axios';
import BatchChange, { ChangeDescription } from './common/BatchChange';
import { OfferModification } from './common/AbstractOfferHistory';
// import TwilioFacade from "../lib/TwilioFacade";
import BatchTransport from '../lib/BatchTransport';
import { PaymentMethodModel } from './models/PaymentMethodModel';
import { observable } from 'mobx';
import { isStaging } from '../constants/defaultValues';

export const OfferStore = types
    .model({
        offers: types.array(OfferModel),
    })
    .volatile((self) => ({
        isBusy: false,
        fetchedFromServer: {} as { [campaignId: number]: boolean },
        fetchedAllFromServer: false,
        fetchedAllOffers: false,
        loadingAll: false,
        currentOpenedOffer: null,
        totalUnreadMessages: observable<{ total: number }>({ total: 0 }),
        filterSet: null,
    }))
    .actions((self) => ({

        /**
        * this method just clear offers
        */
        clearOffers() {
            self.offers.replace([])
        },

        /**
        * this method set filters offers
        * @param filterSet , this param get filters
        * @returns set filterSet this store
        */
        setFilters(filterSet: any) {
            self.filterSet = filterSet;
        },

        syncMissingModels(models: IOfferModelSnapshotIn[]) {
            if (!Array.isArray(models)) {
                return;
            }
            let offerIdList: number[] = self.offers.map((content) => {
                return content.id;
            });

            let possiblyUpdatedModels = models.filter((model) => offerIdList.indexOf(model.id) >= 0);
            if (possiblyUpdatedModels.length) {
                for (let content of self.offers) {
                    for (let updatedContent of possiblyUpdatedModels) {
                        if (content.id === updatedContent.id) {
                            applySnapshot(content, updatedContent);
                        }
                    }
                }
            }

            self.offers.replace(
                self.offers.concat(models.filter((model) => offerIdList.indexOf(model.id) === -1).uniqueById()),
            );
        },
    }))
    .actions((self) => ({

        /**
        * this method check processing for this store
        */
        setBusy() {
            self.isBusy = true;
        },

        /**
        * this method just set value isBusy = false
        */
        setIdle() {
            self.isBusy = false;
        },

        /**
        * this method push model this store
        * @param model, this param type object for get model
        */
        pushModel(model: IOfferModel) {
            self.offers.push(model);
        },

        /**
        * this method set Current Offer Model
        * @param offer, this param Offer Model
        */
        setCurrentOfferModel(offer: IOfferModel | null) {
            self.currentOpenedOffer = offer ? offer.id : null;
        },

        /**
        * this method increase Total Unread Messages
        * @param value, this param for add total Unread Messages
        * @returns total Unread Messages + value
        */
        increaseTotalUnreadMessages(value: number) {
            self.totalUnreadMessages.total = self.totalUnreadMessages.total + value;
        },

        /**
        * this method increase Total Unread Messages
        * @param value, this param for decrease from total Unread Messages
        * @returns total Unread Messages - value
        */
        decreaseTotalUnreadMessages(value: number) {
            self.totalUnreadMessages.total = self.totalUnreadMessages.total - value;
        },
    }))
    .actions((self) => ({

        /**
        * this method create new offer
        */
        create: flow(function* (snapshot: any): any {
            self.setBusy();

            try {
                let result = yield Transport.post('offers', { offer: snapshot }, makeOfferQuery());

                let model;
                model = OfferModel.create(result.data['ad']);

                self.offers.push(model);
                self.setIdle();
                return model;
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method fetch all By campaign ID
        * @param campaignId, this param campaign ID
        */
        fetchAllByCampaignId: flow(function* (campaignId): any {
            self.setBusy();
            try {
                let response = yield Transport.get('offers', makeOfferQuery(campaignId));

                RootStore.identityStore.syncMissingModels(response.data['ads.offers.identity']);
                RootStore.identityStore.syncMissingModels(response.data['ads.offers.target_identity']);

                RootStore.integrationStore.syncMissingModels(response.data['ads.offers.target_integration']);

                if (response.data['ads.offers.content']) {
                    RootStore.contentStore.syncMissingModels(response.data['ads.offers.content']);
                }

                RootStore.publishJobStore.syncMissingModels(response.data['publish_job']);

                self.syncMissingModels(response.data['offers']);

                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
       * this method fetch all offers
       * @param pagination, this param object for pagination offers
       * @param sort, this param object for sort offers
       * @param loadMore, this param for get more offers or not
       */
        fetchAllOffers: flow(function* (
            pagination: { page: number; pageSize: number },
            sort,
            loadMore: boolean = false,
        ): any {
            if (!self.fetchedAllOffers || loadMore) {
                self.setBusy();
                self.loadingAll = true;
                try {
                    //let response = yield Transport.get('offers', makeOfferQuery(null, false, pagination, sort));
                    let newresponse = yield Transport.get('v1/offer/index', newOfferQuery(pagination));

                    self.syncMissingModels(newresponse.data.offers);

                    newresponse.data.offers.forEach((offer: any) => {
                        RootStore.identityStore.syncMissingModels([offer.identity]);
                        RootStore.identityStore.syncMissingModels([offer.target_identity]);
                        RootStore.integrationStore.syncMissingModels([offer.target_integration]);

                        RootStore.campaignStore.syncMissingModels([offer.ad.campaign]);
                        RootStore.contentStore.syncMissingModels([offer.ad.content]);
                        RootStore.adStore.syncMissingModels([offer.ad]);
                        self.fetchedFromServer[offer.ad.campaign.id] = true;

                        if (offer.publish_job) {
                            RootStore.publishJobStore.syncMissingModels([offer.publish_job]);
                        }
                        RootStore.contentStore.syncMissingModels([offer.content]);
                    });

                    // RootStore.identityStore.syncMissingModels(response.data['identity']);
                    // RootStore.identityStore.syncMissingModels(response.data['target_identity']);
                    // RootStore.integrationStore.syncMissingModels(response.data['target_integration']);

                    // if (response.data['ad'] && Array.isArray(response.data['ad'])) {
                    //     for (let ad of response.data['ad']) {
                    //         RootStore.campaignStore.syncMissingModels([ad['campaign']]);
                    //         RootStore.contentStore.syncMissingModels([ad['content']]);
                    //         RootStore.adStore.syncMissingModels([ad]);
                    //         self.fetchedFromServer[ad['campaign'].id] = true;
                    //     }
                    // }

                    // for (let offer of response.data['offers']) {
                    //     RootStore.contentStore.syncMissingModels([offer['content']]);
                    // }

                    //RootStore.publishJobStore.syncMissingModels(response.data['publish_job']);

                    //self.syncMissingModels(response.data['offers']);

                    self.setIdle();
                    self.loadingAll = false;
                    self.fetchedAllOffers = true;

                    return {
                        totalPages: newresponse.data.meta.page_count,
                        page: newresponse.data.meta.page,
                    };
                } catch (error) {
                    self.setIdle();
                    self.loadingAll = false;
                    throw error;
                }
            }
            return;
        }),
        fetchAllOffersReload: flow(function* (
            pagination: { page: 1; pageSize: 30 },
            sort,
        ): any {
                self.setBusy();
                self.loadingAll = true;
                try {
                    //let response = yield Transport.get('offers', makeOfferQuery(null, false, pagination, sort));
                    let newresponse = yield Transport.get('v1/offer/index', newOfferQuery(pagination));

                    self.syncMissingModels(newresponse.data.offers);

                    newresponse.data.offers.forEach((offer: any) => {
                        RootStore.identityStore.syncMissingModels([offer.identity]);
                        RootStore.identityStore.syncMissingModels([offer.target_identity]);
                        RootStore.integrationStore.syncMissingModels([offer.target_integration]);

                        RootStore.campaignStore.syncMissingModels([offer.ad.campaign]);
                        RootStore.contentStore.syncMissingModels([offer.ad.content]);
                        RootStore.adStore.syncMissingModels([offer.ad]);
                        self.fetchedFromServer[offer.ad.campaign.id] = true;

                        if (offer.publish_job) {
                            RootStore.publishJobStore.syncMissingModels([offer.publish_job]);
                        }
                        RootStore.contentStore.syncMissingModels([offer.content]);
                    });

                    self.setIdle();
                    self.loadingAll = false;
                    self.fetchedAllOffers = true;

                    return {
                        totalPages: newresponse.data.meta.page_count,
                        page: newresponse.data.meta.page,
                    };
                } catch (error) {
                    self.setIdle();
                    self.loadingAll = false;
                    throw error;
                }
        })
    }))
    .actions((self) => ({

        /**
        * this method get Offers
        * @param offerId, this param offer ID
        */
        getOffer: flow(function* (offerId: number, force: boolean = false, silent: boolean = false): any {
            let existingModel = self.offers.find((offer) => offer.id === offerId);
            if (existingModel) {
                if (!force && existingModel.getTargetIntegration().metric) {
                    return existingModel;
                }
            }

            if (!silent) {
                self.setBusy();
            }

            try {
                let response = yield Transport.get('offers/' + offerId, makeOfferQuery(null, true));

                RootStore.identityStore.syncMissingModels(response.data['identity']);
                RootStore.identityStore.syncMissingModels(response.data['target_identity']);
                RootStore.identityStore.syncMissingModels(response.data['target_integration.identities']);
                RootStore.integrationStore.syncMissingModels(response.data['target_integration']);
                RootStore.campaignStore.syncMissingModels([response.data['ad'][0]['campaign']]);
                RootStore.publishJobStore.syncMissingModels(response.data['publish_job']);

                if (response.data['offer']['content']) {
                    RootStore.contentStore.syncMissingModels([response.data['offer']['content']]);
                }

                RootStore.adStore.syncMissingModels(response.data['ad']);
                RootStore.offerStore.syncMissingModels([response.data['offer']]);

                if (existingModel) {
                    applySnapshot(existingModel, response.data['offer']);

                    if (!silent) {
                        self.setIdle();
                    }

                    return existingModel;
                } else {
                    if (!silent) {
                        self.setIdle();
                    }

                    return self.offers.find((offer) => offer.id === offerId);
                }
            } catch (error) {
                if (!silent) {
                    self.setIdle();
                }

                throw error;
            }
        }),
    }))
    .actions((self) => ({

        /**
        * this method update Offers
        */
        updateOffer: flow(function* (offerSnapshot: IOfferModelSnapshotIn) {
            self.setBusy();
            try {
                yield Transport.patch('offers/' + offerSnapshot.id, offerSnapshot);
                applySnapshot(yield self.getOffer(offerSnapshot.id), offerSnapshot);
                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method update Offer state
        * @param offerId, this param offer ID
        * @param state, this param get state offer
        */
        updateOffersetstate: flow(function* (
            offerId: any,
            state: 'reject' | 'read' | 'archive' | 'delete' | 'spam' | 'block' | null,
        ) {
            self.setBusy();
            if (offerId.state === state) {
                state = null;
            }

            // if (state === 'reject') {
            // } 
            if (true) {
                try {
                    let updateOffer = self.offers.find((offer: any) => offer.id === offerId.id);

                    yield Transport.patch(`offers/${offerId.id}/set_state`, {
                        offer: { state: state },
                    });

                    updateOffer.state = state;
                    let offers = self.offers.filter((offer: any) => offer.id !== offerId.id);

                    offers.push(updateOffer);

                    self.offers.replace(offers);
                    self.setIdle();
                } catch (error) {
                    self.setIdle();
                    throw error;
                }
            }
        }),

        /**
        * this method pay Offer
        * @param offer, this param Offer Model
        */
        pay: flow(function* (offer: IOfferModel, method: any = null): Generator<any, void, any> {
            self.setBusy();

            try {
                let response = null;
                if (typeof method === 'object' && method !== null) {
                    let batch = new BatchTransport();
                    batch.post(`wallets/${RootStore.users.currentUser.wallet.id}/methods`, {
                        payment_method: {
                            type: 'card',
                            default: true,
                            data: { ...method },
                        },
                    });
                    batch.post<any>(
                        'offers/' + offer.id + '/pay',
                        {
                            transaction: {
                                method_id: '{{$ref:0.payment_method.id}}',
                            },
                        },
                        makePaymentQuery(),
                    );

                    if (!isStaging()) {
                        //@ts-ignore
                        ga('send', {
                            hitType: 'event',
                            eventCategory: 'Brand',
                            eventAction: 'Payment Made',
                            eventLabel: '',
                        });
                    }

                    response = yield batch.all();

                    self.setIdle();
                    let model = PaymentMethodModel.create(response.data[0]['payment_method']);
                    RootStore.users.currentUser.wallet.addPaymentMethod(model);
                    RootStore.users.currentUser.wallet.subtractCredit(offer.price);

                    response = response.data[1];
                } else {
                    response = yield Transport.post<any>(
                        'offers/' + offer.id + '/pay',
                        {
                            transaction: {
                                method_id: method,
                            },
                        },
                        makePaymentQuery(),
                    );
                    if (!isStaging()) {
                        //@ts-ignore
                        ga('send', {
                            hitType: 'event',
                            eventCategory: 'Brand',
                            eventAction: 'Payment Made',
                            eventLabel: '',
                        });
                    }
                    response = response.data;
                }

                RootStore.integrationStore.syncMissingModels(response['publish_job.integration']);
                RootStore.contentStore.syncMissingModels(response['publish_job.content']);
                RootStore.publishJobStore.syncMissingModels(response['publish_job']);
                applySnapshot(offer, response['offer']);
                RootStore.users.currentUser.wallet.subtractCredit(offer.price);
                offer
                    .getHistory()
                    .mergeNewChanges(response['offer']['changes'].map((change: any) => new BatchChange(change)));
                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
        * this method fetch Total Unread Messages
        */
        fetchTotalUnreadMessages: flow(function* (): Generator<any, void, any> {
            // yield self.fetchAllOffers();

            self.offers.forEach(async (offer: IOfferModel) => {
                await offer.getDialogue().fetchUnreadMessageCount();
            });

            self.totalUnreadMessages.total = RootStore.offerStore.offers
                .map((offer: IOfferModel) => offer.getDialogue().unreadMessages)
                .reduce((unreadMessages: number, totalMessages: number) => unreadMessages + totalMessages, 0);

            yield;
        }),

        counterOffer: flow(function* (
            modifications: OfferModification[],
            id: number,
            callback: (batches: BatchChange[]) => void,
        ): Generator<any, void, any> {
            self.setBusy();
            try {
                let response: AxiosResponse<any> = yield Transport.patch<any>(
                    'offers/' + id,
                    modifications,
                    makeOfferQuery(),
                );

                let current: IOfferModel = yield self.getOffer(id);
                applySnapshot(current, response.data['offer']);

                RootStore.adStore.syncMissingModels(response.data['ad']);
                RootStore.identityStore.syncMissingModels(response.data['identity']);
                RootStore.identityStore.syncMissingModels(response.data['target_identity']);
                RootStore.integrationStore.syncMissingModels(response.data['target_integration']);
                RootStore.publishJobStore.syncMissingModels(response.data['publish_job']);

                if (response.data['offer']['content']) {
                    RootStore.contentStore.syncMissingModels([response.data['offer']['content']]);
                }

                for (let ad of response.data['ad']) {
                    RootStore.campaignStore.syncMissingModels(ad['campaign']);
                    RootStore.adStore.syncMissingModels([ad]);
                }

                let batchChanges = [];

                for (let changeList of response.data['offer']['changes']) {
                    batchChanges.push(new BatchChange(changeList as ChangeDescription[]));
                }

                yield callback(batchChanges);

                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),
    }))

    .views((self) => ({

        /**
        * this method get Offers By Campaign
        * @param campaignId, this param campaign ID
        */
        getOffersByCampaign(campaignId: number): IOfferModel[] {
            return self.offers.filter(
                (offer) =>
                    ((getSnapshot(offer.ad_id) as unknown as IAdModelSnapshotIn).campaign_id as number) === campaignId,
            );
        },

        /**
        * this method get All Opponent Identities
        */
        getAllOpponentIdentities(): number[] {
            return Array.from(new Set(self.offers.map((offer) => offer.getOpponentIdentity().id)));
        },

        /**
        * this method get Offers By Offers
        * @param adId, this param ad ID
        */
        getOffersByAd(adId: number): IOfferModel[] {
            return self.offers.filter((offer) => parseInt(getIdentifier(offer.ad_id)) === adId);
        },

        /**
       * this method get Existing offer
       * @param id, this param offer ID
       */
        getExistingModel(id: number): IOfferModel | null {
            let models = self.offers.filter((offer) => offer.id === id);
            if (models.length) {
                return models[0];
            }
            return null;
        },

        /**
        * this method get Offer Content Type
        * @param offerId, this param offer ID
        */
        getOfferContentType(offerId: number): string {
            return self.offers
                .filter((offer) => offer.id === offerId)[0]
                .ad_id.content_id.getContentType()
                .toUpperCase();
        },

        /**
        * this method get Total Unread Messages
        * @param offerId, this param offer ID
        */
        getTotalUnreadMessages(): number {
            return RootStore.offerStore.offers
                .map((offer: IOfferModel) => offer.getDialogue().unreadMessages)
                .filter((offers: number[]) => offers).length;
        },
    }));

export interface IOfferStore extends Instance<typeof OfferStore> { }
export interface IOfferStoreSnapshotIn extends SnapshotIn<typeof OfferStore> { }
export interface IOfferStoreSnapshotOut extends SnapshotOut<typeof OfferStore> { }
