import { Grid } from '@mui/material'
import React from 'react'
import { CameraIcon, ImageIcon } from '../../../../../../../../../../../assets/icons'
import classes from './style.module.scss'

function CreatAdFeedDescription() {
    return (
        <Grid container item columnSpacing={3} marginBottom={3} alignItems="center">
            <Grid container item sm={6} wrap="nowrap" columnSpacing={3}>
                <Grid item>
                    <ImageIcon />
                </Grid>
                <Grid item container flexDirection="column">
                    <Grid item>
                        <span className={classes.label}>image</span>
                    </Grid>
                    <Grid item>
                        <span className={classes.description}>JPG, JPEG, PNG, GIF / Max size: 8 MB / Aspect Ratio: free (auto crop)</span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item sm={6} wrap="nowrap" columnSpacing={3}>
                <Grid item>
                    <CameraIcon />
                </Grid>
                <Grid item container flexDirection="column">
                    <Grid item>
                        <span className={classes.label}>Video</span>
                    </Grid>
                    <Grid item>
                        <span className={classes.description}> MP4 / Max size: 100 MB / Aspect Ratio from 4:5 to 16:9</span>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CreatAdFeedDescription
