import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalBody, Spinner, Button, Label, CustomInput, FormGroup } from 'reactstrap';
import { NoSearchResultIcon, CloseIcon } from '../../assets/icons';
import RootStore from '../../store/RootStore';
import { FormikReactSelect } from '.';
import { ICountryModel } from '../../store/models/CountryModel';
import { showLoading, ajaxErrorAlert } from "./../../lib/Utils";
import Swal from "sweetalert2";
import axios from 'axios'
import { servicePath, ADS_UPDATE_FILTER } from './../../constants/defaultValues'
import { SortOrder } from './../../lib/QueryTypes';
import ManageHashtagsHeader from "./../../assets/img/ManageHashtagsHeader.png";
import SearchIconNew from './../../assets/img/SearchIcon.png'
import { CountriesList } from './../../constants/countriesList'

interface Props extends RouteComponentProps {
    isOpen: boolean;
    toggle: any;
    hashtags: [];
    mode?: string
}

const InviteInfluencersPopup: React.FC<Props> = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [hashtags, setHashtags] = useState<{ mediacount?: string; name: string; ori_count?: Number }[] | null>(null);
    const [selectedHashtags, setSelectedHashtags] = useState<string[]>([]);
    const [fetchingData, setFetchingData] = useState<boolean>(false);
    const [gender, setGender] = useState({ label: 'All', value: null });
    const [country, setCountry] = useState(null);
    const [inviteOnly, setInvite] = useState(false);
    const [showHashtagDiv, setshowHashtagDiv] = useState(true);

    // useEffect(() => {
    //     if(hashtags) setSelectedHashtags(props.hashtags.slice(0, 7));
    // }, [props.hashtags, hashtags]);
    useEffect(() => {
        if (props.hashtags?.length > 0) setSelectedHashtags(props.hashtags);
    }, [props.hashtags]);

    useEffect(() => {
        if (props.mode !== 'create') {
            const ad_info = JSON.parse(localStorage.getItem('ad_info'))

            const invite_only = ad_info?.invite_only || true
            const limit_country = ad_info?.limit_country || []
            const limit_gender = ad_info?.limit_gender || null

            const list = CountriesList || []
            if (list?.length > 0 && Array.isArray(limit_country) && limit_country?.length > 0) {
                // @ts-ignore
                const initial = limit_country?.map(item => {
                    const name = list?.find(li => li.id === item)?.name
                    const id = list?.find(li => li.id === item)?.id
                    return {
                        label: name,
                        value: id
                    }
                })
                setCountry(initial || null)
            }

            if (limit_gender === 'male') {
                setGender({ label: 'Male', value: 'male' })
            } else if (limit_gender === 'female') {
                setGender({ label: 'Female', value: 'female' })
            } else {
                setGender({ label: 'All', value: null })
            }

            setInvite(Boolean(invite_only))
        }
    }, [props])

    useEffect(() => {
        RootStore.fetchStaticData()
    }, [])

    const searchForHashtags = async () => {
        setFetchingData(true);

        try {
            let result = await RootStore.hashtagsStore.searchHashtags(searchTerm?.replace('#', ''), 15);
            setFetchingData(false);
            let hashtags = result?.data ? result?.data : [];
            hashtags?.sort(function (a, b) {
                return b.ori_count - a.ori_count;
            });

            setHashtags(hashtags);
        } catch (e) {
            setFetchingData(false);
            ajaxErrorAlert('You have error , try again .');
        }
    };

    const addToSelected = (hashtag: string): void => {
        if (selectedHashtags.find((selected: string) => selected === hashtag)) return;
        setSelectedHashtags([...selectedHashtags, hashtag]);
    };

    const removeFromSelected = (hashtag: string): void => {
        setSelectedHashtags(selectedHashtags.filter((selected) => hashtag !== selected));
    };

    const goToAds = () => {
        // setHashtags(null);
        // setSearchTerm('');
        // setSelectedHashtags([]);
        // setGender(null);
        // setCountry(null);
        // setInvite(null);
        props.toggle();
        props.history.push('/app/ads');
    };

    const inviteInfluencers = async () => {
        const searchObj = {
            country: '',
            hashtags: [],
            categories: [],
            followers: {
                min: 5,
                max: 1000,
            },
            language: '',
            gender: { label: 'All', value: null },
            inviteOnly: undefined,
            sorts: [
                {
                    key: 'followers',
                    direction: SortOrder.DESCENDING,
                },
            ],
        }
        if (props.mode !== 'create') {
            /**
             * select this saved ad
             */
            const ad_info = JSON.parse(localStorage.getItem('ad_info')) || {
                invite_only: true,
                limit_country: [""],
                limit_gender: null,
                ad_hashtags: ""
            }
            // @ts-ignore
            const selectedAdStore = RootStore?.adStore?.selectedAd
            // @ts-ignore
            const adID = props?.match?.params?.ad_id || selectedAdStore
            let savedFilter = JSON.parse(localStorage.getItem(`filter_${adID}`))
            if (adID) {
                localStorage.setItem('selected_ad', `${adID}`)
                savedFilter = JSON.parse(localStorage.getItem(`filter_${adID}`))
            }

            showLoading()
            const headers = {
                headers: {
                    Authorization: "Bearer " + RootStore.users.getToken(),
                }
            }
            const renderCountry = () => {
                const savedCountry = savedFilter ? savedFilter?.country : null
                if (country) {
                    const arr = Array.isArray(country) ? country.map(item => item?.value) : []
                    return arr
                } else if (savedCountry) {
                    if (typeof savedCountry === 'string') return [savedCountry]
                    if (typeof savedCountry === 'object') return [savedCountry?.value || '']
                    return ['']
                } else {
                    return ['']
                }
            }
            const renderGender = () => {
                const savedGender = savedFilter ? savedFilter?.gender : { label: "All", value: null }
                if (gender) {
                    return gender?.value || null
                } else if (savedGender) {
                    return savedGender?.value || null
                } else {
                    return null
                }
            }
            const renderInviteOnly = () => {
                const savedInviteOnly = savedFilter ? savedFilter?.inviteOnly : 0
                if (savedInviteOnly) {
                    return savedInviteOnly ? 1 : 0
                } else if (inviteOnly) {
                    return 1
                } else {
                    return 0
                }
            }

            const body = {
                countries: renderCountry(),
                gender: renderGender(),
                invite_only: renderInviteOnly()
            }

            /**
             * send country, gender, inviteOnly
             */
            await axios.patch(`${servicePath}${ADS_UPDATE_FILTER}/${adID}/`, body, headers).then((res) => {
                /**
                 * change saved filter
                 */

                if (country) {
                    savedFilter.country = country
                    localStorage.setItem(`filter_${adID}`, savedFilter)
                    ad_info.limit_country = [country[0]?.value || '']
                }
                if (gender) {
                    savedFilter.gender = gender
                    localStorage.setItem(`filter_${adID}`, savedFilter)

                    ad_info.limit_gender = gender?.value || null
                }
                if (inviteOnly) {
                    savedFilter.inviteOnly = inviteOnly
                    localStorage.setItem(`filter_${adID}`, savedFilter)

                    ad_info.invite_only = inviteOnly
                }

                ad_info.ad_hashtags = selectedHashtags.map(item => `#${item}`).join(' ')
                console.log('ad_info : ', ad_info)
                localStorage.setItem('ad_info', JSON.stringify(ad_info))
                Swal.close();
            }).catch(() => {
                Swal.close();
            })

            /**
             * redirect to custom search 
             */
            props.toggle();
            const data = {
                ...searchObj,
                hashtags: selectedHashtags,
                country: Array.isArray(country) && country.length > 0 ? country[0] : "",
                gender: gender,
                inviteOnly: inviteOnly,
            };

            await RootStore.discoverInfluencersStore.setSearchObj(data);
            /**
             * filtered hastags have been changed
             */
            localStorage.setItem(`filter_${adID}`, JSON.stringify(data));

            props.history.replace({
                pathname: '/app/influencer-explorer/custom-search',
                // state: {
                //     name: 'hashtag',
                //     value: selectedHashtags,
                //     update_filter: true,
                //     from:'ad'
                // },
            });
        } else {
            /**
             * select this saved ad
             */
            const adID = +localStorage.getItem('created_ad_id')
            localStorage.setItem('selected_ad', `${adID}`)

            showLoading()
            const headers = {
                headers: {
                    Authorization: "Bearer " + RootStore.users.getToken(),
                }
            }
            const renderCountry = () => {
                if (country) {
                    const arr = Array.isArray(country) ? country.map(item => item?.value) : []
                    return arr
                } else {
                    return ['']
                }
            }
            const renderGender = () => {
                if (gender) {
                    return gender?.value || null
                } else {
                    return 'all'
                }
            }
            const renderInviteOnly = () => {
                return inviteOnly ? 1 : 0
            }

            const ad_info = {
                invite_only: true,
                limit_country: [""],
                limit_gender: null,
                ad_hashtags: ""
            }

            const body = {
                countries: renderCountry(),
                gender: renderGender(),
                invite_only: renderInviteOnly()
            }

            /**
             * send country, gender, inviteOnly
             */
            await axios.patch(`${servicePath}${ADS_UPDATE_FILTER}/${adID}/`, body, headers).then((res) => {
                /**
                 * change saved filter
                 */

                ad_info.limit_country = [country[0]?.value || '']
                ad_info.limit_gender = gender?.value || null
                ad_info.invite_only = inviteOnly ? true : false
                ad_info.ad_hashtags = selectedHashtags.map(item => `#${item}`).join(' ')

                localStorage.setItem('ad_info', JSON.stringify(ad_info))

                Swal.close();
            }).catch(() => {
                Swal.close();
            })

            /**
             * redirect to custom search 
             */
            props.toggle();

            const data = {
                ...searchObj,
                hashtags: selectedHashtags,
                country: Array.isArray(country) && country.length > 0 ? country[0] : "",
                gender: gender,
                inviteOnly: inviteOnly,
            };

            await RootStore.discoverInfluencersStore.setSearchObj(data);
            /**
             * filtered hastags have been changed
             */
            localStorage.setItem(`filter_${adID}`, JSON.stringify(data));
            localStorage.removeItem('created_ad_id')

            props.history.replace({
                pathname: '/app/influencer-explorer/custom-search',
                // state: {
                //     name: 'hashtag',
                //     value: selectedHashtags,
                //     update_filter: true,
                //     from:'ad'
                // },
            });
        }
    };

    return (
        <Modal
            isOpen={props.isOpen}
            style={{ zIndex: 1090 }}
            className="searchHashtagModal"
        >
            <div className="searchHashtagModal_close-box" onClick={inviteInfluencers}>
                <CloseIcon width="20" height="20" />
            </div>
            <div className='searchHashtagModal__hashtagImgParent'>
                <img src={ManageHashtagsHeader} className="" alt="hashtags" />
            </div>
            <ModalBody>
                {showHashtagDiv ? (
                    <>
                        <div>
                            <span className='searchHashtagModal__title'>
                                <img className='searchHashtagModal__title__magnify' src={SearchIconNew} alt="magnify" />
                                {showHashtagDiv
                                    ? 'Discover & Invite Influencers by Hashtags'
                                    : 'Further narrow down discovering Influencers'}
                            </span>
                        </div>
                        <h6 className="searchHashtagModal__subtitle">Enter a few hashtags to get our AI engine started</h6>
                        <div className="d-flex justify-content-between">
                            <input
                                type="text"
                                placeholder="Enter hashtag"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value.trim())}
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (e.key === 'Enter') searchForHashtags();
                                }}
                            />
                            <button
                                disabled={searchTerm.length < 3}
                                className="searchHashtagModal__newsearchButton"
                                onClick={searchForHashtags}
                            >
                                Search
                            </button>
                        </div>
                        <div className="searchHashtagModal__selected mt-3">
                            <div className="title">Selected Hashtags:</div>
                            {selectedHashtags.length === 0 ? <div className="empty">your hashtags go here.</div> : ''}
                            <div className="selectedHashtags">
                                {selectedHashtags.map((hashtag: string, index: number) => (
                                    <div key={index} className="selectedHashtag">
                                        <p>
                                            <a
                                                href={`https://www.instagram.com/explore/tags/${hashtag}/`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {hashtag}
                                            </a>
                                        </p>
                                        <i
                                            className="simple-icon-close remove"
                                            onClick={() => removeFromSelected(hashtag)}
                                        ></i>
                                    </div>
                                ))}
                                {selectedHashtags.length > 1 && (
                                    <Button
                                        color="warning"
                                        className="deleteHashtag"
                                        onClick={() => setSelectedHashtags([])}
                                    >
                                        Remove all
                                    </Button>
                                )}
                            </div>
                        </div>

                        <div className="searchHashtagModal__content">
                            {fetchingData ? (
                                <div className="d-flex flex-column align-items-center my-4">
                                    <Spinner color="primary" className="mb-1" />
                                    <p>Please wait...</p>
                                </div>
                            ) : hashtags && hashtags.length === 0 ? (
                                <div className="searchHashtagModal__emptySearch">
                                    <NoSearchResultIcon />
                                    <p>No result found</p>
                                    <p>
                                        {' '}
                                        <b>Try different hashtags</b>
                                    </p>
                                </div>
                            ) : (hashtags && hashtags.length > 0) || selectedHashtags.length > 0 ? (
                                <div className="hashtags">
                                    {hashtags?.length
                                        ? hashtags.map(
                                            (hashtag) =>
                                                !selectedHashtags.includes(hashtag.name) && (
                                                    <div key={hashtag.name} className="hashtag">
                                                        <p>
                                                            <a
                                                                href={`https://www.instagram.com/explore/tags/${hashtag.name}/`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                #{hashtag.name}
                                                            </a>{' '}
                                                            ({hashtag.mediacount})
                                                        </p>
                                                        <div
                                                            className="add"
                                                            onClick={() => addToSelected(hashtag.name)}
                                                        >
                                                            +
                                                        </div>
                                                    </div>
                                                ),
                                        )
                                        : null}
                                </div>
                            ) : (
                                // <div className="searchHashtagModal__emptySearch">
                                //     <EmptyHashtagSearchIcon width="85px" />
                                //     <p>Search for hashtags</p>
                                // </div>
                                ''
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="mb-5">
                            <Label className="FurtherInfluencersModal__title">
                                Are you looking to work with influencers of a given gender?
                            </Label>

                            <div className="d-flex">
                                <CustomInput
                                    className="radio-style"
                                    type="radio"
                                    id="genderRadio1"
                                    name="genderlist"
                                    label="Female"
                                    value="female"
                                    onChange={(e) => {
                                        const { value, labels } = e.target
                                        setGender({ label: labels[0].textContent, value: value })
                                    }}
                                    checked={gender?.value === "female"}
                                />
                                <CustomInput
                                    className="radio-style"
                                    type="radio"
                                    id="genderRadio2"
                                    name="genderlist"
                                    label="Male"
                                    value="male"
                                    onChange={(e) => {
                                        const { value, labels } = e.target
                                        setGender({ label: labels[0].textContent, value: value })
                                    }}
                                    checked={gender?.value === "male"}
                                />
                                <CustomInput
                                    className="radio-style"
                                    type="radio"
                                    id="genderRadio4"
                                    name="genderlist"
                                    label="All"
                                    value={null}
                                    onChange={(e) => {
                                        const { value, labels } = e.target
                                        setGender({ label: labels[0].textContent, value: value })
                                    }}
                                    checked={gender?.value === null}
                                />
                            </div>
                        </div>

                        <div className="mb-5">
                            <Label className="FurtherInfluencersModal__title">
                                Are you looking to work with influencers of certain countries:
                            </Label>

                            <div className="FurtherInfluencersModal__content">
                                <FormGroup className="error-l-100">
                                    <FormikReactSelect
                                        name="country"
                                        id="country"
                                        isMulti
                                        isClearable={true}
                                        options={CountriesList.map((cou: ICountryModel) => {
                                            return {
                                                label: cou.name,
                                                value: cou.id,
                                            };
                                        })}
                                        onChange={(name: string, value: any) => {
                                            setCountry(value);
                                        }}
                                        onBlur={() => { }}
                                        value={country}
                                    ></FormikReactSelect>
                                </FormGroup>
                            </div>
                        </div>

                        {/* <Label className="mb-1 FurtherInfluencersModal__title">
                                Is their audience engagement important? if yes, select the range you are looking for the
                                influencers to have
                            </Label>
                            <br />
                            <br />
                            <br /> */}

                        <div className="mb-5">
                            <Label className="FurtherInfluencersModal__title">
                                {/* Is their audience engagement important? if yes, select the range you are looking for the influencers to have  */}
                                How would you like influencers make offer to your ad:
                            </Label>
                            <div>
                                <CustomInput
                                    type="radio"
                                    id="inviteRadio1"
                                    name="invite"
                                    className="radio-style"
                                    label="Only influencers which have been invited by me can send me offers"
                                    value={1}
                                    onChange={(e) => setInvite(true)}
                                    checked={Boolean(inviteOnly) === true}
                                />
                                <CustomInput
                                    type="radio"
                                    id="inviteRadio2"
                                    name="invite"
                                    className="radio-style"
                                    label="All influencers can send me offers"
                                    value={0}
                                    onChange={(e) => setInvite(false)}
                                    checked={Boolean(inviteOnly) === false}
                                />
                            </div>
                        </div>
                    </>
                )}
            </ModalBody>
            {/* <ModalFooter> */}
            <div className='searchHashtagModal__manageHashtagsFooter'>
                {showHashtagDiv ? (
                    <>
                        {/* <Button color="link" onClick={goToAds}>
                            Go to My Ads
                        </Button> */}
                        <button
                            className="searchHashtagModal__next"
                            onClick={() => setshowHashtagDiv(false)}
                        >
                            Next
                        </button>
                    </>
                ) : (
                    <div className="searchHashtagModal__manageHashtagsFooter__section-2">
                        {/* <Button color="link" onClick={() => setshowHashtagDiv(true)} className="mr-4 float-left">
                            <i className="simple-icon-arrow-left"></i> Back
                        </Button> */}
                        <button
                            className="searchHashtagModal__back"
                            onClick={() => setshowHashtagDiv(true)}
                        >
                            Back
                        </button>
                        {/* <Button className="float-right" color="success" onClick={inviteInfluencers}>
                            <i className="iconsminds-magnifi-glass"></i> Search Influencers
                        </Button> */}
                        <div className='searchHashtagModal__manageHashtagsFooter__section-2_div'>
                            <span className="cursor-pointer buttonLink pr-4 pt-2" onClick={goToAds}>
                                skip
                            </span>
                            <button
                                className="searchHashtagModal__search"
                                onClick={inviteInfluencers}
                            >
                                <i className="iconsminds-magnifi-glass"></i>{' '} Search Influencers
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {/* </ModalFooter> */}
        </Modal>
    );
};

export default InviteInfluencersPopup;
