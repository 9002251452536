import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import App from "./App";
import store from "./ReduxRootStore";
import { isStaging } from "./constants/defaultValues";

declare var productionMode: boolean;

if (productionMode) {
  Sentry.init({
    dsn: "https://6049805fa10d4bc5a6cae403056eed12@o299198.ingest.sentry.io/5216996",
    environment: isStaging() ? "staging" : "production",
  });
}

export default function render() {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
}
