import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
import { TabsComponent } from "../../../common-components";
import SimilarHashtags from "./SimilarHashtags";
import SimilarWords from "./SimilarWords";
import { Colxx } from "../../../../template/components/common/CustomBootstrap";
import { Row } from "reactstrap";
import { BreadCrumb } from "../../../common-components";

interface Props extends RouteComponentProps {}

@observer
export default class AI extends Component<Props> {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadCrumb heading="AI" {...this.props} />
          </Colxx>
        </Row>
        <TabsComponent
          tabs={[
            {
              tabName: "Similar Words",
              component: <SimilarWords key="SimilarWordsTab" {...this.props} />,
              to: `${this.props.match.url}`,
            },
            {
              tabName: "Hashtags",
              component: (
                <SimilarHashtags key="SimilarHashtagsTab" {...this.props} />
              ),
              to: `${this.props.match.url}/hashtags`,
            },
          ]}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}
