import React, { useEffect, useState, useRef } from 'react';
import { Field, Form } from 'formik';
import { FormGroup, Label, Button, Spinner } from 'reactstrap';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import RootStore from '../../../../store/RootStore';
import { FormikReactSelect, FormikDokaImage, ReactSelectMulti } from '../../../common-components';
import { ajaxErrorAlert, handleError, showLoading, notURL, hashtagAutoLinker } from '../../../../lib/Utils';
import Swal from 'sweetalert2';
import { NotificationManager } from '../../../../template/components/common/react-notifications';
import { ICountryModel } from '../../../../store/models/CountryModel';
import { ICategoryModel } from '../../../../store/models/CategoryModel';
import profileImg from '../../../../assets/img/profilePlaceholder.jpg';
import Picker, { SKIN_TONE_MEDIUM_LIGHT } from 'emoji-picker-react';
import FormikWithRef from '../../../common-components/FormikWithRef';
import $ from "jquery";
import { CountriesList } from './../../../../constants/countriesList'

const BrandInfoSchema = Yup.object().shape({
    name: Yup.string().required("Brand name can't be empty").matches(notURL, "You can't add URL as Brand Name"),
    description: Yup.string().required("Brand Description name can't be empty"),
    logo: Yup.string(),
    country_id: Yup.string(),
    categories: Yup.array()
        .of(
            Yup.object().shape({
                label: Yup.string(),
                value: Yup.number(),
            }),
        )
        .nullable()
        .required('You must select a category!'),
    url: Yup.string().url('Please enter a valid company URL').nullable(),
});

function EditBrandInfo() {
    const [logoChanged, setLogoChanged] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const formik = useRef(null);
    const url = profileImg;
    useEffect(() => {
        RootStore.fetchStaticData()
        const loadData = async () => {
            try {
                await RootStore.fetchStaticData();
                setDataLoaded(true);
            } catch (e) {
                handleError(e);
                ajaxErrorAlert('Failed to get static data! please refresh the page...');
            }
        };

        loadData();
    });

    const setInitialValues = () => {
        const brandInfo = RootStore.identityStore.getByUserId(RootStore.users.currentUser.id);
        const country = CountriesList.filter((country: ICountryModel) => country.id === brandInfo.country_id)[0];

        return {
            logo: brandInfo.logo,
            name: brandInfo.name,
            description: brandInfo.description,
            url: brandInfo.url,
            categories: brandInfo?.categories?.map((category: ICategoryModel) => ({
                value: category.id,
                label: category.title,
            })),
            country_id: { label: country.name, value: country.id },
        };
    };
    // const brandInfo = RootStore.identityStore.getByUserId(RootStore.users.currentUser.id);
    const [show, setShow] = useState(false);
    // const [disp, setEmoji] = useState(brandInfo.description);
    // const addEmoji = (event, emojiObject) => {
    //     if (event.target.value) {
    //         setEmoji(event.target.value);
    //     } else {
    //         setEmoji(disp + emojiObject.emoji);
    //     }
    // };
    const addEmoji = (event, emojiObject) => {
        if (emojiObject.emoji) {
            // will give the current position of the cursor
            const curorPos = $('#onboard-description').prop('selectionStart');

            // will get the value of the text area
            let inputVal = $('#onboard-description').val().toString();
            const finalVal = inputVal.slice(0, curorPos) + emojiObject.emoji + inputVal.slice(curorPos)

            // setting the updated value in the text area
            formik.current.setFieldValue('description', finalVal);
            setShow(false);
        }
    };
    return !dataLoaded ? (
        <Spinner />
    ) : (
        <FormikWithRef
            ref={formik}
            initialValues={setInitialValues()}
            validationSchema={BrandInfoSchema}
            onSubmit={async (values: any, formikActions: any) => {
                formikActions.setSubmitting(true);

                await Swal.fire({
                    text: `Are you sure you want to change your company info?`,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: 'rgb(18, 171, 40)',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: "Yes, I'm Sure",
                })
                    .then(async (res) => {
                        if (res.value) {
                            let brand = RootStore.identityStore.getByUserId(RootStore.users.currentUser.id);
                            let requestData = {};

                            if (values.url && !values.url.includes('http')) {
                                values.url = `http://${values.url}`;
                            }

                            if (logoChanged) {
                                requestData = {
                                    name: values.name,
                                    description: values.description,
                                    logo: values.logo,
                                    country_id: values.country_id.value,
                                    categories: values.categories.map((category: any) => category.value),
                                    url: values.url,
                                };
                            } else {
                                requestData = {
                                    name: values.name,
                                    description: values.description,
                                    country_id: values.country_id.value,
                                    categories: values.categories.map((category: any) => category.value),
                                    url: values.url,
                                };
                            }
                            showLoading();

                            await RootStore.identityStore.editIdentity(brand.id, requestData);
                            Swal.close();
                            formikActions.setSubmitting(false);
                            NotificationManager.success(
                                'Done',
                                'Your brand data was updated successfully!',
                                3000,
                                null,
                                null,
                                'filled',
                            );
                        }
                    })
                    .catch((e) => {
                        handleError(e);
                        ajaxErrorAlert('There was a problem in editing your profile.');
                    });

                formikActions.setSubmitting(false);
            }}
        >
            {({
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                isSubmitting,
            }) => (
                <Form className="av-tooltip tooltip-label-right panel-form editBrandInfoForm">
                    <FormGroup className="error-l-100">
                        <Label className="position-relative">
                            <b>Brand Name:</b>
                            {errors.name && touched.name && <div className="invalidFeedback">{errors.name}</div>}
                        </Label>
                        <Field className="form-control" name="name" disabled={isSubmitting} />
                    </FormGroup>

                    <FormGroup className="error-l-100">
                        <Label className="position-relative">
                            <b>Brand Description: </b>
                            {errors.description && touched.description && (
                                <div className="invalidFeedback">{errors.description}</div>
                            )}
                        </Label>
                        <Field
                            id="onboard-description"
                            className="form-control"
                            component="textarea"
                            name="description"
                            disabled={isSubmitting}
                            value={values.description}
                            onChange={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                let value = hashtagAutoLinker(event.currentTarget.value, true);
                                setFieldValue('description', value);

                                // setEmoji(value);
                            }}
                            onFocus={() => setShow(show ? false : null)}
                            style={{
                                height: 175,
                                resize: 'none',
                                paddingBottom: '1.75rem',
                            }}
                        />
                        <div className="emojieditBrandInfoForm"  >
                            <span
                                onClick={() => setShow(!show)}
                                className={'cursor-pointer simple-icon-emotsmile'}
                            ></span>

                            {show && (
                                <div className="pickereditBrandInfoForm">
                                    <Picker
                                        onEmojiClick={addEmoji}
                                        skinTone={SKIN_TONE_MEDIUM_LIGHT}
                                        disableSkinTonePicker={false}
                                        native
                                    />
                                </div>
                            )}
                        </div>
                    </FormGroup>

                    <FormGroup className="error-l-100">
                        <Label className="position-relative">
                            <b>Your company URL:</b>
                            {errors.url && touched.url && <div className="invalidFeedback">{errors.url}</div>}
                        </Label>
                        <Field className="form-control" name="url" disabled={isSubmitting} />
                    </FormGroup>

                    <FormGroup className="error-l-100">
                        <Label className="position-relative">
                            <b>Logo:</b>
                            {errors.logo && touched.logo && <div className="invalidFeedback">{errors.logo}</div>}
                        </Label>
                        <FormikDokaImage
                            onChange={(name: any, value: any) => {
                                values.logo = value;
                                setLogoChanged(true);
                                setFieldValue(name, value);
                            }}
                            name="logo"
                            picture={typeof values.logo === 'string' ? values.logo : url}
                        />
                    </FormGroup>

                    <FormGroup className="error-l-100">
                        <Label className="position-relative">
                            <b>Select a business category:</b>
                            {errors.categories && touched.categories && (
                                <div className="invalidFeedback">{errors.categories}</div>
                            )}
                        </Label>
                        <ReactSelectMulti
                            name="categories"
                            onChange={setFieldValue}
                            value={values.categories}
                            onBlur={handleBlur}
                            options={RootStore.getCategorySelectOptions()}
                            styles={{
                                menuList: (provided, state) => ({
                                    ...provided,
                                    height: 108
                                })
                            }}
                        />
                    </FormGroup>

                    <FormGroup className="error-l-100">
                        <Label className="position-relative">
                            <b>Where are you located?</b>
                            {errors.country_id && touched.country_id && (
                                <div className="invalidFeedback">{errors.country_id}</div>
                            )}
                        </Label>
                        <FormikReactSelect
                            formatGroupLabel={(data: any) => (
                                <div>
                                    <h4>{data.label}</h4>
                                </div>
                            )}
                            name="country_id"
                            id="onboarding_country"
                            value={values.country_id}
                            options={CountriesList.map((country: ICountryModel) => {
                                return { label: country.name, value: country.id };
                            })}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            disabled={isSubmitting}
                            styles={{
                                menuList: (provided, state) => ({
                                    ...provided,
                                    height: 108
                                })
                            }}
                        />
                    </FormGroup>

                    <Button color="primary" type="submit">
                        Save Brand Info
                    </Button>
                </Form>
            )}
        </FormikWithRef>
    );
}

export default observer(EditBrandInfo);
