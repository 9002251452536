import React, { Component } from "react";
import moment from "moment";

import { Table, Spinner } from "reactstrap";
import { IAdModel } from "../../../../../../../../../../../store/models/AdModel";
import { NoSearchResultIcon } from "../../../../../../../../../../../assets/icons";

interface Ipagination {
  limit: number;
  page: number;
  pagesCount: number;
  totalCount: number;
}

interface Props {
  checkouts: any;
  pagination: Ipagination;
  datafetched?: boolean;
}
interface State {
  payoutsList: any;
  displayAdPreview: boolean;
  adToBeShown: IAdModel | null;
  currentTaskIndex: number | null;
  pagination: Ipagination;
}

export default class WithdrawalRequestTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      payoutsList: this.props.checkouts,
      displayAdPreview: false,
      adToBeShown: null,
      currentTaskIndex: null,
      pagination: this.props.pagination,
    };
    this.onDisplayAdPreview = this.onDisplayAdPreview.bind(this);
  }
  onDisplayAdPreview() {
    this.setState((prevState) => ({
      displayAdPreview: !this.state.displayAdPreview,
    }));
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.checkouts !== this.props.checkouts) {
      this.setState({
        payoutsList: this.props.checkouts,
      });
    }
  }

  render() {
    const { datafetched } = this.props;
    const { payoutsList } = this.state;
    return (
      <div style={{ minHeight: 500 }}>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th style={{ width: "20%" }}>Request Date</th>
              <th>Amount</th>
              <th style={{ width: "20%" }}>Payout Date</th>
              <th>Status</th>
            </tr>
          </thead>
          {datafetched && (
            <tbody style={{ width: "100%", overflow: "auto" }}>
              {Array.isArray(payoutsList) &&
                payoutsList.length > 0 &&
                payoutsList?.map((payouts, index) => {
                  const { paid_at, created_at, amount, status } = payouts;
                  const c = moment(created_at).format("MM/DD/YYYY - hh:mm a");
                  const p = moment(paid_at).format("MM/DD/YYYY - hh:mm a");
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{c}</td>
                      <td>{amount}</td>
                      <td>{p}</td>
                      <td>{status}</td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </Table>
        {!datafetched && (
          <div
            style={{
              width: "100%",
              height: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner color="primary" size={"lg"} className="spinner" />
          </div>
        )}
        {Array.isArray(payoutsList) &&
          payoutsList?.length === 0 &&
          datafetched && (
            <div className="searchHashtagModal__emptySearch">
              <NoSearchResultIcon />
              <br />
              <p>No result found</p>
              <p>
                <b>Try different hashtags</b>
              </p>
            </div>
          )}
      </div>
    );
  }
}
