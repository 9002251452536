import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { TimeLineContentMetricModel } from "./TimeLineContentMetricModel";
import { TimeLineContentMediaModel } from "./TimeLineContentMediaModel";

/**
* this method Time Line Content Model
*/
export const TimeLineContentModel = types
  .model({
    id: types.identifierNumber,
    code: types.maybeNull(types.string),
    created_at: types.maybeNull(types.string),
    updated_at: types.maybeNull(types.string),
    integration_id: types.maybeNull(types.number),
    caption: types.maybeNull(types.string),
    metric: types.maybeNull(TimeLineContentMetricModel),
    medias: types.maybeNull(types.array(TimeLineContentMediaModel)),
  })
  .actions((self) => ({}))
  .views((self) => ({}))
  .views((self) => ({}));
export interface ITimeLineContentModel
  extends Instance<typeof TimeLineContentModel> {}

export interface ITimeLineContentModelSnapshotIn
  extends SnapshotIn<typeof TimeLineContentModel> {}

export interface ITimeLineContentModelSnapshotOut
  extends SnapshotOut<typeof TimeLineContentModel> {}
