import { IOfferModel } from "./store/models/OfferModel";
import { NotificationManager } from "./template/components/common/react-notifications";

export function notifyNewOffer(offer: IOfferModel) {
  NotificationManager.info(
    "New Offer!",
    `You just received a new offer from ${offer.getOpponentIdentity().name}`,
    4000,
    null,
    null,
    "filled"
  );
}
