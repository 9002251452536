import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Card,
  CardBody,
  Row,
  Spinner,
} from "reactstrap";
import RootStore from "../../store/RootStore";
import { Colxx } from "../../template/components/common/CustomBootstrap";
import { IOfferModel } from "../../store/models/OfferModel";

import { IAdModel } from "../../store/models/AdModel";
import { RouteComponentProps } from "react-router-dom";

import OfferCard from "../app/offers/components/OfferCard";

interface Props extends RouteComponentProps {
  isOpen: boolean;
  onClose: () => void;
  ad: IAdModel;
}
interface State {
  ad: IAdModel;
  dataFetched: boolean;
}
export class AdsOffersList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ad:
        this.props.ad.offer_count > 0 && this.props.ad.getTotalOffers() === 0
          ? null
          : this.props.ad,
      dataFetched:
        this.props.ad.offer_count > 0 && this.props.ad.getTotalOffers() === 0
          ? false
          : true,
    };
  }

  async componentDidMount() {
    if (this.props.ad.offer_count > 0 && this.props.ad.getTotalOffers() === 0) {
      await RootStore.adStore.getAdByIdForAdminPanel(
        this.props.ad.id,
        false,
        true
      );
      this.setState({
        dataFetched: true,
        ad: RootStore.adStore.ads.find(
          (ad: IAdModel) => ad.id === this.props.ad.id
        ),
      });
    }
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { ad } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={onClose} modalClassName={`offerModal`}>
        <ModalHeader toggle={onClose}>Offers</ModalHeader>
        <ModalBody>
          {!this.state.dataFetched ? (
            <div className="w-100 d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <Card className="mb-4">
              <CardBody>
                <Row>
                  <Colxx>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Influencer</th>
                          <th>Ad</th>
                          <th>Price</th>
                          <th style={{ textAlign: "center" }}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {RootStore.offerStore.offers.map(
                          (offer: IOfferModel) => {
                            if (offer.ad_id.id === ad.id) {
                              return (
                                <OfferCard offer={offer} {...this.props} />
                              );
                            }
                            return null;
                          }
                        )}
                      </tbody>
                    </Table>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          )}
        </ModalBody>
      </Modal>
    );
  }
}
