import React, { Component, GetDerivedStateFromProps } from 'react';
import numbro from 'numbro';
import classNames from 'classnames';
import { truncate } from 'lodash';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { minifyPrice, minifyNum } from '../../../../lib/Utils';
import RootStore from '../../../../store/RootStore';
import { AddedTickIcon } from '../../../../assets/icons';
import { IInfluencerModel } from '../../../../store/models/InfluencerModel';
import ProfilePlaceholder from '../../../../assets/img/profilePlaceholder.jpg';

interface Props {
    influencer: IInfluencerModel;
    categoryFilter?: string;
    adId?: number | string | null;
    toggleAdSction?: () => void;
}

interface State {
    selected: boolean;
    imgLoaded: boolean;
    imgSrc: string;
}
/**
 * @param props , { influencer ,categoryFilter ,adId ,toggleAdSction }
 * @param state , { selected , imgLoaded , imgSrc }
 * 
 */
@observer
export default class InfluencerListItem extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selected: false,
            imgLoaded: false,
            imgSrc: ProfilePlaceholder,
        };
    }
    /**
     * check selected influencer
     */
    toggleSelected = (event: any) => {
        if (this.state.selected) {
            this.setState({ selected: !this.state.selected });
            RootStore.invitationStore.removeFromList(this.props.influencer);
        } else {
            this.setState({ selected: !this.state.selected });
            RootStore.invitationStore.addToList(this.props.influencer);
        }
    };

    componentDidMount() {
        if (this.props.influencer.identity) {
            if (this.props.influencer.identity.picutre_path) {
                this.setState({
                    imgSrc: this.props.influencer.identity.picutre_path.getThumbnailUrl(40, 40),
                });
            }
        }

        if (RootStore.invitationStore.temporaryList.length === 0) {
            this.setState({ selected: false });
            return;
        }
        let filter = RootStore.invitationStore.temporaryList.filter(
            (inf: IInfluencerModel) => inf.identity.id === this.props.influencer.identity.id,
        );
        if (filter.length > 0) {
            this.setState({ selected: true });
            return;
        }
        this.setState({ selected: false });
    }

    static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props: Props, state: State) => {
        if (RootStore.invitationStore.temporaryList.length === 0) {
            return { selected: false };
        }

        let filter = RootStore.invitationStore.temporaryList.filter(
            (infuencer: IInfluencerModel) => infuencer.identity.id === props.influencer.identity.id,
        );

        if (filter.length > 0) {
            return { selected: true };
        }

        return { selected: false };
    };

    render() {
        const { influencer } = this.props;
        return (
            <tr
                className={classNames('influencerListItem', {
                    InfluencerListItem_selected: this.state.selected,
                })}
            >
                <td>
                    <div
                        className="selectCheckBox"
                        onClick={(event) =>
                            this.props?.adId ? this.toggleSelected(event) : this.props.toggleAdSction()
                        }
                    >
                        <AddedTickIcon />
                    </div>
                    <LazyLoadImage
                        effect={'blur'}
                        alt="influencer"
                        src={this.state.imgSrc}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = ProfilePlaceholder;
                        }}
                        onLoad={() => this.setState({ imgLoaded: true })}
                        className={classNames({
                            influencerCard_imageProfile_placeholder: this.state.imgSrc === ProfilePlaceholder,
                        })}
                    />
                </td>

                <td>
                    <a
                        href={`https://www.instagram.com/${influencer.identity.username}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {influencer?.identity && influencer?.identity?.username
                            ? truncate(influencer?.identity?.username, { length: 20 })
                            : influencer?.identity && influencer?.identity.name
                                ? truncate(influencer?.identity?.name, { length: 20 })
                                : 'No name'}
                    </a>
                </td>

                <td>{influencer.identity ? minifyNum(influencer?.identity?.followers) : '?'}</td>
                <td style={{ color: 'green' }}>
                    {influencer.identity
                        ? Math.floor(
                            influencer?.identity?.quality_score * 100 > 100
                                ? 100
                                : influencer?.identity?.quality_score * 100 || 0,
                        ) + '%'
                        : ''}
                </td>
                <td>
                    {influencer.identity
                        ? numbro(influencer?.identity?.engagement_rate).format({
                            output: 'percent',
                            mantissa: 2,
                            optionalMantissa: true,
                        })
                        : '?'}
                </td>
                <td>
                    {influencer?.identity?.country_id ? (
                        <>{truncate(influencer?.identity?.getCountryName(), { length: 15 })}</>
                    ) : (
                        'Canada'
                    )}
                </td>
                <td>{influencer?.identity?.category_elastic}</td>
                <td>{influencer?.identity ? '$' + minifyPrice(influencer?.identity?.earnings) : ''}</td>
                <td className="influencerListItem__actions">
                    <div className="viewProfile">
                        <Link target="_blank" to={`/app/influencer-explorer/${influencer?.identity?.id}`}>View Profile</Link>
                    </div>
                    <div
                        className={classNames('inviteInfluencer', {
                            InfluencerListItem_selected: this.state.selected,
                        })}
                        onClick={this.toggleSelected}
                    >
                        {this.state.selected ? 'Selected' : 'Invite'}
                    </div>
                </td>
            </tr>

            // <div
            //   className={classNames("influencerCard", {
            //     influencerCard_selected: selected,
            //   })}>
            //   {selected && <AddedTickIcon className="addedTick" />}
            //   <div>
            //     <div className="influencerCard_imageProfile">
            //       <img
            //         src={imgSrc}
            //         onLoad={() => setImgLoaded(true)}
            //         className={classNames({
            //           influencerCard_imageProfile_placeholder: imgSrc === ProfilePlaceholder,
            //         })}
            //         alt=""
            //       />

            //       {influencer.identity &&
            //         influencer.identity.received_offers &&
            //         influencer.identity.received_offers.length > 0 && (
            //           <span data-tip data-for="onGoingOffers">
            //             <HandshakeIcon />
            //             {influencer.identity.received_offers.length}
            //             <ReactTooltip id="onGoingOffers" type="dark" effect="solid">
            //               <p>{influencer.identity.received_offers.length} ongoing offers</p>
            //             </ReactTooltip>
            //           </span>
            //         )}
            //     </div>
            //   </div>
            //   <div className="influencerCard__influencerDetails">
            //     <p className="influencerName">
            //       {influencer.integration && influencer.integration.full_name
            //         ? truncate(influencer.integration.full_name, { length: 13 })
            //         : influencer.identity && influencer.identity.name
            //         ? truncate(influencer.identity.name, { length: 13 })
            //         : "No name"}
            //     </p>
            //     <p>
            //       <InstagramIcon />
            //       {influencer.integration && influencer.integration.username
            //         ? influencer.integration.username
            //         : "No Username"}
            //     </p>

            //     <p className="qualityScore">
            //       Quality Score: &nbsp; <span>2.5</span>{" "}
            //     </p>

            //     <div className="personalInfo">
            //       <div className="left">
            //         <p className="age">
            //           <AgeIcon />
            //           24
            //         </p>
            //         <p className="gender">
            //           <GenderIcon />
            //           Female
            //         </p>
            //         <p className="language">
            //           <GlobalIcon />
            //           English
            //         </p>
            //       </div>
            //       <div className="right">
            //         <p className="category">
            //           <CategoryIcon />
            //           {influencer.identity.categories && influencer.identity.categories.length > 0
            //             ? influencer.identity.categories[0].title.toLowerCase() !== "other"
            //               ? influencer.identity.categories[0].title
            //               : truncate(
            //                   RootStore.categories.find(
            //                     (cate: ICategoryModel) =>
            //                       cate.id === influencer.identity.categories[0].parent_id
            //                   ).title,
            //                   { length: 15 }
            //                 )
            //             : "Other"}
            //         </p>
            //         <p className="country">
            //           {influencer.identity.country_id ? (
            //             <>
            //               <MapPinIcon />
            //               {truncate(influencer.identity.getCountryName(), { length: 15 })}
            //             </>
            //           ) : (
            //             "Canada"
            //           )}
            //         </p>
            //         <p className="price">
            //           <PriceIcon />
            //           $5
            //         </p>
            //       </div>
            //     </div>
            //   </div>
            //   <div className="separator"></div>
            //   <div className="influencerCard__statistics">
            //     <div className="influencerCard__statistics--item">
            //       <h2>
            //         {influencer.integration
            //           ? numbro(influencer.integration.followers).format({
            //               spaceSeparated: false,
            //               average: true,
            //             })
            //           : "?"}
            //       </h2>
            //       <span>Followers</span>
            //     </div>
            //     <div className="influencerCard__statistics--item">
            //       <h2>
            //         {influencer.integration
            //           ? numbro(influencer.integration.followings).format({
            //               spaceSeparated: false,
            //               average: true,
            //             })
            //           : "?"}
            //       </h2>
            //       <span>Following</span>
            //     </div>
            //     <div className="influencerCard__statistics--item">
            //       <h2>
            //         {influencer.integration
            //           ? numbro(influencer.integration.engagement_rate).format({
            //               output: "percent",
            //               mantissa: 2,
            //               optionalMantissa: true,
            //             })
            //           : "?"}
            //       </h2>
            //       <span>Engagement</span>
            //     </div>
            //   </div>
            //   <div className="d-flex justify-content-between align-items-center w-100 mt-4">
            //     <Button
            //       outline
            //       color="primary"
            //       className={classNames({
            //         influencerCard_buttonSelected: selected,
            //       })}
            //       onClick={(event: any) => toggleSelected(event)}>
            //       {selected ? "Selected" : "Invite"}
            //     </Button>

            //     <Link
            //       to={`/app/influencer-explorer/${influencer.identity.id}`}
            //       className="buttonLink cursor-pointer">
            //       View Profile
            //     </Link>
            //   </div>
            // </div>
        );
    }
}
