import * as React from 'react';
import { FormikValues } from 'formik';
import { Button, ButtonGroup } from 'reactstrap';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';

import { IContentModel } from '../../../../store/models/ContentModel';
import ContentPreview from '../../content-manager/components/preview/ContentPreview';
import RootStore from '../../../../store/RootStore';
import ContentSelectDialogue from '../../content-manager/components/ContentSelectDialogue';
import ContentFormDialogue, { ContentTypeEnum } from '../../content-manager/components/ContentFormDialogue';

interface Props extends FormikValues, RouteComponentProps {
    disabled?: boolean;
    offerMode?: boolean;
    disableLibrary?: boolean;
    onBoardingMode?: boolean;
    postData?:any
}

interface State {
    content: IContentModel | null;
}
/**
 * right section of complete offer - device section
 */
@observer
export default class ContentSelectFormik extends React.Component<Props, State> {
    private selectContentRef: React.RefObject<ContentSelectDialogue> | null;
    private contentFormRef: React.RefObject<ContentFormDialogue> | null;

    static defaultProps = {
        disabled: false,
        offerMode: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            content: props.value,
        };

        this.contentFormRef = React.createRef();
        this.selectContentRef = React.createRef();

        this.editContent = this.editContent.bind(this);
        this.deleteContent = this.deleteContent.bind(this);
        this.browse = this.browse.bind(this);
        this.newPost = this.newPost.bind(this);
        this.newStory = this.newStory.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    static getDerivedStateFromProps(props: any, state: any): any {
        RootStore.contentStore.fetchAllIfNecessary();
        if (!props) return;

        const content = props.value;

        return { content };
    }

    private handleChange(value: IContentModel | null) {
        this.setState((prevState) => {
            this.props.onChange(this.props?.name, value);

            return { content: value };
        });
    }

    private handleBlur() {
        this.props.onBlur(this.props?.name, true);
    }

    private deleteContent() {
        this.handleChange(null);
    }

    private browse() {
        if (this.selectContentRef) {
            this.selectContentRef?.current?.open();
        }
    }

    private newPost() {
        if (this.contentFormRef) {
            this.contentFormRef?.current?.openNewDialogue(ContentTypeEnum.POST);
        }
    }

    private newStory() {
        if (this.contentFormRef) {
            this.contentFormRef?.current?.openNewDialogue(ContentTypeEnum.STORY);
        }
    }

    private editContent() {
        if (this.contentFormRef?.current) {
            this.contentFormRef.current?.loadForEdit(this.state?.content?.id);
        }
    }

    render() {
        return (
            <>
                <ContentFormDialogue
                    persistOnSave={!this.props?.offerMode}
                    saveButtonText={'Save & Use This Content'}
                    {...this.props}
                    handleChange={this.handleChange}
                    ref={this.contentFormRef}
                    postData={this.props?.postData}
                />
                <ContentSelectDialogue
                    handleChange={this.handleChange}
                    {...this.props}
                    ref={this.selectContentRef}
                    onNewContent={(type: ContentTypeEnum) => {
                        if (this.contentFormRef) {
                            this.contentFormRef?.current?.openNewDialogue(type);
                        }
                    }}
                />
                <div className="clearfix mb-3 counter-preview p-0">
                    {this.state?.content !== null && (
                        <ContentPreview style={{ float: 'left' }} model={this.state?.content} postData={this.props?.offer} />
                    )}
                </div>
                <div className="clearfix flex-column"></div>
                <div
                    className={classNames(
                        `${
                            this.state?.content === null || this.props?.onBoardingMode ? 'content-select-container' : ''
                        } clearfix`,
                        {
                            disabled: this.props?.disabled,
                        },
                    )}
                >
                    {this.state?.content === null ? (
                        <div style={{ textAlign: 'center' }}>
                            {this.props?.disabled ? (
                                <div className={'no-content-selected'}>No content selected!</div>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <ButtonGroup className="mb-3">
                                        <Button color={'secondary'} size={'sm'} onClick={this.newPost}>
                                            Feed Post
                                        </Button>
                                        <Button color={'secondary'} size={'sm'} onClick={this.newStory}>
                                            Story Post
                                        </Button>
                                    </ButtonGroup>
                                    {this.props.disableLibrary ? null : (
                                        <Button color={'primary'} size={'sm'} onClick={this.browse}>
                                            Select From My Library
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : this.props?.onBoardingMode ? (
                        <>
                            <div className="btn-grp-container">
                                <Button color="secondary" size="sm" className="mr-2" onClick={this.editContent}>
                                    <span
                                        style={{ verticalAlign: 'sub', fontSize: '1.5em' }}
                                        className="glyph-icon simple-icon-pencil"
                                    />
                                    Edit Ad content {this.state?.content?.type === 'story' ? 'story Set' : ''}
                                </Button>
                                <Button color="danger" size="sm" onClick={this.deleteContent}>
                                    <span
                                        style={{ verticalAlign: 'sub', fontSize: '1.5em' }}
                                        className="glyph-icon simple-icon-trash"
                                    />
                                    Remove Ad content
                                </Button>
                            </div>
                        </>
                    ) : (
                        <React.Fragment>
                            {/* under mobile device */}
                            <div className="btn-grp-container">
                                <ButtonGroup className="mb-2">
                                    <Button color={'danger'} size={'sm'} onClick={this.deleteContent}>
                                        &nbsp;
                                        <span
                                            style={{ verticalAlign: 'sub', fontSize: '1.5em' }}
                                            className="glyph-icon simple-icon-trash"
                                        />
                                    </Button>
                                    <Button color={'secondary'} size={'sm'} onClick={this.editContent}>
                                        Edit {this.state?.content?.type === 'story' ? 'Story Set' : 'Feed Post'}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </>
        );
    }
}
