import StoryPreview from './StoryPreview';
import PostPreview from './PostPreview';
import React from 'react';
import $ from 'jquery';

export interface ISamplePostData {
    name: string;
    profilePicture: string;
    likes: number;
    comments: number;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    model: any;
    onboardingPreview?: boolean;
    samplePostData: ISamplePostData;
    postData?: Object;
}

interface State {
    screenWidth: number;
    screenHeight: number;
    media: any;
}

export default class ContentPreview extends React.Component<Props, State> {
    private ref: HTMLDivElement | null = null;

    static defaultProps = {
        onboardingPreview: false,
        samplePostData: {
            name: '',
            comments: 123,
            likes: 1222,
            profilePicture: '',
        },
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            screenWidth: 0,
            screenHeight: 0,
            media: null,
        };
    }

    getVisibleHeight(elem: JQuery) {
        let elBottom, elTop, scrollBot, scrollTop, visibleBottom, visibleTop;
        scrollTop = $(window).scrollTop() as number;
        scrollBot = scrollTop + ($(window).height() as number);
        // @ts-ignore
        elTop = elem.offset().top;
        // @ts-ignore
        elBottom = elTop + elem.outerHeight();
        visibleTop = elTop < scrollTop ? scrollTop : elTop;
        visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;
        return visibleBottom - visibleTop;
    }

    componentDidMount(): void {
        this.setState({ media: this.props.model?.medias ? this.props.model : { medias: this.props.model } });

        let width = $(this.ref as HTMLDivElement).width() as number;
        let height = $(this.ref as HTMLDivElement).innerHeight() as number;
        height = this.getVisibleHeight($(this.ref as HTMLDivElement));

        this.setState({
            screenWidth: width,
            screenHeight: height,
        });
    }

    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        this.setState({ media: nextProps.model?.medias ? nextProps.model : { medias: nextProps.model } });
    }

    render() {
        return this.props.onboardingPreview ? (
            <div ref={(ref) => (this.ref = ref)}>
                {this.props.model?.type === 'story' ? (
                    <StoryPreview
                        screenWidth={this.state.screenWidth}
                        screenHeight={this.state.screenHeight}
                        content={this.state.media}
                    />
                ) : (
                    <PostPreview
                        samplePostData={this.props.samplePostData}
                        justContent={true}
                        content={this.state.media}
                    />
                )}
            </div>
        ) : (
            <div className="device-wrapper" style={{ width: 250 }}>
                <div className="device" data-device="iPhone7" data-orientation="portrait" data-color="black">
                    <div className="screen preview-screen" style={{ overflow: 'none' }} ref={(ref) => (this.ref = ref)}>
                        {this.props.model?.type === 'story' ? (
                            <StoryPreview
                                screenWidth={this.state.screenWidth}
                                screenHeight={this.state.screenHeight}
                                content={this.state.media}
                            />
                        ) : (
                            <PostPreview
                                samplePostData={this.props.samplePostData}
                                content={this.state.media}
                                postData={this.props?.postData}
                            />
                        )}
                    </div>
                    <div className="button"></div>
                </div>
            </div>
        );
    }
}
