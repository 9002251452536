import React, { Component } from "react";
import Swal from "sweetalert2";

interface State {
  verificationCode: string[];
}

interface Props {
  onValidationComplete: () => void;
  onValidationInComplete: () => void;
  onVerificationChange: (code: string) => void;
}
export default class ValidationCodeInput extends Component<Props, State> {
  private _buttonRef: any;

  state: State;

  constructor(props: any) {
    super(props);
    this.state = {
      verificationCode: ["", "", "", "", ""],
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.pastFromClipboard = this.pastFromClipboard.bind(this);
  }
  componentDidMount() {
    document.getElementById("VCI_0").focus();
    document.addEventListener("paste", this.pastFromClipboard);
  }

  componentWillUnmount() {
    document.removeEventListener("paste", this.pastFromClipboard);
  }

  async pastFromClipboard(event: any) {
    if (event.clipboardData.getData("text").length === 5) {
      let code = event.clipboardData.getData("text").toString();

      for (let index = 0; index < 5; index++) {
        if (code.charCodeAt(index) < 47 && code.charCodeAt(index) > 57) {
          await Swal.fire({
            type: "error",
            title: "",
            text: "Please paste a valid five digit code to verify your email",
          });
          return;
        }
      }

      this.setState({
        verificationCode: event.clipboardData.getData("text").split(""),
      });
    }
  }

  onInputChange(event: React.KeyboardEvent<HTMLInputElement>) {
    event.preventDefault();
    let index = parseInt(
      event.currentTarget.id[event.currentTarget.id.length - 1]
    );

    if (event.key.charCodeAt(0) === 66) {
      let cc = this.state.verificationCode;

      if (index > 0) {
        if (cc[index].length === 0) {
          document.getElementById(`VCI_${index - 1}`).focus();
        }
      }

      cc[index] = "";

      this.setState({ verificationCode: cc });
    }

    if (
      (event.key.charCodeAt(0) >= 48 && event.key.charCodeAt(0) <= 57) ||
      (event.key.charCodeAt(0) >= 96 && event.key.charCodeAt(0) <= 105)
    ) {
      let cc = this.state.verificationCode;
      cc[index] = event.key;
      if (index < 4) {
        document.getElementById(`VCI_${index + 1}`).focus();
      }

      this.setState({ verificationCode: cc });
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (this.state.verificationCode.join("").length === 5) {
        this.props.onValidationComplete();
        this.props.onVerificationChange(this.state.verificationCode.join(""));
      } else {
        this.props.onValidationInComplete();
        this.props.onVerificationChange(this.state.verificationCode.join(""));
      }
    }, 100);
  }

  render() {
    return (
      <>
        <div className="verificationCodeForm__input">
          {this.state.verificationCode.map((code: string, index: number) => (
            <input
              key={`inoput_${index}`}
              type="tel"
              value={code}
              maxLength={1}
              size={1}
              min={0}
              max={9}
              id={`VCI_${index}`}
              onKeyDown={this.onInputChange}
            />
          ))}
        </div>
      </>
    );
  }
}
