import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";

import InfluencerCard from "./../explorer/InfluencerCard";
import InfluencerListItem from "../components/influencerListItem";
import EmptyState from "../components/EmptyState";
import { IInfluencerModel } from "../../../../store/models/InfluencerModel";
import classNames from "classnames";
import { observer } from "mobx-react";
import RootStore from "../../../../store/RootStore";

interface InfluencersListProps extends RouteComponentProps {
  influencers: IInfluencerModel[];
  loading: boolean;
  onReachEnd(): void;
  moreToGo: boolean;
  listView: boolean;
  adId: number | string | null;
  toggleAdSction?: () => void;
}
/**
 *
 * @param props , { influencers, loading, onReachEnd, moreToGo, listView, adId, toggleAdSction}
 * @returns infinite scroll list of influencer
 */
let InfluencersList: React.FC<InfluencersListProps> = (props) => {
  const { influencers, loading, onReachEnd, moreToGo, listView } = props;

  return (
    <div className="app-row" style={{ margin: 0 }}>
      {influencers.length !== 0 ? (
        <div
          id="scrollableDiv"
          style={{ height: "74vh", width: "100%", overflow: "auto" }}
        >
          {!listView ? (
            <>
              {/* <div id="scrollableDiv" style={{ height: '74vh', width: '100%', overflow: 'auto' }}> */}
              <InfiniteScroll
                className="customSearch cardView"
                dataLength={influencers.length}
                next={onReachEnd}
                children={influencers
                  .filter((influencer) => influencer.identity.id)
                  .map((influencer, index) => {
                    return (
                      <InfluencerCard
                        toggleAdSction={props.toggleAdSction}
                        key={`${influencer.identity.id}`}
                        influencer={influencer}
                      />
                    );
                  })}
                hasMore={moreToGo}
                loader={
                  loading && (
                    <div className="customSearch__spinner">
                      <Spinner />
                    </div>
                  )
                }
                hasChildren={true}
                scrollableTarget="scrollableDiv"
              ></InfiniteScroll>
              {/* </div> */}
            </>
          ) : (
            <>
              <InfiniteScroll
                className="customSearch listView mb-6"
                dataLength={influencers.length}
                next={onReachEnd}
                hasMore={moreToGo}
                loader={
                  loading && (
                    <div className="customSearch__spinner">
                      <Spinner />
                    </div>
                  )
                }
                hasChildren={true}
              >
                <table className="customSearchListViewHeader-wrapper">
                  <thead>
                    <tr
                      className={classNames("customSearchListViewHeader mt-3", {
                        customSearchListViewHeader_selected:
                          RootStore.invitationStore.temporaryList?.length,
                      })}
                    >
                      <th></th>
                      <th>Influencer</th>
                      <th>Followers</th>
                      <th>Quality Score</th>
                      <th>Avg Engage Rate</th>
                      <th>Location</th>
                      <th>Category</th>
                      <th>Est. Price</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{ height: "70vh", width: "100%", overflow: "auto" }}
                  >
                    {influencers
                      .filter((influencer) => influencer.identity.id)
                      .map((influencer, index: number) => {
                        return (
                          <InfluencerListItem
                            toggleAdSction={props.toggleAdSction}
                            adId={props.adId}
                            key={`${influencer.identity.id}`}
                            influencer={influencer}
                          />
                        );
                      })}
                  </tbody>
                </table>
              </InfiniteScroll>
            </>
          )}
        </div>
      ) : loading ? (
        <Spinner color="primary" style={{ margin: "auto", display: "block" }} />
      ) : (
        <EmptyState {...props} />
      )}
    </div>
  );
};

export default observer(InfluencersList);
