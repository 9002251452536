import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import RootStore from "../RootStore";
import { IOfferModel } from "./OfferModel";
import { IIdentityModel } from "./IdentityModel";
import { Moment } from "moment";
import { OfferDetailedStatus } from "../common/AbstractOfferHistory";

export enum MessageType {
  STATUS_UPDATE,
  SIMPLE,
  STORY_OFFER,
  POST_OFFER,
  PUBLISH_JOB,
  NULLIFY,
}

/**
 * this method Message Model
 */
export const MessageModel = types
  .model({
    id: types.string,
    msg: types.string,
    isSent: types.boolean,
    isDelivered: types.boolean,
    isRead: types.boolean,
    isSelf: types.boolean,
    timestamp: types.string,
    attributes: types.maybeNull(types.frozen()),
    offer_id: types.number,
  })
  .actions((self) => ({
    
    /**
   * this method set Sent
   */
    setSent(state: boolean) {
      self.isSent = state;
    },

     /**
     * this method set Delivered
     */
    setDelivered(state: boolean) {
      self.isDelivered = state;
    },

     /**
     * this method set Read
     */
    setRead(state: boolean) {
      self.isRead = state;
    },
  }))
  .views((self) => ({
    isLatestVersion(): boolean {
      if (typeof self.attributes.data !== "undefined") {
        return RootStore.offerStore
          .getExistingModel(self.offer_id)
          .getHistory()
          .isLatestVersion(self.attributes.data.batch);
      }
      // eslint-disable-next-line no-throw-literal
      throw "Can't check version of a simple message";
    },
    isLatestBatch() {
      if (typeof self.attributes.data !== "undefined") {
        return (
          RootStore.offerStore
            .getExistingModel(self.offer_id)
            .getHistory()
            .getMaxBatchId() === parseInt(self.attributes.data.batch)
        );
      }
      // eslint-disable-next-line no-throw-literal
      throw "Can't check batch of a simple message";
    },
    isFirstVersion(): boolean {
      if (typeof self.attributes.data !== "undefined") {
        return self.attributes.data.batch === -1;
      }
      // eslint-disable-next-line no-throw-literal
      throw "Can't check version of a simple message";
    },
    getStatus(): OfferDetailedStatus {
      if (self.attributes.data !== null) {
        let history = RootStore.offerStore
          .getExistingModel(self.offer_id)
          .getHistory();
        return history.getStatusByBatch(self.attributes.data.batch);
      }
      // eslint-disable-next-line no-throw-literal
      throw "can not get status for a simple message";
    },

    /**
    * this method get Change Caption
    */
    getChangeCaption(): string {
      if (self.attributes.data !== null) {
        let history = RootStore.offerStore
          .getExistingModel(self.offer_id)
          .getHistory();
        return history.getChangeCaptionByBatch(self.attributes.data.batch);
      }
      // eslint-disable-next-line no-throw-literal
      throw "can not get status for a simple message";
    },

    /**
    * this method get Status Update Type
    */
    getStatusUpdateType(): OfferDetailedStatus {
      if (self.attributes.data !== null) {
        let history = RootStore.offerStore
          .getExistingModel(self.offer_id)
          .getHistory();
        return history.getStatusUpdateType(self.attributes.data.batch);
      }
      // eslint-disable-next-line no-throw-literal
      throw "can not get status for a simple message";
    },

    /**
    * this method get Action
    */
    getAction(): "discarded" | "rejected" {
      if (typeof self.attributes.data !== "undefined") {
        return RootStore.offerStore
          .getExistingModel(self.offer_id)
          .getHistory()
          .getRejectionTypeByBatch(self.attributes.data.batch);
      }
      // eslint-disable-next-line no-throw-literal
      throw "Can't run getAction operation a simple message";
    },

    /**
    * this method get Moment
    */
    getMoment(): Moment {
      if (typeof self.attributes.data !== "undefined") {
        return RootStore.offerStore
          .getExistingModel(self.offer_id)
          .getHistory()
          .getMomentByBatch(self.attributes.data.batch);
      }
      // eslint-disable-next-line no-throw-literal
      throw "Can't run getMomentByBatch operation a simple message";
    },

    /**
    * this method get Reactor
    */
    getReactor(): IIdentityModel {
      if (typeof self.attributes.data !== "undefined") {
        return RootStore.offerStore
          .getExistingModel(self.offer_id)
          .getHistory()
          .getReactorByBatch(self.attributes.data.batch);
      }
      // eslint-disable-next-line no-throw-literal
      throw "Can't run getReactorActor operation a simple message";
    },

    /**
    * this method get Type
    */
    getType(): MessageType {
      if (typeof self.attributes.type !== "undefined") {
        if (self.attributes.type.startsWith("publish-job")) {
          return MessageType.PUBLISH_JOB;
        } else if (
          self.attributes.type.startsWith("offer:cancel") ||
          self.attributes.type.startsWith("offer:refund") ||
          self.attributes.type.startsWith("offer:force") ||
          self.attributes.type.startsWith("offer:late")
        ) {
          return MessageType.NULLIFY;
        } else {
          if (
            RootStore.offerStore
              .getExistingModel(self.offer_id)
              .getHistory()
              .isStatusUpdate(self.attributes.data.batch, "getType")
          ) {
            // console.log("status update");
            // console.log(
            //   RootStore.offerStore
            //     .getExistingModel(self.offer_id)
            //     .getHistory()
            //     .getBatchFromHistory(self.attributes.data.batch, "oops")
            // );
            // console.log(self.attributes.data.batch);
            // console.log(RootStore.offerStore.getExistingModel(self.offer_id).getHistory().history);
            // console.log(self);
            return MessageType.STATUS_UPDATE;
          }
          return RootStore.offerStore
            .getExistingModel(self.offer_id)
            .getHistory()
            .getOfferByBatch(self.attributes.data.batch).content_id.type ===
            "post"
            ? MessageType.POST_OFFER
            : MessageType.STORY_OFFER;
        }
      }
      return MessageType.SIMPLE;
    },

    /**
    * this method get Offer
    */
    getOffer(): IOfferModel {
      if (typeof self.attributes.data !== "undefined") {
        return RootStore.offerStore
          .getExistingModel(self.offer_id)
          .getHistory()
          .getOfferByBatch(self.attributes.data.batch);
      }
      // eslint-disable-next-line no-throw-literal
      throw "Can't make snapshot out of a simple message";
    },

    /**
    * this method get Identity
    */
    getIdentity(): IIdentityModel {
      if (self.isSelf) {
        if (
          RootStore.offerStore.getExistingModel(self.offer_id).getIdentity()
            .user_id === RootStore.users.currentUser.id
        ) {
          return RootStore.offerStore
            .getExistingModel(self.offer_id)
            .getIdentity();
        } else {
          return RootStore.offerStore
            .getExistingModel(self.offer_id)
            .getTargetIdentity();
        }
      } else {
        if (
          RootStore.offerStore.getExistingModel(self.offer_id).getIdentity()
            .user_id !== RootStore.users.currentUser.id
        ) {
          return RootStore.offerStore
            .getExistingModel(self.offer_id)
            .getIdentity();
        } else {
          return RootStore.offerStore
            .getExistingModel(self.offer_id)
            .getTargetIdentity();
        }
      }
    },

    /**
    * this method get Main Offer
    */
    getMainOffer(): IOfferModel {
      return RootStore.offerStore.getExistingModel(self.offer_id);
    },
    isFresh(): boolean {
      if (typeof self.attributes.data !== "undefined") {
        return (
          parseInt(self.attributes.data.batch) >=
          RootStore.offerStore
            .getExistingModel(self.offer_id)
            .getHistory()
            .getLatestVersionNumber()
        );
      }
      // eslint-disable-next-line no-throw-literal
      throw "Can't run isFresh operation a simple message";
    },
  }))
  .views((self) => ({

    /**
    * this method offer Buttons Visible
    */
    offerButtonsVisible(): boolean {
      return self.isLatestVersion() && self.getMainOffer().status !== "paid";
    },
  }));

export interface IMessageModel extends Instance<typeof MessageModel> {}

export interface IMessageModelSnapshotIn
  extends SnapshotIn<typeof MessageModel> {}

export interface IMessageModelSnapshotOut
  extends SnapshotOut<typeof MessageModel> {}
