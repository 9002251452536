import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "reactstrap";

import { InvoicesIcon } from "../../../../assets/icons";

interface Props extends RouteComponentProps {
  noCards?: boolean;
}

function EmptyState(props: Props) {
  return (
    <div className="emptyStateContainer">
      <InvoicesIcon />
      <h2>You have not made any deals yet!</h2>
      {props.noCards ? (
        <p>
          Once you make a deal with an influencer, transactions will show up
          here. Also, in order to make the payment process faster and easier,
          you can add your credit card and/or charge your wallet in advance.
        </p>
      ) : (
        <p>
          Once you make a deal with an influencer, transactions will show up
          here.
        </p>
      )}
      <div className="emptyStateContainer__buttons">
        <Button
          color="primary"
          onClick={() => props.history.push("/app/influencer-explorer")}
        >
          Explore Influencers
        </Button>
        {props.noCards && (
          <Button
            color="danger"
            onClick={() =>
              props.history.push("/app/account/payment/add-new-card")
            }
          >
            {" "}
            Add A Credit Card
          </Button>
        )}
      </div>
    </div>
  );
}

EmptyState.defaultProps = {
  noCards: true,
};

export default EmptyState;
