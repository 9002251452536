import React from 'react';
import numbro from 'numbro';
// @ts-ignore
import ReactTooltip from 'react-tooltip';

import {
    LikeIcon,
    CommentsIcon,
    // MediaValuesIcon,
    // MediaFeedIcon,
    // ERIcon,
    // SimilarERIcon,
    LikeFollowersIcon,
    CommentFollowersIcon,
    QuestionMarkIcon,
} from '../../../../assets/icons';
import { IInfluencerModel } from '../../../../store/models/InfluencerModel';
import { minifyNum } from '../../../../lib/Utils';

interface Props {
    influencer: IInfluencerModel;
}
/**
 * 
 * @param props , { influencer } influencer informations
 * {
 *  average_likes_per_post, average_comments_per_post, likes_per_followers 
 *  comments_per_followers
 * }
 * @returns two box in influencer details page
 */
export default function Insights(props: Props) {
    const { influencer } = props;

    return (
        <div className="influencerProfile__details_insights">
            <h1>Influencer insights</h1>
            <div className="influencerProfile__details_insights_cards">
                <div className="influencerProfile__details_insights_card">
                    <div>
                        <LikeIcon />
                        <p>Avg Likes Per Post</p>
                        <span>
                            {influencer.identity.average_likes_per_post
                                ? minifyNum(influencer.identity.average_likes_per_post) //nFormatter(influencer.identity.average_likes_per_post, 1)
                                : '...'}
                        </span>
                    </div>
                    <div>
                        <CommentsIcon />
                        <p>Avg Comments Per Post</p>
                        <span>
                            {influencer.identity.average_comments_per_post
                                ? minifyNum(influencer.identity.average_comments_per_post)
                                : '...'}
                        </span>
                    </div>
                </div>
                {/* <div className="influencerProfile__details_insights_card">
          <div>
            <MediaValuesIcon />
            <p>Media Value Per Story</p>
            <span>$283</span>
          </div>
          <div>
            <MediaFeedIcon />
            <p>Media Value Per Feed</p>
            <span>$983</span>
          </div>
        </div> */}

                {/* <div className="influencerProfile__details_insights_card">
          <div>
            <ERIcon />
            <p>Engagement Rate</p>
            <span>
              {influencer.identity.engagement_rate !== 0
                ? numbro(influencer.identity.engagement_rate).format({
                    output: "percent",
                    mantissa: 2,
                    optionalMantissa: true,
                  })
                : "N/A"}
            </span>
          </div>
          <div>
            <SimilarERIcon />
            <p>Normalized Engagement Rate</p>
            <span>
              {numbro(influencer.identity.engagement_rate_t_score / 100).format({
                output: "percent",
                mantissa: 2,
                optionalMantissa: true,
              })}
            </span>
          </div>
        </div> */}

                <div className="influencerProfile__details_insights_card">
                    <div>
                        <LikeFollowersIcon />
                        <p>
                            Like / Followers Ratio <QuestionMarkIcon data-tip data-for="likePerFollower" />
                        </p>
                        <ReactTooltip id="likePerFollower" type="dark" effect="solid">
                            <h1>Like / Follower Ratio:</h1>
                            <p>
                                This metric is based on the percentage of likes on average in relation to the number of
                                followers..
                            </p>
                        </ReactTooltip>
                        <span>
                            {influencer.identity.likes_per_followers
                                ? numbro(influencer.identity.likes_per_followers / 100).format({
                                      output: 'percent',
                                      mantissa: 2,
                                      optionalMantissa: true,
                                  })
                                : 'N/A'}
                        </span>
                    </div>
                    <div>
                        <CommentFollowersIcon />
                        <p>
                            Comment / Followers Ratio
                            <QuestionMarkIcon data-tip data-for="commentPerFollower" />
                        </p>
                        <ReactTooltip id="commentPerFollower" type="dark" effect="solid">
                            <h1>Comment / Followers Ratio:</h1>
                            <p>
                                This metric is based on the percentage of Comments on average in relation to the number
                                of followers.
                            </p>
                        </ReactTooltip>
                        <span>
                            {influencer.identity.comments_per_followers
                                ? numbro(influencer.identity.comments_per_followers / 100).format({
                                      output: 'percent',
                                      mantissa: 2,
                                      optionalMantissa: true,
                                  })
                                : 'N/A'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
