import React, { Component } from "react";
import { Form, Formik, Field } from "formik";
import { FormGroup, Label } from "reactstrap";
import { Button } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import numbro from "numbro";
import Cleave from "cleave.js/react";
import CardValidator from "card-validator";
import { getIdentifier } from "mobx-state-tree";

import {
  MasterCardIcon,
  TrustPayIcon,
  VisaIcon,
} from "../../../../assets/icons";
import GlideComponent from "../../../../template/components/carousel/GlideComponent";
import {
  Colxx,
  Separator,
} from "../../../../template/components/common/CustomBootstrap";
import { FormikReactSelect, BreadCrumb } from "../../../common-components";
import RootStore from "../../../../store/RootStore";
import CreditCard from "./CreditCard";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import { NotificationManager } from "../../../../template/components/common/react-notifications";
import { IPaymentMethodModel } from "../../../../store/models/PaymentMethodModel";

const ValueSchema = Yup.object().shape({
  amount: Yup.number().min(1, "The value must be greater than zero"),
  payment_method: Yup.object().required(),
  number: Yup.string().when("payment_method", (method: any, schema: any) => {
    if (method.value === "new") {
      return schema.required("The card's number is required!");
    }
  }),
  cvc: Yup.string().when("payment_method", (method: any, schema: any) => {
    if (method.value === "new") {
      return schema
        .required("CVC is required.")
        .min(3, "Must be more than 2 digits");
    }
  }),
  exp_month: Yup.string().when("payment_method", (method: any, schema: any) => {
    if (method.value === "new") {
      return schema
        .required("The card's expiry month is required!")
        .min(1, "The card's expiry month is not valid.")
        .max(12, "The card's expiry month is not valid.");
    }
  }),
  exp_year: Yup.number().when("payment_method", (method: any, schema: any) => {
    if (method.value === "new") {
      return schema
        .required("The card's expiry year is required!")
        .min(
          new Date().getFullYear() - 2000,
          "The card's expiry year is not valid."
        );
    }
  }),
});

export const CustomIndicator = (props: any) => {
  const { children, isSelected, onFocus } = props;
  let cardBrand = null;
  if (props.data.value !== "new" && props.data.value !== null) {
    cardBrand = RootStore.users.currentUser.wallet.getPaymentMethodById(
      props.data.value
    ).data.brand;
  }
  return (
    <div
      className="paymentCustomOption"
      onFocus={onFocus}
      onClick={(event: any) => props.selectOption(props.data, event)}
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {cardBrand === "Visa" ? (
        <VisaIcon />
      ) : cardBrand === "MasterCard" ? (
        <MasterCardIcon />
      ) : null}
      {children}
    </div>
  );
};

interface Props extends RouteComponentProps {}

interface State {
  amount: number;
  required: number | null;
}

export default class AddFund extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      amount: 300,
      required: null,
    };
  }

  componentDidMount(): void {
    RootStore.users.currentUser.wallet
      .fetchRequiredFunds()
      .then((result: any) => {
        this.setState({
          required: result,
        });
      });
  }

  render() {
    return (
      <>
        <BreadCrumb heading="Add Funds" {...this.props} />
        <Separator className="mb-4 mt-3" />
        <div className="addFund">
          <div
            className="addFund__addFund"
            style={{
              position: "relative",
            }}
          >
            <h3>Add Funds to Your Wallet</h3>
            <p>
              Add funds to your wallet to use them to close deals with
              influencers. You can always request the funds to be refunded.
            </p>

            <Formik
              initialValues={{
                amount: 300,
                payment_method:
                  RootStore.users.currentUser.wallet
                    .default_payment_method_id === null
                    ? {
                        value: "new",
                        label: "New Credit Card",
                      }
                    : {
                        value: getIdentifier(
                          RootStore.users.currentUser.wallet
                            .default_payment_method_id
                        ),
                        label:
                          "**** **** **** " +
                          RootStore.users.currentUser.wallet
                            .default_payment_method_id.data.last4,
                      },
                number: "",
                cvc: "",
                exp_month: "",
                exp_year: "",
              }}
              validationSchema={ValueSchema}
              onSubmit={async (values, formikHelpers) => {
                formikHelpers.setSubmitting(true);

                if (values.payment_method.value === "new") {
                  values.number = values.number
                    .split(" ")
                    .join("")
                    .split("-")
                    .join("");
                  // @ts-ignore
                  values.exp_year = parseInt("20" + values.exp_year);
                  // @ts-ignore
                  values.exp_month = parseInt(values.exp_month);

                  if (CardValidator.number(values.number).isValid) {
                    formikHelpers.setSubmitting(true);
                    try {
                      await RootStore.users.currentUser.wallet.chargeAccount(
                        // @ts-ignore
                        parseFloat(values.amount),
                        {
                          number: parseFloat(values.number),
                          cvc: values.cvc,
                          exp_month: values.exp_month,
                          exp_year: values.exp_year,
                        }
                      );
                      formikHelpers.setSubmitting(false);
                      NotificationManager.success(
                        "Done",
                        `Card added and wallet was successfully charged !`,
                        3000,
                        null,
                        null,
                        "filled"
                      );
                      this.props.history.push("/app/account/payment");
                    } catch (e) {
                      handleError(e);
                      formikHelpers.setSubmitting(false);
                      if (e.response.status === 500) {
                        ajaxErrorAlert(
                          `Charge failed!, ${e.response.data.message}`
                        );
                      }

                      ajaxErrorAlert("Charge failed!");
                    }
                  } else {
                    ajaxErrorAlert(
                      "Invalid credit card number! Please check the credit card number that you entered.",
                      "We couldn't place the charge on your card."
                    );
                  }
                } else {
                  formikHelpers.setSubmitting(true);
                  try {
                    await RootStore.users.currentUser.wallet.chargeAccount(
                      // @ts-ignore
                      parseFloat(values.amount),
                      values.payment_method.value
                    );
                    NotificationManager.success(
                      "Done",
                      "Your wallet has been successfully charged !",
                      3000,
                      null,
                      null,
                      "filled"
                    );
                    formikHelpers.setSubmitting(false);
                    this.props.history.push("/app/account/payment");
                  } catch (e) {
                    handleError(e);
                    formikHelpers.setSubmitting(false);
                    ajaxErrorAlert(`Charge failed! ${e.response.data.message}`);
                  }
                }

                formikHelpers.setSubmitting(false);
              }}
            >
              {({
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form
                  className="av-tooltip tooltip-label-right panel-form w-100"
                  onSubmit={handleSubmit}
                >
                  <FormGroup className="error-l-100 w-100">
                    <Label className="position-relative">
                      Select an amount:
                      {errors.amount && touched.amount && (
                        <div className="invalidFeedback">{errors.amount}</div>
                      )}
                    </Label>
                    <ul>
                      <li
                        onClick={() => {
                          setFieldValue("amount", 50);
                          this.setState((prevState) => ({ amount: 50 }));
                        }}
                        className={this.state.amount === 50 ? "selected" : ""}
                      >
                        $50
                      </li>
                      <li
                        onClick={() => {
                          setFieldValue("amount", 150);
                          this.setState((prevState) => ({ amount: 150 }));
                        }}
                        className={this.state.amount === 150 && "selected"}
                      >
                        $150
                      </li>
                      <li
                        onClick={() => {
                          setFieldValue("amount", 300);
                          this.setState((prevState) => ({ amount: 300 }));
                        }}
                        className={this.state.amount === 300 && "selected"}
                      >
                        $300
                      </li>
                      <li>
                        <Field
                          disabled={false}
                          className="form-control"
                          name="amount"
                          type="number"
                          onChange={(event: any) => {
                            setFieldValue("amount", event.target.value);
                            this.setState({
                              amount: parseFloat(event.target.value),
                            });
                          }}
                          value={values.amount}
                          placeholder="Enter a value"
                        />
                      </li>
                    </ul>
                  </FormGroup>
                  <FormGroup className="error-l-100 w-100">
                    <Label>Select Credit Card:</Label>
                    <FormikReactSelect
                      formatGroupLabel={(data: any) => (
                        <div style={{ fontSize: 16 }}>
                          <h4>{data.label}</h4>
                        </div>
                      )}
                      name="payment_method"
                      components={{
                        // DropdownIndicator: CustomIndicator,
                        Option: CustomIndicator,
                      }}
                      options={[
                        ...RootStore.users.currentUser.wallet.methods.map(
                          (method: IPaymentMethodModel) => {
                            return {
                              value: method.id,
                              label: "**** **** **** " + method.data.last4,
                            };
                          }
                        ),
                        { value: "new", label: "New Credit Card" },
                      ]}
                      value={values.payment_method}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>

                  {values.payment_method.value === "new" ? (
                    <div style={{ float: "left", width: "100%" }}>
                      <FormGroup className="error-l-100 mt-4">
                        <Label className="position-relative">
                          Card Number:
                          {errors.number && touched.number && (
                            <div className="invalidFeedback">
                              {errors.number}
                            </div>
                          )}
                        </Label>
                        <Cleave
                          className="form-control"
                          name="number"
                          placeholder="Enter your credit card number"
                          options={{ creditCard: true, delimiter: " - " }}
                          onFocus={(ev) => {
                            setFieldTouched("number");
                          }}
                          onChange={(ev) => {
                            setFieldValue(
                              "number",
                              ev.target.value.replace("-", "").replace(" ", "")
                            );
                          }}
                        />
                      </FormGroup>
                      <div className="d-flex justify-content-between align-items-center w-50 mt-4">
                        <FormGroup className="error-l-100 w-60">
                          <Label className="position-relative">
                            Exp Date:
                            {errors.exp_month && touched.exp_month && (
                              <div className="invalidFeedback">
                                {errors.exp_month}
                              </div>
                            )}
                            {errors.exp_year && touched.exp_year && (
                              <div className="invalidFeedback">
                                {errors.exp_year}
                              </div>
                            )}
                          </Label>
                          <div className="d-flex justify-content-between">
                            <Cleave
                              className="form-control w-45"
                              name="exp_month"
                              placeholder="MM"
                              options={{ date: true, datePattern: ["m"] }}
                              onFocus={(ev) => {
                                setFieldTouched("exp_month");
                              }}
                              onChange={(ev) => {
                                setFieldValue("exp_month", ev.target.value);
                              }}
                            />

                            <Cleave
                              className="form-control w-45"
                              name="exp_year"
                              placeholder="YY"
                              options={{ date: true, datePattern: ["y"] }}
                              onFocus={(ev) => {
                                setFieldTouched("exp_year");
                              }}
                              onChange={(ev) => {
                                setFieldValue("exp_year", ev.target.value);
                              }}
                            />
                          </div>
                        </FormGroup>

                        <FormGroup className="error-l-100 w-30">
                          <Label className="position-relative">
                            CVC:
                            {errors.cvc && touched.cvc && (
                              <div className="invalidFeedback">
                                {errors.cvc}
                              </div>
                            )}
                          </Label>
                          <Field
                            className="form-control"
                            name="cvc"
                            placeholder="CVC"
                            maxLength={4}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  ) : null}

                  <Button color="success">
                    <span>
                      Deposit{" "}
                      {this.state.amount !== 0
                        ? numbro(this.state.amount)
                            .formatCurrency({
                              mantissa: 2,
                              thousandSeparated: true,
                              optionalMantissa: true,
                            })
                            .toUpperCase()
                        : 0}
                    </span>
                  </Button>
                </Form>
              )}
            </Formik>
            <div
              style={{
                position:
                  document.body.clientWidth > 600 ? "absolute" : "static",
                bottom: 0,
                right: 0,
                margin: document.body.clientWidth > 600 ? "20px" : "10px 0",
              }}
            >
              <TrustPayIcon /> Secured by <b>TrustPay</b>
            </div>
          </div>
          <div className="addFund__addWallet">
            <div className="addFund__addWallet_currentBalance">
              <p className="mb-0">
                Your current wallet balance <br />
                <b>
                  {numbro(RootStore.users.currentUser.wallet.credits)
                    .formatCurrency({
                      mantissa: 2,
                      thousandSeparated: true,
                      optionalMantissa: true,
                    })
                    .toUpperCase()}
                </b>
              </p>
            </div>
            <Colxx className="pl-0 pr-0 mb-0 removeArrow addFund__addWallet_slider">
              {RootStore.users.currentUser.wallet.methods.length > 0 ? (
                <GlideComponent
                  settings={{
                    gap: 5,
                    perView: 1,
                    type: "carousel",
                    classes: {
                      disabledArrow: true,
                    },
                  }}
                >
                  {RootStore.users.currentUser.wallet.methods.map(
                    (method: IPaymentMethodModel) => (
                      <div className="creditCardSlide">
                        <CreditCard model={method} />
                      </div>
                    )
                  )}
                </GlideComponent>
              ) : null}
            </Colxx>
          </div>
        </div>
      </>
    );
  }
}
