import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import Integrations from "./integrations/Integrations";
import NewIntegrationPage from "./integrations/NewIntegrationPage";
import EditAccount from "./edit-account/EditAccount";
import ChangePassword from "./change-password/ChangePassword";
import Invoices from "./wallet";
import AddNewCard from "./wallet/AddNewCard";
import CardsList from "./wallet/CardsList";
import EditIntegration from "./integrations/EditIntegration";
import AddFund from "./wallet/AddFund";
// import Refund from "./wallet/Refund";

const SecondMenu = ({ match }: RouteComponentProps) => (
  
  <Switch>
    <Redirect exact from={`${match.url}/`} to="/app/account/integrations" />

    {/**
     * Integrations
     */}
    <Route
      path={`${match.url}/integrations/new-integration`}
      component={NewIntegrationPage}
    />
    <Route path={`${match.url}/integrations/:id`} component={EditIntegration} />
    <Route path={`${match.url}/integrations`} component={Integrations} />

    {/**
     * Account
     */}
    <Route path={`${match.url}/change-password`} component={ChangePassword} />
    <Route path={`${match.url}/edit-account`} component={EditAccount} />

    {/**
     * Invoices
     */}
    <Route
      path={`${match.url}/payment/add-new-card`}
      exact={true}
      component={AddNewCard}
    />
    <Route
      path={`${match.url}/payment/cards-list`}
      exact={true}
      component={CardsList}
    />
    <Route
      path={`${match.url}/payment/add-funds`}
      exact={true}
      component={AddFund}
    />
    {/* <Route path={`${match.url}/payment/refund`} exact={true} component={Refund} /> */}
    <Route path={`${match.url}/payment`} exact={true} component={Invoices} />
    <Redirect to="/error" />
  </Switch>
);

export default SecondMenu;
