import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
 * this method Country Model
 */
export const CountryModel = types.model({
  id: types.identifier,
  code: types.string,
  name: types.string,
});

export interface ICountryModel extends Instance<typeof CountryModel> {}
export interface ICountryModelSnapshotIn
  extends SnapshotIn<typeof CountryModel> {}
export interface ICountryModelSnapshotOut
  extends SnapshotOut<typeof CountryModel> {}
