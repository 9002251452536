import React, { useState } from 'react';
import { Table, Spinner, Button } from 'reactstrap';
import HistoryIcon from '../../../../../../assets/icons/TimeLeft.svg';
import WalletReportColProfile from './parts/profile';
import ModalPayoutPay from './parts/modals/pay/index';
import ModalPayoutTransactions from './parts/modals/payoutTransactions';
// import ModalPayoutTransactions from "./parts/modals/transactions";
import { ajaxErrorAlert } from '../../../../../../lib/Utils';
import { EditIcon, InformationIcon, SuccessIcon } from './../../../../../../assets/icons';
import ReactTooltip from 'react-tooltip';
import ModalPayoutWithdtrawalRequest from './parts/modals/payoutWithdrawalHistory';
import PaypalEmail from './parts/modals/paypalEmail';
import classes from './style.module.scss';

interface Props {
    users: any;
    loading: boolean;
    fetchData: () => void;
}

const WalletReportPayout = (props: Props) => {
    const [isOpen, setisOpen] = useState(false);
    const [ModalPaymentEmail, setModalPaymentEmail] = useState(false);
    const [modalPayoutTransactionsModal, setModalPayoutTransactionsModal] = useState(false);
    const [modalWithdrawalRequestIsopen, setModalWithdrawalRequestIsopen] = useState(false);
    const [dataPay, setdataPay] = useState({
        data: null,
        password_payment: null,
    });

    const modalPayoutTransactionsToggle = () => {
        setModalPayoutTransactionsModal(false);
    };

    const toggleWithdrawalRequestModal = () => {
        setModalWithdrawalRequestIsopen((prev) => !prev);
    };

    return (
        <div>
            <Table striped>
                <thead>
                    <tr className="relative">
                        <th>#</th>
                        <th>Email - Name</th>
                        <th>Paypal Email</th>
                        <th>
                            <div style={{ width: 100 }}>
                                Total Earn &nbsp;
                                <InformationIcon width={14} data-tip data-for="Total Earn" />
                                <ReactTooltip id="Total Earn" type="dark" effect="solid">
                                    <p style={{ width: 200 }}>
                                        The total money that the influencer earned within the platform, from the
                                        registration date until now.
                                    </p>
                                </ReactTooltip>
                            </div>
                        </th>
                        <th>
                            <div style={{ width: 100 }}>
                                Total Paid &nbsp;
                                <InformationIcon width={14} data-tip data-for="Total Paid" />
                                <ReactTooltip id="Total Paid" type="dark" effect="solid">
                                    <p style={{ width: 200 }}>
                                        The total money that Ainfluencer paid to the influencers.
                                    </p>
                                </ReactTooltip>
                            </div>
                        </th>
                        <th>
                            <div style={{ width: 100 }}>
                                Total Wallet &nbsp;
                                <InformationIcon width={14} data-tip data-for="Total Wallet" />
                                <ReactTooltip id="Total Wallet" type="dark" effect="solid">
                                    <p style={{ width: 200 }}>
                                        The total amount that exists in the user wallet, can be withdrawn
                                    </p>
                                </ReactTooltip>
                            </div>
                        </th>
                        <th>
                            <div style={{ width: 120 }}>
                                Total to be Paid &nbsp;
                                <InformationIcon width={14} data-tip data-for="Total to be Paid" />
                                <ReactTooltip id="Total to be Paid" type="dark" effect="solid">
                                    <p style={{ width: 200 }}>
                                        The total money that Ainfluencer has to pay to influencers.
                                    </p>
                                </ReactTooltip>
                            </div>
                        </th>
                        <th>
                            <div style={{ width: 155 }}>
                                Withdrawal Requests &nbsp;
                                <InformationIcon width={14} data-tip data-for="Withdrawal Requests" />
                                <ReactTooltip id="Withdrawal Requests" type="dark" effect="solid">
                                    <p>Count of withdrawal requests by influencers.</p>
                                </ReactTooltip>
                            </div>
                        </th>
                        <th>
                            <div style={{ width: 130 }}>
                                Pending Requests &nbsp;
                                <InformationIcon width={14} data-tip data-for="Pending Requests" />
                                <ReactTooltip id="Pending Requests" type="dark" effect="solid">
                                    <p style={{ width: 200 }}>
                                        Count of withdrawal requests by influencers.(Only those that have pending
                                        status)
                                    </p>
                                </ReactTooltip>
                            </div>
                        </th>
                        <th>
                            <div style={{ width: 120 }}>
                                Total Ainf Fees &nbsp;
                                <InformationIcon width={14} data-tip data-for="Total Ainf Fees" />
                                <ReactTooltip id="Total Ainf Fees" type="dark" effect="solid">
                                    <p style={{ width: 200 }}>
                                        The total fees that Ainfluencer has got from the amounts that brands paid to
                                        this influencer.
                                    </p>
                                </ReactTooltip>
                            </div>
                        </th>
                        {/* <th>
                            <div style={{ width: 150 }}>
                                to Pay as Requested &nbsp;
                                <InformationIcon width={14} data-tip data-for="to Pay as Requested" />
                                <ReactTooltip id="to Pay as Requested" type="dark" effect="solid">
                                    <p>Lorem Ipsum</p>
                                </ReactTooltip>
                            </div>
                        </th> */}
                        <th>
                            <div style={{ width: 240 }}>
                                Future Payments (Pending Deals) &nbsp;
                                <InformationIcon width={14} data-tip data-for="Future Payments (Pending Deals)" />
                                <ReactTooltip id="Future Payments (Pending Deals)" type="dark" effect="solid">
                                    <p style={{ width: 200 }}>
                                        The amount that brands locked for influencers due to deal on offers, and would
                                        be transferred to influencers' wallets systematically when they publish the
                                        brand contents on their Instagram accounts.
                                    </p>
                                </ReactTooltip>
                            </div>
                        </th>
                        <th>
                            <div style={{ width: 100 }}>
                                Future Fees &nbsp;
                                <InformationIcon width={14} data-tip data-for="Future Fees" />
                                <ReactTooltip id="Future Fees" type="dark" effect="solid">
                                    <p style={{ width: 200 }}>
                                        The total amount that would be reduced from brands' wallets when they lock money
                                        to influencers by paying for their offers.
                                    </p>
                                </ReactTooltip>
                            </div>
                        </th>
                        <div className={classes.action_col}>
                            <strong>Action</strong> &nbsp; &nbsp;
                            <strong>History</strong>
                        </div>
                        {/* <th className="text-center">Action</th> */}
                        {/* <th>History</th> */}
                    </tr>
                </thead>

                <tbody>
                    {!props.loading &&
                        props.users &&
                        props.users.map((item: any, index: number) => {
                            return (
                                <tr key={item?.id} className="relative">
                                    <td>{index + 1}</td>
                                    <td>
                                        <WalletReportColProfile
                                            item={item}
                                            label={`${item?.email} (${item?.first_name} ${item?.last_name})`}
                                        />
                                    </td>
                                    <td>
                                        {item?.paypal_email}
                                        <span
                                            className={classes.paypal_email}
                                            onClick={() => {
                                                setdataPay((prev) => ({ ...prev, data: item }));
                                                setModalPaymentEmail(true);
                                            }}
                                        >
                                            <EditIcon className={classes.edit_icon} />
                                        </span>
                                    </td>
                                    <td style={{ width: 100 }}>{item?.earn_money}</td>
                                    <td>{item?.paid_money}</td>
                                    <td>{item?.wallet_money}</td>
                                    <td>{item?.total_to_pay}</td>
                                    <td>
                                        <div style={{ textAlign: 'center' }}>
                                            {item?.withdraw_requests_count}&nbsp;
                                            <img
                                                style={{ width: '20px', cursor: 'pointer' }}
                                                src={HistoryIcon}
                                                alt=""
                                                onClick={() => {
                                                    setdataPay((prev) => ({ ...prev, data: item }));
                                                    toggleWithdrawalRequestModal();
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td>{item?.pending_withdraw_requests_count}</td>
                                    <td>{item?.total_ainfluencer_fees}</td>
                                    {/* <td>{item?.requested_to_pay}</td> */}
                                    <td>{item?.lock_money}</td>
                                    <td style={{ width: 100 }}>{item?.future_fees}</td>
                                    <div className={classes.action_btns}>
                                        <td className="text-center">
                                            {item?.paid_money > 0 && item?.total_to_pay === 0 ? (
                                                <>
                                                    <SuccessIcon style={{ width: '40%' }} />
                                                    <div className="text-success mt-n2">Fully Paid</div>
                                                </>
                                            ) : (
                                                <Button
                                                    disabled={item?.paid_money === 0 && item?.total_to_pay === 0}
                                                    color="success"
                                                    onClick={() => {
                                                        if (item?.paypal_email) {
                                                            setdataPay((prev) => ({ ...prev, data: item }));
                                                            setisOpen(true);
                                                        } else ajaxErrorAlert('Paypal Email does not exist!');
                                                    }}
                                                >
                                                    Pay
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            <img
                                                style={{
                                                    width: '20px',
                                                    cursor: 'pointer',
                                                    marginTop: 11,
                                                }}
                                                src={HistoryIcon}
                                                alt=""
                                                onClick={() => {
                                                    setdataPay((prev) => ({ ...prev, data: item }));
                                                    setModalPayoutTransactionsModal(true);
                                                }}
                                            />
                                        </td>
                                    </div>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
            {props.loading && (
                <div
                    style={{
                        width: '100%',
                        height: 400,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Spinner color="primary" size={'lg'} className="spinner" />
                </div>
            )}
            {isOpen && dataPay?.data && (
                <ModalPayoutPay
                    isOpen={isOpen}
                    toggle={() => setisOpen(false)}
                    dataPayment={dataPay}
                    changePayPass={(password: string) =>
                        setdataPay((prev) => ({ ...prev, password_payment: password }))
                    }
                    fetchData={props.fetchData}
                />
            )}
            {/* {modalPayoutTransactionsModal && dataPay?.data && (
        <ModalPayoutTransactions
          isOpen={modalPayoutTransactionsModal}
          toggle={modalPayoutTransactionsToggle}
          dataPayment={dataPay?.data}
        />
      )} */}
            {modalPayoutTransactionsModal && (
                <ModalPayoutTransactions
                    isOpen={modalPayoutTransactionsModal}
                    toggle={modalPayoutTransactionsToggle}
                    dataPayment={dataPay?.data}
                />
            )}
            {modalWithdrawalRequestIsopen && (
                <ModalPayoutWithdtrawalRequest
                    isOpen={modalWithdrawalRequestIsopen}
                    toggle={toggleWithdrawalRequestModal}
                    dataPayment={dataPay?.data}
                />
            )}
            {ModalPaymentEmail && dataPay.data && (
                <PaypalEmail
                    isOpen={ModalPaymentEmail}
                    toggle={() => setModalPaymentEmail(false)}
                    dataPayment={dataPay}
                    changePayPass={(password: string) =>
                        setdataPay((prev) => ({ ...prev, password_payment: password }))
                    }
                    fetchData={props.fetchData}
                />
            )}
        </div>
    );
};

export default WalletReportPayout;
