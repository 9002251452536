import * as React from "react";
import { Fragment } from "react";
import { Alert, Button, Spinner } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../template/components/common/CustomBootstrap";
import { NavLink, RouteComponentProps } from "react-router-dom";
import RootStore from "../../../../store/RootStore";
import { BreadCrumb } from "../../../common-components";
import IntegrationRow from "./IntegrationRow";
import { IIntegrationModel } from "../../../../store/models/IntegrationModel";
import { observer } from "mobx-react";
import { NoIgAddedIcon } from "../../../../assets/icons";

interface Props extends RouteComponentProps {}

function Integrations(props: Props) {
  let unhealthyIntegrations: IIntegrationModel[] =
    RootStore.integrationStore.getUnhealthyIntegrations();
  let i = 0;
  let unhealthyIntegrationsText: (string | JSX.Element)[] = [];
  for (let integration of RootStore.integrationStore.getUnhealthyIntegrations()) {
    unhealthyIntegrationsText.push(
      <b key={"fig_" + i}>@{integration.username}</b>
    );
    i++;
    if (i !== unhealthyIntegrations.length) {
      unhealthyIntegrationsText.push(", ");
    }
  }

  return (
    <Fragment>
      <div className="text-zero top-right-button-container">
        {RootStore.integrationStore.getMyIntegrations().length !== 0 ? (
          <NavLink
            className={"top-right-button btn btn-primary btn-lg"}
            to={`${props.match.url}/new-integration`}
          >
            Add New Instagram
          </NavLink>
        ) : null}
      </div>
      <BreadCrumb heading="Instagram Accounts" {...props} />
      <Separator className="mb-5" />
      <Colxx xxs="12">
        {RootStore.integrationStore.getUnhealthyIntegrations().length > 0 ? (
          <React.Fragment>
            <Alert color={"danger"} pill={true}>
              <b>Warning!</b> Some of your IG accounts (
              {unhealthyIntegrationsText}) could not be accessed by the
              scheduler. Scheduled posts may fail to publish if you don't fix
              this quickly! Please click on "Troubleshoot" to learn more!
            </Alert>
            <br />
          </React.Fragment>
        ) : null}

        {RootStore.integrationStore.getMyIntegrations().length === 0 ? (
          <div className="emptyStateContainer">
            <NoIgAddedIcon />
            <h2>Your Instagram Account Is Not Connected As Yet!</h2>
            <p>
              In order to use the free post scheduler, you first need to add
              your Instagram account.
            </p>
            <div className="emptyStateContainer__buttons">
              <Button
                color="success"
                onClick={() =>
                  props.history.push(`${props.match.url}/new-integration`)
                }
              >
                {" "}
                Add IG
              </Button>
            </div>
          </div>
        ) : null}

        {RootStore.integrationStore.isBusy ? (
          <div>
            <Spinner color="primary" className="mb-1" />
            <p>Please wait...</p>
          </div>
        ) : (
          RootStore.integrationStore
            .getMyIntegrations()
            .map((integration: IIntegrationModel) => (
              <IntegrationRow
                key={integration.id}
                integration={integration}
                {...props}
              />
            ))
        )}
      </Colxx>
    </Fragment>
  );
}

export default observer(Integrations);
