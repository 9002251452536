import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import Home from "./ContentManagerPage";

export default ({ match }: RouteComponentProps) => (
  <Switch>
    <Route path={`${match.url}/`} component={Home} />
    <Redirect to="/error" />
  </Switch>
);
