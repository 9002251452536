import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import CounterOfferPage from "./CounterOfferPage";
import OfferPage from "./OfferPage";
import OfferListPage from "./OfferListPage";
import PayOfferPage from "./PayOfferPage";

export default ({ match }: RouteComponentProps) => (
  <Switch>
    <Route
      path={`${match.url}/:id(\\d+)/counter`}
      render={(props) => <CounterOfferPage {...props} />}
    />
    <Route
      path={`${match.url}/:id(\\d+)/pay`}
      render={(props) => <PayOfferPage {...props} />}
    />
    <Route
      path={`${match.url}/:id(\\d+)`}
      render={(props) => <OfferPage {...props} />}
    />
    <Route
      path={`${match.url}/`}
      render={(props) => <OfferListPage {...props} />}
    />
  </Switch>
);
