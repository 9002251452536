import React from "react";
import { Modal, Table } from "reactstrap";

export default function ShowDetails(props) {
  return (
    <Modal
      isOpen={props.isOpendetails}
      toggle={props.onClosedetails}
      className="campaignForm"
    >
      <div
        className="campaignForm__form"
        style={{ maxHeight: "600px", overflowY: "auto" }}
      >
        {props.metrics && props.metrics[0] ? (
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>Date/Time</th>
                <th>Likes</th>
                <th>Comments</th>
                <th>Views</th>
              </tr>
            </thead>
            <tbody>
              {props.metrics.map((item: any, index: number) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.created_at}</td>
                  <td>{item.likes}</td>
                  <td>{item.comments}</td>
                  <td>{item.views}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          "No data Available"
        )}
      </div>
    </Modal>
  );
}
