import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { debounce } from "lodash";
import { observer } from "mobx-react";
import Axios, { CancelTokenSource, CancelTokenStatic } from "axios";

import AdSelection from "../components/AdSelection";
import RootStore from "../../../../store/RootStore";
import CancelInvitation from "../components/CancelInvitationWarning";
import SelectedInflucners from "../explorer/SelectedInfluencers";
import { IInfluencerModel } from "../../../../store/models/InfluencerModel";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import {
  SortOrder,
  FilterGroup,
  Pagination,
} from "./../../../../lib/QueryTypes";
import { IAdModel } from "./../../../../store/models/AdModel";

import {
  explorerSearchType,
  CustomSearchTermsType,
} from "../../../../lib/CustomTypes";
import { BreadCrumb } from "../../../common-components";
import {
  TableViewIcon,
  ListViewIcon,
  AddedTickIcon,
} from "../../../../assets/icons";
import _ from "lodash";

import InfluencersList from "./InfluencersList";
import Filters from "./Filters";
import { Separator } from "../../../../template/components/common/CustomBootstrap";
import classNames from "classnames";
import { Row, Col } from "reactstrap";
import DropDownFilter from "./DropDownFilter";
import { filterOptions } from "./filterOptions";
import { NotificationManager } from "../../../../template/components/common/react-notifications";

interface Props
  extends RouteComponentProps<
    {},
    {},
    {
      name?: explorerSearchType;
      value?: any;
      from?: string;
      adId?: any;
      update_filter?: boolean;
    }
  > {}

interface State {
  displayWarning: boolean;
  influencers: IInfluencerModel[];
  searchObj: CustomSearchTermsType;
  pageOffset: number;
  reachedEnd: boolean;
  loading: boolean;
  isSearchSideBarOpen: boolean;
  listView: boolean;
  adSelectionOpen: boolean;
  dataFetched: boolean;
  // sorts: { key: string; direction: SortOrder };
  pagination: Pagination;
  filter_groups: FilterGroup[];
  ads: IAdModel[];
  adId?: any | null;
  autoSave: boolean;
  realInfluencers: boolean;
}

interface Props {
  handleChange: any;
  applyFilters: any;
}
/**
 * @param props, { handleChange sort by handle
 *  , applyFilter apply filter changes}
 */
@observer
export default class CustomSearch extends Component<Props, State> {
  private cancelTokenSource: CancelTokenSource;
  private cancelToken: CancelTokenStatic;

  private filter: any | null = null;

  constructor(props: any) {
    super(props);
    this.filter = React.createRef();
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const ad_id = params.get("ad_id");
    if (ad_id) RootStore.adStore.setAd(parseInt(ad_id));

    this.state = {
      pageOffset: 0,
      displayWarning: false,
      influencers: [],
      reachedEnd: false,
      searchObj: {
        country: "",
        hashtags: [],
        categories: [],
        followers: {
          min: 5,
          max: 1000,
        },
        search: "",
        language: "",
        gender: { label: "All", value: null },
        inviteOnly: undefined,
        sorts: [
          {
            key: "followers",
            direction: SortOrder.DESCENDING,
          },
        ],
      },
      loading: false,
      isSearchSideBarOpen: true,
      listView: false,
      adSelectionOpen: false,
      filter_groups: [],
      dataFetched: false,
      pagination: {
        totalPages: null,
        page: 1,
        pageSize: 10,
      },
      ads: [],
      adId: null,
      autoSave: true,
      realInfluencers: false,
    };

    this.applySearchTerms = debounce(this.applySearchTerms, 750);
    this.applySearchTerms = this.applySearchTerms.bind(this);
  }
  /**
   * when select or unselect influencer it runs
   */
  setDisplayWarning = () => {
    this.setState((prevState) => ({
      displayWarning: !prevState.displayWarning,
    }));
  };
  /**
   * fetch static data
   * check location state and set what type search does
   */
  async componentDidMount() {
    try {
      await RootStore.fetchStaticData();
    } catch (e) {
      handleError(e);
      ajaxErrorAlert("Failed to get static data! please refresh the page...");
    }

    // selected ad
    const selectedAdId = Number(
      localStorage.getItem("selected_ad") || RootStore?.adStore?.selectedAd
    );
    const Ads = await RootStore.adStore.getAllAds();
    const checkExistAd = await Ads.find((item) => +item?.id === +selectedAdId);

    if (selectedAdId && checkExistAd) {
      RootStore.adStore.setAd(selectedAdId);
      this.setState({ adId: selectedAdId });
    }
    // else{
    //     localStorage.removeItem('selected_ad')
    //     localStorage.removeItem('ad_info')
    //     this.props.history.push('/app/influencer-explorer')
    // }

    // if (!this.props.location.state) {
    //     const name = this.props.location?.state?.name
    //     const value = this.props.location?.state?.value
    //     const returnName = (name) =>{
    //         switch (name) {
    //             case explorerSearchType.CATEGORY:
    //                 return 'categories'
    //             case explorerSearchType.LOCATION:
    //                 return 'country'
    //             default:
    //                 return 'hashtags'
    //         }
    //     }
    //     this.setSearchTerms(returnName(name),value);
    //     this.applySearchTerms();
    // }
    if (!this.props.location.state) {
      if (!_.isEmpty(RootStore.discoverInfluencersStore.searchObj)) {
        const searchObj = RootStore.discoverInfluencersStore.searchObj;
        Object.keys(searchObj).forEach((search) => {
          this.setSearchTerms(search, searchObj[search]);
        });
      }
    }
  }
  /**
   *
   * @param prevState
   * listen to change search object for new search
   * listen to change selected ad and show new results
   */
  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      JSON.stringify(prevState.searchObj) !==
      JSON.stringify(this.state.searchObj)
    ) {
      this.setState({
        loading: true,
        influencers: [],
        pageOffset: 0,
      });
      this.applySearchTerms();
    }

    if (!RootStore.adStore.selectedAd) {
      await RootStore.adStore.fetchAll();
      const Ads = RootStore.adStore.getAllAds();
      if (Ads.length === 1) RootStore.adStore.setAd(Ads[0].id);
      //else this.setState({ adSelectionOpen: true });
    }
    // clear selected influencer
    // if(this.state?.adId !== prevState?.adId){
    //     RootStore.invitationStore.unSelect();
    //     RootStore.invitationStore.clearList();
    // }
  }
  /**
   *
   * @param name , type of section
   * @param value , value of sth user wants to change
   * end of it search values changes and user has new results
   */
  setSearchTerms = async (name: string, value: any) => {
    let searchObj = this.state.searchObj;

    if (value) searchObj[name] = value;

    if (!value && (name === "categories" || name === "hashtags"))
      searchObj[name] = [];

    if (
      !value &&
      (name === "country" || name === "language" || name === "search")
    )
      searchObj[name] = "";

    //Store filter at local storage for specific Ad
    // if (RootStore.adStore.selectedAd)
    //     localStorage.setItem('filter_' + RootStore.adStore.selectedAd, JSON.stringify(searchObj));

    RootStore.discoverInfluencersStore.setSearchObj(searchObj);
    await this.setState({ searchObj });
  };
  /**
   *
   * @param searchObj , search values
   * @param dontSave , changes search object in root store or not
   */
  updateSearchObj = async (searchObj, dontSave) => {
    // const selectedAd = Number(localStorage.getItem('selected_ad'))
    // const selectedAdStore = RootStore.adStore.selectedAd || selectedAd
    // if (selectedAdStore && !dontSave)
    //     localStorage.setItem(`filter_${selectedAdStore}`, JSON.stringify(searchObj));

    await RootStore.discoverInfluencersStore.setSearchObj(searchObj);

    this.setState({ searchObj });
  };
  /**
   *
   * @param searchObj , search object values
   * save filter values in local storage
   */
  savefilter = async (searchObj) => {
    const selectedAdId = Number(localStorage.getItem("selected_ad"));
    const selectedAdIdStore = Number(RootStore.adStore?.selectedAd);
    if (selectedAdIdStore || selectedAdId) {
      localStorage.setItem(
        `filter_${selectedAdIdStore || selectedAdId}`,
        JSON.stringify(searchObj)
      );
      NotificationManager.success(
        "Done!",
        "Filter has been saved for selected Ad",
        3000,
        null,
        null,
        "filled"
      );
    } else {
      NotificationManager.info(
        "Notice!",
        "for Save Ad Filter You Must be Select an Ad",
        3000,
        null,
        null,
        "filled"
      );
    }
  };
  /**
   * apply and search changes of filter
   */
  applySearchTerms = async () => {
    const scroller = document.querySelector("#scrollableDiv");
    if (scroller) scroller.scrollTo(0, 0);
    //JSON.stringify(this.state.searchObj).length > 106
    try {
      this.setState({ ...this.state, loading: true });
      let searchObject = {
        ...this.state.searchObj,
        ...(this.state.realInfluencers ? { is_super_admin: true } : {}),
        followers: {
          min: this.state.searchObj.followers.min * 1000,
          max: this.state.searchObj.followers.max * 1000,
        },
      };

      let influencers: IInfluencerModel[] = [];
      this.setState({ pageOffset: 0 });

      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
        this.cancelTokenSource = this.cancelToken.source();
        influencers = (await RootStore.discoverInfluencersStore.influencersCustomSearch(
          {
            ...searchObject,
            ...(this.state.realInfluencers ? { is_super_admin: true } : {}),
            categories: searchObject.categories?.flatMap((cate) => cate.slug),
          },
          this.state.pageOffset,
          this.cancelTokenSource.token
        )) as IInfluencerModel[];
      } else {
        influencers = (await RootStore.discoverInfluencersStore.influencersCustomSearch(
          {
            ...searchObject,
            ...(this.state.realInfluencers ? { is_super_admin: true } : {}),
            categories: searchObject.categories?.map((cate) => cate.slug),
          },
          this.state.pageOffset,
          "elastic"
        )) as IInfluencerModel[];
      }
      await setTimeout(() => {
        if (influencers && influencers.length > 0) {
          this.setState({
            influencers: influencers,
            loading: false,
            reachedEnd: influencers.length < 20,
          });
        } else {
          this.setState({
            influencers: [],
            loading: false,
            reachedEnd: true,
          });
        }
      }, 3000);
    } catch (error) {
      if (!Axios.isCancel(error)) {
        handleError(error);
        ajaxErrorAlert(
          "Failed to get influencers data! Please reload the page!"
        );
      }
    }
    // else {
    //     this.setState((prevState) => ({
    //         pageOffset: 0,
    //         influencers: [],
    //         loading: false,
    //     }));
    // }
  };
  /**
   * method for search real influencer
   */
  // realInfluencerSearch = async () => {
  //     this.setState({ ...this.state, loading: true });
  //     let searchObject = {
  //         is_super_admin: true,
  //         from: 0,
  //     };
  //     let influencers: IInfluencerModel[] = [];
  //     this.setState({ pageOffset: 0 });
  //     influencers = (await RootStore.discoverInfluencersStore.influencersCustomSearch(
  //         {
  //             ...searchObject,
  //             ...(this.state.realInfluencers ? {is_super_admin:true} : {}),
  //         },
  //         this.state.pageOffset,
  //         'elastic',
  //     )) as IInfluencerModel[];

  //     await setTimeout(() => {
  //         if (influencers && influencers.length > 0) {
  //             this.setState({
  //                 influencers: influencers,
  //                 loading: false,
  //                 reachedEnd: influencers.length < 20,
  //             });
  //         } else {
  //             this.setState({
  //                 influencers: [],
  //                 loading: false,
  //                 reachedEnd: true,
  //             });
  //         }
  //     }, 3000);
  // };

  /**
   * when user reached end of page shows more results of influencers
   */
  requestMore = async () => {
    try {
      let influencers = (await RootStore.discoverInfluencersStore.influencersCustomSearch(
        {
          ...this.state.searchObj,
          categories: this.state.searchObj.categories.map((cate) => cate.slug),
          followers: {
            min: this.state.searchObj.followers.min * 1000,
            max: this.state.searchObj.followers.max * 1000,
          },
        },
        this.state.pageOffset,
        "elastic"
      )) as IInfluencerModel[];

      if (influencers.length > 0) {
        this.setState((prevState) => ({
          influencers: this.state.influencers.concat(influencers),
          loading: false,
          reachedEnd: influencers.length < 20 ? true : false,
        }));
      } else {
        this.setState((prevState) => ({
          loading: false,
          reachedEnd: true,
        }));
      }
    } catch (error) {
      handleError(error);
      ajaxErrorAlert("Failed to get influencers data! Please reload the page!");
    }
  };
  /**
   * toggle search menu
   */
  toggleSearchBarOpen = () => {
    this.setState((prevState) => ({
      isSearchSideBarOpen: !prevState.isSearchSideBarOpen,
    }));
  };
  /**
   * submit changes filter user does
   */
  onSubmitForm = (formikValues: any) => {
    this.setSearchTerms("followers", {
      min: 0,
      max: 100,
    });

    this.setSearchTerms("search", formikValues.search || "");

    this.setSearchTerms("hashtags", formikValues.hashtags || []);

    this.setSearchTerms("categories", formikValues.categories || []);

    this.setSearchTerms("country", formikValues.country.value || "");

    this.toggleSearchBarOpen();
    this.applySearchTerms();
  };
  /**
   *
   * @param view , change view of results in two state list | table
   * @returns
   */
  toggleView(view: string) {
    if (view === "list" && this.state.listView) return;
    if (view === "table" && !this.state.listView) return;

    this.setState({ listView: !this.state.listView });
  }
  /**
   * toggle selected all influencer
   */
  toggleAllSelect = () => {
    const { influencers } = this.state;
    const selectedLength = RootStore?.invitationStore?.temporaryList?.length;
    const allInfluencerLength = influencers?.length;
    if (selectedLength < allInfluencerLength) {
      this.state.influencers
        .filter((influencer) => influencer.identity)
        .forEach((influencer) => {
          RootStore.invitationStore.addToList(influencer);
        });
    } else {
      RootStore.invitationStore.unSelect();
      RootStore.invitationStore.clearList();
    }
    // if (!RootStore.invitationStore.isSelected) {
    //     this.state.influencers
    //         .filter((influencer) => influencer.identity)
    //         .forEach((influencer) => {
    //             RootStore.invitationStore.addToList(influencer);
    //         });
    // } else {
    //     RootStore.invitationStore.unSelect();
    //     RootStore.invitationStore.clearList();
    // }
  };
  /**
   * toggle view of ads selection
   */
  skipAd = () => {
    this.setState({ adSelectionOpen: false });
    localStorage.setItem("skip_ad", "1");
  };

  toggleAdSction = () => {
    this.setState((prevState) => {
      return { adSelectionOpen: !prevState.adSelectionOpen };
    });
  };
  /**
   *
   * @param e , value of sort by filter
   */
  handleChange = async (e) => {
    const { value } = e;
    await this.setState({
      filter_groups: value,
      searchObj: {
        ...this.state.searchObj,
        sorts: [
          {
            key: value,
            direction: SortOrder.DESCENDING,
          },
        ],
      },
    });
  };

  updateAd = async (id: number, ad: any) => {
    await localStorage.setItem("control_offer_ad", JSON.stringify(ad));

    await RootStore.adStore.getAdByIdInfo(id);
    await this.setState({ adId: id });
    await localStorage.setItem("selected_ad", `${id}`);
    await RootStore.adStore.setAd(id);

    this.filter.current.selectAdHandler();
    // const savedFilter = JSON.parse(localStorage.getItem(`filter_${id}`))
    // if(savedFilter){
    //     console.log('saved ',savedFilter)
    //     await RootStore.discoverInfluencersStore.setSearchObj(savedFilter);
    //     this.setState({ searchObj:savedFilter });

    // }else{
    //     const searchObj =  {
    //         country: '',
    //         hashtags: [],
    //         categories: [],
    //         followers: {
    //             min: 5,
    //             max: 1000,
    //         },
    //         language: '',
    //         gender: '',
    //         inviteOnly: undefined,
    //         sorts: [
    //             {
    //                 key: 'followers',
    //                 direction: SortOrder.DESCENDING,
    //             },
    //         ],
    //     }

    // }

    // const ad_info = JSON.parse(localStorage.getItem('ad_info'))
    // const limit_country = ad_info?.limit_country?.length > 0 ? ad_info?.limit_country[0] : ''
    // const ad_hashtags = ad_info?.ad_hashtags ? ad_info?.ad_hashtags.match(/#\w+/g).map(item =>item.substring(1))  : []

    // await RootStore.invitationStore.fetchAllInfluencersForExplorerWithAd(limit_country,ad_hashtags)
  };

  _setSearchState = (searchObj) => {
    this.setState({ searchObj });
  };

  closeLoading = () => {
    this.setState({
      loading: false,
    });
  };

  render() {
    const { influencers } = this.state;
    const { adId } = this.state;
    const selectedLength = RootStore?.invitationStore?.temporaryList?.length;
    const allInfluencerLength = influencers?.length;

    return (
      <div className="customSearchList">
        {this.state.adSelectionOpen && (
          <AdSelection
            {...this.props}
            isOpen={this.state.adSelectionOpen}
            updateAd={this.updateAd}
            skipAd={this.skipAd}
            toggleAdPopup={this.toggleAdSction}
          />
        )}
        <BreadCrumb heading="Custom Search" {...this.props} />
        <Separator className="mb-3" />

        <div className="findInfluencers__header mb-3">
          {adId ? (
            <h2 className="mb-3">
              {/* Found {RootStore.invitationStore.totalFoundInfluencers.total}  */}
              Influencers for{" "}
              <span className="mb-2 user-select" onClick={this.toggleAdSction}>
                {RootStore.adStore.ads.find((ad) => ad.id === adId)?.title}
              </span>
            </h2>
          ) : (
            <h2 className="">
              Please select an Ad to invite Influencers:{" "}
              <span className="selectAd" onClick={this.toggleAdSction}>
                {RootStore.adStore.getAllAds().length > 0
                  ? "Select Ad"
                  : "Create your first Ad"}
              </span>
            </h2>
          )}
        </div>

        <Row className="app-row">
          <Col md={{ size: 1 }} className="changeView">
            <div
              className={`${this.state.listView ? "selected" : ""}`}
              onClick={() => this.toggleView("list")}
            >
              <ListViewIcon />
            </div>
            <div
              className={`${!this.state.listView ? "selected" : ""}`}
              onClick={() => this.toggleView("table")}
            >
              <TableViewIcon />{" "}
            </div>
          </Col>
          <Col
            xs="12"
            md={{ size: 3, offset: 8 }}
            className="custom-search__sotr-by-drop-down"
          >
            {filterOptions.map((filterOption, index) => {
              return (
                <DropDownFilter
                  key={index}
                  values={filterOption.options}
                  title={filterOption.label}
                  name={filterOption.name}
                  handleChange={this.handleChange}
                  defaultValue={{ value: "followers", label: "Followers" }}
                />
              );
            })}
          </Col>
        </Row>
        <Row className="app-row">
          {allInfluencerLength > 0 ? (
            <Col
              md="5"
              xs="12"
              onClick={() => {
                this.toggleAllSelect();
                if (!RootStore.adStore.selectedAd) {
                  this.toggleAdSction();
                }
              }}
              className={classNames("customSearchCardViewHeader", {
                customSearchCardViewHeader_selected:
                  selectedLength === allInfluencerLength,
              })}
            >
              <div className="selectCheckBox">
                <AddedTickIcon />
              </div>
              {selectedLength < allInfluencerLength
                ? "Select All "
                : "Unselect All "}
              <span className="text-muted text-small ml-1">
                ({influencers.length} Influencers)
              </span>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <InfluencersList
          influencers={influencers}
          loading={this.state.loading}
          listView={this.state.listView}
          toggleAdSction={this.toggleAdSction}
          adId={RootStore.adStore.selectedAd}
          onReachEnd={() => {
            if (!this.state.reachedEnd) {
              this.setState((prevState) => ({
                pageOffset: prevState.pageOffset + 1,
              }));
              this.requestMore();
            }
          }}
          moreToGo={!this.state.reachedEnd}
          {...this.props}
        />
        {RootStore.invitationStore?.temporaryList?.length > 0 && (
          <SelectedInflucners
            {...this.props}
            onCancel={() => {
              this.setDisplayWarning();
            }}
          />
        )}

        <CancelInvitation
          dialogOpen={this.state.displayWarning}
          onConfirm={() => {
            RootStore.invitationStore.clearList();
            this.setDisplayWarning();
          }}
          onCancel={() => {
            this.setDisplayWarning();
          }}
        />
        <Filters
          ref={this.filter}
          onToggle={this.toggleSearchBarOpen}
          isOpen={this.state.isSearchSideBarOpen}
          loading={RootStore.isLoading}
          fetchingData={this.state.loading}
          closeLoading={this.closeLoading}
          onSetSearchTerm={this.setSearchTerms}
          onApplySearchTerm={this.applySearchTerms}
          setSearchObject={this.updateSearchObj}
          savefilter={this.savefilter}
          // realInfluencerSearch={this.realInfluencerSearch}
          setAutoSave={() => {
            this.setState((prev) => {
              return {
                autoSave: !prev.autoSave,
              };
            });
          }}
          _setSearchState={this._setSearchState}
          autoSave={this.state.autoSave}
          setRealInfluencers={() => {
            this.setState((prevState) => {
              return {
                realInfluencers: !prevState.realInfluencers,
              };
            });
          }}
          realInfluencers={this.state.realInfluencers}
          {...this.props}
        />
      </div>
    );
  }
}
