import * as React from 'react';
import { Col, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import ReactModal from 'react-modal';
import { RouteComponentProps } from 'react-router';
import { ContentModel, IContentModel } from '../../../../store/models/ContentModel';
import ContentForm from './ContentForm';
import RootStore from '../../../../store/RootStore';
import { handleError, makeTempModelId } from '../../../../lib/Utils';
import Swal from 'sweetalert2';
import ContentPreview from './preview/ContentPreview';

export enum ContentTypeEnum {
    STORY = 'story',
    POST = 'post',
}

export enum ContentActionEnum {
    CREATE,
    EDIT,
}

interface Props extends RouteComponentProps<any> {
    contentType?: ContentTypeEnum;
    handleChange: Function;
    saveButtonText?: string;
    persistOnSave?: boolean;
    postData?: object
}

interface State {
    contentType: ContentTypeEnum | null;
    model: IContentModel | null;
    clone: IContentModel | null;
    isOpen: boolean;
    action: ContentActionEnum;
    postData?: object
}

export default class ContentFormDialogue extends React.Component<Props, State> {
    static defaultProps = {
        persistOnSave: true,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
            contentType: null,
            model: null,
            action: ContentActionEnum.CREATE,
            clone: null,
            postData: {}
        };
    }

    openNewDialogue(contentType: ContentTypeEnum) {
        this.setState(
            {
                isOpen: true,
                contentType,
                model: null,
                action: ContentActionEnum.CREATE,
                clone: null,
            },
            () => {
                this.setUp();
            },
        );
    }

    async close(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.setState(
                {
                    isOpen: false,
                    model: null,
                    clone: null,
                    postData: {},
                },
                () => {
                    resolve();
                },
            );
        });
    }

    setUp(): void {
        if (this.state?.contentType) {
            if (!RootStore.users.currentUser) {
                return;
            }

            let model = ContentModel.create({
                id: makeTempModelId(),
                data:
                    this.state?.contentType === ContentTypeEnum.POST
                        ? {
                            caption: '',
                        }
                        : {},
                type: this.state?.contentType,
                medias: [],
                user_id: RootStore.users.currentUser.id,
            });

            // create mode
            this.setState({
                contentType: this.state?.contentType,
                model: model,
            });
        }
    }

    loadForEdit(id: number) {
        this.setState({
            isOpen: true,
            action: ContentActionEnum.EDIT,
        });
        // edit mode
        RootStore.contentStore
        .fetchById(id, true)
        .then((model: any) => {
            this.setState(() => ({
                contentType: model.type,
                model: model,
                clone: model,
            }));
            RootStore.contentStore.setIdle()
        })
        .catch((error: any) => {
            handleError(error);
            Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Can not find this content! Maybe you have mistyped it',
                footer: '<a href>Why do I have this issue?</a>',
            });
        }) 
    }

    getTitle() {
        const { contentType } = this.state || null
        const { isBusy } = RootStore?.contentStore || true
        if(!isBusy && contentType){
            return `Instagram ${contentType === ContentTypeEnum.POST ? 'Feed Post' : 'Story Set'}`
        }
        return "Instagram ..."
    }

    async handleContentSelect(value: IContentModel) {
        await this.close();
        this.props?.handleChange(value);
    }

    acceptClone(model: IContentModel) {
        this.setState({
            clone: model,
        });
    }

    componentDidMount(): void {
        console.log(this.state);
    }

    render() {
        return (
            <ReactModal
                ariaHideApp={false}
                isOpen={this.state?.isOpen}
                contentLabel="Minimal Modal Example"
                className="contentFormDialogueModal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        overflowY: 'scroll',
                    },
                    content: {
                        padding: 0,
                    },
                }}
            >
                <ModalHeader
                    className={'modal-header content-select-dialogue-header'}
                    tag={'h3'}
                    toggle={this.close.bind(this)}
                >
                    <div className="icon" />
                    {this.getTitle()}
                    {this.state?.contentType === ContentTypeEnum.POST && (
                        <p className="text-muted mb-0">
                            Add combined up to 10 videos or images which you would like to show up on Influencers Feed.
                            This could be replaced later by the content Influencers create on their own.
                        </p>
                    )}
                </ModalHeader>
                <ModalBody className={'content-select-dialogue-content'}>
                    {RootStore.contentStore?.isBusy ? (
                        <div className="content-select-dialogue-content_loading-box">
                            <Spinner color="primary" style={{ margin: '15px auto', display: 'block' }} />
                        </div>
                    ) : (
                        <Row>
                            <Col xs={1} lg={9}>
                                {this.state?.model !== null && this.state?.contentType !== null ? (
                                    <ContentForm
                                        persistOnSave={this.props?.persistOnSave}
                                        saveButtonText={this.props?.saveButtonText}
                                        onChange={this.handleContentSelect.bind(this)}
                                        model={this.state?.model}
                                        action={this.state?.action}
                                        contentType={this.state?.contentType}
                                        getOnchageData={(key: string, value: any) => {
                                            this.setState(prev => ({
                                                ...prev,
                                                postData: { [key]: value }
                                            }))
                                            
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col xs={1} lg={3} className="preview-column">
                                {this.state?.model !== null ? (
                                    <React.Fragment>
                                        <h6 className="text-center">
                                            <b>Preview</b>
                                        </h6>
                                        <ContentPreview model={this.state?.model} postData={this.state?.postData}  />
                                    </React.Fragment>
                                ) : null}
                            </Col>
                        </Row>
                    )}
                </ModalBody>
            </ReactModal>
        );
    }
}
