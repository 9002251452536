import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Separator } from "../../../../template/components/common/CustomBootstrap";
import { Spinner } from "reactstrap";
import EmptySearchResult from "../../../app/offers/components/EmptySearchResult";
import NotificationTemplateList from "./NotificationTemplateList";
import RootStore from "../../../../store/RootStore";

interface Props extends RouteComponentProps {}
interface State {
  dataFetched: boolean;
}

export default class NotificationTemplateTab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dataFetched: true,
    };
  }

  async componentDidMount() {
    this.setState({ dataFetched: false });
    await RootStore.notificationTemplateStore.fetchAll();

    this.setState({
      dataFetched: true,
    });
  }

  componentWillUnmount() {
    RootStore.notificationTemplateStore.clearModel();
  }

  render() {
    const notifications =
      RootStore.notificationTemplateStore.notificationTemplates;
    return (
      <>
        <Separator className="mb-4 mt-3" />
        {!this.state.dataFetched ? (
          <div>
            <Spinner color="primary" className="mb-1" />
            <p>Please wait...</p>
          </div>
        ) : (
          <>
            {notifications.length > 0 ? (
              <NotificationTemplateList
                notifications={notifications}
                {...this.props}
              />
            ) : (
              <EmptySearchResult />
            )}
          </>
        )}
      </>
    );
  }
}
