import React from "react";
import Select from "react-select";
import CustomSelectInput from "../../../../template/components/common/CustomSelectInput";

interface Props {
  values: Array<{
    label: string;
    filter: any;
    // { value: string | null; key: string; not: boolean | null; operator: any | null };
  }>;
  title: string;
  name: string;
  handleChange: any;
  defaultValue: Object;
}
/**
 * select option filter section 
 * @param props, { values : { label , filter },title ,name ,handleChange , defaultValue }
 */
export default class DropDownFilter extends React.Component<Props> {
  render() {
    const { values, title, name, handleChange, defaultValue } = this.props;

    return (
      <>
        <label>{title}</label>
        <Select
          components={{ Input: CustomSelectInput }}
          className="react-select"
          classNamePrefix="react-select"
          name={name}
          onChange={handleChange}
          options={values}
          defaultValue={defaultValue}
        />
      </>
    );
  }
}
