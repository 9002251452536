import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
* this method Time Table Model
*/
export const SettingsModel = types
  .model({
    followers: types.string,
    engagement: types.string,
    languages: types.maybe(types.array(types.string))
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export interface ISettingsModel extends Instance<typeof SettingsModel> {}

export interface ISettingsModelSnapshotIn
  extends SnapshotIn<typeof SettingsModel> {}

export interface ISettingsModelSnapshotOut
  extends SnapshotOut<typeof SettingsModel> {}
