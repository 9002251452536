import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { IIdentityModel } from "./IdentityModel";
import RootStore from "../RootStore";
import { IUserModel } from "./UserModel";
import { Moment } from "moment";
import { IOfferModel } from "./OfferModel";

export const CampaignModel = types
  .model({
    id: types.identifierNumber,
    title: types.string,
    description: types.maybeNull(types.string),
    identity_id: types.maybe(types.number),
    deleted_at: types.maybeNull(types.string),
    state: types.maybe(
      types.enumeration([
        "unstarted",
        "awaiting-consideration",
        "negotiating",
        "pending-launch",
        "ongoing",
        "stopped",
      ])
    ),
    budget: types.maybe(types.number),
  })
  .actions((self) => ({}))
  .views((self) => ({

    /**
    * this method get Influencers
    */
    getInfluencers(): IIdentityModel[] {
      let offers = RootStore.offerStore.getOffersByCampaign(self.id);
      let myIdentity = (
        RootStore.users.currentUser as IUserModel
      ).getIdentities()[0].id;
      let influencerIdentityIdList: number[] = [];
      for (let offer of offers) {
        if (offer.getTargetIdentity().id !== myIdentity) {
          influencerIdentityIdList.push(offer.getTargetIdentity().id);
        }
        if (offer.getIdentity().id !== myIdentity) {
          influencerIdentityIdList.push(offer.getIdentity().id);
        }
      }
      return RootStore.identityStore.identities.filter(
        (identity: IIdentityModel) =>
          influencerIdentityIdList.indexOf(identity.id) !== -1
      );
    },
  }))
  .views((self) => ({

    /**
   * this method get Unread Messages
   */
    getUnreadMessages(): number {
      let offers = RootStore.offerStore.getOffersByCampaign(self.id);
      let sum = 0;
      for (let offer of offers) {
        sum += offer.getDialogue().unreadMessages;
      }
      return sum;
    },

    /**
   * this method get Num Negotiating
   */
    getNumNegotiating(): number {
      let offers = RootStore.offerStore.getOffersByCampaign(self.id);
      let sum = 0;
      for (let offer of offers) {
        if (offer.status !== "paid") {
          sum++;
        }
      }
      return sum;
    },

     /**
     * this method get Num Waiting For Payment
     */
    getNumWaitingForPayment(): number {
      let offers = RootStore.offerStore.getOffersByCampaign(self.id);
      let sum = 0;
      for (let offer of offers) {
        if (offer.status === "awaiting-payment") {
          sum++;
        }
      }
      return sum;
    },

    /**
     * this method get Total Invoice Amount
     */
    getTotalInvoiceAmount(): number {
      let offers = RootStore.offerStore.getOffersByCampaign(self.id);
      let sum = 0;
      for (let offer of offers) {
        if (offer.status === "awaiting-payment") {
          sum += offer.price;
        }
      }
      return sum;
    },

    /**
     * this method get First Publish Date
     */
    getFirstPublishDate(): Moment {
      let offers = RootStore.offerStore.getOffersByCampaign(self.id);
      let dates = offers.map((offer: IOfferModel, index: number) => {
        return {
          index,
          value: offer.getPublishTime(),
        };
      });
      dates = dates.sort((a: any, b: any) => {
        if (a.value.isBefore(b.value)) {
          return -1;
        }
        if (b.value.isAfter(a.value)) {
          return 1;
        }
        return 0;
      });
      return dates[0].value;
    },

    /**
     * this method get Deal Parties Label
     */
    getDealPartiesLabel(): string {
      let offers = RootStore.offerStore.getOffersByCampaign(self.id);
      let parties = [];
      for (let offer of offers) {
        if (offer.status === "awaiting-payment") {
          parties.push("@" + offer.getTargetIntegration().username);
        }
      }
      return parties.join(",");
    },
  }));
export interface ICampaignModel extends Instance<typeof CampaignModel> { }

export interface ICampaignModelSnapshotIn
  extends SnapshotIn<typeof CampaignModel> { }

export interface ICampaignModelSnapshotOut
  extends SnapshotOut<typeof CampaignModel> { }
