import React from 'react'
import { Button } from 'reactstrap'
import { NarrowArrowIcon } from '../../../../../../../assets/icons'
import classes from './style.module.scss'

function NextButton(props: any) {
    return (
        <Button color={props.secondary ? "secondary" : "success"} disabled={props?.loading} className={`mt-3 ${classes.button}`} type="submit" {...props}>
            {props?.label ? props?.label : (
                <>
                    {props?.loading ? 'Processing ...' : <span>{props.children ? props.children : <>Save & Next <NarrowArrowIcon className="ml-2" /></>}</span>}
                </>
            )}
        </Button>
    )
}

export default NextButton
