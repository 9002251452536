import React from 'react';
import { RouteComponentProps } from 'react-router';
import moment from 'moment'

import FilterComponent from '../../common-components/Filter/FilterComponent';
import { Separator } from '../../../template/components/common/CustomBootstrap';
import RootStore from '../../../store/RootStore';
import { IOfferModel } from '../../../store/models/OfferModel';
import OfferCard from './components/OfferCard';
import OfferCardMobileView from './components/OfferCardMobileView';
import EmptySearchResult from './components/EmptySearchResult';
import ClientSideFilterSet from '../../common-components/Filter/ClientSideFilterSet';
import { FilterType, Range } from '../../common-components/Filter/AbstractFilterSet';
import { isMobileAndTablet } from '../../../lib/Utils';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SortOrder, Pagination } from '../../../lib/QueryTypes';
import ControlOfferPopup from '../../common-components/ControlOfferPopup';

interface Props extends RouteComponentProps<{}, {}, { adId?: string }> { }

interface State {
    filteredOffers: IOfferModel[];
    reachedEnd: boolean;
    pagination: Pagination;
    dataFetched: boolean;
    sort: { key: string; direction: SortOrder };
    loading: boolean;
    stateDropDown: boolean;
    stateDropDownValues: { name: string; isActive?: boolean, label?: string }[];
    filterstateselected: String;
    controlOfferAd?: any;
    controlOfferModalisOpen?: boolean
}

interface IOfferModelFlattened {
    [key: string]: any;
    opIdentityId: number;
    adId: number;
    campaignId: number;
    offerStatus: string;
    adTitle: string;
    influencerName: string;
    lastMessage: string;
}

@observer
export default class OffersTab extends React.Component<Props, State> {
    private filterSet: ClientSideFilterSet<IOfferModel, IOfferModelFlattened>;

    constructor(props: any) {
        super(props);
        this.state = {
            filteredOffers: [],
            reachedEnd: false,
            dataFetched: false,
            loading: false,
            stateDropDown: false,
            pagination: {
                totalPages: null,
                page: 1,
                pageSize: 30,
            },
            sort: {
                key: 'updated_at',
                direction: SortOrder.DESCENDING,
            },
            stateDropDownValues: [
                {
                    name: 'All',
                    isActive: true,
                    label: 'Show All'
                },
                {
                    name: 'reject',
                    label: 'Rejected'
                },
                {
                    name: 'read',
                    label: 'Read'
                },
                {
                    name: 'unread',
                    label: 'Unread'
                },
                {
                    name: 'archive',
                    label: 'Archived'
                },
                {
                    name: 'delete',
                    label: 'Deleted'
                },
                {
                    name: 'spam',
                    label: 'Spammed'
                },
                {
                    name: 'block',
                    label: 'Blocked'
                },
            ],
            filterstateselected: '',
            controlOfferAd: {
                followers: '',
                engagement: '',
                languages: [],
                countries: [],
                gender: 'all',
                inviteOnly: null
            },
            controlOfferModalisOpen: false
        };

        this.filterSet = new ClientSideFilterSet<IOfferModel, IOfferModelFlattened>({
            getModels: () => {
                return RootStore.offerStore.offers;
            },
            flattenFunction: (offer: IOfferModel) => {
                return {
                    opIdentityId: offer.getOpponentIdentity().id,
                    adId: offer.ad_id.id,
                    campaignId: offer.ad_id.campaign_id.id,
                    offerStatus: offer.getHumanReadableStatus(),
                    adTitle: offer.ad_id.title,
                    influencerName: offer.getOpponentIdentity().name,
                    lastMessage: offer.last_message.message,
                };
            },
            onFilteredCallback: (filteredOffers: IOfferModel[]) => {
                const stateVal = this.state.stateDropDownValues.find((drop) => drop.isActive).name;
                this.setState({
                    filteredOffers:
                        stateVal === 'All'
                            ? filteredOffers
                            : filteredOffers.filter((offer) => offer.state === stateVal),
                    filterstateselected: stateVal,
                });

                RootStore.offerStore.setFilters(this.filterSet.getFilterValues().filters);
            },
            fuzzySearchKeys: ['influencerName', 'lastMessage', 'adTitle'],
        });

        this.filterSet.addFilter({
            name: 'opIdentityId',
            title: 'Influencer',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [
                null,
                ...RootStore?.offerStore
                    ?.getAllOpponentIdentities()
                    ?.filter(
                        (influencer_id: number, index: number, self: number[]) => index === self?.indexOf(influencer_id),
                    ),
            ],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value === null) return 'All';
                return RootStore.identityStore.getByIdentityId(parseInt(value.toString())).name;
            },
        });

        this.filterSet.addFilter({
            name: 'adId',
            title: 'Ad',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [
                null,
                ...RootStore?.offerStore?.offers
                    .map((offer: IOfferModel) => offer?.ad_id?.id)
                    .filter((ad_id: number, index: number, self: number[]) => index === self?.indexOf(ad_id)),
            ],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value === null) return 'All';
                return RootStore.adStore.getExistingAd(parseInt(value.toString()))?.title;
            },
        });

        this.filterSet.addFilter({
            name: 'offerStatus',
            title: 'Status',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [
                null,
                'Waiting your Approval',
                'in Negotiation',
                'Waiting Payment',
                'Scheduled',
                'Waiting Approval',
                'Completed',
                'Pending Publish',
            ],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value === null) return 'All';
                return value.toString();
            },
        });
    }

    async loadMoreOffers() {
        if (!this.state.reachedEnd) {
            //this.setState({ dataFetched: false });
            let res = await RootStore.offerStore.fetchAllOffers(
                this.state.pagination,
                this.state.sort,
                !this.state.reachedEnd,
            );
            console.log('offerStore: ', res);
            this.setState({
                dataFetched: true,
                filteredOffers: RootStore.offerStore.offers,
                pagination: {
                    pageSize: 30,
                    totalPages: res.totalPages,
                    page: res.page,
                },
                loading: false,
                reachedEnd: res.page >= res.totalPages,
            });
        }
    }

    async pageChange(page: number): Promise<void> {
        await this.setState({
            pagination: { ...this.state.pagination, page },
        });
        await this.loadMoreOffers();
        for (let offer of RootStore.offerStore.offers) {
            (async () => {
                await offer.getDialogue().fetchUnreadMessageCount();
            })();
        }
        this.filterSet.applyFilters();
    }

    async componentDidMount() {
        if (!RootStore.offerStore.isBusy)
            await RootStore.offerStore.fetchAllOffers(this.state.pagination, this.state.sort);
        for (let offer of RootStore.offerStore.offers) {
            (async () => {
                await offer.getDialogue().fetchUnreadMessageCount();
            })();
        }
        this.setState({
            dataFetched: true,
            filteredOffers: RootStore.offerStore.offers.filter(
                (offer) => !this.state.stateDropDownValues.map((dropdown) => dropdown.name).includes(offer.state),
            ),
        });

        const havingFilters = RootStore.offerStore.filterSet;
        if (havingFilters) {
            Object.keys(havingFilters).map((filter) => {
                this.filterSet.setFilterValue(filter, havingFilters[filter]);
                return filter;
            });
        }
        this.filterSet.applyFilters();
    }

    captchaActiveFilter = () => {
        let label = this.state.stateDropDownValues.find(
            (drop) => drop.isActive,
        ).label
        if (label === 'Show All') label = 'Filter by Action'
        return label
    }

    controlOfferModal = (ad) => {
        this.setState({
            controlOfferAd: ad,
            controlOfferModalisOpen: true
        })
    }

    onCloseControlOfferModal = () => {
        this.setState({
            controlOfferAd: null,
            controlOfferModalisOpen: false
        })
    }

    reloadList = async () => {
        await this.setState({
            dataFetched: false,
        });
        await RootStore.offerStore.fetchAllOffersReload({ page: 1, pageSize: 30 }, this.state.sort);
        for (let offer of RootStore.offerStore.offers) {
            (async () => {
                await offer.getDialogue().fetchUnreadMessageCount();
            })();
        }
        this.setState({
            dataFetched: true,
            filteredOffers: RootStore.offerStore.offers.filter(
                (offer) => !this.state.stateDropDownValues.map((dropdown) => dropdown.name).includes(offer.state),
            ),
        });

        const havingFilters = RootStore.offerStore.filterSet;
        if (havingFilters) {
            Object.keys(havingFilters).map((filter) => {
                this.filterSet.setFilterValue(filter, havingFilters[filter]);
                return filter;
            });
        }

        this.filterSet.applyFilters();
    }

    render() {
        return (
            <>
                <div className="offersTab_header">
                    <FilterComponent filterSet={this.filterSet} />
                </div>

                <Separator className="mb-4 mt-3" />

                <InfiniteScroll
                    style={{ paddingTop: '25px', minHeight: '400px' }}
                    dataLength={this.state.filteredOffers.length}
                    next={() => {
                        if (!this.state.reachedEnd) {
                            this.pageChange(this.state.pagination.page + 1);
                        }
                    }}
                    hasMore={!this.state.reachedEnd}
                    loader={
                        <div>
                            <Spinner color="primary" size={'lg'} className="spinner" />
                        </div>
                    }
                    hasChildren={true}
                >
                    {this.state.dataFetched && (
                        <div>
                            <table className="offersList" cellSpacing="0" cellPadding="0">
                                <thead>
                                    {!isMobileAndTablet() ? (
                                        <tr>
                                            <th style={{ width: '240px' }}>Influencer</th>
                                            <th>{' '}</th>
                                            <th style={{ width: '220px' }}>Ad</th>
                                            <th>Last Message</th>
                                            <th>Price</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">
                                                {this.state.dataFetched && (
                                                    <div className="text-center">
                                                        <ButtonDropdown
                                                            isOpen={this.state.stateDropDown}
                                                            toggle={() =>
                                                                this.setState({
                                                                    stateDropDown: !this.state.stateDropDown,
                                                                })
                                                            }
                                                        >
                                                            <DropdownToggle
                                                                caret
                                                                size="xs"
                                                                outline
                                                                className="offerListDD"
                                                                color=""
                                                            >
                                                                <p style={{ fontSize: '12px', marginBottom: '0px' }}>
                                                                    {this.captchaActiveFilter()}
                                                                </p>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {this.state.stateDropDownValues.map((drop) => (
                                                                    <DropdownItem
                                                                        key={drop.name}
                                                                        onClick={() => {
                                                                            if (drop.name === 'All')
                                                                                this.setState({
                                                                                    filteredOffers:
                                                                                        RootStore.offerStore.offers.filter(
                                                                                            (offer) =>
                                                                                                !this.state.stateDropDownValues.includes(
                                                                                                    offer.state,
                                                                                                ),
                                                                                        ),
                                                                                    stateDropDown: false,
                                                                                });
                                                                            else
                                                                                this.setState({
                                                                                    filteredOffers:
                                                                                        RootStore.offerStore.offers.filter(
                                                                                            (offer) =>
                                                                                                offer.state === drop,
                                                                                        ),
                                                                                    stateDropDown: false,
                                                                                });

                                                                            const stateDropDownValues =
                                                                                this.state.stateDropDownValues.map(
                                                                                    (dropVal) => {
                                                                                        dropVal.isActive =
                                                                                            dropVal.name === drop.name;
                                                                                        return dropVal;
                                                                                    },
                                                                                );
                                                                            this.setState({
                                                                                stateDropDownValues,
                                                                            });

                                                                            this.filterSet.applyFilters();
                                                                        }}
                                                                    >
                                                                        {drop.label}
                                                                    </DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        </ButtonDropdown>
                                                    </div>
                                                )}
                                            </th>
                                        </tr>
                                    ) : null}
                                </thead>
                                {this.state.filteredOffers.length > 0 ? (
                                    <tbody>
                                        {this.state.filteredOffers.length > 0
                                            ? this.state.filteredOffers
                                                .sort((a, b) =>
                                                    moment(b.getDialogue().getLastMessageMoment()).diff(
                                                        a.getDialogue().getLastMessageMoment(),
                                                    ),
                                                )
                                                .filter((offer) =>
                                                    this.state.filterstateselected === 'All'
                                                        ? offer.state === null
                                                        : offer.state === this.state.filterstateselected,
                                                )
                                                .map((offer: IOfferModel) => (
                                                    <React.Fragment key={`offer_${offer.id}`}>
                                                        {!isMobileAndTablet() || window.innerWidth > 980 ? (
                                                            // @ts-ignore
                                                            <OfferCard offer={offer} controlOfferModal={() => this.controlOfferModal(offer?.ad_id)} {...this.props} />
                                                        ) : (
                                                            // @ts-ignore
                                                            <OfferCardMobileView offer={offer} controlOfferModal={() => this.controlOfferModal(offer?.ad_id)} {...this.props} />
                                                        )}
                                                        <tr className="offersList__spacer" />
                                                    </React.Fragment>
                                                ))
                                            : null}
                                    </tbody>
                                ) : (
                                    ''
                                )}
                            </table>
                            {!this.state.loading && this.state.filteredOffers.length === 0 ? <EmptySearchResult /> : ''}
                        </div>
                    )}
                </InfiniteScroll>
                {this.state.controlOfferModalisOpen && <ControlOfferPopup
                    isOpen={this.state.controlOfferModalisOpen}
                    onClose={this.onCloseControlOfferModal}
                    ad={this.state.controlOfferAd}
                    reloadList={this.reloadList}
                />}
            </>
        );
    }
}
