import { Grid, Modal } from '@mui/material'
import React from 'react'
import { CloseIcon } from '../../../../../../../../../../../../../assets/icons'
import classes from './style.module.scss'

function CreatAdFeedLibrary(props: any) {

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <Grid className={classes.content}>
                <Grid item container justifyContent="space-between" alignItems="center" marginBottom={2} className={classes.head}>
                    <Grid item>
                        <h2 id="parent-modal-title">Select something to schedule</h2>
                    </Grid>
                    <Grid item className={classes.close}>
                        <CloseIcon onClick={props.onClose} />
                    </Grid>
                </Grid>
                <Grid item container className={classes.list} component="ul" justifyContent="space-between">
                    <Grid item component="li">
                        <div className={classes.cover}>
                            <img src="https://api-staging.ainfluencer.com/api/files/a318b0b2-4530-11ec-b509-a256db8a35ec.jpg?width=512&height=384" alt="" />
                        </div>
                        <div className={classes.title}>
                            Caption
                        </div>
                        <div className={classes.tag}>
                            Post
                        </div>
                    </Grid>

                    <Grid item component="li">
                        <div className={classes.cover}>
                            <img src="https://api-staging.ainfluencer.com/api/files/a318b0b2-4530-11ec-b509-a256db8a35ec.jpg?width=512&height=384" alt="" />
                        </div>
                        <div className={classes.title}>
                            Caption
                        </div>
                        <div className={classes.tag}>
                            Post
                        </div>
                    </Grid>

                    <Grid item component="li">
                        <div className={classes.cover}>
                            <img src="https://api-staging.ainfluencer.com/api/files/a318b0b2-4530-11ec-b509-a256db8a35ec.jpg?width=512&height=384" alt="" />
                        </div>
                        <div className={classes.title}>
                            Caption
                        </div>
                        <div className={classes.tag}>
                            Post
                        </div>
                    </Grid>

                    <Grid item component="li">
                        <div className={classes.cover}>
                            <img src="https://api-staging.ainfluencer.com/api/files/a318b0b2-4530-11ec-b509-a256db8a35ec.jpg?width=512&height=384" alt="" />
                        </div>
                        <div className={classes.title}>
                            Caption
                        </div>
                        <div className={classes.tag}>
                            Post
                        </div>
                    </Grid>

                    <Grid item component="li">
                        <div className={classes.cover}>
                            <img src="https://api-staging.ainfluencer.com/api/files/a318b0b2-4530-11ec-b509-a256db8a35ec.jpg?width=512&height=384" alt="" />
                        </div>
                        <div className={classes.title}>
                            Caption
                        </div>
                        <div className={classes.tag}>
                            Post
                        </div>
                    </Grid>

                </Grid>
            </Grid>
        </Modal>
    )
}

export default CreatAdFeedLibrary
