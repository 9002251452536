import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";

import { InvitationIcon, RightArrowIcon } from "../../../../../assets/icons";

interface Props extends RouteComponentProps {
  totalInvitations: number;
}

/**
 * this is function component shows offer number 
 * and linked to offers page,Create Ad,Explore Influencers
 * @param totalInvitations , user total Invitations sent
 * @typeParam Props 
 */

export default function InvitationSummary(props: Props) {
  const { totalInvitations } = props;

  return (
    <div className="dashboardWidget">
      <div className="dashboardWidget__header">
        <InvitationIcon />
        <div>
          <span>{totalInvitations}</span>
          <p>Invitations Sent</p>
        </div>
      </div>
      <p className="dashboardWidget__content">
        {totalInvitations > 0 ? (
          <>
            You have invited <span>{totalInvitations}</span> influencers to
            collaborate on Ads.
          </>
        ) : (
          <>
            You haven't invited any influencers to collaborate on Ads.{" "}
            <Link to="/app/ads/ad/objectives">Create an Ad</Link> or{" "}
            <Link to="/app/influencer-explorer">Explore Influencers</Link> to
            get started.
          </>
        )}
      </p>
      <div className="dashboardWidget__link">
        {totalInvitations > 0 && (
          <Link to="/app/influencer-explorer">
            Explore Influencers <RightArrowIcon />
          </Link>
        )}
      </div>
    </div>
  );
}
