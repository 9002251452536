export enum SortOrder {
    ASCENDING = 'ASC',
    DESCENDING = 'DESC',
}

export enum QueryOperator {
    STRING_CONTAINS = 'ct',
    STARTS_WITH = 'sw',
    ENDS_WITH = 'ew',
    EQUALS = 'eq',
    GREATER_THAN = 'gt',
    GREATER_THAN_EQUAL = 'gte',
    LESS_THAN_EQUAL = 'lte',
    LESS_THAN = 'lt',
    IN = 'in',
    BETWEEN = 'bt',
}

export type Filter = {
    key: string;
    value: any;
    operator: QueryOperator;
    not: boolean;
    date_from?: number;
    date_to?: number;
};

export type FilterGroup = {
    isOr: boolean;
    filters: Filter[];
};

export type Pagination = {
    totalPages: number | null;
    page: number;
    pageSize: number;
};
