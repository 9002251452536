import React from "react";
import { Button } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";

import RootStore from "../../../../store/RootStore";
import { ICategoryModel } from "../../../../store/models/CategoryModel";
import SimilarProfileCard from "./SimilarProfileCard";
import { RightArrowIcon } from "../../../../assets/icons";
import { IInfluencerModel } from "../../../../store/models/InfluencerModel";
import { explorerSearchType } from "../../../../lib/CustomTypes";

interface Props extends RouteComponentProps {
  similarInfluencers: Array<IInfluencerModel>;
  categories: Array<ICategoryModel>;
}

export default function SimilarProfiles(props: Props) {
  const { similarInfluencers, categories } = props;

  return (
    <div className="influencerProfile__sideBar_similarProfiles">
      <h1>Similar Profiles</h1>
      {similarInfluencers.slice(0, 3).map((influencer: IInfluencerModel) => (
        <SimilarProfileCard
          key={`similarInfluencer__${influencer.identity.id}`}
          influencer={influencer}
          {...props}
        />
      ))}

      <Button
        outline
        color="primary"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          props.history.push({
            pathname: "/app/influencer-explorer/custom-search",
            state: {
              name: explorerSearchType.CATEGORY,
              value: [
                ...categories.map((category) =>
                  category.title.toLowerCase() !== "other"
                    ? {
                        value: category.id,
                        label: category.title,
                      }
                    : {
                        value: category.id,
                        label: RootStore.categories.filter(
                          (cate: ICategoryModel) =>
                            cate.id === category.parent_id
                        )[0].title,
                      }
                ),
              ],
            },
          });
        }}
      >
        See More <RightArrowIcon />
      </Button>
    </div>
  );
}
