import React from "react";
import { Modal, Button, FormGroup, Label } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { CloseIcon } from "../../assets/icons";
import { ICampaignModel } from "../../store/models/CampaignModel";

const CampaignSchema = Yup.object().shape({
  title: Yup.string()
    .min(3)
    .required("For creating a campaign you must provide a title"),
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreateCampaign?: (values: { title: string }) => void;
  campaign?: ICampaignModel;
  onEditCampaign?: (values: { title: string }) => void;
}

export function CampaignForm(props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.onClose}
      className="campaignForm"
    >
      <CloseIcon onClick={props.onClose} />
      <div className="campaignForm__form">
        <Formik
          initialValues={
            props.campaign ? { title: props.campaign.title } : { title: "" }
          }
          validationSchema={CampaignSchema}
          onSubmit={(values: { title: string }) => {
            if (props.campaign) {
              props.onEditCampaign(values);
              props.onClose();
            } else {
              props.onCreateCampaign(values);
              props.onClose();
            }
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
            setErrors,
            setFieldError,
          }) => (
            <Form
              translate={"no"}
              className="av-tooltip tooltip-label-right panel-form"
            >
              <FormGroup className="error-l-100 w-100">
                <Label className="position-relative">
                  Provide a title for your campaign:
                  {errors.title && touched.title && (
                    <div className="invalidFeedback">{errors.title}</div>
                  )}
                </Label>
                <Field
                  className="form-control w-100"
                  name="title"
                  placeholder="Example: Halloween Makeup"
                  onChange={(e: any) => {
                    setFieldValue("title", e.target.value);
                  }}
                />
              </FormGroup>
              <Button color="primary" type="submit">
                Create Campaign
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
