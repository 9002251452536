import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, CustomInput } from 'reactstrap';
import { CloseIcon } from '../../assets/icons';
import RootStore from '../../store/RootStore';
import { FormikReactSelect } from '../common-components';
import { CountriesList } from './../../constants/countriesList';
import languageList from '../../constants/data/languages.json';
import { Grid } from '@mui/material';
import { servicePath } from './../../constants/defaultValues';
import axios from 'axios';
import { ajaxErrorAlert, handleError } from '../../lib/Utils';

interface Props {
    isOpen: boolean;
    onClose?: () => void;
    ad?: any;
    reloadList?: () => void;
}

const checkArrZero = (arr) => Array.isArray(arr) && arr.length === 0;
const checkArr = (arr) => Array.isArray(arr) && arr.length > 0;

const ControlOfferPopup: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);

    const [followers, setFollowers] = useState('');
    const [allFollowers, setAllFollowers] = useState(true);

    const [engagement, setEngagement] = useState('');
    const [allEngagement, setAllEngagement] = useState(true);

    const [allCountries, setAllCountries] = useState(true);
    const [countries, setCountries] = useState([]);

    const [allLanguages, setAllLanguages] = useState(true);
    const [languages, setLanguages] = useState([]);

    const [gender, setGender] = useState(null);

    const [inviteOnly, setInviteOnly] = useState(null);
    useEffect(() => {
        try {
            const { limit_country, limit_gender, invite_only, settings } = props?.ad;
            const { followers: f, engagement: e, languages: l } = settings || {
                followers: '',
                engagement: '',
                languages: [],
            };

            if (Number(f) + '' !== 'NaN') {
                setFollowers(f);
                setAllFollowers(false);
            } else {
                setAllFollowers(true);
            }

            if (Number(e) + '' !== 'NaN') {
                setEngagement(e);
                setAllEngagement(false);
            } else {
                setAllEngagement(true);
            }

            if (limit_country) {
                if (checkArr(limit_country)) {
                    const arr = [];
                    if (limit_country && Array.isArray(limit_country)) {
                        limit_country.map((coun: any) => {
                            CountriesList.map((item: any) => {
                                if (item.id === coun) {
                                    arr.push({
                                        label: item.name,
                                        value: item.id,
                                    });
                                }
                            });
                        });
                    }
                    setCountries(arr);
                } else {
                    setCountries([]);
                    setAllCountries(true);
                }
            }

            if (l) {
                if (checkArr(l)) {
                    const arr = [];
                    if (l && Array.isArray(l)) {
                        l.map((lang: any) => {
                            languageList.map((item: any) => {
                                if (item.value === lang) {
                                    arr.push({
                                        label: item.name,
                                        value: item.value,
                                    });
                                }
                            });
                        });
                    }
                    setLanguages(arr);
                } else {
                    setLanguages([]);
                    setAllLanguages(true);
                }
            }
            if (limit_gender) {
                if (limit_gender === 'male') setGender({ label: 'Male', value: 'male' });
                else if (limit_gender === 'female') setGender({ label: 'Female', value: 'female' });
                else setGender({ label: 'All', value: null });
            }

            if (invite_only) {
                setInviteOnly(1);
            } else {
                setInviteOnly(0);
            }
        } catch {
            console.log('INITIALISE');
        }
    }, []);

    useEffect(() => {
        if (!followers) setAllFollowers(true);
        else setAllFollowers(false);
    }, [followers]);
    useEffect(() => {
        if (!engagement) setAllEngagement(true);
        else setAllEngagement(false);
    }, [engagement]);
    useEffect(() => {
        if (!countries || checkArrZero(countries)) setAllCountries(true);
        else setAllCountries(false);
    }, [countries]);
    useEffect(() => {
        if (!languages || checkArrZero(languages)) setAllLanguages(true);
        else setAllLanguages(false);
    }, [languages]);

    const goStepOne = () => {
        setStep(1);
    };

    const goStepTwo = () => {
        setStep(2);
    };

    const goStepThree = () => {
        setStep(3);
    };

    const finish = () => {
        try {
            setLoading(true);
            const headers = {
                headers: {
                    Authorization: 'Bearer ' + RootStore.users.getToken(),
                },
            };
            const id = props.ad?.id;
            const url = `/v2/ads/${id}/settings`;
            const body = {
                followers: allFollowers ? 'all' : followers,
                engagement: allEngagement ? 'all' : engagement,
                countries: allCountries ? [] : countries.map((item) => item?.value),
                languages: allLanguages ? [] : languages.map((item) => item?.value),
                gender: gender?.value || 'all',
                invite_only: inviteOnly ? '1' : '0',
            };
            axios
                .post(`${servicePath}${url}`, body, headers)
                .then((res) => {
                    props.reloadList();
                })
                .catch((err) => {
                    console.log('err : ', err);
                })
                .finally(() => {
                    setLoading(false);
                    props.onClose();
                    setStep(1);
                    setAllCountries(false);
                    setFollowers(null);
                    setAllFollowers(false);
                    setEngagement(null);
                    setAllEngagement(false);
                    setCountries([]);
                    setAllLanguages(false);
                    setLanguages([]);
                    setGender(null);
                    setInviteOnly(null);
                });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem in update the Settings');
        }
    };

    return (
        <Modal isOpen={props.isOpen} className="controlOffer" toggle={props.onClose} fade={false}>
            <div className="controlOffer_close-box" onClick={props.onClose}>
                <CloseIcon width="20" height="20" />
            </div>
            <ModalBody>
                <div>
                    <p className="controlOffer_title_1">Control offers made to your Ad</p>
                    <p className="controlOffer_title_2">Set influencers who are allowed to make offers to your Ad</p>
                </div>
                {/* 1 */}
                {step === 1 ? (
                    <div className="controlOffer_steps-box">
                        <div className="controlOffer_steps-box_section">
                            <h3 className="controlOffer_steps-box_section_title">
                                1. Influencers with number of followers greater than:
                            </h3>
                            <div className="controlOffer_steps-box_section_body">
                                <div>
                                    <input
                                        className="controlOffer_steps-box_section_body_input"
                                        value={followers || ''}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            if (Number(value) + '' !== 'NaN') {
                                                setFollowers(value);
                                            }
                                        }}
                                        maxLength={10}
                                    />
                                    <span className="controlOffer_steps-box_section_body_text"> Followers</span>
                                </div>
                                <div className="ml-4">
                                    <CustomInput
                                        type="radio"
                                        id="allFollowers"
                                        name="allFollowers"
                                        label="All"
                                        value={'all'}
                                        checked={allFollowers}
                                        onChange={() => {
                                            setAllFollowers((prev) => !prev);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="controlOffer_steps-box_section">
                            <h3 className="controlOffer_steps-box_section_title">
                                2. Influencers with engagement rate greater than (Max 10%):
                            </h3>
                            <div className="controlOffer_steps-box_section_body">
                                <div>
                                    <input
                                        className="controlOffer_steps-box_section_body_input"
                                        value={engagement || ''}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            if (Number(value) + '' !== 'NaN') {
                                                if (+value <= 10) {
                                                    setEngagement(value);
                                                }
                                            }
                                        }}
                                        maxLength={2}
                                    />
                                    <span className="controlOffer_steps-box_section_body_text"> % Engagement Rate</span>
                                </div>
                                <div className="ml-4">
                                    <CustomInput
                                        type="radio"
                                        id="allEngagement"
                                        name="allEngagement"
                                        label="All"
                                        value={'all'}
                                        checked={allEngagement}
                                        onChange={() => {
                                            setAllEngagement((prev) => !prev);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="controlOffer_steps-box_buttons">
                            <div></div>
                            <Button
                                color="primary"
                                className="btn-shadow"
                                size="lg"
                                disabled={loading}
                                onClick={goStepTwo}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {/* 2 */}
                {step === 2 ? (
                    <div className="controlOffer_steps-box">
                        <div className="controlOffer_steps-box_section">
                            <h3 className="controlOffer_steps-box_section_title">
                                3. Influencers from the following countries:
                            </h3>
                            <div className="controlOffer_steps-box_section_body">
                                <FormikReactSelect
                                    name="country"
                                    id="country"
                                    value={countries}
                                    isMulti={true}
                                    isClearable={true}
                                    options={CountriesList?.map((cou) => {
                                        return {
                                            label: cou.name,
                                            value: cou.id,
                                        };
                                    })}
                                    onChange={(name: string, value: any) => {
                                        setCountries(value);
                                    }}
                                    onBlur={() => {}}
                                />
                                <div className="ml-4">
                                    <CustomInput
                                        type="radio"
                                        id="allCountries"
                                        name="allCountries"
                                        label="All"
                                        value={'all'}
                                        checked={allCountries}
                                        onChange={() => {
                                            setAllCountries((prev) => !prev);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="controlOffer_steps-box_section">
                            <h3 className="controlOffer_steps-box_section_title">
                                4. Influencers speaking the following languages:
                            </h3>
                            <div className="controlOffer_steps-box_section_body">
                                <FormikReactSelect
                                    name="language"
                                    id="language"
                                    value={languages}
                                    isMulti={true}
                                    isClearable={true}
                                    options={languageList?.map((lang: any) => {
                                        return {
                                            label: lang.name,
                                            value: lang.value,
                                        };
                                    })}
                                    onChange={(name: any, value: any) => {
                                        setLanguages(value);
                                    }}
                                    onBlur={() => {}}
                                />
                                <div className="ml-4">
                                    <CustomInput
                                        type="radio"
                                        id="allLanguages"
                                        name="allLanguages"
                                        label="All"
                                        value={'all'}
                                        checked={allLanguages}
                                        onChange={() => {
                                            setAllLanguages((prev) => !prev);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="controlOffer_steps-box_buttons">
                            <Button
                                color="primary"
                                className="btn-shadow"
                                size="lg"
                                disabled={loading}
                                onClick={goStepOne}
                            >
                                Back
                            </Button>
                            <Button
                                color="primary"
                                className="btn-shadow"
                                size="lg"
                                disabled={loading}
                                onClick={goStepThree}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {/* 3 */}
                {step === 3 ? (
                    <div className="controlOffer_steps-box">
                        <div className="controlOffer_steps-box_section">
                            <h3 className="controlOffer_steps-box_section_title">
                                5. Are you looking to work with influencers of a given gender?
                            </h3>
                            <div className="controlOffer_steps-box_section_body">
                                <FormikReactSelect
                                    name="gender"
                                    id="gender"
                                    value={gender}
                                    isMulti={false}
                                    options={[
                                        { label: 'All', value: null },
                                        { label: 'Male', value: 'male' },
                                        { label: 'Female', value: 'female' },
                                    ]}
                                    onChange={(name: any, value: any) => {
                                        setGender(value);
                                    }}
                                    onBlur={() => {}}
                                />
                            </div>
                        </div>
                        <div className="controlOffer_steps-box_section">
                            <h3 className="controlOffer_steps-box_section_title">
                                6. How would you like to receive offers on your Ad?
                            </h3>
                            <Grid item flexDirection="column">
                                <Grid item>
                                    <CustomInput
                                        type="radio"
                                        id="exCustomRadio1"
                                        name="offer"
                                        label="Only allow offers from influencers that i have invited"
                                        value={1}
                                        checked={Boolean(inviteOnly) === true}
                                        onChange={() => setInviteOnly(true)}
                                    />
                                </Grid>
                                <Grid item>
                                    <CustomInput
                                        type="radio"
                                        id="exCustomRadio2"
                                        name="offer"
                                        label="Allow any influencer to send me an offer"
                                        value={0}
                                        checked={Boolean(inviteOnly) === false}
                                        onChange={() => setInviteOnly(false)}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className="controlOffer_steps-box_buttons">
                            <Button
                                color="primary"
                                className="btn-shadow"
                                size="lg"
                                disabled={loading}
                                onClick={goStepTwo}
                            >
                                Back
                            </Button>
                            <Button
                                color="success"
                                className="btn-shadow"
                                size="lg"
                                disabled={loading}
                                onClick={finish}
                            >
                                Finish
                            </Button>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </ModalBody>
        </Modal>
    );
};

export default ControlOfferPopup;
