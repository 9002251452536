import Client from "twilio-chat";
import { Channel } from "twilio-chat/lib/channel";
import { Paginator } from "twilio-chat/lib/interfaces/paginator";
import { ChannelDescriptor } from "twilio-chat/lib/channeldescriptor";
import { DialogueModel, IDialogueModel } from "../store/models/DialogueModel";
import { resolveIdentifier } from "mobx-state-tree";
import RootStore from "../store/RootStore";
import { Message } from "twilio-chat/lib/message";
import EventEmitter from "events";
import { ajaxErrorAlert, handleError } from "./Utils";
import { notifyNewOffer } from "../Notifications";
import { IOfferModel } from "../store/models/OfferModel";

export default class TwilioFacade {
  private static client: Client = null;
  private static connecting: boolean = false;
  private static currentToken: string = "";
  private static emitter: NodeJS.EventEmitter = new EventEmitter();
  private static channelList: Channel[] = [];
  public static msgDependencyResolver: Function = null;
  public static newOfferHandler: Function = null;
  private static sequenceQueue: { [key: string]: { [key: number]: Message } } =
    {};
  private static lastMessageCounter: { [key: string]: number } = {};

  public static retrievedChannelSids: string[] = [];
  public static blockNewChannelEvent: boolean = false;

  static async connectMessagingClient(token: string) {
    if (
      (!TwilioFacade.client && !this.connecting) ||
      TwilioFacade.currentToken !== token
    ) {
      // Initialize the Chat messaging client
      this.connecting = true;
      try {
        TwilioFacade.client = await Client.create(token);
        this.connecting = false;
        TwilioFacade.currentToken = token;
        TwilioFacade.emitter.emit("connection:successful");
      } catch (e) {
        TwilioFacade.emitter.emit("connection:error", e);
        this.connecting = false;
        throw e;
      }
      TwilioFacade.setUpClientEffects();
    }
  }

  static async connectionReady() {
    if (TwilioFacade.client === null) {
      return new Promise((resolve, reject) => {
        TwilioFacade.emitter.once("connection:successful", () => {
          resolve();
        });
        TwilioFacade.emitter.once("connection:error", (e) => {
          reject(e);
        });
      });
    }
  }

  static setUpClientEffects() {
    // TwilioFacade.client.on("channelJoined", e => {
    //   // console.log(e);
    // });
    //
    // TwilioFacade.client.on("channelRemoved", e => {
    //   // console.log(e);
    // });

    TwilioFacade.client.user.on("updated", (event: any) => {
      event.updateReasons.forEach((reason: string) => {
        if (reason === "online") {
          //do something
          //let user = event.user as User;
          //user.entityName;
        }
      });
    });

    TwilioFacade.client.on("channelUpdated", (e) => {
      if (!TwilioFacade.blockNewChannelEvent) {
        if (typeof e.channel.attributes.offer_id !== "undefined") {
          if (TwilioFacade.newOfferHandler !== null) {
            if (
              TwilioFacade.retrievedChannelSids.indexOf(e.channel.sid) === -1
            ) {
              TwilioFacade.newOfferHandler(e.channel.attributes.offer_id);
            }
          }
        }
      }
    });

    TwilioFacade.client.on("tokenExpired", (e) => {
      // console.log(e);
    });
  }

  static async getUserChannelDescriptors(): Promise<
    Paginator<ChannelDescriptor>
  > {
    await TwilioFacade.connectionReady();
    return await TwilioFacade.client.getUserChannelDescriptors();
  }

  static async getUserChannels(sidList: string[]): Promise<Channel[]> {
    await TwilioFacade.connectionReady();
    let channels: Channel[] = [];
    for (let sid of sidList) {
      channels.push(await TwilioFacade.getChannelBySid(sid));
    }
    return channels;
  }

  static async getChannelBySid(sid: string): Promise<Channel> {
    await TwilioFacade.connectionReady();
    let alreadyInList = TwilioFacade.channelList.filter((ch) => ch.sid === sid);

    if (alreadyInList.length) {
      return alreadyInList[0];
    } else {
      let channel = await TwilioFacade.client.getChannelBySid(sid);
      TwilioFacade.setUpChannelEvents(channel);
      TwilioFacade.channelList.push(channel);
      return channel;
    }
  }

  static async sendMessage(
    channel: Channel,
    msg: string,
    attributes?: Object
  ): Promise<number> {
    await TwilioFacade.connectionReady();
    return await channel.sendMessage(msg, attributes);
  }

  static async createChannel(uniqueName: string, friendlyName: string) {
    await TwilioFacade.connectionReady();
    let channel = await TwilioFacade.client.createChannel({
      uniqueName,
      friendlyName,
    });

    TwilioFacade.setUpChannelEvents(channel);
    TwilioFacade.channelList.push(channel);

    return channel;
  }

  static dispatchMessage(msg: Message) {
    let model: IDialogueModel | undefined = resolveIdentifier(
      DialogueModel,
      RootStore.chatStore,
      msg.channel.sid
    );
    if (typeof model !== "undefined") {
      model.receiveMessage(
        msg.body,
        msg.index,
        parseInt(msg.author),
        msg.attributes
      );
      TwilioFacade.lastMessageCounter[msg.channel.sid] = msg.index;
    } else {
      handleError(
        new Error(
          "Could not resolve dialogue model with id, " + msg.channel.sid
        )
      );
    }
  }
  static pushToSequenceQueue(msg: Message) {
    if (TwilioFacade.lastMessageCounter[msg.channel.sid] !== 0) {
      if (msg.index - TwilioFacade.lastMessageCounter[msg.channel.sid] > 1) {
        // not in correct order
        TwilioFacade.sequenceQueue[msg.channel.sid][msg.index] = msg;
      } else if (
        msg.index - TwilioFacade.lastMessageCounter[msg.channel.sid] ===
        1
      ) {
        // correct order
        TwilioFacade.sequenceQueue[msg.channel.sid][msg.index] = msg;
        let currentIndex = msg.index;
        while (
          typeof TwilioFacade.sequenceQueue[msg.channel.sid][currentIndex] !==
          "undefined"
        ) {
          TwilioFacade.dispatchMessage(
            TwilioFacade.sequenceQueue[msg.channel.sid][currentIndex]
          );
          TwilioFacade.sequenceQueue[msg.channel.sid][currentIndex] = undefined;
          currentIndex++;
        }
      } else {
        // duplicate message
        return;
      }
    } else {
      TwilioFacade.dispatchMessage(msg);
    }
  }
  static setUpChannelEvents(channel: Channel) {
    channel.on("messageAdded", async (msg: Message) => {
      if (
        typeof TwilioFacade.sequenceQueue[msg.channel.sid as string] ===
        "undefined"
      ) {
        TwilioFacade.sequenceQueue[msg.channel.sid as string] = {};
      }
      if (
        typeof TwilioFacade.lastMessageCounter[msg.channel.sid as string] ===
        "undefined"
      ) {
        TwilioFacade.lastMessageCounter[msg.channel.sid] = 0;
      }

      if (TwilioFacade.msgDependencyResolver) {
        await TwilioFacade.msgDependencyResolver(msg);
      }
      TwilioFacade.pushToSequenceQueue(msg);
    });
    channel.on("typingStarted", (e) => {
      // console.log('typingStarted');
      // console.log(e);
    });
    channel.on("typingEnded", (e) => {
      // console.log('typingEnded');
      // console.log(e);
    });
    channel.on("memberUpdated", (member) => {
      // console.log('memberJoined');
      // console.log(e);
      // let model: IDialogueModel | undefined = resolveIdentifier(
      //     DialogueModel,
      //     RootStore.chatStore,
      //     channel.sid
      // );
      // model.updateConsumptionHorizon(
      //     member.identity,
      //     member.lastConsumedMessageIndex,
      //     member.lastConsumptionTimestamp
      // );
    });
    // channel.on("memberJoined", e => {
    //   // console.log('memberJoined');
    //   // console.log(e);
    // });
    // channel.on("memberLeft", e => {
    //   // console.log('memberLeft');
    //   // console.log(e);
    // });
  }
}

TwilioFacade.newOfferHandler = async (offerId: number) => {
  if (
    !RootStore.offerStore.offers.filter(
      (offer: IOfferModel) => offer.id === offerId
    ).length
  ) {
    // new offer
    try {
      let offer = await RootStore.offerStore.getOffer(offerId);
      notifyNewOffer(RootStore.offerStore.getExistingModel(offerId));
      (async () => {
        await offer.getDialogue().fetchChannel();
        await offer.getDialogue().channel.updateLastConsumedMessageIndex(0);
        await offer.getDialogue().fetchUnreadMessageCount();
      })();
    } catch (e) {
      ajaxErrorAlert("Failed to fetch the new offer. Refresh the page!");
      throw e;
    }
  }
};
