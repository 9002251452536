import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import classes from './style.module.scss';
import Logo from '../../../../../../../../assets/img/logo.png';
import OnboardSidebarSteps from './parts/steps';
import { withRouter } from 'react-router-dom';
import createAdContext from '../../../../../../createAd/context';
import RootStore from '../../../../../../../../store/RootStore';

function OnboardSidebar(props: any) {
    const { baseUrl } = useContext(createAdContext);
    const loc = props.location.pathname.split('/');

    return (
        <Grid
            container
            className={classes.sidebar}
            display={{ xs: 'none', lg: 'flex', sm: 'flex', md: 'flex' }}
            alignItems="center"
            flexDirection="row"
            padding={4}
        >
            <Grid item container flexDirection="row" rowSpacing={2}>
                <Grid item container className={classes.logo} rowSpacing={2} flexDirection="column">
                    <Grid item>
                        <img src={Logo} alt="aifluencer" />
                    </Grid>
                    <Grid item width={{ sm: '80%' }}>
                        <p>Follow these 6 basic steps to make the most of your Ainfluencer experience.</p>
                    </Grid>
                </Grid>

                <OnboardSidebarSteps />

                <Grid item container className={classes.buttons} justifyContent="space-between" marginTop={6}>
                    <Grid item>
                        <button
                            onClick={() => {
                                localStorage.clear();
                                RootStore.users.logOut();
                            }}
                        >
                            Sign out
                        </button>
                    </Grid>
                    {!loc.includes('mycompany') && !loc.includes('skip') ? (
                        <Grid item>
                            <button onClick={() => RootStore.router.push(`${baseUrl}/skip`)}>skip</button>
                        </Grid>
                    ) : (
                        ''
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withRouter(OnboardSidebar);
