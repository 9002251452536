import * as React from 'react';
// @ts-ignore
// import Stories from 'react-insta-stories';
import { observer } from 'mobx-react';
import RootStore from '../../../../../store/RootStore';
import { IUserModel } from '../../../../../store/models/UserModel';
import { Carousel } from 'react-responsive-carousel';

interface Props {
    content: any;
    screenWidth: number;
    screenHeight: number;
}

@observer
export default class StoryPreview extends React.Component<Props> {
    private user: IUserModel = RootStore.users.currentUser as IUserModel;

    render() {
        return (
            <div className={'story-preview-container'}>
                <Carousel
                    dynamicHeight={true}
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={true}
                    emulateTouch={true}
                >
                    {this.props.content?.medias.map((value, index, array) => (
                        <div key={'story_image_item_' + index}>
                            {value && value?.type?.startsWith('video/') ? (
                                <video
                                    controls={false}
                                    autoPlay={true}
                                    loop={true}
                                    muted={true}
                                    style={{ width: '100%', height: 'auto' }}
                                >
                                    <source src={value.url} type={value.file.type} />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img alt={'IG'} src={value.url} />
                            )}
                        </div>
                    ))}
                    {/* <Stories
              stories={stories}
              defaultInterval={1500}
              loop={true}
              width={this.props.screenWidth}
              height={this.props.screenHeight}
            /> */}
                </Carousel>
            </div>
        );
        // } else {
        //   return (
        //     <p style={{padding: '1em', textAlign: 'center'}}>
        //       In order to see the preview, add a story first!
        //     </p>
        //   );
        // }
    }
}
