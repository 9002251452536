import { IdentityModel } from './IdentityModel';
import { getSnapshot, IAnyModelType, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { UserModel } from './UserModel';
import { FileModel } from './FileModel';
import { MetricModel } from './MetricModel';
import { PivotModel } from './PivotModel';

import RootStore from '../RootStore';

export const IntegrationModel = types
    .model({
        id: types.identifierNumber,
        user_id: types.maybeNull(types.reference(types.late((): IAnyModelType => UserModel))),
        identities: types.maybeNull(types.array(types.reference(types.late((): IAnyModelType => IdentityModel)))),
        platform: types.enumeration(['instagram']),
        type: types.enumeration(['personal', 'business', 'creator', 'unknown']),
        username: types.string,
        full_name: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
        last_health_check: types.maybeNull(types.string),
        healthy: types.boolean,
        country_id: types.maybeNull(types.string),
        state_id: types.maybeNull(types.string),
        city_id: types.maybeNull(types.string),
        picture: types.maybeNull(FileModel),
        metric: types.maybeNull(MetricModel),
        created_at: types.maybeNull(types.string),
        updated_at: types.maybeNull(types.string),
        deleted_at: types.maybeNull(types.string),
        authenticated: types.maybeNull(types.boolean),
        pivot: types.maybeNull(PivotModel),
        engagement_rate: types.number,
        quality_score: types.maybeNull(types.number),
        followers: types.maybeNull(types.number),
        followings: types.maybeNull(types.number),
    })
    .actions((self) => ({

        /**
         * this method set Authenticated
         */
        setAuthenticated() {
            self.authenticated = true;
        },

        /**
         * this method set Healthy
         */
        setHealthy() {
            self.healthy = true;
        },
    }))
    .views((self) => ({

        /**
         * this method check has Error
         */
        hasError(): boolean {
            return !self.healthy;
        },

        /**
         * this method get Thumbnail
         * @param width, this param width Thumbnail
         * @param height, this param height Thumbnail
         */
        getThumbnail(width: number, height: number) {
            return self.picture.getThumbnailUrl(width, height);
        },

        /**
         * this method get Instagram Url
         */
        getInstagramUrl(): string {
            return `https://www.instagram.com/${self.username}/`;
        },
        isSelf(): boolean {
            return RootStore.users.currentUser.id === getSnapshot(self).user_id;
        },
    }));

export interface IIntegrationModel extends Instance<typeof IntegrationModel> { }

export interface IIntegrationModelSnapshotIn extends SnapshotIn<typeof IntegrationModel> { }

export interface IIntegrationModelSnapshotOut extends SnapshotOut<typeof IntegrationModel> { }
