import { IOfferModel } from './OfferModel';
import { IInvitationModel } from './InvitationModel';
import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { ContentModel } from './ContentModel';
import { CampaignModel } from './CampaignModel';
import { ITimeTableModel, TimeTableModel } from './TimeTableModel';
import { SettingsModel } from './SettingsModel';
import { arrayToText } from '../../lib/Utils';
import moment from 'moment';
import RootStore from '../RootStore';

/**
 * this model ads
 */
export const AdModel = types
    .model({
        id: types.identifierNumber,
        title: types.string,
        description: types.string,
        bio_link: types.maybeNull(types.string),
        duration: types.maybeNull(types.number),
        exclusivity: types.maybeNull(types.number),
        impressions: types.maybeNull(types.number),
        content_id: types.maybeNull(types.reference(ContentModel)),
        approval: types.enumeration(['pending', 'approved', 'rejected']),
        status: types.enumeration(['approval', 'active', 'expired', 'paused', 'draft']),
        campaign_id: types.reference(CampaignModel),
        time_tables: types.maybe(types.array(TimeTableModel)),
        schedule: types.maybe(types.array(TimeTableModel)),
        deleted_at: types.maybeNull(types.string),
        invite_only: types.boolean,
        created_at: types.maybeNull(types.string),
        updated_at: types.maybeNull(types.string),
        rejection_reason: types.maybeNull(types.string),
        settings: types.maybeNull(SettingsModel),
        limit_country: types.maybeNull(types.array(types.string)),
        limit_gender: types.maybeNull(types.string),
        dollars_spent: types.maybeNull(types.number),
        invitation_count: types.maybeNull(types.number),
        offer_count: types.maybeNull(types.number),
        paid_offers_count: types.maybeNull(types.number),
        fixedDuration: types.maybeNull(types.number),
        invitations_count: types.maybeNull(types.number),
        feature: types.maybeNull(types.number),
    })
    .actions((self) => ({}))
    .views((self) => ({
        /**
         * this method get ScheduleText ad
         */
        getScheduleText(): string {
            if (self.time_tables != null) {
                if (self.time_tables.length === 0) {
                    return 'Open For Negotiation';
                }
                return arrayToText(self.time_tables, (model: ITimeTableModel) => {
                    if (model.end) {
                        return (
                            moment(model.start).format('MMMM Do YYYY, h:mm a') +
                            ' - ' +
                            moment(model.end).format('MMMM Do YYYY, h:mm a')
                        );
                    } else {
                        return moment(model.start).format('MMMM Do YYYY, h:mm a');
                    }
                });
            }
            // eslint-disable-next-line no-throw-literal
            throw 'No timetable field specified for this model';
        },
        // getExclusivityText(): string {
        //   if (self.exclusivity) {
        //     return "Exclusive for " + moment.duration(self.exclusivity, "seconds").humanize();
        //   } else {
        //     return "Open For Negotiation";
        //   }
        // },

        /**
         * this method get BioLink Text
         */
        getBioLinkText(): string {
            if (self.bio_link) {
                return 'Yes';
            }
            return 'No';
        },

        /**
         * this method get Duration Text
         */
        getDurationText(): any {
            if (self.duration) {
                if (self.duration === 0) {
                    return 'Permanent';
                }
                return moment.duration(self.duration, 'seconds').humanize();
            } else {
                return 'Open For Negotiation';
            }
        },

        /**
         * this method get Total Offers
         */
        getTotalOffers(): number {
            return RootStore.offerStore.getOffersByAd(self.id).length;
        },

        /**
         * this method get Total Deals
         */
        getTotalDeals(): number {
            return RootStore.offerStore.getOffersByAd(self.id).filter((offer: IOfferModel) => offer.status === 'paid')
                .length;
        },

        /**
         * this method get Total Invoice Amount
         */
        getTotalInvoiceAmount(): number {
            let offers = RootStore.offerStore
                .getOffersByAd(self.id)
                .filter((offer: IOfferModel) => offer.status === 'awaiting-payment');
            let sum = 0;
            for (let offer of offers) {
                sum += offer.price;
            }
            return sum;
        },

        /**
         * this method get Total Invitations
         */
        getTotalInvitations(): number {
            return self.invitation_count;
        },

        /**
         * this method get Invitations List
         */
        getInvitationsList(): IInvitationModel[] {
            return RootStore.invitationStore.invitations.filter(
                (invitation: IInvitationModel) => invitation.ad_id.id === self.id,
            );
        },

        /**
         * this method get Spent Dollars
         */
        getSpentDollars(): number {
            const paidOffers = RootStore.offerStore.offers.filter((offer: IOfferModel) => {
                return offer.ad_id.id === self.id && offer.status === 'paid';
            });

            return paidOffers.reduce((sum: number, offer: IOfferModel) => {
                return (sum += offer.price);
            }, 0);
        },

        /**
         * this method get Ad Status
         */
        getAdStatus(): string {
            switch (self.status) {
                case 'active':
                    return 'Active';
                case 'expired':
                    return 'Expired';
                case 'approval':
                    return 'Approved';
                case 'draft':
                    return 'draft';
                case 'paused':
                    return 'Paused';
                default:
                    return 'Approved';
            }
        },
    }));

export interface IAdModel extends Instance<typeof AdModel> {}

export interface IAdModelSnapshotIn extends SnapshotIn<typeof AdModel> {}

export interface IAdModelSnapshotOut extends SnapshotOut<typeof AdModel> {}
