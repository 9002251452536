import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { NarrowArrowIcon } from '../../../../../../../assets/icons';
import API from '../../../../../../../lib/axiosSetup';
import RootStore from '../../../../../../../store/RootStore';
import OnboardPreview from '../../../../../dashboard/multi-step-form/onboarding/parts/preview';
import createAdContext from '../../../../context';
import classes from './style.module.scss';
import { momentDateTimeFormat } from '../../../../../../../lib/Utils';
import moment from 'moment';

function PublishTermsConfirm(props: any) {
    const {
        baseUrl,
        oldData,
        data: {
            adID,
            terms: {
                fixedSchedule,
                fixedDuration,
                schedule,
                displayBioLink,
                bio_link,
                duration,
                duration_segmentation: { segment_name, segment_time },
            },
        },
    } = useContext(createAdContext);
    const [Process, setProcess] = useState(false);

    const submitHandle = () => {
        const data = {
            fixed_duration: fixedDuration,
            duration_segmentation:
                fixedDuration === 2
                    ? {
                          segment_name: segment_name,
                          segment_time: segment_time,
                      }
                    : null,
            display_bio_link: displayBioLink ? true : false,
            fixed_schedule: fixedSchedule,
            schedule: schedule || [],
            ...(displayBioLink && { bio_link: bio_link }),
        };
        if (oldData?.bio_link && !oldData?.bio_link?.includes('http')) {
            oldData.bio_link = `http://${oldData?.bio_link}`;
        }
        if (data?.bio_link && !data?.bio_link.includes('http')) {
            data.bio_link = `http://${data?.bio_link}`;
        }

        if (oldData) {
            const old = {
                fixed_duration: oldData.duration === null ? 0 : oldData.duration === 0 ? 1 : 2,
                duration_segmentation:
                    fixedDuration === 2
                        ? {
                              segment_name: oldData.duration_segmentation.segment_name || 'hours',
                              segment_time: oldData.duration_segmentation.segment_time || 1,
                          }
                        : null,
                display_bio_link: oldData.bio_link ? true : false,
                fixed_schedule: oldData.schedules.length ? 1 : 0,
                schedule: oldData.schedules
                    ? oldData.schedules.map((el: any) => {
                          return {
                              end: moment(el.end, momentDateTimeFormat),
                              id: null,
                              start: moment(el.start, momentDateTimeFormat),
                          };
                      })
                    : null,
                ...(oldData.bio_link && { bio_link: oldData.bio_link }),
            };

            if (JSON.stringify(data) === JSON.stringify(old))
                return RootStore.router.push(`${baseUrl}/discover/${adID}`);
        }

        setProcess(true);
        API.post(`v2/ads/${adID}/publish-terms`, {
            duration: fixedDuration === 2 ? duration : fixedDuration === 0 ? null : 0,
            ...data,
        })
            .then((res) => {
                setProcess(false);
                RootStore.router.push(`${baseUrl}/discover/${adID}`);
            })
            .catch(function (error) {
                setProcess(false);
                if (error.response?.data?.message) {
                    Swal.fire({
                        type: 'error',
                        text: error.response.data.message,
                        showCancelButton: true,
                        showConfirmButton: true,
                    });
                }
            });
    };

    return (
        <Grid container sm={8} alignItems="center" margin="auto">
            <Grid item sm={8} xs={12} container flexDirection="column" rowSpacing={3}>
                <Grid item textAlign="center" sm={11} margin="auto" fontSize={25}>
                    <div className="title">Your Ad now requires administrator approval.</div>
                    <p>You can make edits now, or click Save & Next to browse influencers.</p>
                </Grid>
                <Grid item container className={classes.button} justifyContent="center" columnSpacing={2}>
                    <Grid item>
                        <Button
                            color="secondary"
                            onClick={() => RootStore.router.push(`${baseUrl}/marketplace/${adID}`)}
                        >
                            Edit
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="success" disabled={Process} onClick={submitHandle}>
                            {Process ? (
                                'Processing ...'
                            ) : (
                                <span>
                                    Save & Next <NarrowArrowIcon className="ml-2" />
                                </span>
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={4} xs={12} container justifyContent="center">
                <Grid item>
                    <OnboardPreview />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withRouter(PublishTermsConfirm);
