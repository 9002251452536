import React, { Component } from "react";
import { StepProp } from "../index";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FormGroup } from "reactstrap";
import {
  ReactSelectMulti,
  FormikReactSelect,
  PasswordField,
} from "../../common-components";
import ValidationCodeInput from "./ValidationCodeInput";
import { ajaxErrorAlert, handleError, showLoading } from "../../../lib/Utils";
import RootStore from "../../../store/RootStore";
import { ICountryModel } from "../../../store/models/CountryModel";
import { Link } from "react-router-dom";
import { Spinner, Button } from "reactstrap";
import { RouteComponentProps } from "react-router";
import Swal from "sweetalert2";
import { AppStoreBtnIcon, GooglePlayBtnIcon } from "../../../assets/icons";
import { isStaging } from "../../../constants/defaultValues";
import Transport from "../../../lib/Transport";
import { CountriesList } from './../../../constants/countriesList' 

interface FormikValues {
  first_name: string;
  last_name: string;
  email: string;
  instagram: string;
  country: { label: string; value: string };
  password: string;
  password_confirmation: string;
  categories: { label: string; value: string }[];
}
interface EmailFormikValue {
  email: string;
}

const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string().required("First name can't be empty"),
  last_name: Yup.string().required("Last name can't be empty"),
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Email can't be empty"),
  instagram: Yup.string().required("Instagram account can't be empty"),
  country: Yup.string().required("Country account can't be empty"),
  password: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password_confirmation: Yup.string()
    .required("Confirm your new password")
    .oneOf([Yup.ref("password"), null], "Password must match"),
  categories: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .required("Categories can't be empty"),
});
const ChangeEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Email can't be empty")
    .nullable(),
});
interface Props extends StepProp, RouteComponentProps<{ id: string }> {
  onStepChange: () => void;
  user: User;
  channel: string;
  ad: string;
  Adstatus: string | null;
}
interface User {
  firstName: string;
  lastName: string;
  email: string | null;
  userName: string | null;
  categories: { label: string; value: string }[];
  country: { name: string; id: string; code: string };
  identity_id: number;
  invited_via: string | null;
}
interface State {
  buttonActive: boolean;
  verificationCode: string;
  token: any;
  userId: number | null;
  email: string | null;
  user: User;
}

export default class InvitationWizard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: this.props.user,
      buttonActive: false,
      verificationCode: "",
      token: JSON.parse(localStorage.getItem("token")) || {},
      userId: JSON.parse(localStorage.getItem("userId")) || null,
      email: JSON.parse(localStorage.getItem("email")) || this.props.user.email,
    };
    this.onValidationCodeComplete = this.onValidationCodeComplete.bind(this);
    this.onValidationCodeInComplete =
      this.onValidationCodeInComplete.bind(this);
    this.onVerificationChange = this.onVerificationChange.bind(this);
    this.setInitialValues = this.setInitialValues.bind(this);
    this.AppVisit = this.AppVisit.bind(this);
  }

  onValidationCodeComplete(): void {
    this.setState({ buttonActive: true });
  }

  onValidationCodeInComplete(): void {
    this.setState({ buttonActive: false });
  }

  onVerificationChange(code: string): void {
    this.setState({ verificationCode: code });
  }

  componentDidMount() {
    RootStore.fetchStaticData()
  }

  async submitVerificationCode(): Promise<void> {
    if (this.state.buttonActive) {
      try {
        let verificationCode = this.state.verificationCode;
        if (verificationCode.length === 5) {
          showLoading();
          await RootStore.users.verifyEmailAddress(
            this.state.verificationCode,
            this.state.token,
            false
          );

          Swal.close();
        }
        localStorage.clear();
        RootStore.router.push(`/invitation/${this.props.match.params.id}/4`);
        await Transport.get(
          "invitations/changestatus/" + this.props.match.params.id + "/Verified"
        );
      } catch (error) {
        handleError(error);
        this.setState({
          verificationCode: "",
        });
        document.getElementById("VCI_0").focus();
        ajaxErrorAlert("There was a problem with verify your code.");
      }
    }
  }
  setEmailInitialValue(): EmailFormikValue {
    return {
      email: this.props.user.email,
    };
  }
  setInitialValues(): FormikValues {
    return {
      first_name: this.props.user.firstName ? this.props.user.firstName : "",
      last_name: this.props.user.lastName ? this.props.user.lastName : "",
      email: this.props.user.email ? this.props.user.email : "",
      instagram: this.props.user.userName ? this.props.user.userName : "",
      categories: this.props.user.categories,
      country: this.props.user.country
        ? {
            label: this.props.user.country.name,
            value: this.props.user.country.id,
          }
        : { label: "", value: "" },
      password: "",
      password_confirmation: "",
    };
  }
  async onSubmit(values: FormikValues, formikActions: any): Promise<void> {
    try {
      const response = await RootStore.users.register({
        ...values,
        identity_id: this.state.user.identity_id,
        invited_via: this.props.user.invited_via,
        type: "influencer",
      });
      if (!isStaging()) {
        // @ts-ignore
        ga('send', {
          hitType: 'event',
          eventCategory: 'Influencer',
          eventAction: 'SignUp',
          eventLabel: 'Web invite',
        });
      }

      formikActions.setSubmitting(true);
      this.setState({
        token: response.data.user.token,
        userId: response.data.user.id,
        email: response.data.user.email,
      });
      localStorage.setItem("token", JSON.stringify(this.state.token));
      localStorage.setItem("userId", JSON.stringify(this.state.userId));
      localStorage.setItem("email", JSON.stringify(this.state.email));
      formikActions.setSubmitting(false);
      this.setState({ email: values.email });
      RootStore.router.push(`/invitation/${this.props.match.params.id}/3`);
      await Transport.get(
        "invitations/changestatus/" + this.props.match.params.id + "/SignedUp"
      );
      await Transport.get("invitations/set_invitationid_user/" + this.props.match.params.id + "/" + response.data.user.id)
      // this.props.onStepChange();
    } catch (e) {
      formikActions.setSubmitting(false);
      if (e.response.status === 422) {
        formikActions.setSubmitting(false);
        Swal.fire({
          type: "error",
          title: "Already Registered!",
          text:
            "Looks like someone has already registered with this email address (" +
            values.email +
            ")! Please choose another one.",
        });
      } else {
        handleError(e);
        ajaxErrorAlert("There was a problem creating the campaign...");
        throw e;
      }
    }
  }
  async onSubmitChangeEmail(
    values: EmailFormikValue,
    formikActions: any
  ): Promise<void> {
    try {
      formikActions.setSubmitting(true);
      const response = await RootStore.users.changeEmail(
        this.state.userId,
        values.email,
        this.state.token
      );
      this.setState({ email: response.data.user.email });
      localStorage.setItem("email", this.state.email);
      await RootStore.users.resendVerificationEmail(
        this.state.token.access_token
      );
      this.setState({ email: values.email });
      RootStore.router.push(`/invitation/${this.props.match.params.id}/3`);
      formikActions.setSubmitting(false);
    } catch (e) {
      formikActions.setSubmitting(false);
      handleError(e);
      ajaxErrorAlert("There was an error changing the email");
      throw e;
    }
  }

  async resendActivationCode(): Promise<void> {
    try {
      let response = await RootStore.users.resendVerificationEmail(
        this.state.token.access_token
      );
      if (response.status === 200) {
        await Swal.fire({
          type: "success",
          title: "",
          text: "The activation code has been sent to your email",
        });
      }
    } catch (error) {
      handleError(error);
      ajaxErrorAlert("There was a problem resending verification code...");
    }
  }

  async AppVisit() {
    await Transport.get(
      "invitations/changestatus/" + this.props.match.params.id + "/AppVisit"
    );
  }
  renderSteps(): JSX.Element {
    if (this.props.step === 1) {
      return (
        <>
          <div className="step step1">
            <div className="logo"></div>
            <div className="title">
              Hi {this.props.user.firstName}, Good news!
            </div>
            {this.props.Adstatus === "expired" || !this.props.ad ? (
              <>
                <p>Sorry, this invitation or campaign link has expired.</p>
                <p>
                  Create your free Ainfluencer account to browse hundreds of Ads
                  from premium brands
                </p>
                <Link to={`/invitation/${this.props.match.params.id}/2`}>
                  <Button>Create My Account</Button>
                </Link>
              </>
            ) : (
              <>
                <p>
                  You have been invited to collaborate with{" "}
                  <span className="channel">{this.props.channel} </span>to
                  Promote their Ad: <span className="ad">{this.props.ad}</span>
                </p>
                <p>
                  It seems you don't have a free influencer account on
                  Aifluencer. Create an account to view Ad's details and get
                  started
                </p>
                <Link to={`/invitation/${this.props.match.params.id}/2`}>
                  <Button>Create My Account</Button>
                </Link>
                <p>
                  A company found your profile on Ainfluencer and thought that
                  you would make a great fit for their brand. Create a free
                  account to learn more about this offer, chat with the brand,
                  and make a deal.
                </p>
              </>
            )}
          </div>
        </>
      );
    }

    if (this.props.step === 2) {
      return (
        <>
          <div className="step step2">
            <div className="logo"></div>
            <div className="title">Create your Aifluencer account</div>
            {this.props.user.firstName === "" ? (
              <React.Fragment>
                <div>
                  <Spinner color="primary" className="mb-1" />
                  <p>Please wait...</p>
                </div>
              </React.Fragment>
            ) : (
              <Formik
                initialValues={this.setInitialValues()}
                validationSchema={PersonalInfoSchema}
                onSubmit={this.onSubmit.bind(this)}
              >
                {({
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isSubmitting,
                }) => (
                  <Form>
                    <FormGroup>
                      <label htmlFor="first_name">First Name</label>
                      <Field
                        name="first_name"
                        placeholder="First Name"
                        disabled={isSubmitting}
                        onChange={handleChange}
                      ></Field>
                      {errors.first_name && touched.first_name ? (
                        <div className="invalid-feedback d-block">
                          {errors.first_name}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="last_name">Last Name</label>
                      <Field
                        name="last_name"
                        placeholder="Last Name"
                        disabled={isSubmitting}
                        onChange={handleChange}
                      ></Field>
                      {errors.last_name && touched.last_name ? (
                        <div className="invalid-feedback d-block">
                          {errors.last_name}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="email">Email Address</label>
                      <Field
                        name="email"
                        placeholder="Email"
                        disabled={isSubmitting}
                        onChange={handleChange}
                        autoComplete="off"
                        value={values.email}
                        // handleChange={(this.props.user.email = values.email)}
                      ></Field>
                      {errors.email && touched.email ? (
                        <div className="invalid-feedback d-block">
                          {errors.email}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="instagram">Instagram Account</label>
                      <Field
                        name="instagram"
                        placeholder="Instagram Account"
                        onChange={handleChange}
                        disabled={true}
                      ></Field>
                      {errors.instagram && touched.instagram ? (
                        <div className="invalid-feedback d-block">
                          {errors.instagram}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="categories">Categories</label>
                      <ReactSelectMulti
                        name="categories"
                        id="reactSelect"
                        value={values.categories}
                        // isMulti={true}
                        options={RootStore.getCategorySelectOptions()}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                      {errors.categories && touched.categories ? (
                        <div className="invalid-feedback d-block">
                          {errors.categories}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="country">Country</label>
                      <FormikReactSelect
                        name="country"
                        id="onboarding_country"
                        value={values.country}
                        options={CountriesList.map(
                          (country: ICountryModel) => {
                            return { label: country.name, value: country.id };
                          }
                        )}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                      {errors.country && touched.country ? (
                        <div className="invalid-feedback d-block">
                          {errors.country}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="password">Password</label>
                      <PasswordField
                        name="password"
                        placeholder="Password"
                        onChange={setFieldValue}
                      />
                      {errors.password && touched.password ? (
                        <div className="invalid-feedback d-block">
                          {errors.password}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="password_confirmation">
                        Password Confirmation
                      </label>
                      <PasswordField
                        name="password_confirmation"
                        placeholder="Password Confirmation"
                        onChange={setFieldValue}
                      />
                      {errors.password_confirmation &&
                      touched.password_confirmation ? (
                        <div className="invalid-feedback d-block">
                          {errors.password_confirmation}
                        </div>
                      ) : null}
                    </FormGroup>

                    <Button type="submit" disabled={isSubmitting}>
                      Create My Account
                    </Button>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </>
      );
    }

    if (this.props.step === 3) {
      return (
        <>
          <div className="step step3">
            <div className="logo"></div>
            <div className="title">Verify Your Email</div>
            <div className="description">A 5-digit code has been sent to </div>
            <div className="email">
              {this.state.email}{" "}
              <span
                onClick={() =>
                  RootStore.router.push(
                    `/invitation/${this.props.match.params.id}/5`
                  )
                }
              >
                Change
              </span>
            </div>
            <div className="input">
              <ValidationCodeInput
                onValidationComplete={this.onValidationCodeComplete}
                onValidationInComplete={this.onValidationCodeInComplete}
                onVerificationChange={this.onVerificationChange}
              />
            </div>

            <Button
              className={`${this.state.buttonActive ? "activeBtn" : "disable"}`}
              onClick={() => this.submitVerificationCode()}
            >
              Verify
            </Button>
            <div className="resendCode">
              Didn't get the code?
              <span onClick={this.resendActivationCode.bind(this)}>
                Resend code
              </span>
            </div>
          </div>
        </>
      );
    }
    if (this.props.step === 4) {
      return (
        <>
          <div className="step step4">
            <div className="logo"></div>
            <div className="title">Success!</div>
            <div className="description">
              Your Aifluencer account has been created.
            </div>
            <div className="description">
              Download the app to complete the registration process
            </div>

            <div className="buttons" onClick={this.AppVisit}>
              <a
                href="https://play.google.com/store/apps/details?id=com.ainfluencer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="button">
                  <GooglePlayBtnIcon />
                </div>
              </a>
              <a
                href="https://apps.apple.com/us/app/id1525128818"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="button">
                  <AppStoreBtnIcon />
                </div>
              </a>
            </div>
            <div className="reason">
              <div className="title">Why do I need the app?</div>
              <div className="description">
                Ainfluencer provides a rich chat and post scheduling <br />
                interface to help brands and influencers successfully <br />
                run campaigns. the app also let you set up payment <br />
                preferences.
              </div>
            </div>
          </div>
        </>
      );
    }
    if (this.props.step === 5) {
      return (
        <>
          <div className="step step5">
            <div className="logo"></div>
            <div className="title">Change Email</div>
            <div className="description">
              Please enter a new email to recive the verification code
            </div>
            <Formik
              initialValues={this.setEmailInitialValue()}
              validationSchema={ChangeEmailSchema}
              onSubmit={this.onSubmitChangeEmail.bind(this)}
            >
              {({
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form>
                  <FormGroup>
                    <Field
                      name="email"
                      placeholder="Email"
                      disabled={isSubmitting}
                      // handleChange={}
                    ></Field>
                    {errors.email && touched.email ? (
                      <div className="invalid-feedback d-block">
                        {errors.email}
                      </div>
                    ) : null}
                  </FormGroup>
                  <Button
                    className={`${
                      this.state.email === values.email
                        ? "disable"
                        : "activeBtn"
                    }`}
                    type="submit"
                  >
                    Send Verification Code
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </>
      );
    }
  }

  render() {
    return <div className="wizard">{this.renderSteps()}</div>;
  }
}
