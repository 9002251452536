import React from 'react'
import classes from './style.module.scss'

interface IProps {
    children: any
}
function OnboardCard(props: IProps) {
    const { children } = props
    return (
        <div className={classes.card}>
           {children}
        </div>
    )
}

export default OnboardCard
