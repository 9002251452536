import React, { useContext } from "react";
import { Grid } from "@mui/material";
import { CustomInput, Label } from 'reactstrap'
import { InformationIcon } from "../../../../../../../../../assets/icons";
import classes from '../../style.module.scss';
import ReactTooltip from 'react-tooltip';
import ScheduleSelectFormik from "../../../../../../../ads/components/ScheduleSelectFormik";
import PublishTermsFormContext from "../../context";

function DatePublish() {
    const { setFieldValue, values, updateTerms } = useContext(PublishTermsFormContext)

    return (
        <Grid container item>
            <Grid item>
                <Label for="exCustomRadio4" className={classes.label}>
                    What date would you like your Ad published on?
                    <InformationIcon data-tip data-for="AdDate22" className="specificLabel" />
                    <ReactTooltip id="AdDate22" type="dark" effect="solid">
                        <p style={{ width: '200px' }}>
                            Suggest a specific date and time if desired. This can be further
                            edited during the offer process.
                        </p>
                    </ReactTooltip>
                </Label>
                <Grid item className={classes.content} flexDirection="column">
                    <Grid item>
                        <CustomInput
                            type="radio"
                            id="exCustomRadio4"
                            name="fixedSchedule"
                            label="I don't have any limitations on when influencers can post it (open for negotiation)"
                            value={0}
                            checked={!values?.fixedSchedule}
                            onChange={(e) => {
                                setFieldValue('fixedSchedule', false);
                                updateTerms('fixedSchedule', false)
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomInput
                            type="radio"
                            id="exCustomRadio5"
                            name="fixedSchedule"
                            label="I want to set a specific date range for when the post is published"
                            value={1}
                            // @ts-ignore
                            checked={values?.fixedSchedule}
                            onChange={(e) => {
                                setFieldValue('fixedSchedule', true);
                                updateTerms('fixedSchedule', true)
                            }}
                        />
                    </Grid>
                    {values.fixedSchedule ?
                        <Grid item container flexDirection="column" rowSpacing={1} marginTop={1}>
                            <Grid item>
                                <strong>Set a date range of when this Ad can be published:</strong>
                            </Grid>
                            <Grid item>
                                <ScheduleSelectFormik
                                    onChange={(name: string, value: any) => {
                                        setFieldValue(name, value);
                                        updateTerms('schedule', value.map((el: any) => {
                                            return {
                                                end: el.end ? el.end : el.start,
                                                id: null,
                                                start: el.start
                                            }
                                        }))
                                    }}
                                    name="schedule"
                                    value={values?.schedule}
                                />
                            </Grid>
                        </Grid>
                        : ''
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DatePublish
