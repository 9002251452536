import * as React from "react";
import { FormikValues } from "formik";
import { FormGroup, Row } from "reactstrap";
import * as moment from "moment";

interface Props extends FormikValues {
  name: string;
  gridRatio?: number;
  value?: number;
  className?: string;
}

type DurationType =
  // | "seconds"
  // | "minutes" |
  "hours" | "days" | "weeks" | "months";

const durationMap: any[] = [
  [
    "months",
    moment
      .duration({
        months: 1,
      })
      .asSeconds(),
  ],
  ["weeks", 7 * 24 * 60 * 60],
  ["days", 24 * 60 * 60],
  ["hours", 60 * 60],
  // ["minutes", 60],
  // ["seconds", 1]
];

interface State {
  duration_type: DurationType;
  duration_coefficient: number;
}

export class FormikDuration extends React.Component<Props, State> {
  static defaultProps = {
    gridRatio: 2,
    value: 24 * 60 * 60,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      duration_coefficient: 0,
      duration_type: "days",
    };
    let found_coefficient = false;
    for (let tuple of durationMap) {
      if (props.value === 0) {
        found_coefficient = true;
        this.state = {
          duration_coefficient: 0,
          duration_type: 'days',
        };
        this.syncValue();
        break;
      } else if (Number.isInteger(props.value / tuple[1])) {
        found_coefficient = true;
        this.state = {
          duration_coefficient: props.value / tuple[1],
          duration_type: tuple[0],
        };
        this.syncValue();
        break;
      }
    }

    if (!found_coefficient) {
      this.syncValue();
      console.warn("Invalid duration! " + props.value);
    }
  }

  syncValue() {
    this.props.onChange(this.props.name, this.getValueInSeconds());
  }

  coefficientChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState(
      {
        duration_coefficient: parseInt(e.target.value),
      },
      () => {
        this.syncValue();
      }
    );
  }

  typeChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState(
      {
        duration_type: e.target.value as DurationType,
      },
      () => {
        this.syncValue();
      }
    );
  }

  getValueInSeconds(): number {
    return FormikDuration.calculateSeconds(
      this.state.duration_coefficient,
      this.state.duration_type
    );
  }

  static calculateSeconds(
    duration_coefficient: number,
    duration_type: DurationType
  ) {
    return moment.duration(duration_coefficient, duration_type).asSeconds();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.value && prevProps.value !== this.props.value) {
      console.log(this.state);
      for (let tuple of durationMap) {
        if (Number.isInteger(this.props.value / tuple[1])) {
          this.setState({
            duration_coefficient: this.props.value / tuple[1],
            duration_type: tuple[0],
          });
          break;
        }
      }
    }
  }

  render() {
    return (
      <Row
        className={`${this.props.className} w-100 duration-control d-flex justify-content-between`}
      >
        <FormGroup className="w-50 mb-0">
          <input
            value={this.state.duration_coefficient}
            type="number"
            min={0}
            disabled={this.props.disabled}
            className="form-control w-100"
            name="duration_coefficient"
            onChange={this.coefficientChanged.bind(this)}
            onBlur={this.props.onBlur}
          />
        </FormGroup>

        <FormGroup className="w-45 mb-0">
          <select
            className="form-control w-100"
            name="duration_type"
            onChange={this.typeChanged.bind(this)}
            onBlur={this.props.onBlur}
            value={this.state.duration_type}
            disabled={this.props.disabled}
          >
            <option>hours</option>
            <option>days</option>
            <option>weeks</option>
            <option>months</option>
          </select>
        </FormGroup>
      </Row>
    );
  }
}
