import { observer } from "mobx-react";
import React, { Component } from "react";
import moment from "moment";
import { Col, Row, Spinner } from "reactstrap";
import RootStore from "../../../../store/RootStore";
import FilterComponent from "../../../common-components/Filter/FilterComponent";
import { debounce } from "lodash";
import ServerSideFilterSet from "../../../common-components/Filter/ServerSideFilterSet";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Bar } from "react-chartjs-2";
import {
  FilterType,
  Range,
} from "../../../common-components/Filter/AbstractFilterSet";
import {
  SortOrder,
  QueryOperator,
  FilterGroup,
} from "../../../../lib/QueryTypes";

interface Props {}
interface State {
  disablePage: boolean;
  sort: { key: string; direction: SortOrder };
  dataFetched: boolean;
  updateLists: boolean;
  filter_groups: FilterGroup[];
  publishJobList: any;
  labels: any;
  pagination: {
    totalPages: number;
    page: number;
    pageSize: number;
    totalCount: number;
  };
}

type Filters = {
  filters: any;
};
@observer
export default class PublishJobsChart extends Component<Props, State> {
  private readonly filterSet: ServerSideFilterSet;
  constructor(props: Props) {
    super(props);

    this.state = {
      disablePage: false,
      labels: [],
      sort: {
        key: "start",
        direction: SortOrder.DESCENDING,
      },
      publishJobList: [],
      filter_groups: [
        {
          isOr: false,
          filters: [
            {
              key: "manual",
              value: null,
              operator: QueryOperator.STRING_CONTAINS,
              not: false,
            },
          ],
        },
        {
          isOr: false,
          filters: [
            {
              key: "deleted_at",
              value: "null",
              operator: null,
              not: false,
            },
          ],
        },
        {
          isOr: false,
          filters: [
            {
              key: "start",
              value: [
                moment().subtract(7, "day").format("YYYY-MM-DD HH:mm:ss"),
                moment().format("YYYY-MM-DD HH:mm:ss"),
              ],
              operator: QueryOperator.BETWEEN,
              not: false,
            },
          ],
        },
      ],
      dataFetched: false,
      updateLists: false,
      pagination: {
        page: 0,
        pageSize: 1000,
        totalPages: 0,
        totalCount: 0,
      },
    };

    this.filterSet = new ServerSideFilterSet(debounce(this.onChange, 600));

    this.filterSet.addFilter({
      name: "manual",
      title: "Type",
      defaultValue: null,
      type: FilterType.simpleFilter,
      values: () => [null, "manual", "auto"],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value && typeof value === "string") {
          return value;
        } else {
          return "All";
        }
      },
    });

    this.filterSet.addFilter({
      name: "start_custom_range",
      title: "Start date range",
      defaultValue: null,
      type: FilterType.rangeFilter,
      values: (value: Range) => [{ start: value.start, end: value.end }, null],
    });

    this.onChange = this.onChange.bind(this);
    this.fetchJobs = this.fetchJobs.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  async componentDidMount() {
    await this.fetchJobs();
    this.setState({
      publishJobList: RootStore.publishJobStore.getAlljobsAdmin(),
    });
  }
  componentWillUnmount() {
    RootStore.publishJobStore.clear();
  }

  setFilters(filters: Filters) {
    let filterGroup = [];
    filterGroup.push({
      isOr: false,
      filters: [
        {
          key: "deleted_at",
          value: "null",
          operator: null,
          not: false,
        },
      ],
    });

    if (filters.filters.start_custom_range) {
      filterGroup.push({
        isOr: false,
        filters: [
          {
            key: "start",
            value: [
              moment(filters.filters.start_custom_range.start).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              moment(filters.filters.start_custom_range.end).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            ],
            operator: QueryOperator.BETWEEN,
            not: false,
          },
        ],
      });
    } else {
      filterGroup.push({
        isOr: false,
        filters: [
          {
            key: "start",
            value: [
              moment().subtract(7, "day").format("YYYY-MM-DD HH:mm:ss"),
              moment().format("YYYY-MM-DD HH:mm:ss"),
            ],
            operator: QueryOperator.BETWEEN,
            not: false,
          },
        ],
      });
    }

    if (filters.filters.manual) {
      filterGroup.push({
        isOr: false,
        filters: [
          {
            key: "manual",
            value: filters.filters.manual === "manual",
            operator: QueryOperator.EQUALS,
            not: false,
          },
        ],
      });
    }

    this.setState({
      pagination: {
        page: 0,
        totalPages: this.state.pagination.totalPages,
        pageSize: this.state.pagination.pageSize,
        totalCount: this.state.pagination.totalCount,
      },
      filter_groups: filterGroup,
      sort: {
        key: "start",
        direction: filters.filters.start,
      },
    });
  }

  async fetchJobs() {
    this.setState({ dataFetched: false, disablePage: true });

    let response = await RootStore.publishJobStore.fetchAllPaginate(
      this.state.pagination,
      this.state.sort,
      this.state.filter_groups
    );

    this.setState({
      pagination: { ...this.state.pagination, ...response },
      dataFetched: true,
      disablePage: false,
    });
  }

  async onFilter(filters: Filters): Promise<void> {
    this.setFilters(filters);
    this.fetchJobs();
  }

  onChange = (filters: Filters): void => {
    this.onFilter(filters);
  };

  render() {
    const labels = this.state.publishJobList
      .filter((item: any) => moment(item.start).isBefore(moment(), "day"))
      .map((item: any) => moment(item.start).format("DD-MM-YYYY"))
      .filter((item: any, i: any, ar: any) => ar.indexOf(item) === i)
      .slice(0, 20)
      .reverse();

    return (
      <>
        <div className={"row"}>
          <div className={"col-12"}>
            <div className={"customBreadCrumb"}>
              <h1>Publish Jobs Chart</h1>
            </div>
          </div>
        </div>

        <Card className="mb-4">
          <CardBody>
            <CardTitle>Charts</CardTitle>
            <FilterComponent search={false} filterSet={this.filterSet} />
            {!this.state.publishJobList?.length ? (
              <Spinner color="primary" size={"lg"} className="spinner" />
            ) : (
              <Row>
                <Col lg={6} className="publishjobs-chart">
                  <h4>Published Jobs</h4>
                  <Bar
                    type="bar"
                    options={{
                      scales: {
                        xAxes: [
                          {
                            gridLines: {
                              display: false,
                            },
                          },
                        ],
                      },
                    }}
                    data={{
                      labels: labels,
                      datasets: [
                        {
                          label: "Feed",
                          data: labels.map(
                            (item: any) =>
                              this.state.publishJobList.filter(
                                (job: any) =>
                                  job.status === "healthy" &&
                                  job.content_id.type === "post" &&
                                  moment(job.start).format("DD-MM-YYYY") ===
                                    item
                              ).length
                          ),
                          borderColor: "#1A76FC",
                          backgroundColor: "#1A76FC",
                        },
                        {
                          label: "Story",
                          data: labels.map(
                            (item: any) =>
                              this.state.publishJobList.filter(
                                (job: any) =>
                                  job.status === "healthy" &&
                                  job.content_id.type !== "post" &&
                                  moment(job.start).format("DD-MM-YYYY") ===
                                    item
                              ).length
                          ),
                          borderColor: "#FF6104",
                          backgroundColor: "#FF6104",
                        },
                      ],
                    }}
                  />
                </Col>
                <Col lg={6} className="publishjobs-chart">
                  <h4>Failed Jobs</h4>
                  <Bar
                    type="bar"
                    options={{
                      scales: {
                        xAxes: [
                          {
                            gridLines: {
                              display: false,
                            },
                          },
                        ],
                      },
                    }}
                    data={{
                      labels: labels,
                      datasets: [
                        {
                          label: "Feed",
                          data: labels.map(
                            (item: any) =>
                              this.state.publishJobList.filter(
                                (job: any) =>
                                  job.status !== "healthy" &&
                                  job.content_id.type === "post" &&
                                  moment(job.start).format("DD-MM-YYYY") ===
                                    item
                              ).length
                          ),
                          borderColor: "#1A76FC",
                          backgroundColor: "#1A76FC",
                        },
                        {
                          label: "Story",
                          data: labels.map(
                            (item: any) =>
                              this.state.publishJobList.filter(
                                (job: any) =>
                                  job.status !== "healthy" &&
                                  job.content_id.type !== "post" &&
                                  moment(job.start).format("DD-MM-YYYY") ===
                                    item
                              ).length
                          ),
                          borderColor: "#FF6104",
                          backgroundColor: "#FF6104",
                        },
                      ],
                    }}
                  />
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}
