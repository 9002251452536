import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { observer } from 'mobx-react';
import Cookies from 'js-cookie';

// components
import AppLayout from '../../template/layout/AppLayout';
import Dashboard from './dashboard';
import Account from './account';
import RootStore from '../../store/RootStore';
import ContentManager from './content-manager';
import Ads from './ads';
import Explorer from './explorer';
import NewExplorer from './explorer/new-explorer';
import Offers from './offers/index';
import Scheduler from './scheduler/index';
import Wizard from './dashboard/multi-step-form/onboarding';
import AnalyticsPage from './scheduler/AnalyticsPage';
import Swal from 'sweetalert2';
import { ajaxErrorAlert, handleError } from '../../lib/Utils';
import { detectAnyAdblocker } from 'just-detect-adblock';
import { CustomRoute, AdBlockerDetector } from '../common-components';
import InitialLoading from './../../views/common-components/InitialLoading'

interface Props extends RouteComponentProps {
    containerClassnames: any[];
    menu: any;
}
interface State {
    token: string,
}

@observer
class App extends Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            token: Cookies.get('token'),
        }
    }
    componentDidUpdate() {
        if (RootStore.users.isLoggedIn()) {
            detectAnyAdblocker().then((detected: boolean) => {
                if (detected) {
                    Swal.fire({
                        title: "It looks like you're using an ad-blocker",
                        text: 'Ainfluencer is an advertising based site and we noticed you have ad-blocking enabled. please turn you ad-blocker off.',
                        type: 'warning',
                        confirmButtonColor: '#145388',
                        confirmButtonText: 'Reload Ainfluencer',
                    }).then((button) => {
                        if (button.value) {
                            window.location.reload();
                        }
                    });
                }
            });
        }
    }
    async componentDidMount() {
        try {
            if (RootStore.users.currentUser) {
                await RootStore.fetchStaticData();
                // await RootStore.offerStore.fetchTotalUnreadMessages();
            }
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('Failed to get static data! please refresh the page...');
        }
    }

    render() {
        const { match, location } = this.props;
        const { token } = this.state
        const loggedIn = RootStore.users.isLoggedIn()


        if (!token && loggedIn === false) {
            return <Redirect to={'/login'} />
        } else if (token && loggedIn === true) {
            return <AppLayout
                displaySideAndTopNav={!location.pathname.includes('/app/welcome')}
                totalUnreadMessages={RootStore.offerStore.totalUnreadMessages.total}
            >
                <Switch>
                    <CustomRoute
                        path={`${match.url}/welcome`}
                        component={Wizard}
                        shouldRedirect={RootStore.users.currentUser.created_campaign}
                        redirectRout={'/error'}
                    />
                    <Route path={`${match.url}/account`} component={Account} />
                    <Route path={`${match.url}/ads`} component={Ads} />
                    <Route path={`${match.url}/content-manager`} component={ContentManager} />
                    <Route path={`${match.url}/influencer-explorer`} component={Explorer} />
                    <Route path={`${match.url}/new-influencer-explorer`} component={NewExplorer} />
                    <Route path={`${match.url}/offers`} component={Offers} />
                    <Route path={`${match.url}/scheduler`} component={Scheduler} />
                    <Route
                        path={`${match.url}/publish-jobs/:id/analytics`}
                        exact={true}
                        render={(props) => <AnalyticsPage {...props} />}
                    />

                    <Route path={`${match.url}/`} component={Dashboard} />
                    {/* 
                    // @ts-ignore */}
                    <AdBlockerDetector pathname={window.location.pathname} />
                    <Redirect to={'/error'} />
                </Switch>
            </AppLayout>
        }
        return <InitialLoading />
    }
}

// @ts-ignore
const mapStateToProps = ({ menu }) => {
    const { containerClassnames } = menu;
    return { containerClassnames };
};

export default withRouter(connect(mapStateToProps, {})(App));


// return RootStore.users.isLoggedIn() ? (
//     <AppLayout
//         displaySideAndTopNav={!location.pathname.includes('/app/welcome')}
//         totalUnreadMessages={RootStore.offerStore.totalUnreadMessages.total}
//     >
//         <Switch>
//             <CustomRoute
//                 path={`${match.url}/welcome`}
//                 component={Wizard}
//                 shouldRedirect={RootStore.users.currentUser.created_campaign}
//                 redirectRout={'/error'}
//             />
//             <Route path={`${match.url}/account`} component={Account} />
//             <Route path={`${match.url}/ads`} component={Ads} />
//             <Route path={`${match.url}/content-manager`} component={ContentManager} />
//             <Route path={`${match.url}/influencer-explorer`} component={Explorer} />
//             <Route path={`${match.url}/offers`} component={Offers} />
//             <Route path={`${match.url}/scheduler`} component={Scheduler} />
//             <Route
//                 path={`${match.url}/publish-jobs/:id/analytics`}
//                 exact={true}
//                 render={(props) => <AnalyticsPage {...props} />}
//             />

//             <Route path={`${match.url}/`} component={Dashboard} />
//             {/* 
//   // @ts-ignore */}
//             <AdBlockerDetector pathname={window.location.pathname} />
//             <Redirect to={'/error'} />
//         </Switch>
//     </AppLayout>
// ) : (
//     <Redirect to={'/login'} />
// );
