import React from 'react';
import { RouteProps, Route } from 'react-router-dom';

interface CustomRouteProps extends RouteProps {
    component: React.ComponentType<any>;
    shouldRedirect?: boolean;
    redirectRout?: string;
}

export const CustomRoute = (props: CustomRouteProps) => {
    const { shouldRedirect } = props;

    return shouldRedirect ? (
        <Route {...props} component={props.component} />
    ) : (
        <Route {...props} component={props.component} />
    );
};
