import React, { useState } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import numbro from 'numbro';
import { truncate } from 'lodash';
// @ts-ignore
import ReactTooltip from 'react-tooltip';
import { observer } from 'mobx-react';
import { isMobileAndTablet } from '../../../../lib/Utils';

import { MapPinIcon, InformationIcon } from '../../../../assets/icons';
import { ExternalLinkIcon, Instagram2Icon } from '../../../../assets/icons';
import RootStore from '../../../../store/RootStore';
import { IInfluencerModel } from '../../../../store/models/InfluencerModel';
import { ICountryModel } from '../../../../store/models/CountryModel';
import { explorerSearchType } from '../../../../lib/CustomTypes';
import { ICategoryModel } from '../../../../store/models/CategoryModel';
import AdSelection from '../components/AdSelection';
import { CountriesList } from './../../../../constants/countriesList';
import { minifyNum } from './../../../../lib/Utils';

interface Props extends RouteComponentProps {
    influencer: IInfluencerModel;
    invited: boolean;
    onToggleSelected: (event: React.MouseEvent) => void;
}

function Profile(props: Props) {
    const { influencer, invited } = props;

    const [showInfluencerModal, setShowInfluencerModal] = useState(false);
    const toggleInfluencerModal = () => {
        setShowInfluencerModal(!showInfluencerModal);
    };

    const skipAd = () => {
        setShowInfluencerModal(!showInfluencerModal);
        localStorage.setItem('skip_ad', '1');
    };

    return (
        <div className="influencerProfile__details_profile">
            <div>
                <AdSelection
                    {...props}
                    isOpen={showInfluencerModal}
                    updateAd={(id: number) => RootStore.adStore.setAd(id)}
                    toggleAdPopup={toggleInfluencerModal}
                    skipAd={skipAd}
                />

                {influencer.integration.picture && (
                    <img src={influencer.integration.picture.getThumbnailUrl(120, 120)} alt="" />
                )}
                <div className="profileDetails">
                    <div className="profileDetails_header">
                        <div className="d-flex align-items-center flex-wrap mb-2">
                            <p className="font-weight-bold profileDetails_header_userName">
                                <Instagram2Icon />
                                <a
                                    href={`https://instagram.com/${influencer.integration.username}/`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ExternalLinkIcon />
                                    {influencer.integration.username}
                                </a>
                            </p>
                            <div className="categories d-flex align-items-center flex-wrap">
                                {influencer.identity.categories.map((cat: ICategoryModel) => (
                                    <span
                                        key={cat.title}
                                        className="influencerTag"
                                        onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                                            localStorage.removeItem('selected_ad');
                                            RootStore.adStore.setAd(null);
                                            props.history.push({
                                                pathname: '/app/influencer-explorer/custom-search',
                                                state: {
                                                    name: explorerSearchType.CATEGORY,
                                                    value: [
                                                        {
                                                            label: cat.title,
                                                            value: cat.id,
                                                            slug: cat.slug,
                                                        },
                                                    ],
                                                    update_filter: true,
                                                    from: 'influencer-profile',
                                                },
                                            });
                                        }}
                                    >
                                        {cat.title}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <p
                            style={{
                                color: '#7f8da5',
                                fontSize: '1.1em',
                                fontWeight: 600,
                            }}
                        >
                            {influencer.integration.full_name
                                ? influencer.integration.full_name
                                : influencer.identity.name
                                ? influencer.identity.name
                                : 'No Name'}
                        </p>
                    </div>
                    {influencer.integration.country_id && (
                        <p>
                            <MapPinIcon />
                            {
                                CountriesList.filter(
                                    (co: ICountryModel) => co.id === influencer.integration.country_id,
                                )[0].name
                            }
                        </p>
                    )}

                    <div className="profileDetails_statistics">
                        {influencer.integration && (
                            <p>
                                <span>{minifyNum(influencer.integration.followers)}</span> Followers
                            </p>
                        )}
                        {influencer.integration && (
                            <p>
                                <span>{minifyNum(influencer.integration.followings)}</span> Followings
                            </p>
                        )}
                        {influencer.integration && (
                            <>
                                <p className="have-info">
                                    <span>
                                        {numbro(influencer.integration.engagement_rate).format({
                                            output: 'percent',
                                            mantissa: 2,
                                            optionalMantissa: true,
                                        })}
                                        {!isMobileAndTablet() && <InformationIcon data-tip data-for="engagementRate" />}
                                    </span>
                                    {'  '}
                                    Engagement Rate
                                </p>
                                <ReactTooltip id="engagementRate" type="dark" effect="solid">
                                    <h1>Engagement Rate:</h1>
                                    <p>
                                        This metric is based on the percentage of Comments on average in relation to the
                                        number of followers.
                                    </p>
                                </ReactTooltip>
                            </>
                        )}
                        {/* {influencer.integration && (
                            <>
                                <p className="have-info">
                                    <span>
                                        {influencer.integration
                                            ? Math.floor(
                                                  influencer.integration.quality_score * 100 > 100
                                                      ? 100
                                                      : influencer.integration.quality_score * 100 || 0,
                                              ) + '%'
                                            : ''}
                                    </span>
                                    {'  '}
                                    Quality Score
                                </p>
                            </>
                        )} */}
                    </div>
                    <p className="w-100">{truncate(influencer.integration.description, { length: 250 })}</p>
                </div>
            </div>
            {!RootStore.users.currentUser.is_admin ? (
                <Button
                    outline
                    color="primary"
                    className={classNames({
                        influencerCard_buttonSelected: invited && RootStore.invitationStore.temporaryList.length !== 0,
                    })}
                    onClick={
                        RootStore.adStore.selectedAd
                            ? (event: React.MouseEvent) => props.onToggleSelected(event)
                            : toggleInfluencerModal
                    }
                >
                    {invited && RootStore.invitationStore.temporaryList.length !== 0 ? 'Selected' : 'Invite'}
                </Button>
            ) : null}
        </div>
    );
}

export default observer(Profile);
