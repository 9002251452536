import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import DatatablePagination from "../../../../views/common-components/DatatablePagination";
import FilterComponent from "../../../common-components/Filter/FilterComponent";
import ServerSideFilterSet from "../../../common-components/Filter/ServerSideFilterSet";
import { Separator } from "../../../../template/components/common/CustomBootstrap";
import EmptySearchResult from "../../../app/offers/components/EmptySearchResult";
import {
  FilterType,
  Range,
} from "../../../common-components/Filter/AbstractFilterSet";
import UTMList from "./UTMList";
import { SortOrder } from "../../../../lib/QueryTypes";
import { debounce } from "lodash";
import { Filters } from "./Users";
import { Spinner } from "reactstrap";
import RootStore from "../../../../store/RootStore";
import { observer } from "mobx-react";
import classes from "./styles/UTMTab.module.scss";

interface Props extends RouteComponentProps {}

interface State {
  dataFetched: boolean;
  users: [];
  searchTerm: string | null;
  sort: { [key: string]: string };
  filter_groups: any;
  pagination: {
    totalPages: number;
    page: number;
    pageSize: number;
    totalCount: number;
  };
}
@observer
export default class UTMTab extends Component<Props, State> {
  private readonly filterSet: ServerSideFilterSet;
  constructor(props: Props) {
    super(props);
    this.state = {
      dataFetched: false,
      users: [],
      searchTerm: null,
      sort: {
        created_at: SortOrder.DESCENDING,
      },
      filter_groups: {},
      pagination: {
        page: 0,
        pageSize: 10,
        totalPages: 0,
        totalCount: 0,
      },
    };

    this.filterSet = new ServerSideFilterSet(debounce(this.onChange, 600));

    this.filterSet.addFilter({
      name: "created_at",
      title: "Create Date",
      type: FilterType.simpleFilter,
      defaultValue: SortOrder.DESCENDING,
      values: () => [SortOrder.ASCENDING, SortOrder.DESCENDING],
      valueToLabelConverter: (value: string | number | Range) =>
        value === SortOrder.ASCENDING ? "Ascending" : "Descending",
    });

    this.filterSet.addFilter({
      name: "created_at_range",
      title: "Date range",
      defaultValue: null,
      type: FilterType.rangeFilter,
      values: (value: Range) => [{ start: value.start, end: value.end }, null],
    });

    this.onChange = this.onChange.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.fetchFilters = this.fetchFilters.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.pageSizeChange = this.pageSizeChange.bind(this);
  }

  async componentDidMount() {
    await this.fetchFilters();
    await this.fetchUsers();
  }

  async fetchUsers() {
    this.setState({ dataFetched: false });
    const response = await RootStore.users.fetchAllUTMs(
      this.state.pagination,
      this.state.filter_groups,
      this.state.sort,
      this.state.searchTerm
    );
    this.setState({
      dataFetched: true,
      users: response.users,
      pagination: response.pagination,
    });
  }

  async fetchFilters() {
    const filters = await RootStore.users.fetchUTMFilters();
    Object.keys(filters || {}).map((filterKey) => {
      this.filterSet.addFilter({
        name: filterKey,
        title: "UTM_" + filterKey.toUpperCase(),
        defaultValue: null,
        type: FilterType.simpleFilter,
        values: () => [null, ...filters[filterKey]],
        valueToLabelConverter: (value: string | number | Range) =>
          value === null ? " All " : String(value),
      });
    });
  }

  pageChange(page: number) {
    this.setState(
      {
        pagination: { ...this.state.pagination, page },
      },
      this.fetchUsers
    );
  }
  pageSizeChange(pageSize: number) {
    this.setState(
      {
        pagination: { ...this.state.pagination, page: 0, pageSize },
      },
      this.fetchUsers
    );
  }

  onChange = (filters: Filters): void => {
    const { created_at, created_at_range, ...rest } = filters.filters;

    const utm_filters = Object.keys(rest)
      .map((filterKey) =>
        rest[filterKey] ? { key: filterKey, value: rest[filterKey] } : null
      )
      .filter((item) => item);

    const filterGroup = {
      date: {
        start: created_at_range?.start?.format?.("YYYY-MM-DD"),
        end: created_at_range?.end?.format?.("YYYY-MM-DD"),
      },
      utm: utm_filters,
    };

    this.setState(
      {
        searchTerm: filters.searchTerm,
        pagination: {
          ...this.state.pagination,
          page: 0,
        },
        filter_groups: filterGroup,
        sort: { created_at },
      },
      this.fetchUsers
    );
  };

  clearFilter = () => {
    this.setState({
      searchTerm: null,
      sort: {
        created_at: SortOrder.DESCENDING,
      },
      filter_groups: {},
      pagination: {
        page: 0,
        pageSize: 10,
        totalPages: 0,
        totalCount: 0,
      },
    });
  };

  render() {
    const { dataFetched, pagination, users } = this.state;

    return (
      <>
        <div className={classes.filters}>
          <FilterComponent
            filterSet={this.filterSet}
            clearFilter={this.clearFilter}
          />
        </div>
        <Separator className="mb-4 mt-3" />
        {!dataFetched ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "20em" }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Spinner color="primary" className="mb-1" />
              <p>Please wait...</p>
            </div>
          </div>
        ) : (
          <>
            {users.length > 0 ? (
              <React.Fragment>
                <UTMList
                  users={this.state.users}
                  pagination={pagination}
                  fetchUsers={this.fetchUsers}
                  {...this.props}
                />
                <DatatablePagination
                  key="pagination"
                  page={pagination.page}
                  pages={pagination.totalPages}
                  showPageSizeOptions={true}
                  pageSizeOptions={[10, 50, 100, 200, 500]}
                  onPageSizeChange={this.pageSizeChange}
                  canPrevious={pagination.page === 0 ? false : true}
                  canNext={
                    pagination.page === pagination.totalPages - 1 ? false : true
                  }
                  defaultPageSize={pagination.pageSize}
                  onPageChange={this.pageChange}
                />
              </React.Fragment>
            ) : (
              <EmptySearchResult />
            )}
          </>
        )}
      </>
    );
  }
}
