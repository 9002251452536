import React, { Component } from 'react';
import { ButtonGroup, Card, CardBody, FormGroup, Label, Form, Input, Spinner } from 'reactstrap';
import { Step, Steps, WithWizard, Wizard } from 'react-albus';
import { BottomNavigation } from '../../../../template/components/wizard/BottomNavigation';
import { TopNavigation } from '../../../../template/components/wizard/TopNavigation';
// import { Field, Form, Formik } from "formik";
import Transport from '../../../../lib/Transport';
import { ajaxErrorAlert, handleError, numberFormatK, showLoading } from '../../../../lib/Utils';
import Swal from 'sweetalert2';
import RootStore from '../../../../store/RootStore';
import { IUserModel } from '../../../../store/models/UserModel';
import { NotificationManager } from '../../../../template/components/common/react-notifications/index';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Separator } from '../../../../template/components/common/CustomBootstrap';
import numbro from 'numbro';
import { makeIntegrationQuery } from '../../../../store/common/RequestHelpers';
import InstagramAuthHelper from '../../../../lib/InstagramAuthHelper';
import { AxiosResponse } from 'axios';
import { isStaging } from '../../../../constants/defaultValues';

declare module 'react-albus' {
    interface StepObject {
        name?: string;
        desc?: string;
        hideTopNav?: boolean;
    }
}

interface Props extends RouteComponentProps {}

interface State {
    bottomNavHidden: boolean;
    topNavDisabled: boolean;
    loading: boolean;
    fields: any[];
    profile: any;
    metrics: any;
}

export default class NewIntegrationWizzard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            bottomNavHidden: false,
            topNavDisabled: false,
            loading: false,
            fields: [
                {
                    valid: false,
                    name: 'username',
                    value: '',
                    error: '',
                },
                {
                    valid: false,
                    name: 'password',
                    value: '',
                    error: '',
                },
            ],
            metrics: {},
            profile: {},
        };

        this.onClickNext = this.onClickNext.bind(this);
        this.onClickPrev = this.onClickPrev.bind(this);
        this.validateUsername = this.validateUsername.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            fields: [{ ...this.state.fields[0] }, { ...this.state.fields[1] }],
        });
        RootStore.contentStore.fetchAllIfNecessary();
    }

    handleInputChange(event: any) {
        let fieldsValue = this.state.fields;

        if (event.target.name === 'password') {
            fieldsValue[1].value = event.target.value;
            this.setState((prevState: any) => ({ fields: fieldsValue }));

            // this.validatePassword(event.target.value);
        } else if (event.target.name === 'username') {
            fieldsValue[0].value = event.target.value;
            this.setState((prevState: any) => ({ fields: fieldsValue }));
        }
    }

    validateUsername(value: string) {
        let error;
        if (!value) {
            error = 'Please enter your username without the leading @';
        } else if (value.length < 2) {
            error = 'Value must be longer than 2 characters';
        }
        return error;
    }

    validatePassword(value: string) {
        let error;
        if (!value) {
            error = 'Please enter your password';
        } else if (value.length < 6) {
            error = 'Password must be longer than 6 characters';
        }
        return error;
    }

    hideNavigation() {
        this.setState({ bottomNavHidden: true, topNavDisabled: true });
    }

    async lookUpAccount(username: string) {
        showLoading('Looking up @' + username + ' ...', "Please wait while we get your profile's information...");

        try {
            let response = await Transport.get('integrations/lookup/instagram/profile/' + username.replace('@', ''));
            Swal.close();
            return response.data;
        } catch (e) {
            if (e.response.status === 400) {
                ajaxErrorAlert(
                    'Could not find IG account with name ' +
                        username +
                        '! Check if you have misspelled the username...',
                );
            } else if (e.response.status === 409) {
                ajaxErrorAlert(
                    'Oops! This username (@' +
                        username +
                        ') has already been taken. Please select a different Instagram account.',
                );
            } else if (e.response.status === 504) {
                ajaxErrorAlert('Oops! We could not connect to Instagram.');
            } else if (e.response.status === 422) {
                ajaxErrorAlert(
                    `An IG with this username is already in our database! If you think someone else 
              has claimed your account unlawfully or by mistake, contact support.`,
                );
            } else {
                handleError(e);
                ajaxErrorAlert('There was a problem looking up the IG account!');
            }

            throw e;
        }
    }

    async addIntegration(data: any) {
        showLoading('Adding @' + data.username + ' ...', 'Please wait while we add your profile...');

        try {
            let response = await Transport.post(
                'integrations',
                {
                    integration: {
                        ...data,
                        identity_id: RootStore.users.getDefaultIdentity().id,
                    },
                },
                makeIntegrationQuery('latest'),
            );

            if (!isStaging()) {
                //@ts-ignore
                ga('send', {
                    hitType: 'event',
                    eventCategory: 'Brand',
                    eventAction: 'Added IG field',
                    eventLabel: '',
                });
            }

            Swal.close();
            return response.data;
        } catch (e) {
            if (e.response.status === 422) {
                ajaxErrorAlert('This IG (@' + data.username + ') has been already used in AInfluencer platform!');
            } else {
                ajaxErrorAlert('There was a problem adding the IG account! Go back and try again...');
            }

            throw e;
        }
    }

    async verifySubmit() {}

    async onClickNext(goToNext: Function, steps: any[], step: any) {
        if (steps.length - 1 <= steps.indexOf(step)) {
            return;
        }

        let username = this.state.fields[0].value.replace(/\s/g, '').replace('@', '');

        // check integration status
        if (steps.indexOf(step) === 0) {
            if (username.length === 0) {
                await Swal.fire({
                    title: `You need to enter your Instagram ID`,
                    type: 'warning',
                    allowOutsideClick: false,
                });
                return;
            } else if (RootStore.integrationStore.alreadyIntegrated(username)) {
                await Swal.fire(`You have added @${username} before!`, '', 'warning');
                this.props.history.push('/app/account/integrations');
                return;
            }

            try {
                let results = (await this.lookUpAccount(username)) as any;
                this.setState({
                    ...results,
                });
            } catch (e) {
                return;
            }
        }

        if (steps.indexOf(step) === 1) {
            if (RootStore.integrationStore.alreadyAdded(username) === false) {
                try {
                    let response = (await this.addIntegration({
                        username,
                        platform: 'instagram',
                        identity_id: (RootStore.users.currentUser as IUserModel).getIdentities()[0].id,
                    })) as any;
                    RootStore.integrationStore.addIntegration(response.integration);
                } catch (e) {
                    handleError(e);
                    ajaxErrorAlert('Could not add IG due to an error in our server.');
                    return;
                }
            }
        }

        // add integration
        if (steps.indexOf(step) === 2) {
            if (this.state.fields[1].value.length === 0) {
                await Swal.fire({
                    title: `You need to enter your Instagram password`,
                    type: 'warning',
                    allowOutsideClick: false,
                });
                return;
            }
            try {
                showLoading('Logging into @' + username + ' ...', 'Please wait while we log into your profile...');

                let integration = RootStore.integrationStore.getByUsername(username);

                let auth = new InstagramAuthHelper(integration.id, integration.username, (): Promise<AxiosResponse> => {
                    return Transport.patch(
                        `integrations/${integration.id}`,
                        {
                            integration: {
                                token: this.state.fields[1].value,
                            },
                        },
                        makeIntegrationQuery(),
                    );
                });

                try {
                    showLoading();
                    await auth.perform();
                    integration.setAuthenticated();
                    Swal.close();

                    NotificationManager.success(
                        'Done',
                        '@' + username + ' was successfully added!',
                        3000,
                        null,
                        null,
                        'filled',
                    );
                } catch (e) {
                    handleError(e);
                    Swal.close();
                    ajaxErrorAlert('There was a problem logging into your account!');
                    return;
                }

                Swal.close();
            } catch (e) {
                Swal.close();
                if (e.response.status === 400) {
                    switch (e.response.data.code) {
                        case 'TWO_FACTOR_LOGIN':
                            ajaxErrorAlert('two factor error! contact support');
                            break;
                        case 'CHECKPOINT':
                            ajaxErrorAlert('critical checkpoint error! contact support');
                            break;
                        case 'WRONG_PASSWORD':
                            ajaxErrorAlert('The password you entered is not correct!');
                            break;
                        case 'ACCESS_SUSPENDED':
                            ajaxErrorAlert(
                                'You need to log into your account on your Instagram app either on your phone or web to verify your account. In case you set a new password for your account then come back here and log your Instagram onto Ainfluencer using the new password.',
                            );
                            break;
                        // case "MEDIA_NOT_FOUND":
                        //   ajaxErrorAlert("The password you entered is not correct!");
                        //   break;
                        // case "INVALID_USER":
                        //   ajaxErrorAlert("The password you entered is not correct!");
                        //   break;
                        // case "INACTIVE_USER":
                        //   ajaxErrorAlert("The password you entered is not correct!");
                        //   break;
                        // case "ACTION_SPAM":
                        //   ajaxErrorAlert("The password you entered is not correct!");
                        //   break;
                        // case "CHALLENGE_WRONG_CODE":
                        //   ajaxErrorAlert("The password you entered is not correct!");
                        //   break;
                        // case "LOGIN_REQUIRED":
                        //   ajaxErrorAlert("The password you entered is not correct!");
                        //   break;
                        // case "UNKNOWN":
                        //   ajaxErrorAlert("There was an error setting your IG password!");
                        //   break;
                    }
                } else {
                    handleError(e);
                    ajaxErrorAlert('There was an error setting your IG password!');
                    return;
                }
                return;
            }
        }

        goToNext();
        step.isDone = true;
        if (steps.length - 2 <= steps.indexOf(step)) {
            this.hideNavigation();
        }
    }

    onClickPrev(goToPrev: Function, steps: any[], step: any) {
        if (steps.indexOf(step) <= 0) {
            return;
        }

        let fieldsValue = this.state.fields;
        // fieldsValue[0].value = "";
        fieldsValue[1].value = '';
        fieldsValue[0].error = '';
        fieldsValue[1].error = '';
        fieldsValue[0].valid = false;
        fieldsValue[1].valid = false;

        this.setState(() => ({ fields: fieldsValue }));
        goToPrev();
    }

    render() {
        return (
            <Card>
                <CardBody className="wizard wizard-default">
                    <Wizard>
                        <TopNavigation className="justify-content-center" disableNav={true} />
                        <div>
                            <Steps>
                                <Step id="step1" name="Enter Username" desc="Enter your Instagram account username">
                                    <div className="wizard-basic-step">
                                        <WithWizard
                                            render={({ next, previous, step, steps }) => (
                                                <Form
                                                    className="av-tooltip tooltip-label-right"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        this.onClickNext(next, steps, step);
                                                    }}
                                                >
                                                    <FormGroup>
                                                        <Label className="position-relative">
                                                            Enter username:
                                                            {this.state.fields[0].error.length > 0 && (
                                                                <div className="invalidFeedback">
                                                                    {this.state.fields[0].error}
                                                                </div>
                                                            )}
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            name="username"
                                                            id="username"
                                                            value={this.state.fields[0].value}
                                                            placeholder="Enter your Instagram ID"
                                                            onChange={this.handleInputChange}
                                                        />
                                                    </FormGroup>
                                                </Form>
                                            )}
                                        />
                                    </div>
                                </Step>

                                <Step id="step2" name={'Verify Account'} desc={'Is this yours?'}>
                                    <div className="wizard-basic-step">
                                        <Card className="IGProfilePreview">
                                            <img
                                                alt={'ainfluencer'}
                                                src={`https://scontent.aigrow.me?url=${encodeURIComponent(this.state.profile.picture)}.jpg`}
                                            />

                                            <a
                                                href={`https://instagram.com/${this.state.fields[0].value}/`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                @{this.state.fields[0].value}
                                            </a>
                                            <h3>{this.state.profile.name}</h3>
                                            <p>{this.state.profile.bio}</p>
                                            <Separator />
                                            <div className="IGProfilePreview__details">
                                                <div>
                                                    <h2>
                                                        {this.state.metrics.medias > 10000
                                                            ? numbro(this.state.metrics.medias).format({
                                                                  average: true,
                                                                  mantissa: 1,
                                                              })
                                                            : numbro(this.state.metrics.medias).format({
                                                                  average: true,
                                                                  mantissa: 0,
                                                              })}
                                                    </h2>
                                                    <span>posts</span>
                                                </div>
                                                <div>
                                                    <h2>{numberFormatK(this.state.metrics.followers)}</h2>
                                                    <span>followers</span>
                                                </div>
                                                <div>
                                                    <h2>{numberFormatK(this.state.metrics.followings)}</h2>
                                                    <span>following</span>
                                                </div>
                                            </div>
                                        </Card>
                                        <br />
                                        <p>If this is your account, please click on "Next":</p>
                                        <br />
                                        &nbsp;
                                    </div>
                                </Step>

                                <Step id="step3" name="Schedule" desc="Start posting content">
                                    <div className="wizard-basic-step text-center pt-3">
                                        {this.state.loading ? (
                                            <div>
                                                <Spinner color="primary" className="mb-1" />
                                                <p>Please wait...</p>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2 className="mb-2">
                                                    @{this.state.fields[0].value} was successfully added!
                                                </h2>
                                                <p>Now you can schedule your first post or story!</p>
                                                <br />
                                                <ButtonGroup>
                                                    <NavLink
                                                        className="btn btn-lg btn-success"
                                                        to={`/app/content-manager`}
                                                    >
                                                        Create New Content
                                                    </NavLink>
                                                    {RootStore.contentStore.contentList.length ? (
                                                        <NavLink
                                                            className="btn btn-lg btn-success"
                                                            to={`/app/scheduler`}
                                                        >
                                                            Start Scheduling
                                                        </NavLink>
                                                    ) : null}
                                                </ButtonGroup>
                                            </div>
                                        )}
                                    </div>
                                </Step>
                            </Steps>

                            <BottomNavigation
                                onClickNext={this.onClickNext}
                                onClickPrev={this.onClickPrev}
                                className={'justify-content-center ' + (this.state.bottomNavHidden && 'invisible')}
                                prevLabel="← Back"
                                nextLabel="Next →"
                            />
                        </div>
                    </Wizard>
                </CardBody>
            </Card>
        );
    }
}
