import { Button } from "reactstrap";
import * as React from "react";
import { observer } from "mobx-react";
import { RouteComponentProps, Link, NavLink } from "react-router-dom";
import { truncate } from "lodash";
import classNames from "classnames";
import moment from "moment";
import TooltipLite from "react-tooltip-lite";

import OfferDetails from "../../../common-components/OfferDetails";
import { IOfferModel } from "../../../../store/models/OfferModel";
// import RootStore from "../../../../store/RootStore";
import { CountDown } from "../../../common-components";
import { TickIcon, CloseIcon, HourGlassIcon } from "../../../../assets/icons";
import profileImg from "../../../../assets/img/profilePlaceholder.jpg";
// import { IPublishJobModel } from "../../../../store/models/PublishJobModel";

interface Props extends RouteComponentProps {
  offer: IOfferModel;
}

interface State {}

@observer
export default class DealCard extends React.Component<Props, State> {
  public url = profileImg;
  render() {
    const { offer } = this.props;

    return (
      <Link to={`/app/offers/${offer.id}`} style={{ display: "contents" }}>
        <tr className="dealCard">
          <td className="dealCard__profileDetails">
            <img
              style={{ width: "50px", height: "50px" }}
              src={
                this.props.offer.getOpponentIdentity().logo
                  ? this.props.offer
                      .getOpponentIdentity()
                      .logo.getThumbnailUrl(50, 50)
                  : this.url
              }
              alt={"Profile"}
            />
            <div>
              <p>
                {this.props.offer.getOpponentIdentity().name ?? "(No Name)"}
              </p>
              <span>
                @
                {truncate(this.props.offer.getTargetIntegration().username, {
                  length: 24,
                })}
              </span>
            </div>
          </td>
          <td>
            <div className="d-flex flex-column justify-content-center">
              <TooltipLite
                mouseOutDelay={200}
                background="transparent"
                content={
                  <div className="offerDetailsTooltip">
                    <OfferDetails offer={this.props.offer} />
                    <Button
                      color="primary"
                      className="mx-auto"
                      onClick={(event: React.MouseEvent) =>
                        this.props.history.push(`/app/offers/${offer.id}`)
                      }
                    >
                      Open Offer
                    </Button>
                  </div>
                }
              >
                <p
                  id={`OfferToolTipToggler_${offer.id}`}
                  className="offerDetailsTooltipLink mb-0"
                >
                  {truncate(offer.ad_id.title, { length: 20 })}
                </p>
              </TooltipLite>
            </div>
          </td>
          <td className="dealCard__status">
            {offer.getHumanReadableStatus() === "Failed" ? (
              <TooltipLite
                mouseOutDelay={200}
                background="#222831"
                className="dealCardTooltip"
                content={
                  <p>
                    This Ad has been failed because of some technical problems.{" "}
                    <br />
                    please{" "}
                    <Link to={`/app/offers/${this.props.offer.id}`}>
                      view offer
                    </Link>{" "}
                    and renegotiate.
                  </p>
                }
              >
                <CloseIcon
                  className={classNames(
                    "bg-" + this.props.offer.getStatusColor()
                  )}
                />
              </TooltipLite>
            ) : offer.getHumanReadableStatus() === "Completed" ? (
              <TooltipLite
                mouseOutDelay={200}
                background="#222831"
                className="dealCardTooltip"
                content={<p>Published</p>}
              >
                <TickIcon
                  className={classNames(
                    "bg-" + this.props.offer.getStatusColor()
                  )}
                />
              </TooltipLite>
            ) : offer.getHumanReadableStatus() === "Scheduled" ? (
              <TooltipLite
                mouseOutDelay={200}
                background="#222831"
                className="dealCardTooltip"
                content={<p>Waiting for publish</p>}
              >
                <HourGlassIcon
                  className={classNames(
                    "bg-" + this.props.offer.getStatusColor()
                  )}
                />
              </TooltipLite>
            ) : null}
          </td>
          <td className="dealCard__timer">
            <CountDown endsAt={moment(offer.start).valueOf()} />
          </td>
          <td className="dealCard__actions">
            {offer.getHumanReadableStatus() === "Failed" ? (
              <Link
                className="dealCard__actions_failed"
                style={{ color: this.props.offer.getStatusColor() }}
                to={`/app/offers/${this.props.offer.id}`}
              >
                View Offer
              </Link>
            ) : offer.getHumanReadableStatus() === "Completed" ? (
              <>
                <Link to={`/app/offers/${this.props.offer.id}`}>
                  View Offer
                </Link>
                <NavLink
                  to={`/app/publish-jobs/${
                    this.props.offer.getLatestPublishJob().id
                  }/analytics`}
                >
                  Analytics
                </NavLink>
              </>
            ) : offer.getHumanReadableStatus() === "Scheduled" ? (
              <Link to={`/app/offers/${this.props.offer.id}`}>View Offer</Link>
            ) : null}
          </td>
        </tr>
      </Link>
    );
  }
}
