import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import RootStore from "../../../../store/RootStore";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import { NotificationManager } from "../../../../template/components/common/react-notifications";
import { observer } from "mobx-react";
import { IIdentityModel } from "../../../../store/models/IdentityModel";
import { FormikReactSelect } from "../../../common-components";
import { ICategoryModel } from "../../../../store/models/CategoryModel";
import { ICountryModel } from "../../../../store/models/CountryModel";
import { CountriesList } from './../../../../constants/countriesList'

const PersonalInfoSchema = Yup.object().shape({
  name: Yup.string().required("Name can't be empty"),
  country: Yup.object().shape({
    value: Yup.string().required(),
    label: Yup.string().required(),
  }),
  categories: Yup.array()
    .min(1, "Pick atleast one category")
    .of(
      Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
    ),
  // email: Yup.string().email("Enter a valid email address"),
});
interface Props {
  isOpen: boolean;
  identity: IIdentityModel;
  toggle: () => void;
}

@observer
export default class EditIdentityForm extends React.Component<Props> {
  onSubmitEditUser = async (values: any, actions: any) => {
    try {
      actions.setSubmitting(true);
      const editIdentityValues = {
        name: values.name,
        country_id: values.country.value,
        categories: values.categories.map((category: any) => category.value),
        // email: values.email ? values.email : null,
      };
      await RootStore.identityStore.editIdentity(
        this.props.identity.id,
        editIdentityValues
      );
      NotificationManager.success(
        "Done",
        "User profile was updated successfully!",
        3000,
        null,
        null,
        "filled"
      );

      this.props.toggle();
    } catch (e) {
      handleError(e);
      ajaxErrorAlert("There was a problem in editing your profile.");
    }
  };

  getUserEditInitialValues(): any {
    const { identity } = this.props;
    const country = CountriesList.find(
      (country: ICountryModel) => country.id === identity.country_id
    );
    return {
      id: identity.id,
      name: identity.name,
      categories: identity.categories.map((category) => ({
        value: category.id,
        label: category.title,
      })),
      country: { value: country.id, label: country.code },
      // email: identity.email ? identity.email : "",
    };
  }

  render() {
    const { isOpen, toggle } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit User Profile</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={this.getUserEditInitialValues()}
            validationSchema={PersonalInfoSchema}
            onSubmit={this.onSubmitEditUser}
          >
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form
                translate={"no"}
                className="av-tooltip tooltip-label-right panel-form"
              >
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Name:</b>
                    {errors.name && touched.name && (
                      <div className="invalidFeedback">{errors.name}</div>
                    )}
                  </Label>
                  <Field className="form-control w-100" name="name" />
                </FormGroup>
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Categories</b>
                    {errors.categories && touched.categories && (
                      <div className="invalidFeedback">{errors.categories}</div>
                    )}
                  </Label>
                  <FormikReactSelect
                    className="w-100"
                    name="categories"
                    value={values.categories}
                    isMulti={true}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={RootStore.categories.map(
                      (category: ICategoryModel) => ({
                        value: category.id,
                        label: category.title,
                      })
                    )}
                  />
                </FormGroup>
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Country</b>
                    {errors.country && touched.country && (
                      <div className="invalidFeedback">{errors.country}</div>
                    )}
                  </Label>
                  <FormikReactSelect
                    className="w-100"
                    name="country"
                    isMulti={false}
                    value={values.country}
                    options={CountriesList.map(
                      (country: ICountryModel) => ({
                        value: country.id,
                        label: country.code,
                      })
                    )}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                {/* <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Email:</b>
                    {errors.email && touched.email && (
                      <div className="invalidFeedback d-block">
                        {errors.email}
                      </div>
                    )}
                  </Label>
                  <Field className="form-control w-100" name="email" />
                </FormGroup> */}
                <hr />
                <Button
                  style={{ float: "right" }}
                  color="success ml-1"
                  type="submit"
                >
                  Save User Info
                </Button>
                <Button
                  style={{ float: "right" }}
                  color="secondary"
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}
