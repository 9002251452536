import React, { Component, Fragment } from "react";
import { AxiosResponse } from "axios";
import { RouteComponentProps } from "react-router-dom";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import Transport from "../../../../lib/Transport";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Button, FormGroup, Label, Spinner } from "reactstrap";
interface FormikValues {
  hashtag_name: string;
  similar_hashtags_number: number;
}
interface ISimilarHashtag {
  hashtag: string;
  similarity: number;
}
interface State {
  similarHashtags: ISimilarHashtag[];
  dataFetched: boolean;
}

const findSimilarHashtagsSchema = Yup.object().shape({
  hashtag_name: Yup.string(),
  similar_hashtags_number: Yup.number(),
});

interface Props extends RouteComponentProps {}
export default class SimilarHashtags extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      similarHashtags: [],
      dataFetched: true,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.similarHashtags = this.similarHashtags.bind(this);
  }

  getInitialValues(): FormikValues {
    return {
      hashtag_name: "",
      similar_hashtags_number: 10,
    };
  }

  async onSubmit(values: FormikValues, action: any): Promise<void> {
    try {
      this.setState({ dataFetched: false });
      let response: AxiosResponse = await Transport.post("admin/ai/hash2hash", {
        hashtag: values.hashtag_name,
        n: values.similar_hashtags_number,
      });
      this.setState({
        similarHashtags: response.data.hashtags,
        dataFetched: true,
      });

      this.similarHashtags();
    } catch (error) {
      handleError(error);
      ajaxErrorAlert("There was a problem finding similar hashtags...");
      throw error;
    }
  }

  similarHashtags(): JSX.Element {
    if (this.state.similarHashtags.length === 0 && this.state.dataFetched) {
      return <></>;
    } else if (
      (this.state.similarHashtags.length > 0 && !this.state.dataFetched) ||
      (this.state.similarHashtags.length === 0 && !this.state.dataFetched)
    ) {
      return <Spinner />;
    } else if (
      this.state.similarHashtags.length > 0 &&
      this.state.dataFetched
    ) {
      return (
        <>
          {this.state.similarHashtags.map((hashtag: ISimilarHashtag) => (
            <p className="mr-3">{hashtag.hashtag}</p>
          ))}
        </>
      );
    }
  }

  render() {
    return (
      <Fragment>
        {/* <Row>
          <Colxx xxs="12">
            <BreadCrumb heading="AI" {...this.props} />
          </Colxx>
        </Row> */}
        <Formik
          initialValues={this.getInitialValues()}
          validationSchema={findSimilarHashtagsSchema}
          onSubmit={this.onSubmit}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
          }) => (
            <Form
              translate={"no"}
              className="av-tooltip tooltip-label-right panel-form"
            >
              <FormGroup className="error-l-100">
                <Label>
                  <b>Write a hashtag below</b>
                </Label>
                <Field
                  className="form-control w-100"
                  name="hashtag_name"
                  placeholder="For e.g. Fashion"
                  // onChange={(name: string, value: string) => {
                  //   setFieldValue(name, value);
                  // }}
                  disabled={isSubmitting}
                />
                {errors.hashtag_name && touched.hashtag_name ? (
                  <div className="invalid-feedback d-block">
                    {errors.hashtag_name}
                  </div>
                ) : null}
              </FormGroup>
              <FormGroup className="error-l-100">
                <Label>
                  <b>Similar hashtags number</b>
                </Label>
                <Field
                  className="form-control w-100"
                  name="similar_hashtags_number"
                  // onChange={(name: string, value: number) => {
                  //   setFieldValue(name, value);
                  // }}
                  disabled={isSubmitting}
                />
                {errors.similar_hashtags_number &&
                touched.similar_hashtags_number ? (
                  <div className="invalid-feedback d-block">
                    {errors.similar_hashtags_number}
                  </div>
                ) : null}
              </FormGroup>

              <Button
                style={{ float: "right" }}
                color="secondary"
                disabled={isSubmitting}
              >
                Find
              </Button>
              <hr />
            </Form>
          )}
        </Formik>
        <div className="similarHashtags w-100 d-flex justify-content-start flex-wrap">
          {!this.state.dataFetched ? (
            <Spinner />
          ) : (
            <>
              {this.state.similarHashtags.length === 0 ? (
                <></>
              ) : (
                <>
                  {this.state.similarHashtags.map((item: ISimilarHashtag) => (
                    <span className="mr-3 mb-5">{item.hashtag}</span>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </Fragment>
    );
  }
}
