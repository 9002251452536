import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Separator } from '../../../../template/components/common/CustomBootstrap';
import DashboardWidgets from './DashboardWidgets';

interface Props extends RouteComponentProps {}

export default class Dashboard extends Component<Props> {
    render() {
        return (
            <React.Fragment>
                {/* <Row>
                    <Colxx xxs="12">
                        <Breadcrumb heading="menu.dashboard" match={this.props.match} />
                        <Separator className="mb-5" />
                    </Colxx>
                </Row> */}
                <h1>Dashboard</h1>
                <Separator className="mb-5" />
                <DashboardWidgets {...this.props} />
            </React.Fragment>
        );
    }
}
