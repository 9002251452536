import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { CustomInput, Label } from 'reactstrap';
import { InformationIcon } from '../../../../../../../../../assets/icons';
import classes from '../../style.module.scss';
import ReactTooltip from 'react-tooltip';
import PublishTermsFormContext from '../../context';
import DateRemainDuration from './parts/duration';

function DateRemain() {
    const { setFieldValue, values, errors, touched, updateTerms } = useContext(PublishTermsFormContext);

    return (
        <Grid container item>
            <Grid item>
                <Label className={classes.label}>
                    How long should this post remain on the Influencer’s Instagram page?
                    <InformationIcon data-tip data-for="AdDate4" className="specificLabel" />
                    <ReactTooltip id="AdDate4" type="dark" effect="solid">
                        <p style={{ width: '200px' }}>
                            Suggest a specific duration that the Ad will remain on the influencer’s page. Typically Ads
                            are removed after 1 or 2 days.
                        </p>
                    </ReactTooltip>
                </Label>
                <Grid item className={classes.content} flexDirection="column">
                    <Grid item>
                        <CustomInput
                            type="radio"
                            id="x1"
                            name="fixedDuration"
                            label="I will negotiate ad duration for each offer"
                            value={0}
                            checked={values?.fixedDuration === 0}
                            onChange={(e) => {
                                setFieldValue('fixedDuration', 0);
                                updateTerms('fixedDuration', 0);
                                setFieldValue('duration', null);
                                updateTerms('duration', null);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomInput
                            type="radio"
                            id="x2"
                            name="fixedDuration"
                            label="I want this post to be permanent"
                            value={1}
                            // @ts-ignore
                            checked={values?.fixedDuration === 1}
                            onChange={(e) => {
                                setFieldValue('fixedDuration', 1);
                                updateTerms('fixedDuration', 1);
                                updateTerms('duration', 0);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomInput
                            type="radio"
                            id="x3"
                            name="fixedDuration"
                            label="I want to set a specific duration for this content"
                            value={2}
                            // @ts-ignore
                            checked={values?.fixedDuration === 2}
                            onChange={(e) => {
                                setFieldValue('fixedDuration', 2);
                                updateTerms('fixedDuration', 2);
                                updateTerms('duration', 3600);
                            }}
                        />
                    </Grid>
                    {values?.fixedDuration === 2 ? (
                        <Grid item className="formikDuration" marginTop={1}>
                            <Label className="position-relative">
                                Ad duration:
                                {errors.duration && touched.duration && (
                                    <div className="invalidFeedback">{errors.duration}</div>
                                )}
                            </Label>
                            <DateRemainDuration />
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DateRemain;
