import React, { PureComponent } from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { observer } from 'mobx-react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Modal, ButtonDropdown, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { AdEmptyStateIcon, RightArrowIcon } from '../../../../assets/icons';
import { ICampaignModel } from '../../../../store/models/CampaignModel';
import RootStore from '../../../../store/RootStore';
import { IAdModel } from '../../../../store/models/AdModel';
import { ajaxErrorAlert, handleError, isMobileAndTablet } from '../../../../lib/Utils';
import AdCardNew from './AdCardNew';
import AdPlaceholder from './AdPlaceholder';
import Swal from "sweetalert2";
import Transport from "../../../../lib/Transport";
import { makeNewAdQuery } from "../../../../store/common/RequestHelpers";
import { thumbnailApiPath } from './../../../../constants/defaultValues'
import DatatablePagination from '../../../common-components/DatatablePagination';

interface Props extends RouteComponentProps {
    isOpen: boolean;
    toggleAdPopup: () => void;
    skipAd: () => void;
    updateAd: (id: number, ad: any) => void;
}

interface State {
    displayCampaigns: boolean;
    adList: IAdModel[];
    selectedCampaign: ICampaignModel;
    displayInvitedInfluencers: boolean;
    displayCancelDialog: boolean;
    onDisplaySuccessModal: boolean;
    dataFetched: boolean;
    campaignFilter: string;
    limit?: number;
    page?: number;
    page_count?: number;
    total?: number
}
/**
 * calss component ad selection provides this ability user show user
 * ads list and it can select and ad 
 * @param props ,  {isOpen , toggle , update }
 * @param state ,  { displayCampaigns,
                    displayInvitedInfluencers,
                    adList,
                    selectedCampaign,
                    displayCancelDialog,
                    onDisplaySuccessModal,
                    dataFetched,
                    campaignFilter
                }
 */
@observer
export default class AdSelection extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            displayCampaigns: false,
            displayInvitedInfluencers: false,
            adList: [],
            selectedCampaign: null,
            displayCancelDialog: false,
            onDisplaySuccessModal: false,
            dataFetched: false,
            campaignFilter: 'All',
            limit: 3,
            page: 0
        };
        //@ts-ignore
        this._isMount = false

        this.loadData = this.loadData.bind(this);
        this.displaySuccess = this.displaySuccess.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }
    /**
     * send request and fetch all ads
     */
    async loadData() {
        //@ts-ignore
        if (this._isMount) return
        this.setState({ dataFetched: false })
        const getMedias = medias => {
            return medias?.filter((media) => media?.deleted_at === null);
        }

        const getThumbnailUrl = (pic) => {
            try {
                return `?width=96&height=96${pic}`
            } catch (err) {
                return ''
            }
        }
        const { limit, page } = this.state

        try {
            Transport.get(`ads/created`, makeNewAdQuery(true, limit, page))
                .then(res => {
                    const { total_count } = res?.data?.meta
                    const adResults = res?.data?.ads
                    const contentResults = res?.data?.content
                    const adList = []
                    for (let i = 0; i < adResults.length; i++) {
                        const meidas = contentResults[i]?.medias[0]
                        const preview = meidas?.preview_file
                        const preview_file = {
                            created_at: preview,
                            height: preview?.height,
                            id: preview?.id,
                            label: preview?.label,
                            size: preview?.size,
                            type: preview?.type,
                            updated_at: preview?.updated_at,
                            url: `${thumbnailApiPath}/${preview?.label}.jpg?width=${96}&height=${96}`,
                            user_id: preview?.user_id,
                            width: preview?.width,
                            getThumbnailUrl: () => getThumbnailUrl(preview?.url)
                        }
                        const medias = [{
                            height: 64,
                            width: 64,
                            id: meidas?.preview_file ? meidas?.preview_file?.id : '',
                            label: meidas?.preview_file
                                ? meidas?.preview_file?.label
                                : '',
                            type: 'image/jpeg',
                            url: meidas
                                ? meidas?.url
                                : '',
                            deleted_at: meidas?.preview_file
                                ? meidas?.preview_file?.deleted_at
                                : '',
                            preview_file,
                            getThumbnailUrl: () => getThumbnailUrl(meidas?.preview_file ? meidas?.preview_file?.url : ''),

                        }]

                        adList.push({
                            id: adResults[i]?.id,
                            title: adResults[i]?.title,
                            description: adResults[i]?.description,
                            bio_link: adResults[i]?.bio_link,
                            duration: adResults[i]?.duration,
                            exclusivity: adResults[i]?.exclusivity,
                            impressions: adResults[i]?.impressions,
                            content_id: {
                                id: contentResults[i]?.content?.id,
                                created_at: contentResults[i]?.content?.created_at,
                                updated_at: contentResults[i]?.content?.updated_at,
                                data: contentResults[i]?.content?.data,
                                type: contentResults[i]?.content?.type,
                                parent_id: contentResults[i]?.content?.parent_id,
                                user_id: contentResults[i]?.content?.user_id,
                                medias,
                                getMedias: () => getMedias(contentResults[i]?.content?.medias),
                            },
                            approval: adResults[i]?.approval,
                            status: adResults[i]?.status,
                            campaign_id: adResults[i]?.campaign_id,
                            time_tables: adResults[i]?.time_tables,
                            schedule: adResults[i]?.schedule,
                            deleted_at: adResults[i]?.deleted_at,
                            invite_only: adResults[i]?.invite_only,
                            created_at: adResults[i]?.created_at,
                            updated_at: adResults[i]?.updated_at,
                            rejection_reason: adResults[i]?.rejection_reason,
                            dollars_spent: adResults[i]?.dollars_spent,
                            invitation_count: adResults[i]?.invitation_count,
                            offer_count: adResults[i]?.offer_count,
                            paid_offers_count: adResults[i]?.paid_offers_count,
                            fixedDuration: adResults[i]?.fixedDuration,
                            invitations_count: adResults[i]?.invitations_count,
                            feature: adResults[i]?.feature,
                        })
                    }
                    //@ts-ignore
                    if (this._isMount) return
                    this.setState({
                        adList,
                        dataFetched: true,
                        total: total_count
                    })
                })
                .catch(err => {
                    this.setState({
                        dataFetched: false
                    })
                    Swal.fire({
                        type: "error",
                        text: err?.response?.data?.message,
                        showCancelButton: false,
                        showConfirmButton: true,
                    });
                })
        } catch (e) {
            this.setState({
                dataFetched: false
            })
            handleError(e);
            ajaxErrorAlert('Failed to load Ads data from server! Maybe it has been deleted!');
            throw e;
        }

    }
    /**
     * 
     * @param ad_id select ad and update selected ad id
     */
    async displaySuccess(ad_id: number, ad: any) {
        this.props.updateAd(ad_id, ad);
        this.props.toggleAdPopup();
        localStorage.setItem('selected_ad', `${ad_id}`)
    }

    pageChange = (page: number) => {
        this.setState({ page: page }, this.loadData);
    }

    render() {
        const { isOpen, toggleAdPopup, skipAd } = this.props;
        const { adList, page, limit, total } = this.state
        return (
            <Modal isOpen={isOpen} toggle={toggleAdPopup} className="adSelection" fade={false}>
                <div className="adSelection__header">
                    <div>{!isMobileAndTablet() && <p>Select an Ad for this invitation list:</p>}</div>
                    {!this.state.dataFetched ? null : (
                        <ButtonDropdown
                            isOpen={this.state.displayCampaigns}
                            toggle={() =>
                                this.setState((prevState: any) => ({
                                    displayCampaigns: !prevState.displayCampaigns,
                                }))
                            }
                        >
                            {/* <DropdownToggle caret size="xs" outline className="offerListDD" color="">
                                {
                                    <>
                                        <span>Campaign</span>
                                        {this.state.campaignFilter}
                                    </>
                                }
                            </DropdownToggle> */}
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={async (event: any) => {
                                        await RootStore.campaignStore.fetchAllIfNecessary();
                                        let campaigns: ICampaignModel[] = RootStore.campaignStore.campaigns.map(
                                            (camp: ICampaignModel) => camp,
                                        );

                                        let ads: any = [];

                                        campaigns.map((campaign) =>
                                            RootStore.adStore
                                                .getAdsByCampaign(campaign.id)
                                                .map((ad: IAdModel) => ads.push(ad)),
                                        );

                                        this.setState((prevState) => ({
                                            selectedCampaign: null,
                                            campaignFilter: 'All',
                                            adList: ads,
                                            dataFetched: true,
                                        }));
                                    }}
                                >
                                    All
                                </DropdownItem>
                                {RootStore.campaignStore.campaigns.length > 0 &&
                                    RootStore.campaignStore.campaigns.map((campaign: ICampaignModel, index: number) => (
                                        <DropdownItem
                                            key={campaign.id}
                                            onClick={(event: any) => {
                                                this.setState((prevState) => ({
                                                    selectedCampaign: campaign,
                                                    campaignFilter: campaign.title,
                                                    adList: RootStore.adStore.getAdsByCampaign(campaign.id),
                                                }));
                                            }}
                                        >
                                            {campaign.title}
                                        </DropdownItem>
                                    ))}
                            </DropdownMenu>
                        </ButtonDropdown>
                    )}

                    <div>
                        <Link className="btn btn-primary" to="/app/ads/ad/objectives/">
                            Create new ad
                        </Link>
                    </div>
                </div>
                <div className="adSelection__newAdList">
                    {this.state.dataFetched ? (
                        this.state.dataFetched && this.state.adList.length > 0 ? (
                            this.state.adList.map((ad: IAdModel, index: number) => {
                                return <AdCardNew
                                    ad={ad}
                                    key={`ad_card_${index}`}
                                    onDisplaySuccessModal={() => this.displaySuccess(ad.id, ad)}
                                    {...this.props}
                                />
                            })
                        ) : (
                            <div className="adSelection__emptyState">
                                <AdEmptyStateIcon />
                                <h6 className="mt-2 mb-5 text-muted">There are no Ads to display.</h6>
                                <p>
                                    Create an Ad to invite influencers.
                                </p>
                                <p>
                                    Once an Ad is created, invite influencers to receive offers from.
                                </p>
                                <Button onClick={() => this.props.history.push(`/app/ads/ad/objectives/`)}>
                                    Create An Ad Now
                                    <RightArrowIcon />
                                </Button>
                                <Button
                                    className="skip mt-2"
                                    color="link"
                                    onClick={skipAd}
                                    style={{ boxShadow: 'none !important' }}
                                >
                                    Skip for now
                                </Button>
                            </div>
                        )
                    ) : (
                        <>
                            <AdPlaceholder />
                            <AdPlaceholder />
                            <AdPlaceholder />
                            <AdPlaceholder />
                        </>
                    )}
                    {Array.isArray(adList) && adList.length > 0 ?
                        <DatatablePagination
                            key="pagination"
                            pageSizeOptions={[10]}
                            page={page}
                            pages={Math.ceil(total / limit)}
                            canPrevious={page === 0 ? false : true}
                            canNext={(page + 1) * limit >= total ? false : true}
                            defaultPageSize={3}
                            onPageChange={this.pageChange}
                        />
                        : null}
                </div>
            </Modal>
        );
    }

    componentWillUnmount() {
        //@ts-ignore
        this._isMount = true
    }
}
