import React from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'reactstrap';
import { CloseIcon } from '../../../assets/icons';

/**
* this class component handle menu app
*/

export default class ApplicationMenu extends React.Component {
    constructor(...params) {
        super(...params);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.toggle = this.toggle.bind(this);

        this.state = {
            isOpen: this.props.isOpen,
        };
    }

    handleDocumentClick(e) {
        const container = ReactDOM.findDOMNode(this);
        if (container.contains(e.target) || container === e.target) {
            return;
        }

        this.toggle(e);
    }

    toggle(e) {
        this.props.toggleOpenHandler();
        // e.preventDefault();
        // const isOpen = this.state.isOpen;
        // if (!isOpen) {
        //   this.addEvents();
        // } else {
        //   this.removeEvents();
        // }
        // this.setState({
        //   isOpen: !isOpen
        // });
    }

    componentWillUnmount() {
        if (this.props.isOpen) {
            this.props.toggleOpenHandler();
        }
    }

    addEvents() {
        ['click', 'touchstart'].forEach((event) => document.addEventListener(event, this.handleDocumentClick, true));
    }

    removeEvents() {
        ['click', 'touchstart'].forEach((event) => document.removeEventListener(event, this.handleDocumentClick, true));
    }

    render() {
        return (
            <div className={`app-menu ${this.props.isOpen ? 'shown' : ''}`}>
                {this.props.children}

                <NavLink
                    className={`app-menu-button ${this.props.isOpen ? 'close' : 'setting'} d-inline-block d-xl-none`}
                    onClick={this.toggle}
                >
                    {this.props.isOpen ? (
                        <CloseIcon />
                    ) : (
                        <i className="simple-icon-equalizer" style={{ fontWeight: 'bold' }} />
                    )}
                </NavLink>
            </div>
        );
    }
}
