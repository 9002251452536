import "./template/assets/css/vendor/bootstrap.min.css";
import "./template/assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-table/react-table.css";

import {
  isMultiColorActive,
  defaultColor,
  themeColorStorageKey,
  isDarkSwitchActive,
} from "./constants/defaultValues";
// import * as serviceWorker from "./serviceWorker";
import { updateDefinitions } from "./store/RootStore";
import render from "./AppRenderer";
import { messaging } from "./PushNotification";

declare let module: any;

const color =
  (isMultiColorActive || isDarkSwitchActive) &&
  localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;
if (color !== null) {
  localStorage.setItem(themeColorStorageKey, color);
}
if (messaging) {
  messaging.onMessage((payload: any) =>
    console.log("Message received. ", payload)
  );
  navigator.serviceWorker.addEventListener("message", (message) =>
    console.log(message)
  );
}

// eslint-disable-next-line no-useless-concat
import("./app-style/theme" + ".scss").then((x) => {
  render();
});
document.getElementById("loadingContainerBeforeScriptLoads")!.remove();
if (module.hot) {
  module.hot.accept(["./AppRenderer"], () => {
    render();
  });
  module.hot.accept(["./store/RootStore"], () => {
    updateDefinitions();
    render();
  });
}

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
//serviceWorker.unregister();
