import React, { Component } from "react";
import { connect } from "react-redux";
import {
  // BrowserRouter as
  Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import { syncHistoryWithStore } from "mst-react-router";
import { createBrowserHistory } from "history";
import $ from "jquery";
import "react-datetime/css/react-datetime.css";

import AppLocale from "./lang";
import ColorSwitcher from "./template/components/common/ColorSwitcher";
import NotificationContainer from "./template/components/common/react-notifications/NotificationContainer";
import { isMultiColorActive } from "./constants/defaultValues";
import main from "./views";
import error from "./views/error";
import { getDirection } from "./template/helpers/Utils";
import app from "./views/app";
import { routerModel } from "./store/RootStore";
import { setFirstLocation } from "./store/RootStore";
import Admin from "./views/admin";
import LandingApp from "./views/invitation/index";
import WrongLogin from "./views/authentication/WrongLogin";

// Hook up router model to browser history object
let hs = createBrowserHistory();
hs.listen((_) => {
  $("#root").scrollTop(0);
});
const history = syncHistoryWithStore(hs, routerModel);

class App extends Component {
  componentWillMount() {
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("rtl");
    }
  }

  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale];
    setFirstLocation(window.location.pathname + window.location.search);

    return (
      <div className="h-100">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <>
            <NotificationContainer />
            {isMultiColorActive && <ColorSwitcher />}
            <Router history={history}>
              <Switch>
                <Route path="/error" exact component={error} />
                <Route path="/app" component={app} />
                <Route path="/admin" component={Admin} />
                {/* <Route path="/influencer/invitation/:id/:step?" component={LandingApp} /> */}
                <Route
                  path="/invitation/:id/:step?"
                  component={LandingApp}
                ></Route>
                <Route path="/influencer-login" component={WrongLogin} />
                <Route path="/" component={main} />
                <Redirect to="/error" />
              </Switch>
            </Router>
          </>
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(App);
