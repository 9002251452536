import React, { useContext } from 'react';
import { AdPreview } from '../../../../../../common-components';
import createAdContext from '../../../../../createAd/context';
import classes from './style.module.scss';

function OnboardPreview(props: any) {
    const {
        data: {
            marketplace: { title, description },
            terms: { schedule, bio_link, duration, fixedDuration, duration_segmentation },
            createAd: { feeds, story },
        },
    } = useContext(createAdContext);

    return (
        <div className={classes.preview}>
            <AdPreview
                values={{
                    title: title,
                    description: description,
                    schedule: schedule,
                    duration_segmentation: duration_segmentation,
                    Duration: duration,
                    fixedDuration: fixedDuration,
                    bio_link: bio_link,
                    content: {
                        medias:
                            feeds.images && feeds.images.length
                                ? feeds.images
                                : story.images && story.images.length
                                ? story.images
                                : null,
                        type:
                            feeds.images && feeds.images.length
                                ? 'Feed'
                                : story.images && story.images.length
                                ? 'Story'
                                : null,
                        data: {
                            caption: feeds.caption,
                        },
                    },
                }}
            />
        </div>
    );
}

export default OnboardPreview;
