import React, { Component } from "react";
import { Form, Formik } from "formik";
import { Button, FormGroup, Label, Row, Col } from "reactstrap";
import * as Yup from "yup";
import { Moment } from "moment";
import { FormikDatePicker } from "../../../common-components";
import moment from "moment";
import Transport from "../../../../lib/Transport";
import { FormikReactSelect } from "../../../common-components/FormikFields";

interface Props {
  onChange?: Function;
}
interface State {
  loading: boolean;
  filterOpen: boolean;
}

type Range = {
  start: Moment;
  end: Moment;
  column: any;
};

const RangeSchema = Yup.object().shape({
  start: Yup.date().required("Start date required"),
  end: Yup.date()
    .required("This field is required")
    .when("start", (start_time, schema) => {
      if (start_time) {
        const nextDay = new Date(
          start_time.getTime() + 7 * 24 * 60 * 60 * 1000
        );
        return schema
          .min(start_time, "End date should be after start date")
          .max(nextDay, "Date ranges up to one week are allowed");
      } else {
        return schema;
      }
    }),
});

export default class DateRangeFormik extends Component<Props, State> {
  private submitAction: string | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      filterOpen: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values: Range) {
    const additionalConfigs: any = { responseType: "arraybuffer" };
    const body = {
      start_date: values.start.format("YYYY-MM-DD HH:mm:ss"),
      end_date: values.end.format("YYYY-MM-DD HH:mm:ss"),
      column: values.column.value,
    };

    let res = null;

    if (this.submitAction === "statistics") {
      this.setState({ loading: true });
      res = await Transport.post(
        "v2/invitations/statistics/export",
        body,
        null,
        additionalConfigs
      );
      this.setState({ loading: false });
    } else {
      this.setState({ loading: true });
      res = await Transport.post(
        "v2/invitations/failed/export",
        body,
        null,
        additionalConfigs
      );
      this.setState({ loading: false });
    }

    const filename = `${this.submitAction}-${values.start.format(
      "YYYY-MM-DD"
    )}-${values.end.format("YYYY-MM-DD")}.xlsx`;
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }

  render() {
    return (
      <Formik
        initialValues={{
          start: moment().subtract(7, "d"),
          end: moment(),
          column: { label: "Sent at", value: "sent_at" },
        }}
        validationSchema={RangeSchema}
        onSubmit={this.onSubmit}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form
            translate={"no"}
            className="av-tooltip tooltip-label-right panel-form position-relative"
          >
            <Row className="w-100">
              <Col xs={12} xl={3}>
                <FormGroup>
                  <Label className="d-block position-relative">
                    Start date
                  </Label>
                  <FormikDatePicker
                    name="start"
                    value={values.start}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    // @ts-ignore
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="LLL"
                    timeCaption="Time"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} xl={3}>
                <FormGroup>
                  <Label className="d-block position-relative">End date</Label>
                  <FormikDatePicker
                    name="end"
                    value={values.end}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    // @ts-ignore
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="LLL"
                    timeCaption="Time"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} xl={3}>
                <FormGroup>
                  <Label className="d-block position-relative">
                    Dates based on
                  </Label>
                  <FormikReactSelect
                    name="column"
                    value={values.column}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={[
                      { label: "Sent at", value: "sent_at" },
                      { label: "Created at", value: "created_at" },
                      { label: "Updated at", value: "updated_at" },
                    ]}
                    className="w-100"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} xl={3}>
                <FormGroup>
                  <Label className="d-block position-relative invisible">
                    .
                  </Label>
                  <div className="d-flex justify-content-end">
                    <Button
                      color="success"
                      type="button"
                      className="mr-1"
                      disabled={this.state.loading}
                      onClick={() => {
                        this.submitAction = "failed";
                        handleSubmit();
                      }}
                    >
                      Export Failed
                    </Button>
                    <Button
                      color="success"
                      type="button"
                      disabled={this.state.loading}
                      onClick={() => {
                        this.submitAction = "statistics";
                        handleSubmit();
                      }}
                    >
                      Export Statistics
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <span
              className="position-absolute"
              style={{ bottom: -20, color: "#ff0000" }}
            >
              {errors.end}
            </span>
          </Form>
        )}
      </Formik>
    );
  }
}
