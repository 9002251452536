import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Row, Card, CardBody } from 'reactstrap';
// import Transport from "../../../../lib/Transport";
// import { AdminPayment } from "../../../../store/common/RequestHelpers";
import { Colxx } from '../../../../template/components/common/CustomBootstrap';
import DatatablePagination from '../../../common-components/DatatablePagination';
import WalletReportPayment from './parts/datagrid/payment';
import WalletReportPayout from './parts/datagrid/payout';
import WalletReportSearch from './parts/search';
import Swal from 'sweetalert2';
import RootStore from '../../../../store/RootStore';
import { servicePath, PAYOUT_V2, PAYMENT_V2 } from './../../../../constants/defaultValues';
import axios from 'axios';
import classes from './style.module.scss';

interface State {
    loading: boolean;
    pagination: {
        totalPages: number | null;
        page: number;
        pageSize: number;
        total_count: number;
    };
    users: any;
    search: any;
    delayTimer?: any;
    datafetched?: boolean;
    payouts?: any;
}

@observer
class WalletReport extends Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            pagination: {
                totalPages: null,
                page: 1,
                pageSize: 20,
                total_count: 0,
            },
            users: null,
            search: null,
            delayTimer: null,
            payouts: null,
        };
        this.pageChange = this.pageChange.bind(this);
        this.searchUsers = this.searchUsers.bind(this);
        this._getPayments = this._getPayments.bind(this);
        this.applychanges = this.applychanges.bind(this);
    }

    async _getPayments() {
        // this.setState({ loading: true });
        // const paginationp = {
        //     search: this.state.search,
        //     page: this.state.pagination.page,
        //     pageSize: this.state.pagination.pageSize,
        //     total_count: this.state.pagination.total_count,
        // };

        // const sort = this.checkTypePage() ? 'total_to_be_paid_money' : 'total_wallet_money'
        // const type = this.checkTypePage() ? 'influencer' : 'brand'

        // const data = await Transport.get('v1/admin/users/payments', AdminPayment(paginationp, sort, type));

        this.setState({ loading: true });
        const headers = {
            headers: {
                Authorization: 'Bearer ' + RootStore.users.getToken(),
            },
        };

        const { pageSize, page } = this.state.pagination;
        const { search } = this.state;
        const LIMIT = pageSize ? `&limit=${pageSize}` : '';
        const PAGE = page ? `&page=${page}` : '';
        const SEARCH = search ? `&search=${search}` : '';

        const url = `${servicePath}${PAYMENT_V2}?${LIMIT}${PAGE}${SEARCH}`;

        axios
            .get(url, headers)
            .then((res) => {
                this.setState({ users: res.data.payments, loading: false });
                this.setState({
                    pagination: {
                        totalPages: Math.ceil(res.data.total / 20),
                        page: res.data.current_page,
                        pageSize: 20,
                        total_count: res.data.total,
                    },
                });
            })
            .catch((err) => {
                console.log('reeee ', err);
                this.setState({ loading: false });
                Swal.fire({
                    type: 'error',
                    text: err?.response?.data?.message,
                    showCancelButton: false,
                    showConfirmButton: true,
                });
            });
    }

    _getPayouts = async () => {
        this.setState({ loading: true });
        const headers = {
            headers: {
                Authorization: 'Bearer ' + RootStore.users.getToken(),
            },
        };

        const { pageSize, page } = this.state.pagination;
        const { search } = this.state;
        const LIMIT = pageSize ? `&limit=${pageSize}` : '';
        const PAGE = page ? `&page=${page}` : '';
        const SEARCH = search ? `&search=${search}` : '';

        const url = `${servicePath}${PAYOUT_V2}?${LIMIT}${PAGE}${SEARCH}`;

        axios
            .get(url, headers)
            .then((res) => {
                this.setState((prevState) => ({
                    payouts: res.data.payouts,
                    loading: false,
                    pagination: {
                        ...prevState.pagination,
                        pagesCount: res.data.per_page,
                        totalPages: Math.ceil(res.data.total / pageSize),
                    },
                }));
            })
            .catch((err) => {
                this.setState({ loading: false });
                Swal.fire({
                    type: 'error',
                    text: err.response.data.message,
                    showCancelButton: false,
                    showConfirmButton: true,
                });
            });
    };

    async pageChange(page: number): Promise<void> {
        await this.setState({
            pagination: { ...this.state.pagination, page: page + 1 },
        });
        if (this.checkTypePage()) {
            this._getPayouts();
        } else {
            this._getPayments();
        }
    }

    checkTypePage = () => this.props.history.location.pathname === '/admin/payments';

    searchUsers(e) {
        const { value } = e.target;
        this.setState({ search: value });
        clearTimeout(this.state.delayTimer);
        const self = this;
        if (value.length > 2 || value.length < 1) {
            this.setState({
                delayTimer: setTimeout(function () {
                    if (self.checkTypePage()) {
                        self._getPayouts();
                    } else {
                        self._getPayments();
                    }
                }, 300),
            });
        }
    }

    async applychanges() {
        await this.setState({
            pagination: { totalPages: null, page: 1, pageSize: 20, total_count: 0 },
        });
        if (this.checkTypePage()) {
            this._getPayouts();
        } else {
            this._getPayments();
        }
    }

    componentDidMount() {
        if (this.checkTypePage()) {
            this._getPayouts();
        } else {
            this._getPayments();
        }
    }

    render() {
        return (
            <>
                <Card className="mb-4">
                    <CardBody>
                        <Row>
                            <Colxx>
                                <WalletReportSearch searchUsers={this.searchUsers} applychanges={this.applychanges} />
                                <div className={classes.table_box}>
                                    {this.checkTypePage() ? (
                                        <WalletReportPayout
                                            fetchData={this._getPayouts}
                                            users={this.state.payouts}
                                            loading={this.state.loading}
                                        />
                                    ) : (
                                        <WalletReportPayment users={this.state.users} loading={this.state.loading} />
                                    )}
                                </div>
                                {this.state.pagination.totalPages > 1 && (
                                    <DatatablePagination
                                        key="pagination"
                                        page={this.state.pagination.page - 1}
                                        pages={this.state.pagination.totalPages}
                                        canPrevious={this.state.pagination.page === 1 ? false : true}
                                        canNext={
                                            this.state.pagination.page === this.state.pagination.totalPages - 1
                                                ? false
                                                : true
                                        }
                                        defaultPageSize={20}
                                        onPageChange={this.pageChange}
                                    />
                                )}
                            </Colxx>
                        </Row>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default withRouter(WalletReport);
