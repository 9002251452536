import React from "react";
import { RouteComponentProps } from "react-router";
import { Badge, Button, ButtonGroup } from "reactstrap";
import moment from "moment";
import Swal from "sweetalert2";
import { observer } from "mobx-react";
import { truncate } from "lodash";

import { OfferModification } from "../../../store/common/AbstractOfferHistory";
import { NotificationManager } from "../../../template/components/common/react-notifications";
import ChatUI from "./components/ChatUI";
import SaySomething from "./components/SaySomething";
import ChatApplicationMenu from "./components/ChatApplicationMenu";
import RootStore from "../../../store/RootStore";
import {
  ajaxErrorAlert,
  handleError,
  showLoading,
  momentDateTimeFormat,
} from "../../../lib/Utils";
import {
  IOfferModel,
  PossibleOfferActions,
} from "../../../store/models/OfferModel";
import OfferForm from "./components/OfferForm";
import { RightArrowIcon } from "../../../assets/icons";
import { loadOfferWithChatDialogue } from "../../../store/models/DialogueModel";

interface State {
  offer: IOfferModel | null;
  openEditModal: boolean;
}

interface Props extends RouteComponentProps<{ id: string }> {}

@observer
export default class OfferPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      offer: null,
      openEditModal: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.performOfferAction = this.performOfferAction.bind(this);
    this.getOfferButtons = this.getOfferButtons.bind(this);
    this.handleSendButtonClick = this.handleSendButtonClick.bind(this);
    this.handleSendMediaClick = this.handleSendMediaClick.bind(this);
    this.publishButtonAction = this.publishButtonAction.bind(this);
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      let offer = null;
      try {
        offer = await loadOfferWithChatDialogue(
          parseInt(this.props.match.params.id)
        );
        this.setState({
          offer,
        });
      } catch (e) {
        ajaxErrorAlert("Could not load offer data!");
        handleError(e);
        window.location.pathname = "/app/offers"
      }
    }
  }

  async componentDidMount(): Promise<any> {
    let offer = null;
    try {
      offer = await loadOfferWithChatDialogue(
        parseInt(this.props.match.params.id)
      );
      RootStore.offerStore.setCurrentOfferModel(offer);

      this.setState({
        offer,
      });
    } catch (e) {
      ajaxErrorAlert("Could not load offer data!");
      handleError(e);
      window.location.pathname = "/app/offers"
    }
  }

  reload = async () =>{
    let offer = null;
    try {
      offer = await loadOfferWithChatDialogue(
        parseInt(this.props.match.params.id)
      );
      RootStore.offerStore.setCurrentOfferModel(offer);

      this.setState({
        offer,
      });
    } catch (e) {
      ajaxErrorAlert("Could not load offer data!");
      handleError(e);
      window.location.pathname = "/app/offers"
    }
  }

  componentWillUnmount() {
    RootStore.offerStore.setCurrentOfferModel(null);
  }

  handleSendButtonClick(msg: string) {
    if (this.state?.offer) {
      this.state?.offer?.getDialogue()?.sendMessage(msg);
    }
  }

  handleSendMediaClick(file: File) {
    if (this.state?.offer) {
      this.state?.offer?.getDialogue()?.sendMedia(file);
    }
  }

  performOfferAction(action: PossibleOfferActions) {
    switch (action) {
      case "approve-cancellation":
      case "reject-cancellation":
      case "cancel-conflict":
      case "refund-conflict":
      case "refund":
      case "cancel":
        alert("under construction... please contact support");
        break;
      case "offer":
      case "counter":
      case "edit":
      case "revise":
      case "complete":
        this.setState((prevState: any) => ({
          openEditModal: !prevState.openEditModal,
        }));
        break;
      case "accept":
        this.state?.offer?.getHistory()?.accept();
        break;
      case "reject":
        this.state?.offer?.getHistory()?.reject();
        break;
      case "terminate":
        break;
      case "contact-support":
        break;
      case "pay":
        if (
          !moment(this.state?.offer?.start, momentDateTimeFormat).isAfter(
            new Date()
          )
        ) {
          Swal.fire({
            title: "Invalid Publish Time",
            text: `The offer was due for publish ${moment(
              this.state?.offer?.start,
              momentDateTimeFormat
            ).fromNow()}, you should set a new publish date in future!`,
            showCancelButton: true,
            cancelButtonText: "Ok",
            showConfirmButton: true,
            confirmButtonText: "Revise",
            reverseButtons: true,
          }).then((res) => {
            if (res?.value) {
              this.setState((prevState) => ({ openEditModal: true }));
            }
          });
        } else {
          this.props.history.push(`/app/offers/${this.state?.offer?.id}/pay`);
        }
        break;
    }
  }

  getOfferButtons() {
    let actions = this.state?.offer?.getPossibleActions();
    let buttons = [];
    let index = -1;
    for (let action of actions) {
      index++;
      let performAction = () => {
        this.performOfferAction(action);
      };
      switch (action) {
        case "accept":
          buttons.push(
            <Button
              key={"offer_btn_" + index}
              size={"sm"}
              color={"success"}
              onClick={performAction}
            >
              <span className={"offer-icon deal mr-1"} />
              Accept
            </Button>
          );
          break;
        case "pay":
          buttons.push(
            <Button
              key={"offer_btn_" + index}
              size={"sm"}
              color={"success"}
              onClick={performAction}
            >
              <span className={"offer-icon pay mr-1"} />
              Pay Now
            </Button>
          );
          break;
        case "cancel":
          buttons.push(
            <Button
              size={"sm"}
              color={"danger"}
              key={"offer_btn_" + index}
              onClick={performAction}
            >
              <span className={"offer-icon reject mr-1"} />
              Cancel
            </Button>
          );
          break;
        case "complete":
          buttons.push(
            <Button
              key={"offer_btn_" + index}
              size={"sm"}
              color={"primary"}
              onClick={performAction}
            >
              <span className={"offer-icon edit mr-1"} />
              Complete
            </Button>
          );
          break;
        case "contact-support":
          buttons.push(
            <Button
              size={"sm"}
              color={"primary"}
              key={"offer_btn_" + index}
              onClick={performAction}
            >
              <span className={"offer-icon reject mr-1"} />
              Contact Support
            </Button>
          );
          break;
        case "counter":
          buttons.push(
            <Button
              key={"offer_btn_" + index}
              size={"sm"}
              color={"primary"}
              onClick={performAction}
            >
              <span className={"offer-icon balance mr-1"} />
              Counter
            </Button>
          );
          break;
        case "edit":
          buttons.push(
            <Button
              key={"offer_btn_" + index}
              size={"sm"}
              color={"primary"}
              onClick={performAction}
            >
              <span className={"offer-icon edit mr-1"} />
              Edit
            </Button>
          );
          break;
        case "offer":
          buttons.push(
            <Button
              key={"offer_btn_" + index}
              size={"sm"}
              color={"primary"}
              onClick={performAction}
            >
              <span className={"offer-icon make-offer mr-1"} />
              Make Offer
            </Button>
          );
          break;
        case "reject":
          buttons.push(
            <Button
              key={"offer_btn_" + index}
              size={"sm"}
              color={"danger"}
              onClick={performAction}
            >
              <span className={"offer-icon reject mr-1"} />
              Reject
            </Button>
          );
          break;
        case "revise":
          buttons.push(
            <Button
              key={"offer_btn_" + index}
              size={"sm"}
              color={"primary"}
              onClick={performAction}
            >
              <span className={"offer-icon edit mr-1"} />
              Revise
            </Button>
          );
          break;
        case "terminate":
          buttons.push(
            <Button
              size={"sm"}
              color={"danger"}
              key={"offer_btn_" + index}
              onClick={performAction}
            >
              <span className={"offer-icon reject mr-1"} />
              Terminate Offer
            </Button>
          );
          break;
      }
    }
    return <ButtonGroup>{buttons}</ButtonGroup>;
  }

  async publishButtonAction(action: string, reson?: string) {
    // if (this.state?.offer?.getLatestPublishJob()?.published_url) {
      const id = this.state?.offer?.getLatestPublishJob()?.id;
      try {
        switch (action) {
          case "approved":
            showLoading(
              "Please Wait...",
              "Please wait while we are Updating job"
            );
            const data =
              await RootStore.publishJobStore.sendApprovebyadminRequest(
                id,
                "brand:approved"
              );

            if (data) {
              Swal.fire({
                type: "success",
                title: "Job has been Updated successfully!",
              });
            } else {
              ajaxErrorAlert("This Job is already updated by Admin");
            }
            break;
          case "disapproved":
            showLoading(
              "Please Wait...",
              "Please wait while we are Updating job"
            );
            const response =
              await RootStore.publishJobStore.sendApprovebyadminRequest(
                id,
                "brand:disapproved",
                reson
              );

            if (response) {
              Swal.fire({
                type: "success",
                title: "Job has been Updated successfully!",
              });
            } else {
              ajaxErrorAlert("This Job is already updated by Admin");
            }
            break;
          default:
            ajaxErrorAlert("This action is not defined.");
            break;
        }
      } catch (e) {
        handleError(e);
        ajaxErrorAlert("There was a problem with Updated job you selected");
      }
    // } else {
    //   ajaxErrorAlert("Publish URL not found");
    // }
  }

  async onSubmit(values: any) {
    if (values.price) {
      values.price = +values.price;
    }

    if (!values.has_bio_link) {
      values.bio_link = null;
    }

    if (values.duration) {
      values.duration = +values.duration;
    }

    // if (values.exclusivity) {
    //   values.exclusivity = +values.exclusivity;
    // }
    values.exclusivity = 0;

    if (values.permanent) {
      values.duration = 0;
    }

    let datesHasChanged = false;
    if (!this.state?.offer?.start && values?.start) {
      datesHasChanged = true;
    } else {
      datesHasChanged =
        moment(this.state?.offer?.start, momentDateTimeFormat).format(
          momentDateTimeFormat
        ) !== values.start.format(momentDateTimeFormat);
    }

    if (
      this.state?.offer?.price === values?.price &&
      this.state?.offer?.duration === values?.duration &&
      !datesHasChanged &&
      // this.state?.offer.exclusivity === values.exclusivity &&
      this.state?.offer?.content_id?.id === values?.content?.id &&
      this.state?.offer?.bio_link === values?.bio_link
    ) {
      // offer has not changed
      if (this.state?.offer?.content_id?.id === values?.content?.id) {
        // old content
        let modifications = values.content.getHistoryModifications();
        if (modifications.length === 1) {
          if (modifications[0]?.body?.data === this.state?.offer?.content_id?.data) {
            await Swal.fire({
              title: "Warning!",
              text: "You have not changed anything, and therefore, there can't be any counter offer! Please at least change price, start date, duration or content.",
              type: "warning",
            });
            return;
          }
        }
      }
    }

    try {
      showLoading(
        "Countering...",
        "Please wait while we make the counter offer..."
      );
      if (values.bio_link && !values.bio_link.includes('http')) {
        values.bio_link = `http://${values.bio_link}`;
      }
      let modifications: OfferModification[] = [];
      modifications.push(...values.content.getHistoryModifications());
      modifications.push({
        type: "OFFER",
        method: "PATCH",
        id: this.state?.offer?.id,
        body: {
          exclusivity: 0,
          price: values.price,
          duration: values.duration,
          content_id: values.content.id,
          start: values.start.format(momentDateTimeFormat),
          bio_link: values.bio_link,
        },
      });
      let result = await this.state?.offer?.getHistory()?.counter(modifications);
      Swal.close();
      this.setState({ openEditModal: false });
      // this.props.history.push("/app/offers/" + this.state?.offer.id);
      NotificationManager.success(
        "Done",
        "Counter offer was made!",
        3000,
        null,
        null,
        "filled"
      );
      return result;
    } catch (e) {
      handleError(e);
      ajaxErrorAlert(
        "It failed to revise the offer!"
      );
      return false;
    }
  }

  render() {
    return (
      <div className="chat-ui">
        {this.state?.offer !== null ? (
          <>
            {this.state?.openEditModal && (
              <OfferForm
                isOpen={this.state?.openEditModal}
                onClose={() =>
                  this.setState({
                    openEditModal: false,
                  })
                }
                disabled={false}
                showSubmit={true}
                onSubmit={this.onSubmit}
                offer={this.state?.offer}
                {...this.props}
              />
            )}
            <div className="app-row">
              <div className="chat-app">
                <div className="chat-app__header">
                  <div
                    onClick={() => {
                      this.props.history.push('/app/offers/')
                    }}
                    className="offerPageBackButton"
                  >
                    <RightArrowIcon />
                  </div>
                  <div>
                    <p>
                      <span>Ad:</span>
                      {truncate(this.state?.offer?.ad_id?.title, { length: 25 })}
                    </p>
                    <Badge
                      style={{
                        fontSize: "0.8em",
                        verticalAlign: "top",
                        fontWeight: "normal",
                      }}
                      pill={true}
                      outline="true"
                      color={"outline-success"}
                    >
                      Stage: <b>{this.state?.offer?.getHumanReadableStatus()}</b>
                    </Badge>
                  </div>
                </div>
                <div
                  className="separator mb-5"
                  style={{
                    float: "left",
                    clear: "both",
                    width: "100%",
                    margin: 0,
                  }}
                />

                <ChatUI
                  getOfferButtons={this.getOfferButtons}
                  performOfferAction={this.performOfferAction}
                  offer={this.state?.offer}
                  {...this.props}
                />
              </div>
            </div>
            <SaySomething
              placeholder={"Say something..."}
              handleSendButtonClick={this.handleSendButtonClick}
              handleSendMediaClick={this.handleSendMediaClick}
            />
            <ChatApplicationMenu
              {...this.props}
              offer={this.state?.offer}
              publishButtonAction={this.publishButtonAction}
              performOfferAction={this.performOfferAction}
              offerButtons={this.getOfferButtons}
              reload={this.reload}
            />
          </>
        ) : (
          <div className="loading" />
        )}
      </div>
    );
  }
}
