import * as React from "react";
import { observer } from "mobx-react";
import { Scrollbars } from "react-custom-scrollbars";

import BubbleGroup from "./BubbleGroup";
import { IOfferModel } from "../../../../store/models/OfferModel";
import { RouteComponentProps } from "react-router";
import RootStore from "../../../../store/RootStore";
import { MessageType } from "../../../../store/models/MessageModel";
import OfferStatusUpdate from "./OfferBubble/OfferStatusUpdate";
import { BlackCloseButtonIcon } from "../../../../assets/icons";

interface Props extends RouteComponentProps {
  offer: IOfferModel;
  getOfferButtons: Function;
  performOfferAction: Function;
}

@observer
export default class ChatUI extends React.Component<Props> {
  private _elemRef: any | null = null;
  private _scrollBarRef: any | null = null;
  private _warningRef: any = null;
  private disposer2: any = null;
  private disposer3: any = null;

  constructor(props: Props | Readonly<Props>) {
    super(props);
    this._scrollBarRef = React.createRef();
    this._warningRef = React.createRef();
  }

  componentDidUpdate() {
    if (this._scrollBarRef?.current) {
      try {
        this._scrollBarRef.current.scrollToBottom();
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    let groups = this.props?.offer?.getDialogue()?.getBubbleGroups();
    return (
      <div
        className={"chat-ui"}
        ref={(ref) => {
          this._elemRef = ref;
        }}
      >
        {this.props.offer.getDialogue().showSensitiveDataWarning ? (
          <div
            className="chatUI__sensitiveContentWarning w-100"
            ref={this._warningRef}
            onClick={() => {
              this.props.offer.getDialogue().closeWarning();
            }}
          >
            <div className="closeButton">
              <BlackCloseButtonIcon />
            </div>
            To protect your payments, always communicate and pay through the
            Ainfluencer.com website or app. We hold on to your payment until the
            ad has been published.
          </div>
        ) : null}

        {this.props.offer.getDialogue().isBusy ||
          RootStore.offerStore.isBusy ? (
          <div className="loading" />
        ) : (
          <>
            {Array.isArray(groups) && groups.length === 0 ? (
              "No message in this conversation... Type something and break the ice!"
            ) : (
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                className="chatUI__scrollbarContainer"
                ref={this._scrollBarRef}
              >
                {groups.map((msgList, index) => {
                  // console.log('MSG : ',msgList)
                  let list = msgList;
                  if (!Array.isArray(msgList)) {
                    list = [msgList];
                  }

                  if (
                    list[0]?.getType() === MessageType.PUBLISH_JOB ||
                    list[0]?.getType() === MessageType.STATUS_UPDATE ||
                    list[0]?.getType() === MessageType.NULLIFY
                  ) {
                    return list.map((statusUpdateMsg) => (
                      <OfferStatusUpdate
                        msg={statusUpdateMsg}
                        offerId={this.props?.offer?.id}
                        key={`${this.props?.offer?.getDialogue()?.id
                          }_statusUpdateMessage_${index}`}
                        performOfferAction={this.props?.performOfferAction}
                      />
                    ));
                  } else {
                    return (
                      <BubbleGroup
                        offerButtons={this.props?.getOfferButtons}
                        performAction={this.props?.performOfferAction}
                        key={`${this.props?.offer?.getDialogue()?.id
                          }_batch_${index}`}
                        {...this.props}
                        self={list[0]?.isSelf}
                        offerStatus={this.props.offer.status}
                        messages={list}
                      />
                    );
                  }
                })}
              </Scrollbars>
            )}
          </>
        )}
      </div>
    );
  }
}
