import React, { Component } from "react";
import moment from "moment";

import { Spinner, Table } from "reactstrap";
import { IAdModel } from "../../../../../../../../../../../store/models/AdModel";
import { ITransactionModel } from "../../../../../../../../../../../store/models/TransactionModel";
import { NoSearchResultIcon } from "../../../../../../../../../../../assets/icons";

interface Ipagination {
  limit: number;
  page: number;
  pagesCount: number;
  totalCount: number;
}

interface Props {
  transactions: any;
  pagination: Ipagination;
  datafetched?: boolean;
}
interface State {
  transactionsList: Array<ITransactionModel>;
  displayAdPreview: boolean;
  adToBeShown: IAdModel | null;
  currentTaskIndex: number | null;
  pagination: Ipagination;
}

export default class TransactionsListPayeout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transactionsList: this.props.transactions,
      displayAdPreview: false,
      adToBeShown: null,
      currentTaskIndex: null,
      pagination: this.props.pagination,
    };
    this.onDisplayAdPreview = this.onDisplayAdPreview.bind(this);
  }
  onDisplayAdPreview() {
    this.setState((prevState) => ({
      displayAdPreview: !this.state.displayAdPreview,
    }));
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.transactions !== this.props.transactions) {
      this.setState({
        transactionsList: this.props.transactions, //new TransactionModel(this.props.transactions),
      });
    }
  }

  render() {
    const transactions = this.props?.transactions;
    const datafetched = this.props?.datafetched;

    const renderData = () => {
      if (
        Array.isArray(transactions) &&
        transactions?.length > 0 &&
        datafetched
      )
        return transactions?.map((transaction, index) => {
          const { offer } = transaction;
          const date = moment(transaction?.created_at).format(
            "MM/DD/YYYY - hh:mm a"
          );
          const ad_name = offer?.ad?.title;
          const S = transaction["source_wallet"];
          const T = transaction["target_wallet"];

          const brand = () => {
            if (S && T) {
              return (
                <>
                  <span>{S?.owner?.first_name + S?.owner?.last_name}</span>
                  <br />
                  <span>{S?.owner?.email}</span>
                </>
              );
            } else if (!S && T) {
              if (transaction?.type === "charge") {
                return (
                  <>
                    <span>
                      {T?.owner?.first_name || "" + T?.owner?.last_name || ""}
                    </span>
                    <br />
                    <span>{T?.owner?.email}</span>
                  </>
                );
              }
              //@ts-ignore
              else if (transaction?.type === "credit") {
                return "Ainfluencer";
              }
            } else if (!T && S) {
              return "Ainfluencer";
            } else {
              return "...";
            }
          };

          return (
            <>
              <tr key={date}>
                <td>{index + 1}</td>
                <td>{brand()}</td>
                <td>{ad_name}</td>
                <td>{date}</td>
                <td>{transaction.currency}</td>
                <td>{transaction.amount}</td>
                <td>{transaction.fee}</td>
                <td
                  className={
                    transaction.status === "locked"
                      ? "text-danger"
                      : transaction.status === "pending"
                      ? "text-warning"
                      : "text-success"
                  }
                >
                  {transaction.status}
                </td>
                <td>{transaction.type}</td>
              </tr>
            </>
          );
        });
    };
    const renderLoading = () =>
      !datafetched && (
        <div
          style={{
            width: "100%",
            height: 400,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner color="primary" size={"lg"} className="spinner" />
        </div>
      );
    const renderEmpty = () =>
      Array.isArray(transactions) &&
      transactions?.length === 0 &&
      datafetched && (
        <div className="searchHashtagModal__emptySearch">
          <NoSearchResultIcon />
          <br />
          <p>No result found</p>
          <p>
            <b>Try different hashtags</b>
          </p>
        </div>
      );
    return (
      <div>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Brand Info</th>
              <th>Ad Name</th>
              <th style={{ width: "20%" }}>Transaction Date</th>
              <th>Currency</th>
              <th>Amount</th>
              <th>Fees</th>
              <th>Status</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody
            style={{ minHeight: "100px", width: "100%", overflow: "auto" }}
          >
            {renderData()}
          </tbody>
        </Table>
        {renderLoading()}
        {renderEmpty()}
      </div>
    );
  }
}
