import React from "react";
import ContentLoader from "react-content-loader";

/**
 * function component wrapped by ContentLoader and show good experience for user
 * before data loaded
 * @returns some graphical element before data loaded
 */
export default function AdPlaceholder() {
  return (
    <div className="adPlaceholder">
      <ContentLoader
        speed={3}
        width={290}
        height={130}
        viewBox="0 0 290 130"
        backgroundColor="#ededed"
        foregroundColor="#c7c7c7"
      >
        <path d="M 975 306.4 L 720.6 422.1 L 975 572.4 l 254.3 -150.3 z" />
        <path d="M 720.6 422.1 L 975 572.4 V 0 z" />
        <path d="M 975 0 v 572.4 l 254.3 -150.3 z" />
        <path d="M 720.6 470.3 L 975 828.7 V 620.6 z" />
        <path d="M 975 620.6 v 208.1 l 254.5 -358.4 z" />
        <rect x="10" y="5" rx="5" ry="5" width="135" height="120" />
        <rect x="160" y="12" rx="4" ry="4" width="120" height="15" />
        <rect x="160" y="40" rx="0" ry="0" width="105" height="8" />
        <rect x="160" y="55" rx="0" ry="0" width="105" height="8" />
        <rect x="160" y="90" rx="15" ry="15" width="115" height="25" />
      </ContentLoader>
      <ContentLoader
        speed={3}
        width={290}
        height={130}
        viewBox="0 0 290 130"
        backgroundColor="#ededed"
        foregroundColor="#c7c7c7"
      >
        <rect x="145" y="15" rx="2" ry="2" width="150" height="10" />
        <rect x="20" y="15" rx="0" ry="0" width="90" height="10" />
        <rect x="145" y="45" rx="2" ry="2" width="150" height="10" />
        <rect x="145" y="75" rx="2" ry="2" width="150" height="10" />
        <rect x="145" y="105" rx="2" ry="2" width="150" height="10" />
        <rect x="20" y="45" rx="0" ry="0" width="90" height="10" />
        <rect x="20" y="75" rx="0" ry="0" width="90" height="10" />
        <rect x="20" y="105" rx="0" ry="0" width="90" height="10" />
      </ContentLoader>
    </div>
  );
}
