import React from 'react';
import { observer } from 'mobx-react';

interface Props {
    data: string;
}

@observer
export class SeeMoreSeeLessData extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);

        this.state = {
            max: 70,
        };
    }

    render() {
        const text = this.props.data;
        return (
            <span className="pre-wrap">
                {text && (
                    <>
                        {text.substr(0, this.state.max) + ' '}
                        {text.length > this.state.max && '... '}
                        {text.length > 70 && (
                            <strong
                                style={{ cursor: 'pointer', color: '#719abd', fontSize: '0.9rem' }}
                                onClick={() => {
                                    const result = this.state.max === text.length ? 70 : text.length;
                                    this.setState({ max: result });
                                }}
                            >
                                {this.state.max === text.length ? 'See Less' : 'See More'}
                            </strong>
                        )}
                    </>
                )}
            </span>
        );
    }
}
