import React from "react";
import { FormikValues } from "formik";
import MediaList from "./MediaList";
import { IMediaModel } from "../../../../../store/models/MediaModel";
import { IContentModel } from "../../../../../store/models/ContentModel";
import { observer } from "mobx-react";
import { ContentTypeEnum } from "../ContentFormDialogue";

interface Props extends FormikValues {
  name: string;
  contentType: ContentTypeEnum;
  contentClone: IContentModel;
}

@observer
export default class FormikMediaList extends React.Component<Props> {
  private handleChange(value: IMediaModel[]) {
    this.props.onChange(this.props.name, value);
    this.props.contentClone.sortMedias(value);
  }

  private handleBlur() {
    this.props.onBlur(this.props.name, true);
  }

  render() {
    return (
      // <>
      // {console.log('---')}
      // {console.log(this.props.contentClone)}
      // {console.log('---')}
      // {console.log(this.props.value)}
      // </>
      <MediaList
        contentClone={null}
        onClick={this.handleBlur.bind(this)}
        onChange={this.handleChange.bind(this)}
        value={[]}
        contentType={this.props.contentType}
      />
    );
  }
}
