import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
 * this method Metric Model
 */
export const MetricModel = types
  .model({
    id: types.identifierNumber,
    integration_id: types.number,
    medias: types.number,
    followers: types.number,
    followings: types.number,
    created_at: types.string,
    updated_at: types.string,
    engagement_rate: types.number,
  })
  .actions((self) => ({}));

export interface IMetricModel extends Instance<typeof MetricModel> {}

export interface IMetricModelSnapshotIn
  extends SnapshotIn<typeof MetricModel> {}

export interface IMetricModelSnapshotOut
  extends SnapshotOut<typeof MetricModel> {}
