import { Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import classes from './style.module.scss';
import ReactTooltip from 'react-tooltip';
import * as Yup from 'yup';
import { Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import NextButton from '../../../dashboard/multi-step-form/onboarding/parts/button';
import OnboardPreview from '../../../dashboard/multi-step-form/onboarding/parts/preview';
import { InformationIcon, NarrowArrowIcon } from '../../../../../assets/icons';
import createAdContext from '../../context';
import RootStore from '../../../../../store/RootStore';
import CreatAdLayout from '../../layout';
import BaseTransport from '../../../../../lib/BaseTransport';
import CreatAdDescription from '../description';
import API from '../../../../../lib/axiosSetup';
import Swal from 'sweetalert2';

function Marketplace(props: any) {
    const {
        baseUrl,
        mode,
        oldData,
        data: {
            adID,
            objectives,
            marketplace: { title, description },
        },
        updateOnboard,
    } = useContext(createAdContext);
    const [Process, setProcess] = useState(false);

    const initialValues = {
        title: title || '',
        description: description || '',
    };
    const AdSchema = Yup.object().shape({
        title: Yup.string().required('Ad title is required!'),
        description: Yup.string()
            .required('Ad description is required!')
            .min(3, 'Must be at least 3 characters')
            .max(2200, 'Must be at most 2200 characters'),
    });

    const updateMarketplace = (value: any, key: any) => {
        updateOnboard((prev: any) => ({ ...prev, marketplace: { ...prev.marketplace, [key]: value } }));
    };

    const nextStepRedirect = () => RootStore.router.push(`${baseUrl}/post-content/${adID ? adID : ''}`);

    const titles = 'Create an Ad - Ainfluencer Marketplace Information';
    const descriptions =
        'Your Ad title and description act as a campaign brief that is presented to influencers whenever they connect with you. include your goals, product highlights, type of influencers that you wish to work with and target customer demographics. Better you explain here less confusions influencers will have in collaborating with you.';

    return (
        <CreatAdLayout
            head={{
                title: titles,
                paragraph: descriptions,
                url: 'objectives',
            }}
        >
            <Grid item container className={classes.Marketplace} marginTop={6} justifyContent="space-between">
                <Grid item container sm={6}>
                    {mode === 'create' && <CreatAdDescription title={titles} description={<p>{descriptions}</p>} />}
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={AdSchema}
                        onSubmit={(values, actions) => {
                            if (!objectives.length) return RootStore.router.push(`${baseUrl}/objectives`);

                            if (adID && oldData) {
                                const data = {
                                    title: title,
                                    description: description,
                                };

                                const old = {
                                    title: oldData.title,
                                    description: oldData.description,
                                };
                                if (JSON.stringify(old) === JSON.stringify(data)) return nextStepRedirect();

                                setProcess(true);
                                API.put(BaseTransport.getEndPointPath(`v2/ads/${adID}`), data)
                                    .then((res) => {
                                        setProcess(false);
                                        return nextStepRedirect();
                                    })
                                    .catch(function (error) {
                                        setProcess(false);
                                        if (error.response?.data?.message) {
                                            Swal.fire({
                                                type: 'error',
                                                text: error.response.data.message,
                                                showCancelButton: false,
                                                showConfirmButton: true,
                                            });
                                        }
                                    });
                            } else {
                                nextStepRedirect();
                            }
                        }}
                    >
                        {({ setFieldValue, values, touched, errors }) => (
                            <Form style={{ width: '100%' }}>
                                <Grid container rowSpacing={4} flexDirection="column">
                                    <Grid item>
                                        <Label htmlFor="title" className="position-relative">
                                            Provide a title for your Ad:
                                            {errors.title && touched.title && (
                                                <div className="invalidFeedback">{errors.title}</div>
                                            )}
                                            <InformationIcon data-tip data-for="AdTitle" />
                                            <ReactTooltip id="AdTitle" type="dark" effect="solid">
                                                <p style={{ width: 240 }}>
                                                    Your ad title is where you give the first impression to influencers
                                                    to attract them to work with you and your campaign. Strong and
                                                    compelling titles attract more interests among influencers to send
                                                    you an offer.
                                                </p>
                                            </ReactTooltip>
                                        </Label>

                                        <Field
                                            className="form-control w-100"
                                            name="title"
                                            id="title"
                                            placeholder="Example: Halloween Makeup"
                                            onChange={(e: any) => {
                                                setFieldValue('title', e.target.value);
                                                updateMarketplace(e.target.value, 'title');
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Label htmlFor="description" className="position-relative">
                                            Describe your requirements:
                                            {errors.description && touched.description && (
                                                <div className="invalidFeedback">{errors.description}</div>
                                            )}
                                            <InformationIcon data-tip data-for="AdDescription" />
                                            <ReactTooltip id="AdDescription" type="dark" effect="solid">
                                                <p style={{ width: 240 }}>
                                                    Describe here your campaign brief. It will be presented to
                                                    influencers whenever they connect with you. Include your goals, type
                                                    of influencers, their location, your expectation in terms of what
                                                    must be done by them, and where to post it. Better you explain here,
                                                    less confusions influencers will have in collaborating with you.
                                                </p>
                                            </ReactTooltip>
                                        </Label>
                                        <Field
                                            className="form-control w-100"
                                            name="description"
                                            id="description"
                                            component="textarea"
                                            style={{ height: 160 }}
                                            placeholder="Example: Describe your campaign with details"
                                            onChange={(e: any) => {
                                                setFieldValue('description', e.target.value);
                                                updateMarketplace(e.target.value, 'description');
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <NextButton loading={Process}>
                                        {!adID && (
                                            <>
                                                Next <NarrowArrowIcon className="ml-2" />
                                            </>
                                        )}
                                    </NextButton>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                <Grid
                    item
                    container
                    sm={5}
                    display={{ xs: 'none', lg: 'flex', sm: 'flex', md: 'flex' }}
                    justifyContent="center"
                >
                    <Grid item sm={6}>
                        <OnboardPreview />
                    </Grid>
                </Grid>
            </Grid>
        </CreatAdLayout>
    );
}

export default withRouter(Marketplace);
