import React, { PureComponent } from 'react';
import { Button, Spinner } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';

import AdSelectionNew from '../components/AdSelectionNew';
import { Separator } from '../../../../template/components/common/CustomBootstrap';
import { BreadCrumb } from '../../../common-components';
import { ucfirst } from '../../../../lib/Utils';
import RootStore from '../../../../store/RootStore';
import { ajaxErrorAlert, handleError, isMobileAndTablet } from '../../../../lib/Utils';
import { explorerSearchType, InfluencerGroup } from '../../../../lib/CustomTypes';
import GroupListNew from './GroupListNew';
import SelectedInflucners from './SelectedInfluencers';
import CancelInvitation from '../components/CancelInvitationWarning';
import SearchHashtagNew from './SearchHashtagNew';
// import { IHashtagModel } from '../../../../store/models/HashtagModel';
// import Transport from '../../../../lib/Transport';
import { SortOrder } from './../../../../lib/QueryTypes';
import { CountriesList } from './../../../../constants/countriesList';
import { EditIcon } from '../../../../assets/icons';
import ControlOfferPopup from '../../../common-components/ControlOfferPopup';
import { EmptyHashtagSearchIcon } from '../../../../assets/icons';

interface Props extends RouteComponentProps {}

interface State {
    displayWarning: boolean;
    fullyLoaded: boolean;
    influencers: Array<InfluencerGroup>;
    totalFound: number;
    showHashtagModal: boolean;
    adSelectionOpen: boolean;
    userDefaultHashtags?: Array<any> | null;
    userDefaultLocation?: Array<any> | null;
    userDefaultCategory?: Array<any> | null;
    controlOfferModalisOpen?: boolean;
    controlOfferAd?: any;
    brandInfo?: any;
    isBusy?: boolean;
}
const EmptyHashtags = ({ toggleSearchHashtagsModal }) => (
    <div className="influencersGroupList__emptyHashtag mb-5">
        <EmptyHashtagSearchIcon />
        <div className="mt-2">Add hashtags to find relevant influencers</div>
        <div className="manageHashtags mt-2" onClick={toggleSearchHashtagsModal}>
            # Manage Hashtags
        </div>
    </div>
);

@observer
export default class Explorer extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            displayWarning: false,
            fullyLoaded: false,
            influencers: [],
            totalFound: 0,
            showHashtagModal: false,
            adSelectionOpen: false,
            userDefaultHashtags: null,
            userDefaultLocation: null,
            userDefaultCategory: null,
            controlOfferModalisOpen: false,
            controlOfferAd: {
                followers: '',
                engagement: '',
                languages: [],
                countries: [],
                gender: 'all',
                inviteOnly: false,
            },
            brandInfo: {
                categories: [],
                country: [],
                hashtags: [],
            },
            isBusy: true,
        };
    }

    async componentDidMount() {
        try {
            const skip_ad = localStorage.getItem('skip_ad');

            await RootStore.fetchStaticData();

            const ID = await RootStore?.users?.currentUser?.id;
            const USER_ID = await RootStore.identityStore.getByUserId(ID)?.id;

            const user_id = await +localStorage.getItem('user_id');
            if (user_id || USER_ID) {
                await RootStore.campaignStore.fetchAllIfNecessary(user_id);
            }
            // await RootStore.hashtagsStore.fetchAll(user_id);

            const Ads = await RootStore.adStore.getAllAds();

            const brandCatCountry = await RootStore.identityStore.getByUserId(ID);
            const country = brandCatCountry?.country_id;
            const categories = brandCatCountry?.categories?.map((item) => item);

            const brandHashtags = (await RootStore?.hashtagsStore?.hashtags.map((item) => item?.name)) || [];

            this.setState((prevState) => {
                return {
                    brandInfo: {
                        ...prevState.brandInfo,
                        categories,
                    },
                };
            });

            // initial selected ad
            const selectedAdId = +localStorage.getItem('selected_ad');
            if (selectedAdId) {
                const checkExistAd = await Ads.find((item) => +item.id === +selectedAdId);
                const ad_info = await JSON.parse(localStorage.getItem('ad_info'));
                const limit_country = ad_info?.limit_country?.length > 0 ? ad_info?.limit_country : null;

                const ad_hashtags = ad_info?.ad_hashtags
                    ? ad_info?.ad_hashtags?.match(/#\w+/g)?.map((item) => item.substring(1))
                    : [];
                if (checkExistAd) {
                    RootStore.adStore.setAd(selectedAdId);
                    this.setState((prevState) => {
                        return {
                            brandInfo: {
                                ...prevState.brandInfo,
                                country: limit_country ? limit_country : [country],
                                hashtags: ad_hashtags,
                            },
                        };
                    });
                } else {
                    localStorage.removeItem('ad_info');
                    localStorage.removeItem('selected_ad');

                    RootStore.adStore.setAd(null);
                    this.setState((prevState) => {
                        return {
                            adSelectionOpen: true,
                            brandInfo: {
                                ...prevState.brandInfo,
                                country: [country],
                                hashtags: brandHashtags,
                            },
                        };
                    });
                }
            } else {
                if (Ads.length === 0) {
                    if (!skip_ad) {
                        this.setState((prevState) => {
                            return {
                                adSelectionOpen: true,
                                brandInfo: {
                                    ...prevState.brandInfo,
                                    country: [country],
                                    hashtags: brandHashtags,
                                },
                            };
                        });
                    }
                } else if (Ads.length === 1) {
                    const id = Ads[0]?.id;
                    RootStore.adStore.setAd(+id);
                    localStorage.setItem('selected_ad', id);

                    await RootStore.adStore.getAdByIdInfo(+id);

                    const ad_info = await JSON.parse(localStorage.getItem('ad_info'));
                    const limit_country = ad_info?.limit_country?.length > 0 ? ad_info?.limit_country : null;
                    const ad_hashtags = ad_info?.ad_hashtags
                        ? ad_info?.ad_hashtags?.match(/#\w+/g)?.map((item) => item.substring(1))
                        : [];

                    this.setState((prevState) => {
                        return {
                            brandInfo: {
                                ...prevState.brandInfo,
                                country: limit_country ? limit_country : [country],
                                hashtags: ad_hashtags,
                            },
                        };
                    });
                } else {
                    this.setState((prevState) => {
                        return {
                            adSelectionOpen: true,
                            brandInfo: {
                                ...prevState.brandInfo,
                                country: [country],
                                hashtags: brandHashtags,
                            },
                        };
                    });
                }
            }
            this.setState({ isBusy: false });
        } catch (err) {
            console.log('initial page err : ', err.response);
            this.setState({ isBusy: false });
        }
    }

    toggleSearchHashtagsModal = () => {
        this.setState({ showHashtagModal: !this.state.showHashtagModal });
    };

    saveHashtags = async (hashtags: string[]) => {
        try {
            const ad_info = JSON.parse(localStorage.getItem('ad_info'));
            const ad_hashtags_1 = ad_info?.ad_hashtags ? ad_info?.ad_hashtags.split(' ') : [];
            const ad_hashtags_2 = ad_hashtags_1.concat(hashtags.map((item) => `#${item}`));
            ad_info.ad_hashtags = ad_hashtags_2.join(' ');
            localStorage.setItem('ad_info', JSON.stringify(ad_info));

            this.setState((prevState) => {
                return {
                    brandInfo: {
                        ...prevState.brandInfo,
                        hashtags,
                    },
                };
            });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('Failed to add hashtags! Please reload the page!');
        }
    };

    // check select ad or not for custom search page
    goCustomSearch = async () => {
        try {
            const adInfo = JSON.parse(localStorage.getItem('ad_info'));
            const skip_ad = localStorage.getItem('skip_ad');
            let newCat = await this.state.brandInfo?.categories?.map((item, i) => {
                return {
                    label: item.title,
                    value: item.id,
                    slug: item.slug,
                };
            });
            const initialSearchObj = {
                country: '',
                hashtags: [],
                categories: newCat,
                followers: {
                    min: 5,
                    max: 1000,
                },
                language: '',
                gender: { label: 'All', value: null },
                inviteOnly: undefined,
                sorts: [
                    {
                        key: 'followers',
                        direction: SortOrder.DESCENDING,
                    },
                ],
            };
            const customSearchMethod = async () => {
                if (adInfo) {
                    const countriesList = CountriesList || [];
                    const name = countriesList?.find((li) => li.id === adInfo?.limit_country[0])?.name;
                    const id = countriesList?.find((li) => li.id === adInfo?.limit_country[0])?.id;

                    if (name && id) {
                        //@ts-ignore
                        initialSearchObj.country = {
                            label: name,
                            value: id,
                        };
                    } else {
                        initialSearchObj.country = '';
                    }

                    const limit_gender = adInfo?.limit_gender || 'all';
                    if (limit_gender === 'male') {
                        //@ts-ignore
                        initialSearchObj.gender = { label: 'Male', value: 'male' };
                    } else if (limit_gender === 'female') {
                        //@ts-ignore
                        initialSearchObj.gender = { label: 'Female', value: 'female' };
                    } else {
                        //@ts-ignore
                        initialSearchObj.gender = { label: 'All', value: null };
                    }

                    initialSearchObj.inviteOnly = adInfo?.invite_only;
                    initialSearchObj.hashtags = adInfo?.ad_hashtags
                        ? adInfo?.ad_hashtags
                              ?.match(/#\w+/g)
                              ?.map((item) => item.substring(1))
                              ?.filter((_, i) => i < 7)
                        : [];
                    initialSearchObj.language = '';
                    initialSearchObj.followers = {
                        min: 5,
                        max: 1000,
                    };
                    await RootStore.discoverInfluencersStore.setSearchObj({
                        ...initialSearchObj,
                    });
                    this.props.history.push('/app/influencer-explorer/custom-search');
                } else {
                    await RootStore.discoverInfluencersStore.setSearchObj({
                        ...initialSearchObj,
                    });
                    this.props.history.push('/app/influencer-explorer/custom-search');
                }
            };

            if (!RootStore.adStore.selectedAd) {
                await RootStore.adStore.fetchAll();
                const Ads = RootStore.adStore.getAllAds();
                if (Ads.length === 0) {
                    if (!skip_ad) {
                        this.setState({ adSelectionOpen: true });
                    } else {
                        customSearchMethod();
                    }
                } else if (Ads.length === 1) {
                    const ID = Ads[0].id;
                    RootStore.adStore.setAd(ID);
                    // selected ad save to storage
                    localStorage.setItem('selected_ad', ID);
                } else {
                    this.setState({ adSelectionOpen: true });
                }
            } else {
                // const selectedAd = RootStore.adStore.getAllAds().find(item=>item.id === RootStore.adStore.selectedAd)
                // const selectedAdArrHashtags = selectedAd?.content_id?.data?.caption?.match(/#\w+/g) || []

                // selected ad save to storage
                // this.props.history.push({
                //     pathname:'/app/influencer-explorer/custom-search',
                //     state:{
                //         from: "influencer-explorer",
                //         name: "hashtag",
                //         value: selectedAdArrHashtags,
                //         update_filter:true
                //     }
                // })
                customSearchMethod();
            }
        } catch (err) {
            console.log('goCustomSearch error ');
        }
    };

    updateAd = async (id: number, ad: any) => {
        await localStorage.setItem('control_offer_ad', JSON.stringify(ad));
        this.setState({ isBusy: true });
        RootStore.adStore.setAd(id);
        // selected ad save to storage
        localStorage.setItem('selected_ad', `${id}`);
        await RootStore.adStore.getAdByIdInfo(id);
        /**
         * clear influencers suggest list
         */
        try {
            await RootStore.fetchStaticData();
            await RootStore.hashtagsStore.fetchAllIfNecessary(
                RootStore.identityStore.getByUserId(RootStore?.users?.currentUser?.id)?.id,
            );
            const adInfo = await JSON.parse(localStorage.getItem('ad_info'));
            const hashtags = adInfo?.ad_hashtags
                ? adInfo?.ad_hashtags?.match(/#\w+/g)?.map((item) => item.substring(1))
                : [];
            const country = adInfo?.limit_country?.length > 0 ? adInfo?.limit_country : [];

            if (hashtags.length > 0) {
                this.setState((prevState) => {
                    return {
                        brandInfo: {
                            ...prevState?.brandInfo,
                            hashtags,
                        },
                    };
                });
            }
            if (country.length > 0) {
                this.setState((prevState) => {
                    return {
                        brandInfo: {
                            ...prevState?.brandInfo,
                            country,
                        },
                    };
                });
            }
            this.setState({ isBusy: false });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('Failed to get static data! please refresh the page...');
        }
    };

    skipAd = () => {
        this.setState({ adSelectionOpen: false });
        localStorage.setItem('skip_ad', '1');
    };

    toggleAdPopup = () => {
        this.setState((prevState) => {
            return { adSelectionOpen: !prevState.adSelectionOpen };
        });
    };

    controlOfferModal = async () => {
        const control_offer_ad = await JSON.parse(localStorage.getItem('control_offer_ad'));
        this.setState({
            controlOfferModalisOpen: true,
            controlOfferAd: control_offer_ad,
        });
    };

    onCloseControlOfferModal = () => {
        this.setState({
            controlOfferAd: null,
            controlOfferModalisOpen: false,
        });
    };

    reloadList = async () => {
        await RootStore.adStore.fetchAllAdsData({ page: 1, pageSize: 500 });
        const selected_ad = (await +localStorage.getItem('selected_ad')) || +RootStore.adStore.selectedAd;
        const adList = await RootStore.adStore.getAllAds().filter((ad: any) => ad.status !== 'paused');
        const updated_ad = await adList.find((item) => +item?.id === +selected_ad);
        await localStorage.setItem('control_offer_ad', JSON.stringify(updated_ad));
        await RootStore.adStore.getAdByIdInfo(selected_ad);

        const limit_country = updated_ad?.limit_country?.length > 0 ? updated_ad?.limit_country : [];
        const ad_info = JSON.parse(localStorage.getItem('ad_info')) || updated_ad;
        ad_info.limit_country = limit_country;
        this.setState((prevState) => {
            return {
                brandInfo: {
                    ...prevState.brandInfo,
                    country: limit_country,
                },
            };
        });
    };

    renderHeader = (type, value) => {
        return type === explorerSearchType.HASHTAG ? (
            <p className="d-flex align-items-center">
                {!isMobileAndTablet() ? 'Influencers associated with hashtag' : 'hashtag:'}{' '}
                <span className="ml-2 theme-blue">#{ucfirst(value)}</span>
                <button className="searchInfluencerAddHashtag" onClick={this.toggleSearchHashtagsModal}>
                    # Manage hashtags
                </button>
            </p>
        ) : type === explorerSearchType.CATEGORY ? (
            !isMobileAndTablet() ? (
                <p>
                    Influencers in <span className="theme-blue">{ucfirst(value)}</span> category:
                </p>
            ) : (
                <p>
                    category: <span className="theme-blue">{ucfirst(value)}</span>
                </p>
            )
        ) : type === explorerSearchType.LOCATION ? (
            <p>
                Influencers nearby:{' '}
                <span className="theme-blue">
                    {CountriesList.map((c) => {
                        if (c.code === value) return c.name;
                        return null;
                    })}
                </span>
            </p>
        ) : null;
    };

    renderEmptyMsg = (group) => {
        return group.by === 'hashtag' ? (
            <h3>
                Could not find any influencer in <span className="theme-blue">#{ucfirst(group.title)}</span> hashtag
            </h3>
        ) : group.by === 'category' ? (
            <h3>
                Could not find any influencer in <span className="theme-blue">{ucfirst(group.title)}</span> category
            </h3>
        ) : (
            <h3>Could not find any influencer in your nearby</h3>
        );
    };

    seeMoreHandler = async (type, value) => {
        const skip_ad = localStorage.getItem('skip_ad');

        const seeMoreMethod = async () => {
            const initialSearchObj = {
                country: '',
                hashtags: [],
                categories: [],
                followers: {
                    min: 5,
                    max: 1000,
                },
                language: '',
                gender: { label: 'All', value: null },
                inviteOnly: undefined,
                sorts: [
                    {
                        key: '_score',
                        direction: SortOrder.DESCENDING,
                    },
                ],
            };

            const renderValue = () => {
                switch (type) {
                    case explorerSearchType.LOCATION:
                        let country = { label: '', value: '' };
                        if (CountriesList?.length > 0) {
                            const findedCountry = CountriesList?.find((item) => item?.id === value);
                            country = {
                                label: findedCountry?.name || '',
                                value: findedCountry?.id || '',
                            };
                        }
                        RootStore.discoverInfluencersStore.setSearchObj({
                            ...initialSearchObj,
                            country,
                        });
                        return country;
                    case explorerSearchType.CATEGORY:
                        const newCat = {
                            label: value?.title,
                            value: value?.id,
                            slug: value?.slug,
                        };
                        RootStore.discoverInfluencersStore.setSearchObj({
                            ...initialSearchObj,
                            categories: [newCat],
                        });
                        return [newCat];
                    case explorerSearchType.HASHTAG:
                        RootStore.discoverInfluencersStore.setSearchObj({
                            ...initialSearchObj,
                            hashtags: [value],
                        });
                        return [value];
                    default:
                        return '';
                }
            };

            const val = await renderValue();
            this.props.history.push({
                pathname: '/app/influencer-explorer/custom-search',
                state: {
                    value: val,
                    update_filter: true,
                    from: 'see-more',
                    type,
                },
            });
        };

        if (!RootStore.adStore.selectedAd) {
            await RootStore.adStore.fetchAll();
            const Ads = RootStore.adStore.getAllAds();
            if (Ads.length === 0) {
                if (!skip_ad) {
                    this.setState({ adSelectionOpen: true });
                } else {
                    seeMoreMethod();
                }
            } else if (Ads.length === 1) {
                const ID = Ads[0].id;
                RootStore.adStore.setAd(ID);
                // selected ad save to storage
                localStorage.setItem('selected_ad', ID);
            } else {
                this.setState({ adSelectionOpen: true });
            }
        } else {
            seeMoreMethod();
        }
    };

    render() {
        const checkArr = (arr) => Array.isArray(arr) && arr.length > 0;
        const { isBusy } = this.state;
        const { hashtags, country, categories } = this.state?.brandInfo;
        return (
            <>
                <div className="findInfluencers">
                    <AdSelectionNew
                        {...this.props}
                        isOpen={this.state.adSelectionOpen}
                        updateAd={this.updateAd}
                        skipAd={this.skipAd}
                        toggleAdPopup={this.toggleAdPopup}
                    />
                    <SearchHashtagNew
                        isOpen={this.state?.showHashtagModal}
                        toggle={this.toggleSearchHashtagsModal}
                        action={this.saveHashtags}
                        loadIdentityHashtags={true}
                        hashtags={hashtags}
                    />
                    <div className="findInfluencers__breadCrumb">
                        <div>
                            <BreadCrumb heading="Discover & Invite Influencers" {...this.props} />
                        </div>
                        <div className="flex text-center">
                            <Button className="findInfluencersButton" color="primary" onClick={this.goCustomSearch}>
                                <i className="simple-icon-magnifier"> </i>
                                Search
                            </Button>
                            <p className="mb-0 mt-1">
                                <i>( Use Custom Search to refine search results )</i>
                            </p>
                        </div>
                    </div>
                    <Separator className="mb-3" />
                    <div className="findInfluencers__header">
                        {this.state.isBusy ? (
                            <h2>
                                <Spinner color="primary" style={{ fontWeight: 400 }} />{' '}
                                {!isMobileAndTablet()
                                    ? 'AInfluencer is finding influencers for you...'
                                    : 'finding influencers for you...'}
                            </h2>
                        ) : RootStore.adStore.selectedAd ? (
                            <h2 className="findInfluencers__header_box">
                                Influencers for{' '}
                                <span
                                    onClick={() =>
                                        this.setState({
                                            adSelectionOpen: !this.state.adSelectionOpen,
                                        })
                                    }
                                    className="ml-1"
                                >
                                    {'  '}{' '}
                                    {RootStore.adStore.ads.find((ad) => ad.id === RootStore.adStore.selectedAd)?.title}
                                </span>
                                <p
                                    className="findInfluencers__header_icon"
                                    onClick={() =>
                                        this.setState({
                                            adSelectionOpen: !this.state.adSelectionOpen,
                                        })
                                    }
                                >
                                    <EditIcon />
                                </p>
                                <p
                                    className="findInfluencers__header_icon_offer_control"
                                    onClick={this.controlOfferModal}
                                    data-tip
                                    data-for="Control Offer"
                                >
                                    <img
                                        alt="setting"
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABNUlEQVRIibWVTU7DMBCFP1qJDeUQrYTgACCxLN71JF1EqrpFZc1t2gXpCQIr4Dz9k1DYTCR3Oo6nCjzpSUn83hvHiT3gQwXUipXTm0Uf2BgFtjLWGXdGeMPbnLkXXV8CI0MzafFbYyPJOsGrzOoLmAGPwIcxa8130c7EW0vWSdWtI8zLPXATF1j9YXjDVRMeHOI3YAxcCZ+A0uELAEVG9KzXM8Ii4y0a4RQ4JGaeg/UmB8k8QgB2Sjh2FNBLvJNnJvSOHTgKXCvPJh7sWY4zcaHu61SBYBR8cBS4V/d9jCVKfeTSUWBt+I4+cu43XbSEv2S8Bfg2Wim6gTAkZm5uNIClQ3wul/GrDvnnww7s49pqlZoVjuMa0g1n3hI+N/TJhpNCp5bpQaem7zkqfoBv4/mnjLXiF5CfCtFi5/niAAAAAElFTkSuQmCC"
                                    />
                                </p>
                                <ReactTooltip id="Control Offer" type="dark" effect="solid">
                                    <p>Advanced Settings</p>
                                </ReactTooltip>
                            </h2>
                        ) : (
                            <h2 className="">
                                Please select an Ad to invite Influencers:{' '}
                                <span
                                    className="selectAd"
                                    onClick={() =>
                                        this.setState({
                                            adSelectionOpen: !this.state.adSelectionOpen,
                                        })
                                    }
                                >
                                    {RootStore.adStore.getAllAds().length > 0 ? 'Select an Ad' : 'Create your first Ad'}
                                </span>
                            </h2>
                        )}
                    </div>

                    {/* country */}
                    {!isBusy &&
                        checkArr(country) &&
                        country
                            .filter((_, i) => i === 0)
                            .map((item) => {
                                return (
                                    <>
                                        <GroupListNew
                                            key={`country_${item}`}
                                            toggleAdSction={this.toggleAdPopup}
                                            header={this.renderHeader(explorerSearchType.LOCATION, item)}
                                            seeMoreHandler={() =>
                                                this.seeMoreHandler(explorerSearchType.LOCATION, item)
                                            }
                                            value={item}
                                            type={explorerSearchType.LOCATION}
                                        />
                                    </>
                                );
                            })}
                    {/* hashtags */}
                    {!isBusy &&
                        checkArr(hashtags) &&
                        hashtags
                            .filter((_, i) => i === 0)
                            .map((item) => {
                                return (
                                    <>
                                        <GroupListNew
                                            key={`hashtag_${item}`}
                                            toggleAdSction={this.toggleAdPopup}
                                            header={this.renderHeader(explorerSearchType.HASHTAG, item)}
                                            seeMoreHandler={() => this.seeMoreHandler(explorerSearchType.HASHTAG, item)}
                                            value={item}
                                            type={explorerSearchType.HASHTAG}
                                            {...this.props}
                                        />
                                    </>
                                );
                            })}
                    {!isBusy && !checkArr(hashtags) && (
                        <EmptyHashtags toggleSearchHashtagsModal={this.toggleSearchHashtagsModal} />
                    )}

                    {/* categories */}
                    {!isBusy &&
                        checkArr(categories) &&
                        categories
                            .filter((_, i) => i === 0)
                            .map((item) => {
                                return (
                                    <GroupListNew
                                        key={`category_${item?.slug}`}
                                        toggleAdSction={this.toggleAdPopup}
                                        header={this.renderHeader(explorerSearchType.CATEGORY, item?.title)}
                                        seeMoreHandler={() => this.seeMoreHandler(explorerSearchType.CATEGORY, item)}
                                        value={item?.slug}
                                        type={explorerSearchType.CATEGORY}
                                        {...this.props}
                                    />
                                );
                            })}
                    {RootStore?.invitationStore?.temporaryList.length > 0 && (
                        <SelectedInflucners
                            {...this.props}
                            onCancel={() =>
                                this.setState((prevState) => ({
                                    displayWarning: !prevState.displayWarning,
                                }))
                            }
                        />
                    )}
                    <CancelInvitation
                        dialogOpen={this.state?.displayWarning}
                        onConfirm={() => {
                            RootStore.invitationStore.clearList();
                            this.setState((prevState) => ({
                                displayWarning: !prevState.displayWarning,
                            }));
                        }}
                        onCancel={() =>
                            this.setState((prevState) => ({
                                displayWarning: !prevState.displayWarning,
                            }))
                        }
                    />
                </div>
                {this.state.controlOfferModalisOpen && (
                    <ControlOfferPopup
                        isOpen={this.state.controlOfferModalisOpen}
                        onClose={this.onCloseControlOfferModal}
                        ad={this.state.controlOfferAd}
                        reloadList={this.reloadList}
                    />
                )}
            </>
        );
    }
}

// seeMoreHandler = async (influencerGroup) => {
//     const skip_ad = localStorage.getItem('skip_ad')
//     const { title, by } = influencerGroup
//     // event.preventDefault();
//     // localStorage.removeItem('selected_ad')
//     // RootStore.adStore.setAd(null)
//     const seeMoreMethod = async () => {
//         let queryParam = influencerGroup.queryParam.value;
//         if (influencerGroup.queryParam.name === explorerSearchType.CATEGORY) {
//             queryParam = {
//                 label: influencerGroup.queryParam.value[0].label,
//                 value: influencerGroup.queryParam.value[0].value,
//                 slug: influencerGroup.queryParam.value[0].slug,
//             };
//         }

//         const initialSearchObj = {
//             country: '',
//             hashtags: [],
//             categories: [],
//             followers: {
//                 min: 5,
//                 max: 1000,
//             },
//             language: '',
//             gender: { label: 'All', value: null },
//             inviteOnly: undefined,
//             sorts: [
//                 {
//                     key: 'followers',
//                     direction: SortOrder.DESCENDING,
//                 },
//             ],
//         }

//         const list = CountriesList || []
//         // const renderValue = (key) =>{
//         //     console.log('[queryParam] : ',[queryParam])
//         //     switch (key) {
//         //         case explorerSearchType.LOCATION:
//         //             let country = {label:'',value:''}
//         //             if(list?.length > 0){
//         //                 const findedCountry = list?.find(item => item?.id === queryParam)
//         //                 country = {
//         //                     label:findedCountry?.name || '',
//         //                     value:findedCountry?.id || ''
//         //                 }
//         //             }
//         //             RootStore.discoverInfluencersStore.setSearchObj({...initialSearchObj,country});
//         //             return country
//         //         case explorerSearchType.CATEGORY:
//         //             RootStore.discoverInfluencersStore.setSearchObj({...initialSearchObj,categories :[queryParam]});
//         //             return [queryParam]
//         //         case explorerSearchType.HASHTAG:
//         //             RootStore.discoverInfluencersStore.setSearchObj({...initialSearchObj,hashtags :[queryParam]});
//         //             return [queryParam]
//         //         default:
//         //             return ''
//         //     }
//         // }
//         const renderValue = () => {
//             switch (by) {
//                 case explorerSearchType.LOCATION:
//                     let country = { label: '', value: '' }
//                     if (list?.length > 0) {
//                         const findedCountry = list?.find(item => item?.id === queryParam)
//                         country = {
//                             label: findedCountry?.name || '',
//                             value: findedCountry?.id || ''
//                         }
//                     }
//                     RootStore.discoverInfluencersStore.setSearchObj({ ...initialSearchObj, country });
//                     return country
//                 case explorerSearchType.CATEGORY:
//                     RootStore.discoverInfluencersStore.setSearchObj({ ...initialSearchObj, categories: [queryParam] });
//                     return [queryParam]
//                 case explorerSearchType.HASHTAG:
//                     RootStore.discoverInfluencersStore.setSearchObj({ ...initialSearchObj, hashtags: [title] });
//                     return [title]
//                 default:
//                     return ''
//             }
//         }

//         const name = influencerGroup?.queryParam?.name
//         const val = await renderValue()
//         this.props.history.push({
//             pathname: '/app/influencer-explorer/custom-search',
//             state: {
//                 name,
//                 value: val,
//                 update_filter: true,
//                 from: 'see-more',
//                 by
//             },
//         });
//     }
//     if (!RootStore.adStore.selectedAd) {
//         await RootStore.adStore.fetchAll();
//         const Ads = RootStore.adStore.getAllAds();
//         if (Ads.length === 0) {
//             if (!skip_ad) {
//                 this.setState({ adSelectionOpen: true })
//             } else {
//                 seeMoreMethod()
//             }
//         } else if (Ads.length === 1) {
//             const ID = Ads[0].id
//             RootStore.adStore.setAd(ID);
//             // selected ad save to storage
//             localStorage.setItem('selected_ad', ID)
//         } else {
//             this.setState({ adSelectionOpen: true });
//         }
//     } else {
//         seeMoreMethod()
//     }

// }
