import { hideSensitiveData } from "./../../lib/Utils";
import {
  flow,
  getSnapshot,
  Instance,
  resolveIdentifier,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import { IMessageModel, MessageModel, MessageType } from "./MessageModel";
import moment, { Moment } from "moment";
import TwilioFacade from "../../lib/TwilioFacade";
import Transport from "../../lib/Transport";
import { Message } from "twilio-chat/lib/message";
import RootStore from "../RootStore";
import { IUserModel } from "./UserModel";
import {
  ajaxErrorAlert,
  handleError,
  makeTempModelId,
  momentDateTimeFormat,
} from "../../lib/Utils";
import { IOfferModel } from "./OfferModel";

function transformAttributes(attributes: any) {
  if (
    typeof attributes["type"] !== "undefined" &&
    attributes["type"] === "offer:created"
  ) {
    attributes = {
      type: "update",
      data: {
        batch: -1,
      },
    };
  }
  return attributes;
}

TwilioFacade.msgDependencyResolver = async (msg: Message) => {
  let attributes = msg.attributes as any;
  if (typeof attributes["data"] !== "undefined") {
    let model: IDialogueModel | undefined = resolveIdentifier(
      DialogueModel,
      RootStore.chatStore,
      msg.channel.sid
    );

    if (typeof model === "undefined") {
      handleError(
        new Error(
          "ConnectyCubeFacadeOnMessageReceived: Could not resolve dialogue model with id, " +
            msg.channel.sid
        )
      );
    }

    // check if we have already loaded the batch number
    if (
      !model.offer.getHistory().batchDataExists(attributes["data"]["batch"])
    ) {
      // if batch data is not there, load it from server
      await model.offer
        .getHistory()
        .fetchUpdateByBatch(attributes["data"]["batch"]);
    }
  }
};

export const DialogueModel = types
  .model({
    id: types.identifier,
    isTyping: types.boolean,
    typingUserId: types.maybeNull(types.number),
    messages: types.array(MessageModel),
  })
  .volatile((self) => ({
    isBusy: false,
    fetchedFromServer: false,
    joined: false,
    lastMessageId: null,
    channel: null,
    offer: null,
    unreadMessages: 0,
    showSensitiveDataWarning: false,
  }))
  .actions((self) => ({

    /**
     * this method Country Model
     */
    setBusy() {
      self.isBusy = true;
    },

    /**
    * this method just set value isBusy = false
    */
    setIdle() {
      self.isBusy = false;
    },

    /**
    * this method set offer
    * @param offer, this param get offer 
    */
    setOffer(offer: IOfferModel) {
      self.offer = offer;
    },

    fetchChannel: flow(function* () {
      if (!self.channel) {
        self.channel = yield TwilioFacade.getChannelBySid(self.id);
      }
    }),

    /**
    * this method close Warning
    */
    closeWarning() {
      self.showSensitiveDataWarning = false;
    },
  }))
  .actions((self) => ({

    /**
    * this method set Typing
    * @param user, this param get user 
    * @param isTyping, this param check isTyping or not
    */
    setTyping(user: number, isTyping: boolean) {
      self.isTyping = isTyping;
      self.typingUserId = user;
    },

    /**
    * this method send Message
    * @param msg, this param get message for send
    */
    sendMessage: flow(function* (msg: string) {
      if (msg.length === 0) {
        return;
      }

      if (hideSensitiveData(msg).triggered) {
        self.showSensitiveDataWarning = true;
      }

      let model = MessageModel.create({
        id: makeTempModelId().toString(),
        msg: msg,
        isSent: false,
        isRead: false,
        isDelivered: true,
        isSelf: true,
        timestamp: moment().toISOString(),
        offer_id: self.offer.id,
        attributes: {},
      });

      self.messages.push(model);

      try {
        let xid = yield TwilioFacade.sendMessage(self.channel as any, msg);
        model.id = (xid as number).toString();
        model.isSent = true;
        self.lastMessageId = model.id;
        yield self.channel.updateLastConsumedMessageIndex(parseInt(xid));
        (self.offer as IOfferModel).setLastMessage({
          attributes: [],
          index: parseInt(xid),
          message: msg,
          user_id: RootStore.users.currentUser.id,
        });
      } catch (e) {
        handleError(e);
        ajaxErrorAlert("Could not send message!");
      }
    }),

    /**
    * this method send Media
    * @param file, this param get file for send
    */
    sendMedia: flow(function* (file: File) {
      let model = MessageModel.create({
        id: makeTempModelId().toString(),
        msg: "",
        isSent: false,
        isRead: false,
        isDelivered: true,
        isSelf: true,
        timestamp: moment().toISOString(),
        offer_id: self.offer.id,
        attributes: {},
      });
      self.messages.push(model);

      const response = yield Transport.upload("files", [
        { name: "file", file: file },
      ]);

      if (response?.data?.file?.url) {
        const fileUrl = response.data.file.url;

        try {
          let xid = yield TwilioFacade.sendMessage(
            self.channel as any,
            fileUrl
          );
          model.id = (xid as number).toString();
          model.isSent = true;
          model.msg = fileUrl;
          model.attributes = {};
          self.lastMessageId = model.id;
          yield self.channel.updateLastConsumedMessageIndex(parseInt(xid));
          (self.offer as IOfferModel).setLastMessage({
            attributes: {},
            index: parseInt(xid),
            message: fileUrl,
            user_id: RootStore.users.currentUser.id,
          });
        } catch (e) {
          handleError(e);
          ajaxErrorAlert("Could not send media!");
        }
      } else {
        ajaxErrorAlert("Could not send media!");
      }
    }),

    /**
    * this method receive Message
    * @param msg, this param message received
    * @param index, this param message ID 
    * @param author, this param author ID message received
    * @param attributes, this param get attributes
    */
    receiveMessage(
      msg: string,
      index: number,
      author: number,
      attributes: any
    ) {
      if (
        self.messages.filter((cMsg) => {
          return getSnapshot(cMsg).id === index.toString();
        }).length
      ) {
        return;
      }

      if (author === (RootStore.users.currentUser as IUserModel).id) {
        if (typeof attributes["data"] === "undefined") {
          return;
        }
      }

      if (hideSensitiveData(msg).triggered) {
        self.showSensitiveDataWarning = true;
      }

      let msgModel = MessageModel.create({
        id: index.toString(),
        msg: msg,
        isSent: true,
        isRead: false,
        attributes: transformAttributes(attributes),
        isDelivered: true,
        isSelf: author === (RootStore.users.currentUser as IUserModel).id,
        timestamp: moment().toISOString(),
        offer_id: self.offer.id,
      });

      self.messages.push(msgModel);

      if (RootStore.offerStore.currentOpenedOffer !== self.offer.id) {
        if (!msgModel.isSelf) {
          self.unreadMessages++;
        }
      } else {
        (async () => {
          await self.channel.updateLastConsumedMessageIndex(
            parseInt(msgModel.id)
          );
        })();
      }

      (self.offer as IOfferModel).setLastMessage({
        attributes: msgModel.attributes,
        index,
        message: msg,
        user_id: author,
      });

      self.lastMessageId = index.toString() as any;
    },

    /**
    * this method fetch Unread Message Count
    */
    fetchUnreadMessageCount: flow(function* (): Generator<any, void, any> {
      self.setBusy();
      yield self.fetchChannel();
      self.unreadMessages = yield self.channel.getUnconsumedMessagesCount();
      self.setIdle();
    }),

    /**
    * this method load History
    */
    loadHistory: flow(function* (): any {
      if (self.fetchedFromServer) {
        (async () => {
          await self.channel.updateLastConsumedMessageIndex(
            parseInt(self.messages[self.messages.length - 1].id)
          );
        })();
        self.unreadMessages = 0;
        return;
      }
      try {
        self.channel = yield TwilioFacade.getChannelBySid(self.id);
        // @ts-ignore
        let result = yield self.channel.getMessages();

        // let minServerBatchId = Math.min.apply(
        //   null,
        //   result.items
        //     .filter(
        //       (item: Message) =>
        //         item.body.length === 0 && (item.attributes as any).data?.batch !== undefined
        //     )
        //     .map((item: Message) => {
        //       return parseInt((transformAttributes(item.attributes) as any).data.batch);
        //     })
        // );

        // result.items = result.items.filter(
        //     (msgModel: any) => msgModel.body && msgModel.body.length > 0
        // );
        self.messages.replace(
          result.items.map((msgModel: Message) => {
            return MessageModel.create({
              id: msgModel.index.toString(),
              isRead: true,
              isDelivered: false,
              isSent: true,
              attributes: transformAttributes(msgModel.attributes),
              msg: msgModel.body,
              timestamp: msgModel.timestamp.toISOString(),
              isSelf:
                msgModel.author ===
                (RootStore.users.currentUser as IUserModel).id.toString(),
              offer_id: self.offer.id,
            });
          })
        );

        self.unreadMessages = 0;
        yield self.channel.updateLastConsumedMessageIndex(
          parseInt(self.messages[self.messages.length - 1].id)
        );
        self.fetchedFromServer = true;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),
  }))
  .views((self) => ({

    /**
    * this method get Bubble Groups
    */
    getBubbleGroups(): IMessageModel[][] {
      if (self.messages.length) {
        let messageBatchList = [];
        let currentPartyIsSelf = self.messages[0].isSelf;
        let currentBatch = [];
        for (let msg of self.messages) {
          if (currentPartyIsSelf == null) {
            currentPartyIsSelf = msg.isSelf;
          }

          if (
            msg.getType() === MessageType.PUBLISH_JOB ||
            msg.getType() === MessageType.STATUS_UPDATE
          ) {
            // clear the current batch
            if (currentBatch.length) {
              messageBatchList.push(currentBatch.slice());
              currentBatch.splice(0, currentBatch.length);
            }
            messageBatchList.push([msg]);
            currentPartyIsSelf = null;
            continue;
          }

          if (msg.getType() === MessageType.NULLIFY) {
            // clear the current batch
            if (currentBatch.length) {
              messageBatchList.push(currentBatch.slice());
              currentBatch.splice(0, currentBatch.length);
            }
            // push the status update
            messageBatchList.push([msg]);
            currentPartyIsSelf = null;
            // push the message if exists
            if (msg.msg.length) {
              const simpleMessageClone = MessageModel.create({
                ...getSnapshot(msg),
                attributes: {},
              });
              messageBatchList.push([simpleMessageClone]);
              currentPartyIsSelf = simpleMessageClone.isSelf;
            }
            continue;
          }

          if (msg.isSelf === currentPartyIsSelf) {
            currentBatch.push(msg);
          } else {
            messageBatchList.push(currentBatch.slice());
            currentBatch.splice(0, currentBatch.length);
            currentBatch.push(msg);
            currentPartyIsSelf = msg.isSelf;
          }
        }

        if (currentBatch.length !== 0) {
          messageBatchList.push(currentBatch);
        }
        return messageBatchList;
      }
      return [];
    },

    /**
    * this method get Last Message Moment
    */
    getLastMessageMoment(): Moment {
      return self.channel !== null && self.channel.lastMessage
        ? moment(self.channel.lastMessage.timestamp, momentDateTimeFormat)
        : null;
    },
  }));

export async function loadOfferWithChatDialogue(
  id: number
): Promise<IOfferModel> {
  let offer = await RootStore.offerStore.getOffer(id, true);
  offer.getDialogue().setBusy();

  try {
    await Promise.all([
      (async () => {
        await offer.getHistory().preload(0);
      })(),

      (async () => {
        await offer.getDialogue().loadHistory();
      })(),
    ]);
    offer.getDialogue().setIdle();
  } catch (e) {
    offer.getDialogue().setIdle();
    throw e;
  }
  return offer;
}

export interface IDialogueModel extends Instance<typeof DialogueModel> {}

export interface IDialogueModelSnapshotIn
  extends SnapshotIn<typeof DialogueModel> {}

export interface IDialogueModelSnapshotOut
  extends SnapshotOut<typeof DialogueModel> {}
