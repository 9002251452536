import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CreateAd from '../../../createAd';
import { route_wellcome } from './model';
import Wellcome from './parts/wellcome';

function Wizard() {
    return (
        <Switch>
            <Route path={route_wellcome} exact={true} component={Wellcome} />
            <Route
                path={route_wellcome + '/:page/:adID?'}
                exact
                render={() => <CreateAd route_base={route_wellcome} mode="onboard" />}
            />
            {/* {routesOnboarding.map((el: any) => <Route path={el.path} exact component={el.component} />)} */}
        </Switch>
    );
}

export default Wizard;
