import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import DatatablePagination from "../../../../views/common-components/DatatablePagination";
import FilterComponent from "../../../common-components/Filter/FilterComponent";
import ServerSideFilterSet from "../../../common-components/Filter/ServerSideFilterSet";
import { Separator } from "../../../../template/components/common/CustomBootstrap";
import EmptySearchResult from "../../../app/offers/components/EmptySearchResult";
import {
  FilterType,
  Range,
} from "../../../common-components/Filter/AbstractFilterSet";
import SuspendedUsersList from "./SuspendedUsersList";
import { SortOrder } from "../../../../lib/QueryTypes";
import { debounce } from "lodash";
import { Filters } from "./Users";
import { Spinner } from "reactstrap";
import RootStore from "../../../../store/RootStore";
import { observer } from "mobx-react";

interface Props extends RouteComponentProps {}

interface State {
  dataFetched: boolean;
  integrations: [];
  searchTerm: string | null;
  sort: any;
  filter_groups: { [key: string]: string };
  pagination: {
    totalPages: number;
    page: number;
    pageSize: number;
    totalCount: number;
  };
}
@observer
export default class SuspendedUsersTab extends Component<Props, State> {
  private readonly filterSet: ServerSideFilterSet;
  constructor(props: Props) {
    super(props);
    this.state = {
      dataFetched: false,
      integrations: [],
      searchTerm: null,
      sort: {},
      filter_groups: { qc_status: "temporary suspend" },
      pagination: {
        page: 0,
        pageSize: 10,
        totalPages: 0,
        totalCount: 0,
      },
    };

    this.filterSet = new ServerSideFilterSet(debounce(this.onChange, 600));

    this.filterSet.addFilter({
      name: "ig_date",
      title: "IG Date",
      type: FilterType.simpleFilter,
      defaultValue: null,
      values: () => [null, SortOrder.ASCENDING, SortOrder.DESCENDING],
      valueToLabelConverter: (value: string | number | Range) =>
        value === null
          ? "All"
          : value === SortOrder.ASCENDING
          ? "Ascending"
          : "Descending",
    });

    this.filterSet.addFilter({
      name: "followers",
      title: "Followers",
      type: FilterType.simpleFilter,
      defaultValue: null,
      values: () => [null, SortOrder.ASCENDING, SortOrder.DESCENDING],
      valueToLabelConverter: (value: string | number | Range) =>
        value === null
          ? "All"
          : value === SortOrder.ASCENDING
          ? "Ascending"
          : "Descending",
    });

    // this.filterSet.addFilter({
    //   name: "quality_metric",
    //   title: "Quality Score",
    //   type: FilterType.simpleFilter,
    //   defaultValue: null,
    //   values: () => [null, SortOrder.ASCENDING, SortOrder.DESCENDING],
    //   valueToLabelConverter: (value: string | number | Range) =>
    //     value === null
    //       ? "All"
    //       : value === SortOrder.ASCENDING
    //       ? "Ascending"
    //       : "Descending",
    // });

    this.filterSet.addFilter({
      name: "qc_status",
      title: "Status",
      defaultValue: "temporary suspend",
      type: FilterType.simpleFilter,
      values: () => [null, "active", "temporary suspend", "permanent suspend"],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === "temporary suspend") {
          return "Temporary suspended";
        } else if (value === "permanent suspend") {
          return "Permanently suspended";
        } else if (value === "active") {
          return "Active";
        } else {
          return "All";
        }
      },
    });
  }

  async componentDidMount() {
    this.fetchUsers();
  }

  onChange = (filters: Filters) => {
    const { qc_status, ...sorts } = filters.filters;

    const filterGroup = {};
    if (qc_status) {
      filterGroup["qc_status"] = qc_status;
    }

    // handle sorts

    // remove nulls
    for (const key in sorts) {
      if (sorts[key] === null) delete sorts[key];
    }
    // detect new changed sort and make others null
    const previousSort = this.state.sort || {};
    let sortsGroup = null;
    if (previousSort.ig_date !== sorts.ig_date) {
      sortsGroup = { ig_date: sorts.ig_date };
      this.filterSet.setFilterValue("followers", null, false);
      // this.filterSet.setFilterValue("quality_metric", null, false);
    }
    //
    else if (previousSort.followers !== sorts.followers) {
      sortsGroup = { followers: sorts.followers };
      this.filterSet.setFilterValue("ig_date", null, false);
      // this.filterSet.setFilterValue("quality_metric", null, false);
    }
    //
    // else if (previousSort.quality_metric !== sorts.quality_metric) {
    //   sortsGroup = { quality_metric: sorts.quality_metric };
    //   this.filterSet.setFilterValue("ig_date", null, false);
    //   this.filterSet.setFilterValue("followers", null, false);
    // }

    this.setState(
      {
        searchTerm: filters.searchTerm,
        pagination: {
          ...this.state.pagination,
          page: 0,
        },
        filter_groups: filterGroup,
        sort: sortsGroup,
      },
      this.fetchUsers
    );
  };

  fetchUsers = async () => {
    this.setState({ dataFetched: false });
    let response = await RootStore.integrationStore.fetchAllV2(
      this.state.pagination,
      this.state.filter_groups,
      this.state.sort,
      this.state.searchTerm
    );
    this.setState({
      dataFetched: true,
      integrations: response.integrations,
      pagination: response.pagination,
    });
  };

  pageChange = (page: number) => {
    this.setState(
      {
        pagination: { ...this.state.pagination, page },
      },
      this.fetchUsers
    );
  };
  pageSizeChange = (pageSize: number) => {
    this.setState(
      {
        pagination: { ...this.state.pagination, page: 0, pageSize },
      },
      this.fetchUsers
    );
  };

  clearFilter = () => {
    this.setState({
      searchTerm: null,
      pagination: {
        page: 0,
        pageSize: 10,
        totalPages: 0,
        totalCount: 0,
      },
      sort: {},
      filter_groups: { qc_status: "temporary suspend" },
    });
  };

  render() {
    const { dataFetched, pagination, integrations } = this.state;

    return (
      <>
        <FilterComponent
          filterSet={this.filterSet}
          clearFilter={this.clearFilter}
        />
        <Separator className="mb-4 mt-3" />
        {!dataFetched ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "20em" }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Spinner color="primary" className="mb-1" />
              <p>Please wait...</p>
            </div>
          </div>
        ) : (
          <>
            {integrations.length > 0 ? (
              <React.Fragment>
                <SuspendedUsersList
                  integrations={this.state.integrations}
                  pagination={pagination}
                  fetchUsers={this.fetchUsers}
                  {...this.props}
                />
                <DatatablePagination
                  key="pagination"
                  page={pagination.page}
                  pages={pagination.totalPages}
                  showPageSizeOptions={true}
                  pageSizeOptions={[10, 50, 100, 200, 500]}
                  onPageSizeChange={this.pageSizeChange}
                  canPrevious={pagination.page === 0 ? false : true}
                  canNext={
                    pagination.page === pagination.totalPages - 1 ? false : true
                  }
                  defaultPageSize={pagination.pageSize}
                  onPageChange={this.pageChange}
                />
              </React.Fragment>
            ) : (
              <EmptySearchResult />
            )}
          </>
        )}
      </>
    );
  }
}
