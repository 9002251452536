import { createContext } from "react"

export interface ICreateAdContext {
    adID: number
    status: null | string
    objectives: any
    marketplace: {
        title: string
        description: string
    }
    createAd: {
        feeds: {
            images: any
            caption: any
        },
        story: {
            images: any
        }
    },
    terms: {
        fixedSchedule: any,
        fixedDuration: any,
        schedule: any,
        duration: any,
        duration_segmentation: {
            segment_name: string,
            segment_time: number
        },
        displayBioLink: any,
        bio_link: any,
    },
    discover: {
        hashtags: any,
        gender: any,
        countries: any,
        offer: any,
    },
    company: {
        name: string
        description: string
        country: any
    }
}

interface IContext {
    oldData: any
    baseUrl: string
    loadData: boolean
    mode?: 'onboard' | 'create'
    data: ICreateAdContext,
    updateOnboard: any,
}

const createAdContext = createContext<IContext>({
    oldData: null,
    baseUrl: '',
    mode: 'onboard',
    loadData: true,
    data: {
        adID: null,
        status: null,
        objectives: null,
        marketplace: {
            title: null,
            description: null
        },
        createAd: {
            feeds: {
                images: null,
                caption: null
            },
            story: {
                images: null
            }
        },
        terms: {
            fixedSchedule: null,
            fixedDuration: null,
            schedule: null,
            duration: null,
            duration_segmentation: {
                segment_name: "hours",
                segment_time: 1
            },
            displayBioLink: null,
            bio_link: null,
        },
        discover: {
            hashtags: null,
            gender: null,
            countries: null,
            offer: null,
        },
        company: {
            name: '',
            description: '',
            country: null
        }
    },
    updateOnboard: null,

})
export default createAdContext