import ApiQueryBuilder from "../../../../lib/ApiQueryBuilder";
import { SortOrder, FilterGroup } from "../../../../lib/QueryTypes";

export function userQueryBuilder(
  pagination: { pageSize: number; page: number },
  filterGroups: FilterGroup[] = [],
  sort: { key: string; direction: SortOrder },
  search
): ApiQueryBuilder {
  let query = new ApiQueryBuilder();
  if (filterGroups.length !== 0) {
    query.addFilterGroupList(filterGroups);
  }

  if (sort) {
    query.addSort(sort.key, sort.direction);
  }

  query.addIncludesUser("identities", true);
  query.addIncludesUser("identities.logo", false);
  query.addIncludesUser("integrations", true);
  query.addIncludesUser("integrations.picture", false);
  query.setPagination(pagination.pageSize, pagination.page);
  query.setSearch(search) // new search method
  return query;
}
export function identityQueryBuilder(
  pagination: { pageSize: number; page: number },
  filterGroups: FilterGroup[] = [],
  sort: { key: string; direction: SortOrder },
  search,
  category,
  type
): ApiQueryBuilder {
  let query = new ApiQueryBuilder();
  if (filterGroups.length !== 0) {
    query.addFilterGroupList(filterGroups);
  }

  if (sort) {
    query.addSort(sort.key, sort.direction);
  }

  query.addIncludes("logo", false);
  // query.addIncludes("categories", false);
  query.addIncludes("user", true);
  query.addIncludes("integrations", true);
  query.setPagination(pagination.pageSize, pagination.page);
  query.setSearch(search) // new search method
  query.setCategory(category) // new category method
  query.setType(type) // new type method
  return query;
}

export function integrationQueryBuilder(
  pagination: { pageSize: number; page: number },
  filterGroups: FilterGroup[] = [],
  sort: { key: string; direction: SortOrder },
  search
): ApiQueryBuilder {
  let query = new ApiQueryBuilder();
  if (filterGroups.length !== 0) {
    query.addFilterGroupList(filterGroups);
  }

  if (sort) {
    query.addSort(sort.key, sort.direction);
  }
  query.addIncludesIntegration("picture", false);
  query.addIncludesIntegration("identities", true);
  query.addIncludesIntegration("user", true);
  query.setPagination(pagination.pageSize, pagination.page);
  query.setSearch(search) // new search method
  return query;
}
