import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import CreatAdUploads from '../../../image'
import CreatAdStoryContext from './context'
import CreatAdStoryFileUpload from './parts/fileUpload'
import classes from './style.module.scss'

function CreatAdStoryImage() {
    const { images: { list, remove } } = useContext(CreatAdStoryContext)
    return (
        <Grid container item className={classes.upload} padding={3} flexDirection="column">
            <CreatAdStoryFileUpload />
            <CreatAdUploads list={list} remove={remove} />
        </Grid>
    )
}

export default CreatAdStoryImage
