import {
  applySnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import { PublishJobStore } from "./PublishJobStore";
import { FileModel, IFileModelSnapshotIn } from "./models/FileModel";

export const FileStore = types
  .model({
    files: types.array(FileModel),
  })
  .actions((self) => ({
    syncMissingModels(models: IFileModelSnapshotIn[]) {
      if (!Array.isArray(models)) {
        return;
      }

      let currentIdList: number[] = self.files.map((file) => {
        return file.id;
      });
      let possiblyUpdatedModels;
      if (currentIdList.length !== 0) {
        possiblyUpdatedModels = models.filter(
          (model) => currentIdList.indexOf(model.id) >= 0
        );
        if (possiblyUpdatedModels.length) {
          for (let content of self.files) {
            for (let updatedFile of possiblyUpdatedModels) {
              if (content.id === updatedFile.id) {
                applySnapshot(content, updatedFile);
              }
            }
          }
        }
        self.files.replace(
          self.files.concat(
            models
              .filter((model) => currentIdList.indexOf(model.id) === -1)
              .uniqueById()
          )
        );
      }
    },
  }));

export interface IFileStore extends Instance<typeof PublishJobStore> {}

export interface IFileStoreSnapshotIn
  extends SnapshotIn<typeof PublishJobStore> {}

export interface IFileStoreSnapshotOut
  extends SnapshotOut<typeof PublishJobStore> {}
