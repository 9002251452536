import React, { useState, useRef, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import RootStore from '../../store/RootStore';
import { ajaxErrorAlert, handleError, showLoading } from '../../lib/Utils';
import { VerificationCodeFormIcon } from '../../assets/icons';
import { ITokenModel } from '../../store/models/TokenModel';
import { isStaging } from './../../constants/defaultValues'

interface Props extends RouteComponentProps<{}, {}, { email: string; token: ITokenModel; user_id: number, phone: any }> { }

export default function ValidateConfirmationCode(props: Props) {
  const _buttonRef = useRef<HTMLButtonElement>(null);
  const _pin_0 = useRef<HTMLInputElement>(null);
  const _pin_1 = useRef<HTMLInputElement>(null);
  const _pin_2 = useRef<HTMLInputElement>(null);
  const _pin_3 = useRef<HTMLInputElement>(null);
  const _pin_4 = useRef<HTMLInputElement>(null);

  const [pinZero, setPinZero] = useState('');
  const [pinOne, setPinOne] = useState('');
  const [pinTwo, setPinTwo] = useState('');
  const [pinThree, setPinThree] = useState('');
  const [pinFour, setPinFour] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [resendCode, setResendCode] = useState<{
    timer: number;
    displayResend: boolean;
  }>({
    timer: 60,
    displayResend: false,
  });

  const pastFromClipboard = useCallback(
    async (event: ClipboardEvent) => {
      if (event.clipboardData.getData('text').toString().trim().length === 5) {
        let textCode = event.clipboardData.getData('text').toString().trim();
        for (let index = 0; index < 5; index++) {
          if (textCode.charCodeAt(index) < 47 || textCode.charCodeAt(index) > 57) {
            Swal.fire({
              type: 'error',
              title: '',
              text: 'Please paste a valid five digit code to verify your email',
            });
            return;
          }
        }
        await setPinZero(textCode[0]);
        await setPinOne(textCode[1]);
        await setPinTwo(textCode[2]);
        await setPinThree(textCode[3]);
        await setPinFour(textCode[4]);
        const submitCode = async () => {
          let code = `${pinZero}${pinOne}${pinTwo}${pinThree}${pinFour}`;
          console.log("new code", code)
          try {
            showLoading('Checking Activation Code...', 'Please wait activating your account...');
            await RootStore.users.verifyEmailAddress(textCode, props.location.state['token'], true);
            if (!isStaging()) {
              //@ts-ignore
              ga('send', {
                hitType: 'event',
                eventCategory: 'Sign Up',
                eventAction: 'Verify Email Address',
                eventLabel: '',
              })
            }
            Swal.close();
          } catch (error) {
            handleError(error);
            ajaxErrorAlert('There was a problem with verify your code.').then((res) => {
              if (res.value) {
                _pin_0.current?.focus();
              }
            });
          }
        };
        await submitCode();
      }
    },
    [pinFour, pinOne, pinThree, pinTwo, pinZero, props.location.state],
  );
  useEffect(() => {
    if (!props.location.state) {
      props.history.push('/login');
    }
    const interval_id = setInterval(() => {
      if (resendCode.timer > 0) {
        setResendCode((prevState) => ({
          ...prevState,
          timer: prevState.timer - 1,
        }));
      }

      if (resendCode.timer === 0) {
        setResendCode((prevState) => ({ ...prevState, displayResend: true }));
      }
    }, 1000);

    return () => {
      clearInterval(interval_id);
    };
  }, [resendCode, props.history, props.location]);
  useEffect(() => {
    if (!props.location.state) {
      props.history.push('/login');
    }

    document.addEventListener('paste', pastFromClipboard);

    if (!pinFour && !pinThree && !pinTwo && !pinOne && !pinZero && _pin_0?.current) {
      _pin_0.current.focus();
    } else if (pinZero && !pinOne && !pinTwo && !pinThree && !pinFour) {
      _pin_1.current.focus();
    } else if (pinZero && pinOne && !pinTwo && !pinThree && !pinFour) {
      _pin_2.current.focus();
    } else if (pinZero && pinOne && pinTwo && !pinThree && !pinFour) {
      _pin_3.current.focus();
    } else if (pinZero && pinOne && pinTwo && pinThree && !pinFour) {
      _pin_4.current.focus();
    }
    return () => {
      document.removeEventListener('paste', pastFromClipboard);
    };
  }, [pinZero, pinOne, pinTwo, pinThree, pinFour, pastFromClipboard, props.history, props.location.state]);
  const resendActivationCode = async () => {
    showLoading('Sending activation code...');

    try {
      let response = await RootStore.users.resendVerificationEmail(
        props.location.state.token.access_token.toString(),
      );

      if (response.status === 200) {
        await Swal.fire({
          type: 'success',
          title: '',
          text: 'The activation code has been sent to your email',
        });
        setResendCode((prevState) => ({
          timer: 60,
          displayResend: false,
        }));
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleChange = (e, setValue) => {
    const { value } = e.target;
    if (!isNaN(Number(value))) {
      setValue(value);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let code = `${pinZero}${pinOne}${pinTwo}${pinThree}${pinFour}`;
    try {
      showLoading('Checking Activation Code...', 'Please wait activating your account...');
      await RootStore.users.verifyEmailAddress(code, props.location.state['token'], true);
      if (!isStaging()) {
        //@ts-ignore
        ga('send', {
          hitType: 'event',
          eventCategory: 'Sign Up',
          eventAction: 'Verify Email Address',
          eventLabel: '',
        })
      }
      Swal.close();
      setIsSubmitting(false);
    } catch (error) {
      handleError(error);
      ajaxErrorAlert('There was a problem with verify your code.').then((res) => {
        if (res.value) {
          _pin_0.current?.focus();
          setIsSubmitting(false);
        }
      });
    }
  };



  return (
    <div className="authForm">
      <div className="authForm__side">
        <p className="text-white h2">Success!</p>
        <p className="white">
          A verification code has been sent to {props.location.state?.email}{' '}
          <span
            className="verificationCode_Link"
            onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
              event.preventDefault();

              props.history.push('/change-email', {
                email: props.location.state.email,
                phone: props.location.state.phone,
                token: props.location.state.token,
                user_id: props.location.state.user_id,
              });
            }}
          >
            Change
          </span>
          .<br />
          Please check your inbox and insert your code in the next screen.
        </p>
        <br />
        <NavLink to={`/register`}>Back To Registration</NavLink>
      </div>
      <div className="verificationCodeForm authForm__form">
        <VerificationCodeFormIcon />

        <h3>Enter five digit code here</h3>
        <div className="verificationCodeForm__input">
          <input
            ref={_pin_0}
            value={pinZero}
            min={0}
            autoFocus
            max={9}
            maxLength={1}
            onChange={(e) => handleChange(e, setPinZero)}
          />
          <input
            ref={_pin_1}
            value={pinOne}
            min={0}
            autoFocus
            max={9}
            maxLength={1}
            disabled={isSubmitting}
            onChange={(e) => handleChange(e, setPinOne)}
          />
          <input
            ref={_pin_2}
            value={pinTwo}
            min={0}
            autoFocus
            max={9}
            maxLength={1}
            disabled={isSubmitting}
            onChange={(e) => handleChange(e, setPinTwo)}
          />
          <input
            ref={_pin_3}
            value={pinThree}
            min={0}
            autoFocus
            max={9}
            maxLength={1}
            disabled={isSubmitting}
            onChange={(e) => handleChange(e, setPinThree)}
          />
          <input
            ref={_pin_4}
            value={pinFour}
            min={0}
            autoFocus
            max={9}
            maxLength={1}
            disabled={isSubmitting}
            onChange={(e) => handleChange(e, setPinFour)}
          />
        </div>
        <button
          ref={_buttonRef}
          className={classNames('btn btn-primary', {
            disabled: `${pinZero}${pinOne}${pinTwo}${pinThree}${pinFour}`.length < 5,
          })}
          onClick={handleSubmit}
          disabled={`${pinZero}${pinOne}${pinTwo}${pinThree}${pinFour}`.length < 5}
        >
          Verify
        </button>

        <div className="verificationCodeForm__resendCode">
          {resendCode.displayResend ? (
            <p>
              No code? <span onClick={resendActivationCode}>Resend it now</span>
            </p>
          ) : (
            <p>
              You may try to resend the activation code in{' '}
              <b>
                {resendCode.timer === 60
                  ? `01:00`
                  : `00:${resendCode.timer > 9 ? resendCode.timer : '0' + resendCode.timer}`}
              </b>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line no-lone-blocks
{
  /* <Formik
      enableReinitialize={true}
      initialValues={{
        PIN_0: "",
        PIN_1: "",
        PIN_2: "",
        PIN_3: "",
        PIN_4: "",
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        let code = `${values.PIN_0}${values.PIN_1}${values.PIN_2}${values.PIN_3}${values.PIN_4}`;
        try {
          showLoading(
            "Checking Activation Code...",
            "Please wait activating your account..."
          );
          await RootStore.users.verifyEmailAddress(
            code,
            props.location.state["token"],
            true
          );

          Swal.close();
          actions.setSubmitting(false);
        } catch (error) {
          actions.resetForm();
          handleError(error);
          ajaxErrorAlert("There was a problem with verify your code.").then(
            (res) => {
              if (res.value) {
                _pin_0.current?.focus();
                actions.setSubmitting(false);
              }
            }
          );
        }
      }}
    >
      {({
        values,
        handleReset,
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
        errors,
        isSubmitting,
      }) => (
        <Form
          onReset={handleReset}
          onSubmit={handleSubmit}
          className="verificationCodeForm authForm__form"
        >
          <VerificationCodeFormIcon />

          <h3>Enter five digit code here</h3>
          <div className="verificationCodeForm__input">
            <input
              ref={_pin_0}
              name={`PIN_0`}
              key={`PIN_0`}
              value={values.PIN_0}
              min={0}
              autoFocus
              max={9}
              maxLength={1}
              onChange={handleChange}
              disabled={isSubmitting}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (Number(event.key)) {
                  if (
                    `${event.key}${values.PIN_1}${values.PIN_2}${values.PIN_3}${values.PIN_4}`.trim()
                      .length === 5
                  ) {
                    handleSubmit();
                  }
                  setFieldValue("PIN_0", event.key);
                  _pin_1.current?.focus();
                } else if (event.key === "Backspace") {
                  setFieldValue("PIN_0", "");
                }
              }}
              onBlur={handleBlur}
            />
            <input
              ref={_pin_1}
              name={`PIN_1`}
              key={`PIN_1`}
              value={values.PIN_1}
              min={0}
              max={9}
              maxLength={1}
              disabled={isSubmitting}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (Number(event.key)) {
                  if (
                    `${values.PIN_0}${event.key}${values.PIN_2}${values.PIN_3}${values.PIN_4}`.trim()
                      .length === 5
                  ) {
                    handleSubmit();
                  }
                  setFieldValue("PIN_1", event.key);
                  _pin_2.current?.focus();
                } else if (event.key === "Backspace") {
                  if (values.PIN_1 === "") {
                    _pin_0.current?.focus();
                  } else {
                    setFieldValue("PIN_1", "");
                  }
                }
              }}
              onBlur={handleBlur}
            />
            <input
              ref={_pin_2}
              name={`PIN_2`}
              key={`PIN_2`}
              value={values.PIN_2}
              min={0}
              max={9}
              maxLength={0}
              disabled={isSubmitting}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (Number(event.key)) {
                  if (
                    `${values.PIN_0}${values.PIN_1}${event.key}${values.PIN_3}${values.PIN_4}`.trim()
                      .length === 5
                  ) {
                    handleSubmit();
                  }
                  setFieldValue("PIN_2", event.key);
                  _pin_3.current?.focus();
                } else if (event.key === "Backspace") {
                  if (values.PIN_2 === "") {
                    _pin_1.current?.focus();
                  } else {
                    setFieldValue("PIN_2", "");
                  }
                }
              }}
              onBlur={handleBlur}
            />
            <input
              ref={_pin_3}
              name={`PIN_3`}
              key={`PIN_3`}
              value={values.PIN_3}
              min={0}
              max={9}
              maxLength={1}
              disabled={isSubmitting}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (Number(event.key)) {
                  if (
                    `${values.PIN_0}${values.PIN_1}${values.PIN_2}${event.key}${values.PIN_4}`.trim()
                      .length === 5
                  ) {
                    handleSubmit();
                  }
                  setFieldValue("PIN_3", event.key);
                  _pin_4.current?.focus();
                } else if (event.key === "Backspace") {
                  if (values.PIN_3 === "") {
                    _pin_2.current?.focus();
                  } else {
                    setFieldValue("PIN_3", "");
                  }
                }
              }}
              onBlur={handleBlur}
            />
            <input
              ref={_pin_4}
              name={`PIN_4`}
              key={`PIN_4`}
              value={values.PIN_4}
              min={0}
              max={9}
              maxLength={1}
              disabled={isSubmitting}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (Number(event.key)) {
                  if (
                    `${values.PIN_0}${values.PIN_1}${values.PIN_2}${values.PIN_3}${event.key}`.trim()
                      .length === 5
                  ) {
                    handleSubmit();
                  }
                  setFieldValue("PIN_4", event.key);
                } else if (event.key === "Backspace") {
                  if (values.PIN_4 === "") {
                    _pin_3.current?.focus();
                  } else {
                    setFieldValue("PIN_4", "");
                  }
                }
              }}
              onBlur={handleBlur}
            />
          </div>

          <button
            ref={_buttonRef}
            className={classNames("btn btn-primary", {
              disabled:
                `${values.PIN_0}${values.PIN_1}${values.PIN_2}${values.PIN_3}${values.PIN_4}`
                  .length < 5,
            })}
            type={"submit"}
            disabled={
              `${values.PIN_0}${values.PIN_1}${values.PIN_2}${values.PIN_3}${values.PIN_4}`
                .length < 5
            }
          >
            Verify
          </button>

          <div className="verificationCodeForm__resendCode">
            {resendCode.displayResend ? (
              <p>
                No code?{" "}
                <span onClick={resendActivationCode}>Resend it now</span>
              </p>
            ) : (
              <p>
                You may try to resend the activation code in{" "}
                <b>
                  {resendCode.timer === 60
                    ? `01:00`
                    : `00:${
                        resendCode.timer > 9
                          ? resendCode.timer
                          : "0" + resendCode.timer
                      }`}
                </b>
              </p>
            )}
          </div>
        </Form>
      )}
    </Formik>  */
}
