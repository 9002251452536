import React, { Component } from "react";
import moment from "moment";

import { Table, Spinner } from "reactstrap";
import { IAdModel } from "../../../../../../../../../../../store/models/AdModel";
import { NoSearchResultIcon } from "../../../../../../../../../../../assets/icons";
// import { ITransactionModel } from "../../../../../../../../../../../store/models/TransactionModel";

interface Ipagination {
  limit: number;
  page: number;
  pagesCount: number;
  totalCount: number;
}

interface Props {
  transactions: any;
  pagination: Ipagination;
  datafetched?: boolean;
}
interface State {
  transactionsList: any;
  displayAdPreview: boolean;
  adToBeShown: IAdModel | null;
  currentTaskIndex: number | null;
  pagination: Ipagination;
}

export default class TransactionsListPayment extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transactionsList: this.props.transactions,
      displayAdPreview: false,
      adToBeShown: null,
      currentTaskIndex: null,
      pagination: this.props.pagination,
    };
    this.onDisplayAdPreview = this.onDisplayAdPreview.bind(this);
  }
  onDisplayAdPreview() {
    this.setState((prevState) => ({
      displayAdPreview: !this.state.displayAdPreview,
    }));
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.transactions !== this.props.transactions) {
      this.setState({
        transactionsList: this.props.transactions, //new TransactionModel(this.props.transactions),
      });
    }
  }

  render() {
    const { datafetched } = this.props;
    const { transactionsList } = this.state;
    return (
      <div style={{ minHeight: 500 }}>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Ad Name</th>
              <th>Influencer Name</th>
              <th>Paymanet Amount</th>
              <th style={{ width: "20%" }}>Date</th>
              <th>Gateway</th>
              <th>Type</th>
              <th>Transaction ID</th>
            </tr>
          </thead>
          {datafetched && (
            <tbody style={{ width: "100%", overflow: "auto" }}>
              {Array.isArray(transactionsList) &&
                transactionsList.length > 0 &&
                transactionsList?.map((transaction, index) => {
                  const date = moment(transaction.created_at).format(
                    "MM/DD/YYYY - hh:mm a"
                  );
                  const {
                    currency,
                    type,
                    id,
                    amount,
                    offer,
                    target_wallet,
                  } = transaction;
                  const f = target_wallet?.owner?.first_name || "";
                  const l = target_wallet?.owner?.last_name || "";
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{offer?.ad?.title}</td>
                      <td>{f + " " + l}</td>
                      <td>{amount}</td>
                      <td>{date}</td>
                      <td>{currency}</td>
                      <td>{type}</td>
                      <td>{id}</td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </Table>
        {!datafetched && (
          <div
            style={{
              width: "100%",
              height: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner color="primary" size={"lg"} className="spinner" />
          </div>
        )}
        {Array.isArray(transactionsList) &&
          transactionsList?.length === 0 &&
          datafetched && (
            <div className="searchHashtagModal__emptySearch">
              <NoSearchResultIcon />
              <br />
              <p>No result found</p>
              <p>
                <b>Try different hashtags</b>
              </p>
            </div>
          )}
      </div>
    );
  }
}
