import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { FileModel } from "./FileModel";

/**
 * this method Media Model
 */
export const MediaModel = types
  .model({
    id: types.identifierNumber,
    created_at: types.maybe(types.string),
    updated_at: types.maybe(types.string),
    file: types.maybeNull(FileModel),
    original_file: types.maybeNull(FileModel),
    preview_file: types.maybeNull(FileModel),
    data: types.maybe(types.frozen()),
    sequence_number: types.number,
    content_id: types.maybe(types.number),
    deleted_at: types.maybeNull(types.string),
  })
  .actions((self) => ({
    unDelete() {
      self.deleted_at = null;
    },
  }))
  .views((self) => ({}))
  .volatile((self) => ({
    originalSequenceNumber: self.sequence_number,
  }));

export interface IMediaModel extends Instance<typeof MediaModel> {}
export interface IMediaModelSnapshotIn extends SnapshotIn<typeof MediaModel> {}
export interface IMediaModelSnapshotOut
  extends SnapshotOut<typeof MediaModel> {}

export enum MediaAction {
  DELETE,
  EDIT,
  CREATE,
  REORDER,
}

export type MediaModification = {
  action: MediaAction;
  model: IMediaModelSnapshotIn;
};

export function fixMediaStructure(media: any): IMediaModelSnapshotIn {
  media = JSON.parse(JSON.stringify(media));
  media["file_id"] = media["file"].id;
  if (media["original_file"]) {
    media["original_file_id"] = media["original_file"].id;
  } else {
    media["original_file_id"] = media["file"].id;
  }
  return media;
}
