import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import RootStore from "../../../store/RootStore";
import { ajaxErrorAlert, handleError, showLoading } from "../../../lib/Utils";
// import { NotificationManager } from "../../../template/components/common/react-notifications";
import { observer } from "mobx-react";
import { IAdModel } from "../../../store/models/AdModel";
import Swal from "sweetalert2";

const AdInfoSchema = Yup.object().shape({
  rejection_reason: Yup.string(),
});
interface Props {
  isOpen: boolean;
  Ad: IAdModel;
  toggle: () => void;
}

@observer
export default class AdRejection extends React.Component<Props> {
  onSubmitEditAd = async (values: any, actions: any) => {
    try {
      actions.setSubmitting(true);
      const editIdentityValues = {
        approval: "rejected",
        rejection_reason: values.rejection_reason
          ? values.rejection_reason
          : null,
      };
      showLoading();
      await RootStore.adStore.editAd(this.props.Ad.id, editIdentityValues);
      Swal.close();
      Swal.fire({
        type: "success",
        title: "Ad has been rejected successfully!",
        showCancelButton: false,
        showConfirmButton: true,
      });
      actions.setSubmitting(true);
      this.props.toggle();
    } catch (e) {
      handleError(e);
      ajaxErrorAlert("There was a problem in rejection the Ad.");
    }
  };

  getAdEditInitialValues(): any {
    const { Ad } = this.props;

    return {
      id: Ad.id,
      rejection_reason: Ad.rejection_reason ? Ad.rejection_reason : "",
    };
  }

  render() {
    const { isOpen, toggle } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit User Profile</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={this.getAdEditInitialValues()}
            validationSchema={AdInfoSchema}
            onSubmit={this.onSubmitEditAd}
          >
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form
                translate={"no"}
                className="av-tooltip tooltip-label-right panel-form"
              >
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Rejection Reason in case of rejection</b>
                    {errors.rejection_reason && touched.rejection_reason && (
                      <div className="invalidFeedback d-block">
                        {errors.rejection_reason}
                      </div>
                    )}
                  </Label>
                  <Field
                    className="form-control w-100"
                    name="rejection_reason"
                    as={"textarea"}
                    disabled={isSubmitting}
                  />
                </FormGroup>
                <hr />
                <Button
                  style={{ float: "right" }}
                  color="danger ml-1"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Reject Ad
                </Button>
                <Button
                  style={{ float: "right" }}
                  color="secondary"
                  onClick={toggle}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}
