import React from "react";
import { Modal, Button } from "reactstrap";
import { WarningIcon } from "../../../../assets/icons";
import { observer } from "mobx-react";
import RootStore from "../../../../store/RootStore";

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  dialogOpen: boolean;
}
/**
 * 
 * @param props , { onConfirm, onCancel, dialogOpen }
 * @returns modal show when user when want to cancel invitaion 
 */

function CancelInvitation(props: Props) {
  const { onCancel, dialogOpen, onConfirm } = props;

  return (
    <Modal isOpen={dialogOpen} className="cancelInvitation">
      <div className="cancelInvitation__body">
        <WarningIcon />
        <h1>Leave without sending invites?</h1>
        <p>
          You have selected {RootStore.invitationStore.temporaryList.length}{" "}
          influencers, if you leave now, your selection will be lost! Do you
          want to continue?
        </p>
        <div className="d-flex justify-content-between align-items-center">
          <Button onClick={onCancel}>Stay</Button>
          <Button color="link" onClick={onConfirm}>
            Cancel Invitation
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default observer(CancelInvitation);
