import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { TabsComponent } from "../../../common-components";
import { Colxx } from "../../../../template/components/common/CustomBootstrap";
import { Row } from "reactstrap";
import { BreadCrumb } from "../../../common-components";
import NotificationTemplateTab from "./NotificationTemplateTab";

interface Props extends RouteComponentProps {}
export default class Templates extends Component<Props> {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadCrumb heading="Templates Management" {...this.props} />
            {/* <Separator className="mb-5" /> */}
          </Colxx>
          <div style={{ width: "100%" }}>
            <TabsComponent
              tabs={[
                {
                  tabName: "Notifications",
                  component: (
                    <NotificationTemplateTab key="UsersTab" {...this.props} />
                  ),
                  to: `${this.props.match.url}`,
                },
              ]}
              {...this.props}
            />
          </div>
        </Row>
      </React.Fragment>
    );
  }
}
