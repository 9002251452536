import Transport from "../../../../lib/Transport";
import moment from "moment";
import { IAdModel } from "../../../../store/models/AdModel";
import { IInvitationModel } from "../../../../store/models/InvitationModel";

export enum TaskType {
  invite = "invitation_with_no_email",
  approve = "ad_approval",
}

export interface ITask {
  assignee_id?: number;
  created_at: string;
  compeleted_at?: string;
  id: number;
  updated_at?: string;
  type: TaskType;
  data: {
    invitation?: IInvitationModel;
    ad: IAdModel;
  };
}
export default abstract class AbstractTaskGenerator {
  constructor(public task: ITask) {
    // this.compeleteTask = this.compeleteTask.bind(this);
  }
  taskType(): string {
    return this.task.type;
  }
  isCompleted(): boolean {
    return this.task.compeleted_at ? true : false;
  }
  async completeTask(): Promise<void> {
    this.task.compeleted_at = moment().format("YYYY-MM-DD HH:mm:ss");
    Transport.patch(`tasks/${this.task.id}`, {
      task: {
        completed_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    });
  }
}
//git
