import React from 'react';
import { Button } from 'reactstrap';
import { RightArrowIcon } from '../../../../assets/icons';
import Swal from 'sweetalert2';
import RootStore from '../../../../store/RootStore';
import { RouteComponentProps } from 'react-router-dom';
import { ajaxErrorAlert, handleError, isMobileAndTablet } from '../../../../lib/Utils';
import { observer } from 'mobx-react';

interface Props extends RouteComponentProps {
    onCancel: () => void;
}
/**
 * 
 * @param props , { onCancel } selected or unselected influencer
 * @returns influencer box shows on Discover & Invite Influencers and custom search 
 */
function SelectedInflucners(props: Props) {
    const { onCancel } = props;

    let marginLeft =
        RootStore.invitationStore.getLength() === 1
            ? '3.2em'
            : RootStore.invitationStore.getLength() === 2
            ? '4em'
            : RootStore.invitationStore.getLength() === 3
            ? '5em'
            : RootStore.invitationStore.getLength() > 3
            ? '5em'
            : '';
    /**
     * when clicked on invite btn of influencer box shows this function 
     * section and invited an or some of influencer
     */
    const nextClickHandle = async () => {
        if (RootStore.adStore.selectedAd) {
            const haveAd = RootStore.adStore
                .getAllAds()
                .find((ad: any) => parseInt(ad.id) === RootStore.adStore.selectedAd);

            if (!haveAd) {
                ajaxErrorAlert("Can't invite Influencers to an inactive Ad!");
                props.history.push('/app/ads');
            } else {
                try {
                    if (haveAd.status === 'paused') {
                        Swal.fire({
                            type: 'error',
                            text: "You can't invite infleuncers to a paused ad. In order to invite them resume the ad to invite them.",
                            showCancelButton: false,
                            showConfirmButton: true,
                        }).then((res) => {
                            if (res.value) {
                                Swal.close();
                                props.history.push('/app/ads');
                            }
                        });
                    } else {
                        let response = await RootStore.invitationStore.createInvitation(RootStore.adStore.selectedAd);
                        RootStore.invitationStore.clearList();

                        RootStore.users.currentUser.increaseInvitationsCount(
                            response.inserted - response.existing > 0 ? response.inserted - response.existing : 0,
                        );

                        if (response.existing === 0 && response.inserted > 0) {
                            Swal.fire({
                                type: 'success',
                                title: 'Nice Work! Invitations Sent',
                                html: `Invitations were sent for ${response.inserted} influencers! Influencers will be alerted and should respond back shortly. Look for notifications in your email inbox or on the dashboard. 
                      <b>Remember</b>, the more influencers you invite, the more deals you’ll make.`,
                                showCancelButton: false,
                                showConfirmButton: true,
                            }).then((res) => {
                                if (res.value) {
                                    Swal.close();
                                }
                            });
                        } else if (response.existing > 0 && response.inserted === 0) {
                            Swal.fire({
                                type: 'success',
                                title: 'Nice Work! Invitations Sent',
                                html: `All influencers you selected were already invited for the selected Ad(s)! Influencers will be alerted and should respond back shortly.
                  Look for notifications in your email inbox or on the dashboard. <b>Remember</b>, the more influencers you invite, the more deals you’ll make.`,
                                showCancelButton: false,
                                showConfirmButton: true,
                            }).then((res) => {
                                if (res.value) {
                                    Swal.close();
                                }
                            });
                        } else {
                            Swal.fire({
                                type: 'success',
                                title: 'Nice Work! Invitations Sent',
                                html: `${response.inserted} new invites were sent! ${response.existing} of your selected invitations have been already sent previously! 
                        Influencers will be alerted and should respond back shortly. Look for notifications in your email inbox or on the dashboard. 
                      <b>Remember</b>, the more influencers you invite, the more deals you’ll make.`,
                                showCancelButton: false,
                                showConfirmButton: true,
                            }).then((res) => {
                                if (res.value) {
                                    Swal.close();
                                }
                            });
                        }
                    }
                } catch (error) {
                    ajaxErrorAlert("Can't invite Influencers to an inactive Ad!");
                    handleError(error);
                }
                //RootStore.adStore.setAd(null);
            }
        }
    };
    const tempZero = RootStore.invitationStore.temporaryList[0]
    const tempOne = RootStore.invitationStore.temporaryList[1]
    return (
        <>
            {RootStore.invitationStore.isBusy && RootStore.invitationStore.getLength() === 0 ? null : (
                <div className="selectedInfluencers">
                    <div>
                        <ul>
                            {RootStore.invitationStore.getLength() === 1 && (
                                <li>
                                    <img
                                        src={
                                            tempZero.identity.picutre_path ?
                                            tempZero.identity.picutre_path.getThumbnailUrl(
                                                90,
                                                90,
                                            ) : tempZero.identity.logo?.url
                                        }
                                        alt="ainfluencer"
                                    />
                                </li>
                            )}
                            {RootStore.invitationStore.getLength() === 2 && (
                                <>
                                    <li>
                                        <img
                                            src={
                                                tempZero.identity.picutre_path ?
                                                tempZero.identity.picutre_path.getThumbnailUrl(
                                                    90,
                                                    90,
                                                ) : tempZero.identity.logo?.url
                                            }
                                            alt="ainfluencer"
                                        />
                                    </li>
                                    {!isMobileAndTablet() && (
                                        <li>
                                            <img
                                                src={
                                                    tempOne.identity.picutre_path ?
                                                    tempOne.identity.picutre_path.getThumbnailUrl(
                                                        90,
                                                        90,
                                                    ) : tempOne.identity.logo?.url
                                                }
                                                alt="ainfluencer"
                                            />
                                        </li>
                                    )}
                                </>
                            )}
                            {RootStore.invitationStore.getLength() > 2 && (
                                <>
                                    <li>
                                        <img
                                            src={
                                                tempZero.identity.picutre_path ?
                                                tempZero.identity.picutre_path.getThumbnailUrl(
                                                    90,
                                                    90,
                                                ) : tempZero.identity.logo?.url
                                            }
                                            alt="ainfluencer"
                                        />
                                    </li>
                                    {!isMobileAndTablet() && (
                                        <li>
                                            <img
                                                src={
                                                    tempOne.identity.picutre_path ?
                                                    tempOne.identity.picutre_path.getThumbnailUrl(
                                                        90,
                                                        90,
                                                    ) : tempOne.identity.logo?.url
                                                }
                                                alt="ainfluencer"
                                            />
                                        </li>
                                    )}
                                    {!isMobileAndTablet() ? (
                                        <li>+{RootStore.invitationStore.getLength() - 2}</li>
                                    ) : (
                                        <li>+{RootStore.invitationStore.getLength() - 1}</li>
                                    )}
                                </>
                            )}
                        </ul>

                        <p style={{ marginLeft: marginLeft }}>
                            {RootStore.invitationStore.getLength() === 1 && (
                                <>
                                    <span>{tempZero.identity.username} </span>{' '}
                                </>
                            )}
                            {RootStore.invitationStore.getLength() === 2 && (
                                <>
                                    <span>{tempZero.identity.username} </span>
                                    and <span>{tempOne.identity.username}</span>{' '}
                                </>
                            )}
                            {RootStore.invitationStore.getLength() > 2 && (
                                <>
                                    <span>{tempZero.identity.username} </span>,{' '}
                                    {!isMobileAndTablet() && (
                                        <span>{tempOne.identity.username}</span>
                                    )}
                                    {'and '}
                                    <span>
                                        {!isMobileAndTablet()
                                            ? RootStore.invitationStore.getLength() - 2
                                            : RootStore.invitationStore.getLength() - 1}{' '}
                                        other
                                    </span>
                                </>
                            )}
                            {'  '}
                            {RootStore.invitationStore.getLength() > 1 ? 'are' : 'is'} in the invitation list.
                        </p>
                    </div>
                    <div className="selectedInfluencers__actions">
                        <Button onClick={onCancel} className="cancel-btn mr-4">
                            Cancel
                        </Button>
                        <Button onClick={() => nextClickHandle()}>
                            {RootStore.adStore.selectedAd
                                ? `Invite Influencer${RootStore.invitationStore.getLength() > 1 ? 's' : ''}`
                                : 'Next'}{' '}
                            <RightArrowIcon />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default observer(SelectedInflucners);
