import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';
import Ads from './ad/Ads';
import CreatAd from '../createAd/';
import AdCreatedPage from './ad/AdCreatedPage';

interface Props extends RouteComponentProps {}

export default function Index(props: Props) {
    const { match } = props;

    return (
        <Switch>
            <Route path={match.url + '/ad/'} exact={true} render={() => <Redirect to={'/app/ads'} />} />
            <Route path={match.url + '/ad/:page/:adID?'} exact={true} component={CreatAd} />

            <Route path={`${match.url}/ad-created`} exact={true} component={AdCreatedPage} />

            <Route path={`${match.url}`} component={Ads} />
            <Redirect to={`${match.url}`} />
        </Switch>
    );
}
