import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { NoSearchResultIcon, CloseIcon } from '../../../../assets/icons';
import RootStore from '../../../../store/RootStore';
import ManageHashtagsHeader from '../../../../assets/img//ManageHashtagsHeader.png';
import SearchIconNew from '../../../../assets/img/SearchIcon.png';

interface Props {
    isOpen: boolean;
    toggle: any;
    action: (hashtags: string[], filed?: string, removedHashtags?: string[]) => void;
    loadIdentityHashtags?: boolean;
    hashtags?: any;
    onSave?: () => void;
}

/**
 *
 * @param props , {is open , toggle , action , loadIdentityHashtags}
 * @returns search hashtag field , list of search response
 */
const SearchHashtag: React.FC<Props> = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [hashtags, setHashtags] = useState(null);
    const [selectedHashtags, setSelectedHashtags] = useState<string[]>([]);
    const [fetchingData, setFetchingData] = useState<boolean>(false);

    useEffect(() => {
        if (Array.isArray(props.hashtags) && props.hashtags.length > 0) {
            setSelectedHashtags(props.hashtags);
        }
    }, [props.hashtags]);

    /**
     * triger when user press search btn
     */
    const searchForHashtags = async () => {
        setFetchingData(true);

        let result = await RootStore.hashtagsStore.searchHashtags(searchTerm?.replace('#', ''), 15);

        setFetchingData(false);

        let hashtags = result.data ? result.data : [];

        // if (hashtags.length > 0) {
        //   hashtags = hashtags
        //     .filter((hashtag: any) => hashtag.similarity > 0.75)
        //     .map((hashtag: any) => hashtag.hashtag);
        // }

        if (Array.isArray(hashtags)) {
            hashtags?.sort(function (a, b) {
                return b.ori_count - a.ori_count;
            });
        }

        setHashtags(hashtags);
    };

    /**
     *
     * @param hashtag ,user fill it
     * @returns , nothing
     * add one of hashtag user fill on list of hashtags
     */
    const addToSelected = (hashtag: string): void => {
        if (selectedHashtags.find((selected: string) => selected === hashtag)) return;

        setSelectedHashtags([...selectedHashtags, hashtag]);
    };

    /**
     *
     * @param hashtag , remove hashtag user click on it in the selected place
     */
    const removeFromSelected = (hashtag: string): void => {
        setSelectedHashtags(selectedHashtags.filter((selected) => hashtag !== selected));
    };

    /**
     * when user click save btn , added to ad hashtag section
     */
    const onSave = () => {
        setHashtags(null);
        setSearchTerm('');
        setSelectedHashtags([]);
        props.action(selectedHashtags);
        props.toggle();
        // props.onSave()
    };
    return (
        <Modal
            isOpen={props.isOpen}
            // isOpen={true}
            style={{ zIndex: 1090 }}
            className="searchHashtagModal"
            toggle={props.toggle}
        >
            <div className="searchHashtagModal_close-box" onClick={props.toggle}>
                <CloseIcon width="20" height="20" />
            </div>
            {/* <ModalHeader toggle={props.toggle}># Manage Hashtag</ModalHeader> */}
            <div className="searchHashtagModal__hashtagImgParent">
                <img src={ManageHashtagsHeader} className="" alt="hashtags" />
            </div>
            <ModalBody>
                <div className="searchHashtagModal__title">
                    <img className="searchHashtagModal__title__magnify" src={SearchIconNew} alt="magnify" />
                    <span># Manage Hashtags</span>
                </div>
                <div className="d-flex justify-content-between mt-4">
                    <input
                        type="text"
                        placeholder="Enter a hashtag"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value.trim());
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === 'Enter') searchForHashtags();
                        }}
                    />
                    <button
                        disabled={searchTerm.length < 3}
                        className="searchHashtagModal__newsearchButton"
                        onClick={searchForHashtags}
                    >
                        Search
                    </button>
                </div>
                <div className="searchHashtagModal__selected mt-4">
                    <div className="title">Added Hashtags:</div>
                    {selectedHashtags.length === 0 && !props.loadIdentityHashtags ? (
                        <div className="empty">your hashtags go here.</div>
                    ) : selectedHashtags.length === 0 && props.loadIdentityHashtags ? (
                        <div className="save" onClick={onSave}>
                            Save Changes
                        </div>
                    ) : (
                        <>
                            <div className="selectedHashtags">
                                {selectedHashtags.map((hashtag: string, index: number) => (
                                    <div key={index} className="selectedHashtag">
                                        <p>{hashtag}</p>
                                        <div className="remove" onClick={() => removeFromSelected(hashtag)}>
                                            x
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="save" onClick={onSave}>
                                Save Changes
                            </div>
                        </>
                    )}
                </div>
                <div className="searchHashtagModal__content">
                    {fetchingData ? (
                        <div className="d-flex flex-column align-items-center my-4">
                            <Spinner color="primary" className="mb-1" />
                            <p>Please wait...</p>
                        </div>
                    ) : hashtags && hashtags.length === 0 ? (
                        <div className="searchHashtagModal__emptySearch">
                            <NoSearchResultIcon />
                            <p>No result found</p>
                            <p>
                                {' '}
                                <b>Try different hashtags</b>
                            </p>
                        </div>
                    ) : (hashtags && hashtags.length > 0) || selectedHashtags.length > 0 ? (
                        <div className="hashtags">
                            {hashtags?.length
                                ? hashtags.map(
                                      (hashtag) =>
                                          !selectedHashtags.includes(hashtag.name) && (
                                              <div key={hashtag.name} className="hashtag">
                                                  <p>
                                                      <a
                                                          href={`https://www.instagram.com/explore/tags/${hashtag.name}/`}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                      >
                                                          {hashtag.name}
                                                      </a>{' '}
                                                      ({hashtag.mediacount})
                                                  </p>
                                                  <div className="add" onClick={() => addToSelected(hashtag.name)}>
                                                      +
                                                  </div>
                                              </div>
                                          ),
                                  )
                                : null}
                        </div>
                    ) : null}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default SearchHashtag;
