import React from "react";
import numbro from "numbro";
import moment from "moment";
import { observer } from "mobx-react";
import { truncate } from "lodash";

import { IOfferModel } from "../../../../../store/models/OfferModel";
import { momentDateTimeFormat, ucfirst } from "../../../../../lib/Utils";

type Props = {
  offer: IOfferModel;
  mode: "revisable" | "final" | "negotiable";
  performAction: Function;
};

function OfferSpecs(props: Props) {
  return (
    <div className="offer-specs">
      <div className="specs">
        <div className="details">
          <ul>
            <ul>
              {props.mode === "negotiable" ? (
                <li>
                  <b className="money">
                    {props.offer.price
                      ? numbro(props.offer.price)
                          .formatCurrency({
                            mantissa: 2,
                            thousandSeparated: true,
                            optionalMantissa: true,
                          })
                          .toUpperCase()
                      : "Suggest a Price"}
                  </b>
                </li>
              ) : null}
              <li>
                <span className="schedule-icon" />
                At:{" "}
                {props.offer.start !== null ? (
                  <b>
                    {moment(props.offer.start, momentDateTimeFormat).format(
                      "MMMM Do YYYY, h:mm a"
                    )}
                  </b>
                ) : (
                  <b>Unspecified</b>
                )}
              </li>
              <li>
                <span className="duration-icon" />
                Duration:{" "}
                <b>
                  {props.offer.duration !== null
                    ? props.offer.duration === 0
                      ? `Permanent ${
                          props.offer.content_id.type === "post"
                            ? "Post"
                            : "Story"
                        }`
                      : ucfirst(
                          moment
                            .duration(props.offer.duration as number, "seconds")
                            .humanize()
                        )
                    : "Unspecified"}
                </b>
              </li>
              <li>
                <span className="bio-link-icon" />
                Bio Link:{" "}
                {props.offer.bio_link && props.offer.bio_link.length > 0 ? (
                  <a
                    href={`${props.offer.bio_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {truncate(props.offer.bio_link, { length: 30 })}
                  </a>
                ) : (
                  <b>Unspecified</b>
                )}
              </li>
              <li>
                Content Type: <b>{props.offer.content_id.getContentType()}</b>
              </li>
              {/* <li>
                <span className="exclusivity-icon" />
                Exclusive for:{" "}
                <b>
                  {props.offer.exclusivity !== null
                    ? props.offer.exclusivity === 0
                      ? "Non-Exclusive Publish"
                      : moment
                          .duration(props.offer.exclusivity as number, "seconds")
                          .humanize()
                    : "Unspecified"}
                </b>
              </li> */}
              {props.mode === "revisable" ? (
                <li className={"controls"} style={{textAlign: 'right'}}>
                  <span
                    onClick={() => {
                      props.performAction("counter");
                    }}
                  >
                    Revise
                  </span>
                </li>
              ) : null}
            </ul>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default observer(OfferSpecs);
