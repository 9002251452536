import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { routesOnboarding } from "../../../../../model";
import { TickIcon } from "../../../../../../../../../../assets/icons";

function OnboardSidebarSteps(props: any) {
  const pathname = props.location.pathname;
  const [KeyActiveStep, setKeyActiveStep] = useState(null);

  useEffect(() => {
    routesOnboarding.find((el: any, key: number) =>
      pathname.split("/")[3] === el.key
        ? setKeyActiveStep(pathname.split("/")[3] === "skip" ? 5 : key)
        : ""
    );
  }, [pathname]);

  return (
    <Grid
      item
      container
      className={classes.steps}
      rowSpacing={3}
      flexDirection="row"
    >
      {routesOnboarding.map((el: any, key: number) => {
        if (typeof el.skip !== "undefined" && el.skip) return false;
        const success = KeyActiveStep > key;
        const active = KeyActiveStep === key;
        return (
          <Grid
            item
            container
            className={`${classes.items} ${active ? classes.active : ""} ${
              success ? classes.success : ""
            }`}
            alignItems="center"
            flexWrap="nowrap"
          >
            <Grid item flexBasis="content" marginRight={2}>
              <div className={classes.cycle}>
                {success ? <TickIcon /> : key + 1 === 7 ? 6 : key + 1}
              </div>
            </Grid>
            <Grid flexBasis="content">{el.label}</Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default withRouter(OnboardSidebarSteps);
