import React from 'react';
import classes from './style.module.scss';
import image from '../../../assets/img/frame.png';
import logo from '../../../assets/img/logo.png';
import { Grid } from '@mui/material';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';

function QRCode(props: any) {
    return (
        <div className={classes.content}>
            <Grid container flexDirection="column">
                <Grid item marginBottom={7}>
                    <img alt="logo" src={logo} className={classes.logo} />
                </Grid>
                <Grid item>
                    <p>Welcome to Ainfluencer</p>
                </Grid>
                <Grid item>
                    <small>Scan the QR code below to download the app</small>
                </Grid>
                <Grid item>
                    <img alt="QR code" src={image} className={classes.qrcode} />
                </Grid>
                <Grid item marginTop={3}>
                    <Button onClick={() => props.history.push('/login')}>Back</Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default withRouter(QRCode);
