import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Card, CardBody, CardTitle, Row } from 'reactstrap';
import { Colxx } from '../../../../template/components/common/CustomBootstrap';

interface Props extends RouteComponentProps {
    notifications: any[];
}

export default class NotificationTemplateList extends Component<Props> {
    render() {
        const { notifications } = this.props;
        return (
            <Fragment>
                <Card className="mb-4 ">
                    {this.props.notifications ? (
                        <CardBody>
                            <CardTitle>Notifications List</CardTitle>
                            <Row>
                                <Colxx>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Notification Title</th>
                                                {/* <th>Notification Body</th> */}
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {notifications.map((notification, index) => (
                                                <tr key={`notification__${notification.id}`} className="cursor-pointer">
                                                    <td>{index + 1}</td>
                                                    <td>{notification.name}</td>
                                                    {/* <td>{notification.body}</td> */}
                                                    <td>
                                                        <div className="d-flex justify-content-center">
                                                            <Button
                                                                className={'mr-1'}
                                                                color={'secondary'}
                                                                size={'sm'}
                                                                onClick={() => {
                                                                    this.props.history.push(
                                                                        `${this.props.match.url}/edit/${notification.id}`,
                                                                    );
                                                                }}
                                                            >
                                                                <span className="simple-icon-pencil" />
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Colxx>
                            </Row>
                        </CardBody>
                    ) : (
                        <></>
                    )}
                </Card>
            </Fragment>
        );
    }
}
