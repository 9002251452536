import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Spinner } from "reactstrap";
import { observer } from "mobx-react";
import { Container } from "reactstrap";
import Swal from "sweetalert2";

import RootStore from "../../../../store/RootStore";
import CampaignCard from "./CampaignCard";
import { ICampaignModel } from "../../../../store/models/CampaignModel";
import { CampaignForm } from "../../../common-components";
import {
  isMobileAndTablet,
  showLoading,
  makeTempModelId,
  ajaxErrorAlert,
  handleError,
} from "../../../../lib/Utils";

interface Props extends RouteComponentProps {}

function CampaignsTab(props: Props) {
  const [campaignsFetched, setCampaignsFetched] = useState(false);
  const [displayCreateCampaign, setDisplayCreateCampaign] = useState(false);

  const createCampaign = async (values: { title: string }) => {
    showLoading("Loading...", "Please wait while we creating your campaign...");
    try {
      let campaign = (await RootStore.campaignStore.create({
        id: makeTempModelId(),
        title: values.title,
        description: "",
        budget: 0,
        state: "unstarted",
      })) as ICampaignModel;

      RootStore.users.currentUser.increaseCampaignsCount();

      Swal.close();

      return {
        label: campaign.title,
        value: campaign.id,
      };
    } catch (e) {
      handleError(e);
      ajaxErrorAlert("There was a problem creating the campaign...");
      throw e;
    }
  };

  useEffect(() => {
    return () => setCampaignsFetched(false);
  }, []);

  useEffect(() => {
    const fetchCampaigns = async () => {
      await RootStore.campaignStore.fetchAll();
    };

    if (!campaignsFetched) {
      fetchCampaigns();
      setCampaignsFetched(true);
    }
  }, [campaignsFetched]);

  return (
    <Container fluid={true}>
      <CampaignForm
        onClose={() => setDisplayCreateCampaign(false)}
        isOpen={displayCreateCampaign}
        onCreateCampaign={async (campaignTitle) => {
          await createCampaign(campaignTitle);
        }}
      />

      <div style={{ textAlign: "center" }}>
        {!isMobileAndTablet() ? (
          <span
            className="btn btn-primary btn-md pull-right mb-4 cursor-pointer"
            onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
              event.preventDefault();
              setDisplayCreateCampaign(true);
            }}
          >
            Create a New Campaign
          </span>
        ) : (
          <span
            className="btn btn-primary btn-sm mb-4 cursor-pointer"
            onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
              event.preventDefault();
              setDisplayCreateCampaign(true);
            }}
          >
            Create a New Campaign
          </span>
        )}
      </div>
      <div>
        {!campaignsFetched || RootStore.campaignStore.isBusy ? (
          <Spinner color="primary" size={"lg"} className="spinner" />
        ) : (
          <div>
            {RootStore.campaignStore
              .getExistingCampaigns()
              .map((campaign: ICampaignModel) => (
                <CampaignCard
                  campaign={campaign}
                  {...props}
                  key={campaign.id}
                />
              ))}
          </div>
        )}
      </div>
    </Container>
  );
}

export default observer(CampaignsTab);
