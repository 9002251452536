import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import RootStore from "../../../../store/RootStore";
import Swal from "sweetalert2";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import { NotificationManager } from "../../../../template/components/common/react-notifications";
import { IUserModel } from "../../../../store/models/UserModel";
import { observer } from "mobx-react";

const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string().required("First name can't be empty"),
  last_name: Yup.string().required("Last name can't be empty"),
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Email can't be empty"),
});

interface Props {
  isOpen: boolean;
  user: IUserModel;
  toggle: any;
}

@observer
export default class EditUserForm extends React.Component<Props> {
  onSubmitEditUser = async (values: any, actions: any) => {
    try {
      actions.setSubmitting(true);
      if (values.email !== this.props.user.email) {
        let res = await Swal.fire({
          title: "Warning",
          text:
            "If you change user's email address, user will have to verify his/her new email",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "rgb(18, 171, 40)",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, Change It",
        });

        if (res.value) {
          await RootStore.users.editUser(values.id, values);
          NotificationManager.success(
            "Done",
            "User profile was updated successfully!",
            3000,
            null,
            null,
            "filled"
          );
        }
      } else {
        await RootStore.users.editUser(values.id, values);
        NotificationManager.success(
          "Done",
          "User profile was updated successfully!",
          3000,
          null,
          null,
          "filled"
        );
      }
      this.props.toggle();
    } catch (e) {
      handleError(e);
      ajaxErrorAlert("There was a problem in editing your profile.");
    }
  };

  getUserEditInitialValues(): any {
    const { user } = this.props;
    return {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    };
  }

  render() {
    const { isOpen, toggle } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit Profile</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={this.getUserEditInitialValues()}
            validationSchema={PersonalInfoSchema}
            onSubmit={this.onSubmitEditUser}
          >
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form
                translate={"no"}
                className="av-tooltip tooltip-label-right panel-form"
              >
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>First name:</b>
                    {errors.first_name && touched.first_name && (
                      <div className="invalidFeedback">{errors.first_name}</div>
                    )}
                  </Label>
                  <Field className="form-control w-100" name="first_name" />
                </FormGroup>
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Last name:</b>
                    {errors.last_name && touched.last_name && (
                      <div className="invalidFeedback">{errors.last_name}</div>
                    )}
                  </Label>
                  <Field className="form-control w-100" name="last_name" />
                </FormGroup>
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Email:</b>
                    {errors.email && touched.email && (
                      <div className="invalidFeedback">{errors.email}</div>
                    )}
                  </Label>
                  <Field className="form-control w-100" name="email" />
                </FormGroup>
                <hr />
                <Button
                  style={{ float: "right" }}
                  color="success ml-1"
                  type="submit"
                >
                  Save User Info
                </Button>
                <Button
                  style={{ float: "right" }}
                  color="secondary"
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}
