import { FilterGroup, QueryOperator } from "../../../../lib/QueryTypes";
import moment from "moment";
export type Filters = {
  searchTerm: string | null;
  filters: any;
};

export class FilterFactory {
  public filterGroup: FilterGroup[] = [];
  constructor(filters: FilterGroup[]) {
    for (let filter of filters) {
      this.filterGroup.push(filter);
    }
  }
  static influencerFilterSet(filters: Filters) {
    let filterGroup = new FilterFactory([
      // {
      //   isOr: false,
      //   filters: [
      //     {
      //       key: "type",
      //       value: "influencer",
      //       operator: QueryOperator.EQUALS,
      //       not: false,
      //     },
      //   ],
      // },
      {
        isOr: false,
        filters: [
          {
            key: "user_id",
            value: filters.filters.user_id || null,
            operator: null,
            not: filters.filters.user_id === "true" ? true : false,
          },
        ],
      },
    ]);
    return filterGroup;
  }
  static brandFilterSet(filters: Filters) {
    let filterGroup = new FilterFactory([
      {
        isOr: false,
        filters: [
          {
            key: "type",
            value: "brand",
            operator: QueryOperator.EQUALS,
            not: false,
          },
        ],
      },
      {
        isOr: false,
        filters: [
          {
            key: "user_id",
            value: null,
            operator: null,
            not: filters.filters.user_id === "true" ? true : false,
          },
        ],
      },
    ]);
    return filterGroup;
  }
  buildFilterGruop(filters: Filters) {
    // if (filters.searchTerm) {
    //   this.filterGroup.push({
    //     isOr: true,
    //     filters: [
    //       {
    //         key: "name",
    //         value: filters.searchTerm,
    //         operator: QueryOperator.STRING_CONTAINS,
    //         not: false,
    //       },
    //       {
    //         key: "username",
    //         value: filters.searchTerm,
    //         operator: QueryOperator.STRING_CONTAINS,
    //         not: false,
    //       },
    //     ],
    //   });
    // }
    if (filters.filters.country_id) {
      this.filterGroup.push({
        isOr: false,
        filters: [
          {
            key: "country_id",
            value: filters.filters.country_id,
            operator: QueryOperator.STRING_CONTAINS,
            not: false,
          },
        ],
      });
    }
    // if (filters.filters.category) {
    //   this.filterGroup.push({
    //     isOr: false,
    //     filters: [
    //       {
    //         // key: "categories.id",
    //         key: "categories.slug",
    //         value: filters.filters.category,
    //         operator: QueryOperator.EQUALS,
    //         not: false,
    //       },
    //     ],
    //   });
    // }
    if (filters.filters.created_at_range) {
      this.filterGroup.push({
        isOr: false,
        filters: [
          {
            key: "created_at",
            value: [
              moment(filters.filters.created_at_range.start).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              moment(filters.filters.created_at_range.end).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            ],
            operator: QueryOperator.BETWEEN,
            not: false,
          },
        ],
      });
    }
    return this.filterGroup;
  }
}
