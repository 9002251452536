import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import createAdContext from '../../../../context';
import CreatAdStoryImage from './parts/image';
import CreatAdFeedContext from './parts/image/context';

export default function CreatAdFeed() {
    const { data: { createAd: { story: { images } } }, updateOnboard } = useContext(createAdContext)
    const [Images, setImages] = useState([])

    useEffect(() => {
        if (images && !Images.length) images.map(el => setImages(prev => ([...prev, el.url])));
    }, [images])

    const addImage = (url: string) => {
        setImages((prev: any) => [...prev, url])
    }

    const removeImage = (url: string) => {
        setImages((prev: any) => prev.filter((el: any) => el !== url && el));
        updateOnboard((prev: any) => ({
            ...prev,
            createAd: {
                ...prev.createAd,
                story: {
                    images: [...prev.createAd.story.images.filter((el: any) => el.url !== url && el)]
                }
            }
        }))
    }

    return (
        <CreatAdFeedContext.Provider value={{
            images: {
                list: Images,
                add: addImage,
                remove: removeImage
            }
        }}>
            <Grid
                container
                flexDirection="column"
                rowSpacing={2}
            >
                <Grid container item>
                    <CreatAdStoryImage />
                </Grid>
            </Grid>
        </CreatAdFeedContext.Provider>
    );
}