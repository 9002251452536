import React, { useRef, useState, useEffect, useImperativeHandle } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FormGroup, Label, Button, Spinner, ButtonGroup } from 'reactstrap';
import * as Yup from 'yup';
import { Field, Form, FormikProps } from 'formik';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ApplicationMenu from '../../../../template/components/common/ApplicationMenu';
import { RangeTooltip } from '../../../../template/components/common/SliderTooltips';
import { isMobileAndTablet } from '../../../../lib/Utils';
import { FormikReactSelect, TagsInputExample } from '../../../common-components';
import RootStore from '../../../../store/RootStore';
import { ICountryModel } from '../../../../store/models/CountryModel';
import { explorerSearchType } from '../../../../lib/CustomTypes';
import languageList from '../../../../constants/data/languages.json';
import SearchHashtag from '../../../common-components/SearchHashtag';
import FormikWithRef from '../../../common-components/FormikWithRef';
import 'react-tagsinput/react-tagsinput.css';
import { Modal, ModalBody } from 'reactstrap';
import findingInfluencers from '../../../../assets/img/LoadingInfluencers.gif';
import { SortOrder } from './../../../../lib/QueryTypes';
import Switch from 'rc-switch';
import { NotificationManager } from '../../../../template/components/common/react-notifications';
import { DeleteIcon, CloseIcon } from '../../../../assets/icons';
import { CountriesList } from './../../../../constants/countriesList';

interface FormikValues {
    search: string;
    hashtags: string[];
    country: any;
    categories: Array<{ label: string; slug: string; value: number }>;
    followers: {
        min: number;
        max: number;
    };
    language:
        | {
              label: string;
              value: string;
          }
        | string;
    gender: {
        label: string;
        value: string;
    };
    inviteOnly: boolean;
}

const validationSchema = Yup.object().shape({
    search: Yup.string(),
    hashtags: Yup.array(Yup.string().required('Enter any hashtags you want, then press enter')).nullable(),
    country: Yup.string().nullable(),
    categories: Yup.array(Yup.string()).nullable(),
    followers: Yup.object().shape({ min: Yup.number(), max: Yup.number() }),
    language: Yup.string().nullable(),
});

interface FiltersProps
    extends RouteComponentProps<{}, {}, { name?: explorerSearchType; value?: any; update_filter?: boolean }> {
    isOpen: boolean;
    loading: boolean;
    fetchingData: boolean;
    closeLoading?(): void;
    onToggle(): void;
    onSetSearchTerm(name: string, value: any): void;
    onApplySearchTerm(): void;
    setSearchObject(FormikValues, dontSave?: boolean): void;
    savefilter(FormikValues): void;
    // realInfluencerSearch(): void;
    setAutoSave(): void;
    autoSave: boolean;
    _setSearchState: any;
    setRealInfluencers: any;
    realInfluencers: boolean;
}
/**
 *
 * @param props , { isOpen, loading, fetchingData, onToggle,
 * onSetSearchTerm, onApplySearchTerm, setSearchObject, savefilter, realInfluencerSearch }
 *     }
 * @returns filter section of custom search
 */
const Filters = (props: FiltersProps, ref) => {
    const {
        isOpen,
        onToggle,
        loading,
        fetchingData,
        onSetSearchTerm,
        onApplySearchTerm,
        setSearchObject,
        savefilter,
        // realInfluencerSearch,
        setAutoSave,
        autoSave,
        _setSearchState,
        setRealInfluencers,
        realInfluencers,
    } = props;

    const [showHashtagModal, setShowHashtagModal] = useState(false);
    const [micro, setMicro] = useState(localStorage.getItem('macro') === '1' ? false : true);
    const [showClearMenu, setShowClearMenu] = useState(false);

    // const [counter,setCounter] = useState(0)

    const [formValues, setFormValues] = useState({
        country: '',
        hashtags: [],
        categories: [],
        followers: {
            min: 5,
            max: 1000,
        },
        search: '',
        language: '',
        gender: { label: 'All', value: null },
        inviteOnly: undefined,
        sorts: [
            {
                key: 'followers',
                direction: SortOrder.DESCENDING,
            },
        ],
    });

    const superAdmin = RootStore.users.currentUser.is_super_admin;
    // const adInfo =  JSON.parse(localStorage.getItem('ad_info'))

    const setAdInfoData = async (obj) => {
        try {
            const adInfo = JSON.parse(localStorage.getItem('ad_info'));
            const countriesList = CountriesList || [];
            const name = countriesList?.find((li) => li.id === adInfo?.limit_country[0])?.name;
            const id = countriesList?.find((li) => li.id === adInfo?.limit_country[0])?.id;
            const country =
                name && id
                    ? {
                          label: name,
                          value: id,
                      }
                    : null;
            obj.country = country;
            const { limit_gender } = adInfo;
            if (limit_gender === 'male') {
                obj.gender = { label: 'Male', value: 'male' };
            } else if (limit_gender === 'female') {
                obj.gender = { label: 'Female', value: 'female' };
            } else {
                obj.gender = { label: 'All', value: null };
            }

            obj.inviteOnly = adInfo?.invite_only;
            // 7 hashtags limit
            obj.hashtags = adInfo?.ad_hashtags
                ? adInfo?.ad_hashtags
                      ?.match(/#\w+/g)
                      ?.map((item) => item.substring(1))
                      ?.filter((_, i) => i < 7)
                : [];
            obj.categories = obj.categories ? obj.categories : [];
            obj.language = '';
            obj.followers = {
                min: 5,
                max: 1000,
            };
        } catch (err) {
            console.log('ad info err ', err);
        }
    };
    /**
     *
     * @param { showSpecific : boolean } , does form value need update or not
     * @returns values , formik form values
     */
    const initialValues = async () => {
        const initialSearchObj = {
            country: '',
            hashtags: [],
            categories: [],
            followers: {
                min: 5,
                max: 1000,
            },
            search: '',
            language: '',
            gender: { label: 'All', value: null },
            inviteOnly: undefined,
            sorts: [
                {
                    key: '_score',
                    direction: SortOrder.DESCENDING,
                },
            ],
        };

        try {
            const adInfo = JSON.parse(localStorage.getItem('ad_info'));
            const selectedAd = +RootStore.adStore.selectedAd || +localStorage.getItem('selected_ad');
            const savedFilter = JSON.parse(localStorage.getItem(`filter_${selectedAd}`));

            let filterObject = RootStore.discoverInfluencersStore?.searchObj;

            // @ts-ignore
            const from = props?.location?.state?.from || '';

            if (!from) {
                if (selectedAd) {
                    if (savedFilter) {
                        console.log(1);
                        // 7 hashtags limit
                        savedFilter.hashtags = savedFilter?.hashtags?.filter((_, i) => i < 7);
                        filterObject = { ...filterObject, ...savedFilter };
                    } else if (adInfo) {
                        setAdInfoData(filterObject);
                    } else {
                        filterObject = { ...filterObject, ...initialSearchObj };
                    }
                    RootStore.discoverInfluencersStore.setSearchObj({
                        ...filterObject,
                        sorts: [
                            {
                                key: '_score',
                                direction: SortOrder.DESCENDING,
                            },
                        ],
                    });
                    _setSearchState({
                        ...filterObject,
                        sorts: [
                            {
                                key: '_score',
                                direction: SortOrder.DESCENDING,
                            },
                        ],
                    });
                } else {
                    const brandCatCountry = await RootStore.identityStore.getByUserId(RootStore.users.currentUser.id);
                    const country = brandCatCountry?.country_id;
                    const countriesList = CountriesList || [];
                    const name = countriesList?.find((li) => li.id === country)?.name;
                    const id = countriesList?.find((li) => li.id === country)?.id;
                    const defaultCountry = () => {
                        if (name && id) {
                            return [
                                {
                                    label: name,
                                    value: id,
                                },
                            ];
                        } else {
                            return '';
                        }
                    };

                    const categories = brandCatCountry?.categories?.map((item) => item);
                    const newCat = categories.map((item) => {
                        return {
                            label: item?.title,
                            value: item?.id,
                            slug: item?.slug,
                        };
                    });
                    filterObject = {
                        ...initialSearchObj,
                        country: defaultCountry(),
                        categories: newCat,
                    };
                    RootStore.discoverInfluencersStore.setSearchObj({
                        ...filterObject,
                        sorts: [
                            {
                                key: '_score',
                                direction: SortOrder.DESCENDING,
                            },
                        ],
                    });
                    _setSearchState({
                        ...filterObject,
                        sorts: [
                            {
                                key: '_score',
                                direction: SortOrder.DESCENDING,
                            },
                        ],
                    });
                }
            } else {
                RootStore.discoverInfluencersStore.setSearchObj({
                    ...filterObject,
                    sorts: [
                        {
                            key: '_score',
                            direction: SortOrder.DESCENDING,
                        },
                    ],
                });
                _setSearchState({
                    ...filterObject,
                    sorts: [
                        {
                            key: '_score',
                            direction: SortOrder.DESCENDING,
                        },
                    ],
                });
            }

            const values: FormikValues = {
                hashtags: filterObject?.hashtags || [],
                country: filterObject?.country || '',
                categories: filterObject?.categories || [],
                followers: filterObject?.followers
                    ? filterObject?.followers
                    : {
                          min: 5,
                          max: 1000,
                      },
                search: filterObject?.search,
                language: filterObject?.language || '',
                gender: filterObject?.gender || { label: 'All', value: null },
                inviteOnly: filterObject?.inviteOnly || false,
            };
            return values;
        } catch (err) {
            console.log('initial value err', err);
            return initialSearchObj;
        }
    };

    useEffect(() => {
        const initialize = async () => {
            await RootStore.fetchStaticData();
            const values = await initialValues();
            // @ts-ignore
            setFormValues(values);
            onApplySearchTerm();
        };
        initialize();
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            selectAdHandler,
        }),
        [],
    );

    /**
     * hashtag modal toggle
     */
    const toggleSearchHashtagsModal = () => {
        setShowHashtagModal(!showHashtagModal);
    };
    /**
     *
     * @param value , range slider value
     */
    const setMicroValue = async (value) => {
        setMicro(value);
        if (value) {
            localStorage.setItem('macro', '0');
        } else {
            localStorage.setItem('macro', '1');
        }

        const obj = RootStore.discoverInfluencersStore.searchObj;
        const followersRange = { min: value ? 5 : 1001, max: value ? 1000 : 17000 };
        setFormValues({ ...obj, followers: followersRange });
        onSetSearchTerm('followers', followersRange);
        RootStore.discoverInfluencersStore.setSearchObj({ ...obj, followers: followersRange });
    };

    const formik = useRef<FormikProps<FormikValues>>(null);

    /**
     * just apply changed filter values
     */
    const applyFilter = () => {
        // if (real) realInfluencerSearch();
        // else onApplySearchTerm();
        onApplySearchTerm();
        if (isMobileAndTablet()) onToggle();
    };

    const selectAdHandler = async () => {
        const selectedAd = +RootStore.adStore.selectedAd || +localStorage.getItem('selected_ad');
        const savedFilter = JSON.parse(localStorage.getItem(`filter_${selectedAd}`));
        const adInfo = JSON.parse(localStorage.getItem('ad_info'));

        if (savedFilter) {
            //Pre-Toggle for micro & macro
            //@ts-ignore
            if (savedFilter?.followers?.min <= 1000) setMicro(true);
            else if (savedFilter?.followers?.min > 1000) setMicro(false);

            // 7 hashtags limit
            savedFilter.hashtags = savedFilter?.hashtags?.filter((_, i) => i < 7);
            setSearchObject(savedFilter);
            setFormValues(savedFilter);
            await RootStore.discoverInfluencersStore.setSearchObj(savedFilter);
            await _setSearchState(savedFilter);

            formik?.current?.setFieldValue('hashtags', savedFilter?.hashtags);
            formik?.current?.setFieldValue('search', savedFilter?.search);
            formik?.current?.setFieldValue('country', savedFilter?.country);
            formik?.current?.setFieldValue('categories', savedFilter?.categories);
            formik?.current?.setFieldValue('followers', savedFilter?.followers);
            formik?.current?.setFieldValue('language', savedFilter?.language);
            formik?.current?.setFieldValue('gender', savedFilter?.gender);
            formik?.current?.setFieldValue('inviteOnly', savedFilter?.inviteOnly);
        } else if (adInfo) {
            const initialSearchObj = {
                country: '',
                hashtags: [],
                categories: [],
                followers: {
                    min: 5,
                    max: 1000,
                },
                search: '',
                language: '',
                gender: { label: 'All', value: null },
                inviteOnly: undefined,
                sorts: [
                    {
                        key: 'followers',
                        direction: SortOrder.DESCENDING,
                    },
                ],
            };
            await setAdInfoData(initialSearchObj);
            await RootStore.discoverInfluencersStore.setSearchObj(initialSearchObj);
            await _setSearchState(initialSearchObj);
            // setSearchObject(initialSearchObj, true)

            formik?.current?.setFieldValue('hashtags', initialSearchObj?.hashtags);
            formik?.current?.setFieldValue('search', initialSearchObj?.search);
            formik?.current?.setFieldValue('country', initialSearchObj?.country);
            formik?.current?.setFieldValue('categories', initialSearchObj?.categories);
            formik?.current?.setFieldValue('followers', initialSearchObj?.followers);
            formik?.current?.setFieldValue('language', initialSearchObj?.language);
            formik?.current?.setFieldValue('gender', initialSearchObj?.gender);
            formik?.current?.setFieldValue('inviteOnly', initialSearchObj?.inviteOnly);

            // await setSearchObject(initialSearchObj, true);
        }
        onApplySearchTerm();
    };

    /**
     *
     * @param hashtags , give hashtag values for set to formik and search
     */
    const addHashtags = (hashtags: string[]): void => {
        setFormValues({ ...formValues, hashtags: formik.current.values.hashtags.concat(hashtags) });
        formik.current.setFieldValue('hashtags', formik.current.values.hashtags.concat(hashtags));
        onSetSearchTerm('hashtags', formik.current.values.hashtags);

        const obj = RootStore.discoverInfluencersStore.searchObj;
        RootStore.discoverInfluencersStore.setSearchObj({
            ...obj,
            hashtags: formik.current.values.hashtags.concat(hashtags),
        });
        //if (!isMobileAndTablet()) onApplySearchTerm();
    };

    const clearAllSettings = async () => {
        const selectedAd = +RootStore.adStore.selectedAd || +localStorage.getItem('selected_ad');
        localStorage.removeItem(`filter_${selectedAd}`);

        const initialSearchObj = {
            country: '',
            hashtags: [],
            categories: [],
            followers: {
                min: 5,
                max: 1000,
            },
            search: '',
            language: '',
            gender: { label: 'All', value: null },
            inviteOnly: undefined,
            sorts: [
                {
                    key: 'followers',
                    direction: SortOrder.DESCENDING,
                },
            ],
        };

        await RootStore.discoverInfluencersStore.setSearchObj(initialSearchObj);
        // await _setSearchState(initialSearchObj)

        formik.current.setFieldValue('hashtags', []);
        formik.current.setFieldValue('search', '');
        formik.current.setFieldValue('country', '');
        formik.current.setFieldValue('categories', []);
        formik.current.setFieldValue('followers', { min: 5, max: 1000 });
        formik.current.setFieldValue('language', '');
        formik.current.setFieldValue('gender', { label: 'All', value: null });
        formik.current.setFieldValue('inviteOnly', undefined);

        NotificationManager.success('Done!', 'Filter has been cleared', 2000, null, null, 'filled');
        showClearMenuHandler();
    };

    const returnToAdSettings = async () => {
        const adInfo = JSON.parse(localStorage.getItem('ad_info'));
        const selectedAd = +RootStore.adStore.selectedAd || +localStorage.getItem('selected_ad');
        localStorage.removeItem(`filter_${selectedAd}`);

        // @ts-ignore
        const from = props?.location?.state?.from || '';
        // @ts-ignore
        const by = props?.location?.state?.by || '';
        // @ts-ignore
        const value = props?.location?.state?.value || '';

        if (from && from === 'see-more') {
            const initialSearchObj = {
                country: '',
                hashtags: [],
                categories: [],
                followers: {
                    min: 5,
                    max: 1000,
                },
                search: '',
                language: '',
                gender: { label: 'All', value: null },
                inviteOnly: undefined,
                sorts: [
                    {
                        key: 'followers',
                        direction: SortOrder.DESCENDING,
                    },
                ],
            };
            formik.current.setFieldValue('hashtags', []);
            formik.current.setFieldValue('search', '');
            formik.current.setFieldValue('country', '');
            formik.current.setFieldValue('categories', []);
            formik.current.setFieldValue('followers', { min: 5, max: 1000 });
            formik.current.setFieldValue('language', '');
            formik.current.setFieldValue('gender', { label: 'All', value: null });
            formik.current.setFieldValue('inviteOnly', undefined);

            switch (by) {
                case explorerSearchType.LOCATION:
                    formik.current.setFieldValue('country', value);
                    await _setSearchState({ ...initialSearchObj, country: value?.value });
                    RootStore.discoverInfluencersStore.setSearchObj({ ...initialSearchObj, country: value?.value });
                    break;
                case explorerSearchType.CATEGORY:
                    formik.current.setFieldValue('categories', value);
                    await _setSearchState({ ...initialSearchObj, categories: value });
                    RootStore.discoverInfluencersStore.setSearchObj({ ...initialSearchObj, categories: value });
                    break;
                case explorerSearchType.HASHTAG:
                    formik.current.setFieldValue('hashtags', value);
                    await _setSearchState({ ...initialSearchObj, hashtags: value });
                    RootStore.discoverInfluencersStore.setSearchObj({ ...initialSearchObj, hashtags: value });
                    break;
                default:
                    break;
            }

            // applyFilter()
        } else {
            const initialSearchObj = {
                country: { label: '', value: '' } || '',
                hashtags: [],
                categories: [],
                followers: {
                    min: 5,
                    max: 1000,
                },
                search: '',
                language: '',
                gender: { label: 'All', value: null },
                inviteOnly: undefined,
                sorts: [
                    {
                        key: 'followers',
                        direction: SortOrder.DESCENDING,
                    },
                ],
            };

            // const adInfo = JSON.parse(localStorage.getItem('ad_info'))

            const countriesList = CountriesList || [];

            const name = countriesList?.find((li) => li.id === adInfo?.limit_country[0])?.name;
            const id = countriesList?.find((li) => li.id === adInfo?.limit_country[0])?.id;

            if (name && id) {
                initialSearchObj.country = {
                    label: name,
                    value: id,
                };
            } else {
                initialSearchObj.country = '';
            }
            const { limit_gender } = adInfo;
            if (limit_gender === 'male') {
                initialSearchObj.gender = { label: 'Male', value: 'male' };
            } else if (limit_gender === 'female') {
                initialSearchObj.gender = { label: 'Female', value: 'female' };
            } else {
                initialSearchObj.gender = { label: 'All', value: null };
            }

            initialSearchObj.inviteOnly = adInfo?.invite_only;
            initialSearchObj.hashtags = adInfo?.ad_hashtags
                ? adInfo?.ad_hashtags
                      ?.match(/#\w+/g)
                      ?.map((item) => item.substring(1))
                      ?.filter((_, i) => i < 7)
                : [];
            initialSearchObj.categories = [];
            initialSearchObj.language = '';
            initialSearchObj.followers = {
                min: 5,
                max: 1000,
            };
            await RootStore.discoverInfluencersStore.setSearchObj(initialSearchObj);
            // await _setSearchState(initialSearchObj)

            formik.current.setFieldValue('hashtags', initialSearchObj?.hashtags);
            formik.current.setFieldValue('search', initialSearchObj?.search);
            formik.current.setFieldValue('country', initialSearchObj?.country);
            formik.current.setFieldValue('categories', initialSearchObj?.categories);
            formik.current.setFieldValue('followers', initialSearchObj?.followers);
            formik.current.setFieldValue('language', initialSearchObj?.language);
            formik.current.setFieldValue('gender', initialSearchObj?.gender);
            formik.current.setFieldValue('inviteOnly', initialSearchObj?.inviteOnly);
            // setSearchObject(initialSearchObj, true);
            // applyFilter()
        }
        showClearMenuHandler();
    };

    const realInfluencerHandler = async () => {
        setRealInfluencers(!realInfluencers);
        // applyFilter(!realInfluencers)
    };

    const searchInfluencer = async () => {
        // setSearchObject(formik.current.values, true);
        const obj = await RootStore.discoverInfluencersStore.searchObj;
        await _setSearchState(obj);
        applyFilter();
        if (autoSave) {
            savefilter(formik.current.values);
        }
    };

    const showClearMenuHandler = () => {
        setShowClearMenu((prev) => !prev);
    };
    const clearOptions = [
        {
            label: 'Clear & Return to Ad Setting',
            action: returnToAdSettings,
        },
        {
            label: 'Clear All Settings',
            action: clearAllSettings,
        },
    ];

    return (
        <div>
            <SearchHashtag isOpen={showHashtagModal} toggle={toggleSearchHashtagsModal} action={addHashtags} />
            <Modal size="lg" isOpen={fetchingData}>
                <ModalBody className="text-center p-3">
                    <div className="searchHashtagModal_close-box" onClick={props.closeLoading}>
                        <CloseIcon width="20" height="20" />
                    </div>
                    <img src={findingInfluencers} alt="influencers_loader" width={'75%'} />

                    <h4 className="p-2" style={{ lineHeight: '25px' }}>
                        <b>
                            Hang tight! Your <span className="preloading"> AI</span>&nbsp;pal is looking for influencers
                            for you!
                        </b>
                    </h4>
                </ModalBody>
            </Modal>
            <ApplicationMenu isOpen={isOpen} toggleOpenHandler={onToggle}>
                <PerfectScrollbar
                    option={{
                        suppressScrollX: true,
                        wheelPropagation: true,
                    }}
                >
                    <div className="p-4 mb-6">
                        <h2 className="text-center mb-4">
                            <b> Search Filters</b>
                        </h2>
                        {loading ? (
                            <React.Fragment>
                                <div className="text-center">
                                    <Spinner color="primary" className="mb-1" />
                                    <p>Please wait...</p>
                                </div>
                            </React.Fragment>
                        ) : (
                            <>
                                <FormikWithRef
                                    ref={formik}
                                    enableReinitialize
                                    initialValues={formValues}
                                    onSubmit={() => {}}
                                    validationSchema={validationSchema}
                                >
                                    {({
                                        handleSubmit,
                                        setFieldValue,
                                        setFieldTouched,
                                        handleChange,
                                        handleBlur,
                                        values,
                                        errors,
                                        touched,
                                        isSubmitting,
                                        submitForm,
                                        resetForm,
                                    }) => (
                                        <Form translate={'no'} className="av-tooltip tooltip-label-right px-2">
                                            {/* <div className={realInfluencers ? 'inactive-part' : ''}> */}
                                            <div>
                                                <FormGroup className="error-l-100">
                                                    <Label className="position-relative">
                                                        <b>Username </b>
                                                        {errors.search && touched.search && (
                                                            <div className="invalidFeedback">{errors.search}</div>
                                                        )}
                                                    </Label>
                                                    <Field
                                                        className="form-control"
                                                        name="search"
                                                        placeholder="Search influencer's name, IG username and bio"
                                                        onChange={(event: any) => {
                                                            if (event.target.value) {
                                                                event.preventDefault();
                                                                setFieldValue('search', event.target.value);
                                                                onSetSearchTerm('search', event.target.value);
                                                            } else {
                                                                event.preventDefault();
                                                                setFieldValue('search', '');
                                                                onSetSearchTerm('search', null);
                                                            }

                                                            //if (!isMobileAndTablet()) onApplySearchTerm();
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="error-l-100">
                                                    <Label className="position-relative">
                                                        <b>
                                                            Hashtags :
                                                            <span className="text-muted"> (enter and hit enter)</span>
                                                        </b>
                                                        {errors.hashtags && touched.hashtags && (
                                                            <div className="invalidFeedback">{errors.hashtags}</div>
                                                        )}
                                                    </Label>
                                                    <TagsInputExample
                                                        onChange={(name: string, value: any) => {
                                                            setFieldValue(name, value);
                                                            onSetSearchTerm('hashtags', value);
                                                            //if (!isMobileAndTablet()) onApplySearchTerm();
                                                        }}
                                                        name="hashtags"
                                                        id="hashtags"
                                                        placeholder="Add #"
                                                        tags={values?.hashtags || []}
                                                    />
                                                </FormGroup>
                                                <div className="d-flex justify-content-end mb-1">
                                                    <button
                                                        className="searchInfluencerAddHashtag "
                                                        onClick={toggleSearchHashtagsModal}
                                                        type="button"
                                                    >
                                                        Search Hashtags
                                                    </button>
                                                </div>

                                                <FormGroup className="error-l-100">
                                                    <Label className="position-relative">
                                                        <b>Categories:</b>
                                                        {errors.categories && touched.categories && (
                                                            <div className="invalidFeedback">{errors.categories}</div>
                                                        )}
                                                    </Label>
                                                    <FormikReactSelect
                                                        name="categories"
                                                        id="categories"
                                                        value={values.categories}
                                                        isMulti={true}
                                                        options={RootStore.getCategorySelectOptions()}
                                                        onChange={(name: string, value: any, slug: any) => {
                                                            setFieldValue(name, value);
                                                            onSetSearchTerm(name, value);
                                                            //if (!isMobileAndTablet()) onApplySearchTerm();
                                                        }}
                                                        onBlur={setFieldTouched}
                                                    />
                                                </FormGroup>

                                                <FormGroup className="error-l-100">
                                                    <Label className="position-relative">
                                                        <b>Location:</b>
                                                        {errors.country && touched.country && (
                                                            <div className="invalidFeedback">{errors.country}</div>
                                                        )}
                                                    </Label>
                                                    <FormikReactSelect
                                                        name="country"
                                                        id="country"
                                                        value={values.country}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        options={CountriesList?.map((cou: ICountryModel) => {
                                                            return {
                                                                label: cou.name,
                                                                value: cou.id,
                                                            };
                                                        })}
                                                        onChange={(name: string, value: any) => {
                                                            setFieldValue(name, value || null);
                                                            onSetSearchTerm('country', value || null);

                                                            //if (!isMobileAndTablet()) onApplySearchTerm();
                                                        }}
                                                        onBlur={setFieldTouched}
                                                        className="w-100"
                                                    />
                                                </FormGroup>
                                                <FormGroup className="error-l-100">
                                                    <Label className="position-relative">
                                                        <b>Language:</b>
                                                        {errors.language && touched.language && (
                                                            <div className="invalidFeedback">{errors.language}</div>
                                                        )}
                                                    </Label>
                                                    <FormikReactSelect
                                                        className="w-100"
                                                        name="language"
                                                        id="language"
                                                        value={values.language}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        options={languageList?.map((lang: any) => {
                                                            return {
                                                                label: lang.name,
                                                                value: lang.value,
                                                            };
                                                        })}
                                                        onChange={(name: any, value: any) => {
                                                            setFieldValue(name, value || null);
                                                            onSetSearchTerm(name, value || null);

                                                            //if (!isMobileAndTablet()) onApplySearchTerm();
                                                        }}
                                                        onBlur={setFieldTouched}
                                                    />
                                                </FormGroup>

                                                <FormGroup className="error-l-100 mb-4">
                                                    <Label className="position-relative">
                                                        <b>Gender:</b>
                                                        {errors.gender && touched.gender && (
                                                            <div className="invalidFeedback">{errors.gender}</div>
                                                        )}
                                                    </Label>
                                                    <FormikReactSelect
                                                        className="w-100"
                                                        name="gender"
                                                        id="gender"
                                                        value={values.gender}
                                                        isMulti={false}
                                                        options={[
                                                            { label: 'All', value: null },
                                                            { label: 'Male', value: 'male' },
                                                            { label: 'Female', value: 'female' },
                                                        ]}
                                                        onChange={(name: any, value: any) => {
                                                            setFieldValue(name, value || null);
                                                            onSetSearchTerm(name, value || null);
                                                            //if (!isMobileAndTablet()) onApplySearchTerm();
                                                        }}
                                                        onBlur={() => {}}
                                                    />
                                                </FormGroup>

                                                <FormGroup className="error-l-100 mt-3 mb-6">
                                                    <Label className="position-relative">
                                                        <b>Followers:</b>
                                                        {errors.followers && touched.followers && (
                                                            <div className="invalidFeedback">{errors.followers}</div>
                                                        )}
                                                    </Label>

                                                    <ButtonGroup className="sliderToggle ml-2">
                                                        <Button
                                                            color="primary"
                                                            size="xs"
                                                            onClick={() => setMicroValue(true)}
                                                            active={micro}
                                                        >
                                                            Micro
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            size="xs"
                                                            onClick={() => setMicroValue(false)}
                                                            active={!micro}
                                                        >
                                                            Macro
                                                        </Button>
                                                    </ButtonGroup>

                                                    <RangeTooltip
                                                        min={micro ? 5 : 1001}
                                                        max={micro ? 1000 : 100000}
                                                        className="my-2"
                                                        name="followers"
                                                        //defaultValue={[values.followers.min, values.followers.max]}
                                                        value={[
                                                            values?.followers?.min || 5,
                                                            values?.followers?.max || 1000,
                                                        ]}
                                                        pushable={10}
                                                        onChange={(value: any) => {
                                                            setFieldValue('followers', {
                                                                min: value[0],
                                                                max: value[1],
                                                            });
                                                            onSetSearchTerm('followers', {
                                                                min: value[0],
                                                                max: value[1],
                                                            });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {/* {superAdmin && (
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            checked={realInfluencers}
                                                            onChange={() => setRealInfluencers(!realInfluencers)}
                                                        />{' '}
                                                        Get Real Influencers
                                                    </Label>
                                                </FormGroup>
                                            )} */}
                                        </Form>
                                    )}
                                </FormikWithRef>
                                {superAdmin && (
                                    <div onClick={realInfluencerHandler} className="custom-search-real-parent">
                                        <strong>Influencers with Email: &nbsp;</strong>
                                        <Switch
                                            className={`custom-switch custom-switch-primary ${
                                                realInfluencers ? 'item-check' : null
                                            }`}
                                            checked={realInfluencers}
                                        />
                                    </div>
                                )}
                                <div className="autosave-clearbtn-box">
                                    <div onClick={setAutoSave} className="custom-switch-parent">
                                        <strong>Auto Save : &nbsp;</strong>
                                        <Switch
                                            className={`custom-switch custom-switch-primary ${
                                                autoSave ? 'item-check' : null
                                            }`}
                                            checked={autoSave}
                                        />
                                    </div>
                                    <div className="menuDropdownClear">
                                        <Button size="sm" className="button" onClick={showClearMenuHandler}>
                                            <span className="text"> &nbsp; Clear &nbsp; </span>
                                            <DeleteIcon />
                                        </Button>
                                        {showClearMenu && (
                                            <div className="result">
                                                <ul>
                                                    {clearOptions.map((element: any): any => (
                                                        <li key={element.label} onClick={element.action}>
                                                            {element.label}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <Button
                                    size="lg"
                                    block
                                    color="success"
                                    type="submit"
                                    className="applyFilter semi-round-border mt-3"
                                    onClick={searchInfluencer}
                                >
                                    <i className="iconsminds-magnifi-glass icon"></i>
                                    Search&nbsp;<span className="d-none d-sm-block">Influencers</span>
                                </Button>
                            </>
                        )}
                    </div>
                </PerfectScrollbar>
            </ApplicationMenu>
        </div>
    );
};

export default React.forwardRef(Filters);

/* <FormGroup tag="fieldset">
                                                <Row>
                                                    <Col xd={6} style={{ paddingTop: '10px' }}>
                                                        <Input
                                                            type="radio"
                                                            name="orand"
                                                            value={'or'}
                                                            checked={orand === 'or'}
                                                            onChange={orandchange}
                                                        />
                                                        {''}
                                                        OR
                                                    </Col>
                                                    <Col xd={6} style={{ paddingTop: '10px' }}>
                                                        <Input
                                                            type="radio"
                                                            name="orand"
                                                            value={'and'}
                                                            checked={orand === 'and'}
                                                            onChange={orandchange}
                                                        />
                                                        {''}
                                                        AND
                                                    </Col>
                                                </Row>
                                            </FormGroup> */

/* <FormGroup className="error-l-100">
                                                    <Label className="position-relative">
                                                        <b>Keyword:</b>
                                                        {errors.search && touched.search && (
                                                            <div className="invalidFeedback">{errors.search}</div>
                                                        )}
                                                    </Label>
                                                    <Field
                                                        className="form-control"
                                                        name="search"
                                                        placeholder="Search influencer's name, IG username and bio"
                                                        onChange={(event: any) => {
                                                            if (event.target.value) {
                                                                event.preventDefault();
                                                                setFieldValue('search', event.target.value);
                                                                onSetSearchTerm('search', event.target.value);
                                                            } else {
                                                                event.preventDefault();
                                                                setFieldValue('search', '');
                                                                onSetSearchTerm('search', null);
                                                            }

                                                            //if (!isMobileAndTablet()) onApplySearchTerm();
                                                        }}
                                                    />
                                                </FormGroup> */

// if(from && from !== 'see-more'){
//     console.log('from && from == see-more')
//     if(selectedAd){
//         if(state && adInfo){
//             setAdInfoData(filterObject)
//         }else if(!state && savedFilter){
//             filterObject = savedFilter
//         }else{
//             filterObject = initialSearchObj
//         }
//     }else{
//         filterObject = initialSearchObj
//     }
// }else if(from && from === 'see-more'){
//     console.log('see-more')
//     console.log('state', state)
//     switch (name) {
//         case explorerSearchType.CATEGORY:
//             filterObject.categories = value;
//             break
//         case explorerSearchType.LOCATION:
//             filterObject.location = value;
//             break
//         case explorerSearchType.HASHTAG:
//             filterObject.hashtags = value;
//             break
//         default:
//             break
//     }
//     console.log('filterObject', filterObject)

// }else if(from && from === 'ad'){
//     console.log('from && from == ad')

//     if(selectedAd){
//         if(state && adInfo){
//             setAdInfoData(filterObject)
//         }else if(!state && savedFilter){
//             filterObject = savedFilter
//         }else{
//             filterObject = initialSearchObj
//         }
//     }else{
//         filterObject = initialSearchObj
//     }
// }

// if (from === 'see-more') {
//     console.log('state ',props.location.state)
//     switch (props.location?.state?.name) {
//         case explorerSearchType.LOCATION:
//             // let country = CountriesList.filter(
//             //     (country: ICountryModel) => country.id === props.location.state.value,
//             // )[0];
//             // @ts-ignore
//             values.country = value
//             break;
//         case explorerSearchType.HASHTAG:
//             values.hashtags = [].concat(value);
//             break;
//         case explorerSearchType.CATEGORY:
//             values.categories = [].concat(value);
//             break;
//     }
// }

// const orandchange = (value) => {
//     orandstate(value.target.value);
//     RootStore.discoverInfluencersStore.setCategory_condition(value.target.value);
//     onApplySearchTerm();
// };

// clear
// @ts-ignore
// const from = props?.location?.state?.from || ''
// @ts-ignore
// const by = props?.location?.state?.by || ''
// @ts-ignore
// const value = props?.location?.state?.value || ''

// if(from && from === 'see-more'){
//     const initialSearchObj = {
//         country: '',
//         hashtags: [],
//         categories: [],
//         followers: {
//             min: 5,
//             max: 1000,
//         },
//         language: '',
//         gender: {label:'All',value:null},
//         inviteOnly: undefined,
//         ...(realInfluencers ? {is_super_admin:true} : {}),
//         sorts: [
//             {
//                 key: 'followers',
//                 direction: SortOrder.DESCENDING,
//             },
//         ],
//     }
//     formik.current.setFieldValue('hashtags',[])
//     formik.current.setFieldValue('country','')
//     formik.current.setFieldValue('categories',[])
//     formik.current.setFieldValue('followers',{min: 5,max: 1000})
//     formik.current.setFieldValue('language','')
//     formik.current.setFieldValue('gender',{label:'All',value:null})
//     formik.current.setFieldValue('inviteOnly',undefined)

//     switch (by) {
//         case explorerSearchType.LOCATION:
//             formik.current.setFieldValue('country',value )
//             await _setSearchState({...initialSearchObj,country: value?.value})
//             RootStore.discoverInfluencersStore.setSearchObj({...initialSearchObj,country: value?.value});
//             break
//         case explorerSearchType.CATEGORY:
//             formik.current.setFieldValue('categories',value)
//             await _setSearchState({...initialSearchObj,categories :value})
//             RootStore.discoverInfluencersStore.setSearchObj({...initialSearchObj,categories :value});
//             break
//         case explorerSearchType.HASHTAG:
//             formik.current.setFieldValue('hashtags',value)
//             await _setSearchState({...initialSearchObj,hashtags :value})
//             RootStore.discoverInfluencersStore.setSearchObj({...initialSearchObj,hashtags :value});
//             break
//         default:
//             break
//     }

//     applyFilter(realInfluencers)
// }else {
//     const initialSearchObj = {
//         country: '',
//         hashtags: [],
//         categories: [],
//         followers: {
//             min: 5,
//             max: 1000,
//         },
//         language: '',
//         gender: {label:'All',value:null},
//         inviteOnly: undefined,
//         sorts: [
//             {
//                 key: 'followers',
//                 direction: SortOrder.DESCENDING,
//             },
//         ],
//     }

//     // const adInfo = JSON.parse(localStorage.getItem('ad_info'))

//     const countriesList = CountriesList || []

//     const name = countriesList?.find(li => li.id === adInfo?.limit_country[0])?.name
//     const id = countriesList?.find(li => li.id === adInfo?.limit_country[0])?.id

//     if(name && id){
//         initialSearchObj.country =  {
//             label: name,
//             value: id
//         }
//     }else{
//         initialSearchObj.country = ''
//     }
//     const {limit_gender} = adInfo
//     if(limit_gender === 'male'){
//         initialSearchObj.gender = {label:'Male',value:'male'}
//     }else if(limit_gender === 'female'){
//         initialSearchObj.gender = {label:'Female',value:'female'}
//     }else{
//         initialSearchObj.gender = {label:'All',value:null}
//     }

//     initialSearchObj.inviteOnly = adInfo?.invite_only
//     initialSearchObj.hashtags = adInfo?.ad_hashtags ? adInfo?.ad_hashtags?.
//                                                                 match(/#\w+/g)?.
//                                                                 map(item =>item.substring(1))?.
//                                                                 filter((_,i)=>i<7)  : []
//     initialSearchObj.categories = []
//     initialSearchObj.language = ''
//     initialSearchObj.followers = {
//         min: 5,
//         max: 1000,
//     }

//     await RootStore.discoverInfluencersStore.setSearchObj(initialSearchObj)
//     await _setSearchState(initialSearchObj)

//     formik.current.setFieldValue('hashtags',initialSearchObj?.hashtags )
//     formik.current.setFieldValue('country',initialSearchObj?.country )
//     formik.current.setFieldValue('categories',initialSearchObj?.categories )
//     formik.current.setFieldValue('followers',initialSearchObj?.followers )
//     formik.current.setFieldValue('language',initialSearchObj?.language )
//     formik.current.setFieldValue('gender',initialSearchObj?.gender )
//     formik.current.setFieldValue('inviteOnly',initialSearchObj?.inviteOnly )
//     // setSearchObject(initialSearchObj, true);
//     applyFilter(realInfluencers)
// }
