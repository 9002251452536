import { Field, Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import classes from './style.module.scss';
import { FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormikDatePicker } from '../../../../../../../../common-components';
// import Transport from '../../../../../../../../..//lib/Transport';
import moment from 'moment';
import Swal from 'sweetalert2';
// import { handleError } from '../../../../../../../../../lib/Utils';
import LoadingButton from '../../../../../../../../common-components/loadingButton';
import { isStaging, v2UsersCheckouts, v2UsersPayouts } from './../../../../../../../../../constants/defaultValues';
import axios from 'axios';
import RootStore from '../../../../../../../../../store/RootStore';

function ModalPayoutPay(props: any) {
    const [IsLoading, setIsLoading] = useState(false);
    const [checkoutsList, setCheckoutsList] = useState([]);

    console.log(props);
    const _getCheckoutLists = async () => {
        const headers = {
            headers: {
                Authorization: 'Bearer ' + RootStore.users.getToken(),
            },
        };

        const baseURL = isStaging() ? 'https://api-staging.ainfluencer.com/api' : 'https://ainfluencer.com/api';

        const url = `${baseURL}${v2UsersCheckouts(props.dataPayment?.data?.id)}`;

        axios
            .get(url, headers)
            .then((res) => {
                const { checkouts } = res?.data;
                setCheckoutsList(checkouts);
                console.log('res checoute', res);
            })
            .catch((err) => {
                Swal.fire({
                    type: 'error',
                    text: err.response.data.message,
                    showCancelButton: false,
                    showConfirmButton: true,
                });
            });
    };

    useEffect(() => {
        _getCheckoutLists();
    }, []);

    const InitialValues = {
        requests: '',
        amount: '',
        created_at: '',
        currency: 'usd',
        payment_password: props.dataPayment.password_payment || '',
        payment_fee: props.dataPayment.payment_fee || 20,
    };

    const formikSchema = Yup.object().shape({
        amount: Yup.number().typeError('please enter valid amount').required("amount can't be empty"),
        created_at: Yup.date().typeError('please enter valid date').required("date can't be empty"),
        currency: Yup.string().required("currency can't be empty"),
        // payment_fee: Yup.number().max(100, (obj) => {
        //   const value = obj.value;
        //   if (value > 100) return `Payment Fee cannot be more than ${obj.max}%`;
        // }),
        payment_password: Yup.string().required("password can't be empty"),
    });

    const submitForm = (values) => {
        setIsLoading(true);
        const headers = {
            headers: {
                Authorization: 'Bearer ' + RootStore.users.getToken(),
            },
        };
        const {
            requests,
            currency,
            amount,
            created_at,
            payment_password,
            //payment_fee,
        } = values;

        const body = {
            checkout_request_id: requests,
            currency,
            amount,
            created_at: moment(created_at).format('MM DD YYYY, hh:mm:ss'),
            payment_password,
            //fee: payment_fee,
            paid_to: props.dataPayment?.data?.paypal_email,
        };
        //console.log('XXX ', body);

        const baseURL = isStaging() ? 'https://api-staging.ainfluencer.com/api' : 'https://ainfluencer.com/api';

        const url = `${baseURL}${v2UsersPayouts(props.dataPayment?.data?.id)}`;

        axios
            .post(url, body, headers)
            .then((res) => {
                setIsLoading(false);
                props.toggle();
                Swal.fire('Got Paid!', 'Payout was successful. User got paid 😉', 'success');
                console.log('res v2UsersPayouts', res);
                props.fetchData();
            })
            .catch((err) => {
                console.log('PayOut Catch:', err);
                setIsLoading(false);
                Swal.fire({
                    type: 'error',
                    text: err.response.data.message,
                    showCancelButton: false,
                    showConfirmButton: true,
                });
            });
    };

    const { first_name = '', last_name = '' } = props?.dataPayment?.data;
    const { paypal_email } = props.dataPayment?.data?.admin_wallet || '';

    return (
        <Modal {...props} className="asset-modal">
            <ModalHeader toggle={props.toggle}>
                Pay to {`${first_name} ${last_name} ${paypal_email ? `(${paypal_email})` : ''}`}
                <div className={classes.pay_action_title}>
                    Total to be Paid: {props.dataPayment?.data?.total_to_pay}
                </div>
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={InitialValues}
                    validationSchema={formikSchema}
                    onSubmit={(values) => submitForm(values)}
                >
                    {({ setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                        <Form className="av-tooltip tooltip-label-right panel-form editBrandInfoForm">
                            {/* <FormGroup className="error-l-100">
                                <Label className="position-relative">
                                    <b>Requests:</b>
                                </Label>
                                <select
                                    className={`form-control ${classes.inputs}`}
                                    name="requests"
                                    onChange={(e) => setFieldValue('requests', e.target.value)}
                                >
                                    <option selected value="0">
                                        Select a request ...
                                    </option>
                                    {checkoutsList?.map((item) => {
                                        const { id, amount, priority } = item;
                                        return (
                                            <option key={id} value={id}>
                                                <span>
                                                    ${amount} {'  '}
                                                </span>
                                                <span>
                                                    {'  '} {priority}
                                                </span>
                                            </option>
                                        );
                                    })}
                                </select>
                            </FormGroup> */}

                            <FormGroup className="error-l-100">
                                <Label className="position-relative">
                                    <b>Amount to pay Influencer:</b>
                                    {errors.amount && touched.amount && (
                                        <div className="invalidFeedback">{errors.amount}</div>
                                    )}
                                </Label>
                                <Field
                                    className={`form-control ${classes.inputs}`}
                                    name="amount"
                                    disabled={isSubmitting}
                                />
                            </FormGroup>

                            <FormGroup className="error-l-100">
                                <Label className="position-relative">
                                    <b>Date:</b>
                                    {errors.created_at && touched.created_at && (
                                        <div className="invalidFeedback">{errors.created_at}</div>
                                    )}
                                </Label>
                                <FormikDatePicker
                                    name="created_at"
                                    value={values.created_at}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    // @ts-ignore
                                    showTimeSelect
                                    renderInput={(props, openCalendar) => (
                                        <input
                                            type="text"
                                            {...props}
                                            onClick={openCalendar}
                                            className={`form-control ${classes.inputs}`}
                                            readOnly
                                        />
                                    )}
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="LLL"
                                    timeCaption="Time"
                                />
                            </FormGroup>

                            <FormGroup className="error-l-100">
                                <Label className="position-relative">
                                    <b>Currency:</b>
                                    {errors.currency && touched.currency && (
                                        <div className="invalidFeedback">{errors.currency}</div>
                                    )}
                                </Label>
                                <select
                                    className={`form-control ${classes.inputs}`}
                                    name="currency"
                                    onChange={(e) => setFieldValue('currency', e.target.value)}
                                >
                                    <option value="usd">usd</option>
                                </select>
                            </FormGroup>

                            {/* <FormGroup className="error-l-100">
                                <Label className="position-relative">
                                    <b>Payment fee(%):</b>
                                    {errors.payment_fee && touched.payment_fee && (
                                        <div className="invalidFeedback">{errors.payment_fee}</div>
                                    )}
                                </Label>
                                <Field className={`form-control ${classes.inputs}`} type="text" name="payment_fee" />
                            </FormGroup> */}

                            <FormGroup className="error-l-100">
                                <Label className="position-relative">
                                    <b>Payment password:</b>
                                    {errors.payment_password && touched.payment_password && (
                                        <div className="invalidFeedback">{errors.payment_password}</div>
                                    )}
                                </Label>
                                <Field
                                    className={`form-control ${classes.inputs}`}
                                    type="password"
                                    name="payment_password"
                                />
                            </FormGroup>

                            <LoadingButton backgroundColor="success" loading={IsLoading}>
                                Pay Now
                            </LoadingButton>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
}

export default ModalPayoutPay;
