import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
* this method Payment Method Model
*/
export const PaymentMethodModel = types.model({
  id: types.identifierNumber,
  created_at: types.string,
  updated_at: types.string,
  data: types.frozen(),
});

export interface IPaymentMethodModel
  extends Instance<typeof PaymentMethodModel> {}

export interface IPaymentMethodModelSnapshotIn
  extends SnapshotIn<typeof PaymentMethodModel> {}

export interface IPaymentMethodModelSnapshotOut
  extends SnapshotOut<typeof PaymentMethodModel> {}
