import React, { useState, useRef } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { Form } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { clone, destroy } from 'mobx-state-tree';
import { Scrollbars } from 'react-custom-scrollbars';

import { IContentModel } from '../../../../store/models/ContentModel';
import { ajaxErrorAlert, handleError, hashtagsCounter, makeTempModelId } from '../../../../lib/Utils';
import RootStore from '../../../../store/RootStore';
import { NotificationManager } from '../../../../template/components/common/react-notifications';
import FormikMediaList from './media-list/FormikMediaList';
import { ContentActionEnum, ContentTypeEnum } from './ContentFormDialogue';
import { fixMediaStructure } from '../../../../store/models/MediaModel';
import { HashtagIcon } from '../../../../assets/icons';
import SearchHashtag from '../../../common-components/SearchHashtag';
import FormikWithRef from '../../../common-components/FormikWithRef';
import Picker, { SKIN_TONE_MEDIUM_LIGHT } from 'emoji-picker-react';
import $ from "jquery";

interface Props {
    action: ContentActionEnum;
    contentType: ContentTypeEnum;
    model: IContentModel;
    onChange: Function;
    saveButtonText?: string;
    persistOnSave: boolean;
    getOnchageData?: any
}

const ContentForm: React.FC<Props> = (props) => {
    let cloned = clone(props.model);
    const formik = useRef(null);
    const [cloneState] = useState(cloned);
    const [showHashtagModal, setShowHashtagModal] = useState(false);
    const textRef = useRef<any>();
    const childRef = React.createRef<any>();
    
    const toggleSearchHashtagsModal = () => {
        setShowHashtagModal(!showHashtagModal);
    };

    const addHashtags = (hashtags: string[], field: string = 'caption'): void => {
        const hashtagsString = '\n.\n#' + hashtags.join(' #');
        formik?.current?.setFieldValue(field, formik?.current?.values?.caption + hashtagsString);
    };

    const [show, setShow] = useState(false);

    const addEmoji = (event, emojiObject) => {
        if (emojiObject.emoji) {
            // will give the current position of the cursor
            const curorPos = $('#onboard-caption').prop('selectionStart');

            // will get the value of the text area
            let x = $('#onboard-caption').val().toString();
            const returnedText = x.slice(0, curorPos) + emojiObject.emoji + x.slice(curorPos)
            // setting the updated value in the text area
            formik.current.setFieldValue('caption', returnedText);
            props.getOnchageData('captcha',returnedText)
            setShow(false);
        }
    };

    const renderHashCounter = (caption) =>{
        if(hashtagsCounter(caption) < 31){
            return <>
                    <HashtagIcon /> 
                    {hashtagsCounter(caption)} 
                </>
        }else{
            return <>
                    Exceeded {hashtagsCounter(caption) - 30}&nbsp;
                    <HashtagIcon /> 
                </>
        }
    }

    return (
        <>
            <SearchHashtag isOpen={showHashtagModal} toggle={toggleSearchHashtagsModal} action={addHashtags} />
            <FormikWithRef
                ref={formik}
                // enableReinitialize={true}
                initialValues={{
                    caption: props.contentType === ContentTypeEnum.POST ? props.model.data.caption : '',
                    medias: props.model.getMedias(),
                }}
                validationSchema={
                    props.contentType === ContentTypeEnum.POST
                        ? Yup.object().shape({
                            medias: Yup.array().min(1),
                            caption: Yup.string()
                                .max(2200)
                                .test(
                                    'test-caption',
                                    "You're not allowed to use more than 30 hashtags in caption.",
                                    (value) => 30 - hashtagsCounter(value) > -1,
                                ),
                        })
                        : Yup.object().shape({
                            medias: Yup.array().min(1),
                        })
                }
                onSubmit={async (values, action) => {
                    action.setSubmitting(true);
                    if (!RootStore.users) {
                        action.setSubmitting(false);
                        return;
                    }

                    if (hashtagsCounter(values?.caption) > 30) {
                        ajaxErrorAlert(`You're not allowed to use more than 30 hashtags in caption`);
                        action.setSubmitting(false);
                        return;
                    }

                    values = JSON.parse(JSON.stringify(values));
                    // @ts-ignore
                    values.data = {};
                    if (values?.caption) {
                        // @ts-ignore
                        values.data.caption = JSON.parse(JSON.stringify(values?.caption));
                    }

                    values.caption = undefined;

                    if (props.action !== ContentActionEnum.CREATE) {
                        try {
                            if (props.persistOnSave) {
                                await RootStore.contentStore.update(props.model, props.model.modifications, values);
                                props.onChange(props.model);
                                //destroy(props.model);
                            } else {
                                props.onChange(props.model);
                            }

                            NotificationManager.success('Done', 'Data was updated!', 3000, null, null, 'filled');
                        } catch (e) {
                            ajaxErrorAlert('Could not save changes!');
                            handleError(e);
                        }
                    } else {
                        let tempId = makeTempModelId();

                        let payload: any = {};
                        if (props.contentType === ContentTypeEnum.POST) {
                            // @ts-ignore
                            payload['caption'] = values?.data?.caption;
                        }

                        for (let i = 0; i < values?.medias?.length; i++) {
                            // @ts-ignore
                            values.medias[i] = fixMediaStructure(values?.medias[i]);
                        }

                        try {
                            if (props.persistOnSave) {
                                let model = await RootStore.contentStore.create({
                                    ...values,
                                    ...{
                                        id: tempId,
                                        user_id: RootStore.users.currentUser.id,
                                        data: payload,
                                        type: props.contentType,
                                    },
                                });
                                props.onChange(model);
                                destroy(cloneState);
                            } else {
                                props.onChange(props.model);
                            }
                            NotificationManager.success(
                                'Done',
                                'New content was successfully created!',
                                3000,
                                null,
                                null,
                                'filled',
                            );
                        } catch (e) {
                            handleError(e);
                            ajaxErrorAlert('Could not create new media!');
                        }
                    }
                    action.setSubmitting(true);
                }}
            >
                {({
                    setFieldValue,
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    submitForm,
                }) => (
                    <Form translate={'no'} className="av-tooltip tooltip-label-right panel-form">
                        <Scrollbars
                            ref={childRef}
                            cellPadding={0}
                            className="contentFormScrollbarContainer"
                            renderTrackHorizontal={(props) => (
                                <div {...props} style={{ display: 'none' }} className="track-horizontal" />
                            )}
                        >
                            <FormGroup>
                                <Label className="position-relative">
                                    Content:
                                    {errors.medias && touched.medias && (
                                        <div className="invalidFeedback">{errors.medias}</div>
                                    )}
                                </Label>
                                <FormikMediaList
                                    contentClone={props.model}
                                    name={'medias'}
                                    contentType={props.contentType}
                                    onChange={setFieldValue}
                                    value={values?.medias}
                                />
                            </FormGroup>

                            {props.contentType === ContentTypeEnum.POST ? (
                                <>
                                    <FormGroup className="error-l-100 position-relative">
                                        <div style={{ position: 'relative' }}>
                                            <Label className="d-flex position-relative">
                                                <span className="pt-1">
                                                    Feed Caption:{' '}
                                                    {errors.caption && touched.caption && (
                                                        <div className="invalidFeedback">{errors.caption}</div>
                                                    )}
                                                </span>
                                                <div className="d-flex justify-content-end pl-2">
                                                    <div className="SearchHashtag" onClick={toggleSearchHashtagsModal}>
                                                        <i className="simple-icon-magnifier"></i> Search &amp; Add Hashtags
                                                    </div>
                                                </div>
                                            </Label>

                                            <textarea
                                                id="onboard-caption"
                                                onFocus={() => setShow(show ? false : null)}
                                                name="caption"
                                                placeholder="Write your caption"
                                                onChange={(event: any) => {
                                                    props.getOnchageData('captcha', event.target.value)
                                                    setFieldValue('caption', event.target.value);
                                                    // const target = event.target as HTMLTextAreaElement;
                                                    // textRef.current.style.height = "175px";
                                                    // textRef.current.style.height = `${target.scrollHeight}px`;

                                                    // if (childRef && event.target.value.length > 200) {
                                                    //     const { scrollHeight } = childRef.current.getValues();
                                                    //     childRef.current.scrollTop(scrollHeight);
                                                    // }
                                                }}
                                                value={values?.caption}
                                                className="form-control w-100"
                                                ref={textRef}
                                                style={{
                                                    height: 225,
                                                    resize: 'none',
                                                    paddingBottom: '1.75rem',
                                                }}
                                            >
                                            </textarea>

                                            <div className="contentCaptionHashtagCounter">
                                                <span
                                                    onClick={() => setShow(!show)}
                                                    className={'cursor-pointer simple-icon-emotsmile'}
                                                ></span>

                                                {show && (
                                                    <div className="contentPickerPosition">
                                                        <Picker
                                                            onEmojiClick={addEmoji}
                                                            skinTone={SKIN_TONE_MEDIUM_LIGHT}
                                                            disableSkinTonePicker={false}
                                                            native
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </FormGroup>
                                </>
                            ) : null}
                        </Scrollbars>
                        {props?.contentType === ContentTypeEnum.POST ? <div className="hashtag-counter-box">
                            <span
                                className={classNames('contentHashtagCounter', {
                                    contentHashtagCounter_error: hashtagsCounter(values?.caption) > 30,
                                })}
                                >
                               {renderHashCounter(values?.caption)}
                            </span>
                        </div> : ''}
                        <Button color="success" type="button" disabled={isSubmitting || hashtagsCounter(values?.caption) > 30 || values?.caption?.length > 2200} onClick={submitForm}>
                            {props?.saveButtonText
                                ? props?.saveButtonText
                                : 'Save ' + (props?.contentType === ContentTypeEnum.POST ? 'post' : 'story set')}
                        </Button>
                        <div className="errorsHashtagOrTextLength">
                            {values?.caption?.length > 2200 &&
                                <p>You can't enter more than 2200 characters into the caption</p>
                            }
                            {hashtagsCounter(values?.caption) > 30 &&
                                <p>Up to 30 hashtags are allowed!</p>
                            }
                        </div>
                    </Form>
                )}
            </FormikWithRef>
        </>
    );
};

export default ContentForm;
