async function makeIgSearchRequest(
  context: "user" | "hashtag" | "place",
  query: string
): Promise<any> {
  return await (
    await fetch(
      `https://www.instagram.com/web/search/topsearch/?context=${context}&query=${encodeURIComponent(
        query
      )}&rank_token=${Math.random()}`
    )
  ).json();
}

interface IHashtagSearchResult {
  name: string;
  id: number;
  media_count: number;
  use_default_avatar: boolean;
  profile_pic_url: string;
  search_result_subtitle: string;
}

export async function hashTagSearch(
  query: string
): Promise<IHashtagSearchResult[]> {
  return (await makeIgSearchRequest("hashtag", query)).hashtags.map(
    (data: any) => data.hashtag
  );
}

interface IFacebookLocation {
  pk: string;
  name: string;
  address: string;
  city: string;
  short_name: string;
  lng: number;
  lat: number;
  external_source: string;
  facebook_places_id: number;
}

interface IPlaceSearchResult {
  location: IFacebookLocation;
  title: string;
  subtitle: string;
  media_bundles: any[];
  slug: string;
}

export async function locationSearch(
  query: string
): Promise<IPlaceSearchResult[]> {
  return (await makeIgSearchRequest("place", query)).places.map(
    (data: any) => data.place
  );
}

interface IUserSearchResult {
  pk: string;
  username: string;
  full_name: string;
  is_private: boolean;
  profile_pic_url: string;
  profile_pic_id: string;
  is_verified: boolean;
  has_anonymous_profile_picture: boolean;
  mutual_followers_count: number;
  latest_reel_media: number;
}

export async function userSearch(query: string): Promise<IUserSearchResult[]> {
  return (await makeIgSearchRequest("user", query)).users.map(
    (data: any) => data.user
  );
}
