import { AbstractFilterSet } from "./AbstractFilterSet";
import Fuse from "fuse.js";

interface IFlattenedModel<T, F> {
  ref: T;
  flattenedMap: F;
}

interface IClientSideFilterSeOptions<T, F> {
  getModels: () => T[];
  flattenFunction: (model: T) => F;
  onFilteredCallback: (models: T[]) => void;
  fuzzySearchKeys: string[];
}

export default class ClientSideFilterSet<
  T,
  F extends { [key: string]: string | number }
> extends AbstractFilterSet {
  private readonly getModels: () => T[];
  private readonly flattenFunction: (model: T) => F;
  private readonly onFilteredCallback: (models: T[]) => void;
  private readonly fuzzySearchKeys: string[];

  constructor(options: IClientSideFilterSeOptions<T, F>) {
    super();
    this.flattenFunction = options.flattenFunction;
    this.onFilteredCallback = options.onFilteredCallback;
    this.getModels = options.getModels;
    this.fuzzySearchKeys = options.fuzzySearchKeys;
  }

  protected handleChange(): void {
    // flatten models for search
    let flattenedModels: IFlattenedModel<T, F>[] = this.getModels().map(
      (model: T) => {
        return {
          ref: model,
          flattenedMap: this.flattenFunction(model),
        };
      }
    );

    if (flattenedModels.length === 0) {
      this.onFilteredCallback([]);
      return;
    }

    // apply each filter
    for (let key in this.filterValues) {
      if (this.filterValues.hasOwnProperty(key)) {
        // null equals "All" filter
        if (this.filterValues[key] !== null) {
          flattenedModels = flattenedModels.filter(
            (model) => model.flattenedMap[key] === this.filterValues[key]
          );
        }
      }
    }

    // apply fuzzy search
    if (this.searchTerm.length) {
      const options = {
        keys: this.fuzzySearchKeys.map((key) => "flattenedMap." + key),
      };
      const fuse = new Fuse(flattenedModels, options);
      flattenedModels = fuse.search(this.searchTerm);
    }

    // convert back to T using the stored model reference
    this.onFilteredCallback(flattenedModels.map((model) => model.ref));
  }
}
