import React, { Component } from "react";
import Slider, { Settings } from "react-slick";

interface Props {
  settings: Settings;
}

interface State {}

export class SlickSlider extends Component<Props, State> {
  private _slider: React.RefObject<any>;

  constructor(props: any) {
    super(props);

    this._slider = React.createRef();

    this.state = {};
  }

  render() {
    const { settings, children } = this.props;

    return (
      <div>
        <Slider
          afterChange={(e) => {}}
          {...settings}
          // @ts-ignore
          ref={(node) => (this._slider = node)}
        >
          {children}
        </Slider>
      </div>
    );
  }
}
