import React, { useState } from "react";
import { IAdModel } from "../../store/models/AdModel";
import {
  HistoryClockIcon,
  CalendarWithAClockTimeToolsIcon,
  LinkButtonIcon,
  // RateStarIcon,
  EventIcon,
} from "../../assets/icons";
import { truncate } from "lodash";
import moment from "moment";

interface Props {
  ad: IAdModel;
}

export function AdDetails(props: Props) {
  const { ad } = props;
  const [displayTimeTable, setDisplayTimeTable] = useState(false);

  return (
    <div className="adDetails">
      <ul>
        <li>
          <HistoryClockIcon />
          <span>Schedule: </span>
        </li>
        <li>
          <CalendarWithAClockTimeToolsIcon />
          <span>Duration:</span>
        </li>
        <li>
          <LinkButtonIcon />
          <span>BioLink:</span>
        </li>
        {/* <li>
          <RateStarIcon />
          <span>Exclusivity:</span>
        </li> */}
      </ul>
      <ul>
        {ad.time_tables && ad.time_tables.length > 0 ? (
          ad.time_tables.length === 1 &&
          ad.time_tables[0].start === ad.time_tables[0].end ? (
            <li key={ad.time_tables[0].start}>
              <div className="d-flex align-items-center">
                <EventIcon />
                <span
                  style={{ color: "rgb(26, 118, 252)", marginTop: "0.25em" }}
                >
                  {moment(ad.time_tables[0].start).format(
                    "MMMM - Do YYYY - HH:MM"
                  )}
                </span>
              </div>
            </li>
          ) : (
            <li
              onMouseEnter={() => setDisplayTimeTable(true)}
              onMouseLeave={() => setDisplayTimeTable(false)}
            >
              <span
                style={{
                  color: "#1a76fc",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Multiple dates/times
              </span>
              {displayTimeTable && (
                <div className="adDetails__timeTable">
                  {ad.time_tables.map((tt, index: number) => (
                    <React.Fragment key={`adDetailsTimeTable_${index}`}>
                      {tt.start === tt.end ? (
                        <div>
                          <EventIcon />
                          <span>
                            {moment(tt.start).format("MMMM - Do YYYY - HH:MM")}
                          </span>
                        </div>
                      ) : (
                        <div className="periodic">
                          <span>
                            {moment(tt.start).format("MMMM - Do YYYY - HH:MM")}
                          </span>
                          <span>
                            {moment(tt.end).format("MMMM - Do YYYY - HH:MM")}
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </li>
          )
        ) : (
          <li>
            <span>Open For Negotiation</span>
          </li>
        )}
        <li>
          <span>
            {ad.duration && ad.duration > 0
              ? moment.duration(ad.duration, "seconds").humanize()
              : "Open For Negotiation"}
          </span>
        </li>
        <li>
          <span>
            {ad && ad.bio_link && ad.bio_link.length > 0
              ? truncate(ad.bio_link, { length: 30 })
              : "No"}
          </span>
        </li>
        {/* <li>
          <span>
            {" "}
            {ad.exclusivity && ad.exclusivity > 0
              ? moment.duration(ad.exclusivity, "seconds").humanize()
              : "Open For Negotiation"}
          </span>
        </li> */}
      </ul>
    </div>
  );
}
