import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import Chart from "./components/chart";

interface Props extends RouteComponentProps {}

export default function Chats(props: Props) {
  return (
    <Switch>
      <Route path={`${props.match.url}/`} component={Chart} />
      <Redirect to="/error" />
    </Switch>
  );
}
