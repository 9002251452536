import React from "react";
import { IOfferModel } from "../../../../store/models/OfferModel";
import { RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps {
  offer: IOfferModel;
}
/**
 * 
 * @param props , { offer } 
 * use last message of offer for show to user 
 * linked to speciefic offer
 */
export default function SimilarProfileCard(props: Props) {
  const { offer } = props;

  return (
    <div
      className="ongoingOfferCard"
      onClick={() => props.history.push(`/app/offers/${offer.id}`)}
    >
      {/* <img src={offer.content_id.} alt="" /> */}
      <div className="ongoingOfferCard__details">
        <div className="d-flex justify-content-between align-items-center">
          {/* <p>{influencer.integration.full_name}</p> */}
          {/* <span>{offer.getHumanReadableStatus()}</span> */}
        </div>
        <span>{offer.last_message.message || "No Message"}</span>
        {/* <span>12 photos - 2 videos</span> */}
      </div>
    </div>
  );
}
