import React from "react";
import { Button } from "reactstrap";
import { truncate } from "lodash";
import { RouteComponentProps } from "react-router-dom";

import RootStore from "../../../../store/RootStore";
import { ICategoryModel } from "../../../../store/models/CategoryModel";
import OngoingOfferCard from "./OngoingOfferCard";
import { explorerSearchType } from "../../../../lib/CustomTypes";
import { RightArrowIcon, OfferEmptyStateIcon } from "../../../../assets/icons";
import { IOfferModel } from "../../../../store/models/OfferModel";

interface Props extends RouteComponentProps {
  offers: Array<IOfferModel>;
  fullName: string;
  categories: Array<ICategoryModel>;
  onInviteInfluencer: (event: React.MouseEvent) => void;
}
/**
 * 
 * @param props , {offers, fullname,catedories,onInviteInfluencer}
 * @returns 
 */
export default function OngoingOffers(props: Props) {
  const { offers, categories, fullName, onInviteInfluencer } = props;

  return (
    <div className="influencerProfile__sideBar_ongoingOffers">
      <h1>Ongoing Offers with {truncate(fullName, { length: 20 })}</h1>
      {offers.length > 0 ? (
        <>
          <div>
            {offers.slice(0, 3).map((offer) => (
              <OngoingOfferCard
                key={`ongoingOfferCard_${offer.id}`}
                offer={offer}
                {...props}
              />
            ))}
          </div>
          <Button
            outline
            color="primary"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              props.history.push({
                pathname: "/app/influencer-explorer/custom-search",
                state: {
                  name: explorerSearchType.CATEGORY,
                  value: [
                    ...categories.map((category) =>
                      category.title.toLowerCase() !== "other"
                        ? {
                            value: category.id,
                            label: category.title,
                          }
                        : {
                            value: category.id,
                            label: RootStore.categories.filter(
                              (cate: ICategoryModel) =>
                                cate.id === category.parent_id
                            )[0].title,
                          }
                    ),
                  ],
                },
              });
            }}
          >
            See All <RightArrowIcon />
          </Button>
        </>
      ) : (
        <div className="influencerProfile__sideBar_ongoingOffers_empty">
          <OfferEmptyStateIcon />
          <p>
            You don't have any offers with {truncate(fullName, { length: 12 })}
          </p>
          <Button
            outline
            color="primary"
            onClick={(event: React.MouseEvent) => {
              onInviteInfluencer(event);
              props.history.push("/app/influencer-explorer");
            }}
          >
            Invite {truncate(fullName, { length: 12 })} to your Ads
          </Button>
        </div>
      )}
    </div>
  );
}
