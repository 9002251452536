import {
  applySnapshot,
  destroy,
  flow,
  getIdentifier,
  getSnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import { IPaymentMethodModel, PaymentMethodModel } from "./PaymentMethodModel";
import { ITransactionModel, TransactionModel } from "./TransactionModel";
import Transport from "../../lib/Transport";
import BatchTransport from "../../lib/BatchTransport";
import { isStaging } from '../../constants/defaultValues';

export const WalletModel = types
  .model({
    id: types.identifierNumber,
    created_at: types.string,
    updated_at: types.string,
    owner_id: types.number,
    credits: types.number,
    total_income: types.number,
    income: types.number,
    methods: types.array(PaymentMethodModel),
    default_payment_method_id: types.maybeNull(
      types.reference(PaymentMethodModel)
    ),
    transactions: types.array(TransactionModel),
  })
  .volatile((self) => ({
    isBusy: false,
  }))
  .actions((self) => ({

    /**
    * this method check processing for this store
    */
    setBusy() {
      self.isBusy = true;
    },

    /**
    * this method just set value isBusy = false
    */
    setIdle() {
      self.isBusy = false;
    },
  }))
  .actions((self) => ({

    /**
    * this method load Transactions
    */
    loadTransactions: flow(function* (): Generator<
      any,
      ITransactionModel[],
      any
    > {
      try {
        self.setBusy();
        let response = yield Transport.get(`wallets/${self.id}/transactions`);
        self.transactions.replace(
          response.data["transactions"].map((tData: any) =>
            TransactionModel.create(tData)
          )
        );
        self.setIdle();
        return self.transactions;
      } catch (e) {
        self.setIdle();
        throw e;
      }
    }),

    /**
    * this method create Payment Method
    * @param data, this param get Payment for created
    */
    createPaymentMethod: flow(function* (
      data
    ): Generator<any, IPaymentMethodModel, any> {
      self.setBusy();
      try {
        let response = yield Transport.post(`wallets/${self.id}/methods`, {
          payment_method: {
            type: "card",
            default: true,
            data,
          },
        });
        self.setIdle();
        let model = PaymentMethodModel.create(response.data["payment_method"]);
        self.methods.push(model);
        applySnapshot(self, {
          ...getSnapshot(self),
          default_payment_method_id: model.id,
        });
        return model;
      } catch (e) {
        self.setIdle();
        throw e;
      }
    }),

    /**
    * this method remove Payment Method
    * @param model, this param get Payment for deleted
    */
    removePaymentMethod: flow(function* (
      model: IPaymentMethodModel
    ): Generator<any, void, any> {
      self.setBusy();
      try {
        yield Transport.delete(`methods/${model.id}`);
        self.setIdle();
        self.methods.replace(
          self.methods.filter((method) => method.id !== model.id)
        );
        destroy(model);
      } catch (e) {
        self.setIdle();
        throw e;
      }
    }),

    /**
    * this method set Default Payment Method
    * @param model, this param get Payment for deleted
    */
    setDefaultPaymentMethod: flow(function* (
      model: IPaymentMethodModel
    ): Generator<any, void, any> {
      self.setBusy();
      try {
        yield Transport.patch(`wallets/${self.id}`, {
          wallet: {
            default_payment_method_id: model.id,
          },
        });
        self.setIdle();
        self.default_payment_method_id = model;
      } catch (e) {
        self.setIdle();
        throw e;
      }
    }),

    /**
    * this method fetch Required Funds
    */
    fetchRequiredFunds: flow(function* (): Generator<any, number, any> {
      let response = yield Transport.get(`wallets/${self.id}/funds`);
      return response.data["required"];
    }),

    /**
    * this method charge Account
    * @param amount, this param amount for charge
    * @param method, this param get payment data
    */
    chargeAccount: flow(function* (
      amount: number,
      method: any = null
    ): Generator<any, void, any> {
      self.setBusy();
      try {
        let response = null;
        if (typeof method === "object") {
          let batch = new BatchTransport();
          batch.post(`wallets/${self.id}/methods`, {
            payment_method: {
              type: "card",
              default: true,
              data: { ...method },
            },
          });
          batch.post(`wallets/${self.id}/charges`, {
            transaction: {
              method_id: "{{$ref:0.payment_method.id}}",
              amount: amount,
            },
          });
          response = yield batch.all();

          self.setIdle();
          let model = PaymentMethodModel.create(
            response.data[0]["payment_method"]
          );
          self.methods.push(model);
          self.default_payment_method_id = model;
          self.credits += amount;
        } else {
          response = yield Transport.post(`wallets/${self.id}/charges`, {
            transaction: {
              method_id: method,
              amount: amount,
            },
          });
          self.credits += amount;
        }
        if (!isStaging()) {
          //@ts-ignore
          ga('send', {
            hitType: 'event',
            eventCategory: 'Brand',
            eventAction: 'Payment Made',
            eventLabel: '',
          });
        }
      } catch (e) {
        self.setIdle();
        throw e;
      }
    }),

    /**
    * this method add Payment Method
    * @param method, this param payment method
    */
    addPaymentMethod(method: IPaymentMethodModel) {
      self.methods.push(method);
      self.default_payment_method_id = method;
    },

    /**
    * this method subtract Credit
    * @param amount, this param get amount
    */
    subtractCredit(amount: number) {
      self.credits -= amount;
    },
  }))
  .views((self) => ({

    /**
    * this method get Default Card
    */
    getDefaultCard(): IPaymentMethodModel {
      return self.methods.filter(
        (method) =>
          method.id === parseInt(getIdentifier(self.default_payment_method_id))
      )[0];
    },

    /**
    * this method get Payment Method By Id
    * @param id, this param get payment ID
    */
    getPaymentMethodById(id: number): IPaymentMethodModel {
      return self.methods.filter((method) => method.id === id)[0];
    },
  }));

export interface IWalletModel extends Instance<typeof WalletModel> { }

export interface IWalletModelSnapshotIn
  extends SnapshotIn<typeof WalletModel> { }

export interface IWalletModelSnapshotOut
  extends SnapshotOut<typeof WalletModel> { }
