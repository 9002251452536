import React from "react";
import { Modal, Button, FormGroup, Label } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { CloseIcon } from "../../../../assets/icons";

const CampaignSchema = Yup.object().shape({
  reason: Yup.string()
    .min(3)
    .required("please provide us a reason, It's required."),
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  publishButtonAction: (reason: string) => void;
}

export default function DisapproveReasonModal(props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.onClose}
      className="campaignForm"
    >
      <CloseIcon onClick={props.onClose} />
      <div className="campaignForm__form">
        <Formik
          initialValues={{ reason: "" }}
          validationSchema={CampaignSchema}
          onSubmit={(values: { reason: string }) => {
            props.publishButtonAction(values.reason);
            props.onClose();
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
            setErrors,
            setFieldError,
          }) => (
            <Form
              translate={"no"}
              className="av-tooltip tooltip-label-right panel-form"
            >
              <FormGroup className="error-l-100 w-100">
                <Label className="position-relative">
                  Provide a reson for Disapproving this Job:
                  {errors.reason && touched.reason && (
                    <div className="invalidFeedback">{errors.reason}</div>
                  )}
                </Label>
                <Field
                  className="form-control w-100"
                  name="reason"
                  placeholder="Please Enter a Reason"
                  onChange={(e: any) => {
                    setFieldValue("reason", e.target.value);
                  }}
                />
              </FormGroup>
              <Button color="primary" type="submit">
                submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
