import { RouteComponentProps } from 'react-router-dom';
import { CountriesList } from '../../../constants/countriesList';

export interface ICreateAddComponent extends RouteComponentProps {
    layout: 'onboard' | 'app';
}

export const getCountry = (country: any) => {
    const customCountry = [];
    if (country && Array.isArray(country)) {
        country.map((ca: any) => {
            CountriesList.map((el: any) => {
                if (el.id === ca) {
                    customCountry.push({
                        label: el.name,
                        value: el.id,
                    });
                }
            });
        });
    }
    return customCountry;
};

export const findHashtags = (searchText: string) => {
    var regexp = /\B#\w\w+\b/g;
    const result = searchText.match(regexp);
    if (result) {
        return result;
    } else {
        return [];
    }
};
