/* eslint-disable */
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
// @ts-ignore
import { IMediaModel, MediaAction, MediaModel } from '../../../../../store/models/MediaModel';
import {
    ajaxErrorAlert,
    getNowUTC,
    handleError,
    loadVideo,
    makeTempModelId,
    showLoading,
} from '../../../../../lib/Utils';
import { observer } from 'mobx-react';
import SortableMedia from './SortableMedia';
import { IContentModel } from '../../../../../store/models/ContentModel';
import $ from 'jquery';
import * as Doka from '../../../../../3rd-party/doka/doka.esm.min';
import { DokaOutput } from '../../../../../3rd-party/doka/doka.esm.min';
import '../../../../../3rd-party/doka/doka.scss';
import Transport from '../../../../../lib/Transport';
import { IFileModel } from '../../../../../store/models/FileModel';
import Swal from 'sweetalert2';
import ApiQueryBuilder from '../../../../../lib/ApiQueryBuilder';
import StoryEditor from '@ainfluencer/a-influencer-editor';
import { applySnapshot, getSnapshot } from 'mobx-state-tree';
import { ContentTypeEnum } from '../ContentFormDialogue';
import { CameraIcon, ImageIcon } from '../../../../../assets/icons';
import ReactDOM from 'react-dom';
import { hashTagSearch, locationSearch, userSearch } from '../../../../../lib/InstagramApi';

StoryEditor.basePath = '/assets/story-editor/';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    value: IMediaModel[];
    contentType: ContentTypeEnum;
    onChange(value: any): void;
    contentClone: IContentModel;
}

interface State {
    value: IMediaModel[];
    modalRight: boolean;
    currentMedia: IMediaModel | null;
}

@observer
export default class MediaList extends Component<Props, State> {
    private editor: StoryEditor | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            value: this.props.value,
            modalRight: false,
            currentMedia: null,
        };
    }

    private askForFile(accept: string): Promise<File> {
        return new Promise((resolve, reject) => {
            let input = $(document.createElement('input'));
            input.attr('accept', accept);
            input.attr('type', 'file');
            input.on('change', (e: Event) => {
                // @ts-ignore
                resolve(e.target.files[0]);
            });
            input.trigger('click');
        });
    }

    private showDoka(file: File): Promise<DokaOutput> {
        const doka = Doka.create({
            cropAspectRatioOptions: [
                {
                    label: 'Square',
                    value: '1:1',
                },
                {
                    label: 'Landscape',
                    value: '1.9:1',
                },
                {
                    label: 'Portrait',
                    value: '4:5',
                },
            ],
        });
        // @ts-ignore
        return doka.edit(file);
    }

    private convertFileToImage(file: File): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = (event) => {
                let imgObj = new Image();

                // @ts-ignore
                imgObj.src = event.target.result;
                imgObj.onload = () => {
                    resolve(imgObj);
                };
                imgObj.onerror = (error) => {
                    reject(error);
                };
            };
            // @ts-ignore
            reader.readAsDataURL(file);
            reader.onerror = (error) => {
                reject(error);
            };
        });
    }

    addNewMedia(
        file: IFileModel,
        previewFile: IFileModel | null = null,
        originalFile: IFileModel | null = null,
        data: any = {},
    ): IMediaModel {
        let media = MediaModel.create({
            id: makeTempModelId(),
            file: file,
            original_file: originalFile,
            preview_file: previewFile,
            data: data,
            updated_at: getNowUTC(),
            created_at: getNowUTC(),
            content_id: this.props.contentClone.id,
            sequence_number: this.props.contentClone.getMedias().length,
        });

        this.props.contentClone.addMedia(media);
        this.props.onChange(this.props.contentClone.getMedias());
        this.props.contentClone.addModification(MediaAction.CREATE, media);

        return media;
    }

    addNewStory() {
        this.setState((prevState) => ({
            currentMedia: null,
            modalRight: !prevState.modalRight,
        }));
    }

    async uploadingDialogue(operation: Promise<any>): Promise<boolean | any> {
        showLoading('Uploading...', 'Please wait while we upload the file...');

        try {
            let result = await operation;
            Swal.close();
            return result;
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('Could not upload file! There was a problem in the server.');
            return false;
        }
    }

    async addNewFile() {
        if (this.props.contentClone.medias.length <= 10) {
            let file: File = null;
            try {
                file = await this.askForFile('image/gif, image/jpeg, image/jpg, image/png, video/mp4');
            } catch (e) {
                ajaxErrorAlert('There was a problem with your selected file!');
                return;
            }
            const list = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'video/mp4'];
            if (list.indexOf(file.type) === -1) {
                Swal.fire({
                    type: 'error',
                    title: 'Invalid File!',
                    text: 'Please select an image / video file!',
                });
                return;
            }

            if (file.type === 'video/mp4') {
                let video = await loadVideo(file);

                let ratio = video.videoWidth / video.videoHeight;
                if (!(ratio >= 4 / 5 && ratio <= 16 / 9)) {
                    Swal.fire({
                        type: 'error',
                        title: 'Invalid Video Aspect Ratio!',
                        text: 'your post should be for: Square Posts (1:1) or Horizontal Posts (16:9) or Vertical Posts (4:5)!',
                    });
                    return;
                }

                if (video.duration >= 60) {
                    Swal.fire({
                        type: 'error',
                        title: 'Invalid Video Duration!',
                        text: 'The videos in Instagram Feed have a 60 seconds time limit! You can upload up to 10 videos of 60 seconds each.',
                    });
                    return;
                }

                if (file.size / 1024 / 1024 > 100) {
                    Swal.fire({
                        type: 'error',
                        title: 'File is too large!',
                        text: 'Instagram only accepts video files upto 100mb!',
                    });
                    return;
                }

                let qb = new ApiQueryBuilder();
                qb.addQueryParam('type', 'post');
                let result = await this.uploadingDialogue(
                    Transport.upload<any>(
                        'files',
                        [
                            {
                                file,
                                name: 'file',
                            },
                        ],
                        qb,
                    ),
                );
                if (result !== false) {
                    this.addNewMedia(result.data.file);
                }
            } else if (list.slice(0, -1).indexOf(file.type) != -1) {
                if (file.size / 1024 / 1024 > 8) {
                    Swal.fire({
                        type: 'error',
                        title: 'File is too large!',
                        text: 'Instagram only accepts image files upto 8mb!',
                    });
                    return;
                }

                let dokaOutput: DokaOutput | null = null;

                try {
                    dokaOutput = await this.showDoka(file);
                } catch (e) {
                    ajaxErrorAlert('There was a problem when resizing the image!');
                    return;
                }

                if (dokaOutput !== null) {
                    let qb = new ApiQueryBuilder();
                    qb.addQueryParam('type', 'post');
                    let result = await this.uploadingDialogue(
                        Transport.upload<any>(
                            'files',
                            [
                                {
                                    file: dokaOutput.file,
                                    name: 'file',
                                },
                            ],
                            qb,
                        ),
                    );
                    if (result !== false) {
                        this.addNewMedia(result.data.file);
                    }
                }
            }
        } else {
            Swal.fire({
                type: 'error',
                title: 'Maximum content reached',
                text: 'You are allowed to use a maximum of ten content per post.',
            });
        }
    }

    toggleRight = () => {
        this.setState((prevState) => ({
            modalRight: !prevState.modalRight,
        }));
    };

    editStory(val: IMediaModel) {
        this.setState((prevState) => ({
            currentMedia: val,
            modalRight: !prevState.modalRight,
        }));
    }

    async saveStory() {
        if (!this.editor.hasMedia()) {
            Swal.fire({
                type: 'warning',
                title: 'Nothing to save...',
                html: 'Select an image or video to create a story!',
            });
            return;
        }

        this.toggleRight();

        let thumbnail: File;
        try {
            thumbnail = await this.editor.getThumbnailFile();
        } catch (e) {
            ajaxErrorAlert('There was a problem creating story thumbnail!');
            handleError(e);
            return;
        }

        let promises = [];

        let qb = new ApiQueryBuilder();
        qb.addQueryParam('type', 'story');

        if (this.editor.getOriginalFile().type.startsWith('video/')) {
            promises.push(
                async () =>
                    await Transport.upload<any>(
                        'files',
                        [
                            {
                                name: 'file',
                                file: this.editor.getOriginalFile(),
                            },
                            {
                                name: 'watermark',
                                file: await this.editor.getTransparentOverlay(),
                            },
                        ],
                        new ApiQueryBuilder(),
                    ),
            );
        } else {
            promises.push(() =>
                Transport.upload<any>(
                    'files',
                    [
                        {
                            file: thumbnail,
                            name: 'file',
                        },
                    ],
                    qb,
                ),
            );

            if (this.editor.needsUpload()) {
                promises.push(
                    async () =>
                        await Transport.upload<any>(
                            'files',
                            [
                                {
                                    name: 'file',
                                    file: this.editor.getOriginalFile(),
                                },
                            ],
                            new ApiQueryBuilder(),
                        ),
                );
            }
        }

        let result = await this.uploadingDialogue(Promise.all(promises.map((t) => t())));

        if (result !== false) {
            if (this.state.currentMedia === null) {
                // create mode
                if (this.editor.getOriginalFile().type.startsWith('video/')) {
                    this.addNewMedia(result[0].data.watermark, null, result[0].data.file, this.editor.getData());
                } else {
                    this.addNewMedia(
                        result[0].data.file,
                        result[0].data.file,
                        result[1].data.file,
                        this.editor.getData(),
                    );
                }
            } else {
                // edit mode
                let snapshot = getSnapshot(this.state.currentMedia);

                if (this.editor.getOriginalFile().type.startsWith('video/')) {
                    snapshot.original_file = result[0].data.file;
                    snapshot.file = result[0].data.watermark;
                    snapshot.preview_file = null;
                } else {
                    if (this.editor.needsUpload()) {
                        snapshot.original_file = result[1].data.file;
                    }
                    snapshot.file = result[0].data.file;
                    snapshot.preview_file = result[0].data.file;
                }

                snapshot.data = this.editor.getData();
                applySnapshot(this.state.currentMedia, snapshot);

                this.props.contentClone.addModification(MediaAction.EDIT, this.state.currentMedia);
            }

            if (this.editor.getOriginalFile().type.startsWith('video/')) {
                this.editor.notifyUploaded();
            } else {
                if (this.editor.needsUpload()) {
                    this.editor.notifyUploaded();
                }
            }
        }
    }

    async onOpened() {
        this.editor = new StoryEditor({
            onSliderInput: async (callback: (question: string, emoji: string) => void) => {
                const { value: question } = await Swal.fire({
                    title: 'Enter your question:',
                    input: 'text',
                    inputPlaceholder: 'Am I great?',
                });
                callback(question, '❤');
            },
            onCountDownInput: (callback: (name: string, moment: Date) => void) => {
                callback('Sample Counter', new Date());
            },
            onHashtagInput: async (callback: (hashtag: string) => void) => {
                const promiseOptions = async (inputValue: string) =>
                    (await hashTagSearch(inputValue)).map((igResult) => {
                        return {
                            label: '#' + igResult.name + ` (${igResult.search_result_subtitle})`,
                            value: igResult.name,
                        };
                    });

                let value: any = null;

                await Swal.fire({
                    title: 'Hashtag Search:',
                    html: '<div id="user_hashtag_search"></div>',
                    onOpen(modalElement: HTMLElement) {
                        ReactDOM.render(
                            <AsyncCreatableSelect
                                formatCreateLabel={(userInput) => `Use hashtag #${userInput.replace('#', '')}`}
                                onChange={(val, items) => (value = val)}
                                isClearable={true}
                                cacheOptions
                                placeholder={'Enter hashtag...'}
                                loadOptions={promiseOptions}
                            />,

                            $(`#user_hashtag_search`).get(0),
                        );
                    },
                });

                if (value) {
                    callback(value.value);
                }
            },
            onLocationInput: async (callback: (locationId: string, caption: string) => void) => {
                const promiseOptions = async (inputValue: string) =>
                    (await locationSearch(inputValue)).map((igResult) => {
                        return {
                            label: igResult.title,
                            value: {
                                id: igResult.location.pk,
                                title: igResult.title,
                            },
                        };
                    });

                let value: any = null;

                await Swal.fire({
                    title: 'Location Search:',
                    html: '<div id="user_location_search"></div>',
                    onOpen(modalElement: HTMLElement) {
                        ReactDOM.render(
                            <AsyncSelect
                                onChange={(val, items) => (value = val)}
                                isClearable={true}
                                cacheOptions
                                placeholder={'Enter location name...'}
                                loadOptions={promiseOptions}
                            />,

                            $(`#user_location_search`).get(0),
                        );
                    },
                });

                if (value) {
                    callback(value.value.id, value.value.title);
                }
            },
            onMentionInput: async (callback: (userId: string, username: string) => void) => {
                const promiseOptions = async (inputValue: string) =>
                    (await userSearch(inputValue)).map((igResult) => {
                        return {
                            label: (
                                <div>
                                    <img
                                        alt={igResult.full_name}
                                        src={igResult.profile_pic_url}
                                        height={25}
                                        width={25}
                                        style={{ display: 'inline-block' }}
                                    />
                                    &nbsp; {igResult.full_name} - @{igResult.username}
                                </div>
                            ),
                            value: {
                                id: igResult.pk,
                                username: igResult.username,
                            },
                        };
                    });

                let value: any = null;

                await Swal.fire({
                    title: 'Enter Instagram User:',
                    html: '<div id="user_mention_search"></div>',
                    onOpen(modalElement: HTMLElement) {
                        ReactDOM.render(
                            <AsyncSelect
                                onChange={(val, items) => (value = val)}
                                isClearable={true}
                                cacheOptions
                                placeholder={'Enter IG Name or Username...'}
                                loadOptions={promiseOptions}
                            />,

                            $(`#user_mention_search`).get(0),
                        );
                    },
                });

                if (value) {
                    callback(value.value.id, value.value.username);
                }
            },
            onPollInput: async (callback: (question: string, positiveTally: string, negativeTally: string) => void) => {
                const { value: formValues } = await Swal.fire({
                    title: 'Multiple inputs',
                    html:
                        '<input id="q" class="swal2-input" placeholder="Question" value="What is your question?">' +
                        '<input id="p" class="swal2-input" placeholder="Positive Tally" value="YES">' +
                        '<input id="n" class="swal2-input" placeholder="Negative Tally" value="NO">',
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            // @ts-ignore
                            q: document.getElementById('q').value,
                            // @ts-ignore
                            p: document.getElementById('p').value,
                            // @ts-ignore
                            n: document.getElementById('n').value,
                        };
                    },
                });

                if (formValues) {
                    callback(formValues['q'], formValues['p'], formValues['n']);
                }
            },
            onQuestionsInput: async (callback: (question: string) => void) => {
                const { value: question } = await Swal.fire({
                    title: 'Enter your question:',
                    input: 'text',
                    inputPlaceholder: 'Am I great?',
                });
                callback(question);
            },
        });
        StoryEditor.doka = Doka.create();
        await this.editor.init();

        if (this.state.currentMedia !== null) {
            if (this.state.currentMedia.original_file !== null) {
                try {
                    await this.editor.setImage(this.state.currentMedia.original_file.url);

                    this.editor.setData(this.state.currentMedia.data);
                } catch (e) {
                    handleError(e);
                    alert('failed to set image...');
                }
            } else {
                alert('original_file is null! contact support!');
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.modalRight}
                    toggle={this.toggleRight}
                    wrapClassName="modal-right"
                    onOpened={this.onOpened.bind(this)}
                    zIndex={1000000}
                >
                    <ModalHeader toggle={this.toggleRight}>Story Editor</ModalHeader>
                    <ModalBody style={{ padding: 0 }}>
                        <div className="wrapper">
                            <div className="a-influencer-editor"></div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.saveStory.bind(this)}>
                            Save
                        </Button>{' '}
                        <Button color="secondary" onClick={this.toggleRight}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <div className="media-list-container">
                    {this.props.contentType === ContentTypeEnum.POST && (
                        <div className="media-list-container-guide">
                            <div className="d-flex align-items-center mr-5">
                                <ImageIcon />
                                <div>
                                    <p className="mb-0">Image</p>
                                    <p className="mb-0">
                                        JPG, JPEG, PNG, GIF / Max size: 8 MB / Aspect Ratio: <b>free (auto crop)</b>
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mr-5">
                                <CameraIcon />
                                <div>
                                    <p className="mb-0">Video</p>
                                    <p className="mb-0">
                                        MP4 / Max size: 100 MB / Aspect Ratio from <b>4:5</b> to <b>16:9</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.props.contentType === ContentTypeEnum.POST ? (
                        <ButtonGroup className="media-list-container-actions">
                            <Button
                                color="primary"
                                type="button"
                                size="xs"
                                disabled={this.props.contentClone.getMedias().length === 10}
                                className="media-list-container-action"
                                onClick={() => {
                                    this.addNewFile();
                                }}
                            >
                                <span style={{ verticalAlign: 'bottom' }} className="glyph-icon simple-icon-plus" />
                                {'  Add new image / video'}
                            </Button>
                        </ButtonGroup>
                    ) : (
                        <>
                        {console.log(this.props)}
                            <Button
                                color="primary"
                                type="button"
                                size="xs"
                                className="media-list-container-action"
                                onClick={() => {
                                    this.addNewStory();
                                }}
                            >
                                <span style={{ verticalAlign: 'bottom' }} className="glyph-icon simple-icon-plus" />
                                {'  Add new story'}
                            </Button>
                        </>
                    )}{' '}
                    <br />
                    <br />
                    <SortableMedia
                        actionCallback={this.props?.contentClone && this.props.contentClone.addModification}
                        onEditRequest={(val: IMediaModel) => {
                            this.editStory(val);
                        }}
                        onChange={(order: any[]) => {
                            this.props.onChange(order);
                        }}
                        medias={this.props?.contentClone && this.props.contentClone.getMedias()}
                    />
                </div>
            </React.Fragment>
        );
    }
}
