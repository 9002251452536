import ApiQueryBuilder from "./ApiQueryBuilder";
import { AxiosResponse } from "axios";
import Transport from "./Transport";

interface RequestAttributes {
  uri: string;
  method: "POST" | "GET" | "PATCH" | "DELETE";
  query: any | null;
  body: any | null;
}

export default class BatchTransport {
  private requests: RequestAttributes[] = [];

  public post<T>(
    path: string,
    params: any,
    query: ApiQueryBuilder | null = null
  ) {
    this.requests.push({
      method: "POST",
      uri: path,
      body: params,
      query: query === null ? null : query.getQueryData(),
    });
  }

  public get<T>(path: string, query: ApiQueryBuilder | null = null) {
    this.requests.push({
      method: "GET",
      uri: path,
      body: null,
      query: query === null ? null : query.getQueryData(),
    });
  }

  public patch<T>(
    path: string,
    params: any,
    query: ApiQueryBuilder | null = null
  ) {
    this.requests.push({
      method: "PATCH",
      uri: path,
      body: params,
      query: query === null ? null : query.getQueryData(),
    });
  }

  public delete<T>(path: string) {
    this.requests.push({
      method: "DELETE",
      uri: path,
      body: null,
      query: null,
    });
  }

  public all(): Promise<AxiosResponse<any[]>> {
    return Transport.post<any[]>("batch", this.requests);
  }
}
