import React from "react";
import { Modal, Button, FormGroup, Label } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { CloseIcon } from "../../assets/icons";

const HashtagsSearchFormSchema = Yup.object().shape({
  hashtag: Yup.string().min(3).required("--"),
});

interface IHashtagsSearchFormProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateCampaign?: (values: { title: string }) => void;
  onEditCampaign?: (values: { title: string }) => void;
}

export function HashtagsSearchForm(props: IHashtagsSearchFormProps) {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.onClose}
      className="hashtagsSearchForm"
    >
      <CloseIcon onClick={props.onClose} />
      <div className="campaignForm__form">
        <Formik
          initialValues={{ hashtag: "" }}
          validationSchema={HashtagsSearchFormSchema}
          onSubmit={(values, actions) => {}}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
            setErrors,
            setFieldError,
          }) => (
            <Form
              translate={"no"}
              className="av-tooltip tooltip-label-right panel-form"
            >
              <FormGroup className="error-l-100 w-100">
                <Label className="position-relative">
                  Provide a title for your campaign:
                  {errors.hashtag && touched.hashtag && (
                    <div className="invalidFeedback">{errors.hashtag}</div>
                  )}
                </Label>
                <Field
                  className="form-control w-100"
                  name="title"
                  placeholder="Example: Halloween Makeup"
                  onChange={(e: any) => {
                    setFieldValue("title", e.target.value);
                  }}
                />
              </FormGroup>
              <Button color="primary" type="submit">
                Create Campaign
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
