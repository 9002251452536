import React, { useContext } from 'react'
import { Grid } from "@mui/material";
import CreatAdHocTopMenu from './parts/topMenu';
import createAdContext from '../context';
import OnboardCard from '../../dashboard/multi-step-form/onboarding/hoc/parts/card';
import OnboardSidebar from '../../dashboard/multi-step-form/onboarding/hoc/parts/sidebar'
import classes from './style.module.scss'
import OnboardHead from '../../dashboard/multi-step-form/onboarding/hoc/parts/card/parts/head';

interface IProps {
    head?: {
        url?: any | 'backward'
        title?: string
        paragraph?: string
    }
    children: any
}
const CreatAdLayout = (getProps: IProps) => {
    const { children } = getProps
    const { mode, loadData } = useContext(createAdContext)
    const style = { padding: '30px', backgroundColor: '#FFF', borderRadius: '10px', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.04)' }

    return (
        <>
            {mode === 'onboard' ?
                <Grid container>
                    <Grid item className={classes.wrraper}>
                        {getProps?.head &&
                            <OnboardHead
                                url={getProps.head.url}
                                title={getProps.head.title}
                                paragraph={getProps.head.paragraph}
                            />
                        }
                        <OnboardCard>
                            {children}
                            <OnboardSidebar />
                        </OnboardCard>
                    </Grid>
                    <OnboardSidebar />
                </Grid>
                :
                <Grid container flexDirection="column">
                    <CreatAdHocTopMenu />
                    {loadData ?
                        <Grid item style={style}>
                            {getProps?.head?.url &&
                                <OnboardHead
                                    url={getProps?.head?.url}
                                />
                            }
                            {children}
                        </Grid>
                        :
                        <div className={classes.loading}>
                            <p>Please wait ...</p>
                            <div className={classes.loader}></div>
                        </div>
                    }
                </Grid>
            }
        </>
    )
}
export default CreatAdLayout
