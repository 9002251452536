import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner
} from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import RootStore from "../../../../store/RootStore";
import Swal from "sweetalert2";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
import { observer } from "mobx-react";
import axios from 'axios'
import { servicePath, V2_USERS_CREDITS } from './../../../../constants/defaultValues'

const PersonalInfoSchema = Yup.object().shape({
  amount: Yup.number(),
  password: Yup.string().required("Password can't be empty"),
});

interface Props {
  isOpen: boolean;
  name: string;
  email: string;
  toggle: any;
}
interface State {
  loading?: boolean
}

@observer
export default class CreditUserForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }
  onSubmitEditUser = async (values: any) => {
    try {
      this.setState({ loading: true })
      const headers = {
        headers: {
          Authorization: "Bearer " + RootStore.users.getToken(),
        }
      }
      const url = `${servicePath}${V2_USERS_CREDITS}`
      const body = {
        amount: values?.amount,
        email: values?.email,
        payment_password: values?.password
      }
      axios.post(url, body, headers)
        .then(res => {
          this.setState({ loading: false })
          this.props.toggle()
          Swal.fire({
            type: 'success',
            title: 'User Created successfully!',
            showCancelButton: false,
            showConfirmButton: true,
          });
        })
        .catch(err => {
          this.setState({ loading: false })
          Swal.fire({
            type: "error",
            text: err.response.data.message,
            showCancelButton: false,
            showConfirmButton: true,
          });
        })
    } catch (e) {
      this.setState({ loading: false })
      handleError(e);
      ajaxErrorAlert("There was a problem in editing your profile.");
    }
  };

  getUserEditInitialValues(): any {
    const { name, email } = this.props;
    return {
      email,
      name
    };
  }

  render() {
    const { isOpen, toggle } = this.props;
    const { loading } = this.state
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Credit User</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={this.getUserEditInitialValues()}
            validationSchema={PersonalInfoSchema}
            onSubmit={this.onSubmitEditUser}
          >
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form
                translate={"no"}
                className="av-tooltip tooltip-label-right panel-form"
              >
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Amount:</b>
                    {errors.amount && touched.amount && (
                      <div className="invalidFeedback">{errors.amount}</div>
                    )}
                  </Label>
                  <Field className="form-control w-100" name="amount" />
                </FormGroup>
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Name:</b>
                  </Label>
                  <Field disabled className="form-control w-100" name="name" />
                </FormGroup>
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Email:</b>
                  </Label>
                  <Field disabled className="form-control w-100" name="email" />
                </FormGroup>
                <FormGroup className="error-l-100">
                  <Label className="position-relative">
                    <b>Password:</b>
                    {errors.password && touched.password && (
                      <div className="invalidFeedback">{errors.password}</div>
                    )}
                  </Label>
                  <Field className="form-control w-100" name="password" type="password" />
                </FormGroup>
                <hr />
                <Button
                  style={{ float: "right" }}
                  color="success ml-1"
                  type="submit"
                  disabled={loading}
                  load
                >
                  {loading ? <Spinner color="primary" size={"sm"} style={{ margin: 2 }} /> : 'Credit'}
                </Button>
                <Button
                  style={{ float: "right" }}
                  color="secondary"
                  onClick={toggle}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}
