import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
* this method Time Line Content Metric Model
*/
export const TimeLineContentMetricModel = types
  .model({
    id: types.identifierNumber,
    timeline_content_id: types.maybeNull(types.number),
    likes: types.maybeNull(types.number),
    created_at: types.maybeNull(types.string),
    updated_at: types.maybeNull(types.string),
    comments: types.maybeNull(types.number),
    caption: types.maybeNull(types.string),
  })
  .actions((self) => ({}))
  .views((self) => ({}))
  .views((self) => ({}));
export interface ITimeLineContentMetricModel
  extends Instance<typeof TimeLineContentMetricModel> {}

export interface ITimeLineContentMetricModelSnapshotIn
  extends SnapshotIn<typeof TimeLineContentMetricModel> {}

export interface ITimeLineContentMetricModelSnapshotOut
  extends SnapshotOut<typeof TimeLineContentMetricModel> {}
