import React, { useState } from "react";
import { IContentModel } from "../../../../../store/models/ContentModel";
import { ucfirst } from "../../../../../lib/Utils";
import classNames from "classnames";
import { observer } from "mobx-react";
import { ContentPreviewModal } from "../../../../common-components/ContentPreviewModal";

type Props = {
  content: IContentModel;
  postData?:any
};

function AdThumbnail(props: Props) {
  const [displayAdPreview, setDisplayAdPreview] = useState(false);
 
  return (
    <div className="ad-thumbnail">
      <ContentPreviewModal
        isOpen={displayAdPreview}
        content={props.content}
        onClose={() => setDisplayAdPreview(false)}
        postData={props?.postData}
      />
      <div className={classNames("preview", props.content.type)}>
        <img
          src={
            props.content.type === "post"
              ? props.content.getPreviewImage(130, 130)
              : props.content.getPreviewImage(130, 130)
          }
          alt={"Content"}
        />
        <div className="preview-btn" onClick={() => setDisplayAdPreview(true)}>
          <span className={"iconsminds-magnifi-glass"} />
          View {ucfirst(props.content.type)}
        </div>
      </div>
    </div>
  );
}

export default observer(AdThumbnail);
