import React, { Component } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import AI from "./components/AI";

interface Props extends RouteComponentProps {}

export default class AITab extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}`} component={AI} />
      </Switch>
    );
  }
}
