import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import classes from './style.module.scss';
import { Form, Formik } from 'formik';
import DatePublish from './parts/datePublished';
import PublishTermsFormContext from './context';
import DateRemain from './parts/dateRemain';
import PublishTermsFormBio from './parts/bio';
import NextButton from '../../../../../dashboard/multi-step-form/onboarding/parts/button';
import CreatAdDescription from '../../../description';
import createAdContext from '../../../../context';
import OnboardPreview from '../../../../../dashboard/multi-step-form/onboarding/parts/preview';
import CreatAdLayout from '../../../../layout';
import RootStore from '../../../../../../../store/RootStore';

function PublishTermsForm(props: any) {
    const { baseUrl, data: { adID, terms }, updateOnboard } = useContext(createAdContext)

    const updateTerms = (key: any, value: any) => {
        updateOnboard((prev: any) => ({ ...prev, terms: { ...prev.terms, [key]: value } }))
    }

    return (
        <CreatAdLayout
            head={{
                title: "Publishing terms",
                paragraph: "",
                url: "post-content"
            }}
        >
            <Formik
                initialValues={{
                    campaign_id: null,
                    title: '',
                    description: '',
                    content_id: null,
                    schedule: terms.schedule,
                    fixedSchedule: terms.fixedSchedule,
                    fixedDuration: terms.fixedDuration,
                    segment_time: terms.duration_segmentation.segment_time,
                    segment_name: terms.duration_segmentation.segment_name,
                    displayBioLink: terms.displayBioLink,
                    bio_link: terms.bio_link,
                }}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    // const { fixedSchedule, fixedDuration, schedule, segment_time, segment_name, displayBioLink, bio_link } = values

                }}
            >
                {({
                    setFieldValue,
                    values,
                    touched,
                    errors,
                    setFieldTouched
                }) => (
                    <PublishTermsFormContext.Provider value={{
                        values: values,
                        touched: touched,
                        errors: errors,
                        setFieldTouched: setFieldTouched,
                        setFieldValue: setFieldValue,
                        updateTerms: updateTerms
                    }}>
                        <Grid container>
                            <Grid item container sm={6}>
                                <CreatAdDescription
                                    title='Publishing Terms and Requirements'
                                    description={
                                        <p>
                                            Include details such as the desired date/time range for your posts, and duration for how long you’d like the post to stay live on an influencer’s page.
                                            <br />
                                            To track site visits or sales, you can specify if you require that a URL be placed in the influencer’s bio.
                                            <br />
                                            These settings can be modified once you start collaborating with influencers.
                                        </p>
                                    }
                                    note="Note: Ainfluencer tracks every post that influencers publish and will ensure that the terms of your agreement are met before releasing payment to them."
                                />
                                <Form style={{ width: '100%' }}>
                                    <Grid container className={classes.form} marginTop={2} rowSpacing={3}>
                                        <DatePublish />
                                        <DateRemain />
                                        <PublishTermsFormBio />
                                    </Grid>
                                    <NextButton onClick={() => RootStore.router.push(`${baseUrl}/confirm/${adID ? adID : ''}`)} />
                                </Form>
                            </Grid>
                            <Grid item container sm={5} display={{ xs: 'none', lg: 'flex', sm: 'flex', md: 'flex' }} justifyContent="center">
                                <Grid item sm={6}>
                                    <OnboardPreview />
                                </Grid>
                            </Grid>
                        </Grid>
                    </PublishTermsFormContext.Provider >
                )
                }
            </Formik >
        </CreatAdLayout>
    )
}

export default PublishTermsForm
