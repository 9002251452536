import React, { Fragment } from 'react';
import { Button, Table, Card, CardBody, CardTitle, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import Swal from 'sweetalert2';
import { observer } from 'mobx-react';
import moment from 'moment';
import { truncate } from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { IUserModel } from '../../../../store/models/UserModel';
import { Colxx } from '../../../../template/components/common/CustomBootstrap';
import { IIntegrationModel } from '../../../../store/models/IntegrationModel';
import RootStore from '../../../../store/RootStore';
import { ajaxErrorAlert, handleError } from '../../../../lib/Utils';
import EditUserForm from './EditUserForm';
import CreditUserForm from './CreditUserForm';
import EditIdentityForm from './EditIdentityForm';
import { IIdentityModel } from '../../../../store/models/IdentityModel';
import { NotificationManager } from '../../../../template/components/common/react-notifications';
import EmailForm from './EmailForm';
import ChangePasswordForm from './ChangePasswordForm';
import { ICategoryModel } from '../../../../store/models/CategoryModel';
import profileImg from '../../../../assets/img/profilePlaceholder.jpg';
import { ICountryModel } from '../../../../store/models/CountryModel';
import { servicePath, userListExcelDownload } from './../../../../constants/defaultValues';
import axios from 'axios';
import { CountriesList } from './../../../../constants/countriesList';

// import Integrations from "../../app/account/integrations/Integrations";

interface Pagination {
    totalCount: number;
    pageSize: number;
    page: number;
    totalPages: number;
}
interface Props extends RouteComponentProps {
    users?: IUserModel[];
    identities: IIdentityModel[];
    integrations: IIntegrationModel[];
    identityUsers?: IUserModel[];
    pagination?: Pagination;
    rangeDate?: string[];
}

interface State {
    openEditUser: boolean;
    openEditIdentity: boolean;
    modalBack: boolean;
    openEmailForm: boolean;
    openChangePasswordForm: boolean;
    followers?: number | null;
    openCreditUser?: boolean;
    creditUserName?: string;
    creditUserEmail?: string;
}
@observer
export default class InfluencersList extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            openEditUser: false,
            openEditIdentity: false,
            modalBack: false,
            openEmailForm: false,
            openChangePasswordForm: false,
            followers: null,
            openCreditUser: false,
            creditUserEmail: '',
            creditUserName: '',
        };

        this.toggleEditUser = this.toggleEditUser.bind(this);
        this.toggleEditidentity = this.toggleEditidentity.bind(this);
        this.activateUser = this.activateUser.bind(this);
        this.getUserAvatar = this.getUserAvatar.bind(this);
        this.toggleOpenEmail = this.toggleOpenEmail.bind(this);
        this.toggleOpenChangePassword = this.toggleOpenChangePassword.bind(this);
        this.downloadUserList = this.downloadUserList.bind(this);
    }
    async downloadbrand() {
        const { pathname } = window.location;
        const getPageType = window.location.pathname.substring(18, pathname.length - 1);

        try {
            let res = await Swal.fire({
                title: 'Download',
                text: `Are you sure want to download all ${getPageType === 'brand' ? 'Brands' : 'Influencers'}?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Download',
            });
            if (res.value) {
                this.downloadUserList();
            }
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem with download user list');
        }
    }

    downloadUserList = () => {
        let headers: any = {
            headers: {
                Authorization: 'Bearer ' + RootStore.users.getToken(),
            },
            responseType: 'arraybuffer',
        };

        const { pathname } = window.location;
        const getPageType = window.location.pathname.substring(18, pathname.length - 1);

        const formData = new FormData();
        formData.append('type', getPageType || 'brand');
        formData.append('date_from', this.props.rangeDate[0]);
        formData.append('date_to', this.props.rangeDate[1]);
        if (this.state.followers) {
            formData.append('followers', `${this.state.followers}`);
        }

        const url = `${servicePath}${userListExcelDownload}`;

        axios.post(url, formData, headers).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'users-list.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    };
    public userId: number;
    public identityId: number;
    async activateUser(userId: number) {
        try {
            if (RootStore.users.adminUsers.find((user: IUserModel) => user.id === userId).email_verified_at) {
                NotificationManager.info('', 'User has already been activated!', 3000, null, null, 'filled');
            } else {
                await RootStore.users.editUser(userId, {
                    email_verified_at: moment(moment.now()).format('YYYY-MM-DD HH:mm:ss'),
                });
                NotificationManager.success(
                    'Done',
                    'User profile was updated successfully!',
                    3000,
                    null,
                    null,
                    'filled',
                );
            }

            // this.props.updateData(userId);
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem with activating user you selected');
        }
    }
    async removeUser(userId: number) {
        try {
            let res = await Swal.fire({
                title: 'Warning',
                text: 'Are you sure you want to delete this user',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Delete this user',
            });
            if (res.value) {
                RootStore.users.deleteUser(userId);
            }
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem with deleting user you selected');
        }
    }
    toggleEditUser(): void {
        this.setState((prevState) => ({
            openEditUser: !prevState.openEditUser,
        }));
    }
    toggleOpenEmail(): void {
        this.setState((prevState) => ({
            openEmailForm: !prevState.openEmailForm,
        }));
    }
    toggleEditidentity(): void {
        this.setState((prevState) => ({
            openEditIdentity: !prevState.openEditIdentity,
        }));
    }
    toggleOpenChangePassword(): void {
        this.setState((prevState) => ({
            openChangePasswordForm: !prevState.openChangePasswordForm,
        }));
    }

    getUserAvatar(user: IUserModel): string | null {
        const { identities } = this.props;
        let url = profileImg;
        let identity = identities.find((identity: IIdentityModel) => identity.user_id === user.id);
        return !identity ? url : identity.logo ? identity.logo.url : url;
    }

    findCountry(user: IUserModel): string {
        let identity: IIdentityModel = RootStore.identityStore.identities.find(
            (identity: IIdentityModel) => identity.user_id === user.id,
        );

        if (identity) {
            let countryId = identity.country_id;
            if (countryId) {
                return truncate(CountriesList.find((country: ICountryModel) => country.id === countryId).name, {
                    length: 17,
                });
            }
            return 'N/A';
        }
        return 'N/A';
    }

    followersOnChange = (e) => {
        const { value } = e.target;
        this.setState({ followers: value });
    };

    userCredit = (name, email) => {
        this.setState({
            openCreditUser: true,
            creditUserName: name,
            creditUserEmail: email,
        });
    };

    toggleCreditUser = () => {
        this.setState((prev) => {
            return {
                openCreditUser: !prev.openCreditUser,
            };
        });
    };

    render() {
        const integrations = this.props?.integrations || [];
        const identities = this.props?.identities || [];
        const users = this.props?.users || [];
        const { pathname } = window.location;
        const getPageType = window.location.pathname.substring(18, pathname.length - 1);
        console.log('integrations ', integrations);

        return (
            <Fragment>
                <Card className="mb-4">
                    {this.props.users ? (
                        <CardBody>
                            <CardTitle>Users List</CardTitle>
                            <Row>
                                <Colxx>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Email Address</th>
                                                <th>Name</th>
                                                <th>Created</th>
                                                <th>Type</th>
                                                <th>Country</th>
                                                <th>Platform</th>
                                                <th>Invited via</th>
                                                <th>IGs</th>
                                                <th>Phone Number</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((user: any, index: number) => {
                                                return (
                                                    <tr key={`user__${user.id}`} className="cursor-pointer">
                                                        <td>
                                                            {this.props.pagination.totalCount -
                                                                this.props.pagination.page *
                                                                    this.props.pagination.pageSize -
                                                                index}
                                                        </td>
                                                        <td>
                                                            <div className={'d-flex align-items-center'}>
                                                                <LazyLoadImage
                                                                    effect={'blur'}
                                                                    alt={'image'}
                                                                    onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = profileImg;
                                                                    }}
                                                                    src={this.getUserAvatar(user)}
                                                                    className="rounded"
                                                                    width={30}
                                                                    height={30}
                                                                />
                                                                <div className="ml-1 text-muted cursor-pointer">
                                                                    {user.email}
                                                                    {'  '}
                                                                    {user.email_verified_at && (
                                                                        <i
                                                                            title="verified user"
                                                                            className="simple-icon-check text-success"
                                                                        ></i>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {truncate(user.first_name + ' ' + user.last_name, {
                                                                length: 21,
                                                            })}
                                                        </td>
                                                        <td>{moment(user.created_at).format('MM-DD-YYYY')}</td>
                                                        <td>
                                                            {user.is_brand
                                                                ? 'Brand'
                                                                : user.is_influencer
                                                                ? 'Influencer'
                                                                : user.registered_platform === 'web'
                                                                ? 'Brand'
                                                                : user.registered_platform === 'android'
                                                                ? 'Influencer'
                                                                : user.registered_platform === 'ios'
                                                                ? 'Influencer'
                                                                : 'N/A'}
                                                        </td>
                                                        <td>{this.findCountry(user)}</td>
                                                        <td>{user.registered_platform}</td>
                                                        <td>{user.invited_via ? user.invited_via : '-'}</td>
                                                        <td>
                                                            {RootStore.integrationStore.integrations.filter(
                                                                (integration: IIntegrationModel) =>
                                                                    integration.user_id.id === user.id,
                                                            ).length > 0 ? (
                                                                <>
                                                                    {RootStore.integrationStore.integrations
                                                                        .filter(
                                                                            (integration: IIntegrationModel) =>
                                                                                integration.user_id.id === user.id,
                                                                        )
                                                                        .map((integration: IIntegrationModel) => (
                                                                            <a
                                                                                href={`http://instagram.com/${integration.username}/`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                {' '}
                                                                                @{integration.username}
                                                                            </a>
                                                                        ))}
                                                                </>
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </td>
                                                        <td>{user?.phone || '...'}</td>
                                                        <td>
                                                            {user.deleted_at && user.deleted_at !== null ? (
                                                                <p className="text-muted cursor-pointer d-flex justify-content-center align-items-center">
                                                                    User Has Been Deactivated
                                                                    <Button
                                                                        className={'mr-1 ml-1'}
                                                                        color={'secondary'}
                                                                        size={'sm'}
                                                                    >
                                                                        <span className="simple-icon-refresh" />
                                                                    </Button>
                                                                </p>
                                                            ) : (
                                                                <div className="d-flex justify-content-end">
                                                                    <Button
                                                                        className={'mr-1'}
                                                                        color={'secondary'}
                                                                        size={'sm'}
                                                                        onClick={() => {
                                                                            this.setState((prevState) => {
                                                                                this.userId = user.id;
                                                                                return {
                                                                                    openEditUser: !prevState.openEditUser,
                                                                                };
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span className="simple-icon-user" />
                                                                    </Button>
                                                                    <Button
                                                                        color={'secondary'}
                                                                        className={'mr-1'}
                                                                        size={'sm'}
                                                                        onClick={() => {
                                                                            this.setState((prevState) => {
                                                                                this.userId = user.id;
                                                                                return {
                                                                                    openEmailForm: !prevState.openEmailForm,
                                                                                };
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span className="simple-icon-envelope" />
                                                                    </Button>
                                                                    <Button
                                                                        color={'secondary'}
                                                                        className={'mr-1'}
                                                                        size={'sm'}
                                                                        onClick={() => {
                                                                            this.setState((prevState) => {
                                                                                this.userId = user.id;
                                                                                return {
                                                                                    openChangePasswordForm: !prevState.openChangePasswordForm,
                                                                                };
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span className="simple-icon-key" />
                                                                    </Button>
                                                                    {!user.email_verified_at ? (
                                                                        <Button
                                                                            className={'mr-1'}
                                                                            color={'secondary'}
                                                                            size={'sm'}
                                                                            onClick={() => this.activateUser(user.id)}
                                                                        >
                                                                            <span className="simple-icon-check" />
                                                                        </Button>
                                                                    ) : null}
                                                                    <Button
                                                                        color={'danger'}
                                                                        size={'sm'}
                                                                        onClick={() => this.removeUser(user.id)}
                                                                    >
                                                                        <span className="simple-icon-trash" />
                                                                    </Button>
                                                                    <Button
                                                                        className={'ml-1'}
                                                                        color={'success'}
                                                                        size={'sm'}
                                                                        onClick={() =>
                                                                            this.userCredit(
                                                                                user.first_name + ' ' + user.last_name,
                                                                                user?.email,
                                                                            )
                                                                        }
                                                                        title="Credit"
                                                                    >
                                                                        <span className="simple-icon-credit-card" />
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                    {this.state.openEditUser && (
                                        <EditUserForm
                                            isOpen={this.state.openEditUser}
                                            toggle={this.toggleEditUser}
                                            user={users.filter((user: any) => user.id === this.userId)[0]}
                                        />
                                    )}
                                    {this.state.openCreditUser && (
                                        <CreditUserForm
                                            isOpen={this.state.openCreditUser}
                                            toggle={this.toggleCreditUser}
                                            name={this.state.creditUserName}
                                            email={this.state.creditUserEmail}
                                        />
                                    )}
                                    {this.state.openEmailForm && (
                                        <EmailForm
                                            isOpen={this.state.openEmailForm}
                                            toggle={this.toggleOpenEmail}
                                            user={users.filter((user: any) => user.id === this.userId)[0]}
                                        />
                                    )}
                                    {this.state.openChangePasswordForm && (
                                        <ChangePasswordForm
                                            isOpen={this.state.openChangePasswordForm}
                                            toggle={this.toggleOpenChangePassword}
                                            user={users.filter((user: any) => user.id === this.userId)[0]}
                                        />
                                    )}
                                </Colxx>
                            </Row>
                        </CardBody>
                    ) : (
                        <>
                            <CardBody>
                                <CardTitle
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {getPageType === 'brand' ? 'Brands' : 'Influencers'} List
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                marginRight: 32,
                                            }}
                                        >
                                            <span style={{ fontSize: 14, color: '#707070' }}>Followers : </span>
                                            <input
                                                style={{
                                                    width: 80,
                                                    border: '1px solid #bbb',
                                                    fontSize: 14,
                                                    padding: 5,
                                                    outline: 'none',
                                                    marginLeft: 4,
                                                    color: '#979797',
                                                }}
                                                onChange={this.followersOnChange}
                                            />
                                        </div>
                                        <Button
                                            style={{ float: 'right' }}
                                            color="success"
                                            onClick={() => this.downloadbrand()}
                                        >
                                            Download List
                                        </Button>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Colxx>
                                        <Table striped>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Instagram </th>
                                                    <th>Offers count</th>
                                                    <th>Categories</th>
                                                    <th>Country</th>
                                                    <th>Create Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {identities?.map((identity: any, index: number) => {
                                                    const first_name =
                                                        RootStore.users.adminUsers.find(
                                                            (user: IUserModel) => user.id === identity.user_id,
                                                        )?.first_name || '...';
                                                    const last_name =
                                                        RootStore.users.adminUsers.find(
                                                            (user: IUserModel) => user.id === identity.user_id,
                                                        )?.last_name || '...';
                                                    const full_name = `${first_name} ${last_name}`;
                                                    const email =
                                                        RootStore.users.adminUsers.find(
                                                            (user: IUserModel) => user.id === identity.user_id,
                                                        )?.email || '...';

                                                    return (
                                                        <tr key={`identity__${identity.id}`} className="cursor-pointer">
                                                            <td>
                                                                {this.props.pagination.totalCount -
                                                                    this.props.pagination.page *
                                                                        this.props.pagination.pageSize -
                                                                    index}
                                                            </td>
                                                            <td>
                                                                <div className={'d-flex align-items-center'}>
                                                                    <img
                                                                        alt={'img'}
                                                                        src={
                                                                            identity.logo
                                                                                ? identity.logo.url
                                                                                : profileImg
                                                                        }
                                                                        className="rounded"
                                                                        width={30}
                                                                        height={30}
                                                                    />
                                                                    <div className="ml-1 text-muted cursor-pointer">
                                                                        {RootStore.users.adminUsers.find(
                                                                            (user: IUserModel) =>
                                                                                user.id === identity.user_id,
                                                                        ) ? (
                                                                            RootStore.users.adminUsers.find(
                                                                                (user: IUserModel) =>
                                                                                    user.id === identity.user_id,
                                                                            ).deleted_at ? (
                                                                                <span style={{ color: 'red' }}>
                                                                                    {' '}
                                                                                    (DELETED)
                                                                                </span>
                                                                            ) : (
                                                                                <span>{full_name}</span>
                                                                            )
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {/* <td>{identity?.email || '...'}</td> */}
                                                            <td>{email}</td>
                                                            <td>
                                                                {identities[0]?.type === 'influencer' ? (
                                                                    <>
                                                                        {integrations &&
                                                                            integrations?.find((integration) => {
                                                                                return (
                                                                                    identity?.user_id ===
                                                                                    integration?.user_id?.id
                                                                                );
                                                                            })?.username}
                                                                    </>
                                                                ) : RootStore.users.adminUsers.find(
                                                                      (user: IUserModel) =>
                                                                          user.id === identity.user_id,
                                                                  ) ? (
                                                                    RootStore.users.adminUsers.find(
                                                                        (user: IUserModel) =>
                                                                            user.id === identity.user_id,
                                                                    ).email
                                                                ) : (
                                                                    'User Deleted'
                                                                )}
                                                            </td>
                                                            <td>{identity?.offers_count}</td>
                                                            <td>
                                                                {identity.categories ? (
                                                                    <>
                                                                        {truncate(
                                                                            identity.categories
                                                                                .map(
                                                                                    (category: ICategoryModel) =>
                                                                                        category.title,
                                                                                )
                                                                                .join(', '),
                                                                            { length: 30 },
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>N/A</>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {CountriesList.find((country: ICountryModel) => {
                                                                    return country.id === identity.country_id;
                                                                }) ? (
                                                                    <>
                                                                        {
                                                                            CountriesList.find(
                                                                                (country: ICountryModel) => {
                                                                                    return (
                                                                                        country.id ===
                                                                                        identity.country_id
                                                                                    );
                                                                                },
                                                                            ).code
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>N/A</>
                                                                )}
                                                            </td>
                                                            <td>{identity?.created_at}</td>
                                                            <td>
                                                                {identity?.deleted_at &&
                                                                identity?.deleted_at !== null ? (
                                                                    <p className="text-muted cursor-pointer d-flex justify-content-center align-items-center">
                                                                        User Has Been Deactivated
                                                                        <Button
                                                                            className={'mr-1 ml-1'}
                                                                            color={'secondary'}
                                                                            size={'sm'}
                                                                        >
                                                                            <span className="simple-icon-refresh" />
                                                                        </Button>
                                                                    </p>
                                                                ) : (
                                                                    <div className="d-flex justify-content-center">
                                                                        <Button
                                                                            className={'mr-1 ml-1'}
                                                                            color={'secondary'}
                                                                            size={'sm'}
                                                                            onClick={() => {
                                                                                this.setState((previousState) => {
                                                                                    this.identityId = identity.id;
                                                                                    return {
                                                                                        openEditIdentity: !previousState.openEditIdentity,
                                                                                    };
                                                                                });
                                                                            }}
                                                                        >
                                                                            <span className="simple-icon-user" />
                                                                        </Button>
                                                                        <Button
                                                                            color={'danger'}
                                                                            size={'sm'}
                                                                            onClick={() =>
                                                                                this.removeUser(identity.user_id)
                                                                            }
                                                                        >
                                                                            <span className="simple-icon-trash" />
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                        {this.state.openEditIdentity && (
                                            <EditIdentityForm
                                                isOpen={this.state.openEditIdentity}
                                                toggle={this.toggleEditidentity}
                                                identity={
                                                    identities?.filter(
                                                        (identity: any) => identity.id === this.identityId,
                                                    )[0]
                                                }
                                            />
                                        )}
                                    </Colxx>
                                </Row>
                            </CardBody>
                        </>
                    )}
                </Card>
            </Fragment>
        );
    }
}
