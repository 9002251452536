import React from 'react';
import AdCard from './AdCard';
import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { truncate } from 'lodash';
import classNames from 'classnames';
// @ts-ignore
import ReactTooltip from 'react-tooltip';

import RootStore from '../../../../store/RootStore';

import {
    DeleteIcon,
    EditIcon,
    EyeIcon,
    TickIcon,
    PauseIcon,
    PlayButtonArrowheadIcon,
    HourGlassIcon,
    CloseIcon,
    RedBackClockIcon,
    RightArrowIcon,
    CircleIcon,
    RejectIcon,
    DollarsSpentIcon,
    InformationIcon,
    CopyToClipboardIcon,
} from '../../../../assets/icons';

@observer
export default class AdCardMobileView extends AdCard {
    render() {
        const { ad } = this.props;
        return (
            <>
                <tr className="adCardAdTab">
                    <td className="adCardAdTab__content">
                        <img
                            alt="Post"
                            className={classNames({
                                adCardAdTab__story: ad.content_id.type === 'story',
                            })}
                            src={ad.content_id.getPreviewImage(190, 190)}
                        />

                        <div>
                            <h4 className="mb-0">{truncate(ad.title, { length: 22 })}</h4>
                            <p>
                                Campaign: <span>{ad.campaign_id.title}</span>
                            </p>

                            {ad.approval === 'approved' ? (
                                <div className="adCardAdTab__approved">
                                    <TickIcon />
                                    <p>Approved</p>
                                    {ad.invite_only && (
                                        <>
                                            <span data-tip data-for="invite-only" className="invite-only">
                                                <CircleIcon />
                                                Invite Only
                                            </span>
                                        </>
                                    )}
                                </div>
                            ) : ad.approval === 'pending' ? (
                                <div className="adCardAdTab__pending">
                                    <HourGlassIcon />
                                    <p>
                                        <span>Pending Approval</span>
                                        {ad.invite_only && (
                                            <>
                                                <span data-tip data-for="invite-only" className="invite-only">
                                                    <CircleIcon />
                                                    Invite Only
                                                </span>
                                            </>
                                        )}
                                    </p>
                                </div>
                            ) : ad.approval === 'rejected' ? (
                                <div className="adCardAdTab__rejected">
                                    <CloseIcon />
                                    <div className="msg_m">
                                        <span>Disapproved </span>
                                        <p> By App</p>
                                    </div>
                                </div>
                            ) : ad.status === 'expired' ? (
                                <div className="adCardAdTab__expired">
                                    <RedBackClockIcon />
                                    <p>
                                        <span>Expired</span>
                                        <Link to="/">Read Message</Link>
                                        <Button
                                            color="link"
                                            onClick={(event: React.MouseEvent) => this.props.onDelete(ad.id)}
                                        >
                                            Delete this Ad
                                        </Button>
                                    </p>
                                </div>
                            ) : (
                                <div className="adCardAdTab__approved">
                                    <TickIcon />
                                    <p>Active</p>
                                    {ad.invite_only && (
                                        <>
                                            <span data-tip data-for="invite-only" className="invite-only">
                                                <CircleIcon />
                                            </span>

                                            <ReactTooltip id="invite-only" type="dark" effect="solid">
                                                <p>Invite Only</p>
                                            </ReactTooltip>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        {ad.approval === 'rejected' ? (
                            <div className="mt-4 pt-5">
                                <InformationIcon
                                    height="20px"
                                    data-tip
                                    data-for={`AdrejectedMsg_${ad.id}`}
                                    color="primary"
                                />

                                <ReactTooltip
                                    id={`AdrejectedMsg_${ad.id}`}
                                    className="AdsMsg"
                                    type="dark"
                                    effect="solid"
                                >
                                    <p>
                                        <p>
                                            {' '}
                                            <b>Reason :</b>
                                        </p>

                                        {ad.rejection_reason}
                                    </p>
                                </ReactTooltip>
                            </div>
                        ) : null}
                    </td>
                    <div className="d-flex flex-row w-100 flex-wrap my-3">
                        <td className="adCardAdTab__overview">
                            <div>
                                Invitations: <span> </span>
                                {ad.getTotalInvitations()}
                            </div>
                        </td>
                        <td className="adCardAdTab__overview">
                            {ad.getTotalOffers() > 0 ? (
                                <Link to="/app/offers">
                                    Offers: <span> </span>
                                    {ad.getTotalOffers()}
                                </Link>
                            ) : (
                                <div>
                                    Offers: <span> </span>
                                    {ad.getTotalOffers()}
                                </div>
                            )}
                        </td>
                        <td className="adCardAdTab__overview">
                            {ad.getTotalDeals() > 0 ? (
                                <Link to="/app/offers/deals">
                                    Deals: <span> </span>
                                    {ad.getTotalDeals()}
                                </Link>
                            ) : (
                                <div>
                                    Deals: <span> </span>
                                    {ad.getTotalDeals()}
                                </div>
                            )}
                        </td>
                        <td className="adCardAdTab__overview">
                            <div>
                                Impressions: <span> </span>
                                {ad.impressions}
                            </div>
                        </td>
                        {RootStore.users.currentUser.is_admin ? (
                            <td className="adCardAdTab__overview">
                                <div>
                                    <DollarsSpentIcon />
                                    {ad.dollars_spent}
                                </div>
                            </td>
                        ) : null}
                    </div>

                    <td className="adCardAdTab__actions">
                        <div className="adCardAdTab__actions-btns opened">
                            <div
                                className="adCardAdTab__actions_settings"
                                data-tip
                                data-for="Control Offer"
                                color="warning"
                                onClick={this.props.controlOfferModal}
                            >
                                <img
                                    alt="gear icon"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABNUlEQVRIibWVTU7DMBCFP1qJDeUQrYTgACCxLN71JF1EqrpFZc1t2gXpCQIr4Dz9k1DYTCR3Oo6nCjzpSUn83hvHiT3gQwXUipXTm0Uf2BgFtjLWGXdGeMPbnLkXXV8CI0MzafFbYyPJOsGrzOoLmAGPwIcxa8130c7EW0vWSdWtI8zLPXATF1j9YXjDVRMeHOI3YAxcCZ+A0uELAEVG9KzXM8Ii4y0a4RQ4JGaeg/UmB8k8QgB2Sjh2FNBLvJNnJvSOHTgKXCvPJh7sWY4zcaHu61SBYBR8cBS4V/d9jCVKfeTSUWBt+I4+cu43XbSEv2S8Bfg2Wim6gTAkZm5uNIClQ3wul/GrDvnnww7s49pqlZoVjuMa0g1n3hI+N/TJhpNCp5bpQaem7zkqfoBv4/mnjLXiF5CfCtFi5/niAAAAAElFTkSuQmCC"
                                />
                            </div>
                            {ad.status === 'paused' ? (
                                <>
                                    <Button
                                        disabled={ad.approval === 'rejected' ? true : false}
                                        data-tip
                                        data-for="Active"
                                        color="dark"
                                        className="play-pause"
                                        onClick={(event: React.MouseEvent) => this.props.onActiveAd(ad.id)}
                                    >
                                        <PlayButtonArrowheadIcon />
                                    </Button>
                                    <ReactTooltip id="Active" type="dark" effect="solid">
                                        <p>Active Ad</p>
                                    </ReactTooltip>
                                </>
                            ) : (
                                <>
                                    <Button
                                        disabled={ad.approval === 'rejected' ? true : false}
                                        data-tip
                                        data-for="Pause"
                                        color="dark"
                                        className="play-pause"
                                        onClick={(event: React.MouseEvent) => this.props.onPauseAd(ad.id)}
                                    >
                                        <PauseIcon />
                                    </Button>
                                    <ReactTooltip id="Pause" type="dark" effect="solid">
                                        <p>Pause Ad</p>
                                    </ReactTooltip>
                                </>
                            )}

                            <Link
                                data-tip
                                data-for="Edit"
                                color="secondary"
                                to={
                                    this.isAdmin
                                        ? `/admin/ads-management/${ad.id}/edit-ad`
                                        : `/app/ads/${ad.id}/edit-ad`
                                }
                            >
                                <EditIcon />
                            </Link>

                            <ReactTooltip id="Edit" type="dark" effect="solid">
                                <p>Edit</p>
                            </ReactTooltip>

                            <Button
                                data-tip
                                data-for="Delete"
                                color="danger"
                                onClick={(event: React.MouseEvent) => this.props.onDelete(ad.id)}
                            >
                                <DeleteIcon />
                            </Button>
                            <ReactTooltip id="Delete" type="dark" effect="solid">
                                <p>Delete</p>
                            </ReactTooltip>

                            <Button
                                data-tip
                                data-for="Preview"
                                color="primary"
                                onClick={() => this.props.openModal(ad)}
                            >
                                <EyeIcon />
                            </Button>
                            <ReactTooltip id="Preview" type="dark" effect="solid">
                                <p>Preview</p>
                            </ReactTooltip>

                            <Button
                                data-tip
                                data-for="Duplicate"
                                color="primary"
                                onClick={(event: React.MouseEvent) => this.props.duplicateAd(ad.id)}
                            >
                                <CopyToClipboardIcon />
                            </Button>

                            <ReactTooltip id="Duplicate" type="dark" effect="solid">
                                <p>Duplicate Ad</p>
                            </ReactTooltip>
                            <ButtonDropdown
                                isOpen={this.state.dropDown}
                                toggle={() => this.setState({ dropDown: !this.state.dropDown })}
                            >
                                <DropdownToggle color="primary" disabled={ad.approval === 'rejected' ? true : false}>
                                    <RightArrowIcon />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {this.props.ad.invite_only ? (
                                        <DropdownItem onClick={() => this.props.inviteOnlyHandle(ad.id)}>
                                            Allow anyone to make offer
                                        </DropdownItem>
                                    ) : (
                                        <DropdownItem onClick={() => this.props.inviteOnlyHandle(ad.id)}>
                                            Invite Only
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </ButtonDropdown>
                            {this.isAdmin && ad.approval !== 'rejected' ? (
                                <Button color="danger" onClick={() => this.props.toggleReject(ad.id)}>
                                    <RejectIcon />
                                </Button>
                            ) : null}
                        </div>
                    </td>
                </tr>
                <tr className="adCardAdTab__spacer" />
            </>
        );
    }
}
