import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import UserLayout from '../../template/layout/UserLayout';

import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
// import AccountActivation from "./ConfirmEmail";
import ValidateConfirmationCode from './ValidateConfirmationCode';
import ChangePassword from './ChangePassword';
import ChangeEmail from './ChangeEmail';
import QRCode from './qrcode/qrcode';

const User = ({ match }: { match: any }) => {
    return (
        <>
            <UserLayout>
                <div className="authWrapper">
                    <Switch>
                        <Route path={`/login`} component={Login} />
                        <Route path={`/change-email`} component={ChangeEmail} />
                        <Route path={`/register`} component={Register} />
                        <Route path={`/mobile-app`} component={QRCode} />
                        <Route path={`/forgot-password`} component={ForgotPassword} />
                        <Route path={`/account-activation`} component={ValidateConfirmationCode} />
                        <Route path={`/reset/password/:token`} component={ChangePassword} />
                        <Redirect exact from={`/`} to={`/login`} />
                        <Redirect to="/error" />
                    </Switch>
                </div>
            </UserLayout>
            <Switch>
                
            </Switch>
        </>
    );
};

export default withRouter(User);
