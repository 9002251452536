import * as React from "react";
import { Badge, Card, Spinner, CardBody, CardSubtitle } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
// @ts-ignore
import Img from "react-image";
import { truncate } from "lodash";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";

import RootStore from "../../../../store/RootStore";
import { IContentModel } from "../../../../store/models/ContentModel";

interface Props extends RouteComponentProps {
  mode: "manager" | "select";
  onSelect: Function;
}

function ContentList(props: Props) {
  let contents: IContentModel[] = RootStore.contentStore.getContents();

  return RootStore.contentStore.isBusy ? (
    <Spinner color="primary" size={"lg"} className="spinner" />
  ) : (
    <React.Fragment>
      {contents?.length ? (
        <React.Fragment>
          <h3>
            <Badge color={"primary"} pill className={"large"}>
              Today
            </Badge>
          </h3>
          <br />
        </React.Fragment>
      ) : null}

      {contents.length ? (
        <Scrollbars
          className="contentListContainer"
          // autoHeight={true}
          // autoHeightMax={750}
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              style={{ display: "none" }}
              className="track-horizontal"
            />
          )}
        >
          <div className="contentListContainer__list">
            <React.Fragment>
              {contents
                .sort((a, b) => moment(b.updated_at).diff(a.updated_at))
                .map((value, index: number) => {
                  let style = { width: 256 * 2, height: 384 * 2 };

                  if (value.type === "post") {
                    style = { width: 512, height: 384 };
                  }

                  if (value.type === "post") {
                    return (
                      <Card className="content-post" key={`key_${index}`}>
                        <div className="position-relative">
                          <Img
                            src={value.getPreviewImage(
                              style.width,
                              style.height
                            )}
                            style={style}
                            loader={
                              <div style={style}>
                                <Spinner color="primary" className="mb-1" />
                              </div>
                            }
                            onClick={() => {
                              props.onSelect(value);
                            }}
                          />
                          <Badge
                            pill
                            className="position-absolute content-manager-badge badge-top-left"
                          >
                            Post
                          </Badge>
                        </div>
                        <CardBody>
                          <CardSubtitle className="mb-4">
                            {truncate(value.data.caption, { length: 75 })}
                          </CardSubtitle>
                        </CardBody>
                      </Card>
                    );
                  } else {
                    return (
                      <div
                        key={"key" + index}
                        className={
                          "content-card-head content-story content-card-story"
                        }
                      >
                        <Card inverse className="mb-4">
                          <Img
                            src={value.getPreviewImage(
                              style.width,
                              style.height
                            )}
                            style={style}
                            loader={
                              <div style={style}>
                                <Spinner color="primary" className="mb-1" />
                              </div>
                            }
                            onClick={() => {
                              props.onSelect(value);
                            }}
                          />
                          <Badge
                            pill
                            className="position-absolute content-manager-badge badge-top-left"
                          >
                            Story
                          </Badge>
                        </Card>
                      </div>
                    );
                  }
                })}
            </React.Fragment>
          </div>
        </Scrollbars>
      ) : (
        <div className="contentListContainer__emptyState">
          <img
            alt={"Empty List"}
            src={"/assets/img/hugo-list-is-empty.svg"}
            width={"40%"}
          />

          <h1 className="display-4">You have not created any content...</h1>
          <p className="lead">
            AIInfluencer gives you powerful tools to make your content!
          </p>
          <hr className="my-4" />
          <p>Use the above buttons to create new stories or posts...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default observer(ContentList);
