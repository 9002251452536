import React from 'react';
import Select from 'react-select';
import { CustomInput } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// import "ace-builds/webpack-resolver";
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';

export class FormikReactSelect extends React.Component {
    handleChange = (value) => {
        this.props.onChange(this.props.name, value);
    };

    handleBlur = () => {
        this.props.onBlur(this.props.name, true);
    };

    render() {
        return (
            <Select
                {...this.props}
                formatGroupLabel={this.props.formatGroupLabel}
                className={`react-select ${this.props.className} basic-single`}
                classNamePrefix="react-select"
                options={this.props.options}
                isMulti={this.props.isMulti}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={this.props.value}
                components={this.props.components}
            />
        );
    }
}

export class FormikAceEditor extends React.Component {
    handleChange = (value) => {
        this.props.onChange(this.props.name, value);
    };

    handleBlur = () => {
        this.props.onBlur(this.props.name, true);
    };
    render() {
        return (
            <AceEditor
                style={{ width: '100%' }}
                placeholder="Placeholder Text"
                mode="javascript"
                theme="monokai"
                // onLoad={this.onLoad}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                fontSize={14}
                // showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={this.props.value}
                setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                }}
            />
        );
    }
}

export class FormikWysiwyg extends React.Component {
    handleChange = (value) => {
        this.props.onChange(this.props.name, value);
    };

    handleBlur = () => {
        this.props.onBlur(this.props.name, true);
    };
    render() {
        return (
            <ReactQuill
                theme="snow"
                onChange={this.handleChange}
                value={this.props.value}
                modules={FormikWysiwyg.modules}
                formats={FormikWysiwyg.formats}
            />
        );
    }
}
FormikWysiwyg.modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'video'],
        ['clean'],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
FormikWysiwyg.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
];

export class FormikCheckboxGroup extends React.Component {
    handleChange = (val) => {
        let valueArray = [...this.props.value] || [];
        if (!valueArray.includes(val)) {
            valueArray.push(val);
        } else {
            valueArray.splice(valueArray.indexOf(val), 1);
        }
        this.props.onChange(this.props.name, valueArray);
    };

    handleBlur = () => {
        this.props.onBlur(this.props.name, true);
    };

    render() {
        const { name, value, options, inline = false } = this.props;
        return options.map((child, index) => {
            return (
                <div
                    key={`${name}_${child.value}_${index}`}
                    className={`position-relative form-check ${inline ? 'form-check-inline' : ''}`}
                >
                    <input
                        id={child.value}
                        name={name}
                        type="checkbox"
                        className="form-check-input"
                        onChange={() => this.handleChange(child.value)}
                        onBlur={this.handleBlur}
                        defaultChecked={value.includes(child.value)}
                        disabled={child.disabled}
                    />
                    <label className="form-check-label">{child.label}</label>
                </div>
            );
        });
    }
}
export class FormikCustomCheckboxGroup extends React.Component {
    handleChange = (val) => {
        let valueArray = [...this.props.value] || [];
        if (!valueArray.includes(val)) {
            valueArray.push(val);
        } else {
            valueArray.splice(valueArray.indexOf(val), 1);
        }
        this.props.onChange(this.props.name, valueArray);
    };

    handleBlur = () => {
        this.props.onBlur(this.props.name, true);
    };

    render() {
        const { name, value, options, inline = false } = this.props;
        return options.map((child, index) => {
            return (
                <CustomInput
                    key={`${name}_${child.value}_${index}`}
                    type="checkbox"
                    id={`${name}_${child.value}_${index}`}
                    name={child.name}
                    label={child.label}
                    onChange={() => this.handleChange(child.value)}
                    onBlur={this.handleBlur}
                    checked={value.includes(child.value)}
                    disabled={child.disabled}
                    inline={inline}
                />
            );
        });
    }
}

export const FormikCheckbox = (props) => {
    const handleChange = (event) => {
        props.onChange(props.name, !props.value);
    };

    const handleBlur = () => {
        props.onBlur(props.name, true);
    };

    return (
        <div className="position-relative form-check form-check-inline">
            <input
                name={props.name}
                type="checkbox"
                className="form-check-input"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={props.value}
                disabled={props.disabled}
            />
            <label className="form-check-label">{props.label}</label>
        </div>
    );
};

export const FormikCustomCheckbox = (props) => {
    const handleChange = (event) => {
        props.onChange(props.name, !props.value);
    };
    const handleBlur = () => {
        props.onBlur(props.name, true);
    };
    return (
        <CustomInput
            type="checkbox"
            id={props.name}
            name={props.name}
            label={props.label}
            onChange={handleChange}
            onBlur={handleBlur}
            checked={props.value}
            disabled={props.disabled}
            inline
        />
    );
};

export class FormikRadioButtonGroup extends React.Component {
    handleChange = (val) => {
        this.props.onChange(this.props.name, val);
    };

    handleBlur = () => {
        this.props.onBlur(this.props.name, true);
    };

    render() {
        const { name, value, options, inline = false } = this.props;
        return options.map((child, index) => {
            return (
                <div
                    key={`${name}_${child.value}_${index}`}
                    className={`position-relative form-check ${inline ? 'form-check-inline' : ''}`}
                >
                    <input
                        id={child.value}
                        name={name}
                        type="radio"
                        className="form-check-input"
                        onChange={() => this.handleChange(child.value)}
                        onBlur={this.handleBlur}
                        defaultChecked={value === child.value}
                        disabled={child.disabled}
                    />
                    <label className="form-check-label">{child.label}</label>
                </div>
            );
        });
    }
}

export class FormikCustomRadioGroup extends React.Component {
    handleChange = (val) => {
        this.props.onChange(this.props.name, val);
    };

    handleBlur = () => {
        this.props.onBlur(this.props.name, true);
    };

    render() {
        const { name, value, options, inline = false } = this.props;
        return options.map((child, index) => {
            return (
                <CustomInput
                    key={`${name}_${child.value}_${index}`}
                    type="radio"
                    id={`${name}_${child.value}_${index}`}
                    name={child.name}
                    label={child.label}
                    onChange={() => this.handleChange(child.value)}
                    onBlur={this.handleBlur}
                    checked={value === child.value}
                    disabled={child.disabled}
                    inline={inline}
                />
            );
        });
    }
}
export class FormikTagsInput extends React.Component {
    handleChange = (val) => {
        this.props.onBlur(this.props.name, true);
        this.props.onChange(this.props.name, val);
    };

    render() {
        const { name, value } = this.props;
        return <TagsInput id={name} name={name} value={value} onChange={this.handleChange} />;
    }
}

export class FormikSwitch extends React.Component {
    handleChange = (val) => {
        this.props.onBlur(this.props.name, true);
        this.props.onChange(this.props.name, val);
    };

    render() {
        const { name, value, className } = this.props;
        return (
            <>
                <Switch id={name} name={name} className={className} checked={value} {...this.props?.refs} onChange={this.handleChange} />
                {this.props?.refs && this.props?.refs['data-for'] && <ReactTooltip id='btnStatus' type="dark" effect="solid" />}
            </>
        );
    }
}
