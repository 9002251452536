import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
* this method Pivot Model
*/
export const PivotModel = types
  .model({
    identity_id: types.maybeNull(types.number),
    integration_id: types.maybeNull(types.number),
    created_at: types.maybeNull(types.string),
    updated_at: types.maybeNull(types.string),
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export interface IPivotModel extends Instance<typeof PivotModel> {}

export interface IPivotModelSnapshotIn extends SnapshotIn<typeof PivotModel> {}

export interface IPivotModelSnapshotOut
  extends SnapshotOut<typeof PivotModel> {}
