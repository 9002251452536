import React, { Component } from "react";
import { Spinner } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import { observer } from "mobx-react";
import numbro from "numbro";
import { truncate } from "lodash";

import ApplicationMenu from "../../../template/components/common/ApplicationMenu";
import { IOfferModel } from "../../../store/models/OfferModel";
import RootStore from "../../../store/RootStore";
import { momentDateTimeFormat } from "../../../lib/Utils";
import profileImg from "../../../assets/img/profilePlaceholder.jpg";

interface Props extends RouteComponentProps {
  offer: IOfferModel;
  offerButtons: any;
}

interface State {
  searchKey: string;
}

@observer
export class ChatApplicationMenu extends Component<Props, State> {
  public url = profileImg;
  constructor(props: Props) {
    super(props);

    this.state = {
      searchKey: "",
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let offer: any = null;

    if (props.offer === null) {
      offer = null;
    }

    offer = props.offer;

    return {
      offer,
    };
  }

  render() {
    return (
      <React.Fragment>
        <ApplicationMenu>
          {RootStore.offerStore.isBusy ? (
            <React.Fragment>
              <div>
                <Spinner color="primary" className="mb-1" />
                <p>Please wait...</p>
              </div>
            </React.Fragment>
          ) : (
            <PerfectScrollbar
              options={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <div className={"offer-sidebar"}>
                <h2 style={{ fontWeight: "bold" }}>
                  {this.props.offer.ad_id!.title}
                </h2>
                <h4>
                  Latest proposition{" "}
                  <b>by {this.props.offer.getLastModifierIdentity().name}:</b>
                </h4>
                <div className="offer-heading">
                  <div className="proposer">
                    <div className="chat">
                      <div className="profile">
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={
                            this.props.offer.getLastModifierIdentity().logo
                              ? this.props.offer
                                  .getLastModifierIdentity()
                                  .logo.getThumbnailUrl(50, 50)
                              : this.url
                          }
                          alt={"Profile"}
                        />
                      </div>
                      <div className="message">
                        <b>
                          {this.props.offer.getTargetIdentity().name.length > 15
                            ? `${this.props.offer
                                .getTargetIdentity()
                                .name.slice(0, 14)}...`
                            : this.props.offer.getTargetIdentity().name}
                        </b>
                        <span>
                          on @{this.props.offer.getTargetIntegration().username}
                        </span>
                      </div>
                    </div>
                    <div className="money">
                      {this.props.offer.price
                        ? numbro(this.props.offer.price)
                            .formatCurrency({
                              mantissa: 2,
                              thousandSeparated: true,
                              optionalMantissa: true,
                            })
                            .toUpperCase()
                        : ""}
                    </div>
                  </div>
                </div>
                {this.props.offer.content_id ? (
                  <img
                    alt={"offer content"}
                    src={this.props.offer.content_id.getPreviewImage(310, 310)}
                  />
                ) : (
                  <React.Fragment>
                    No content Specified!
                    <br />
                  </React.Fragment>
                )}
                <div className={"details"}>
                  <ul>
                    <li>
                      <span className="schedule-icon" />
                      At:{" "}
                      {this.props.offer.start ? (
                        <b>
                          {moment(
                            this.props.offer.start,
                            momentDateTimeFormat
                          ).format("MMMM Do YYYY, h:mm a")}
                        </b>
                      ) : (
                        <b>Unspecified</b>
                      )}
                    </li>
                    <li>
                      <span className="duration-icon" />
                      Duration:{" "}
                      <b>
                        {this.props.offer.duration
                          ? moment
                              .duration(
                                this.props.offer.duration as number,
                                "seconds"
                              )
                              .humanize()
                          : "Unspecified"}
                      </b>
                    </li>
                    <li>
                      <span className="bio-link-icon" />
                      Bio Link:{" "}
                      {this.props.offer.bio_link &&
                      this.props.offer.bio_link.length > 0 ? (
                        <a
                          href={`${this.props.offer.bio_link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {truncate(this.props.offer.bio_link, { length: 40 })}
                        </a>
                      ) : (
                        <b>Unspecified</b>
                      )}
                    </li>
                    {/* <li>
                      <span className="exclusivity-icon" />
                      Exclusive for:{" "}
                      <b>
                        {this.props.offer.duration
                          ? moment
                              .duration(this.props.offer.exclusivity as number, "seconds")
                              .humanize()
                          : "Unspecified"}
                      </b>
                    </li> */}
                  </ul>
                </div>
                <br />
                {/* {getOfferButtons(this.props.offer.getPossibleActions(), this.props)} */}
                {this.props.offer.status !== "paid"
                  ? this.props.offerButtons(
                      this.props.offer.getPossibleActions()
                    )
                  : null}
                {/*<ButtonGroup>*/}
                {/*  <Button size={'sm'} color={'success'}><span className={'offer-icon deal'}/>&nbsp;&nbsp;Accept</Button>*/}
                {/*  <Button size={'sm'} color={'primary'}><span className={'offer-icon balance'}/>&nbsp;&nbsp;Counter</Button>*/}
                {/*  <Button size={'sm'} color={'danger'}><span className={'offer-icon reject'}/>&nbsp;&nbsp;Reject</Button>*/}
                {/*</ButtonGroup>*/}
              </div>
            </PerfectScrollbar>
          )}
        </ApplicationMenu>
      </React.Fragment>
    );
  }
}
