import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { capitalize, truncate } from "lodash";
import RootStore from "../../../../../../../store/RootStore";
import { IntroSVGIcon, NarrowArrowIcon } from "../../../../../../../assets/icons";

function Wellcome(props: any) {
    const first_name = RootStore.users.currentUser.first_name;

    return (
        <div className="onboardingIntro" style={{ marginTop: '0 !important' }}>
            <IntroSVGIcon className="onboardingIntro__svg" />
            <div className="onboardingIntro__stages">
                <h1 style={{ fontSize: '2.3rem' }}>Hey {truncate(capitalize(first_name), { length: 30 })},</h1>
                <h2 style={{ color: "#7e7e7ef0" }}>
                    Welcome to <span>ai</span>
                    <span>nfluencer</span>
                </h2>
                <ol>
                    <li>
                        Ainfluencer is a 100% free marketplace where you can discover and invite influencers in your niche to collaborate,
                        create content, and promote your products and services.
                    </li>
                </ol>

                <Button className="btn" onClick={() => props.history.push('/app/welcome/mycompany')}>
                    Let’s Get Started <NarrowArrowIcon />
                </Button>
            </div>
        </div>
    );
}

export default withRouter(Wellcome)