import React, { useContext } from "react";
import { Grid } from "@mui/material";
import { CustomInput, Label } from 'reactstrap'
import { InformationIcon } from "../../../../../../../../../assets/icons";
import classes from '../../style.module.scss';
import ReactTooltip from 'react-tooltip';
import PublishTermsFormContext from "../../context";
import { Field } from "formik";


function PublishTermsFormBio() {
    const { setFieldValue, values, updateTerms } = useContext(PublishTermsFormContext)

    return (
        <Grid container item>
            <Grid item>
                <Label className={classes.label}>
                    Do you want to add a link in the influencer's bio after publishing your
                    Ad?
                    <InformationIcon data-tip data-for="AdDate3" className="specificLabel" />
                    <ReactTooltip id="AdDate3" type="dark" effect="solid">
                        <p style={{ width: '200px' }}>
                            Select YES if you wish to negotiate placing a promotional link
                            in an influencer’s profile page. The link will be placed for the
                            duration period below.
                        </p>
                    </ReactTooltip>
                </Label>
                <Grid item className={classes.content} flexDirection="column">
                    <Grid item>
                        <CustomInput
                            type="radio"
                            id="x4"
                            name="displayBioLink"
                            label="No"
                            value={0}
                            checked={!values?.displayBioLink}
                            onChange={(e) => {
                                setFieldValue('displayBioLink', 0);
                                updateTerms('displayBioLink', 0)
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomInput
                            type="radio"
                            id="x5"
                            name="displayBioLink"
                            label="Yes"
                            value={1}
                            // @ts-ignore
                            checked={values?.displayBioLink}
                            onChange={(e) => {
                                setFieldValue('displayBioLink', 1);
                                updateTerms('displayBioLink', 1)
                            }}
                        />
                    </Grid>
                    {values?.displayBioLink ? (
                        <Grid item className="formikDuration" marginTop={1}>
                            <Field
                                className="form-control"
                                name="bio_link"
                                placeholder="https://example.com"
                                onChange={(e: any) => {
                                    setFieldValue('bio_link', e.target.value);
                                    updateTerms('bio_link', e.target.value)
                                }}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </Grid >
    )
}

export default PublishTermsFormBio
