import { Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Label } from 'reactstrap';
import OnboardCard from '../../hoc/parts/card';
import OnboardBody from '../../hoc/parts/card/parts/body';
import NextButton from '../button';
import classes from './style.module.scss';
import * as Yup from 'yup';
import { FormikDokaImage, FormikReactSelect } from '../../../../../../common-components';
import RootStore from '../../../../../../../store/RootStore';
import { ICountryModel } from '../../../../../../../store/models/CountryModel';
import CreatAdLayout from '../../../../../createAd/layout';
import { CountriesList } from '../../../../../../../constants/countriesList';
import createAdContext from '../../../../../createAd/context';
import BaseTransport from '../../../../../../../lib/BaseTransport';
import API from '../../../../../../../lib/axiosSetup';
import Swal from 'sweetalert2';

function OnboardCompany(props: any) {
    const {
        data: {
            adID,
            discover: { hashtags },
        },
    } = useContext(createAdContext);
    const [Loading, setLoading] = useState(false);
    const [CountriesState, setCountriesState] = useState(null);
    const [CategoryList, setCategoryList] = useState(null);
    const IdentitySchema = Yup.object().shape({
        business_name: Yup.string().required('Business Name is required.'),
        category: Yup.array()
            .typeError('Category is required to be selected.')
            .required('Category is required to be selected.'),
        located: Yup.string().required('You should select your location.'),
    });

    useEffect(() => {
        const x = async () => {
            await RootStore.fetchStaticData();
            setCategoryList(RootStore.getCategorySelectOptions());
        };
        x();
    }, []);

    useEffect(() => {
        initiAds();
    }, []);

    const initiAds = async () => {
        if (!adID) return false;
        await RootStore.adStore.fetchAll();
        const Ads = await RootStore.adStore.getAllAds();
        const checkExistAd = await Ads.find((item) => +item?.id === +adID);
        if (checkExistAd) {
            RootStore.adStore.setAd(adID);
        }
    };

    const updateBrand = (identityId: number) => {
        API.put(BaseTransport.getEndPointPath(`v2/user/brand-information`), {
            identity: {
                id: identityId,
            },
        })
            .then(() => {
                setLoading(false);
                RootStore.router.push(`/app/welcome/objectives`);
            })
            .catch(function (error) {
                setLoading(false);
                if (error.response?.data?.message) {
                    Swal.fire({
                        type: 'error',
                        text: error.response.data.message,
                        showCancelButton: true,
                        showConfirmButton: true,
                    });
                }
            });
    };

    return (
        <CreatAdLayout
            head={{
                title: 'Your Brand Profile',
                paragraph: 'Tell us about your company and what you do',
            }}
        >
            <OnboardCard>
                <OnboardBody>
                    <Formik
                        initialValues={{
                            business_name: '',
                            category: [],
                            located: '',
                            logo: '',
                        }}
                        validationSchema={IdentitySchema}
                        onSubmit={(values) => {
                            const { business_name, category, located, logo } = values;

                            setLoading(true);
                            API.post(BaseTransport.getEndPointPath(`v2/user/brand-information`), {
                                ad_id: adID,
                                identity: {
                                    type: 'brand',
                                    name: business_name,
                                    categories: category.map((el) => el.value),
                                    country_id: located,
                                    logo: logo,
                                    hashtags: hashtags.length ? hashtags : [],
                                    register_step: true,
                                },
                                campaign: {
                                    title: 'Main Ad Group',
                                    description: 'This is default campaign',
                                    budget: 0,
                                    state: 'unstarted',
                                },
                            })
                                .then((res: any) => {
                                    updateBrand(res.data.identity.id);
                                })
                                .catch(function (error) {
                                    setLoading(false);
                                    if (error.response?.data?.message) {
                                        Swal.fire({
                                            type: 'error',
                                            text: error.response.data.message,
                                            showCancelButton: true,
                                            showConfirmButton: true,
                                        });
                                    }
                                });
                        }}
                    >
                        {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
                            <Form style={{ width: '100%' }}>
                                <Grid container spacing={5}>
                                    <Grid
                                        item
                                        container
                                        sm={6}
                                        xs={12}
                                        flexDirection="column"
                                        position="relative"
                                        rowSpacing={3}
                                    >
                                        <Grid item>
                                            <Label className="position-relative">
                                                Business name (Required)
                                                {errors.business_name && touched.business_name && (
                                                    <div className="invalidFeedback">{errors.business_name}</div>
                                                )}
                                            </Label>
                                            <Grid item>
                                                <Field className="form-control" name="business_name" />
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid item>
                                                <Label className="position-relative">
                                                    Where are you located ? (Required)
                                                    {errors.located && touched.located && (
                                                        <div className="invalidFeedback">{errors.located}</div>
                                                    )}
                                                </Label>
                                            </Grid>
                                            <Grid item>
                                                <FormikReactSelect
                                                    formatGroupLabel={(data: any) => (
                                                        <div>
                                                            <h4>{data.label}</h4>
                                                        </div>
                                                    )}
                                                    name="located"
                                                    id="onboarding_country"
                                                    value={CountriesState}
                                                    options={CountriesList.map((country: ICountryModel) => {
                                                        return { label: country.name, value: country.id };
                                                    })}
                                                    onChange={(e, value) => {
                                                        setCountriesState(value);
                                                        setFieldValue('located', value.value);
                                                    }}
                                                    onBlur={setFieldTouched}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid item>
                                                <Label className={`${classes.label} position-relative`}>
                                                    Select a category (Required)
                                                    {errors.category && touched.category && (
                                                        <div className="invalidFeedback">{errors.category}</div>
                                                    )}
                                                </Label>
                                            </Grid>
                                            <Grid item>
                                                <FormikReactSelect
                                                    formatGroupLabel={(data: any) => (
                                                        <div>
                                                            <h4>{data.label}</h4>
                                                        </div>
                                                    )}
                                                    name="category"
                                                    id="onboarding_country"
                                                    isMulti={true}
                                                    value={values.category}
                                                    options={CategoryList && CategoryList}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        sm={6}
                                        xs={12}
                                        textAlign="center"
                                        flexDirection="column"
                                        position="relative"
                                    >
                                        <Grid item>
                                            <Label className="position-relative">Your logo: (Optional)</Label>
                                        </Grid>
                                        <Grid item>
                                            <FormikDokaImage companyMode={true} onChange={setFieldValue} name="logo" />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item container justifyContent="right">
                                    <Grid item>
                                        <NextButton loading={Loading}>Next</NextButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </OnboardBody>
            </OnboardCard>
        </CreatAdLayout>
    );
}

export default withRouter(OnboardCompany);
