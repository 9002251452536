import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import classes from './style.module.scss';
import { FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
// import Transport from "../../../../../../../../..//lib/Transport";
import Swal from 'sweetalert2';
import { handleError } from '../../../../../../../../../lib/Utils';
import LoadingButton from '../../../../../../../../common-components/loadingButton';
import axios from 'axios';
// import API from "../../../../../../../../../lib/axiosSetup";
import RootStore from '../../../../../../../../../store/RootStore';
import { servicePath } from '../../../../../../../../../constants/defaultValues';

function PaypalEmail(props: any) {
    const [IsLoading, setIsLoading] = useState(false);
    const InitialValues = {
        email: '',
        password: props.dataPayment.password_payment || '',
    };

    const formikSchema = Yup.object().shape({
        email: Yup.string().required("email can't be empty").email().trim(),
        password: Yup.string().required("password can't be empty"),
    });
    const payment = props.dataPayment?.data;

    const onSubmit = (values: any) => {
        setIsLoading(true);
        const { email, password } = values;
        const headers = {
            headers: {
                Authorization: 'Bearer ' + RootStore.users.getToken(),
            },
        };
        const body = {
            paypal_email: email,
            payment_password: password,
        };
        console.log('payment ', props);
        const url = `${servicePath}/v2/wallets/${payment.wallet_id}`;
        axios
            .post(url, body, headers)
            .then((res) => {
                setIsLoading(false);
                Swal.fire({
                    type: res.data?.error ? 'error' : 'success',
                    title: res.data?.error || res.data?.message,
                    showCloseButton: true,
                }).then((e) => {
                    if (e.value && res.data?.message) {
                        props.toggle();
                    }
                    props.fetchData();
                });
                props.changePayPass(password);
            })
            .catch((error: any) => {
                if (error?.response?.data?.message) {
                    Swal.fire({
                        type: 'error',
                        title: error?.response?.data?.message,
                        showCloseButton: true,
                    });
                }

                setIsLoading(false);
                handleError(error);
            });
    };

    return (
        <Modal {...props} className="asset-modal">
            <ModalHeader toggle={props.toggle}>
                Update Paypal email ({`${props.dataPayment?.data.first_name} ${props.dataPayment?.data.last_name}`})
                {payment?.paypal_email ? (
                    <>
                        <br />
                        <strong style={{ fontSize: '.9rem' }}>({payment?.paypal_email})</strong>
                    </>
                ) : (
                    ''
                )}
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={InitialValues} validationSchema={formikSchema} onSubmit={onSubmit}>
                    {({ errors, touched, isSubmitting }) => (
                        <Form className="av-tooltip tooltip-label-right panel-form editBrandInfoForm">
                            <FormGroup className="error-l-100">
                                <Label className="position-relative">
                                    <b>New Email:</b>
                                    {errors.email && touched.email && (
                                        <div className="invalidFeedback">{errors.email}</div>
                                    )}
                                </Label>
                                <Field
                                    className={`form-control ${classes.inputs}`}
                                    name="email"
                                    disabled={isSubmitting}
                                />
                            </FormGroup>

                            <FormGroup className="error-l-100">
                                <Label className="position-relative">
                                    <b>Payment password:</b>
                                    {errors.password && touched.password && (
                                        <div className="invalidFeedback">{errors.password}</div>
                                    )}
                                </Label>
                                <Field className={`form-control ${classes.inputs}`} type="password" name="password" />
                            </FormGroup>

                            <LoadingButton loading={IsLoading}>Submit</LoadingButton>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
}

export default PaypalEmail;
