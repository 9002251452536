import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { NarrowArrowIcon } from '../../../../../../../../../../../../assets/icons';
import RootStore from '../../../../../../../../../../../../store/RootStore';
import classes from './style.module.scss';

interface IProps extends RouteComponentProps<any> {
    url: any | 'backward';
}
function OnboardHeadBack(props: IProps) {
    const { url } = props;

    return (
        <>
            <NarrowArrowIcon
                className={classes.back}
                onClick={
                    typeof url === 'string'
                        ? () =>
                              url === 'backward'
                                  ? RootStore.router.goBack()
                                  : RootStore.router.push(
                                        `${url}${props.match.params?.adID ? `/${props.match.params?.adID}` : ''}`,
                                    )
                        : url()
                }
            />
            {/* {} */}
        </>
    );
}

export default withRouter(OnboardHeadBack);
