import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TopNav from '../../views/common-components/Topnav';
import Sidebar from '../containers/navs/Sidebar';
import RootStore from '../../store/RootStore';
import classNames from 'classnames';

interface IAppLayoutProps extends RouteComponentProps {
    isAdmin?: boolean;
    containerClassnames?: string;
    displaySideAndTopNav: boolean;
    totalUnreadMessages?: number;
}

interface IAppLayoutState {
    isAdmin: boolean;
}

class AppLayout extends Component<IAppLayoutProps, IAppLayoutState> {
    constructor(props: any) {
        super(props);

        this.state = {
            isAdmin: RootStore.users.currentUser.is_admin,
        };
    }

    static getDerivedStateFromProps(props: IAppLayoutProps, state: IAppLayoutState) {
        if (!props.isAdmin) {
            return null;
        }
        const isAdmin = props.isAdmin;
        return { isAdmin };
    }

    render() {
        const { containerClassnames, displaySideAndTopNav } = this.props;

        return (
            <div
                id="app-container"
                className={classNames(containerClassnames, {
                    onboardingContainer: !displaySideAndTopNav,
                })}
            >
                {displaySideAndTopNav && (
                    <>
                        <TopNav history={this.props.history} />
                        <Sidebar
                            // @ts-ignore
                            isAdmin={this.state.isAdmin}
                            totalUnreadMessages={this.props.totalUnreadMessages}
                        />
                    </>
                )}
                <main>{this.props.children}</main>
            </div>
        );
    }
}

const mapStateToProps = ({ menu }: any) => ({
    containerClassnames: menu.containerClassnames,
});

export default withRouter(connect(mapStateToProps, {})(AppLayout));
