import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { NarrowArrowIcon } from '../../../../../../../assets/icons';
import RootStore from '../../../../../../../store/RootStore';
import createAdContext from '../../../../../createAd/context';
import CreatAdLayout from '../../../../../createAd/layout';
import classes from './style.module.scss';

function OnboardSkip(props: any) {
    const {
        data: { adID },
    } = useContext(createAdContext);

    return (
        <CreatAdLayout
            head={{
                title: 'Are you sure you want to skip this step?',
                paragraph: 'You are about to skip Ad creation',
            }}
        >
            <Grid container flexDirection="column" className={classes.skip} rowSpacing={2}>
                <Grid item container className="title">
                    Skipping this step means the Ad will not appear in the marketplace
                </Grid>
                <Grid item container flexDirection="column">
                    <Grid item>What would you like to do next?</Grid>
                    <Grid item container columnSpacing={2}>
                        <Grid item>
                            <Button
                                color="success"
                                onClick={() => props.history.goBack()}
                                className={`mt-3 ${classes.button}`}
                                type="submit"
                            >
                                Continue With Ad Creation <NarrowArrowIcon className="ml-2" />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                color="secondary"
                                onClick={() => RootStore.router.push(adID ? '/app/ads' : '/app/start')}
                                className={`mt-3 ${classes.button}`}
                                type="submit"
                            >
                                {adID ? 'Visit Ainfluencer Account' : 'Visit Influencer Dashboard'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CreatAdLayout>
    );
}

export default withRouter(OnboardSkip);
