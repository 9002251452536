import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import CreatAdUploads from '../../../image'
import CreatAdFeedContext from './context'
import CreatAdFeedDescription from './parts/description'
import CreatAdFeedFileUpload from './parts/fileUpload'
import classes from './style.module.scss'

function CreatAdFeedImage() {
    const { images: { list, remove } } = useContext(CreatAdFeedContext)

    return (
        <Grid container item className={classes.upload} padding={3} flexDirection="column">
            <CreatAdFeedDescription />
            <CreatAdFeedFileUpload />
            <CreatAdUploads list={list} remove={remove} />
        </Grid>
    )
}

export default CreatAdFeedImage
