import * as React from "react";
import { FormikValues } from "formik";
import { observer } from "mobx-react";
import * as Doka from "../../3rd-party/doka/doka.esm.min";
import { DokaOutput } from "../../3rd-party/doka/doka.esm.min";
import { Button } from "reactstrap";
import $ from "jquery";
import { UploadIcon } from "../../assets/icons";
import PersonIcon from "../../assets/icons/user.png";

interface Props extends FormikValues {
  name: string;
  companyMode?: boolean
  picture?: string;
}

interface State {
  picture: any | null;
}

@observer
export class FormikDokaImage extends React.Component<Props, State> {
  private imageRef: Element | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      picture: "",
    };
  }

  private handleBlur() {
    this.props.onBlur(this.props.name, true);
  }

  private removePicture() {
    this.setState({
      picture: "",
    });
    this.props.onChange(this.props.name, "");
  }

  private async newPicture() {
    let file = await FormikDokaImage.askForFile(
      "image/gif, image/jpeg, image/jpg, image/png"
    );
    let output: DokaOutput = await FormikDokaImage.showDoka(file);

    let reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // @ts-ignore
        this.imageRef.src = reader.result;
        this.props.onChange(this.props.name, reader.result);
      },
      false
    );

    if (output) {
      reader.readAsDataURL(output.file);
      this.setState({
        picture: output.file,
      });
    }
  }

  static askForFile(accept: string): Promise<File> {
    return new Promise((resolve, reject) => {
      let input = $(document.createElement("input"));
      input.attr("accept", accept);
      input.attr("type", "file");
      input.on("change", (e: Event) => {
        // @ts-ignore
        resolve(e.target.files[0]);
      });
      input.trigger("click");
    });
  }

  static showDoka(file: File): Promise<DokaOutput> {
    // @ts-ignore
    const doka = Doka.create({
      outputWidth: 192,
      outputHeight: 192,
      outputFit: "cover",
      cropMask: (root: any, setInnerHTML: any) => {
        // This sets the SVG inner HTML
        // 1. It defines a <mask> (white background, black circle on top)
        // 2. Then it fills the screen with transparent white, the mask is applied to this layer
        // 3. Finally it draws a one pixel line around the masked circular area
        setInnerHTML(
          root,
          `
            <mask id="my-mask">
                <rect x="0" y="0" width="100%" height="100%" fill="white"/>
                <circle cx="50%" cy="50%" r="50%" fill="black"/>
            </mask>
            <rect fill="rgba(255,255,255,.3125)" x="0" y="0" width="100%" height="100%" mask="url(#my-mask)"/>
            <circle cx="50%" cy="50%" r="50%" fill="transparent" stroke-width="1" stroke="#fff"/>
        `
        );
      },
      cropAspectRatio: 1,
    });
    // @ts-ignore
    return doka.edit(file);
  }

  render() {
    return (
      <div className="doka-image-control">
        {!this.props.picture && this.state.picture === "" ? (
          <>
            {this.props?.companyMode ?
              <div onClick={this.newPicture.bind(this)}>
                <img src={PersonIcon} alt="" style={{ width: '120px', marginTop: '20px', opacity: '.3' }} />
              </div>
              : (
                <div className="select-image" onClick={this.newPicture.bind(this)}>
                  <UploadIcon />
                  Click to upload image...
                </div>
              )}
          </>
        ) : (
          <div className={"selected-image"}>
            <img
              ref={(ref) => (this.imageRef = ref)}
              src={this.props.picture ? this.props.picture : ""}
              alt={"Selected"}
            />
            <br />
            <Button
              size={"xs"}
              color={"danger"}
              onClick={this.removePicture.bind(this)}
            >
              Remove Image
            </Button>
          </div>
        )}
      </div>
    );
  }
}
