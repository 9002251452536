import React, { Component, Fragment } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Row, Card, CardBody, Table } from 'reactstrap';
import numbro from 'numbro';
import { observer } from 'mobx-react';
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "reactstrap";
import axios from 'axios'
import Qs from 'qs'
import moment  from 'moment';

import { BreadCrumb } from '../../../common-components';
import { Colxx, Separator } from '../../../../template/components/common/CustomBootstrap';
import {
    CardIcon,
    WalletIcon,
    EditIcon,
    PlusIcon,
    // , DollarSignIcon
} from '../../../../assets/icons';
import EmptyState from './EmptyState';
import RootStore from '../../../../store/RootStore';
import { ajaxErrorAlert, handleError } from '../../../../lib/Utils';
import { ITransactionModel } from '../../../../store/models/TransactionModel';

import {servicePath} from './../../../../constants/defaultValues'

interface Props extends RouteComponentProps { }

interface State {
    transactions: ITransactionModel[] | null;
    reachedEnd: boolean;
    dataFetched: boolean;
    loading: boolean;
    limit:number;
    sorts: Array<object>;
    headers:object;
    page_count: number | null;
    page: number;
}

@observer
export default class Invoices extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            transactions: null,
            reachedEnd: false,
            dataFetched: false,
            loading: true,
            limit:10,
            page_count: null,
            page: 1,
            sorts: [
                {
                    key: "updated_at",
                    direction: "DESC"
                }
            ],
            headers:{
                headers: {
                    Authorization: `Bearer ${RootStore.users?.getToken()}`
                }
            },
        };
    }

    async componentDidMount() {
        try {
            this.getTransacions()
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('Could not load transactions list from server.');
        }
    }

    getTransacions = () =>{
        const {headers,page,limit,sorts} = this.state
        const body = {
            sorts,
            limit,
            page
        }
        const params = Qs.stringify(body, { arrayFormat: "indices" })
        const { id } = RootStore?.users?.currentUser?.wallet

        axios.get(`${servicePath}/wallets/${id}/transactions?${params}`,
                headers)
        .then(res => {
            const {transactions} = res?.data || []
            this.setState({dataFetched : true,transactions,loading:false})
        })
        .catch(err => {
            this.setState({loading:false})
            handleError(err);
            ajaxErrorAlert('Could not load transactions list from server.');    
        })
    }

    loadMoreOffers() {
        if (!this.state?.reachedEnd && this.state?.transactions !== null) {
            this.setState({ dataFetched: false, loading: true });
            const {headers,page,limit,sorts} = this.state
            const body = {
                sorts,
                limit,
                page
            }
            const params = Qs.stringify(body, { arrayFormat: "indices" })
            const { id } = RootStore?.users?.currentUser?.wallet

            axios.get(`${servicePath}/wallets/${id}/transactions?${params}`,
                headers)
            .then(res => {
                const {transactions, meta} = res?.data || []
                const {page, page_count} = meta

                    this.setState({
                        dataFetched: true,
                        page_count,
                        page,
                        loading: false,
                        reachedEnd: page >= page_count,
                    });
                    this.setState(prevState =>{
                        return {transactions : prevState?.transactions?.concat(transactions) }
                    })
            })
            .catch(err => {
                this.setState({loading:false})
                handleError(err);
                ajaxErrorAlert('Could not load transactions list from server.');
            })
        }
    }

 

    next = () =>{
        if (!this.state.reachedEnd) {
            this.setState(preState =>{
                return {page : preState.page + 1}
            })
        }
         this.loadMoreOffers();
    }

    render() {
        const {transactions, loading} = this.state
        const wallet = RootStore.users?.currentUser?.wallet
        const methods = wallet?.methods

        const last4 = () =>{
            if(typeof wallet?.getDefaultCard === 'function'){
                return wallet?.getDefaultCard()?.data?.last4 || '****'
            }else {
                return "****"
            }
        }

        const renderDefaultCard = () =>{
            if(Array.isArray(methods) && methods.length > 0){
                    return <>
                        Default Card
                        <br />
                        <span>
                            **** {last4()}
                        </span>
                    </>
               
            }else{
                return <>No Credit Card Added!</>
            }
        }
        return (
            <Fragment>
                <div className="invoices">
                    <div>
                        <BreadCrumb heading="Payment & Transactions" {...this.props} />
                    </div>

                    <div className="invoices__header">
                        <div className="invoices__header_card">
                            <div className="invoices__header_card_icon">
                                <CardIcon />
                            </div>
                            <p className="ml-2 d-block">
                                {renderDefaultCard()}
                            </p>

                            <div>
                                {RootStore.users?.currentUser.wallet?.methods?.length ? (
                                    <>
                                        <Link
                                            to={`${this.props?.match?.url}/cards-list`}
                                            className="d-flex align-items-center"
                                        >
                                            <span>
                                                <EditIcon />
                                            </span>{' '}
                                            Manage
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <Link
                                            to={`${this.props?.match?.url}/add-new-card`}
                                            className="d-flex align-items-center"
                                        >
                                            <span>
                                                <EditIcon />
                                            </span>{' '}
                                            Add Card
                                        </Link>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="invoices__header_card">
                            <div className="invoices__header_card_icon">
                                <WalletIcon />
                            </div>
                            <p className="ml-2 d-block">
                                Wallet Balance <br />
                                <span>
                                    {numbro(RootStore.users?.currentUser?.wallet?.credits)
                                        .formatCurrency({
                                            mantissa: 2,
                                            thousandSeparated: true,
                                            optionalMantissa: true,
                                        })
                                        .toUpperCase()}
                                </span>
                            </p>

                            <div>
                                <Link to={`${this.props.match?.url}/add-funds`} className="d-flex align-items-center">
                                    <span>
                                        <PlusIcon />
                                    </span>{' '}
                                    Add Funds
                                </Link>
                                {/* <Link
                  to={`${this.props.match.url}/refund`}
                  className="d-flex align-items-center">
                  <span>
                    <DollarSignIcon />
                  </span>{" "}
                  Withdraw Credit
                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Separator className="mb-3 mt-3" />
                {loading && transactions === null ? <div >
                           <Spinner color="primary" size={"lg"} className="spinner" /> 
                        </div> : ''}
                <InfiniteScroll
                    style={{ paddingTop: "25px" }}
                    dataLength={transactions ? transactions.length : 0}
                    next={this.next}
                    hasMore={!this.state?.reachedEnd}
                    loader={
                        <div className="table-loader">
                           {loading ? <Spinner color="primary" size={"lg"} className="spinner" /> : ''}
                        </div>
                    }
                    hasChildren={true}
                >
                {transactions && Array.isArray(transactions) && transactions.length > 0 ? (
                    <Card className="invoices__table">
                        <CardBody>
                            <Row className="mb-4 d-flex justify-content-between">
                                <Colxx xxx="12">
                                    <div className="search-sm d-inline-block float-md-left mr-5 mr-1 mb-1 align-top invoicesSearchBox">
                                        <input
                                            type="text"
                                            name="keyword"
                                            id="search"
                                            placeholder="Search"
                                            defaultValue={null}
                                            onChange={(event) => { }}
                                        />
                                    </div>
                                </Colxx>
                            </Row>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Amount</th>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Ad</th>
                                        <th>Influencer</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions.map(
                                        (transaction: ITransactionModel, index: number) => {
                                            const {created_at, type, offer_id, status, amount} = transaction
                                            return <tr key={`transaction_${index}`}>
                                                <td>
                                                    {amount}{' $'}
                                                </td>
                                                <td>{moment(created_at,"YYYYMMDD, h:mm:ss a").format('MMMM DD YYYY, hh:mm:ss')}</td>
                                                <td>{type}</td>
                                                <td>
                                                    {!offer_id ? '-' : <>Offer #{offer_id}</>}
                                                </td>
                                                <td>-</td>
                                                <td>
                                                    <span className="onHold">{status}</span>
                                                </td>
                                                <td>
                                                    {'--'}
                                                </td>
                                            </tr>
                                        },
                                    )}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                ) : ''}

                {!loading && transactions !== null && transactions.length === 0  ?
                    <EmptyState {...this.props} noCards={RootStore.users?.currentUser?.wallet?.methods?.length === 0} />
                    :''    
                }

               

            </InfiniteScroll>

            </Fragment>
        );
    }
}

