import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Swal from 'sweetalert2';

import RootStore from '../../../store/RootStore';
import { ajaxErrorAlert, handleError, showLoading } from '../../../lib/Utils';
import { IAdModel } from '../../../store/models/AdModel';
import AdRejection from './AdRejection';
import AdCard from '../../app/ads/ad/AdCard';
import { observer } from 'mobx-react';
import { AdPreviewModal } from '../../common-components';

interface Props extends RouteComponentProps {
    ads: IAdModel[];
}
interface State {
    openRejectAd: boolean;
    ads: IAdModel[];
    adDataModal: any;
    adModal: any;
}
@observer
export default class AdsList extends Component<Props, State> {
    public adId: number;

    constructor(props: Props) {
        super(props);
        this.state = {
            openRejectAd: false,
            ads: this.props.ads,
            adDataModal: null,
            adModal: false,
        };

        this.onDeleteAd = this.onDeleteAd.bind(this);
        this.toggleRejectAd = this.toggleRejectAd.bind(this);
    }
    // componentDidMount() {
    //   this.setState({ ads: [...RootStore.adStore.getAllAds()] });
    // }
    toggleRejectAd(adId: number): void {
        this.setState((prevState) => {
            this.adId = adId;

            return {
                openRejectAd: !prevState.openRejectAd,
            };
        });
    }

    async onDeleteAd(adId: number) {
        try {
            await Swal.fire({
                title: 'Warning',
                text: 'Are you sure you want to delete this ad?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Delete It',
                allowOutsideClick: false,
            })
                .then(async (res) => {
                    if (res.value) {
                        showLoading('Please Wait...', 'Please wait while we deleting ad');
                        await RootStore.adStore.removeAd(adId);
                        this.setState({ ads: RootStore.adStore.getAllAds() });
                        Swal.close();

                        Swal.fire({
                            type: 'success',
                            title: 'Ad has been deleted successfully!',
                            showCancelButton: false,
                            showConfirmButton: true,
                        });
                    }
                })
                .catch((e) => {
                    handleError(e);
                    ajaxErrorAlert('There was a problem with deleting ad you selected.');
                });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem with deleting ad you selected');
        }
    }

    render() {
        const { ads } = this.state;

        return (
            <Fragment>
                {this.state.adModal && (
                    <AdPreviewModal
                        getAdService={true}
                        brand={{
                            user_id: this.state.adDataModal?.content_id.user_id,
                        }}
                        isOpen={true}
                        ad={this.state.adDataModal}
                        onClose={() =>
                            this.setState({
                                adModal: false,
                            })
                        }
                        clear="report-component"
                    />
                )}
                <table className="adsList">
                    <thead>
                        <tr>
                            <th>Ad</th>
                            <th>Invitations</th>
                            <th>Offers</th>
                            <th>Deals</th>
                            <th>Impression</th>
                            {RootStore.users.currentUser.is_admin && <th>Dollars spent</th>}
                            {!RootStore.users.currentUser.is_admin && (
                                <>
                                    <th>Invite Only</th>
                                    <th>Paused</th>
                                </>
                            )}
                            {RootStore.users.currentUser.is_admin && <th>Visible</th>}
                            <th>Quick Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ads.map((ad) => (
                            <AdCard
                                ad={ad}
                                openModal={(ad: any) => {
                                    this.setState({
                                        adDataModal: ad,
                                        adModal: true,
                                    });
                                }}
                                key={`ad_${ad.id}`}
                                {...this.props}
                                toggleReject={this.toggleRejectAd}
                                onDelete={this.onDeleteAd}
                            />
                        ))}
                    </tbody>
                </table>
                {this.state.openRejectAd && (
                    <AdRejection
                        isOpen={this.state.openRejectAd}
                        toggle={() => this.toggleRejectAd(this.adId)}
                        Ad={ads.find((ad: IAdModel) => ad.id === this.adId)}
                    />
                )}
            </Fragment>
        );
    }
}
