import { IOfferModel } from './models/OfferModel';
import { applySnapshot, flow, getIdentifier, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import Transport from '../lib/Transport';
import { ajaxErrorAlert, handleError } from '../lib/Utils';
import { IPublishJobModel, IPublishJobModelSnapshotIn, PublishJobModel } from './models/PublishJobModel';
import { FilterGroup } from '../lib/QueryTypes';
import { IContentModel } from './models/ContentModel';
import { makePublishJobQuery } from './common/RequestHelpers';
import RootStore from './RootStore';
import moment, { Moment } from 'moment';
import { NotificationManager } from '../template/components/common/react-notifications';
import ApiQueryBuilder from '../lib/ApiQueryBuilder';

export const PublishJobStore = types
    .model({
        publishJobList: types.array(PublishJobModel),
    })
    .volatile((self) => ({
        isBusy: false,
        fetchedFromServer: false,
    }))
    .actions((self) => ({
        /**
         * this method check processing for this store
         */
        setBusy() {
            self.isBusy = true;
        },

        /**
         * this method just set value isBusy = false
         */
        setIdle() {
            self.isBusy = false;
        },

        /**
         * this method push model this store
         * @param model, this param type object for get model
         */
        pushModel(model: IPublishJobModel) {
            self.publishJobList.push(model);
        },

        /**
         * this method just clear publish Job List
         */
        clear() {
            self.publishJobList.clear();
        },

        /**
         * this method just clear ads
         */
        clearData() {
            RootStore.adStore.clearAdsData();
        },
        syncMissingModels(models: IPublishJobModelSnapshotIn[]) {
            if (!Array.isArray(models)) {
                return;
            }
            let publishJobIdList: number[] = self.publishJobList.map((publishJob) => {
                return publishJob.id;
            });

            let possiblyUpdatedModels = models.filter((model) => publishJobIdList.indexOf(model.id) >= 0);
            if (possiblyUpdatedModels.length) {
                for (let publishJob of self.publishJobList) {
                    for (let updatedPublishJob of possiblyUpdatedModels) {
                        if (publishJob.id === updatedPublishJob.id) {
                            applySnapshot(publishJob, updatedPublishJob);
                        }
                    }
                }
            }

            self.publishJobList.replace(
                self.publishJobList.concat(
                    models.filter((model) => publishJobIdList.indexOf(model.id) === -1).uniqueById(),
                ),
            );
        },
    }))
    .actions((self) => ({
        /**
         * this method create new Publish Job
         */
        create: flow(function* (snapshot: IPublishJobModelSnapshotIn): any {
            self.setBusy();

            try {
                let result = yield Transport.post(
                    'contents/' + snapshot.content_id + '/publish-jobs',
                    { publish_job: snapshot },
                    makePublishJobQuery(),
                );
                RootStore.contentStore.syncMissingModels(result.data['content']);

                self.publishJobList.push(PublishJobModel.create(result.data['publish_job']));
                self.setIdle();
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),
        syncMissingModels(models: IPublishJobModelSnapshotIn[]) {
            if (!Array.isArray(models)) {
                return;
            }
            let publishJobIdList: number[] = self.publishJobList.map((publishJob) => {
                return publishJob.id;
            });

            let possiblyUpdatedModels = models.filter((model) => publishJobIdList.indexOf(model.id) >= 0);
            if (possiblyUpdatedModels.length) {
                for (let content of self.publishJobList) {
                    for (let updatedPublishJob of possiblyUpdatedModels) {
                        if (content.id === updatedPublishJob.id) {
                            applySnapshot(content, updatedPublishJob);
                        }
                    }
                }
            }

            self.publishJobList.replace(
                self.publishJobList.concat(
                    models.filter((model) => publishJobIdList.indexOf(model.id) === -1).uniqueById(),
                ),
            );
        },

        /**
         * this method fetch publish jobs
         * @param id, this param id publish JobList
         * @param metricMode, this param metric Mode latest or all
         * @param forceFetch, this param used if need force fetch
         */
        fetch: flow(function* (
            id: number,
            metricMode: 'none' | 'latest' | 'all' = 'latest',
            forceFetch: boolean = false,
        ): Generator<any, IPublishJobModel, any> {
            if (!forceFetch) {
                let prevModel = self.publishJobList.filter((jb) => jb.id === id);
                if (prevModel.length) {
                    return prevModel[0];
                }
            }

            self.setBusy();

            try {
                let query = makePublishJobQuery(metricMode);
                let response = yield Transport.get(`publish-jobs/${id}`, query);

                console.log(response);

                RootStore.contentStore.syncMissingModels(response.data['content']);
                RootStore.integrationStore.syncMissingModels(response.data['integration']);
                RootStore.campaignStore.syncMissingModels(response.data['offer.campaign']);

                if (response.data['publish_job']['offer']) {
                    RootStore.offerStore.syncMissingModels([response.data['publish_job']['offer']]);
                    RootStore.identityStore.syncMissingModels([response.data['publish_job']['offer']['identity']]);
                }

                let prevModel = self.publishJobList.filter((jb) => jb.id === id);
                if (prevModel.length) {
                    self.syncMissingModels([response.data['publish_job']]);
                    self.setIdle();
                    return prevModel[0];
                } else {
                    let model = PublishJobModel.create(response.data['publish_job']);
                    self.publishJobList.push(model);
                    self.setIdle();
                    return model;
                }
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        /**
         * this method fetch all Publish Job
         */
        fetchAll: flow(function* (): any {
            self.setBusy();

            try {
                let query = makePublishJobQuery('latest');
                query.setPagination(10000, 0);

                let response = yield Transport.get('publish-jobs', query);

                RootStore.contentStore.syncMissingModels(response.data['content']);
                RootStore.integrationStore.syncMissingModels(response.data['integration']);
                RootStore.campaignStore.syncMissingModels(response.data['offer.campaign']);

                RootStore.identityStore.syncMissingModels(response.data['identity']);
                if (RootStore.users.currentUser.is_admin) {
                    for (let job of response.data['identity']) {
                        if (job['user']) {
                            RootStore.users.syncMissingModels([job['user']]);
                        }
                    }
                }
                if (response.data['offer.ad']) {
                    RootStore.adStore.syncMissingModels(response.data['offer.ad']);
                    for (let ad of response.data['offer.ad']) {
                        if (ad['content']) {
                            RootStore.contentStore.syncMissingModels([ad['content']]);
                        }
                    }
                }
                RootStore.identityStore.syncMissingModels(response.data['offer.target_identity']);

                for (let job of response.data['publish_jobs']) {
                    if (job['offer']) {
                        RootStore.offerStore.syncMissingModels([job['offer']]);
                    }
                }
                self.syncMissingModels(response.data['publish_jobs']);
                self.fetchedFromServer = true;
                self.setIdle();
            } catch (error) {
                console.log(error);

                self.setIdle();
                throw error;
            }
        }),

        /**
         * this method fetch all Publish Job
         * @param pagination, this param object for pagination Publish Job
         * @param SortOrder, this param object for sort Publish Job
         * @param filters_group, this param object for filter Publish Job
         */
        fetchAllPaginate: flow(function* (
            pagination: { page: number; pageSize: number },
            SortOrder,
            filters_group: FilterGroup[] = [],
        ): any {
            self.setBusy();

            try {
                let query = makePublishJobQuery('latest');
                query.setPagination(pagination.pageSize, pagination.page);
                if (SortOrder) {
                    query.addSort(SortOrder.key, SortOrder.direction);
                }
                if (filters_group?.length) {
                    query.addFilterGroupList(filters_group);
                }
                self.publishJobList.clear();
                RootStore.offerStore.clearOffers();
                RootStore.contentStore.clearContentList();

                let response = yield Transport.get('publish-jobs', query);

                RootStore.contentStore.syncMissingModels(response.data['content']);
                RootStore.integrationStore.syncMissingModels(response.data['integration']);
                RootStore.campaignStore.syncMissingModels(response.data['offer.campaign']);
                RootStore.adStore.syncMissingModels(response.data['offer.ad']);

                RootStore.identityStore.syncMissingModels(response.data['identity']);
                if (RootStore.users.currentUser.is_admin) {
                    for (let job of response.data['identity']) {
                        if (job['user']) {
                            RootStore.users.syncMissingModels([job['user']]);
                        }
                    }

                    for (let job of response.data['offer.target_identity']) {
                        if (job['user']) {
                            RootStore.users.syncMissingModels([job['user']]);
                        }
                    }

                    for (let job of response.data['offer.ad']) {
                        if (job['content']) {
                            RootStore.contentStore.syncMissingModels([job['content']]);
                        }
                    }
                }
                RootStore.identityStore.syncMissingModels(response.data['offer.target_identity']);

                for (let job of response.data['publish_jobs']) {
                    if (job['offer']) {
                        RootStore.offerStore.syncMissingModels([job['offer']]);
                    }
                }
                self.syncMissingModels(response.data['publish_jobs']);
                self.setIdle();
                return {
                    totalPages: response.data.meta.page_count,
                    page: response.data.meta.page,
                };
            } catch (error) {
                console.log(error);

                self.setIdle();
                throw error;
            }
        }),

        fetchAllPaginateV2: flow(function* (
            pagination: { page: number; pageSize: number },
            SortOrder,
            filters_group: FilterGroup[] = [],
        ): any {
            self.setBusy();

            try {
                let query = new ApiQueryBuilder();
                query.setPagination(pagination.pageSize, pagination.page + 1);

                let response = yield Transport.get('v2/admin/publish-jobs', query);

                self.setIdle();
                return {
                    publish_jobs: response.data['result'],
                    totalPages: response.data.last_page,
                    page: response.data.current_page - 1,
                };
            } catch (error) {
                console.log(error);

                self.setIdle();
                throw error;
            }
        }),
    }))
    .actions((self) => ({
        /**
         * this method for fetch all Publish Jobs force
         */
        fetchAllIfNecessary: flow(function* (): any {
            if (!self.fetchedFromServer) {
                try {
                    yield self.fetchAll();
                    self.fetchedFromServer = true;
                } catch (e) {
                    handleError(e);
                    console.log(e);

                    ajaxErrorAlert('Failed to load schedule, please refresh the page.');
                }
            }
        }),

        /**
         * this method clear Publish Jobs
         */
        clearPublishJobs() {
            self.publishJobList.clear();
        },
    }))
    .views((self) => ({
        /**
         * this method get Jobs By Offer
         * @param id, this param Publish Job ID
         */
        getJobsByOffer(id: number): IPublishJobModel[] {
            return self.publishJobList.filter((job) => {
                return job.offer_id && parseInt(getIdentifier(job.offer_id)) === id;
            });
        },

        /**
         * this method get Calendar Data
         */
        getCalendarData(): any[] {
            return self.publishJobList.map((job: IPublishJobModel) => {
                return {
                    key: job.id,
                    title: (job.content_id as IContentModel).type,
                    start: job.start,
                    end: job.duration === 0 ? moment(job.start).add(job.duration, 'seconds') : job.start,
                };
            });
        },

        /**
         * this method get all Publish Job
         */
        getAllJobs(): PublishJobView[] {
            let list: PublishJobView[] = [];
            for (let publishJob of self.publishJobList) {
                list.push(publishJob.getView());
                if (publishJob.duration !== 0) {
                    // add take down job too
                    list.push(publishJob.getTakeDownView());
                }
            }
            list = list.sort(compareByDate);
            return list;
        },

        /**
         * this method get all Publish Job admin
         */
        getAlljobsAdmin() {
            return self.publishJobList;
        },
        async sendApprovebyadminRequest(id: Number, status: String, reason?: string) {
            try {
                const response = await Transport.patch('publish-jobs/' + id, {
                    publish_job: {
                        url_approval: status,
                        reason: reason,
                    },
                });

                self.publishJobList.map((item) => {
                    if (item.id === id) {
                        applySnapshot(item, response.data['publish_job']);
                    }
                    return null;
                });

                return true;
            } catch (error) {
                NotificationManager.error('Error!', ``, 3000, null, null, 'filled');
                return false;
            }
        },
        async sendApprovebyadminRequestV2(id: Number, status: String, reason?: string) {
            try {
                await Transport.patch('publish-jobs/' + id, {
                    publish_job: {
                        url_approval: status,
                        reason: reason,
                    },
                });

                return true;
            } catch (error) {
                NotificationManager.error('Error!', ``, 3000, null, null, 'filled');
                return false;
            }
        },
    }));

function compareByDate(a: PublishJobView, b: PublishJobView) {
    if (a.eventDate.unix() < b.eventDate.unix()) return 1;
    if (b.eventDate.unix() < a.eventDate.unix()) return -1;

    return 0;
}

export interface PublishJobView {
    title: string;
    isTakeDown: boolean;
    eventDate: Moment;
    username: string;
    previewImage: string;
    content: IContentModel;
    duration: string;
    mediaType: 'post' | 'story';
    offerId?: IOfferModel;
    url: string;
    campaign?: string;
    failed: boolean;
    id: number;
    status: 'healthy' | 'expired' | 'failed';
    state: 'scheduled' | 'published' | 'completed' | 'removal';
}

export interface IContentStore extends Instance<typeof PublishJobStore> {}

export interface IContentStoreSnapshotIn extends SnapshotIn<typeof PublishJobStore> {}

export interface IContentStoreSnapshotOut extends SnapshotOut<typeof PublishJobStore> {}
