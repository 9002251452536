import React, { useContext } from 'react'
import classes from './style.module.scss'
import OnboardHeadBack from './parts/arrow'
import createAdContext from '../../../../../../../../createAd/context'

interface IProps {
    title?: string
    paragraph?: string
    url?: any | 'backward'
}
function OnboardHead(props: IProps) {
    const { baseUrl } = useContext(createAdContext)
    const { title, paragraph, url } = props
    return (
        <div className={`${classes.head} ${!title && !paragraph ? classes.sample : ''}`}>
            {url && <OnboardHeadBack url={typeof url === 'string' ? url === 'backward' ? 'backward' : `${baseUrl}/${url}` : () => url} />}
            {title && <div className={classes.title}>{title}</div>}
            {paragraph && <div className={classes.paragraph}>{paragraph}</div>}
        </div>
    )
}

export default OnboardHead
