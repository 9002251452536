import React, { Component } from "react";
// import { Spinner } from "reactstrap";

import { Separator } from "../../../../template/components/common/CustomBootstrap";
import { BreadCrumb, CalendarWidget } from "../../../common-components";
import { ajaxErrorAlert, handleError } from "../../../../lib/Utils";
// import Gamification from "./Gamification";
import { RouteComponentProps, Link } from "react-router-dom";
import RootStore from "../../../../store/RootStore";
import { observer } from "mobx-react";
// import BlogPosts from "./blog-posts";
import Overview from "./overview";
import { ScheduleNewContentIcon } from "../../../../assets/icons";
import YouTubePlayer from "../../../common-components/youtube-video";

// import Transport from "../../../../lib/Transport";

// interface State {
//   blogPosts: any[];
//   blogPostFetched: boolean;
// }

/**
 * this is class shows ads, offers, invitations sent number
 * and give user future publishing post date
 * linked to schedule content
 */

@observer
export default class Dashboard extends Component<RouteComponentProps> {
  // constructor(props: RouteComponentProps) {
  //   super(props);
  //   this.state = {
  //     blogPosts: [],
  //     blogPostFetched: false,
  //   };
  // }

  /**
   *  send request and get dashboard sections number and future ads date
   */
  async componentDidMount() {
    try {
      await RootStore.fetchStaticData();
      // let response = await Transport.get("wp/posts/?tags=47&_embed");
      // this.setState({ blogPosts: response.data, blogPostFetched: true });
    } catch (e) {
      handleError(e);
      ajaxErrorAlert(
        "Failed to get blog post data! please refresh the page..."
      );
    }
  }
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  render() {
    return (
      <>
        <BreadCrumb heading="Dashboard" {...this.props} />
        <Separator className="mb-5" />

        {RootStore.users.currentUser.getIdentities()?.length &&
        !RootStore.offerStore.isBusy ? (
          <div className="dashboardLanding">
            <div className="dashboardLanding__overView">
              <Overview {...this.props} />
              <YouTubePlayer embed="frE2ZLDDceY" />
              {/* {this.state.blogPostFetched ? (
                <BlogPosts blogPosts={this.state.blogPosts} {...this.props} />
              ) : (
                <div className="d-flex w-100 justify-content-center align-items-center">
                  <Spinner />
                </div>
              )} */}
            </div>

            <div>
              <div className="calendar-top"></div>
              <div className="calendar">
                <div className="calendar_header">
                  <h3>Publish Schedule</h3>
                  <Link
                    className="btn btn-primary"
                    to="/app/scheduler/schedule-content"
                  >
                    <ScheduleNewContentIcon /> Schedule New Content
                  </Link>
                </div>
                <CalendarWidget />
              </div>
            </div>
          </div>
        ) : (
          <div className="loading" />
        )}
      </>
    );
  }
}
