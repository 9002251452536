import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import moment, { Moment } from 'moment';
import { ucfirst, momentDateTimeFormat } from '../../lib/Utils';

/**
* this method Trans action Model
*/
export const TransactionModel = types
    .model({
        id: types.identifierNumber,
        created_at: types.string,
        updated_at: types.string,
        offer_id: types.maybeNull(types.number),
        currency: types.string,
        amount: types.number,
        fee: types.number,
        status: types.enumeration(['cancelled', 'expired', 'failed', 'locked', 'pending', 'refunded', 'transferred']),
        type: types.enumeration(['charge', 'transfer', 'payout']),
        description: types.string,

    })
    .views((self) => ({
        moment(): Moment {
            return moment(self.created_at, momentDateTimeFormat);
        },
        getTypeLabel() {
            return ucfirst(self.type);
        },
        getStatusLabel() {
            return ucfirst(self.status);
        },
    }));

export interface ITransactionModel extends Instance<typeof TransactionModel> { }

export interface ITransactionModelSnapshotIn extends SnapshotIn<typeof TransactionModel> { }

export interface ITransactionModelSnapshotOut extends SnapshotOut<typeof TransactionModel> { }
