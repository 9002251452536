import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { Colxx, Separator } from '../../../../template/components/common/CustomBootstrap';
import { BreadCrumb } from '../../../common-components';
import AddNewCardForm from './AddNewCardForm';
import { FormikHelpers } from 'formik';
import RootStore from '../../../../store/RootStore';
import { ajaxErrorAlert, handleError } from '../../../../lib/Utils';
import { NotificationManager } from '../../../../template/components/common/react-notifications';

interface Props extends RouteComponentProps {}

interface State {}

export default class AddNewCard extends Component<Props, State> {
    private async onSubmit(values: any, helpers: FormikHelpers<any>) {
        try {
            await RootStore.users.currentUser.wallet.createPaymentMethod(values);
            helpers.setSubmitting(false);
            NotificationManager.success('Done', `Your credit card was added successfully!`, 3000, null, null, 'filled');
            this.props.history.push('/app/account/payment');
        } catch (e) {
            handleError(e);
            ajaxErrorAlert(`Could not add credit card!, ${e.response.data.message}`);
        }
        helpers.setSubmitting(false);
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Colxx xxs="12">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <BreadCrumb heading="Add New Credit Card" {...this.props} />
                            </div>
                        </div>
                        <Separator className="mb-3 mt-3" />
                    </Colxx>
                </Row>

                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <div className="addNewCard">
                            <h2>Enter your credit card information</h2>
                            <hr />
                            <AddNewCardForm onSubmit={this.onSubmit.bind(this)} submitText="Save Card" />
                        </div>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
