import React, { Component } from "react";
import Lottie from "react-lottie";
import Measure, { BoundingRect } from "react-measure";
import animationData from "../../constants/data/animation.json";

export const AnimatedLogo = ({
  widthRatio,
  heightRatio,
  loop = false,
  speed = 1000,
  width,
  height,
}: {
  widthRatio: number;
  heightRatio: number;
  loop: boolean;
  speed: number;
  width?: number;
  height?: number;
}) => {
  const defaultOptions = {
    loop: loop,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie
      options={defaultOptions}
      height={350 * heightRatio}
      width={1063 * widthRatio}
      speed={speed}
    />
  );
};

interface Props {}

interface State {
  dimensions: BoundingRect | undefined;
}

export class ResponsiveAnimatedLogo extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      dimensions: {
        width: -1,
        height: -1,
        right: 0,
        bottom: 0,
        top: 0,
        left: 0,
      },
    };
  }
  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const { width } = this.state.dimensions;

    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          this.setState({ dimensions: contentRect.bounds });
        }}
      >
        {({ measureRef }) => (
          <div
            ref={measureRef}
            style={{
              height: width / 6,
              width: width,
            }}
          >
            <Lottie
              options={defaultOptions}
              height={(width! * 0.7) / 3}
              width={width! * 0.7}
              speed={1000}
            />
          </div>
        )}
      </Measure>
    );
  }
}
