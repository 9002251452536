import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Row } from 'reactstrap';
import { Colxx } from '../../../template/components/common/CustomBootstrap';
import { BreadCrumb } from '../../common-components';
import RootStore from '../../../store/RootStore';
import { observer } from 'mobx-react';
import AdsList from './AdsList';
import { IAdModel } from '../../../store/models/AdModel';
import { SortOrder, QueryOperator, FilterGroup } from '../../../lib/QueryTypes';
import DatatablePagination from '../../common-components/DatatablePagination';
import { Filters } from '../users-managment/components/Users';
import { Spinner } from 'reactstrap';
import ServerSideFilterSet from '../../common-components/Filter/ServerSideFilterSet';
import { FilterType, Range } from '../../common-components/Filter/AbstractFilterSet';
import { debounce } from 'lodash';
import FilterComponent from '../../common-components/Filter/FilterComponent';
import { Separator } from '../../../template/components/common/CustomBootstrap';
import EmptySearchResult from '../../app/offers/components/EmptySearchResult';

interface Props extends RouteComponentProps {}
interface State {
    ads: IAdModel[];
    dataFetched: boolean;
    filter_groups: FilterGroup[];
    sort: { key: string; direction: SortOrder };
    pagination: {
        totalPages: number | null;
        page: number;
        pageSize: number;
    };
}
@observer
export default class AddsManagement extends Component<Props, State> {
    private readonly filterSet: ServerSideFilterSet;
    constructor(props: Props) {
        super(props);
        this.state = {
            ads: [],
            sort: {
                key: 'created_at',
                direction: SortOrder.DESCENDING,
            },
            filter_groups: [
                {
                    isOr: false,
                    filters: [
                        {
                            key: 'approval',
                            value: 'pending',
                            operator: QueryOperator.STRING_CONTAINS,
                            not: false,
                        },
                    ],
                },
            ],
            dataFetched: false,
            pagination: {
                totalPages: null,
                page: 0,
                pageSize: 10,
            },
        };
        this.filterSet = new ServerSideFilterSet(debounce(this.onChange, 600));
        this.filterSet.addFilter({
            name: 'status',
            title: 'Status',
            type: FilterType.simpleFilter,
            defaultValue: null,
            values: () => [null, 'paused', 'active', 'expired'],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value) {
                    return value.toString();
                } else {
                    return 'All';
                }
            },
        });
        this.filterSet.addFilter({
            name: 'approval',
            title: 'Approval',
            type: FilterType.simpleFilter,
            defaultValue: 'pending',
            values: () => [null, 'pending', 'approved', 'rejected', 'draft'],
            valueToLabelConverter: (value: string | number | Range) => {
                if (value) {
                    return value.toString();
                } else {
                    return 'All';
                }
            },
        });
        this.onChange = this.onChange.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.fetchAds = this.fetchAds.bind(this);
    }
    async componentDidMount() {
        await this.fetchAds();
        this.setState({ ads: RootStore.adStore.getAllAds() });
    }

    setFilters(filters: Filters) {
        let filterGroup = [];

        if (filters.searchTerm) {
            filterGroup.push({
                isOr: true,
                filters: [
                    {
                        key: 'title',
                        value: filters.searchTerm,
                        operator: QueryOperator.STRING_CONTAINS,
                        not: false,
                    },
                ],
            });
        }
        if (filters.filters.status) {
            filterGroup.push({
                isOr: false,
                filters: [
                    {
                        key: 'status',
                        value: filters.filters.status,
                        operator: QueryOperator.STRING_CONTAINS,
                        not: false,
                    },
                ],
            });
        }
        if (filters.filters.approval) {
            filterGroup.push({
                isOr: false,
                filters: [
                    {
                        key: 'approval',
                        value: filters.filters.approval,
                        operator: QueryOperator.STRING_CONTAINS,
                        not: false,
                    },
                ],
            });
        }

        this.setState({
            pagination: {
                page: 0,
                totalPages: this.state.pagination.totalPages,
                pageSize: this.state.pagination.pageSize,
            },
            filter_groups: filterGroup,
            sort: {
                key: 'created_at',
                direction: SortOrder.DESCENDING,
            },
        });
    }

    async fetchAds() {
        this.setState({ dataFetched: false });
        let res = await RootStore.adStore.fetchAllAds(this.state.pagination, this.state.filter_groups, this.state.sort);
        this.setState({
            dataFetched: true,
            ads: RootStore.adStore.getAllAds(),
            pagination: {
                pageSize: this.state.pagination.pageSize,
                //@ts-ignore
                totalPages: res.totalPages,
                //@ts-ignore
                page: res.page,
            },
        });
    }

    async onFilter(filters: Filters): Promise<void> {
        this.setFilters(filters);
        this.fetchAds();
    }
    async pageChange(page: number): Promise<void> {
        await this.setState({
            pagination: { ...this.state.pagination, page },
        });
        await this.fetchAds();
    }
    onChange = (filters: Filters): void => {
        this.onFilter(filters);
    };
    render() {
        return (
            <>
                <FilterComponent values={RootStore.adStore.filterSet || {}} filterSet={this.filterSet} />
                <Separator className="mb-4 mt-3" />
                {!this.state.dataFetched ? (
                    <div>
                        <Spinner color="primary" className="mb-1" />
                        <p>Please wait...</p>
                    </div>
                ) : (
                    <>
                        {this.state.ads.length > 0 ? (
                            <Fragment>
                                <Row>
                                    <Colxx xxs="12">
                                        <BreadCrumb heading="Ads management" {...this.props} />
                                        {/* <Separator className="mb-5" /> */}
                                    </Colxx>
                                </Row>
                                <AdsList ads={this.state.ads} {...this.props} />
                                <DatatablePagination
                                    key="pagination"
                                    page={this.state.pagination.page}
                                    pages={this.state.pagination.totalPages}
                                    canPrevious={this.state.pagination.page === 0 ? false : true}
                                    canNext={
                                        this.state.pagination.page === this.state.pagination.totalPages - 1
                                            ? false
                                            : true
                                    }
                                    defaultPageSize={10}
                                    onPageChange={this.pageChange}
                                />
                            </Fragment>
                        ) : (
                            <EmptySearchResult />
                        )}
                    </>
                )}
            </>
        );
    }
}
