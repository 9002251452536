import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import Transport from "../../../../lib/Transport";
import {
  UserIcon,
  AdIcon,
  InfluencerIcon,
  BrandIcon,
  DealsIcon,
  OfferssIcon,
  InvitesIcon,
  TransactionsIcon,
} from "../../../../assets/icons";
import { Spinner } from "reactstrap";

interface Model {
  total: number;
  today: number;
  yesterday: number;
  month: number;
}
interface InfluencerModel extends Model {
  external: number;
}
interface State {
  dataFetched: boolean;
  day_start: string | null;
  users: Model | null;
  influencers: InfluencerModel | null;
  brands: Model | null;
  offers: Model | null;
  deals: Model | null;
  ads: Model | null;
  integrations: Model | null;
  invites: { sent: Model; accepted: Model } | null;
  transactions: { in: Model; out: Model; released: Model } | null;
}
interface Props extends RouteComponentProps {}

export default class DashboardWidgets extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dataFetched: false,
      day_start: null,
      integrations: null,
      users: null,
      influencers: null,
      brands: null,
      offers: null,
      deals: null,
      ads: null,
      invites: null,
      transactions: null,
    };
  }

  async componentDidMount() {
    let response = await Transport.get("admin/dashboard");

    this.setState({
      dataFetched: true,
      day_start: response.data.day_start,
      users: response.data.users,
      influencers: response.data.influencers,
      deals: response.data.deals,
      brands: response.data.brands,
      offers: response.data.offers,
      ads: response.data.ads,
      invites: response.data.invites,
      integrations: response.data.integrations,
      transactions: {
        in: {
          total: +response.data.transactions.in.total,
          month: +response.data.transactions.in.month,
          yesterday: +response.data.transactions.in.yesterday,
          today: +response.data.transactions.in.today,
        },
        out: {
          total: +response.data.transactions.out.total,
          month: +response.data.transactions.out.month,
          yesterday: +response.data.transactions.out.yesterday,
          today: +response.data.transactions.out.today,
        },
        released: {
          total: +response.data.transactions.released.total,
          month: +response.data.transactions.released.month,
          yesterday: +response.data.transactions.released.yesterday,
          today: +response.data.transactions.released.today,
        },
      },
    });
  }
  render() {
    return (
      <>
        {!this.state.dataFetched ? (
          <div>
            <Spinner color="primary" className="mb-1" />
            <p>Please wait...</p>
          </div>
        ) : (
          <div className="analytics-page">
            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <UserIcon />
                  <div>
                    <span> {this.state.users.total}</span>
                    <p>Total users</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <UserIcon />

                  <div>
                    <span> {this.state.users.month}</span>
                    <p>Users this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <UserIcon />

                  <div>
                    <span>{this.state.users.yesterday}</span>

                    <p>Users yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <UserIcon />

                  <div>
                    <span>{this.state.users.today}</span>

                    <p>Users today</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InfluencerIcon />

                  <div>
                    <span> {this.state.influencers.total}</span>
                    <p>Total influencers</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InfluencerIcon />

                  <div>
                    <span> {this.state.influencers.month}</span>
                    <p>Influencers this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InfluencerIcon />

                  <div>
                    <span>{this.state.influencers.yesterday}</span>

                    <p>Influencers yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InfluencerIcon />

                  <div>
                    <span>{this.state.influencers.today}</span>

                    <p>Influencers today</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InfluencerIcon />

                  <div>
                    <span> {this.state.integrations.total}</span>
                    <p>Total instagram accounts</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InfluencerIcon />

                  <div>
                    <span> {this.state.integrations.month}</span>
                    <p>Instagram accounts this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InfluencerIcon />

                  <div>
                    <span>{this.state.integrations.yesterday}</span>

                    <p>Instagram accounts yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InfluencerIcon />

                  <div>
                    <span>{this.state.integrations.today}</span>

                    <p>Instagram accounts today</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <BrandIcon />

                  <div>
                    <span> {this.state.brands.total}</span>
                    <p>Total brands</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <BrandIcon />

                  <div>
                    <span> {this.state.brands.month}</span>
                    <p>Brands this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <BrandIcon />

                  <div>
                    <span>{this.state.brands.yesterday}</span>

                    <p>Brands yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <BrandIcon />

                  <div>
                    <span>{this.state.brands.today}</span>
                    <p>Brands today</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <AdIcon />

                  <div>
                    <span> {this.state.ads.total}</span>
                    <p>Ads total</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <AdIcon />

                  <div>
                    <span>{this.state.ads.month}</span>
                    <p>Ads this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <AdIcon />

                  <div>
                    <span>{this.state.ads.yesterday}</span>
                    <p>Ads yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <AdIcon />

                  <div>
                    <span>{this.state.ads.today}</span>
                    <p>Ads</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <OfferssIcon />

                  <div>
                    <span> {this.state.offers.total}</span>
                    <p>Totoal offers</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <OfferssIcon />

                  <div>
                    <span> {this.state.offers.month}</span>
                    <p>Offers this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <OfferssIcon />

                  <div>
                    <span>{this.state.offers.yesterday}</span>

                    <p>Offers yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <OfferssIcon />

                  <div>
                    <span>{this.state.offers.today}</span>

                    <p>Offers today</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <DealsIcon />

                  <div>
                    <span> {this.state.deals.total}</span>
                    <p>Total deals</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <DealsIcon />

                  <div>
                    <span> {this.state.deals.month}</span>
                    <p>Deals this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <DealsIcon />

                  <div>
                    <span>{this.state.deals.yesterday}</span>

                    <p>Deals yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <DealsIcon />
                  <div>
                    <span>{this.state.deals.today}</span>

                    <p>Deals today</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InvitesIcon />

                  <div>
                    <span> {this.state.invites.sent.total}</span>
                    <p>Total sent invitations</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InvitesIcon />

                  <div>
                    <span> {this.state.invites.sent.month}</span>
                    <p>sent Invitations this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InvitesIcon />

                  <div>
                    <span>{this.state.invites.sent.yesterday}</span>

                    <p>sent Invitations yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InvitesIcon />
                  <div>
                    <span>{this.state.invites.sent.today}</span>

                    <p>sent Invitations today</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InvitesIcon />

                  <div>
                    <span> {this.state.invites.accepted.total}</span>
                    <p>Total accepted invitations</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InvitesIcon />

                  <div>
                    <span> {this.state.invites.accepted.month}</span>
                    <p>Accepted Invitations this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InvitesIcon />

                  <div>
                    <span>{this.state.invites.accepted.yesterday}</span>

                    <p>Accepted Invitations yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <InvitesIcon />
                  <div>
                    <span>{this.state.invites.accepted.today}</span>

                    <p>Accepted Invitations today</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />

                  <div>
                    <span> {this.state.transactions.in.total}$</span>
                    <p>Total transactionIns</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />

                  <div>
                    <span> {this.state.transactions.in.month}$</span>
                    <p>TransactionIns this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />

                  <div>
                    <span>{this.state.transactions.in.yesterday}$</span>

                    <p>TransactionIns yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />
                  <div>
                    <span>{this.state.transactions.in.today}$</span>

                    <p>TransactionIns today</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />

                  <div>
                    <span> {this.state.transactions.out.total}$</span>
                    <p>Total transactionOuts</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />

                  <div>
                    <span> {this.state.transactions.out.month}$</span>
                    <p>TransactionOuts this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />

                  <div>
                    <span>{this.state.transactions.out.yesterday}$</span>

                    <p>TransactionOuts yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />
                  <div>
                    <span>{this.state.transactions.out.today}$</span>

                    <p>TransactionOuts today</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="kpiList_4col mb-3">
              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />

                  <div>
                    <span> {this.state.transactions.released.total}$</span>
                    <p>Total transfers</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />

                  <div>
                    <span> {this.state.transactions.released.month}$</span>
                    <p>Transfers this month</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />

                  <div>
                    <span>{this.state.transactions.released.yesterday}$</span>

                    <p>Transfers yesterday</p>
                  </div>
                </div>
              </div>

              <div className="dashboardWidget">
                <div className="dashboardWidget__header">
                  <TransactionsIcon />
                  <div>
                    <span>{this.state.transactions.released.today}$</span>

                    <p>Transfers today</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
