import React, { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';

import { TabsComponent } from '../../../common-components';
import AdsTab from './AdsTab';
import CampaignsTab from '../campaigns/CampaignsTab';
import InvitedInfluencersTab from '../invited-influencers/InvitedInfluencersTab';
import YouTubePlayer from '../../../common-components/youtube-video';

function AdsList(props: RouteComponentProps) {
    const [isOpen, setIsOpen] = useState(true);
    const dontshow = localStorage.getItem('dont_show_guidance_video');

    const iframee = useRef();
    const tabs = [
        {
            tabName: 'Ads',
            component: <AdsTab {...props} />,
            to: `${props.match.url}`,
            pageTitle: 'My Ads',
        },
        {
            tabName: 'Invited Influencers',
            component: <InvitedInfluencersTab {...props} />,
            to: `${props.match.url}/invited-influencers`,
            pageTitle: 'Invited Influencers',
        },
        {
            tabName: 'Campaigns',
            component: <CampaignsTab {...props} />,
            to: `${props.match.url}/campaigns`,
            pageTitle: 'Campaigns',
        },
    ];

    useEffect(() => {
        const check = async () => {
            if (dontshow) {
                close();
            }
        };
        check();
    }, [iframee]);
    const close = () => {
        setIsOpen(false);
    };

    const dontShowMe = () => {
        localStorage.setItem('dont_show_guidance_video', 'on');
        close();
    };

    return (
        <>
            {/* <BreadCrumb
        heading={
          tabs.find((tab) => tab.to === props.location.pathname).pageTitle
        }
        {...props}
      /> */}
            <div className="separator mb-5" />
            {isOpen && (
                <div
                    className="ad-guidance-video-parent"
                    onClick={(e) => {
                        e.stopPropagation();
                        close();
                    }}
                >
                    <div className="ad-guidance-video-child">
                        <YouTubePlayer embed="frE2ZLDDceY" />
                        <div className="ad-guidance-video-close" onClick={close}>
                            close
                        </div>
                        {!dontshow && (
                            <div className="ad-guidance-video-btn" onClick={dontShowMe}>
                                Don't show me again
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="tips-box">
                <p className="tips-box_title" onClick={() => setIsOpen(true)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="32"
                        height="28"
                        viewBox="0 0 48 48"
                        style={{ fill: '#000000' }}
                    >
                        <path
                            fill="#FF3D00"
                            d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"
                        ></path>
                        <path fill="#FFF" d="M20 31L20 17 32 24z"></path>
                    </svg>
                    Tips
                </p>
            </div>
            <TabsComponent tabs={tabs} {...props} />
        </>
    );
}

export default observer(AdsList);
