import AbstractTaskGenerator, { ITask } from "./AbstractTaskGenerator";
// import RootStore from "../../../../store/RootStore";
// import Transport from "../../../../lib/Transport";
// import moment from "moment";
// interface HasView {
//   view: () => void;
// }

export default class InviteWithDm extends AbstractTaskGenerator {
  constructor(public task: ITask) {
    super(task);
  }
  taskTitle(): string {
    return `Invite ${this.task.data.invitation.target_id.name}`;
  }
  actionBtnTitle(): string {
    return "Send DM";
  }
  viewText(): string {
    return "Complete task";
  }
}
//git
