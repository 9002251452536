import React from "react";
import { Modal, Button, FormGroup, Label } from "reactstrap";
import { Form, Formik } from "formik";
import Swal from "sweetalert2";

import { IAdModel } from "../../../../store/models/AdModel";
import {
  handleError,
  ajaxErrorAlert,
  showLoading,
  momentDateTimeFormat,
} from "../../../../lib/Utils";
import NotificationManager from "../../../../template/components/common/react-notifications/NotificationManager";
import ScheduleSelectFormik from "../../ads/components/ScheduleSelectFormik";
import RootStore from "../../../../store/RootStore";

interface Props {
  ad: IAdModel;
  isOpen: boolean;
  onClose(): void;
}
/**
 * 
 * @param props , { ad , isOpen , onClose }
 * @returns form edit schedule
 */
function EditSchedule(props: Props) {
  const { onClose, isOpen } = props;
  /**
   * 
   * @returns { start, end, id } of selected date want to edit
   */
  const getInitialValues = () => {
    let data: any = { ...props?.ad };
    data.schedule = [];

    for (let timeTable of props?.ad?.time_tables) {
      data?.schedule.push({
        start: timeTable?.start,
        end: timeTable?.end,
        id: timeTable?.id,
      });
    }

    return data;
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} className="editScheduleModal">
      <Formik
        initialValues={getInitialValues()}
        // validationSchema={AdSchema}
        onSubmit={async (values, actions) => {
          showLoading(
            "Loading...",
            "Please wait while we reschedule your Ad..."
          );

          try {
            if (values.schedule) {
              for (let schedule_item of values.schedule) {
                schedule_item.start =
                  schedule_item.start.format(momentDateTimeFormat);
                if (schedule_item.end) {
                  schedule_item.end =
                    schedule_item.end.format(momentDateTimeFormat);
                } else {
                  schedule_item.end = schedule_item.start;
                }
              }
            }

            values.content_id = values.content_id.id;
            values.campaign_id = values.campaign_id.id;

            await RootStore.adStore.update(props.ad.id, values);

            Swal.close();
            NotificationManager.success(
              "Done",
              "The ad was successfully updated!",
              3000,
              null,
              null,
              "filled"
            );
            props.onClose();
          } catch (error) {
            ajaxErrorAlert(
              "There was a problem with editing schedule of selected Ad."
            );
            handleError(error);
          }
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
          setErrors,
          setFieldError,
        }) => (
          <Form
            translate={"no"}
            className="av-tooltip tooltip-label-right panel-form "
          >
            <Label>Set a date range of when this Ad can be published.</Label>
            <FormGroup className="error-l-100 d-flex flex-column">
              <Label className="position-relative">
                Reschedule
                {errors.schedule && touched.schedule && (
                  <div className="invalidFeedback">{errors.schedule}</div>
                )}
              </Label>
              <ScheduleSelectFormik
                onChange={(name: string, value: any) => {
                  setFieldValue(name, value);
                }}
                name="schedule"
                value={values.schedule}
              />
            </FormGroup>
            <div className="editScheduleModal__actions">
              <Button onClick={onClose} outline color="secondary">
                Cancel
              </Button>
              <Button type="submit">Edit Scheduling</Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default EditSchedule;
