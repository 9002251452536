import * as React from 'react';
import { Badge, Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { truncate } from 'lodash';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NotificationManager } from '../../../../template/components/common/react-notifications';

import RootStore from '../../../../store/RootStore';
import { IAdModel } from '../../../../store/models/AdModel';
import {
    EditIcon,
    TickIcon,
    HourGlassIcon,
    ViewIcon,
    OfferIcon,
    PauseIcon,
    DealIcon,
    RedBackClockIcon,
    Invitation2Icon,
    DollarsSpentIcon,
    InformationIcon,
    ThreeDotIcon,
} from '../../../../assets/icons';
import { AdsInvitationsList, AdsOffersList } from '../../../common-components';
import { FormikSwitch } from '../../../common-components';

import { ajaxErrorAlert, handleError, showLoading } from '../../../../lib/Utils';
// import { NotificationManager } from "../../../../template/components/common/react-notifications";
import Swal from 'sweetalert2';
import ProfilePlaceholder from '../../../../assets/img/profilePlaceholder.jpg';
import { SortOrder } from './../../../../lib/QueryTypes';
import { CountriesList } from './../../../../constants/countriesList';
import moment from 'moment';

interface Props extends RouteComponentProps {
    ad: IAdModel;
    openModal: any;
    toggleReject?: (adId: number) => void;
    onDelete(adId: number): void;
    onPauseAd?: (adId: number) => void;
    onActiveAd?: (adId: number) => void;
    inviteOnlyHandle?: (adId: number) => void;
    duplicateAd?: (adId: number) => void;
    controlOfferModal?: () => void;
}

interface State {
    displayAdPreview: boolean;
    showRejectionModal: boolean;
    showInvitationsModal: boolean;
    showOfferModal: boolean;
    actions: boolean;
    dropDown: boolean;
    disabled?: boolean;
}

@observer
export default class AdCard extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            displayAdPreview: false,
            showRejectionModal: false,
            showInvitationsModal: false,
            showOfferModal: false,
            actions: false,
            dropDown: false,
            disabled: false,
        };

        this.onDisplayAdPreview = this.onDisplayAdPreview.bind(this);
        this.approveAd = this.approveAd.bind(this);
        this.toggleInvitationsModal = this.toggleInvitationsModal.bind(this);
        this.toggleOffersModal = this.toggleOffersModal.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.featureChange = this.featureChange.bind(this);
    }

    public isAdmin: boolean = RootStore.users.currentUser.is_admin;

    onDisplayAdPreview() {
        this.setState((prevState) => ({
            displayAdPreview: !this.state.displayAdPreview,
        }));
    }

    async featureChange(id, status) {
        try {
            await RootStore.adStore.editAd(id, { feature: status });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem in approving the Ad.');
        }
    }

    toggleRejectionReasonModal(): void {
        if (this.isAdmin) {
            this.setState((prevState) => ({
                showRejectionModal: !prevState.showRejectionModal,
            }));
        }
    }

    async approveAd(id: number): Promise<void> {
        try {
            showLoading();
            await RootStore.adStore.editAd(id, { approval: 'approved' });
            Swal.close();
            Swal.fire({
                type: 'success',
                title: 'Ad has been approved successfully!',
                showCancelButton: false,
                showConfirmButton: true,
            });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem in approving the Ad.');
        }
    }

    toggleInvitationsModal(): void {
        if (this.isAdmin && this.props.ad.invitation_count > 0) {
            this.setState((prevState) => ({
                showInvitationsModal: !prevState.showInvitationsModal,
            }));
        }
        console.log('toggled', this.state.showInvitationsModal);
    }

    toggleOffersModal(): void {
        if (this.isAdmin) {
            this.setState((prevState) => ({
                showOfferModal: !prevState.showOfferModal,
            }));
        }
    }

    copyToClipboard(): void {
        const host = window.location.host;
        const el = document.createElement('textarea');
        el.value = `https://${host}/app/ads/ad/objectives/${this.props.ad.id}`;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        NotificationManager.success('Ad URL has been Copied to Clipboard', `Copied !`, 3000, null, null, 'filled');
    }

    getPropertyButtonStatusAd(ad: any): any {
        const IsPaused = ad.status === 'paused';
        return {
            onClick: () => {
                if (ad.status === 'draft') return false;
                IsPaused ? this.props.onActiveAd(ad.id) : this.props.onPauseAd(ad.id);
            },
            tooltip: IsPaused ? 'Resume Ad' : 'Pause Ad',
        };
    }

    getPropertyButtonVisibleAd(ad: any): any {
        const IsHidden = ad.feature == null;
        return {
            onClick: () => {
                this.featureChange(ad.id, IsHidden ? 1 : null);
            },
            tooltip: ad.feature == null ? 'Show on Landing page' : 'Hide from Landing page',
        };
    }

    goCustomSearch = async (ad) => {
        if (ad.approval !== 'rejected') {
            // Extract hashtags from caption
            let hashtagList = this.props.ad.content_id.data.caption
                ? this.props.ad.content_id.data.caption.match(/#\w+/g)
                : [];
            const hasHashtags = hashtagList && hashtagList.length > 0;
            hasHashtags ? (hashtagList = hashtagList.map((h) => h.slice(1))) : (hashtagList = []);

            const adID = this.props?.ad?.id;
            localStorage.setItem('selected_ad', `${adID}`);

            await RootStore.adStore.getAdByIdInfo(+adID);
            const adInfo = JSON.parse(localStorage.getItem('ad_info'));
            const { limit_country, limit_gender, invite_only } = adInfo;
            await RootStore.fetchStaticData();

            const renderGender = () => {
                let gender = { label: 'All', value: null };
                if (limit_gender === 'male') {
                    gender = { label: 'Male', value: 'male' };
                } else if (limit_gender === 'female') {
                    gender = { label: 'Female', value: 'female' };
                } else {
                    gender = { label: 'All', value: null };
                }
                return gender;
            };
            const renderCountry = () => {
                const countriesList = CountriesList || [];
                const name = countriesList?.find((li) => li.id === limit_country[0])?.name;
                const id = countriesList?.find((li) => li.id === limit_country[0])?.id;

                let country: any = '';
                if (name && id) {
                    country = {
                        label: name,
                        value: id,
                    };
                } else {
                    country = '';
                }
                return country;
            };
            const data = {
                hashtags: hashtagList,
                country: renderCountry(),
                gender: renderGender(),
                inviteOnly: invite_only,
            };
            const initialSearchObj = {
                country: '',
                hashtags: [],
                categories: [],
                followers: {
                    min: 5,
                    max: 1000,
                },
                language: '',
                gender: { label: 'All', value: null },
                inviteOnly: undefined,
                sorts: [
                    {
                        key: 'followers',
                        direction: SortOrder.DESCENDING,
                    },
                ],
            };
            await RootStore.discoverInfluencersStore.setSearchObj({ ...initialSearchObj, ...data });

            this.props.history.push({
                pathname: `/app/influencer-explorer/custom-search`,
                // state: {
                //     adId: adID,
                //     name: hasHashtags ? 'hashtag' : undefined,
                //     value: hasHashtags ? hashtagList.splice(0, 7) : undefined,
                //     from: 'ad'
                // },
            });
        }
    };

    render() {
        const { ad } = this.props;

        return (
            <>
                {this.isAdmin && this.state.showInvitationsModal ? (
                    <AdsInvitationsList
                        isOpen={this.state.showInvitationsModal}
                        onClose={this.toggleInvitationsModal}
                        ad={ad}
                        {...this.props}
                    />
                ) : null}

                {this.isAdmin && this.state.showOfferModal ? (
                    <AdsOffersList
                        isOpen={this.state.showOfferModal}
                        onClose={this.toggleOffersModal}
                        ad={ad}
                        {...this.props}
                    />
                ) : null}

                <tr className="adCardAdTab">
                    <td className="adCardAdTab__content">
                        {ad?.content_id && (
                            <LazyLoadImage
                                effect={'blur'}
                                alt="Post"
                                className={classNames({
                                    adCardAdTab__story: ad.content_id.type === 'story',
                                })}
                                src={ad.content_id.getPreviewImage(190, 190)}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = ProfilePlaceholder;
                                }}
                            />
                        )}

                        <div>
                            <div>
                                <h3 className="mb-0">{truncate(ad.title, { length: 200 })}</h3>
                                {ad.campaign_id && (
                                    <p>
                                        Campaign: <span>{truncate(ad.campaign_id.title, { length: 18 })}</span>
                                    </p>
                                )}
                                {this.isAdmin ? <p>created at : {moment(ad.created_at).format('DD-MM-YYYY')}</p> : null}
                            </div>
                            {ad.status === 'draft' ? (
                                <div className="adCardAdTab__draft">
                                    <EditIcon />
                                    <p>Draft</p>
                                </div>
                            ) : (
                                <>
                                    {ad.status === 'paused' ? (
                                        <div className="adCardAdTab__paused">
                                            <PauseIcon />
                                            <p>Paused</p>
                                            {ad.invite_only && (
                                                <>
                                                    <span
                                                        data-tip
                                                        data-for="invite-only"
                                                        className="invite-only"
                                                    ></span>
                                                </>
                                            )}
                                        </div>
                                    ) : ad.approval === 'approved' ? (
                                        <div className="adCardAdTab__approved">
                                            <TickIcon />
                                            <p>Approved</p>
                                            {ad.invite_only && (
                                                <>
                                                    <span
                                                        data-tip
                                                        data-for="invite-only"
                                                        className="invite-only"
                                                    ></span>
                                                </>
                                            )}
                                        </div>
                                    ) : ad.approval === 'pending' ? (
                                        <div className="adCardAdTab__pending">
                                            <HourGlassIcon />
                                            <p>
                                                <span>Pending Approval</span>
                                            </p>
                                            {ad.invite_only && (
                                                <>
                                                    <span
                                                        data-tip
                                                        data-for="invite-only"
                                                        className="invite-only"
                                                    ></span>
                                                </>
                                            )}
                                        </div>
                                    ) : ad.approval === 'rejected' ? (
                                        <>
                                            {/* <div className="adCardAdTab__rejected">
                                                <CloseIcon />
                                                <div>
                                                    <span>Disapproved By AINF</span>
                                                </div>
                                            </div> */}

                                            <Badge color="danger" className="adStatusBadge">
                                                <span className="simple-icon-ban pr-1" /> Disapproved By AINF
                                            </Badge>

                                            <span>
                                                <InformationIcon
                                                    fill="blue"
                                                    height="20px"
                                                    data-tip
                                                    data-for={`AdrejectedMsg_${ad.id}`}
                                                    color="primary"
                                                />
                                                <ReactTooltip
                                                    id={`AdrejectedMsg_${ad.id}`}
                                                    className="AdsMsg"
                                                    type="dark"
                                                    effect="solid"
                                                >
                                                    <p>
                                                        <b>Reason :</b>
                                                        <br />
                                                        {ad.rejection_reason}
                                                    </p>
                                                </ReactTooltip>
                                            </span>
                                        </>
                                    ) : ad.status === 'expired' ? (
                                        <div className="adCardAdTab__expired">
                                            <RedBackClockIcon />
                                            <p>
                                                <span>Expired</span>
                                                <Link to="/">Read Message</Link>
                                                <Button
                                                    color="link"
                                                    onClick={(event: React.MouseEvent) => this.props.onDelete(ad.id)}
                                                >
                                                    Delete this Ad
                                                </Button>
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="adCardAdTab__approved">
                                            <TickIcon />
                                            <p>Active</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        {/* {ad.approval === 'rejected' && } */}
                    </td>
                    <td className="adCardAdTab__overview">
                        {this.isAdmin && ad.invitation_count > 0 ? (
                            <p className="pointer" onClick={this.toggleInvitationsModal}>
                                <Invitation2Icon />
                                {ad.invitation_count}
                            </p>
                        ) : (
                            <p
                                className={`${
                                    ad.approval === 'rejected' || ad.approval === 'pending' ? 'item-check' : null
                                } `}
                                onClick={() => ad.status !== 'draft' && this.goCustomSearch(ad)}
                            >
                                <Invitation2Icon style={{ marginLeft: '7px' }} />
                                {this.props.ad.invitations_count || 0}
                                <span className="p-1"></span>
                            </p>
                        )}
                    </td>
                    <td className="adCardAdTab__overview">
                        {ad.offer_count > 0 && !this.isAdmin ? (
                            <Link
                                to="/app/offers"
                                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                                    event.preventDefault();
                                    this.props.history.push({
                                        pathname: '/app/offers',
                                        state: {
                                            adId: this.props.ad.id,
                                        },
                                    });
                                }}
                            >
                                <OfferIcon />
                                {ad.getTotalOffers()}
                            </Link>
                        ) : ad.offer_count > 0 && this.isAdmin ? (
                            <div className="pointer" onClick={this.toggleOffersModal.bind(this)}>
                                <OfferIcon />
                                {ad.offer_count}
                            </div>
                        ) : (
                            <div>
                                <OfferIcon />
                                {ad.getTotalOffers()}
                            </div>
                        )}
                    </td>
                    <td className="adCardAdTab__overview">
                        {ad.paid_offers_count > 0 && !this.isAdmin ? (
                            <Link
                                to="/app/offers/deals"
                                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                                    event.preventDefault();
                                    this.props.history.push({
                                        pathname: '/app/offers/deals',
                                        state: {
                                            adId: this.props.ad.id,
                                        },
                                    });
                                }}
                            >
                                <DealIcon />
                                {ad.getTotalDeals()}
                            </Link>
                        ) : ad.paid_offers_count > 0 && this.isAdmin ? (
                            <div className="pointer">
                                <DealIcon />
                                {ad.paid_offers_count}
                            </div>
                        ) : (
                            <div>
                                <DealIcon />
                                {ad.getTotalDeals()}
                            </div>
                        )}
                    </td>
                    <td className="adCardAdTab__overview">
                        <div>
                            <ViewIcon />
                            {ad.impressions}
                        </div>
                    </td>
                    {this.isAdmin && (
                        <td className="adCardAdTab__overview">
                            <div>
                                <DollarsSpentIcon />
                                {ad.dollars_spent}
                            </div>
                        </td>
                    )}
                    {!this.isAdmin && (
                        <>
                            <td>
                                <div className="adCardAdTab__actions-btns">
                                    <div className="d-flex justify-content-center">
                                        <FormikSwitch
                                            disabled={this.state.disabled}
                                            name="permanent"
                                            className={`custom-switch custom-switch-primary ${
                                                ad.approval === 'rejected' || ad.approval === 'pending'
                                                    ? 'item-check'
                                                    : null
                                            }`}
                                            refs={{ 'data-tip': 'Invite Only Ad' }}
                                            value={this.props.ad.invite_only}
                                            onBlur={() => ad.status !== 'draft' && this.props.inviteOnlyHandle(ad.id)}
                                            onChange={() => ad.invite_only === false}
                                        />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="adCardAdTab__actions-btns">
                                    <div className="d-flex justify-content-center ">
                                        <FormikSwitch
                                            disabled={ad.approval === 'rejected' || ad.approval === 'pending'}
                                            className={`custom-switch custom-switch-primary ${
                                                ad.approval === 'rejected' || ad.approval === 'pending'
                                                    ? 'item-check'
                                                    : null
                                            }`}
                                            refs={{
                                                'data-for': 'btnStatus',
                                                'data-tip': this.getPropertyButtonStatusAd(ad).tooltip,
                                            }}
                                            value={ad.status === 'paused'}
                                            onBlur={this.getPropertyButtonStatusAd(ad).onClick}
                                            onChange={() => {}}
                                        />
                                    </div>
                                </div>
                            </td>
                        </>
                    )}
                    {this.isAdmin && (
                        <td>
                            <div className="adCardAdTab__actions-btns">
                                <div className="d-flex justify-content-center ">
                                    <FormikSwitch
                                        disabled={ad.approval === 'rejected' || ad.approval === 'pending'}
                                        className={`custom-switch custom-switch-primary ${
                                            ad.approval === 'rejected' || ad.approval === 'pending'
                                                ? 'item-check'
                                                : null
                                        }`}
                                        refs={{ 'data-tip': this.getPropertyButtonVisibleAd(ad).tooltip }}
                                        value={ad.status === 'paused'}
                                        onBlur={this.getPropertyButtonVisibleAd(ad).onClick}
                                        onChange={() => {}}
                                    />
                                </div>
                            </div>
                        </td>
                    )}

                    <td className="adCardAdTab__actions">
                        <div className="adCardAdTab__actions-btns opened">
                            {!this.isAdmin && (
                                <>
                                    <div
                                        className="adCardAdTab__actions_settings"
                                        data-tip
                                        data-for="Control Offer"
                                        color="warning"
                                        onClick={this.props.controlOfferModal}
                                    >
                                        <img
                                            alt="gear icon"
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABNUlEQVRIibWVTU7DMBCFP1qJDeUQrYTgACCxLN71JF1EqrpFZc1t2gXpCQIr4Dz9k1DYTCR3Oo6nCjzpSUn83hvHiT3gQwXUipXTm0Uf2BgFtjLWGXdGeMPbnLkXXV8CI0MzafFbYyPJOsGrzOoLmAGPwIcxa8130c7EW0vWSdWtI8zLPXATF1j9YXjDVRMeHOI3YAxcCZ+A0uELAEVG9KzXM8Ii4y0a4RQ4JGaeg/UmB8k8QgB2Sjh2FNBLvJNnJvSOHTgKXCvPJh7sWY4zcaHu61SBYBR8cBS4V/d9jCVKfeTSUWBt+I4+cu43XbSEv2S8Bfg2Wim6gTAkZm5uNIClQ3wul/GrDvnnww7s49pqlZoVjuMa0g1n3hI+N/TJhpNCp5bpQaem7zkqfoBv4/mnjLXiF5CfCtFi5/niAAAAAElFTkSuQmCC"
                                        />
                                    </div>
                                    <ReactTooltip id="Control Offer" type="dark" effect="solid">
                                        <p>Advanced Settings</p>
                                    </ReactTooltip>
                                </>
                            )}

                            <UncontrolledDropdown>
                                <DropdownToggle color="secondary" style={{ padding: '8px' }}>
                                    <ThreeDotIcon />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {!this.isAdmin && (
                                        <DropdownItem href={`/app/ads/ad/objectives/${ad.id}`}>
                                            <span className="simple-icon-pencil pr-2" /> Edit Ad
                                        </DropdownItem>
                                    )}
                                    <DropdownItem onClick={() => this.props.openModal(ad)}>
                                        <span className="simple-icon-eye pr-2" /> Preview Ad
                                    </DropdownItem>
                                    <DropdownItem onClick={() => this.copyToClipboard()}>
                                        <span className="simple-icon-link pr-2" /> Copy Ad URL
                                    </DropdownItem>
                                    {!this.isAdmin && (
                                        <DropdownItem onClick={() => this.props.duplicateAd(ad.id)}>
                                            <span className="simple-icon-docs pr-2" /> Duplicate Ad
                                        </DropdownItem>
                                    )}
                                    {this.isAdmin && (ad.approval === 'pending' || ad.approval === 'rejected') && (
                                        <DropdownItem
                                            onClick={() => this.approveAd(ad.id)}
                                            style={{ backgroundColor: 'darkseagreen' }}
                                        >
                                            <span className="simple-icon-check pr-2" /> Approve Ad
                                        </DropdownItem>
                                    )}
                                    {this.isAdmin && (ad.approval === 'pending' || ad.approval === 'approved') && (
                                        <DropdownItem
                                            onClick={() => this.props.toggleReject(ad.id)}
                                            style={{ backgroundColor: 'antiquewhite' }}
                                        >
                                            <span className="simple-icon-ban pr-2" /> Reject Ad
                                        </DropdownItem>
                                    )}
                                    <DropdownItem
                                        onClick={() => this.props.onDelete(ad.id)}
                                        style={{ backgroundColor: 'pink' }}
                                    >
                                        <span className="simple-icon-trash pr-2" /> Delete Ad
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            {/* <Link
                                data-tip
                                data-for="Edit"
                                color="secondary"
                                to={this.isAdmin ? `/admin/ads-management` : `/app/ads/ad/objectives/${ad.id}`}
                            >
                                <EditIcon />
                            </Link>

                            <ReactTooltip id="Edit" type="dark" effect="solid">
                                <p>Edit</p>
                            </ReactTooltip>

                            <Button
                                data-tip
                                data-for="Preview"
                                color="primary"
                                onClick={() => this.props.openModal(ad)}
                            >
                                <EyeIcon />
                            </Button>
                            <ReactTooltip id="Preview" type="dark" effect="solid">
                                <p>Preview</p>
                            </ReactTooltip>

                            <MenuDropdown items={optionMenu} />

                            {RootStore.users.currentUser.is_admin ? (
                                ad.feature == null ? (
                                    <>
                                        <Button
                                            data-tip
                                            data-for="Preview1"
                                            color="primary"
                                            onClick={() => this.featureChange(ad.id, 1)}
                                        >
                                            Show
                                        </Button>
                                        <ReactTooltip id="Preview1" type="dark" effect="solid">
                                            <p>Show in Landing page</p>
                                        </ReactTooltip>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            data-tip
                                            data-for="Preview2"
                                            color="success"
                                            onClick={() => this.featureChange(ad.id, null)}
                                        >
                                            Hide
                                        </Button>
                                        <ReactTooltip id="Preview2" type="dark" effect="solid">
                                            <p>Hide from Landing page</p>
                                        </ReactTooltip>
                                    </>
                                )
                            ) : (
                                ''
                            )}

                            {this.isAdmin ? (
                                <>
                                    {ad.approval === 'pending' || ad.approval === 'approved' ? (
                                        <>
                                            <Button
                                                data-tip
                                                data-for="reject"
                                                color="warning"
                                                onClick={() => this.props.toggleReject(ad.id)}
                                            >
                                                <RejectIcon />
                                            </Button>
                                            <ReactTooltip id="reject" type="dark" effect="solid">
                                                <p>Reject Ad</p>
                                            </ReactTooltip>
                                        </>
                                    ) : null}
                                    {ad.approval === 'pending' || ad.approval === 'rejected' ? (
                                        <>
                                            <Button
                                                data-tip
                                                data-for="approve"
                                                color="success"
                                                onClick={() => this.approveAd(ad.id)}
                                            >
                                                <TickIcon />
                                            </Button>
                                            <ReactTooltip id="approve" type="dark" effect="solid">
                                                <p>Approve Ad</p>
                                            </ReactTooltip>
                                        </>
                                    ) : null}
                                </>
                            ) : null} */}
                        </div>
                    </td>
                </tr>
                <tr className="adCardAdTab__spacer" />
            </>
        );
    }
}
