import React from "react";
// import { RouteComponentProps } from "react-router-dom";
// import ContentLoader from "react-content-loader";

import RootStore from "../../../../../store/RootStore";
import OffersOverview from "./OffersOverview";
import { IOfferModel } from "../../../../../store/models/OfferModel";
import AdsOverview from "./AdsOverview";
import InvitationsOverview from "./InvitationsOverview";
import { observer } from "mobx-react";
// import OverviewPlaceholder from "./OverviewPlaceholder";

// interface Props extends RouteComponentProps {}

/**
 * this is function component shows ads, offers, invitations sent number
 */


function Overview(props) {
  return (
    <div className="kpiList">
      {/* // ? (
        // <>
        //   <OverviewPlaceholder />
        //   <OverviewPlaceholder />
        //   <OverviewPlaceholder />
        // </>
        // ) : ( */}
      <>
      <AdsOverview
          totalAds={RootStore.users.currentUser.ad_count}
          totalCampaigns={RootStore.users.currentUser.campaign_count}
          {...props}
        />
        <OffersOverview
          totalOffers={RootStore.users.currentUser.offer_count}
          totalPending={
            RootStore.offerStore.offers.filter(
              (offer: IOfferModel) => offer.status === "pending"
            ).length
          }
          totalWaiting={
            RootStore.offerStore.offers.filter(
              (offer: IOfferModel) => offer.status === "awaiting-payment"
            ).length
          }
          {...props}
        />
        
        <InvitationsOverview
          totalInvitations={RootStore.users.currentUser.sent_invitation_count}
          {...props}
        />
      </>
    </div>
  );
}

export default observer(Overview);
