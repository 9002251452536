import React from "react";
import {
  IMessageModel,
  MessageType,
} from "../../../../../store/models/MessageModel";
import { observer } from "mobx-react";
import { contactSupportLink } from "../../../../../constants/defaultValues";
import { ucfirst } from "../../../../../lib/Utils";
import RootStore from "../../../../../store/RootStore";
import classNames from "classnames";

type Props = {
  msg: IMessageModel;
  offerButtons: Function;
  postData?: any
};

@observer
export default class BubbleFooter extends React.Component<Props, any> {
  getCallToActions(): JSX.Element {
    if (this.props.msg.offerButtonsVisible()) {
      return this.props.offerButtons();
    } else {
      if (this.props.msg.getType() === MessageType.PUBLISH_JOB) {
        if (
          this.props.msg.attributes.type === "publish-job:publishing-failed" ||
          this.props.msg.attributes.type === "publish-job:completion-failed"
        ) {
          return (
            <a
              href={contactSupportLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Support
            </a>
          );
        }
      }
      if (
        this.props.msg.isLatestVersion() &&
        this.props.msg.getMainOffer().getLatestPublishJob() !== null
      ) {
        return (
          <a
            href={contactSupportLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Support
          </a>
        );
      }
      return <></>;
    }
  }

  getText(): JSX.Element {
    if (this.props.msg.offerButtonsVisible()) {
      if (this.props.msg.getStatus() === "rejected") {
        return (
          <>
            {ucfirst(this.props.msg.getAction())} by&nbsp;
            <b>
              {this.props.msg.getReactor().user_id ===
              RootStore.users.currentUser.id
                ? "You"
                : this.props.msg.getReactor().name}
            </b>
            &nbsp; {this.props.msg.getMoment().fromNow()}
          </>
        );
      } else if (this.props.msg.getStatus() === "awaiting-payment") {
        return (
          <>
            Accepted by&nbsp;
            <b>
              {this.props.msg.getReactor().user_id ===
              RootStore.users.currentUser.id
                ? "You"
                : this.props.msg.getReactor().name}
            </b>
            &nbsp; {this.props.msg.getMoment().fromNow()}
          </>
        );
      } else {
        return <>{this.props.msg.getMoment().fromNow()}</>;
      }
    } else {
      if (
        this.props.msg.isLatestVersion() &&
        this.props.msg.getMainOffer().getLatestPublishJob() !== null
      ) {
        // This only happens on failure
        return (
          <>
            An error was detected on the connection between @
            {this.props.msg.getOffer().getTargetIntegration().username} and
            AInfluencer!
          </>
        );
      } else {
        if (this.props.msg.getStatus() === "rejected") {
          return (
            <>
              {ucfirst(this.props.msg.getAction())} by&nbsp;
              <b>
                {this.props.msg.getReactor().user_id ===
                RootStore.users.currentUser.id
                  ? "You"
                  : this.props.msg.getReactor().name}
              </b>
              &nbsp; {this.props.msg.getMoment().fromNow()}
            </>
          );
        }
        if (this.props.msg.getStatus() === "accepted") {
          return (
            <>
              Accepted by&nbsp;
              <b>
                {this.props.msg.getReactor().user_id ===
                RootStore.users.currentUser.id
                  ? "You"
                  : this.props.msg.getReactor().name}
              </b>
              &nbsp; {this.props.msg.getMoment().fromNow()}
            </>
          );
        }
      }
    }
  }

  render() {
    return (
      <div className={"footer"}>
        <div className={"left"}>{this.getText()}</div>
        <div className="offerCard__status">
          <span
            className={classNames([
              "bg-" + this.props.msg.getOffer().getStatusColor(),
            ])}
          >
            {this.props.msg.getOffer().getHumanReadableStatus()}
          </span>
        </div>
        <div className={"right"}>{this.getCallToActions()}</div>
      </div>
    );
  }
}
