import * as React from "react";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router";
import { Button, Card, CardBody, FormGroup, Label } from "reactstrap";
import { observer } from "mobx-react";
import { Form, Formik } from "formik";

import { Separator } from "../../../../template/components/common/CustomBootstrap";
import {
  FormikDatePicker,
  FormikReactSelect,
  FormikSwitch,
  BreadCrumb,
  FormikDuration,
} from "../../../common-components";
import RootStore from "../../../../store/RootStore";
import ContentSelectFormik from "./ContentSelectFormik";
import {
  ajaxErrorAlert,
  handleError,
  momentDateTimeFormat,
} from "../../../../lib/Utils";
import { IUserModel } from "../../../../store/models/UserModel";
import { IIntegrationModel } from "../../../../store/models/IntegrationModel";
import { NotificationManager } from "../../../../template/components/common/react-notifications";

interface Props extends RouteComponentProps<{ id?: string }> {}

const ScheduleSchema = Yup.object().shape({
  content: Yup.string().nullable().required("Select a content"),
  date: Yup.date()
    .nullable()

    .when(["immediately"], (immediately: boolean, schema: any) => {
      if (!immediately) {
        return schema
          .required("Date required")
          .min(new Date(), "You cant select day before today");
      }
      return schema;
    }),
  immediately: Yup.boolean().required(),
  integration: Yup.string().nullable().required("Select an IG"),
  removeInFuture: Yup.bool(),
  duration: Yup.number()
    .nullable()
    .when(["removeInFuture"], (removeInFuture: boolean, schema: any) => {
      if (removeInFuture) {
        return schema.min(3600);
      } else {
        return schema;
      }
    }),
});

@observer
export default class ScheduleContentForm extends React.Component<Props> {
  async onSubmit(values: any): Promise<any> {
    if (!values.immediately) {
      values.start = values.date.format(momentDateTimeFormat);
    }

    if (!values.removeInFuture) {
      values.duration = 0;
    }
    values.content_id = values.content.id;
    values.identity_id = (
      RootStore.users.currentUser as IUserModel
    ).getIdentities()[0].id;

    values.integration_id = values.integration.value;

    try {
      await RootStore.publishJobStore.create(values);
      this.props.history.push("/app/scheduler");
      NotificationManager.success(
        "Done",
        "Content was successfully scheduled for release!",
        3000,
        null,
        null,
        "filled"
      );
    } catch (e) {
      handleError(e);
      ajaxErrorAlert("Could not schedule content!");
    }
  }

  render() {
    return (
      <>
        <BreadCrumb
          heading="Schedule Content"
          optionalProperties={
            this.props.match.params.id && this.props.match.params.id.length
              ? [
                  {
                    id: ":id",
                    value: this.props.match.params.id,
                    title: `@${
                      RootStore.integrationStore.getById(
                        parseInt(this.props.match.params.id)
                      ).username
                    }`,
                  },
                ]
              : undefined
          }
          {...this.props}
        />
        <Separator className="mb-5" />
        <Card>
          <CardBody>
            <Formik
              initialValues={{
                immediately: false,
                date: null,
                date2: null,
                removeInFuture: false,
                content: null,
                integration:
                  this.props.match.params.id &&
                  this.props.match.params.id.length
                    ? {
                        value: parseInt(this.props.match.params.id),
                        label:
                          "@" +
                          RootStore.integrationStore.getById(
                            parseInt(this.props.match.params.id)
                          ).username,
                      }
                    : null,
                duration: 0,
              }}
              validationSchema={ScheduleSchema}
              onSubmit={this.onSubmit.bind(this)}
            >
              {({
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form
                  translate={"no"}
                  className="av-tooltip tooltip-label-right panel-form scheduleContentSelect"
                >
                  <FormGroup className="error-l-100">
                    <Label className=" position-relative">
                      Content:
                      {errors.content && touched.content && (
                        <div className="invalidFeedback">{errors.content}</div>
                      )}
                    </Label>
                  </FormGroup>
                  <FormGroup
                    className="error-l-100"
                    style={{ minHeight: "13em" }}
                  >
                    <ContentSelectFormik
                      {...this.props}
                      name="content"
                      value={values.content}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="error-l-100">
                    <Label className="d-block position-relative">
                      Post immediately?
                      {errors.immediately && touched.immediately && (
                        <div className="invalidFeedback">
                          {errors.immediately}
                        </div>
                      )}
                    </Label>
                    <FormikSwitch
                      name="immediately"
                      className="custom-switch custom-switch-primary"
                      value={values.immediately}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>

                  {!values.immediately ? (
                    <FormGroup className="error-l-100">
                      <Label className="position-relative">
                        Publish Date:
                        {errors.date && touched.date && (
                          <div className="invalidFeedback">{errors.date}</div>
                        )}
                      </Label>
                      <FormikDatePicker
                        name="date"
                        value={values.date}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        // @ts-ignore
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="LLL"
                        timeCaption="Time"
                      />
                    </FormGroup>
                  ) : null}
                  <FormGroup className="error-l-100">
                    <Label className="d-block position-relative">
                      Remove in future:
                      {errors.removeInFuture && touched.removeInFuture && (
                        <div className="invalidFeedback">
                          {errors.removeInFuture}
                        </div>
                      )}
                    </Label>
                    <FormikSwitch
                      name="removeInFuture"
                      className="custom-switch custom-switch-primary"
                      value={values.removeInFuture}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>

                  {values.removeInFuture ? (
                    <FormGroup className="error-l-100">
                      <Label className="position-relative">
                        Remove After:
                        {errors.duration && touched.duration && (
                          <div className="invalidFeedback">
                            {errors.duration}
                          </div>
                        )}
                      </Label>
                      <FormikDuration
                        className={"publish-job-duration"}
                        gridRatio={4}
                        name={"duration"}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                  ) : null}

                  <FormGroup className="error-l-100">
                    <Label className="position-relative">
                      Instagram:
                      {errors.integration && touched.integration && (
                        <div className="invalidFeedback">
                          {errors.integration}
                        </div>
                      )}
                    </Label>
                    <FormikReactSelect
                      name="integration"
                      id="integration"
                      value={values.integration ?? null}
                      isMulti={false}
                      options={RootStore.integrationStore
                        .getUsableIntegrations()
                        .map((integration: IIntegrationModel) => {
                          return {
                            value: integration.id,
                            label: "@" + integration.username,
                          };
                        })}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>

                  <Button color="success" type="submit" disabled={isSubmitting}>
                    {values.immediately ? "Publish Now" : "Schedule"}
                  </Button>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </>
    );
  }
}
