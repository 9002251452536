import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TabsComponent } from '../../common-components';
import WalletReport from './components';
import Transactions from './../transactions';

interface Props extends RouteComponentProps {}

export default class PaymentReport extends Component<Props> {
    render() {
        return (
            <>
                {/* <Row>
                    <Colxx xxs="12">
                        <BreadCrumb heading="Payouts | Payments | Transactions" {...this.props} />
                    </Colxx>
                </Row> */}
                <TabsComponent
                    tabs={[
                        {
                            tabName: 'Payout',
                            component: <WalletReport key="walletReportPayout" {...this.props} />,
                            to: `${this.props.match.url}`,
                        },
                        {
                            tabName: 'Payment',
                            component: <WalletReport key="walletReportPayment" {...this.props} />,
                            to: `${this.props.match.url}/payment`,
                        },
                        {
                            tabName: 'Transactions',
                            component: <Transactions key="transactionss" {...this.props} />,
                            to: `${this.props.match.url}/transactions`,
                        },
                    ]}
                    {...this.props}
                />
            </>
        );
    }
}
