import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
// @ts-ignore
import Delayed from 'react-delayed';
// @ts-ignore
import ReactTooltip from 'react-tooltip';
import { observer } from 'mobx-react';
import { truncate } from 'lodash';

import RootStore from '../../store/RootStore';
import { changeLocale, clickOnMobileMenu, setContainerClassnames } from '../../template/redux/actions';
import { menuHiddenBreakpoint, searchPath } from '../../constants/defaultValues';
import { MenuIcon, MobileMenuIcon } from '../../template/components/svg';
import TopnavNotifications from './TopnavNotification';
import { getDirection, setDirection } from '../../template/helpers/Utils';
import { IUserModel } from '../../store/models/UserModel';
import { AnimatedLogo } from './AnimatedLogo';
import { showLoading } from '../../lib/Utils';
import { IIdentityModel } from '../../store/models/IdentityModel';
import { isStaging } from '../../constants/defaultValues';

interface Props {
    changeLocale: Function;
    setContainerClassnames: Function;
    clickOnMobileMenu: Function;
    selectedMenuHasSubItems: boolean;
    containerClassnames: any;
    menuClickCount: number;
    locale: string;
    intl: any;
    history: any;
}

interface StateProps {
    searchKeyword: string;
    isInFullScreen: boolean;
}

@observer
class TopNav extends Component<Props, StateProps> {
    private user: IUserModel;
    private identity: IIdentityModel;
    private lottie: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            isInFullScreen: false,
            searchKeyword: '',
        };

        this.user = RootStore.users.currentUser as IUserModel;
        this.identity = RootStore.users.getDefaultIdentity();
    }

    handleChangeLocale = (locale: string, direction: string) => {
        this.props.changeLocale(locale);

        const currentDirection = getDirection().direction;
        if (direction !== currentDirection) {
            setDirection(direction);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
    };

    isInFullScreen = () => {
        return (
            (document.fullscreenElement && document.fullscreenElement !== null) ||
            // @ts-ignore
            (document.webkitFullscreenElement &&
                // @ts-ignore
                document.webkitFullscreenElement !== null) ||
            // @ts-ignore
            (document.mozFullScreenElement &&
                // @ts-ignore
                document.mozFullScreenElement !== null) ||
            // @ts-ignore
            (document.msFullscreenElement && document.msFullscreenElement !== null)
        );
    };

    handleSearchIconClick = (e: any) => {
        if (window.innerWidth < menuHiddenBreakpoint) {
            let elem = e.target;
            if (!e.target.classList.contains('search')) {
                if (e.target.parentElement.classList.contains('search')) {
                    elem = e.target.parentElement;
                } else if (e.target.parentElement.parentElement.classList.contains('search')) {
                    elem = e.target.parentElement.parentElement;
                }
            }

            if (elem.classList.contains('mobile-view')) {
                this.search();
                elem.classList.remove('mobile-view');
                this.removeEventsSearch();
            } else {
                elem.classList.add('mobile-view');
                this.addEventsSearch();
            }
        } else {
            this.search();
        }
    };

    handleDocumentClickSearch = (e: any) => {
        let isSearchClick = false;
        if (
            e.target &&
            e.target.classList &&
            (e.target.classList.contains('navbar') || e.target.classList.contains('simple-icon-magnifier'))
        ) {
            isSearchClick = true;
            if (e.target.classList.contains('simple-icon-magnifier')) {
                this.search();
            }
        } else if (
            e.target.parentElement &&
            e.target.parentElement.classList &&
            e.target.parentElement.classList.contains('search')
        ) {
            isSearchClick = true;
        }

        if (!isSearchClick) {
            const input = document.querySelector('.mobile-view');
            if (input && input.classList) input.classList.remove('mobile-view');
            this.removeEventsSearch();
            this.setState({
                searchKeyword: '',
            });
        }
    };

    addEventsSearch = () => {
        document.addEventListener('click', this.handleDocumentClickSearch as any, true);
    };

    removeEventsSearch = () => {
        document.removeEventListener('click', this.handleDocumentClickSearch as any, true);
    };

    handleSearchInputChange = (e: any) => {
        this.setState({
            searchKeyword: e.target.value,
        });
    };

    handleSearchInputKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            this.search();
        }
    };

    search = () => {
        this.props.history.push(searchPath + '/' + this.state.searchKeyword);
        this.setState({
            searchKeyword: '',
        });
    };

    toggleFullScreen = () => {
        const isInFullScreen = this.isInFullScreen();

        var docElm = document.documentElement;
        if (!isInFullScreen) {
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
                // @ts-ignore
            } else if (docElm.mozRequestFullScreen) {
                // @ts-ignore
                docElm.mozRequestFullScreen();
                // @ts-ignore
            } else if (docElm.webkitRequestFullScreen) {
                // @ts-ignore
                docElm.webkitRequestFullScreen();
                // @ts-ignore
            } else if (docElm.msRequestFullscreen) {
                // @ts-ignore
                docElm.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                // @ts-ignore
            } else if (document.webkitExitFullscreen) {
                // @ts-ignore
                document.webkitExitFullscreen();
                // @ts-ignore
            } else if (document.mozCancelFullScreen) {
                // @ts-ignore
                document.mozCancelFullScreen();
                // @ts-ignore
            } else if (document.msExitFullscreen) {
                // @ts-ignore
                document.msExitFullscreen();
            }
        }
        this.setState({
            isInFullScreen: !isInFullScreen,
        });
    };

    handleLogout = () => {
        showLoading('Logging out...');
        localStorage.clear();
        RootStore.users.logOut();
    };

    menuButtonClick = (e: React.MouseEvent, menuClickCount: number, containerClassnames: any) => {
        e.preventDefault();

        setTimeout(() => {
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);
        this.props.setContainerClassnames(++menuClickCount, containerClassnames, this.props.selectedMenuHasSubItems);
    };

    mobileMenuButtonClick = (e: any, containerClassnames: any) => {
        e.preventDefault();
        this.props.clickOnMobileMenu(containerClassnames);
    };

    render() {
        const {
            containerClassnames,
            menuClickCount,
            // ,locale
        } = this.props;
        // const { messages } = this.props.intl;
        return (
            <nav className="navbar fixed-top">
                <div className="d-flex align-items-center navbar-left">
                    <NavLink
                        to="#"
                        className="menu-button d-none d-md-block"
                        onClick={(e) => this.menuButtonClick(e, menuClickCount, containerClassnames)}
                    >
                        <MenuIcon />
                    </NavLink>
                    <NavLink
                        to="#"
                        className="menu-button-mobile d-xs-block d-sm-block d-md-none"
                        onClick={(e) => this.mobileMenuButtonClick(e, containerClassnames)}
                    >
                        <MobileMenuIcon />
                    </NavLink>
                </div>
                <div className="navbarLogo">
                    <a
                        className="navbar-logo"
                        href={isStaging() ? 'https://app-staging.ainfluencer.com' : 'https://ainfluencer.com'}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Delayed mounted={true} mountAfter={500}>
                            <AnimatedLogo widthRatio={0.2} heightRatio={0.2} loop={false} speed={1000} />
                        </Delayed>
                    </a>
                    {isStaging() ? (
                        <>
                            <span data-tip data-for="stagingLevelLabel" className="navbarLogo__stagingLabel">
                                Staging
                            </span>
                            <ReactTooltip id="stagingLevelLabel" type="dark" effect="solid" place="bottom">
                                <p>
                                    <b>Staging</b> environments are made to test codes, builds, and updates to ensure
                                    quality under a production-like environment before application deployment.
                                </p>
                            </ReactTooltip>
                        </>
                    ) : (
                        <span className="navbarLogo__stagingLabel">BETA</span>
                    )}
                </div>

                <div className="d-flex justify-content-end align-items-center">
                    <div className="header-icons d-flex">
                        <TopnavNotifications />
                        {/* <button
              className="header-icon btn btn-empty "
              type="button"
              id="fullScreenButton"
              onClick={this.toggleFullScreen}
              style={{ height: "39.6px", width: "39.6px" }}>
              {this.state.isInFullScreen ? (
                <i className="simple-icon-size-actual d-block" />
              ) : (
                <i className="simple-icon-size-fullscreen d-block" />
              )}
            </button> */}
                    </div>
                    <div className="user mr-2">
                        <UncontrolledDropdown className="dropdown-menu-right">
                            <DropdownToggle className="p-0" color="empty">
                                <span className="name mr-1 ml-3 mr-3">
                                    {this.user ? truncate(this.user.getFullName(), { length: 20 }) : null}
                                </span>
                                <span>
                                    {this.identity && this.identity.logo ? (
                                        <img alt="Profile" src={this.identity.logo.getThumbnailUrl(60, 60)} />
                                    ) : null}
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className="mt-3" right>
                                <a href="mailto:support@ainfluencer.com">
                                    <DropdownItem>
                                        <i className="simple-icon-phone"> </i> Contact us
                                    </DropdownItem>
                                </a>

                                <Link to="/app/account/edit-account">
                                    <DropdownItem>
                                        <i className="simple-icon-user"> </i> Edit Account
                                    </DropdownItem>
                                </Link>

                                <Link to="/app/account/change-password">
                                    <DropdownItem>
                                        <i className="simple-icon-key"> </i> Change Password
                                    </DropdownItem>
                                </Link>
                                <Link to="/app/account/integrations">
                                    <DropdownItem>
                                        <i className="simple-icon-login"> </i> Instagram Accounts
                                    </DropdownItem>
                                </Link>
                                <Link to="/app/account/payment">
                                    <DropdownItem>
                                        <i className="simple-icon-credit-card"> </i> Payment & Transactions
                                    </DropdownItem>
                                </Link>
                                <DropdownItem divider />
                                <DropdownItem onClick={(event: React.MouseEvent) => this.handleLogout()}>
                                    Sign out
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            </nav>
        );
    }
}

// @ts-ignore
const mapStateToProps = ({ menu, settings }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } = menu;
    const { locale } = settings;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
        locale,
    };
};
export default injectIntl(
    connect(mapStateToProps, {
        setContainerClassnames,
        clickOnMobileMenu,
        changeLocale,
    })(TopNav),
);
