import React from "react";
import { RouteComponentProps, NavLink, Switch, Route } from "react-router-dom";
import { Nav, NavItem, TabContent, TabPane } from "reactstrap";

interface Props extends RouteComponentProps {
  tabs: Array<{
    tabName: string;
    component: React.ReactNode;
    to: string;
  }>;
}

export function TabsComponent(props: Props) {
  return (
    <>
      <Nav
        tabs
        className="separator-tabs ml-0 mb-5 tabComponent__nav-tabs tabComponent w-100"
      >
        {props.tabs.map((tab, index) => (
          <NavItem key={`tab__${tab.tabName}`}>
            <NavLink exact={true} className="nav-link" to={tab.to}>
              {tab.tabName}

              {/*<Badge*/}
              {/*  style={{ verticalAlign: "top" }}*/}
              {/*  color={"danger"}*/}
              {/*  pill={true}*/}
              {/*  size={"xs"}>*/}
              {/*  {index}*/}
              {/*</Badge>*/}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <Switch>
        {props.tabs.map((tab, index) => (
          <Route
            key={index}
            path={tab.to}
            exact={true}
            render={() => (
              <TabContent>
                <TabPane key={index}>{tab.component}</TabPane>
              </TabContent>
            )}
          />
        ))}
      </Switch>
    </>
  );
}
