import {
  applySnapshot,
  flow,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import Transport from "../lib/Transport";
import { ajaxErrorAlert } from "../lib/Utils";
import {
  makeCampaignQuery,
  makePublishJobQuery,
} from "./common/RequestHelpers";
import {
  CampaignModel,
  ICampaignModel,
  ICampaignModelSnapshotIn,
} from "./models/CampaignModel";
import RootStore from "./RootStore";
import { IUserModel } from "./models/UserModel";

export const CampaignStore = types
  .model({
    campaigns: types.array(CampaignModel),
  })
  .volatile((self) => ({
    isBusy: false,
    fetchedFromServer: false,
  }))
  .actions((self) => ({

    /**
    * this method check processing for this store
    */
    setBusy() {
      self.isBusy = true;
    },

    /**
    * this method just set value isBusy = false
    */
    setIdle() {
      self.isBusy = false;
    },

    /**
   * this method push model this store
   * @param model, this param type object for get model
   */
    pushModel(model: ICampaignModel) {
      self.campaigns.push(model);
    },
    syncMissingModels(models: ICampaignModelSnapshotIn[]) {
      if (!Array.isArray(models)) {
        return;
      }
      let campaignIdList: number[] = self.campaigns.map((campaign) => {
        return campaign.id;
      });

      let possiblyUpdatedModels = models.filter(
        (model) => campaignIdList.indexOf(model.id) >= 0
      );
      if (possiblyUpdatedModels.length) {
        for (let content of self.campaigns) {
          for (let updatedContent of possiblyUpdatedModels) {
            if (content.id === updatedContent.id) {
              applySnapshot(content, updatedContent);
            }
          }
        }
      }

      self.campaigns.replace(
        self.campaigns.concat(
          models
            .filter((model) => campaignIdList.indexOf(model.id) === -1)
            .uniqueById()
        )
      );
    },
  }))
  .actions((self) => ({

    /**
   * this method get campaign by id
   * @param id, this param campaign id
   * @param forceFetch, this param if true replace get campaigns to current data campaign
   */
    getCampaignById: flow(function* (
      id: number,
      forceFetch: boolean = false
    ): Generator<any, ICampaignModel, any> {
      if (!forceFetch) {
        let results = self.campaigns.filter((cmp) => cmp.id === id);
        if (results.length) {
          return results[0];
        }
      } else {
        self.campaigns.replace(
          self.campaigns.filter((campaign) => campaign.id !== id)
        );
      }

      self.setBusy();
      let response;
      try {
        response = yield Transport.get(
          `campaigns/${id}`,
          makeCampaignQuery(true)
        );
      } catch (error) {
        self.setIdle();
        throw error;
      }
      let model = CampaignModel.create(response.data.campaign);
      let contentModels = [];
      for (let ad of response.data.campaign.ads) {
        if (ad["content"] !== null) {
          contentModels.push(ad["content"]);
        }
      }
      if (typeof response.data["ads.offers"] !== "undefined") {
        for (let offer of response.data["ads.offers"]) {
          if (offer["content"] !== null) {
            contentModels.push(offer["content"]);
          }
        }
      }

      RootStore.identityStore.syncMissingModels(
        response.data["ads.offers.identity"]
      );
      RootStore.identityStore.syncMissingModels(
        response.data["ads.offers.target_identity"]
      );
      RootStore.integrationStore.syncMissingModels(
        response.data["ads.offers.target_integration"]
      );
      RootStore.contentStore.syncMissingModels(contentModels);
      RootStore.adStore.syncMissingModels(response.data.campaign.ads);
      RootStore.offerStore.syncMissingModels(response.data["ads.offers"]);

      self.campaigns.push(model);
      self.setIdle();
      return model;
    }),


    /**
     * this method check exist campaign
     * @param id, this param id campaign for check exist
     */
    getExistingCampaign(id: number): ICampaignModel {
      let existing = self.campaigns.filter((campaign) => campaign.id === id);
      if (existing.length) {
        return existing[0];
      }

      // eslint-disable-next-line no-throw-literal
      throw "Could not find campaign " + id + " in store!";
    },

    /**
    * this method create campaign
    */
    create: flow(function* (snapshot: ICampaignModelSnapshotIn): any {
      let user = RootStore.users.currentUser as IUserModel;
      self.setBusy();

      try {
        let result = yield Transport.post(
          `identities/${user.getIdentities()[0].id}/campaigns`,
          { campaign: snapshot },
          makePublishJobQuery()
        );
        let model = CampaignModel.create(result.data["campaign"]);
        self.campaigns.push(model);
        self.setIdle();
        return model;
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),

    /**
     * this method update campaign
     * @param campaignId, this param id campaign for updated
     * @param values, this param new values for edit the campaign
     */
    updateCampaign: flow(function* (
      campaignId: number,
      values: ICampaignModelSnapshotIn
    ) {
      self.setBusy();

      try {
        const response = yield Transport.patch(`campaigns/${campaignId}`, {
          campaign: values,
        });

        let oldModel: ICampaignModel = RootStore.campaignStore.campaigns.filter(
          (campaign: any) => campaign.id === campaignId
        )[0];

        applySnapshot(oldModel, response.data.campaign);

        self.setIdle();
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),

    /**
     * this method fetch all campaign
     */
    fetchAll: flow(function* (): Generator<any, ICampaignModel[], any> {
      let user = RootStore.users.currentUser as IUserModel;
      self.setBusy();

      try {
        let response = yield Transport.get(
          `identities/${user.getIdentities()[0].id}/campaigns`,
          makeCampaignQuery()
        );

        let contentModels = [];
        let ads = [];

        for (let campaign of response.data["campaigns"]) {
          // self.campaigns.push(CampaignModel.create(campaign));

          if (campaign.ads.length > 0) {
            for (let ad of campaign.ads) {
              ads.push(ad);

              if (ad["content"] !== null) {
                contentModels.push(ad["content"]);
              }
            }
          }
        }

        RootStore.contentStore.syncMissingModels(contentModels);
        RootStore.adStore.syncMissingModels(ads);

        self.syncMissingModels(response.data["campaigns"]);

        self.setIdle();
        return self.campaigns;
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),

    /**
    * this method for remove campaign
    * @param campaignId, this param get id for remove the campaign
    */
    removeCampaign: flow(function* (campaignId: any): any {
      self.setBusy();

      try {
        yield Transport.delete(`campaigns/${campaignId}`);
        let campaigns = RootStore.campaignStore.campaigns.filter(
          (campaign: any) => campaign.id !== campaignId
        );
        self.campaigns.replace(campaigns);
        self.setIdle();
      } catch (error) {
        self.setIdle();
        throw error;
      }
    }),
  }))

  .actions((self) => ({
    /**
    * this method froce fetch all campaign
    */
    fetchAllIfNecessary: flow(function* (): Generator<
      any,
      ICampaignModel[],
      any
    > {
      if (!self.fetchedFromServer) {
        try {
          self.fetchedFromServer = true;
          return yield self.fetchAll();
        } catch (e) {
          ajaxErrorAlert("Failed to load campaigns, please refresh the page.");
          throw e;
        }
      }
      return self.campaigns;
    }),
  }))
  .views((self) => ({
    getExistingCampaigns(): ICampaignModel[] {
      return self.campaigns.filter((item) => item.deleted_at === null);
    },
  }));

export interface ICampaignStore extends Instance<typeof CampaignStore> { }
export interface ICampaignStoreSnapshotIn
  extends SnapshotIn<typeof CampaignStore> { }
export interface ICampaignStoreSnapshotOut
  extends SnapshotOut<typeof CampaignStore> { }
