import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SearchEmptyStateIcon } from "../../../../assets/icons";

interface Props extends RouteComponentProps {}

/**
 * empty , no result search
 */
export default function EmptyState(props: Props) {
  return (
    <div className="searchEmptyState">
      <SearchEmptyStateIcon />
      <br />
      <h2>No results to display.</h2>
      <p>Please refine your search using the custom search filters.</p>
    </div>
  );
}
