import React from "react";
import ContentLoader from "react-content-loader";
/**
 * function component wrapped by ContentLoader and show good experience for user
 * before data loaded
 * @returns some graphical element before data loaded
 */
export default function InfluencerCardLoader() {
  return (
    <div className="influencerCardLoader">
      <ContentLoader
        speed={3}
        width={320}
        height={368}
        viewBox="0 0 320 368"
        backgroundColor="#ededed"
        foregroundColor="#c7c7c7"
      >
        <circle cx="150" cy="55" r="45" />
        <rect x="64" y="114" rx="2" ry="2" width="175" height="10" />
        <rect x="85" y="140" rx="2" ry="2" width="130" height="5" />
        <rect x="85" y="157" rx="2" ry="2" width="130" height="5" />
        <rect x="20" y="240" rx="2" ry="2" width="75" height="15" />
        <rect x="120" y="240" rx="2" ry="2" width="75" height="15" />
        <rect x="221" y="240" rx="2" ry="2" width="75" height="15" />
        <rect x="20" y="290" rx="11" ry="11" width="89" height="25" />
        <rect x="221" y="297" rx="2" ry="2" width="75" height="7" />
        <rect x="20" y="199" rx="0" ry="0" width="272" height="3" />
      </ContentLoader>
    </div>
  );
}
