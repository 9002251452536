import React from "react";
import { RouteComponentProps } from "react-router";
import { IPublishJobModel } from "../../../store/models/PublishJobModel";
import RootStore from "../../../store/RootStore";
import { Alert, Spinner } from "reactstrap";
import ContentPreview from "../content-manager/components/preview/ContentPreview";
import { contactSupportLink } from "../../../constants/defaultValues";
import moment from "moment";

interface Props extends RouteComponentProps<{ id: string }> {}
interface State {
  model: IPublishJobModel;
}

export default class InvestigatePublishJob extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      model: null,
    };
  }

  async componentDidMount(): Promise<void> {
    let model = await RootStore.publishJobStore.fetch(
      parseInt(this.props.match.params.id)
    );

    this.setState((prevState) => ({
      model: model,
    }));
  }

  render() {
    return (
      <>
        {this.state.model === null ? (
          <>
            <p>
              Loading...
              <br />
              <Spinner />
            </p>
          </>
        ) : (
          <>
            <h1 style={{ color: "red" }}>
              Failed to publish{" "}
              {this.state.model.getContentTypeTitle().toLowerCase()} on @
              {this.state.model.integration_id.username}, job id: #
              {this.state.model.id}
            </h1>
            <p>
              A problem happened during the automated scheduling of this{" "}
              {this.state.model.getContentTypeTitle().toLowerCase()}! The post
              was not published!
            </p>
            <br />
            <i>
              {this.state.model.error &&
                moment
                  .utc(this.state.model.error.created_at)
                  .local()
                  .format("MMMM Do YYYY, h:mm:ss a")}
            </i>
            <br />
            {this.state.model.offer_id !== null ? (
              <Alert color="warning">
                This publish operation was due to be done on{" "}
                <b>{this.state.model?.offer_id.getOpponentIdentity().name}'s</b>{" "}
                Instagram account, @
                {this.state.model?.offer_id.getTargetIntegration().username},
                for an agreed upon offer!{" "}
                <a href={"/app/offers/" + this.state.model?.offer_id}>
                  View The Offer / Chat With Him
                </a>
              </Alert>
            ) : null}
            <br />
            <h3>Why did this happen?</h3>
            There can be different reasons that can cause the integration
            between our system and Instagram to break. The current error code in
            our system for this problem is: {this.state.model.error?.type}#
            {this.state.model.error?.id}
            <br />
            <br />
            <h3>What's Next?</h3>
            Contact AInfluencer support for further instructions:{" "}
            <a href={contactSupportLink}>Contact Support</a>
            <br />
            <br />
            <h3>Failed Content Preview:</h3>
            <br />
            <ContentPreview
              model={this.state.model.content_id}
              style={{ float: "left" }}
            />
          </>
        )}
      </>
    );
  }
}
