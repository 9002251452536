import React, { Component } from "react";
import RootStore from "../../../../store/RootStore";
import {
  SortOrder,
  QueryOperator,
  FilterGroup,
} from "../../../../lib/QueryTypes";
import { Filters } from "./Users";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { Spinner } from "reactstrap";
import FilterComponent from "../../../common-components/Filter/FilterComponent";
import { Separator } from "../../../../template/components/common/CustomBootstrap";
import IntegrationRow from "../../../app/account/integrations/IntegrationRow";
import DatatablePagination from "../../../../views/common-components/DatatablePagination";
import ServerSideFilterSet from "../../../common-components/Filter/ServerSideFilterSet";
import { debounce } from "lodash";
import EmptySearchResult from "../../../app/offers/components/EmptySearchResult";
import {
  FilterType,
  Range,
} from "../../../common-components/Filter/AbstractFilterSet";
import { IIntegrationModel } from "../../../../store/models/IntegrationModel";

interface Props extends RouteComponentProps { }
interface State {
  searchTerm: string | null;
  sort: { key: string; direction: SortOrder.DESCENDING };
  dataFetched: boolean;
  updateLists: boolean;
  filter_groups: FilterGroup[];
  pagination: {
    totalPages: number;
    page: number;
    pageSize: number;
    totalCount: number;
  };
}
@observer
export default class IntegrationsTab extends Component<Props, State> {
  private readonly filterSet: ServerSideFilterSet;
  constructor(props: Props) {
    super(props);
    this.state = {
      searchTerm: null,
      filter_groups: [
        {
          isOr: false,
          filters: [
            {
              key: "user_id",
              value: "null",
              operator: null,
              not: true,
            },
          ],
        },
      ],
      sort: {
        key: "created_at",
        direction: SortOrder.DESCENDING,
      },
      dataFetched: false,
      updateLists: false,
      pagination: {
        page: 0,
        pageSize: 10,
        totalPages: 0,
        totalCount: 0,
      },
    };
    this.filterSet = new ServerSideFilterSet(debounce(this.onChange, 600));
    this.filterSet.addFilter({
      name: "created_at",
      title: "Create Date",
      type: FilterType.simpleFilter,
      defaultValue: this.state.sort
        ? this.state.sort.direction
        : SortOrder.DESCENDING,
      values: () => [SortOrder.ASCENDING, SortOrder.DESCENDING],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === SortOrder.ASCENDING) {
          return "Ascending";
        } else if (value === SortOrder.DESCENDING) {
          return "Descending";
        }
      },
    });

    this.filterSet.addFilter({
      name: "user_id",
      title: "User",
      type: FilterType.simpleFilter,
      defaultValue: "internal",
      values: () => ["internal", "external", null],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === "internal") {
          return "Internal IGs";
        } else if (value === "external") {
          return "External IGs";
        } else {
          return "All";
        }
      },
    });

    this.filterSet.addFilter({
      name: "healthy",
      title: "Health",
      defaultValue: null,
      type: FilterType.simpleFilter,
      values: () => ["true", "false", null],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === "true") {
          return "Healthy";
        } else if (value === "false") {
          return "Unhealthy";
        } else {
          return "All";
        }
      },
    });

    this.filterSet.addFilter({
      name: "authenticated",
      title: "authenticated",
      defaultValue: null,
      type: FilterType.simpleFilter,
      values: () => ["true", "false", null],
      valueToLabelConverter: (value: string | number | Range) => {
        if (value === "true") {
          return "Authenticated";
        } else if (value === "false") {
          return "Not Authenticated";
        } else {
          return "All";
        }
      },
    });

    this.fetchUsers = this.fetchUsers.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.pageSizeChange = this.pageSizeChange.bind(this);
  }
  async componentDidMount() {
    RootStore.identityStore.clearIdentities();
    await this.fetchUsers();
  }
  componentWillUnmount() {
    RootStore.users.clearUsers();
    RootStore.identityStore.clearIdentities();
  }

  async fetchUsers() {
    this.setState({ dataFetched: false });
    let response = await RootStore.integrationStore.fetchAll(
      this.state.pagination,
      this.state.filter_groups,
      this.state.sort,
      this.state.searchTerm
    );

    this.setState({
      dataFetched: true,
      pagination: response?.pagination,
    });
  }
  setFilters(filters: Filters) {
    let filterConfig: FilterGroup[] = [];
    if (filters.filters.user_id) {
      filterConfig.push({
        isOr: false,
        filters: [
          {
            key: "user_id",
            value: filters.filters?.user_id,
            operator: null,
            not: filters.filters.user_id === "internal" ? true : false,
          },
        ],
      });
    }
    if (filters.filters.authenticated) {
      filterConfig.push({
        isOr: false,
        filters: [
          {
            key: "token",
            value: "null",
            operator: null,
            not: filters.filters.authenticated === "true" ? true : false,
          },
        ],
      });
    }
    // if (filters.searchTerm) {
    //   filterConfig.push({
    //     isOr: true,
    //     filters: [
    //       {
    //         key: "full_name",
    //         value: filters.searchTerm,
    //         operator: QueryOperator.STRING_CONTAINS,
    //         not: false,
    //       },
    //       {
    //         key: "username",
    //         value: filters.searchTerm,
    //         operator: QueryOperator.STRING_CONTAINS,
    //         not: false,
    //       },
    //     ],
    //   });
    // }
    if (filters.filters.healthy) {
      filterConfig.push({
        isOr: false,
        filters: [
          {
            key: "healthy",
            value: filters.filters.healthy === "true" ? 1 : 0,
            operator: QueryOperator.EQUALS,
            not: false,
          },
        ],
      });
    }

    this.setState({
      pagination: {
        page: 0,
        totalPages: this.state.pagination.totalPages,
        pageSize: this.state.pagination.pageSize,
        totalCount: this.state.pagination.totalCount,
      },
      filter_groups: filterConfig,
      sort: {
        key: "created_at",
        direction: filters.filters.created_at,
      },
    });
  }
  async onFilter(filters: Filters): Promise<void> {
    const { searchTerm } = filters
    this.setState({ searchTerm }, async () => {
      await this.setFilters(filters);
      this.fetchUsers();
    })
  }

  async pageChange(page: number): Promise<void> {
    await this.setState({
      pagination: { ...this.state.pagination, page },
    });
    await this.fetchUsers();
  }

  async pageSizeChange(pageSize: number) {
    await this.setState({
      pagination: { ...this.state.pagination, page: 0, pageSize },
    });

    await this.fetchUsers();
  }

  onChange = (filters: Filters): void => {
    this.onFilter(filters);
  };

  clearFilter = () => {
    this.setState({
      searchTerm: null,
      pagination: {
        page: 0,
        pageSize: 10,
        totalPages: 0,
        totalCount: 0,
      },
      sort: {
        key: "created_at",
        direction: SortOrder.DESCENDING,
      }
    })
  }
  render() {
    return (
      <>
        <FilterComponent
          filterSet={this.filterSet}
          clearFilter={this.clearFilter}
        />
        <Separator className="mb-4 mt-3" />
        {!this.state.dataFetched ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "20em" }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Spinner color="primary" className="mb-1" />
              <p>Please wait...</p>
            </div>
          </div>
        ) : (
          <>
            {RootStore.integrationStore.integrations.length > 0 ? (
              <>
                {RootStore.integrationStore.integrations.map(
                  (integration: IIntegrationModel) => (
                    <div className="mb-4" key={integration.id}>
                      <IntegrationRow
                        integration={integration}
                        {...this.props}
                      />
                    </div>
                  )
                )}
                <DatatablePagination
                  key="pagination"
                  page={this.state.pagination.page}
                  pages={this.state.pagination.totalPages}
                  showPageSizeOptions={true}
                  pageSizeOptions={[10, 50, 100, 200, 500]}
                  onPageSizeChange={this.pageSizeChange}
                  canPrevious={this.state.pagination.page === 0 ? false : true}
                  canNext={
                    this.state.pagination.page ===
                      this.state.pagination.totalPages - 1
                      ? false
                      : true
                  }
                  defaultPageSize={this.state.pagination.pageSize}
                  onPageChange={this.pageChange}
                />
              </>
            ) : (
              <EmptySearchResult />
            )}
          </>
        )}
      </>
    );
  }
}
