import React from "react";
import { Spinner, Col } from "reactstrap";
import { Form, Formik } from "formik";
import { FormGroup, Label, Button } from "reactstrap";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import {
  Colxx,
  Separator,
} from "../../../../template/components/common/CustomBootstrap";
import { BreadCrumb, PasswordField } from "../../../common-components";
import { IUserModel } from "../../../../store/models/UserModel";
import RootStore from "../../../../store/RootStore";
import { NotificationManager } from "../../../../template/components/common/react-notifications/index";
import * as Yup from "yup";
import {
  ajaxErrorAlert,
  handleError,
  showLoading,
} from "../../../../lib/Utils";
import InstagramAuthHelper from "../../../../lib/InstagramAuthHelper";
import { AxiosResponse } from "axios";
import Transport from "../../../../lib/Transport";
import { makeIntegrationQuery } from "../../../../store/common/RequestHelpers";
import Swal from "sweetalert2";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {}

interface Props {
  user: IUserModel;
}

function EditIntegration(props: Props) {
  const model = RootStore.integrationStore.getById(
    parseInt(props.match.params.id)
  );

  const getPasswordSchema = () => {
    if (model.healthy) {
      return Yup.object().shape({
        new_password: Yup.string()
          .required("Enter your password")
          .min(6, "Must be at least 6 characters"),
      });
    } else {
      return Yup.object().shape({
        new_password: Yup.string()
          .required("Enter your password")
          .min(6, "Must be at least 6 characters"),
      });
    }
  };

  return (
    <Col>
      <Colxx xxs="12">
        <BreadCrumb
          heading="IG Account Management"
          {...props}
          optionalProperties={[
            {
              title: model.username,
              value: props.match.params.id,
              id: ":id",
            },
          ]}
        />
        <Separator className="mb-5" />
      </Colxx>
      {!RootStore.integrationStore.isBusy ? (
        <Formik
          validationSchema={getPasswordSchema()}
          initialValues={{
            new_password: "",
          }}
          onSubmit={async (values, actions) => {
            try {
              let auth = new InstagramAuthHelper(
                model.id,
                model.username,
                (): Promise<AxiosResponse> => {
                  return Transport.patch(
                    `integrations/${model.id}`,
                    {
                      integration: {
                        token: values.new_password,
                      },
                    },
                    makeIntegrationQuery()
                  );
                }
              );

              showLoading();
              await auth.perform();
              model.setAuthenticated();
              model.setHealthy();
              Swal.close();

              NotificationManager.success(
                "Done",
                "Your Instagram has been connected successfully!",
                3000,
                null,
                null,
                "filled"
              );
              props.history.push("/app/account/integrations");
            } catch (e) {
              if (e.response && e.response.status === 400) {
                Swal.fire({
                  title: "Wrong Password!",
                  text: "The password you entered appears to be wrong! Make sure you entered the correct password and the password has not been changed by someone else...",
                  type: "error",
                });
                return;
              }

              handleError(e);
              Swal.close();
              ajaxErrorAlert(
                "There was a problem changing this integration`s password!"
              );
              return;
            }
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
          }) => (
            <Form
              translate={"no"}
              onSubmit={handleSubmit}
              className="editIntegrationForm"
            >
              {!model.healthy && (
                <h1 style={{ width: "100%", color: "red" }}>
                  Reconnect Instagram account:{" "}
                  <b>@{model.username} (Disconnected)</b>
                  {!model.healthy ? (
                    <p
                      style={{
                        color: "orangered",
                        fontSize: "0.55em",
                        paddingTop: "0.5em",
                      }}
                    >
                      There seems to be an error with the connection between
                      this IG and our system. Most of the times, these errors
                      are fixed by logging in again with your password! Please
                      enter your password again so that we can re-connect this
                      account:
                    </p>
                  ) : null}
                </h1>
              )}
              <FormGroup className="error-l-100">
                <Label>
                  Instagram Password
                  {errors.new_password && touched.new_password && (
                    <div className="invalidFeedback">{errors.new_password}</div>
                  )}
                </Label>
                <PasswordField
                  className="form-control w-100"
                  name="new_password"
                  onChange={setFieldValue}
                />
              </FormGroup>
              <div style={{ clear: "both" }} />
              <Button
                color={model.healthy ? "primary" : "success"}
                type="submit"
              >
                {model.healthy ? "Submit Password" : "Login Again"}
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        <div>
          <Spinner color="primary" className="mb-1" />
          <p>Please wait...</p>
        </div>
      )}
    </Col>
  );
}

export default observer(EditIntegration);
