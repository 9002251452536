import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getNowUTC, hashtagsCounter, makeTempModelId } from '../../../../../lib/Utils';
import { fixMediaStructure, MediaModel } from '../../../../../store/models/MediaModel';
import RootStore from '../../../../../store/RootStore';
import CreatAdTabs from '../../parts/tabs';
import classes from './style.module.scss';
import NextButton from '../../../dashboard/multi-step-form/onboarding/parts/button';
import OnboardPreview from '../../../dashboard/multi-step-form/onboarding/parts/preview';
import createAdContext from '../../context';
import CreatAdDescription from '../description';
import CreatAdLayout from '../../layout';
import BaseTransport from '../../../../../lib/BaseTransport';
import Swal from 'sweetalert2';
import API from '../../../../../lib/axiosSetup';

function CreatAdPost(props: any) {
    const {
        baseUrl,
        updateOnboard,
        oldData,
        data: {
            adID,
            objectives,
            marketplace: { title, description },
            createAd: { feeds, story },
            discover: { hashtags, gender, offer },
        },
    } = useContext(createAdContext);
    const [Process, setProcess] = useState(false);

    const parentAdQuery = (contentID: any, adID: any) => {
        API.put(BaseTransport.getEndPointPath(`v2/ads/${adID}`), {
            content_id: contentID,
        })
            .then((res) => {
                RootStore.router.push(`${baseUrl}/publish-terms/${adID}`);
                localStorage.setItem('user_id', contentID);
            })
            .catch(function (error) {
                if (error.response?.data?.message) {
                    Swal.fire({
                        type: 'error',
                        text: error.response.data.message,
                        showCancelButton: false,
                        showConfirmButton: true,
                    });
                }
            });
    };

    const queryPostContent = (adID: any, FileImages: any, files: any) => {
        const data = {
            data: {
                caption: feeds.caption ? feeds.caption : null,
            },
            type: feeds.images.length ? 'post' : story.images.length ? 'story' : 'post',
        };

        if (oldData) {
            const old = {
                data: {
                    caption: oldData?.content?.data?.caption,
                },
                type: feeds.images.length ? 'post' : story.images.length ? 'story' : 'post',
            };

            const oldDataImages = oldData?.content?.medias.map((el) => el?.file.url);
            const newDataImages = FileImages.map((el) => el?.file.url);

            if (
                JSON.stringify(oldDataImages) === JSON.stringify(newDataImages) &&
                JSON.stringify(data) === JSON.stringify(old)
            )
                return RootStore.router.push(`${baseUrl}/publish-terms/${adID}`);
        }

        setProcess(true);
        API.post('v2/ad/create/content', {
            content: {
                id: -2,
                user_id: RootStore.users.currentUser.id,
                medias: FileImages,
                ...data,
            },
        })
            .then((res) => {
                if (res?.data) {
                    setProcess(false);
                    if (files) parentAdQuery(res?.data.content.id, adID);
                }
            })
            .catch(function (error) {
                setProcess(false);
                if (error.response?.data?.message) {
                    Swal.fire({
                        type: 'error',
                        text: error.response.data.message,
                        showCancelButton: false,
                        showConfirmButton: true,
                    });
                }
            });
    };

    const submitHandle = () => {
        if (!title || !description || !objectives.length) return RootStore.router.push(`${baseUrl}/marketplace`);

        const FileImages = [];
        const files = feeds.images.length ? feeds.images : story.images.length ? story.images : null;
        if (!files) {
            return Swal.fire({
                text: 'Oops! You have to upload a content such as Image or video to your ad.',
                type: 'error',
                allowEscapeKey: true,
                allowOutsideClick: true,
            });
        }

        if (files) {
            files.forEach((element: any, key: number) => {
                const media = MediaModel.create({
                    id: makeTempModelId(),
                    file: element,
                    original_file: null,
                    preview_file: null,
                    data: {},
                    updated_at: getNowUTC(),
                    created_at: getNowUTC(),
                    content_id: 0,
                    sequence_number: 0,
                });
                FileImages[key] = fixMediaStructure(media);
            });
        }

        if (feeds.caption && hashtagsCounter(feeds.caption) > 30) {
            return Swal.fire({
                type: 'error',
                text: 'Oops! Maximum 30 hashtags are allowed.',
                allowEscapeKey: true,
                allowOutsideClick: true,
            });
        } else if (feeds.caption && feeds.caption.length >= 2200) {
            return Swal.fire({
                type: 'error',
                text: 'Oops! Maximum 2200 characters are allowed for this field.',
                allowEscapeKey: true,
                allowOutsideClick: true,
            });
        }

        if (!adID) {
            API.post('v2/ad/create/step1', {
                title: title,
                description: description,
                goal_answers: objectives,
                campaign: 'default',
            })
                .then((res) => {
                    localStorage.setItem('control_offer_ad', JSON.stringify(res?.data));
                    if (res?.data) {
                        setProcess(false);
                        updateOnboard((prev: any) => ({ ...prev, adID: res?.data.id }));
                        queryPostContent(res?.data.id, FileImages, files);
                    }
                })
                .catch(function (error) {
                    setProcess(false);
                    if (error.response?.data?.message) {
                        Swal.fire({
                            type: 'error',
                            text: error.response.data.message,
                            showCancelButton: false,
                            showConfirmButton: true,
                        });
                    }
                });
        } else {
            queryPostContent(adID, FileImages, files);
        }
    };

    return (
        <CreatAdLayout
            head={{
                title: 'Create an Ad - Post and Content',
                paragraph: 'Select the type of posts and content you want influencers to publish',
                url: 'marketplace',
            }}
        >
            <Grid container>
                <Grid item container className={classes.objectives}>
                    <Grid item container sm={7} flexDirection="column" className={classes.list}>
                        <Grid item className={classes.title} marginBottom={4}>
                            <Grid item marginBottom={4}>
                                <CreatAdDescription
                                    title="Select Post Type and Add Creatives:"
                                    description={
                                        <p>
                                            If you would like influencers to publish your existing content, then upload
                                            images or videos here.
                                            <br />
                                            <br />
                                            If you would like to have influencers create new content, then add some
                                            sample or approved graphics for them to work with. Higher quality graphics
                                            and videos will attract more attention and generate more interest from
                                            influencers.
                                        </p>
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <CreatAdTabs />
                        </Grid>

                        <Grid item>
                            <NextButton onClick={submitHandle} loading={Process}></NextButton>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        sm={5}
                        display={{ xs: 'none', lg: 'flex', sm: 'flex', md: 'flex' }}
                        justifyContent="center"
                    >
                        <Grid item sm={6}>
                            <OnboardPreview />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CreatAdLayout>
    );
}

export default withRouter(CreatAdPost);
