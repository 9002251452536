import React, { Component } from 'react';

import TaskList from './TaskList';
import { Colxx } from '../../../../template/components/common/CustomBootstrap';
import { Row, Table, Card, CardBody, CardTitle, Button } from 'reactstrap';
import { ITask } from './AbstractTaskGenerator';
import InviteWithDmTask from './InviteWitDmTask';
import ApproveAdTask from './ApproveAdTask';
import { AdPreview } from '../../../common-components/AdPreview2';
import { IAdModel } from '../../../../store/models/AdModel';
import RootStore from '../../../../store/RootStore';
import { Modal } from 'reactstrap';
import { CloseIcon } from '../../../../assets/icons';
import { Spinner } from 'reactstrap';
import Swal from 'sweetalert2';
import { ajaxErrorAlert, handleError, showLoading } from '../../../../lib/Utils';
// import moment from "moment";

interface Props {
    tasks: ITask[];
}
interface State {
    tasksList: TaskList;
    displayAdPreview: boolean;
    adToBeShown: IAdModel | null;
    currentTaskIndex: number | null;
}
export default class TaskListComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            tasksList: new TaskList(this.props.tasks),
            displayAdPreview: false,
            adToBeShown: null,
            currentTaskIndex: null,
        };
        this.onDisplayAdPreview = this.onDisplayAdPreview.bind(this);
        this.approveAd = this.approveAd.bind(this);
        this.rejectAd = this.rejectAd.bind(this);
    }
    onDisplayAdPreview() {
        this.setState((prevState) => ({
            displayAdPreview: !this.state.displayAdPreview,
        }));
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.tasks !== this.props.tasks) {
            this.setState({
                tasksList: new TaskList(this.props.tasks),
            });
        }
    }

    async completeTask(task: InviteWithDmTask | ApproveAdTask): Promise<void> {
        if (task instanceof ApproveAdTask) {
            this.onDisplayAdPreview();
            this.setState({ adToBeShown: null });
            await task.getAd();
            this.setState({
                adToBeShown: RootStore.adStore.getExistingAd(task.task.data.ad.id),
            });
        } else if (task instanceof InviteWithDmTask) {
            await task.completeTask();
            document.querySelector<HTMLElement>(`.taskTitle_${this.state.currentTaskIndex}`).style.textDecoration =
                'line-through';
        }
    }
    approveAd(): void {
        let currentTask = this.state.tasksList.taskList[this.state.currentTaskIndex];
        if (currentTask instanceof ApproveAdTask) {
            currentTask.approveAd();
            this.onDisplayAdPreview();
            document.querySelector<HTMLElement>(`.taskTitle_${this.state.currentTaskIndex}`).style.textDecoration =
                'line-through';
        }
    }
    async rejectAd() {
        try {
            await Swal.fire({
                text: 'Write down the rejection reason',
                type: 'warning',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off',
                },
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Reject',
                allowOutsideClick: false,
                showLoaderOnConfirm: true,
                preConfirm: (reason) => {
                    RootStore.adStore.editAd(
                        this.state.tasksList.taskList[this.state.currentTaskIndex].task.data.ad.id,
                        {
                            approval: 'rejected',
                            rejection_reason: reason,
                        },
                    );
                    let currentTask = this.state.tasksList.taskList[this.state.currentTaskIndex];
                    if (currentTask instanceof ApproveAdTask) {
                        currentTask.completeTask();
                    }
                },
            })
                .then(async (res) => {
                    if (res.value) {
                        showLoading('Please Wait...', 'Please wait while we rejecting ad');
                        // await RootStore.adStore.removeAd(adId);
                        Swal.close();
                        this.onDisplayAdPreview();
                        document.querySelector<HTMLElement>(
                            `.taskTitle_${this.state.currentTaskIndex}`,
                        ).style.textDecoration = 'line-through';
                        Swal.fire({
                            type: 'success',
                            title: 'Ad has been rejected successfully!',
                            showCancelButton: false,
                            showConfirmButton: true,
                        });
                    }
                })
                .catch((e) => {
                    handleError(e);
                    ajaxErrorAlert('There was a problem with rejecting ad you selected.');
                });
        } catch (e) {
            handleError(e);
            ajaxErrorAlert('There was a problem with rejecting ad you selected');
        }
    }
    render() {
        return (
            <>
                <Modal toggle={this.onDisplayAdPreview} isOpen={this.state.displayAdPreview} className="adPreviewModal">
                    {this.state.adToBeShown ? (
                        <>
                            <CloseIcon onClick={this.onDisplayAdPreview} className="adPreviewModal__closeButton" />
                            <AdPreview values={this.state.adToBeShown} />
                            <div className="d-flex mt-5 w-100 justify-content-around">
                                <Button color={'secondary'} size={'md'} onClick={this.approveAd}>
                                    Approve
                                </Button>
                                <Button color={'danger'} size={'md'} onClick={this.rejectAd}>
                                    Reject
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex w-100 justify-content-center">
                            <Spinner />
                        </div>
                    )}
                </Modal>
                {/* <AdPreviewModal
          isOpen={this.state.displayAdPreview}
          ad={this.state.adToBeShown}
          onClose={this.onDisplayAdPreview}
        /> */}
                <Card className="mb-4">
                    <CardBody>
                        <CardTitle>Task List</CardTitle>
                        <Row>
                            <Colxx>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.tasksList.taskList.map(
                                            (task: InviteWithDmTask | ApproveAdTask, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td className={`taskTitle_${index}`}>{task.taskTitle()}</td>
                                                        <td>{task.taskType()}</td>
                                                        <td
                                                            onClick={() => {
                                                                this.setState({ currentTaskIndex: index });
                                                                this.completeTask(task);
                                                            }}
                                                            style={{ color: 'blue', cursor: 'pointer' }}
                                                        >
                                                            {task.viewText()}
                                                        </td>
                                                    </tr>
                                                );
                                            },
                                        )}
                                    </tbody>
                                </Table>
                            </Colxx>
                        </Row>
                    </CardBody>
                </Card>
            </>
        );
    }
}
//git
