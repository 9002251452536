import { applySnapshot, clone, getSnapshot } from "mobx-state-tree";
import moment, { Moment } from "moment";
import BatchChange, { ChangeableTypes, ChangeStatus } from "./BatchChange";
import BatchTransport from "../../lib/BatchTransport";
import { IOfferModel, IOfferModelSnapshotIn } from "../models/OfferModel";
import { IMediaModel, IMediaModelSnapshotIn } from "../models/MediaModel";
import { IContentModel, IContentModelSnapshotIn } from "../models/ContentModel";
import {
  ajaxErrorAlert,
  arrayToText,
  momentDateTimeFormat,
} from "../../lib/Utils";
import { IIdentityModel } from "../models/IdentityModel";
import ApiQueryBuilder from "../../lib/ApiQueryBuilder";
import { QueryOperator } from "../../lib/QueryTypes";
import Transport from "../../lib/Transport";
import numbro from "numbro";

export enum OfferStatus {
  Pending = "pending",
  AwaitingPayment = "awaiting-payment",
  Cancelled = "cancelled",
  Refunded = "refunded",
  Paid = "paid",
  Expired = "expired",
  Rejected = "rejected",
}

export enum OfferDerivedStatus {
  NoOffer = "no-offer",
  RejectedByMe = "rejected-by-me",
  NeedsBrandAccept = "needs-brand-accept",
  Incomplete = "incomplete",
  RejectedByBrand = "rejected-by-brand",
  NeedsMyAccept = "needs-my-accept",
  Published = "published",
  DiscardedByMe = "discarded-by-me",
  DiscardedByBrand = "discarded-by-brand",
  Reopened = "reopened",
  Revised = "revised",
  Accepted = "accepted",
}

export type OfferDetailedStatus = OfferStatus | OfferDerivedStatus;

export enum OfferRejectionType {
  Rejected = "rejected",
  Discarded = "discarded",
}

export type OfferModificationInfo = {
  user_id: number;
  moment: Moment;
};

export type OfferModification = {
  type: "OFFER" | "CONTENT" | "MEDIA";
  method: "PATCH" | "POST" | "DELETE";
  id?: number;
  body: any;
};

export default abstract class AbstractOfferHistory<T> {
  protected readonly offer: IOfferModel;
  private history: { [key: number]: BatchChange } = {};
  private offerSnapshots: { [key: number]: T } = {};

  protected abstract async counterOfferRequest(
    modificationsList: OfferModification[],
    callback: (batches: BatchChange[]) => void
  ): Promise<void>;
  protected abstract resolveMediaById(id: number): IMediaModel;
  protected abstract resolveContentById(id: number): IContentModel;

  protected abstract syncUpdatedMainOffer(response: any): void;
  protected abstract handleNewContents(response: any): void;
  protected abstract handleNewMedias(response: any): void;
  protected abstract getMissingContentIds(idList: number[]): number[];

  protected abstract async requestAccept(): Promise<BatchChange[]>;
  protected abstract async requestReject(): Promise<BatchChange[]>;

  protected abstract makeHistoryTree(offer: IOfferModel): T;
  protected abstract applyOfferSnapshot(
    tree: T,
    newSnapshot: IOfferModelSnapshotIn
  ): void;
  protected abstract applyContentSnapshot(
    tree: T,
    newContentSnapshot: IContentModelSnapshotIn
  ): void;
  protected abstract getContentFromHistoryTree(tree: T): IContentModel;
  protected abstract getOfferFromHistoryTree(tree: T): IOfferModel;
  protected abstract replaceContent(tree: T, content: IContentModel): void;

  constructor(offer: IOfferModel) {
    this.offer = offer;
    this.history[-1] = new BatchChange([
      {
        batch: 0,
        status: ChangeStatus.Pending,
        changeable_id: 0,
        changeable_type: ChangeableTypes.Offer,
        created_at: this.offer.updated_at,
        operation: "change",
        user_id: this.offer.getIdentity().user_id,
        value: null,
      },
    ]);
  }

  /**
  * this method merge New Changes
  * @param batches, this param get batches
  */
  public mergeNewChanges(batches: BatchChange[]): void {
    let startBatch = this.offer.version - batches.length;
    for (const batch of batches) {
      this.history[startBatch] = batch;
      startBatch++;
    }
    this.offerSnapshots = {};
  }

  /**
  * this method modifications List
  * @param modificationsList, this param get modifications List
  */
  async counter(modificationsList: OfferModification[]): Promise<void> {
    await this.counterOfferRequest(modificationsList, async (batches) => {
      this.mergeNewChanges(batches);
    });
  }

  /**
  * this method get min Server Batch Id
  */
  getMinServerBatchId(): number {
    return Math.min.apply(
      null,
      Object.keys(this.history).filter(
        (key) => key !== "-1"
      ) as unknown as number[]
    );
  }

  async preload(start: number, end: number | null = null): Promise<void> {
    if (this.getMinServerBatchId() !== Infinity) {
      if (start === this.getMinServerBatchId() && end === null) {
        console.log("nothing to pull for history starting with" + start);
        return;
      }
      if (start < this.getMinServerBatchId()) {
        end = this.getMinServerBatchId();
      }
    }

    const batchTransport = new BatchTransport();
    const callbacks: Function[] = [];

    let startBatchNumber: number = Math.max(0, start);

    let response = await Transport.get(
      "offers/" +
        this.offer.id +
        "/history/" +
        startBatchNumber +
        (end ? "/" + end : "")
    );
    this.updateHistory(response.data, startBatchNumber);

    this.loadMissingModels(batchTransport, callbacks);

    await AbstractOfferHistory.sendBatchRequest(batchTransport, callbacks);
  }

  async fetchUpdateByBatch(batchNumber: number): Promise<void> {
    const batchTransport = new BatchTransport();
    const callbacks: Function[] = [];

    let startBatchNumber: number = Math.max(0, this.getMaxBatchId());

    let response = await Transport.get(
      "offers/" +
        this.offer.id +
        "/history/" +
        startBatchNumber +
        "/" +
        batchNumber
    );
    this.updateHistory(response.data, startBatchNumber);

    let query = new ApiQueryBuilder();
    query.addIncludes("identity", true);
    query.addIncludes("identity.logo", false);
    query.addIncludes("target_identity", true);
    query.addIncludes("target_identity.logo", false);
    query.addIncludes("target_integration", true);
    query.addIncludes("target_integration.picture", false);
    query.addIncludes("content.medias.file", false);
    query.addIncludes("content.medias.preview_file", false);
    query.addIncludes("content.medias.original_file", false);
    query.addIncludes("ad", true);
    query.addIncludes("ad.campaign", true);

    batchTransport.get("offers/" + this.offer.id, query);
    callbacks.push((response: any) => this.syncUpdatedMainOffer(response));

    this.loadMissingModels(batchTransport, callbacks);

    await AbstractOfferHistory.sendBatchRequest(batchTransport, callbacks);
  }

  /**
  * this method update History
  * @param response, this param get response
  */
  private updateHistory(response: any, startBatchNumber: number): void {
    let batchNumber = startBatchNumber;
    for (const grp of response.history) {
      this.history[batchNumber] = new BatchChange(grp);
      batchNumber++;
    }
  }

  /**
  * this method send Batch Request
  */
  private static async sendBatchRequest(
    batchTransport: BatchTransport,
    callbacks: Function[]
  ): Promise<void> {
    try {
      if (callbacks && callbacks.length > 0) {
        const responses = await batchTransport.all();
        for (let i = 0; i < callbacks.length; i++) {
          callbacks[i](responses.data[i]);
        }
      }
    } catch (e) {
      ajaxErrorAlert("Failed to load offer model data!");
    }
  }

  /**
  * this method load Missing Models
  * @param request, this param get Batch Transport
  * @param callbacks, this param is function for custom callback
  */
  private async loadMissingModels(
    request: BatchTransport,
    callbacks: Function[]
  ) {
    let mediaModelIds: number[] = [];
    let contentModelIds: number[] = [];

    for (let batchId in this.history) {
      contentModelIds = contentModelIds.concat(
        this.history[batchId].getAllContentModelIds()
      );
      mediaModelIds = mediaModelIds.concat(
        this.history[batchId].getAllMediaModelIds()
      );
    }

    mediaModelIds = Array.from(new Set(mediaModelIds));
    contentModelIds = Array.from(new Set(contentModelIds));

    contentModelIds = this.getMissingContentIds(contentModelIds);

    if (contentModelIds.length) {
      let contentQuery = new ApiQueryBuilder();
      contentQuery.addIncludes("medias", false);
      contentQuery.addIncludes("medias.file", false);
      contentQuery.addIncludes("medias.preview_file", false);
      contentQuery.addIncludes("medias.original_file", false);
      contentQuery.addFilterGroup([
        {
          key: "id",
          value: contentModelIds,
          operator: QueryOperator.IN,
          not: false,
        },
      ]);
      request.get("contents", contentQuery);
      callbacks.push((response: any) => {
        this.handleNewContents(response);
      });
    }
    if (mediaModelIds.length) {
      let mediaQuery = new ApiQueryBuilder();
      mediaQuery.addIncludes("file", false);
      mediaQuery.addIncludes("original_file", false);
      mediaQuery.addIncludes("preview_file", false);
      mediaQuery.addQueryParam("with_trashed", true);
      mediaQuery.addFilterGroup([
        {
          key: "id",
          value: mediaModelIds,
          operator: QueryOperator.IN,
          not: false,
        },
      ]);
      request.get("medias", mediaQuery);
      callbacks.push((response: any) => {
        this.handleNewMedias(response);
      });
    }
  }

  /**
  * this method get Batch From History
  * @param batchNumber, this param get Batch number
  * @param operationName, this param operationName
  */
  public getBatchFromHistory(
    batchNumber: number,
    operationName: string = ""
  ): BatchChange {
    if (typeof this.history[batchNumber] === "undefined") {
      // eslint-disable-next-line no-throw-literal
      throw (
        (operationName.length ? operationName + ": " : "") +
        "Could not load history data for batch " +
        batchNumber +
        " of offer " +
        this.offer.id
      );
    }
    return this.history[batchNumber];
  }

  /**
  * this method get Modification Info
  * @param batchNumber, this param get Batch number
  */
  public getModificationInfo(batchNumber: number): OfferModificationInfo {
    if (batchNumber > this.getMaxBatchId()) {
      return {
        user_id: this.offer.getLastModifierIdentity().user_id,
        moment: moment.utc(this.offer.updated_at).local(),
      };
    }
    return this.getBatchFromHistory(
      batchNumber,
      "getModificationInfo"
    ).getModificationInfo();
  }

  /**
  * this method get Snapshot By Batch Number
  * @param batchNumber, this param get Batch number
  */
  private getSnapshotByBatchNumber(batchNumber: number): T {
    if (this.offerSnapshots[batchNumber]) {
      return this.offerSnapshots[batchNumber];
    }

    if (batchNumber > this.getMaxBatchId()) {
      return this.makeHistoryTree(this.offer);
    }

    if (batchNumber === 0) {
      // if we have zero, -1 is rejected
      this.history[-1].forceStatus(ChangeStatus.Rejected);
    }

    const previous = this.getSnapshotByBatchNumber(batchNumber + 1);
    this.offerSnapshots[batchNumber] = this.applyChanges(
      previous,
      this.getBatchFromHistory(batchNumber, "getSnapshotByBatchNumber")
    );
    return this.offerSnapshots[batchNumber];
  }

  /**
  * this method apply Changes
  * @param previousModel, this param get previous Model
  * @param changes, this param get changes
  */
  private applyChanges(previousModel: T, changes: BatchChange): T {
    const historyTreeSnapshot = clone(previousModel);

    if (!changes.somethingChanged()) {
      return historyTreeSnapshot;
    }

    if (changes.hasMainContentChange()) {
      this.replaceContent(
        historyTreeSnapshot,
        this.resolveContentById(changes.getNewContentId())
      );
    }

    if (changes.hasOfferUpdate()) {
      this.applyOfferSnapshot(historyTreeSnapshot, {
        ...getSnapshot(this.getOfferFromHistoryTree(historyTreeSnapshot)),
        ...changes.getOfferSnapShot(),
      });
    }

    if (changes.hasContentUpdate()) {
      this.applyContentSnapshot(historyTreeSnapshot, {
        ...getSnapshot(this.getContentFromHistoryTree(historyTreeSnapshot)),
        ...changes.getContentSnapShot(),
      });
    }

    // add
    for (const id of changes.getNewMediaIds()) {
      this.getContentFromHistoryTree(historyTreeSnapshot).removeMediaById(id);
    }

    // remove
    for (const id of changes.getRemovedMediaIds()) {
      const media = this.resolveMediaById(id);
      if (!media) {
        // eslint-disable-next-line no-throw-literal
        throw "Could not resolve media with id=" + id;
      }
      if (
        this.getContentFromHistoryTree(historyTreeSnapshot).medias.filter(
          (md) => md.id === media.id
        ).length === 0
      ) {
        let clonedMedia: IMediaModel = clone(media);
        clonedMedia.unDelete();
        this.getContentFromHistoryTree(historyTreeSnapshot).addMedia(
          clonedMedia
        );
      }
    }

    if (changes.hasMediaChange()) {
      for (const snapshot of changes.getMediaSnapShots()) {
        for (const media of this.getContentFromHistoryTree(historyTreeSnapshot)
          .medias) {
          if (media.id === snapshot.id) {
            applySnapshot(media, {
              ...getSnapshot<IMediaModelSnapshotIn>(media),
              ...snapshot,
            });
          }
        }
      }
    }

    return historyTreeSnapshot;
  }

   /**
  * this method get Max Batch Id
  */
  getMaxBatchId(): number {
    return Math.max.apply(
      null,
      Object.keys(this.history) as unknown as number[]
    );
  }

  /**
  * this method get Rejection Type By Batch
  * @param batchId, this param batch ID 
  */
  getRejectionTypeByBatch(batchId: number): OfferRejectionType {
    if (
      this.getModificationInfo(batchId).user_id ===
      this.getModificationInfo(batchId + 1).user_id
    ) {
      return OfferRejectionType.Discarded;
    } else {
      return OfferRejectionType.Rejected;
    }
  }

  /**
  * this method get Status By Batch
  * @param batchId, this param batch ID 
  */
  getStatusByBatch(batchId: number): OfferDetailedStatus {
    if (!this.isStatusUpdate(batchId, "getStatusByBatch")) {
      const prev = this.history[batchId];
      if (batchId >= this.getMaxBatchId()) {
        return prev.getStatus();
      } else {
        if (this.history[batchId + 1].isStatusUpdate()) {
          return this.getStatusUpdateType(batchId + 1);
        }
        return prev.getStatus();
      }
    }
    // eslint-disable-next-line no-throw-literal
    throw "can't get status for a status update batch, use get status update type instead!";
  }

  /**
  * this method get Offer By Batch
  * @param batchId, this param batch ID 
  */
  getOfferByBatch(batchId: number): IOfferModel {
    return this.getOfferFromHistoryTree(
      this.getSnapshotByBatchNumber(batchId + 1)
    );
  }

  /**
  * this method get Identity By Batch
  * @param batchId, this param batch ID 
  */
  getIdentityByBatch(batchId: number): IIdentityModel {
    const currentUserId = this.getModificationInfo(batchId).user_id;
    if (this.offer.getIdentity().user_id === currentUserId) {
      return this.offer.getIdentity();
    } else {
      return this.offer.getTargetIdentity();
    }
  }

  /**
  * this method get Reactor By Batch
  * @param batchId, this param batch ID 
  */
  getReactorByBatch(batchId: number): IIdentityModel {
    const currentUserId = this.getModificationInfo(batchId).user_id;
    const nextUserId = this.getModificationInfo(batchId + 1).user_id;

    if (
      this.getRejectionTypeByBatch(batchId) === OfferRejectionType.Discarded
    ) {
      if (this.offer.getIdentity().user_id === currentUserId) {
        return this.offer.getIdentity();
      } else {
        return this.offer.getTargetIdentity();
      }
    } else {
      if (this.offer.getIdentity().user_id === nextUserId) {
        return this.offer.getIdentity();
      } else {
        return this.offer.getTargetIdentity();
      }
    }
  }

  /**
  * this method check Latest Version
  * @param batchNumber, this param batch number 
  */
  isLatestVersion(batchNumber: number): boolean {
    return batchNumber === this.getLatestVersionNumber();
  }

  /**
  * this method get Latest Version Number
  */
  getLatestVersionNumber(): number {
    for (let i = this.getMaxBatchId(); i >= this.getMinServerBatchId(); i--) {
      if (!this.history[i].isStatusUpdate()) {
        return i;
      }
    }
    return -1;
  }

  /**
  * this method check batch Data Exists
  * @param batchNumber, this param batch number 
  */
  batchDataExists(batchNumber: number): boolean {
    return this.history[batchNumber] !== undefined;
  }

  /**
  * this method get Status Update Type
  * @param batchNumber, this param batch number 
  */
  getStatusUpdateType(batchNumber: number): OfferDetailedStatus {
    if (!this.isStatusUpdate(batchNumber, "getStatusUpdateType")) {
      // eslint-disable-next-line no-throw-literal
      throw "Can not get status type if the batch is not a status update!";
    }
    if (batchNumber === this.getMaxBatchId() || batchNumber === -1) {
      if (this.offer.status === OfferStatus.AwaitingPayment) {
        return OfferDerivedStatus.Accepted;
      }

      if (this.offer.status === OfferStatus.Rejected) {
        return OfferStatus.Rejected;
      }

      if (this.offer.status === OfferStatus.Paid) {
        return OfferStatus.Paid;
      }

      if (this.offer.status === OfferStatus.Pending) {
        return OfferDerivedStatus.Reopened;
      }

      // eslint-disable-next-line no-throw-literal
      throw (
        "invalid status update type for the latest version of the offer" +
        this.offer.status
      );
    } else {
      const nextBatch = this.getBatchFromHistory(
        batchNumber + 1,
        "getStatusUpdateType"
      );
      if (nextBatch.isStatusUpdate()) {
        if (nextBatch.getStatusChangeValue() === OfferStatus.AwaitingPayment) {
          return OfferDerivedStatus.Accepted;
        } else if (nextBatch.getStatusChangeValue() === OfferStatus.Rejected) {
          return OfferStatus.Rejected;
        } else if (nextBatch.getStatusChangeValue() === OfferStatus.Paid) {
          return OfferStatus.AwaitingPayment;
        } else {
          // eslint-disable-next-line no-throw-literal
          throw (
            "nextBatch.isStatusUpdate: inconsistent batch data for status update that came prior to another status update:" +
            batchNumber
          );
        }
      } else {
        if (
          this.getBatchFromHistory(
            batchNumber,
            "getStatusUpdateType"
          ).getStatusChangeValue() === OfferStatus.AwaitingPayment
        ) {
          if (
            this.offer.status === OfferStatus.Paid ||
            this.offer.status === OfferStatus.Cancelled ||
            this.offer.status === OfferStatus.Refunded
          ) {
            return OfferStatus.Paid;
          }
          return OfferDerivedStatus.Revised;
        } else if (
          this.getBatchFromHistory(
            batchNumber,
            "getStatusUpdateType"
          ).getStatusChangeValue() === OfferStatus.Rejected
        ) {
          return OfferDerivedStatus.Reopened;
        } else {
          // eslint-disable-next-line no-throw-literal
          throw (
            "nextBatchIsNot.isStatusUpdate:inconsistent batch data for status update that came after another status update" +
            batchNumber
          );
        }
      }
    }
  }

  async accept(): Promise<void> {
    if (
      this.offer.status === OfferStatus.Pending ||
      this.offer.status === OfferStatus.Rejected
    ) {
      await this.mergeNewChanges(await this.requestAccept());
    }
    // eslint-disable-next-line no-throw-literal
    throw "Can not accept the offer if it's status is not pending";
  }

  async reject(): Promise<void> {
    if (
      this.offer.status === OfferStatus.Pending ||
      this.offer.status === OfferStatus.AwaitingPayment
    ) {
      await this.mergeNewChanges(await this.requestReject());
    }
    // eslint-disable-next-line no-throw-literal
    throw "Can not reject the offer if it's status is not pending";
  }

  /**
  * this method check Status Update
  * @param batchNumber, this param batch number 
  * @param operation, this param get operation
  */
  isStatusUpdate(batchNumber: number, operation: string = ""): boolean {
    return this.getBatchFromHistory(
      batchNumber,
      "isStatusUpdate-" + operation
    ).isStatusUpdate();
  }

  /**
  * this method get Moment By Batch
  * @param batchId, this param batch ID 
  */
  getMomentByBatch(batchId: number): Moment {
    return this.getModificationInfo(batchId + 1).moment;
  }

  /**
  * this method get Change Caption By Batch
  * @param batchId, this param batch ID 
  */
  getChangeCaptionByBatch(batchId: number): string {
    if (batchId === -1) {
      // eslint-disable-next-line no-throw-literal
      throw "can not get change description for the virtual -1 batch";
    }

    let current = this.getOfferByBatch(batchId);
    let prev = this.getOfferByBatch(batchId - 1);

    let changes = [];

    if (current.price !== prev.price) {
      if (prev.price === null) {
        changes.push(
          `set the price to ${numbro(current.price)
            .formatCurrency({
              mantissa: 2,
              thousandSeparated: true,
              optionalMantissa: true,
            })
            .toUpperCase()}`
        );
      } else if (current.price === null) {
        changes.push(`removed the price`);
      } else if (prev.price < current.price) {
        changes.push(
          `increased the price from ${numbro(prev.price)
            .formatCurrency({
              mantissa: 2,
              thousandSeparated: true,
              optionalMantissa: true,
            })
            .toUpperCase()} to ${numbro(current.price)
            .formatCurrency({
              mantissa: 2,
              thousandSeparated: true,
              optionalMantissa: true,
            })
            .toUpperCase()}`
        );
      } else {
        changes.push(
          `reduced the price from ${numbro(prev.price)
            .formatCurrency({
              mantissa: 2,
              thousandSeparated: true,
              optionalMantissa: true,
            })
            .toUpperCase()} to ${numbro(current.price)
            .formatCurrency({
              mantissa: 2,
              thousandSeparated: true,
              optionalMantissa: true,
            })
            .toUpperCase()}`
        );
      }
    }

    // if (current.exclusivity !== prev.exclusivity) {
    //   if (prev.exclusivity === null) {
    //     let exclusivityDescription =
    //       current.exclusivity === 0
    //         ? "non-exclusive publish"
    //         : "exclusive for " +
    //           moment.duration(current.exclusivity as number, "seconds").humanize();
    //     changes.push(`set the exclusivity terms to ${exclusivityDescription}`);
    //   } else if (prev.exclusivity < current.exclusivity) {
    //     changes.push(
    //       `increased the exclusivity period from ${moment
    //         .duration(prev.exclusivity as number, "seconds")
    //         .humanize()} to ${moment.duration(current.exclusivity as number, "seconds").humanize()}`
    //     );
    //   } else {
    //     changes.push(
    //       `decreased the exclusivity period from ${moment
    //         .duration(prev.exclusivity as number, "seconds")
    //         .humanize()} to ${moment.duration(current.exclusivity as number, "seconds").humanize()}`
    //     );
    //   }
    // }

    if (current.duration !== prev.duration) {
      if (prev.duration === null) {
        let durationDescription =
          current.duration === 0
            ? "permanent"
            : moment.duration(current.duration as number, "seconds").humanize();
        changes.push(`set the ad duration terms to ${durationDescription}`);
      } else if (prev.duration < current.duration || current.duration === 0) {
        changes.push(
          `increased the ad duration from ${
            prev.duration === 0
              ? "permanent"
              : moment.duration(prev.duration as number, "seconds").humanize()
          } to ${
            current.duration === 0
              ? "permanent"
              : moment
                  .duration(current.duration as number, "seconds")
                  .humanize()
          }`
        );
      } else {
        changes.push(
          `decreased the ad duration from ${
            prev.duration === 0
              ? "permanent"
              : moment.duration(prev.duration as number, "seconds").humanize()
          } to ${
            current.duration === 0
              ? "permanent"
              : moment
                  .duration(current.duration as number, "seconds")
                  .humanize()
          }`
        );
      }
    }

    if (current.bio_link !== prev.bio_link) {
      if (prev.bio_link === null) {
        changes.push(`defined a bio link insertion for this ad`);
      } else {
        changes.push(`changed the bio link for this ad`);
      }
    }

    if (current.start !== prev.start) {
      if (prev.start === null) {
        changes.push(
          `set the ad publish date to ${moment(
            current.start,
            momentDateTimeFormat
          ).format("MMMM Do YYYY, h:mm a")}`
        );
      } else {
        changes.push(
          `changed the ad publish date to ${moment(
            current.start,
            momentDateTimeFormat
          ).format("MMMM Do YYYY, h:mm a")}`
        );
      }
    }

    if (
      JSON.stringify(getSnapshot(current.content_id)) !==
      JSON.stringify(getSnapshot(prev.content_id))
    ) {
      changes.push(`modified the ad content`);
    }

    return arrayToText(changes, (item) => item);
  }
}
