import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import Transport from '../../../lib/Transport';
import createAdContext, { ICreateAdContext } from './context';
import Discover from './parts/discover';
import marketplace from './parts/marketplace';
import objectives from './parts/objectives';
import ConfrimCreateAd from './parts/confirm';
import CreatAdPost from './parts/post';
import PublishTerms from './parts/publishTerms';
import OnboardCompany from '../../app/dashboard/multi-step-form/onboarding/parts/company';
import OnboardSkip from '../../app/dashboard/multi-step-form/onboarding/parts/skip';
import { momentDateTimeFormat } from '../../../lib/Utils';
import moment from 'moment';
import { getCountry } from './model';

interface Iprops extends RouteComponentProps {
    route_base?: string;
    mode?: 'create' | 'onboard';
}
function CreatAd(props: any) {
    const adID = props.match.params?.adID;
    const [OldData, setOldData] = useState(null);
    const [DataMount, setDataMount] = useState(adID ? false : true);
    const [OnboardData, setOnboardData] = useState<ICreateAdContext>({
        adID: null || adID,
        status: null,
        objectives: [],
        marketplace: {
            title: '',
            description: '',
        },
        createAd: {
            feeds: {
                images: [],
                caption: '',
            },
            story: {
                images: [],
            },
        },
        terms: {
            fixedSchedule: 0,
            fixedDuration: 0,
            schedule: null,
            duration: 3600,
            duration_segmentation: {
                segment_name: 'hours',
                segment_time: 1,
            },
            displayBioLink: null,
            bio_link: null,
        },
        discover: {
            hashtags: [],
            gender: 'all',
            countries: null,
            offer: false,
        },
        company: {
            name: '',
            description: '',
            country: null,
        },
    });

    const getAd = () => {
        if (!adID) return false;
        Transport.get(`v2/ads/${adID}`).then((res) => {
            const resData = res?.data || res?.data?.data;
            if (resData) {
                setDataMount(true);
                setOldData(resData);
                const {
                    bio_link,
                    title,
                    description,
                    schedules,
                    status,
                    duration,
                    invite_only,
                    goal_answers,
                    content,
                    limit_country,
                    limit_gender,
                    campaign,
                } = resData;

                setOnboardData({
                    adID: adID,
                    status: status,
                    objectives: goal_answers || [],
                    marketplace: {
                        title: title,
                        description: description,
                    },
                    createAd: {
                        feeds: {
                            images: content?.type === 'post' ? content.medias.map((el) => el?.file) : [],
                            caption: resData?.content?.data?.caption || '',
                        },
                        story: {
                            images: content?.type === 'story' ? content.medias.map((el) => el?.file) : [],
                        },
                    },
                    terms: {
                        fixedSchedule: schedules.length ? 1 : 0,
                        fixedDuration: duration === null ? 0 : duration === 0 ? 1 : 2,
                        schedule: schedules
                            ? schedules.map((el: any) => {
                                  return {
                                      end: moment(el.end, momentDateTimeFormat),
                                      id: null,
                                      start: moment(el.start, momentDateTimeFormat),
                                  };
                              })
                            : null,
                        duration: duration || 3600,
                        duration_segmentation: {
                            segment_name: resData.duration_segmentation.segment_name || 'hours',
                            segment_time: resData.duration_segmentation.segment_time || 1,
                        },
                        displayBioLink: bio_link ? 1 : 0,
                        bio_link: bio_link,
                    },
                    discover: {
                        hashtags: [],
                        gender: limit_gender || 'all',
                        countries: getCountry(limit_country),
                        offer: invite_only,
                    },
                    company: {
                        name: campaign.identity.name,
                        description: campaign.identity.description,
                        country: campaign.identity.country_id ? getCountry([campaign.identity.country_id]) : null,
                    },
                });
            }
        });
    };

    useEffect(() => {
        getAd();
    }, []);

    // useEffect(() => {
    //     console.log(OnboardData);
    // }, [OnboardData])

    const route_base = props?.route_base ? props?.route_base : '/app/ads/ad';

    return (
        <createAdContext.Provider
            value={{
                oldData: OldData,
                loadData: DataMount,
                baseUrl: route_base,
                mode: props.mode ? props.mode : 'create',
                data: OnboardData,
                updateOnboard: setOnboardData,
            }}
        >
            <Switch>
                <Route path={`${route_base}/objectives/:adID?`} exact={true} component={objectives} />
                <Route path={`${route_base}/marketplace/:adID?`} exact={true} component={marketplace} />
                <Route path={`${route_base}/skip/:adID?`} exact={true} component={OnboardSkip} />
                <Route path={`${route_base}/mycompany/:adID?`} exact={true} component={OnboardCompany} />
                <Route path={`${route_base}/post-content/:adID?`} exact={true} component={CreatAdPost} />
                {adID ? (
                    <>
                        <Route path={`${route_base}/confirm/:adID?`} exact={true} component={ConfrimCreateAd} />
                        <Route path={`${route_base}/publish-terms/:adID?`} exact={true} component={PublishTerms} />
                        <Route path={`${route_base}/discover/:adID?`} exact={true} component={Discover} />
                    </>
                ) : (
                    <Redirect to={`${route_base}/objectives`} />
                )}
            </Switch>
        </createAdContext.Provider>
    );
}

export default withRouter(CreatAd);
