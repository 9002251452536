import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import RootStore from "../RootStore";

/**
 * this method Last Modification Model
 */
export const LastModificationModel = types
  .model({
    status: types.enumeration(["pending", "accepted", "rejected"]),
    user_id: types.number,
  })
  .actions((self) => ({}))
  .views((self) => ({
    isSelf(): boolean {
      return self.user_id === RootStore.users.currentUser.id;
    },
  }));

export interface ILastModificationModel
  extends Instance<typeof LastModificationModel> {}

export interface ILastModificationModelSnapshotIn
  extends SnapshotIn<typeof LastModificationModel> {}

export interface ILastModificationModelSnapshotOut
  extends SnapshotOut<typeof LastModificationModel> {}
