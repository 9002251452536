import React from 'react'
import ContentLoader from 'react-content-loader'

function ObjectivesLoader() {


    return (
        <div>
            <ContentLoader
                speed={1}
                width={'100%'}
                height={300}
                viewBox="0 0 640 250"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="3" ry="3" width="100%" height="30" />
                <rect x="0" y="60" rx="3" ry="3" width="100%" height="30" />
                <rect x="0" y="120" rx="3" ry="3" width="100%" height="30" />
                <rect x="0" y="180" rx="3" ry="3" width="100%" height="30" />
            </ContentLoader>
        </div>
    )
}

export default ObjectivesLoader
