import { Grid } from '@mui/material';
import React from 'react';
import classes from './style.module.scss';

interface iProps {
    title?: string;
    description?: any;
    note?: string;
}
function CreatAdDescription(props: iProps) {
    const { title, description, note } = props;
    return (
        <Grid container flexDirection="column">
            <Grid item>
                <div className={classes.title}>{title}</div>
            </Grid>
            <Grid item>{description}</Grid>
            <Grid item>
                <span className={classes.note}>{note}</span>
            </Grid>
        </Grid>
    );
}

export default CreatAdDescription;
