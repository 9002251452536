import { Grid } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button } from 'reactstrap';
import { DokaOutput } from '../../../../../../../../../../../3rd-party/doka/doka.esm.min';
import * as Doka from '../../../../../../../../../../../3rd-party/doka/doka.esm.min';
import ApiQueryBuilder from '../../../../../../../../../../../lib/ApiQueryBuilder';
import Transport from '../../../../../../../../../../../lib/Transport';
import CreatAdStoryContext from '../../context';
import createAdContext from '../../../../../../../../context';
import Swal from 'sweetalert2';
import { getExtension } from '../../../../../../../../../../../lib/Utils';

function CreatAdStoryFileUpload() {
    const elementFile = useRef(null)
    const { images: { add } } = useContext(CreatAdStoryContext)
    const { updateOnboard, data: { createAd: { story: { images } } } } = useContext(createAdContext)
    const [Uploading, setUploading] = useState(false)

    const transportFile = (file: any) => {
        setUploading(true)
        let qb = new ApiQueryBuilder();
        qb.addQueryParam('type', 'story');
        Transport.upload<any>(
            'files',
            [
                {
                    file: file,
                    name: 'file',
                },
            ],
            qb,
        ).then(res => {
            if (!res?.data) return false
            const file = res.data.file
            add(file.url);
            updateOnboard((prev: any) => ({
                ...prev,
                createAd: {
                    ...prev.createAd,
                    story: {
                        images: [...prev.createAd.story.images, file]
                    }
                }
            }))
            setUploading(false)
        })
    }


    const checkSize = (width: number, height: number) => {
        if (width !== 1080 || height !== 1920) {
            Swal.fire({
                type: 'error',
                title: '',
                text: 'Oops! The content for stories must be 1080 in width * 1920 in height',
                showCloseButton: true,
                confirmButtonText: 'Ok!'
            })
            return false
        }
        return true
    }

    const showDoka = (file: File): Promise<DokaOutput> => {
        const doka = Doka.create(
            {
                imagePreviewScaleMode: 'image',
                outputWidth: 1080,
                outputHeight: 1920,
                outputFit: 'force',
                cropAspectRatioOptions: [
                    {
                        label: 'Square',
                        value: '9:16',
                    },
                    {
                        label: 'Landscape',
                        value: '9:16',
                    },
                    {
                        label: 'Portrait',
                        value: '9:16',
                    },
                ],
                afterCreateOutput: (blob): any => {
                    let img = new Image()
                    img.src = window.URL.createObjectURL(blob.file)
                    img.onload = () => {
                        const { width, height } = img
                        if (checkSize(width, height)) transportFile(blob.file)
                    }
                }
            }
        );
        // @ts-ignore
        return doka.edit(file);
    }

    const checkDuration = (duration: number) => {
        if (duration >= 60) {
            Swal.fire({
                type: 'error',
                title: '',
                text: 'The story should be size max duration 60',
                showCloseButton: true,
                confirmButtonText: 'Ok!'
            })
            return false
        }
        return true
    }

    const uploadFile = (e: any) => {
        const file = e.target.files[0];
        const fileSize = Math.ceil(file.size / 1024)
        const fileType = getExtension(file.type)
        if (!file && ['image', 'video'].includes(fileType)) return false

        if (fileSize > 200000) {
            return Swal.fire({
                type: 'error',
                title: '',
                text: 'The story should max size 200 mg',
                showCloseButton: true,
                confirmButtonText: 'Ok!'
            })
        }

        if (fileType === 'image') {
            showDoka(file)
        } else if (fileType === 'video') {
            const url = URL.createObjectURL(file)
            const video = document.createElement('video')
            video.onloadedmetadata = evt => {
                URL.revokeObjectURL(url)
                const { videoWidth, videoHeight, duration } = video
                if (checkSize(videoWidth, videoHeight) && checkDuration(duration)) {
                    transportFile(file)
                }
            }
            video.src = url
            video.load()
        }
    }

    useEffect(() => {
        updateOnboard((prev: any) => ({
            ...prev,
            createAd: {
                ...prev.createAd,
                feeds: {
                    ...prev.createAd.feeds,
                    caption: ''
                }
            }
        }))
    }, [])

    return (
        <Grid item container columnSpacing={1}>
            <Grid item>
                <input type="file" ref={elementFile} hidden onChange={(e) => uploadFile(e)} />
                <Button
                    color="primary"
                    type="button"
                    size="xs"
                    className="media-list-container-action"
                    disabled={Uploading}
                    onClick={() => {
                        if (images.length >= 10) {
                            return Swal.fire({
                                type: "error",
                                title: "Oops! Maximum 10 contents are allowed to be uploaded.",
                                showCancelButton: true,
                                showConfirmButton: true,
                            });
                        }
                        elementFile.current && elementFile.current.click()
                    }}
                >
                    <span style={{ verticalAlign: 'bottom' }} className="glyph-icon simple-icon-plus" />
                    {Uploading ? ' Uploading ...' : '  Add new Story'}
                </Button>
            </Grid>

        </Grid>
    )
}

export default CreatAdStoryFileUpload
