import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Card,
  CardBody,
  Row,
  Spinner,
} from "reactstrap";
import RootStore from "../../store/RootStore";
import { Colxx } from "../../template/components/common/CustomBootstrap";
import { IUserModel } from "../../store/models/UserModel";
import { IInvitationModel } from "../../store/models/InvitationModel";
import { IIntegrationModel } from "../../store/models/IntegrationModel";
import { IAdModel } from "../../store/models/AdModel";
import { RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps {
  isOpen: boolean;
  onClose: () => void;
  ad: IAdModel;
}
interface State {
  invitation: IInvitationModel[];
  dataFetched: boolean;
}
export class AdsInvitationsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      invitation:
        this.props.ad.invitation_count > 0 &&
        this.props.ad.getInvitationsList().length === 0
          ? null
          : this.props.ad.getInvitationsList(),
      dataFetched:
        this.props.ad.invitation_count > 0 &&
        this.props.ad.getInvitationsList().length === 0
          ? false
          : true,
    };
    this.typeChanged = this.typeChanged.bind(this);
  }

  async componentDidMount() {
    if (
      this.props.ad.invitation_count > 0 &&
      this.props.ad.getInvitationsList().length === 0
    ) {
      await RootStore.adStore.getAdByIdForAdminPanel(
        this.props.ad.id,
        true,
        false
      );
      this.setState({
        dataFetched: true,
        invitation: this.props.ad.getInvitationsList(),
      });
    }
  }

  typeChanged(e) {
    this.setState({
      dataFetched: true,
      invitation: e.target.value
        ? this.props.ad
            .getInvitationsList()
            .filter((item) => item.track === e.target.value)
        : this.props.ad.getInvitationsList(),
    });
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { invitation } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        modalClassName={`invitationModal`}
      >
        <ModalHeader toggle={onClose}>Invitations</ModalHeader>
        <ModalBody>
          <select
            className="form-control"
            name="duration_type"
            onChange={this.typeChanged}
            style={{ width: "180px" }}
          >
            <option value="">Select Track filter</option>
            <option value="inviteClicked">inviteClicked</option>
            <option value="SignedUp">SignedUp</option>
            <option value="Verified">Verified</option>
            <option value="AppVisit">AppVisit</option>
            <option value="AppLogin">AppLogin</option>
            <option value="ViewOffer">ViewOffer</option>
            <option value="AcceptOffer">AcceptOffer</option>
            <option value="OfferMade">OfferMade</option>
            <option value="Dealclosed">Dealclosed</option>
          </select>

          <br />

          {!this.state.dataFetched ? (
            <div className="w-100 d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <Card className="mb-4">
              <CardBody>
                <Row style={{ overflowX: "auto" }}>
                  <Colxx>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Track</th>
                          <th>Company Name</th>
                          <th>Company Email address</th>
                          <th>Influencer Profile</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.ad.invitation_count > 0
                          ? invitation.map(
                              (invitation: IInvitationModel, index: number) => (
                                <tr key={`invitationId__${invitation.id}`}>
                                  <th>
                                    {this.props.ad.invitation_count - index}
                                  </th>
                                  <th>
                                    {invitation.dm_status !== "not_sent" &&
                                    invitation.email_status === "not_sent"
                                      ? "DM"
                                      : invitation.email_status !==
                                          "not_sent" &&
                                        invitation.dm_status === "not_sent"
                                      ? "Email"
                                      : "Both"}
                                  </th>
                                  <th>
                                    {invitation.dm_status !== "not_sent" &&
                                    invitation.email_status === "not_sent"
                                      ? invitation.dm_status
                                      : invitation.email_status !==
                                          "not_sent" &&
                                        invitation.dm_status === "not_sent"
                                      ? invitation.email_status
                                      : invitation.dm_status}
                                  </th>
                                  <th>{invitation.track}</th>
                                  <th>{invitation.source_id.name}</th>
                                  <th>
                                    {
                                      RootStore.users.adminUsers.find(
                                        (user: IUserModel) =>
                                          user.id ===
                                          invitation.source_id.user_id
                                      ).email
                                    }
                                  </th>
                                  {/* <th>{invitation.target_id.name}</th> */}
                                  <th className="invitationModal__influencerUsername">
                                    <a
                                      target="_blank"
                                      href={`/admin/explorer/${invitation.target_id.id}`}
                                      rel="noopener noreferrer"
                                    >
                                      {invitation.target_id.integrations
                                        .length > 0
                                        ? RootStore.integrationStore.integrations.find(
                                            (integration: IIntegrationModel) =>
                                              integration.id ===
                                              invitation.target_id
                                                .integrations[0].id
                                          ).username
                                        : "deleted instagram account"}
                                    </a>
                                  </th>
                                </tr>
                              )
                            )
                          : null}
                      </tbody>
                    </Table>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          )}
        </ModalBody>
      </Modal>
    );
  }
}
