import Axios from 'axios';
import RootStore from './RootStore';
import { ICategoryModel } from './models/CategoryModel';
import { flow, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { IIntegrationModelSnapshotIn } from './models/IntegrationModel';
import Transport from '../lib/Transport';
import {
    makeInfluencersCustomQuery,
    makeInfluencersQueryById,
    makeInfluencersQueryByHashtag,
    makeInfluencersQueryByCategory,
    makeInfluencersQueryByLocation,
} from './common/RequestHelpers';
import { CustomSearchTermsType } from '../lib/CustomTypes';
import { IInfluencerModel, InfluencerModel } from './models/InfluencerModel';
import { IIdentityModelSnapshotIn } from './models/IdentityModel';
import { makeTempModelId } from '../lib/Utils';

export const DiscoverInfluencersStore = types
    .model({
        influencersList: types.array(InfluencerModel),
        category_condition: types.maybeNull(types.string),
    })
    .volatile((self) => ({
        isBusy: false,
        fetchedFromServer: false,
        searchObj: {},
    }))
    .actions((self) => ({

        /**
        * this method check processing for this store
        */
        setBusy() {
            self.isBusy = true;
        },

        /**
        * this method just set value isBusy = false
        */
        setIdle() {
            self.isBusy = false;
        },

        /**
        * this method push model this store
        * @param model, this param type object for get model
        */
        pushModel(model: IInfluencerModel) {
            self.influencersList.push(model);
        },

        /**
        * this method just clear influencers
        */
        clearList() {
            self.influencersList.clear();
        },
        setSearchObj(search: CustomSearchTermsType) {
            self.searchObj = search;
        },
    }))
    .actions((self) => ({

        /**
        * this method search influencers
        * @param queryParams, this param have parameters for search influencers
        * @param pageOffset, this param get page Offset
        * @param cancelToken, this param for remove token
        */
        influencersCustomSearch: flow(function* (
            queryParams: any,
            pageOffset: number = 1,
            cancelToken: null = null,
        ): any {
            self.setBusy();
            if (cancelToken === 'elastic') {
                let response = yield Transport.post('v1/elastic/search', {
                    keywords: queryParams.search || undefined,
                    hashtags:
                        queryParams.hashtags && queryParams.hashtags.length > 0 ? queryParams.hashtags : undefined,
                    categories:
                        queryParams.categories && queryParams.categories.length > 0
                            ? queryParams.categories
                            : undefined,
                    min_follower: queryParams.followers ? queryParams.followers.min : undefined,
                    max_follower: queryParams.followers ? queryParams.followers.max : undefined,
                    sorts: queryParams.sorts ? queryParams.sorts :
                        [{
                            key: 'followers',
                            direction: 'desc',
                        }]
                    ,
                    country_id: queryParams.country?.value || queryParams?.country || undefined,
                    from: pageOffset,
                    number_of_data: 5000,
                    language: queryParams.language?.value || queryParams?.language || undefined,
                    gender: queryParams.gender?.value || null,
                    invite_only: queryParams.inviteOnly || false,
                    is_super_admin: queryParams.is_super_admin || undefined,
                    // category_condition: self.category_condition ? self.category_condition : 'or'
                });
                let influencers = Object.values(response.data.doc).map((elasticitem: any) => {
                    return InfluencerModel.create({
                        integration: null,
                        identity: {
                            id: elasticitem._source.identity_id,
                            country_id: elasticitem._source.country_id,
                            type: 'influencer',
                            picutre_path: {
                                height: 64,
                                width: 64,
                                id: makeTempModelId(),
                                label: elasticitem._source.picutre_path
                                    ? elasticitem._source.picutre_path.split('/files/')[1].split('.jpg')[0]
                                    : '',
                                type: 'image/jpeg',
                                url: elasticitem._source.picutre_path
                                    ? elasticitem._source.picutre_path.split('/files/')[1].split('.jpg')[0]
                                    : '',
                            },
                            name: elasticitem._source.full_name,
                            followers: elasticitem._source.followers,
                            followings: elasticitem._source.followings,
                            engagement_rate: elasticitem._source.engagement_rate,
                            username: elasticitem._source.username,
                            quality_score: elasticitem._source.quality_score,
                            earnings: elasticitem._source.earnings,
                            language: elasticitem._source.language,
                            category_elastic: elasticitem._source.categories[0]
                                ? elasticitem._source.categories[0].name === 'Other'
                                    ? elasticitem._source.categories[1]
                                        ? elasticitem._source.categories[1].name
                                        : elasticitem._source.categories[0].name
                                    : elasticitem._source.categories[0].name
                                : 'N/A',
                            category_elasticarray: JSON.stringify(elasticitem._source.categories),
                        },
                    });
                });
                RootStore.invitationStore.unSelect();
                self.setIdle();
                return influencers;
            } else {
                try {
                    let response = yield Transport.get(
                        'influencers',
                        makeInfluencersCustomQuery(queryParams, pageOffset),
                        cancelToken,
                    );
                    let influencers = response.data.identities.map((identity: any) =>
                        InfluencerModel.create({
                            integration: response.data.integrations.filter(
                                (integ: any) => integ.id === identity.integrations[0],
                            )[0],
                            identity: identity,
                        }),
                    );
                    RootStore.invitationStore.unSelect();

                    self.setIdle();

                    return influencers;
                } catch (error) {
                    if (!Axios.isCancel(error)) {
                        self.setIdle();
                        throw error;
                    }
                }
            }
        }),

        /**
        * this method fetch Influencers By Location
        */
        fetchInfluencersByLocation: flow(function* (location: string = ''): Generator<any, IInfluencerModel[], any> {
            try {
                let influencers: IInfluencerModel[] = [];

                let response = yield Transport.get('influencers', makeInfluencersQueryByLocation(location));
                if (response.data['identities'].length > 0) {
                    influencers = response.data['identities'].map((identity: IIdentityModelSnapshotIn, index: number) =>
                        InfluencerModel.create({
                            integration: response.data['integrations'].filter(
                                (integration: any) => integration.id === identity.integrations[0],
                            )[0],
                            identity: identity,
                        }),
                    );
                }
                return influencers;
            } catch (error) {
                throw error;
            }
        }),

        /**
        * this method fetch Influencers By Hashtags
        */
        fetchInfluencersByHashtags: flow(function* (
            hashtags: string[] = [],
        ): Generator<any, { hashtag: string; influencers: IInfluencerModel[] }[], any> {
            try {
                let influencers: {
                    hashtag: string;
                    influencers: IInfluencerModel[];
                }[] = [];

                if (hashtags.length > 0) {
                    for (const hashtag of hashtags) {
                        let result = yield Transport.get('influencers', makeInfluencersQueryByHashtag(hashtag));
                        if (result.data['identities'].length > 0) {
                            let infs: IInfluencerModel[] = result.data['identities'].map(
                                (identity: IIdentityModelSnapshotIn) =>
                                    InfluencerModel.create({
                                        integration: result.data['integrations'].filter(
                                            (integ: IIntegrationModelSnapshotIn) =>
                                                integ.id === identity.integrations[0],
                                        )[0],
                                        identity: identity,
                                    }),
                            );
                            influencers.push({ hashtag: hashtag, influencers: infs });
                        }
                    }
                }

                return influencers;
            } catch (error) {
                throw error;
            }
        }),

        /**
        * this method fetch Influencers By Categories
        */
        fetchInfluencersByCategories: flow(function* (categories: ICategoryModel[] = []) {
            try {
                let influencers: {
                    category: ICategoryModel;
                    influencers: IInfluencerModel[];
                }[] = [];
                if (categories.length > 0) {
                    for (const category of categories) {
                        let result = yield Transport.get('influencers', makeInfluencersQueryByCategory(category.id));

                        if (result.data['identities'].length > 0) {
                            let infs: IInfluencerModel[] = result.data['identities'].map(
                                (identity: IIdentityModelSnapshotIn) =>
                                    InfluencerModel.create({
                                        integration: result.data['integrations'].filter(
                                            (integ: IIntegrationModelSnapshotIn) =>
                                                integ.id === identity.integrations[0],
                                        )[0],
                                        identity: identity,
                                    }),
                            );
                            influencers.push({ category: category, influencers: infs });
                        }
                    }
                }

                return influencers;
            } catch (error) {
                throw error;
            }
        }),

        /**
        * this method get Influencers By id
        * @param influencer_id, this param the Influencer id
        */
        getInfluencerById: flow(function* (influencer_id: string): Generator<any, any, any> {
            self.setBusy();
            try {
                let response = yield Transport.get(`identities/${influencer_id}/profile`, makeInfluencersQueryById());
                //let response = yield Transport.get(`v1/elastic/find-integration/${influencer_id}`);
                self.setIdle();
                return {
                    identity: response.data['identity'],
                };
            } catch (error) {
                self.setIdle();
                throw error;
            }
        }),

        setCategory_condition: function (value: any) {
            self.category_condition = value;
        },
    }))
    .views((self) => ({}));

export interface IDiscoverInfluencersStore extends Instance<typeof DiscoverInfluencersStore> { }

export interface IDiscoverInfluencersStoreSnapshotIn extends SnapshotIn<typeof DiscoverInfluencersStore> { }

export interface IDiscoverInfluencersStoreSnapshotOut extends SnapshotOut<typeof DiscoverInfluencersStore> { }
