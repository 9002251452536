import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { IdentityModel } from "./IdentityModel";
import { IntegrationModel } from "./IntegrationModel";

/**
 * this method Influencer Model
 */
export const InfluencerModel = types
  .model({
    integration: types.maybeNull(IntegrationModel),
    identity: types.maybeNull(IdentityModel),
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export interface IInfluencerModel extends Instance<typeof InfluencerModel> {}

export interface IInfluencerModelSnapshotIn
  extends SnapshotIn<typeof InfluencerModel> {}

export interface IInfluencerModelSnapshotOut
  extends SnapshotOut<typeof InfluencerModel> {}
