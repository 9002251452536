import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

/**
 * this method Hashtag Model
 */
export const HashtagModel = types
  .model({
    id: types.identifierNumber,
    created_at: types.maybeNull(types.string),
    updated_at: types.maybeNull(types.string),
    identity_id: types.number,
    name: types.string,
  })

  .actions((self) => ({}))
  .views((self) => ({}));
export interface IHashtagModel extends Instance<typeof HashtagModel> {}

export interface IHashtagModelSnapshotIn
  extends SnapshotIn<typeof HashtagModel> {}

export interface IHashtagModelSnapshotOut
  extends SnapshotOut<typeof HashtagModel> {}
