import {
  destroy,
  getSnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import {
  fixMediaStructure,
  IMediaModel,
  MediaAction,
  MediaModel,
  MediaModification,
} from "./MediaModel";
import { observable } from "mobx";
import { OfferModification } from "../common/AbstractOfferHistory";

export const ContentModel = types
  .model({
    id: types.identifierNumber,
    created_at: types.maybe(types.string),
    updated_at: types.maybe(types.string),
    data: types.frozen(),
    type: types.enumeration(["story", "post"]),
    parent_id: types.maybeNull(types.number),
    user_id: types.number,
    medias: types.array(types.late<typeof MediaModel>(() => MediaModel)),
  })
  .volatile((self) => ({
    modifications: [],
  }))
  .actions((self) => ({

    /**
   * this method add Media
   * @param media, this param get media for added
   */
    addMedia(media: IMediaModel) {
      self.medias.push(media);
    },

    /**
   * this method sort Medias
   * @param order, this param get order
   */
    sortMedias(order: any[]) {
      self.medias = observable(
        self.medias.slice().sort((a: IMediaModel, b: IMediaModel) => {
          if (order.indexOf(a.id) < order.indexOf(b.id)) {
            return -1;
          } else if (order.indexOf(a) > order.indexOf(b)) {
            return 1;
          } else {
            return 0;
          }
        })
      );
      for (let i = 0; i < self.medias.length; i++) {
        self.medias[i].sequence_number = i;
      }
      self.modifications = self.modifications.filter(
        (modification: MediaModification) =>
          modification.action !== MediaAction.REORDER
      );
      for (let media of self.medias) {
        if (
          media.id > 0 &&
          media.sequence_number !== media.originalSequenceNumber
        ) {
          self.modifications.push({
            action: MediaAction.REORDER,
            model: fixMediaStructure(getSnapshot(media)),
          });
        }
      }
    },

    addModification(action: MediaAction, model: IMediaModel) {
      (self.modifications as MediaModification[]).push({
        action: action,
        model: fixMediaStructure(getSnapshot(model)),
      });
    },

    /**
     * this method clear Modifications
     */
    clearModifications() {
      self.modifications = [];
    },

    /**
     * this method get Modifications
     */
    getModifications(): MediaModification[] {
      return self.modifications;
    },

    /**
     * this method set Caption
     * @param caption, this param get caption to set
     */
    setCaption(caption: string) {
      self.data = {
        ...self.data,
        caption: caption,
      };
    },

    /**
     * this method set id
     * @param caption, this param get id to set
     */
    setId(id: number) {
      self.id = id;
    },

    /**
     * this method delete Media
     * @param model, this param get Media for deleted
     */
    deleteMedia(model: IMediaModel) {
      destroy(model);
    },

    /**
     * this method remove Media By Id
     * @param id, this param get Media ID
     */
    removeMediaById(id: number) {
      self.medias.replace(self.medias.filter((media) => media.id !== id));
    },

    /**
     * this method get History Modifications
     */
    getHistoryModifications(): OfferModification[] {
      let changes: OfferModification[] = [];
      changes.push({
        type: "CONTENT",
        id: self.id,
        method: "PATCH",
        body: {
          data: self.data,
        },
      });
      for (let modification of self.modifications as MediaModification[]) {
        if (modification.action === MediaAction.CREATE) {
          changes.push({
            type: "MEDIA",
            method: "POST",
            body: modification.model,
          });
        } else if (modification.action === MediaAction.DELETE) {
          changes.push({
            type: "MEDIA",
            id: modification.model.id,
            method: "DELETE",
            body: {},
          });
        } else {
          // edit or reorder
          changes.push({
            type: "MEDIA",
            id: modification.model.id,
            method: "PATCH",
            body: modification.model,
          });
        }
      }
      return changes;
    },
  }))
  .views((self) => ({
    
    /**
     * this method get Medias
     */
    getMedias(): IMediaModel[] {
      return self.medias.filter((media) => media.deleted_at === null);
    },

    /**
     * this method get Content Type
     */
    getContentType(): string {
      return self.type === "post" ? "Feed Post" : "Story Post";
    },
  }))
  .actions((self) => ({

    /**
     * this method get Content Type
     */
    getPreviewImage(width: number, height: number) {
      const firstMedia = self.medias.filter(
        (media) => media.deleted_at === null
      )[0];
      if (!firstMedia) {
        return "";
      }
      return firstMedia.preview_file.getThumbnailUrl(width, height);
    },
  }))
  .views((self) => ({

    /**
     * this method get Thumbnail Size
     */
    getThumbnailSize(): string {
      const firstMedia = self.medias.filter(
        (media) => media.deleted_at === null
      )[0];
      return (
        firstMedia.preview_file.width + "x" + firstMedia.preview_file.height
      );
    },
  }));

export interface IContentModel extends Instance<typeof ContentModel> { }
export interface IContentModelSnapshotIn
  extends SnapshotIn<typeof ContentModel> { }
export interface IContentModelSnapshotOut
  extends SnapshotOut<typeof ContentModel> { }
