import React, { Component } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import Template from "./components/Template";
import NotificationTemplateEditForm from "./components/EditNotificationTemplateForm";

interface Props extends RouteComponentProps {}

export default class NotificationsTemplate extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route
          path={`${this.props.match.url}/edit/:id`}
          component={NotificationTemplateEditForm}
        />
        <Route path={`${this.props.match.url}/`} component={Template} />
        <Redirect to="/error" />
      </Switch>
    );
  }
}
